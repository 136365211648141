import { useMutation } from 'react-query';
import { getIntegrationLink } from '../api';
import { EScmAppType } from '@blindspot/common/types/bff';
import { useSession } from '@descope/react-sdk';

export const useIntegrationLink = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async ({ provider, appType }: { provider: string; appType?: EScmAppType }) => {
    if (isAuthenticated && !isSessionLoading) {
      return getIntegrationLink(provider, sessionToken, appType);
    }
  });
};
