export const getCurrentTenant = (token: string) => {
  const parts = token.split('.');
  try {
    if (parts.length === 3) {
      const claims = JSON.parse(window.atob(parts[1]));
      return claims.dct || null;
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};
