import { useMutation } from 'react-query';
import { getReportDownloadLinkApi } from './api';
import { useSession } from '@descope/react-sdk';

export const useReportDownloadLink = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(
    async (reportId: string) => {
      if (isAuthenticated && !isSessionLoading) {
        return await getReportDownloadLinkApi(reportId, sessionToken);
      }
    },
    {
      retry: false,
    },
  );
};
