import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import styled, { css } from 'styled-components';

export const TreeStyled = styled(Tree)(
  ({ theme }) => css`
    height: 100%;
    overflow-y: clip;

    & .rc-tree-treenode .rc-tree-node-content-wrapper {
      display: inline-flex;
      align-items: center;
      min-height: 29px;
    }

    & .rc-tree-title {
      width: max-content;
      cursor: pointer;
      font-size: ${theme.text.size.body2};
      font-weight: ${theme.text.weight.regular};
    }

    & .rc-tree-node-selected {
      color: ${theme.colors.indigo[600]};
      background-color: ${theme.colors.indigo[100]};
      box-shadow: unset;
      opacity: unset;
    }

    & .rc-tree-treenode span.rc-tree-switcher {
      background-image: none;
      position: relative;
      width: 12px;
      height: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: ${theme.spacing[0.5]};
    }

    & .rc-tree-treenode-leaf-last:not(:last-of-type) .rc-tree-switcher {
      &:before {
        content: '';
        position: absolute;
        top: 28px;
        width: 1px;
        height: 100vh;
        border-left: 1px dashed ${theme.colors.zinc[200]};
      }

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: ${theme.spacing[1]};
        border-bottom: 1px dashed ${theme.colors.zinc[200]};
        top: 32px;
        left: 6px;
      }
    }
  `,
);
