import React from 'react';
import { ButtonModes, ButtonStyled, IButton } from '@FormElements';

export const Button: React.FC<IButton> = ({ children, onClick, styleMode = ButtonModes.Transparent, iconAfter, disabled }) => {
  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    return onClick && onClick(e);
  };

  return (
    <ButtonStyled disabled={disabled} onClick={handleClick} $mode={styleMode}>
      {children}
      {iconAfter && <span data-icon={iconAfter} />}
    </ButtonStyled>
  );
};
