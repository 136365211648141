import { ApexOptions } from 'apexcharts';
import { DefaultTheme } from 'styled-components';
import { chartAnimationOptions } from './chartAnimationOptions';
import { getChartTitleOptions } from './getChartTitleOptions';
import { EIssuesCategoryLabel } from '@ComponentsTypes';
import { ESeverityLevel } from '@APITypes';
import { pageTools } from '@Utils';
import { EIssueGroup } from 'Layouts/IssuesPage/interfaces';
import { NavigateFunction } from 'react-router-dom';

const IssuesSeverityLinks = {
  [ESeverityLevel.CRITICAL]: `${pageTools.issues.path}/${EIssueGroup.ALL}?filter[severity]=${ESeverityLevel.CRITICAL}`,
  [ESeverityLevel.HIGH]: `${pageTools.issues.path}/${EIssueGroup.ALL}?filter[severity]=${ESeverityLevel.HIGH}`,
  [ESeverityLevel.MEDIUM]: `${pageTools.issues.path}/${EIssueGroup.ALL}?filter[severity]=${ESeverityLevel.MEDIUM}`,
  [ESeverityLevel.LOW]: `${pageTools.issues.path}/${EIssueGroup.ALL}?filter[severity]=${ESeverityLevel.LOW}`,
};

export const getDependenciesByCategoriesOptions = ({ theme, navigate }: { theme: DefaultTheme; navigate: NavigateFunction }): ApexOptions => {
  return {
    ...getChartTitleOptions(theme, 'Dependencies by security status', false),
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      animations: chartAnimationOptions,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      events: {
        xAxisLabelClick: function (event) {
          const route = IssuesSeverityLinks[event.target.textContent.toLowerCase()];
          if (route) {
            navigate(route);
          }
        },
      },
    },
    colors: [theme.colors.rose[600], theme.colors.orange[500], theme.colors.zinc[400], theme.colors.zinc[200]],
    dataLabels: {
      enabled: true,
      offsetX: 20,
      style: {
        fontSize: theme.text.size.overline,
        fontFamily: theme.text.fontFamily,
        fontWeight: theme.text.weight.regular,
        colors: [theme.colors.zinc[500]],
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: 12,
        borderRadius: 6,
        borderRadiusApplication: 'around',
        dataLabels: {
          position: 'top',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [EIssuesCategoryLabel.CRITICAL, EIssuesCategoryLabel.HIGH, EIssuesCategoryLabel.MEDIUM, EIssuesCategoryLabel.LOW],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max: (max) => max + Math.ceil(max * 0.1),
      labels: {
        show: true,
        align: 'right',
        style: {
          colors: [theme.colors.black],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    grid: {
      show: false,
    },
  };
};
