import { Node } from 'reactflow';
import { IChainNode, NODE_HEIGHT } from '../types';

export function createNode({ primaryText, id, secondaryText, usagesText, isVulnerableFunction = false }: IChainNode): Node {
  return {
    id: id,
    data: { primaryText, secondaryText, usagesText, isVulnerableFunction },
    type: 'chainNode',
    position: { x: 0, y: 0 },
    height: NODE_HEIGHT,
  };
}
