import { axiosConfig as axios } from '@Utils';
import { IIgnoreSastIssueRequestPayload } from '../types';

export const ignoreSastIssue = async (token: string, fingerprint: string, data: IIgnoreSastIssueRequestPayload) => {
  const response = await axios.patch(`/issues/first-party/${fingerprint}/handle-ignore`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
