import { ApexOptions } from 'apexcharts';
import { getPercentage } from '../utils';
import { chartAnimationOptions } from './chartAnimationOptions';
import { getChartTitleOptions } from './getChartTitleOptions';

export const getLanguageInUseOptions = ({ theme, totalLanguagesCount }) => {
  return {
    ...getChartTitleOptions(theme, 'Languages in use', false),
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false,
      },
      animations: chartAnimationOptions,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: 14,
        dataLabels: {
          maxItems: 10,
          total: {
            enabled: false,
          },
        },
      },
    },
    tooltip: {
      followCursor: true,
      y: {
        formatter: function (val) {
          return `${getPercentage(val, totalLanguagesCount)}%`;
        },
      },
      x: {
        show: false,
      },
    },
    colors: [
      theme.colors.indigo[200],
      theme.colors.indigo[300],
      theme.colors.indigo[400],
      theme.colors.indigo[600],
      theme.colors.orange[400],
      theme.colors.orange[600],
      theme.colors.orange[700],
      theme.colors.cyan[200],
      theme.colors.cyan[400],
      theme.colors.cyan[600],
    ],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: theme.text.size.overline,
      fontFamily: theme.text.fontFamily,
      markers: {
        radius: 12,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    grid: {
      show: false,
    },
  } as ApexOptions;
};
