import { ERepositoryScore } from '@blindspot/common/types/bff/repositories';
import { RunStatus } from '../runs/enums';
import { RunType } from '@blindspot/common/types/runs';
import { ITicket } from '@blindspot/common/types/bff/tickets';
import { IChain, IReachabilityChain } from '@blindspot/common/types/bff/api';
import { IRemediationEffort } from '@blindspot/common/types/bff/remediation/RemediationEffort.interface';

import { CodingLanguages, DevLanguage, EScmType } from '@blindspot/common/types/bff/scm';
import { EDependencyFileType } from '@blindspot/common/types/artifacts-manager';
import { EJobType } from '@blindspot/common/types/processManager';
import { S3UrlParams } from '@blindspot/common/types/bff/api';

// TODO Remove this file and use the one from @blindspot/common/types/runs.
export enum ERunTrigger {
  WEBHOOK = 'webhook',
  MANUALLY = 'manually',
  AUTO = 'auto',
}

export enum EMonitoringStatus {
  MONITORED = 'monitored',
  UNMONITORED = 'unmonitored',
  SCANNING = 'scanning',
}

export enum EWorkflowEvents {
  DEP_TREE_DISCOVER_START = 'DepTreeDiscoverStart',
  DEP_TREE_DISCOVER_FINISH = 'DepTreeDiscoverFinish',
  PROTECT_FINISH = 'ProtectFinish',
  PROTECT_START = 'ProtectStart',
  POST_PROCESSING = 'PostProceesing',
  POST_PROTECT = 'PostProtect',
  PROGRESS_BAR = 'ProgressBar',
  PROGRESS_BAR_START = 'ProgressBarStart',
  NEW_REPOSITORY_DISCOVERED = 'NewRepositoryDiscovered',
  VULNERABILITIES_POST_PROCESSING = 'VulnerabilitiesPostProcessing',
  REPORT_GENERATION_COMPLETED = 'ReportGenerationCompleted',
  REPORT_GENERATION_ERROR = 'ReportGenerationError',
  DISCOVERY_COMPLETED = 'DiscoveryCompleted',
  DISCOVERY_STARTED = 'DiscoveryStarted',
  REMEDIATION_EFFORT_UPDATE = 'RemediationEffortUpdate',
}

export enum ExtendsDevLanguageMapping {
  'C#' = DevLanguage.CSHARP,
  'C++' = DevLanguage.CPLUSPLUS,
  'Dockerfile' = DevLanguage.DOCKER,
}

export const SupportedLanguagesToMonitor = [DevLanguage.JAVA, DevLanguage.PYTHON, DevLanguage.NODEJS, DevLanguage.CSHARP, DevLanguage.GO, DevLanguage.CPLUSPLUS];
export const SupportedLanguagesToMonitorButton = [...SupportedLanguagesToMonitor, DevLanguage.TYPESCRIPT, DevLanguage.JAVASCRIPT];
export const SupportedLanguagesToFilterButton = [DevLanguage.JAVA, DevLanguage.PYTHON, DevLanguage.JAVASCRIPT_TYPESCRIPT, DevLanguage.CSHARP, DevLanguage.GO, DevLanguage.CPLUSPLUS];

export interface IRepository {
  uuid: string;
  scmOriginId: string;
  scmOriginType: EScmType;
  name: string;
  owner: string;
  branchesCount: number;
  buildsCount: number;
  lastCommitDate: Date;
  lastDiscoveryDate: Date;
  languages: CodingLanguages;
  monitoringStatus?: EMonitoringStatus;
  monitoringBranch: string;
  lastCommitAuthorName: string;
  dependenciesCount: number;
  issuesCount: number;
  createdAt: Date;
  private: boolean;
  url: string;
  progressBars?: ProgressBar[];
  severeIssues?: ISevereIssues;
  repoScore?: ERepositoryScore;
  scanConfigSetName?: string;
}

export interface ISevereIssues {
  critical?: number;
  high?: number;
  medium?: number;
  low?: number;
}

export enum ESeverityLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export enum EUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
}

export interface IBaseUser {
  username: string;
  email: string;
}

export interface IUser extends IBaseUser {
  uuid: string;
  displayName: string;
  avatarUrl: string;
}

export interface IUserUpdate {
  displayName?: string;
}

export interface ISelectFilter {
  name: string;
  value: string | number;
  default?: boolean;
}

export enum EDependencyOrigin {
  NPM = 'Npm',
  PYPI = 'PyPi',
  MVN = 'Maven',
  NUGET = 'NuGet',
  GO = 'Go',
  CONAN = 'Conan',
}

export const DevLanguageToDependencyOrigin = {
  [DevLanguage.JAVA]: EDependencyOrigin.MVN,
  [DevLanguage.PYTHON]: EDependencyOrigin.PYPI,
  [DevLanguage.NODEJS]: EDependencyOrigin.NPM,
  [DevLanguage.CSHARP]: EDependencyOrigin.NUGET,
  [DevLanguage.GO]: EDependencyOrigin.GO,
  [DevLanguage.CPLUSPLUS]: EDependencyOrigin.CONAN,
};

export const DependencyEnrichmentsOriginToDevLanguage = {
  [EDependencyOrigin.MVN]: DevLanguage.JAVA,
  [EDependencyOrigin.PYPI]: DevLanguage.PYTHON,
  [EDependencyOrigin.NPM]: DevLanguage.NODEJS,
  [EDependencyOrigin.NUGET]: DevLanguage.CSHARP,
  [EDependencyOrigin.GO]: DevLanguage.GO,
  [EDependencyOrigin.CONAN]: DevLanguage.CPLUSPLUS,
};

export enum ERelationship {
  DIRECT = 'direct',
  INDIRECT = 'indirect',
  BOTH = 'both',
}

export interface IDependency {
  uuid: string;
  name: string;
  owner: string;
  monitoringStatus: EMonitoringStatus;
  dependencyOrigin: EDependencyOrigin;
  securityStatus: ESeverityLevel | undefined;
  totalIssues: number;
  severeIssues: ISevereIssues;
  buildCount: number;
  branchesCount: number;
  repositoriesCount: number;
  repositories?: IDependencyRepository[];
  installedVersion: string;
  latestVersion?: string;
  packageManagerUrl?: string;
  srcHomepage?: string;
  sha1?: string;
  sha256?: string;
  scanDate?: Date;
  latestVersionReleaseDate?: Date;
  rank?: string;
  description?: string;
  releaseDate?: Date;
  artifactSize?: string;
  dependentsCount?: string;

  relationship: ERelationship;
}

export interface IDependencyRepository {
  uuid: string;
  name: string;
  owner: string;
  files: { path: string; relationship: ERelationship }[];
}

export enum EIssueSubType {
  TAMPERING = 'tampering',
  VULNERABILITY = 'vulnerability',
  RISKY_CODE = 'riskyCode',
  MALICIOUS_PACKAGE = 'maliciousPackage',
  SAST = 'sast',
}

export enum EIssueSubtypePseudoCategory {
  SRC_RISKY_CODE = 'srcRiskyCode',
}

export interface IIssue {
  uuid: string;
  category: EIssueSubType;
  severity: ESeverityLevel;
  score: number | undefined;
  name: string;
  fixedAt: Date | null;
  description: string;
  discoveredAt: Date;
  lastCommitAuthorName: string;
  dependency?: {
    uuid: string;
    name: string;
    owner: string;
    installedVersion?: string;
    latestVersion?: string;
    dependencyOrigin?: string;
    repositories?: IDependencyRepository[];
  };
  vulnerabilities?: IVulnerability[];
  maliciousPackage?: IMaliciousPackage;
  tampering?: ITampering;
  riskyCode?: IRiskycode;
  reachabilityStatus: EReachabilityStatus;
  tickets: ITicket[];
}

export interface IIgnoredIssue {
  ignoredBy: string;
  reason: string;
  ignoredAt: Date;
}

export interface IIssueDetails extends IIssue {
  dependency?: IDependency;
  remediation: string;
  ignoredIssue?: IIgnoredIssue;
  recommended: boolean;
}

export interface IMaliciousPackage {
  id: number;
  malId: string;
  name: string;
  aliases: string[];
  description: string;
  url: string;
}

export interface IVulnerability {
  cveId: string;
  description?: string;
  recommendation?: string;
  cvssV3Vector?: string;
  cvssV3ImpactSubScore?: number;
  cvssV3ExploitabilitySubScore?: number;
  weaknesses: IWeakness[];
  epss?: number;
  kev?: any;
  percentile?: number;
  published: Date;
  versions: {
    fixed: string[];
    introduced: string[];
    lastAffected: string[];
  };
  url: string;
}

export interface ITampering {
  tamperedDescriptions: string[];
  filesPath: string[];
  functionsNames: string[];
  decompileEnrich?: string[];
}

export interface IRiskycode {
  tamperedDescriptions: string[];
  filesPath: string[];
  functionsNames: string[];
  decompileEnrich: string[];
}

export interface IMaliciousPackage {
  group_id: string;
  artifact_id: string;
  version: string;
  lang: DevLanguage;
}

export interface IJavaDependency {
  artifact_id: string;
  group_id: string;
  version: string;
}

export interface IJavaDependencyJar extends IJavaDependency {
  lib_s3_url?: S3UrlParams;
}

export interface ITableColumnConfig {
  manuallyDeactivated?: boolean;
  size?: string;
}

export type ITableColumnConfigs = { [key: string]: ITableColumnConfig };

export type ITableConfigs = {
  [key: string]: ITableColumnConfigs;
};

export interface IBsRunIdPayload {
  bs_run_id: string;
  trigger?: ERunTrigger;
  lang?: DevLanguage;
  status?: string;
}

export interface IRunUpdatePayload extends IBsRunIdPayload {
  status: RunStatus;
}

export interface DepsList extends IJavaDependency, IBsRunIdPayload {}

export interface IDepTreeGenerationPayload extends IBsRunIdPayload {
  bs_run_id: string;
  bucket: string;
  key: string;
  status: string;
}

export interface IMaliciousCodeGenerationPayload extends IBsRunIdPayload {
  bs_run_id: string;
  s3_bucket: string;
  s3_key: string;
  lang: DevLanguage;
  artifact_id: string;
  group_id: string;
  version: string;
  type: EIssueSubType | EIssueSubtypePseudoCategory;
}

export interface ISbomGenerationPayload extends IBsRunIdPayload {
  bs_run_id: string;
  result_bucket: string;
  result_key: string;
  status: string;
}

export interface IIssueMethods {
  name: string;
  clazz: string;
  path: string;
  tamperDesc: string;
  decompiled?: string;
  args?: string[];
  body?: string;
  return?: string | null;
  semgrep_output?: any;
  gpt?: {
    gpt_score?: number;
    gpt_response?: string;
    is_tampered_gpt?: boolean;
  };
}

export interface IPostProcessingPayload extends IBsRunIdPayload {
  score: number;
  above_threshold: boolean;
  type: EIssueSubType;
  methods: IIssueMethods[];
  dependency: IJavaDependencyJar;
  model_result_path: S3UrlParams;
  lang: DevLanguage;
  commit: string;
}

export interface IS3PostProcessingPayload extends IBsRunIdPayload {
  bucket: string;
  key: string;
  dep_tree_bucket?: string;
  dep_tree_key?: string;
}

export interface IRemediationResult {
  source: { dep_id: number; version: string; owner: string; name: string; lang: DevLanguage };
  targets: IRemediationTarget[];
  source_sub_deps: {
    version: string;
    owner: string;
    name: string;
  }[];
}

export interface IRemediationTarget {
  version: string;
  owner: string;
  name: string;
  bs_run_id: string;
  fixed_critical_issues: number;
  fixed_high_issues: number;
  vulnerabilities: IVulnerabilityPayload[];
  recommended: boolean;
  sub_deps_vulns: {
    dependency: {
      version: string;
      owner: string;
      name: string;
    };
    vulnerabilities: IVulnerabilityPayload[];
  }[];
}

export interface IReachabilityInput {
  dep_id: string;
  src_url: {
    bucket: string;
    key: string;
  };
  group_id: string;
  version: string;
  trigger: ERunTrigger;
  action_type: RunType;
  meta_run_id: string;
  bs_run_id: string;
  lang: DevLanguage;
  artifact_id: string;
  issues: {
    id: number;
    name: string;
    severity: ESeverityLevel;
  }[];
  dependencies_file_relative_path: string;
}

export interface IDepFileReachabilityPayload extends IReachabilityInput {
  issues: {
    id: number;
    name: string;
    severity: ESeverityLevel;
    affected: IOsvAffected;
  }[];
}

export interface IReachabilityResult extends IReachabilityInput {
  issues: IIssueReachabilityResult[];
}

export interface IIssueReachabilityResult {
  id: number;
  name: string;
  severity: ESeverityLevel;
  reachable: EReachabilityStatus;
  chains?: IReachabilityChain[];
}

export interface IPostProtectPayload {
  status: string;
  issue_id: number;
  enriched_tampered_methods_bucket: string;
  enriched_tampered_methods_key: string;
  bs_run_id: string;
}

export interface IScanFailedPayload extends IJavaDependency, IBsRunIdPayload {}

export interface IVulnerabilitiesPostProcessingPayload extends IBsRunIdPayload {
  bucket: string;
  result_key: string;
  dep_tree: {
    key: string;
    bucket: string;
  };
}

export interface IMaliciousPackagesPostProcessingPayload extends IBsRunIdPayload {
  result_bucket: string;
  result_key: string;
}

export interface ISastPayload extends IBsRunIdPayload {
  status: string;
  s3_bucket: string;
  s3_key: string;
  type: EIssueSubType;
}

export interface IMessagePayload {
  message: EAction;
  payload:
    | IMaliciousCodeGenerationPayload
    | IBsRunIdPayload
    | IRunUpdatePayload
    | IDepTreeGenerationPayload
    | IPostProcessingPayload
    | IPostProtectPayload
    | IScanFailedPayload
    | ISastPayload;
  run_metadata?: IRunMetaData;
}

export interface IRunMetaData {
  job_type?: EJobType;
  process_id: string;
  job_id?: string;
  status: string;
  branch_uuid: string;
  process_type?: string;
  extra_data?: { issueCount: number; reportId?: number; orgProviderId?: string };
}

export enum EAction {
  RUN_UPDATE = 'run_update',
  POST_PROCESSING = 'post_processing',
  ENRICHED_DEPS_TREE_FILE = 'enriched_deps_tree_file',
  POST_PROTECT = 'post_protect',
  SCAN_COMPLETED = 'scan_completed',
  SCAN_FAILED = 'scan_failed',
  BL_EVENTS_DEP_GOVERNANCE_ENRICHMENT = 'dependency_governance_enrichment',
  BL_EVENTS_DEP_GOVERNANCE_ENRICHMENT_RESULT = 'dependency_governance_enrichment_result',
  VULNERABILITIES_POST_PROCESSING = 'vulnerabilities-post-processing',
  AUTO_PROTECT = 'auto_protect',
  REACHABILITY_POST_PROCESSING = 'reachability_post_processing',
  REMEDIATION_POST_PROCESSING = 'remediation_post_processing',
}

export interface IMonitoringData {
  maxRepositoryCount: number;
  monitored: number;
  total: number;
  scanning: number;
}

export const NotAvailable = 'N/A';
export const Undetermined = 'Undetermined';

export class PostProtectEvent {
  modelResultPath: S3UrlParams;
  libS3Url: S3UrlParams;
  issueId: number;
  bsRunId: string;
  runType: RunType;
  groupId: string;
  artifactId: string;
  version: string;
  lang: DevLanguage;
}

export interface IWebhookMetaCommits {
  id: string;
  tree_id: string;
  timestamp: string;
  added: string[];
  removed: string[];
  modified: string[];
  author: { name: string };
}

export interface IAppBlEventHandler {
  handleEvent(body: IMessagePayload): Promise<void>;
}

export interface IDependencyGovernanceEnrichmentPayload {
  depId: string;
  version: string;
  owner: string;
  name: string;
  uuid: string;
  lang: DevLanguage;
  latest_version: string | undefined;
  latest_release_date: string | undefined;
  cur_release_date: string | undefined;
  latest_sh1?: string;
  latest_sh256: string | undefined;
  cur_sh1?: string;
  cur_sh256: string | undefined;
  latest_jar_size?: string;
  cur_jar_size?: string;
  package_manager_url: string | undefined;
  description: string | undefined;
  rank: string | undefined;
  dependents_count: string | undefined;
  homepage: string | undefined;
  latest_wheel_size?: string;
  cur_wheel_size?: string;
}

export interface ProgressBar {
  seen: number;
  total: number;
  processId?: string;
  type?: string;
  // TODO Remove.
  metaRunId?: number;
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum DependencyFilesSupported {
  POM_XML = 'pom.xml',
  BUILD_GRADLE = 'build.gradle',
  REQUIREMENTS_TXT = 'requirements.txt',
  PYPROJECT_TOML = 'pyproject.toml',
  POERTY_LOCK = 'poetry.lock',
  SETUP_CFG = 'setup.cfg',
  PACKAGE_JSON = 'package.json',
  PACKAGE_LOCK_JSON = 'package-lock.json',
  YARN_LOCK = 'yarn.lock',
  CSPROJ = '.csproj',
  PACKAGES_CONFIG = 'packages.config',
  GO_MOD = 'go.mod',
  CONANFILE_TXT = 'conanfile.txt',
  CONANFILE_PY = 'conanfile.py',
}

export enum MetaFileNames {
  BUILD_GRADLE = 'gradle-wrapper.properties',
}

export enum FileToDevLanguage {
  'pom.xml' = DevLanguage.JAVA,
  'build.gradle' = DevLanguage.JAVA,
  'gradle-wrapper.properties' = DevLanguage.JAVA,
  'requirements.txt' = DevLanguage.PYTHON,
  'pyproject.toml' = DevLanguage.PYTHON,
  'poetry.lock' = DevLanguage.PYTHON,
  'setup.cfg' = DevLanguage.PYTHON,
  'package.json' = DevLanguage.NODEJS,
  'package-lock.json' = DevLanguage.NODEJS,
  'yarn.lock' = DevLanguage.NODEJS,
  '.csproj' = DevLanguage.CSHARP,
  'packages.config' = DevLanguage.CSHARP,
  'go.mod' = DevLanguage.GO,
  'conanfile.txt' = DevLanguage.CPLUSPLUS,
  'conanfile.py' = DevLanguage.CPLUSPLUS,
}

export interface RunPayload {
  filePath: string;
  fileName: string;
  fileSha: string;
  cacheKey: string;
  lang: DevLanguage;
  bucket: string;
  key: string;
  [EDependencyFileType.PRIMARY]: S3UrlParams & { filename: string };
  [EDependencyFileType.SECONDARY]?: S3UrlParams & { filename: string };
  [EDependencyFileType.METADATA]?: S3UrlParams & { filename: string };
}

export interface IUserRole {
  weight: number;
  name: string;
}

export interface IOrganizationUser {
  uuid: string;
  displayName: string;
  email: string;
  name: string;
  url: string;
  avatarUrl: string;
  status: EUserStatus;
  role: IUserRole;
}

export interface IOrganizationConnection {
  id: string;
  name: string;
}

export interface IPageMeta {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
}

export interface IPage<T> {
  data: T[];
  meta: IPageMeta;
}

export interface IPageRequestOptions {
  order?: Order;
  page?: number;
  take?: number;
}

export interface IOsvAffected {
  affected?: {
    ranges?: {
      type?: string;
      repo?: string;
      events?: {
        introduced?: string;
        fixed?: string;
        last_affected?: string;
        limit?: string;
      }[];
    }[];
    versions?: string[];
    database_specific?: any;
    package?: any;
  }[];
}

export interface IVulnerabilityPayload {
  vulnId: string;
  recommendation: string;
  description: string;
  aliases: string[];
  severity: string;
  published?: string;
  cvssV3BaseScore: number | undefined;
  cvssV3Vector?: string;
  cvssV3ImpactSubScore?: number;
  cvssV3ExploitabilitySubScore?: number;
  kev?: any;
  epss?: number;
  percentile?: number;
  affected?: IOsvAffected;
  cwes: IWeaknessesPayload[];
}

export interface IMaliciousVulnerability extends IOsvAffected {
  id: string;
  summary: string;
  details: string;
  aliases: string[];
  modified: string;
  published: string;
  database_specific: {
    cwes: IWeaknessesPayload[];
  };
  references: {
    type: string;
    url: string;
  }[];
  schema_version: string;
}

interface IBaseWeakness {
  name: string;
  url: string;
  description?: string;
}

export interface IWeaknessesPayload extends IBaseWeakness {
  cweId: string;
}

export interface IWeakness extends IBaseWeakness {
  cweId: number;
}

export interface IVulnerabilityFile {
  vulnerability: IVulnerabilityPayload;
  component: { name: string; group: string; version: string };
  chains: IChain[];
}

export interface IMaliciousPackageFile {
  dependency: IMaliciousPackage;
  vulnerabilities: IMaliciousVulnerability[];
}

export interface ISuspicions {
  line_range: [number, number] | number[];
  reason: string;
  certainty: number;
  lines: string;
}

export interface IMaliciousCodeFile {
  suspicions: ISuspicions[];
  description: string;
  maliciousness_score: number;
  bucket_path: string;
  class_name: string;
  path: string;
}

export enum ERiskyCodeSeverity {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum ERiskyCodeScore {
  INFO = 3,
  WARNING = 6,
  ERROR = 8,
}

export const riskyCodeSeverityToScore = {
  [ERiskyCodeSeverity.INFO]: ERiskyCodeScore[ERiskyCodeSeverity.INFO],
  [ERiskyCodeSeverity.WARNING]: ERiskyCodeScore[ERiskyCodeSeverity.WARNING],
  [ERiskyCodeSeverity.ERROR]: ERiskyCodeScore[ERiskyCodeSeverity.ERROR],
};

export const riskyCodeScoreToSeverityLevel = {
  [ERiskyCodeScore[ERiskyCodeSeverity.INFO]]: ESeverityLevel.LOW,
  [ERiskyCodeScore[ERiskyCodeSeverity.WARNING]]: ESeverityLevel.MEDIUM,
  [ERiskyCodeScore[ERiskyCodeSeverity.ERROR]]: ESeverityLevel.HIGH,
};

export interface IDependencySummary {
  id: number;
  name: string;
  owner: string;
  version: string;
  issuesCount: string;
  uuid: string;
  critical?: string;
  high?: string;
  medium?: string;
  low?: string;
}

export interface IRepositoryDependencyFile {
  path: string;
}

export enum EReachabilityStatus {
  REACHABLE = 'reachable',
  UNREACHABLE = 'unreachable',
  UNDEFINED = 'undefined',
}

export type RemediationIssuesType = Record<ESeverityLevel, number>;

export interface IRemediationVersion {
  uuid: string;
  name: string;
  version: string;
}

export interface IRemediationDependency {
  source: IRemediationVersion;
  target: IRemediationVersion;
  fixedIssues: RemediationIssuesType;
  efforts: IRemediationEffort;
  depFileUrlWithLine: string;
  depFileLineNumber: number;
}

export interface IRemediation {
  dependencyFileName: string;
  depFileUuid: string;
  dependencies: IRemediationDependency[];
  depFilefixedIssues: RemediationIssuesType;
}

export interface IRemediationSummary {
  currentStatus: RemediationIssuesType;
  fixesAvailable: RemediationIssuesType;
  introduced: RemediationIssuesType;
  statusAfterFixes: RemediationIssuesType;
  scores?: {
    currentStatusScore: ERepositoryScore;
    statusAfterFixesScore: ERepositoryScore;
  };
  effortLevelsCount: {
    [key: string]: number;
  };
}

export interface IRemediationIssue {
  baseIssueUuid: string;
  name: string;
  severity: ESeverityLevel;
  category: EIssueSubType;
  tickets: ITicket[];
}

export interface IRemediationTargetFe {
  version: string;
  selected: boolean;
  recommended: boolean;
  issues: {
    currentStatus: RemediationIssuesType;
    fixesAvailable: RemediationIssuesType;
    introduced: RemediationIssuesType;
    statusAfterFixes: RemediationIssuesType;
  };
}

export const hashIssueMethodsOrders = {
  MVN_TAMPERING: ['name', 'path', 'clazz', 'tamperDesc', 'args'],
  DEFAULT: ['name', 'path', 'clazz', 'tamperDesc'],
};
