import { pageTools } from '@Utils';
import { EIssueLabels } from './issueLabels.enum';
import { EIssueGroup } from 'Layouts/IssuesPage/interfaces';
import { ESeverityLevel } from '@APITypes';

export const LABEL_TO_LINK = {
  [EIssueLabels.TOTAL]: `${pageTools.issues.path}/${EIssueGroup.ALL}`,
  [EIssueLabels.SEVERE]: `${pageTools.issues.path}/${EIssueGroup.ALL}?filter[severity]=${ESeverityLevel.CRITICAL}%2B${ESeverityLevel.HIGH}`,
  [EIssueLabels.REACHABLE_SEVERE]: `${pageTools.issues.path}/${EIssueGroup.RECOMMENDED}`,
};
