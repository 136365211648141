import React from 'react';
import { IVulnerability } from '@APITypes';
import { VulnerabilityLinkLabelsStyled } from './styled';
import { LinkStyled } from '../styled';

export const VulnerabilityLinkLabels: React.FC<{ vulnerability: IVulnerability }> = ({ vulnerability }) => {
  return (
    <VulnerabilityLinkLabelsStyled>
      <LinkStyled target="_blank" rel="noreferrer" href={vulnerability.url}>
        {vulnerability.cveId}
      </LinkStyled>
      {!!vulnerability.weaknesses?.length &&
        vulnerability.weaknesses.map((weakness) => (
          <LinkStyled target="_blank" rel="noreferrer" key={weakness.cweId} href={weakness.url}>
            CWE-{weakness.cweId}
          </LinkStyled>
        ))}
    </VulnerabilityLinkLabelsStyled>
  );
};
