import { useQuery } from 'react-query';
import { fetchRepositoriesFailedPullRequests } from '../api';
import { IApiDashboardFilterParams, IRepositoryFailedPullRequestsItem } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useRepositoriesFailedPullRequests = (params?: IApiDashboardFilterParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const key = 'repositoriesFailedPullRequests';

  const queryOptions = {
    queryKey: [key, params],
    queryFn: async () => {
      return await fetchRepositoriesFailedPullRequests(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IRepositoryFailedPullRequestsItem[]>(queryOptions);
};
