import { useContext, useEffect, useId } from 'react';
import { EWorkflowEvents } from '@APITypes';
import { SocketContext, SocketEventHandlerType, useAsyncError } from '@Utils';
import { useUserOrganization } from '@Hooks';
import { bindEvent } from '../utils';

export const useSocketBind = (eventName: EWorkflowEvents, eventHandler: SocketEventHandlerType, subChannel?: string): void => {
  const throwError = useAsyncError();
  const { pusher } = useContext(SocketContext);
  const { data: userOrganization, isLoading } = useUserOrganization();
  const sourceId = useId();

  useEffect(() => {
    if (pusher?.connection?.state !== 'connected' || isLoading) {
      return;
    }

    const channelName = subChannel ? `presence-${userOrganization?.org}-${subChannel}` : `presence-${userOrganization?.org}`;
    const channel = pusher.channel(channelName);

    return bindEvent(eventName, eventHandler, pusher, throwError, sourceId, channel);
  }, [eventHandler, pusher, subChannel, isLoading]);
};
