import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const SliderTrackCirecleStyled = styled(FontAwesomeIcon)`
  --fa-border-color: ${({ theme }) => theme.colors.zinc[300]};
  --fa-border-radius: 50%;
  --fa-border-width: 1px;
  --fa-border-padding: '0';
  color: ${({ theme }) => theme.colors.white};
  height: 20px;
  width: 20px;
`;
