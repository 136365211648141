import React from 'react';
import { AxiosError } from 'axios';
import { ENotificationType, toastService } from '@Notification';
import { Loader } from '@Components';
import { ReportActionButton } from '../styled';
import { IReportDownloadProps } from '../interfaces';
import { useReportDownloadLink } from '../../../hooks';

export const ReportDownload: React.FC<IReportDownloadProps> = ({ reportId, reportName }) => {
  const { mutate: getDownloadLink, isLoading: isDownloading } = useReportDownloadLink();

  const onClickDownload = () => {
    getDownloadLink(reportId, {
      onSuccess(downloadLink: string) {
        const a = document.createElement('a');
        a.setAttribute('href', downloadLink);
        a.setAttribute('download', reportName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      onError(error: unknown) {
        if (error instanceof AxiosError) {
          toastService({ header: 'Something went wrong', type: ENotificationType.ERROR });
        }
      },
    });
  };

  return (
    <ReportActionButton disabled={isDownloading} onClick={onClickDownload}>
      {isDownloading ? <Loader /> : 'Download'}
    </ReportActionButton>
  );
};
