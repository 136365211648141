import styled from 'styled-components';

export const SummaryBlockStyled = styled.div`
  flex: 1;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows['1']};
  padding: ${({ theme }) => theme.spacing[2]};
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  grid-column-gap: ${({ theme }) => theme.spacing['2.5']};
  grid-row-gap: ${({ theme }) => theme.spacing['2.5']};
`;
