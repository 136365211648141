import { usePostProcessing, useProtectFinish, useProtectStart, useReportGenerationCompleted, useReportGenerationError } from '@Utils';
import { IssueCategoryLabel } from '@blindspot/common/types/bff';
import { ExportsViewButton } from 'Layouts/Exports/components';
import { ENotificationType } from './types';
import React from 'react';
import { toastService } from './toastService';

export const useNotificationSockets = (): void => {
  useProtectStart((data) => {
    toastService({ header: 'Scan started', message: `Scanning the repository ${data.repository}` });
  });
  useProtectFinish((data) => {
    const issues = data.issuesLength;
    toastService({
      header: `${data.repository} is now monitored`,
      message: issues ? `${issues} issues detected on the repository  ${data.repository}` : `No Issues detected on the repository ${data.repository}`,
      type: ENotificationType.SUCCESS,
    });
  });

  usePostProcessing(
    undefined,
    (data) => {
      toastService({
        header: 'Issue Detected',
        message: `${IssueCategoryLabel[data.category]} issue detected on the dependency ${data.dependency}`,
        type: ENotificationType.ERROR,
      });
    },
    (data) => {
      toastService({
        header: 'Issues Detected',
        message: `${data.issuesCounter} Vulnerabilities issues detected on repository ${data.repository}`,
        type: ENotificationType.ERROR,
      });
    },
  );

  useReportGenerationCompleted(() => {
    toastService({
      header: 'Export is ready',
      message: 'Your export file is ready for download',
      type: ENotificationType.SUCCESS,
      children: <ExportsViewButton />,
    });
  });

  useReportGenerationError(() => {
    toastService({
      header: 'Export failed',
      message: 'We’ve failed generating you export file, please try again',
      type: ENotificationType.ERROR,
    });
  });
};
