import styled from 'styled-components';

export const IssueDescriptionShowMoreStyled = styled.button`
  font-size: ${({ theme }) => theme.text.size.caption};
  color: ${({ theme }) => theme.colors.indigo['500']};
  letter-spacing: 0.46px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;
