import { Button } from '@Styles';
import styled from 'styled-components';

export const SelectOption = styled(Button)`
  align-items: center;
  text-align: left;
  gap: 6px;
  border-radius: 10px;
  transition: 200ms;
  line-height: 28px;
  letter-spacing: 0.15px;
  width: 100%;
  font-size: ${({ theme }) => theme.text.size.body1};
  padding: ${({ theme }) => `${theme.spacing['1']} ${theme.spacing['2']}`};
  color: ${({ theme }) => theme.colors.zinc['800']};
  background: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme }) => theme.colors.zinc['100']};
  }
  max-width: 100%;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
`;
