import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ScanConfigNameStyled = styled(Flex)(
  ({ theme }) => css`
    align-items: center;
    gap: ${theme.spacing[1]};
    font-size: ${theme.text.size.body1};
    font-weight: ${theme.text.weight.regular};
    line-height: ${({ theme }) => theme.spacing[3.5]};
    letter-spacing: 0.15px;
    text-align: left;
    color: ${theme.colors.zinc[800]};
  `,
);
