import { useEffect } from 'react';
import { useForm, Controller, SubmitHandler, UseControllerReturn } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useQueryClient } from 'react-query';
import { ControlInputBlockStyled, ControlInputContainer, ControlErrorMessage, SubmitButton, CancelButton, InputLabel } from '@Components/Form';
import { Flex } from '@Styles';
import { DropdownSelect, Loader } from '@Components';
import { ENotificationType, toastService } from '@Notification';
import { useModal } from '@Modal';
import { useTicketManagerProjects, useCreateIssueTicket, useTicketDescription, useTicketManagerProjectResources } from './hooks';
import { ICreateTicketFormData, ICreateTicketFormProps, ICreateTicketRequestPayload } from './types';
import { CreateTicketFormStyled } from './styled';
import { TicketDescription } from './TicketDescription';
import { TicketSummaryInput } from './TicketSummaryInput';

export const CreateTicketForm: React.FC<ICreateTicketFormProps> = ({ ticketManager, baseIssueUuid, repoUuid }) => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { data: ticketDescription, isLoading: isLoadingTicketDescription, isError: isTicketDescriptionError } = useTicketDescription({ baseIssueUuid, repoUuid });
  const { data: projects = [], isLoading: isLoadingProjects, isError: isProjectsError } = useTicketManagerProjects(ticketManager);

  const defaultValues: ICreateTicketFormData = {
    summary: ticketDescription?.summary || '',
    description: ticketDescription?.description || '',
    projectId: projects?.[0]?.id || '',
    issueTypeId: '',
    assigneeId: '',
  };
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<ICreateTicketFormData>({
    defaultValues,
    shouldFocusError: true,
    mode: 'onChange',
  });
  const selectedProject = watch('projectId');

  const {
    data: projectResources = { issueTypes: [], users: [] },
    isFetching: isFetchingProjectResources,
    isError: isProjectResourcesError,
  } = useTicketManagerProjectResources(ticketManager, selectedProject, !!selectedProject);
  const createIssueTicket = useCreateIssueTicket();

  useEffect(() => {
    if (!isLoadingProjects && !isLoadingTicketDescription) {
      reset(defaultValues);
    }
  }, [isLoadingProjects, isLoadingTicketDescription]);

  useEffect(() => {
    if (!isFetchingProjectResources) {
      setValue('issueTypeId', projectResources?.issueTypes?.[0]?.id || '');
      setValue('assigneeId', '');
    }
  }, [isFetchingProjectResources]);

  const isInitialDataFetchingError = isTicketDescriptionError || isProjectsError || isProjectResourcesError;

  useEffect(() => {
    if (!isInitialDataFetchingError) {
      return;
    }
    toastService({ header: 'Something went wrong', type: ENotificationType.ERROR });
    closeModal();
  }, [isInitialDataFetchingError]);

  const handleCreateTicketSubmit: SubmitHandler<ICreateTicketFormData> = async (data) => {
    const payload: ICreateTicketRequestPayload = { ...data, baseIssueUuid, repoUuid, ticketManager };
    createIssueTicket.mutate(payload, {
      onSuccess: () => {
        if (baseIssueUuid) {
          queryClient.invalidateQueries('issues');
          queryClient.invalidateQueries(['issueDetails', { issueUuid: baseIssueUuid }]);
        }
        if (repoUuid) {
          queryClient.invalidateQueries(['repoTicket', repoUuid]);
        }
        toastService({
          header: 'Ticket created successfully',
          type: ENotificationType.SUCCESS,
        });
        closeModal();
      },
      onError: () => {
        toastService({
          header: 'An error occurred while creating ticket',
          type: ENotificationType.ERROR,
        });
      },
    });
  };

  const projectsOptions = projects?.map((project) => ({ name: project.name, value: project.id })) || [];
  const renderProjectSelect = ({ field: { onChange, onBlur, value }, fieldState: { error } }: UseControllerReturn<ICreateTicketFormData, 'projectId'>) => (
    <DropdownSelect name="projectId" label="Project" placeholder="Select project" value={value} options={projectsOptions} error={error?.message} onBlur={onBlur} onChange={onChange} />
  );

  const assigneesOptions = projectResources?.users?.map((user) => ({ name: user.name, value: user.id })) || [];
  const renderAssigneeSelect = ({ field: { onChange, onBlur, value }, fieldState: { error } }: UseControllerReturn<ICreateTicketFormData, 'assigneeId'>) => (
    <DropdownSelect
      name="assigneeId"
      label="Assignee"
      placeholder="Select assignee"
      disabled={isFetchingProjectResources}
      loading={isFetchingProjectResources}
      value={value}
      options={assigneesOptions}
      error={error?.message}
      onBlur={onBlur}
      onChange={onChange}
    />
  );

  const issueTypesOptions = projectResources?.issueTypes?.map((issueType) => ({ name: issueType.name, value: issueType.id })) || [];
  const renderIssueTypeSelect = ({ field: { onChange, onBlur, value }, fieldState: { error } }: UseControllerReturn<ICreateTicketFormData, 'issueTypeId'>) => (
    <DropdownSelect
      name="issueTypeId"
      label="Issue type"
      placeholder="Select issue type"
      disabled={isFetchingProjectResources}
      loading={isFetchingProjectResources}
      value={value}
      options={issueTypesOptions}
      error={error?.message}
      onBlur={onBlur}
      onChange={onChange}
    />
  );

  if (isLoadingProjects || isLoadingTicketDescription || isInitialDataFetchingError) {
    return <Loader />;
  }

  const isSubmitDisabled = !isValid || !!Object.keys(errors).length || isFetchingProjectResources || createIssueTicket.isLoading;

  return (
    <CreateTicketFormStyled onSubmit={handleSubmit(handleCreateTicketSubmit)}>
      <ControlInputBlockStyled margin={2}>
        <InputLabel htmlFor="summary">Summary</InputLabel>
        <ControlInputContainer $focused={false} $error={!!errors.summary}>
          <Controller
            name="summary"
            control={control}
            rules={{ required: 'Please provide a summary for ticket' }}
            render={({ fieldState, field: { onChange, value } }) => <TicketSummaryInput value={value} onChange={onChange} isError={!!fieldState.error} />}
          />
        </ControlInputContainer>
        <ErrorMessage errors={errors} name="summary" render={ControlErrorMessage} />
      </ControlInputBlockStyled>
      <ControlInputBlockStyled margin={2}>
        <Flex justifyContent="space-between" gap={3}>
          <Controller name="projectId" control={control} rules={{ required: 'Please select project for ticket' }} render={renderProjectSelect} />
          <Controller name="assigneeId" control={control} render={renderAssigneeSelect} />
        </Flex>
      </ControlInputBlockStyled>
      <ControlInputBlockStyled margin={2}>
        <Controller name="issueTypeId" control={control} rules={{ required: 'Please select issue type for ticket' }} render={renderIssueTypeSelect} />
      </ControlInputBlockStyled>
      <ControlInputBlockStyled margin={2} gap={0.5}>
        <InputLabel>Description</InputLabel>
        <TicketDescription markdown={ticketDescription?.description} />
      </ControlInputBlockStyled>
      <Flex flexDirection="row" justifyContent="flex-end" gap={1}>
        <CancelButton type="button" onClick={closeModal}>
          Cancel
        </CancelButton>
        <SubmitButton type="submit" disabled={isSubmitDisabled}>
          Create
        </SubmitButton>
      </Flex>
    </CreateTicketFormStyled>
  );
};
