import { useTheme } from 'styled-components';

import { useFilter } from 'core/Filter';
import { isEmpty } from '@Utils';
import { DashboardCardStyled } from '../styled';
import { useRepositoriesLanguages } from '../hooks';
import { getLanguageInUseOptions } from '../config';
import { Chart } from './Chart';

export const LanguagesInUse = () => {
  const theme = useTheme();
  const { appliedFilters } = useFilter();
  const { data, isLoading } = useRepositoriesLanguages({ filter: appliedFilters });

  const languages = data || {};

  const series = !isEmpty(data)
    ? Object.keys(languages)
        .map((language) => ({
          name: language,
          data: [languages[language]],
        }))
        .sort((a, b) => b.data[0] - a.data[0])
        .slice(0, 10)
    : [{ data: [null] }];

  const totalLanguagesCount = Object.values(languages).reduce((acc, curr) => acc + curr, 0);

  const options = getLanguageInUseOptions({ theme, totalLanguagesCount });

  return (
    <DashboardCardStyled $paddingBottom={theme.spacing[2]}>
      <Chart isLoading={isLoading} options={options} series={series} type="bar" width={'100%'} height={'100px'} maxPrintWidth={'818px'} isLanguageInUse />
    </DashboardCardStyled>
  );
};
