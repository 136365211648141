import { useMutation, useQueryClient } from 'react-query';
import { ignoreIssue } from '../api';
import { IIgnoreIssueRequestPayload } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useIgnoreIssue = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueId, data }: { issueId: string; data: IIgnoreIssueRequestPayload }) => {
      if (isAuthenticated && !isSessionLoading) {
        return ignoreIssue(sessionToken, issueId, data);
      }
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('issueDetails');
      },
    },
  );
};
