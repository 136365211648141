import { EReachabilityStatus, ESeverityLevel } from '@blindspot/common/types/bff';
import { IScanConfigPRChecksValueFailConditions } from '@blindspot/common/types/bff/configs';

export const failConditionsOptions: { value: IScanConfigPRChecksValueFailConditions; name: string }[] = [
  {
    value: { severity: [ESeverityLevel.CRITICAL, ESeverityLevel.HIGH], reachability: [EReachabilityStatus.REACHABLE, EReachabilityStatus.UNDEFINED] },
    name: 'Severity: (High or Critical) & Reachability: (Reachable or Undetermined)',
  },
  {
    value: { severity: [ESeverityLevel.CRITICAL], reachability: [EReachabilityStatus.REACHABLE, EReachabilityStatus.UNDEFINED] },
    name: 'Severity: (Critical) & Reachability: (Reachable or Undetermined)',
  },
  { value: { severity: [ESeverityLevel.CRITICAL, ESeverityLevel.HIGH] }, name: 'Severity: (High or Critical)' },
  { value: { severity: [ESeverityLevel.HIGH] }, name: 'Severity: (High)' },
];
