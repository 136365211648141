import { ModalStandard, useModal } from '@Modal';
import { Button, ButtonColor, ButtonVariant, ButtonSize, Loader } from '@Components';
import { InfoModalContentStyled, ModalContentStyled, ActionContainerStyled, LoaderContainerStyled } from './styled';
import { IIntegrationModalProps } from './types';
import { BackLink, IntegrationTitle } from './components';

export const IntegrationModal = ({
  title,
  titleSign,
  info,
  inputs,
  connectFlowSelection,
  formData,
  nextButtonType = 'button',
  nextButtonTitle = 'Next',
  isLoading = false,
  backButtonTitle = 'Back',
  nextButtonDisabled = false,
  onSubmit,
  onNextClickHandler = () => undefined,
  onBackClickHandler,
  hideActions = false,
}: IIntegrationModalProps) => {
  const { closeModal } = useModal();
  const { handleSubmit = () => undefined } = formData || {};

  return (
    <ModalStandard
      title={<IntegrationTitle titleSign={titleSign} title={title} />}
      content={
        <ModalContentStyled as={'form'} onSubmit={handleSubmit(onSubmit)}>
          {onBackClickHandler && <BackLink onClick={onBackClickHandler} title={backButtonTitle}></BackLink>}
          {inputs && inputs}
          {info && <InfoModalContentStyled>{info}</InfoModalContentStyled>}
          {connectFlowSelection && connectFlowSelection}
          {!hideActions && (
            <ActionContainerStyled>
              <Button onClick={closeModal} color={ButtonColor.ZINC} variant={ButtonVariant.OUTLINED} size={ButtonSize.LARGE} type={'button'}>
                Cancel
              </Button>
              <Button onClick={onNextClickHandler} variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE} type={nextButtonType} disabled={nextButtonDisabled}>
                {nextButtonTitle}
              </Button>
            </ActionContainerStyled>
          )}
          {isLoading && (
            <LoaderContainerStyled>
              <Loader />
            </LoaderContainerStyled>
          )}
        </ModalContentStyled>
      }
    />
  );
};
