import { Flex } from '@Styles';
import styled from 'styled-components';

export const DrawerApiChangesContainerStyled = styled(Flex)`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.black};
  flex-direction: column;
  & > span {
    font-size: ${({ theme }) => theme.text.size.overline};
    font-weight: ${({ theme }) => theme.text.weight.bold};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    opacity: 0.5;
  }
  padding: 0 ${({ theme }) => theme.spacing[4]};

  padding-bottom: ${({ theme }) => theme.spacing[3]};
  border-bottom: 1px solid rgba(161, 161, 170, 0.25);
`;
