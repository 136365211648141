import { Flex } from '@Styles';
import styled from 'styled-components';

export const FailedPullRequestsAlertStyled = styled(Flex)`
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.rose[600]}14;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.body2};
`;
