import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationSummaryIssuesContainerStyled = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.zinc[300]};
  border-radius: 10px 10px 25px 10px;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
  height: 75px;
  width: 199px;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
`;
