import React, { ReactNode, useState } from 'react';
import { IModalProviderProps } from './interfaces';
import { ModalContext } from './ModalContext';
import { ModalWindow } from '../ModalWindow';

export const ModalProvider: React.FC<IModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);

  const openModal = (content: ReactNode) => {
    setContent(content);
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <ModalWindow isOpen={isOpen} content={content} closeModal={closeModal} />
      {children}
    </ModalContext.Provider>
  );
};
