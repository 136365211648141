import styled from 'styled-components';

export const GrayTextStyled = styled.span`
  color: ${({ theme }) => theme.colors.zinc[900]};
  text-align: center;
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  line-height: 22.4px;
  letter-spacing: 0.4px;
  opacity: 0.74;
`;
