import React from 'react';
import { EScanConfigName, EScanConfigMonitoringEngine, IScanConfigMonitoringEngineValue } from '@blindspot/common/types/bff/configs';
import { IndentedBlock, SectionSubtitleStyled, SectionTitleStyled } from '../../styled';
import { IMonitoringEngineSettingsProps } from './interfaces';
import { MonitoringEngineSettingsStyled, MonitoringEngineOptionsWrapperStyled, EngineSectionStyled, EnginesContainerStyled } from './styled';
import { firstPartyMonitoringEngineOptions, monitoringEngineOptions } from './constants';
import { MonitoringEngineOption } from './components';

export const MonitoringEngineSettings: React.FC<IMonitoringEngineSettingsProps> = ({ configValue, setDraftUpdates }) => {
  const onUpdateMonitoringEngineConfig = (monitoringEngine: EScanConfigMonitoringEngine) => {
    setDraftUpdates((prevState) => ({
      ...prevState,
      configs: prevState.configs.map((prevConfig) => {
        if (prevConfig.name !== EScanConfigName.MONITORING_ENGINE) {
          return prevConfig;
        }
        const prevConfigValue = prevConfig.value as IScanConfigMonitoringEngineValue;
        return {
          ...prevConfig,
          value: {
            ...prevConfigValue,
            [monitoringEngine]: !prevConfigValue[monitoringEngine],
          },
        };
      }),
    }));
  };
  return (
    <MonitoringEngineSettingsStyled>
      <IndentedBlock>
        <SectionTitleStyled>Select engines</SectionTitleStyled>
        <SectionSubtitleStyled>
          Choose detection engines to scan your repository on every code change.
          <br />
          Select <b>Tampering and Risky Code</b> for identifying malicious alterations, <b>Supply Chain</b> for a detecting malicious packages, <b>SCA</b> for detecting and prioritizing
          vulnerabilities, or all for thorough scanning.
        </SectionSubtitleStyled>
        <EnginesContainerStyled>
          <EngineSectionStyled>
            <b>1st Party Code</b>
            <MonitoringEngineOptionsWrapperStyled>
              {firstPartyMonitoringEngineOptions.map(({ value, title, description }) => (
                <MonitoringEngineOption
                  key={value}
                  name={value}
                  value={!!configValue?.[value]}
                  title={title}
                  description={description}
                  onChange={() => onUpdateMonitoringEngineConfig(value)}
                />
              ))}
            </MonitoringEngineOptionsWrapperStyled>
          </EngineSectionStyled>
          <EngineSectionStyled>
            <b>3rd Party Code</b>
            <MonitoringEngineOptionsWrapperStyled>
              {monitoringEngineOptions.map(({ value, title, description }) => (
                <MonitoringEngineOption
                  key={value}
                  name={value}
                  value={!!configValue?.[value]}
                  title={title}
                  description={description}
                  onChange={() => onUpdateMonitoringEngineConfig(value)}
                />
              ))}
            </MonitoringEngineOptionsWrapperStyled>
          </EngineSectionStyled>
        </EnginesContainerStyled>
      </IndentedBlock>
    </MonitoringEngineSettingsStyled>
  );
};
