import styled from 'styled-components';

export const SliderTrackStyled = styled.div<{ $index?: number; $value?: number | number[] }>`
  position: relative;
  height: ${({ theme }) => theme.spacing['0.5']};
  border-radius: ${({ theme }) => theme.spacing['1.5']};
  background: ${({ theme, $index }) => ($index === 1 ? theme.colors.zinc[300] : theme.colors.indigo[500])};
  opacity: ${({ theme, $index }) => ($index === 1 ? 0.38 : 1)};
  height: 6px;
`;
