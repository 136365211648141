import { DrawerContainerStyled, HeaderContainerStyled, IssueDetailsOverviewHeaderContainerStyled } from './styled';
import { IssueDrawerActionButtons } from '.';
import { IssueDetailsOverview, SeverityLabelStyled } from '../issueDetails/styled';
import { IFirstPartyIssueDrawerProps } from './types';
import { InfoLabel, InfoLabelTypes } from '@Components';
import { Flex } from '@Styles';
import { IssueIgnored, IssueTitleByCategory } from '../issueDetails/components';
import { IssueRecommendationLabelStyled } from '../issueDetails/components/IssueRecommendation/styled/styled';
import { EIssueSubType } from '@APITypes';
import { SastIssueDetailsAdditional } from '../issueDetails/IssueDetailsAdditional/SastIssueDetailsAdditional';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { ISastIssue } from '../IssuesTable1stParty';
import { useIgnoreSastIssueByFingerprint } from './hooks';
import { DrawerNavigator } from 'Layouts/components/DrawerNavigator';
import { useModal } from '@Modal';
import { IgnoreIssueModal } from '../issueDetails/IssueNavContainer';
import { EIssueSourceType } from '@blindspot/common/types/bff';
import { useQueryClient } from 'react-query';

export const FirstPartyIssueDrawer = ({ issues }: IFirstPartyIssueDrawerProps) => {
  const [searchParams, setUrlSearchParams] = useSearchParams();
  const issueFingerprint = searchParams.get('openIssue');
  const findCurrentIssuePredicate = useCallback((issue: ISastIssue) => issue.fingerprint === issueFingerprint, [issueFingerprint]);
  const currentIssue = issues.find(findCurrentIssuePredicate);
  const currentIndex = issues.findIndex(findCurrentIssuePredicate);
  const queryClient = useQueryClient();
  const { mutate: handleIgnoreSastIssueMutate, isLoading: isMutatingSast } = useIgnoreSastIssueByFingerprint(issueFingerprint, {
    onSuccess: () => {
      setIsIgnored(!isIgnored);
      queryClient.invalidateQueries(['issues', 'summary']);
      queryClient.invalidateQueries(['sastIssueIgnoreMetadata']);
    },
  });
  const [isIgnored, setIsIgnored] = useState<boolean>(currentIssue?.ignored ?? false);
  const { openModal } = useModal();

  if (!currentIssue) {
    return null;
  }
  const isFixed = currentIssue.isFixed < 1;

  const handleClickPrev = () => {
    if (currentIndex - 1 >= 0) {
      const prevIssue = issues[currentIndex - 1];
      setUrlSearchParams((prev) => {
        prev.set('openIssue', prevIssue.fingerprint);
        return prev.toString();
      });
    }
  };
  const handleClickNext = () => {
    if (currentIndex + 1 < issues.length) {
      const nextIssue = issues[currentIndex + 1];
      setUrlSearchParams((prev) => {
        prev.set('openIssue', nextIssue.fingerprint);
        return prev.toString();
      });
    }
  };

  const handleChangeDirection = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      handleClickPrev();
    } else {
      handleClickNext();
    }
  };

  // TODO: Implement these functions
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleOpenTicket = () => {};

  const handleIgnoreIssue = () => {
    isIgnored
      ? handleIgnoreSastIssueMutate({ ignored: !isIgnored, reason: undefined })
      : openModal(<IgnoreIssueModal isSast isSastIgnored={isIgnored} handleSastIgnore={handleIgnoreSastIssueMutate} />);
  };

  return (
    <DrawerContainerStyled>
      <HeaderContainerStyled>
        <DrawerNavigator currentIndex={currentIndex} onChangeDirection={handleChangeDirection} total={issues.length} />
        <IssueDrawerActionButtons isMutating={isMutatingSast} ignored={isIgnored} onIgnoreIssue={handleIgnoreIssue} onOpenTicket={handleOpenTicket} />
      </HeaderContainerStyled>
      <IssueDetailsOverview $severity={currentIssue.severity}>
        <IssueDetailsOverviewHeaderContainerStyled flexDirection="row" justifyContent="space-between">
          <Flex flexDirection="row" justifyContent="flex-start" alignItems="center" gap={1}>
            <SeverityLabelStyled>
              <InfoLabel capitalize type={currentIssue.severity}>
                {currentIssue.severity}
              </InfoLabel>
            </SeverityLabelStyled>
            {isFixed ? (
              <SeverityLabelStyled>
                <InfoLabel type={InfoLabelTypes.Fixed}>Fixed</InfoLabel>
              </SeverityLabelStyled>
            ) : (
              <SeverityLabelStyled>
                <InfoLabel noContainer>Unresolved</InfoLabel>
              </SeverityLabelStyled>
            )}
          </Flex>
          <IssueIgnored issueSourceType={EIssueSourceType.FIRST_PARTY} isIgnored={isIgnored} />
        </IssueDetailsOverviewHeaderContainerStyled>
        <IssueTitleByCategory issue={currentIssue} />
        <InfoLabel capitalize type={InfoLabelTypes.Bordered}>
          <IssueRecommendationLabelStyled>{EIssueSubType.VULNERABILITY}</IssueRecommendationLabelStyled>
        </InfoLabel>
      </IssueDetailsOverview>
      <SastIssueDetailsAdditional issue={currentIssue} />
    </DrawerContainerStyled>
  );
};
