import styled from 'styled-components';

export const SubTitle = styled.span<{ $color?: string }>`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[0.5]};
  font-size: ${({ theme }) => theme.text.size['caption']};

  ${(props) => {
    switch (props.$color) {
      case 'integrated':
        return `
          color: ${props.theme.colors.green['600']};
        `;
      case 'failed':
        return `
          color: ${props.theme.colors.rose['600']};
        `;
      case 'pending':
        return `
          color: ${props.theme.colors.zinc['600']};
        `;
      case 'expiration':
        return `
          color: ${props.theme.colors.zinc['400']};
        `;
      default:
        return `
          color: ${props.theme.colors.green['600']};
        `;
    }
  }}
  &[data-icon] {
    padding: ${({ theme }) => theme.spacing['0.5']} 0;
    font-size: ${({ theme }) => theme.text.size['caption']};

    &:before {
      margin-right: ${({ theme }) => theme.spacing['0.5']};
    }
  }
`;
