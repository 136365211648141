import React, { useCallback, useState } from 'react';
import { Tooltip } from 'Layouts/components/Tooltip';
import { SelectOption } from '../styled/SelectOption.styled';
import { DropdownOptionProps } from '../interfaces';

export const DropdownOption: React.FC<DropdownOptionProps> = ({ option, onClick }) => {
  return (
    <Tooltip delayShowMs={500} fullWidth tooltip={<>{option.name || option.value}</>}>
      <SelectOption type="button" value={option.value} onClick={onClick}>
        {option.name || option.value}
        {option.label && option.label}
      </SelectOption>
    </Tooltip>
  );
};
