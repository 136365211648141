import { formatNumberWithCommas } from '@Utils';

export const getChartTooltip = (theme) => ({
  tooltip: {
    enabled: true,
    style: {
      fontSize: theme.text.size.overline,
      fontFamily: theme.text.fontFamily,
    },
    x: {
      show: false,
    },
    y: {
      formatter: (value) => formatNumberWithCommas(value),
    },
  },
});
