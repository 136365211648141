import styled, { css } from 'styled-components';

export const TableRowStyled = styled.tr<{ $isAction?: boolean; $highlight?: boolean }>`
  color: ${({ theme }) => theme.colors.zinc[700]};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  min-height: 50px;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.colors.zinc[200]};
  cursor: ${({ $isAction }) => ($isAction ? 'pointer' : 'auto')};
  ${({ theme, $highlight }) => ($highlight ? `background-color: ${theme.colors.indigo[100]};` : '')};
  ${({ $isAction }) =>
    $isAction &&
    css`
      &:hover {
        background: ${({ theme }) => `${theme.colors.zinc[100]}`};
      }
    `}

  svg {
    color: ${({ theme }) => theme.colors.zinc[500]};
  }
`;
