import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { TabPages } from '@Components';
import { useReachabilityIssue } from '../hooks';
import { DividerStyled } from './styled';

export const ReachabilityPaths = () => {
  const { baseIssueUuid, issueUuid } = useParams();
  const { data: reachabilityIssue } = useReachabilityIssue({ baseIssueUuid, issueUuid });

  const tabs = useMemo(() => {
    return [
      {
        title: 'Path Graph',
        key: 'paths',
        linkTo: `/issues/${baseIssueUuid}/reachabilities/${issueUuid}/paths`,
      },
    ];
  }, [baseIssueUuid, issueUuid]);

  if (!reachabilityIssue?.chains?.length) {
    return null;
  }

  return (
    <div>
      <DividerStyled />
      <TabPages items={tabs} />
      <Outlet />
    </div>
  );
};
