import { ApexOptions } from 'apexcharts';
import { DefaultTheme } from 'styled-components';
import { chartAnimationOptions } from './chartAnimationOptions';
import { getChartTitleOptions } from './getChartTitleOptions';
import { getChartLegendOptions } from './getChartLegendOptions';
import { getChartTooltip } from './getChartTooltip';
import { pageTools } from '@Utils';
import { EIssueGroup } from 'Layouts/IssuesPage/interfaces';
import { NavigateFunction } from 'react-router-dom';
import { EIssueSubType } from '@APITypes';

enum EIssueCategory {
  TAMPERING = 'Tampering',
  VULNERABILITY = 'Vulnerability',
  RISKY_CODE = 'Risky code',
  MALICIOUS_PACKAGE = 'Malicious package',
}

const IssuesCategoryLinks = {
  [EIssueCategory.TAMPERING]: `${pageTools.issues.path}/${EIssueGroup.ALL}?filter[category]=${EIssueSubType.TAMPERING}`,
  [EIssueCategory.VULNERABILITY]: `${pageTools.issues.path}/${EIssueGroup.ALL}?filter[category]=${EIssueSubType.VULNERABILITY}`,
  [EIssueCategory.RISKY_CODE]: `${pageTools.issues.path}/${EIssueGroup.ALL}?filter[category]=${EIssueSubType.RISKY_CODE}`,
  [EIssueCategory.MALICIOUS_PACKAGE]: `${pageTools.issues.path}/${EIssueGroup.ALL}?filter[category]=${EIssueSubType.MALICIOUS_PACKAGE}`,
};

export const getIssuesByCategoryOptions = ({ theme, navigate }: { theme: DefaultTheme; navigate: NavigateFunction }) => {
  return {
    ...getChartTitleOptions(theme, 'Issues by category', true),
    ...getChartLegendOptions(theme, true),
    ...getChartTooltip(theme),
    chart: {
      events: {
        xAxisLabelClick: function (event) {
          if (event.target.textContent in IssuesCategoryLinks) {
            const route = IssuesCategoryLinks[event.target.textContent];
            navigate(route);
          }
        },
      },
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      animations: chartAnimationOptions,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: 12,
        borderRadius: 6,
      },
    },
    colors: [theme.colors.zinc[200], theme.colors.zinc[400], theme.colors.orange[500], theme.colors.rose[600]],
    xaxis: {
      categories: [EIssueCategory.TAMPERING, EIssueCategory.VULNERABILITY, EIssueCategory.RISKY_CODE, EIssueCategory.MALICIOUS_PACKAGE],
      title: {
        text: 'issues',
        style: {
          color: theme.colors.zinc[400],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
      labels: {
        show: true,
        align: 'right',
        style: {
          colors: [theme.colors.zinc[400]],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
          textDecoration: 'underline',
        },
      },
      axisBorder: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: 'right',
        style: {
          colors: [theme.colors.black],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    grid: {
      show: false,
    },
  } as ApexOptions;
};
