import React from 'react';
import { ModalStandard, useModal } from '@Modal';
import { ActionContainer, GitLabModalTextStyled, GitLabModalTitleStyled, InfoGitLabModalContent, ModalContent } from './styled';
import { InputGitLabTokenOrganizationModal } from './InputGitLabTokenOrganizationModal';
import { GitLabModalTitle } from './GitLabModalTitle';
import { Button, ButtonColor, ButtonVariant, ButtonSize } from '@Components';

export const InfoGitLabTokenOrganizationModal: React.FC = () => {
  const { closeModal, openModal } = useModal();
  const onNextClickHandler = () => {
    openModal(<InputGitLabTokenOrganizationModal />);
  };

  return (
    <ModalStandard
      title={<GitLabModalTitle />}
      content={
        <ModalContent>
          <InfoGitLabModalContent>
            <GitLabModalTitleStyled>What is GitLab?</GitLabModalTitleStyled>
            <GitLabModalTextStyled>
              GitLab is a web-based platform that provides a complete set of tools for software development, continuous integration, and collaboration. It is widely used for version control,
              code review, issue tracking, and more.
            </GitLabModalTextStyled>
          </InfoGitLabModalContent>
          <ActionContainer>
            <Button onClick={closeModal} color={ButtonColor.ZINC} variant={ButtonVariant.OUTLINED} size={ButtonSize.LARGE}>
              Cancel
            </Button>
            <Button onClick={onNextClickHandler} variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE}>
              Next
            </Button>
          </ActionContainer>
        </ModalContent>
      }
    />
  );
};
