import styled from 'styled-components';

export const DrawerWindowContainer = styled.div<{ $open: boolean; $transitioning: boolean; $transitionSpeedMs: number }>`
  background: ${({ theme }) => theme.colors.white};
  max-width: 50vw;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  box-shadow: -5px 0px 15px rgba(18, 25, 66, 0.08);
  transition: transform ${({ $transitionSpeedMs }) => $transitionSpeedMs}ms ease;
  z-index: 101;

  top: 0;
  right: 0;
  transform: translateX(${({ $open, $transitioning }) => ($open && $transitioning ? '0' : '100%')});
`;
