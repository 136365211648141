import { useParams } from 'react-router-dom';
import ReactFlow, { Controls, useNodesState, useEdgesState, getNodesBounds } from 'reactflow';
import { useEffect, useState } from 'react';
import 'reactflow/dist/style.css';

import { useReachabilityIssue } from '../hooks';
import { createNodesAndEdges } from './utils';
import { DEFAULT_GRAPH_HEIGHT, INIT_BOUNDS, NODE_HEIGHT, NODE_TYPES } from './types';
import { IssueDependencyChainStyled } from './styled';
import { DownloadButton } from './DownloadButton';
import { ExpandGraphButton, getLayoutedElements, DEFAULT_GRAPH_PROPS, EFlowLayout } from 'Layouts/components/Graph';

export const IssueDependencyChain = () => {
  const { baseIssueUuid, issueUuid } = useParams();
  const { data: reachabilityIssue = null } = useReachabilityIssue({ baseIssueUuid, issueUuid });

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [bounds, setBounds] = useState(INIT_BOUNDS);
  const [height, setHeight] = useState(DEFAULT_GRAPH_HEIGHT);

  useEffect(() => {
    if (reachabilityIssue) {
      const { nodes: initialNodes, edges: initialEdges } = createNodesAndEdges(reachabilityIssue);
      const { nodes: loutedNodes, edges: loutedEdges } = getLayoutedElements(initialNodes, initialEdges, EFlowLayout.HORIZONTAL, NODE_HEIGHT);
      setBounds(getNodesBounds(loutedNodes));

      setNodes(loutedNodes);
      setEdges(loutedEdges);
    }
  }, [reachabilityIssue]);

  if (!reachabilityIssue?.dependencyChain) {
    return null;
  }

  const onGraphExpandToggle = () => {
    if (height > DEFAULT_GRAPH_HEIGHT) {
      setHeight(DEFAULT_GRAPH_HEIGHT);
    } else {
      setHeight(bounds.height);
    }
  };

  return (
    <IssueDependencyChainStyled $height={height}>
      <ReactFlow {...DEFAULT_GRAPH_PROPS} nodes={nodes} edges={edges} nodeTypes={NODE_TYPES}>
        <Controls showInteractive={false} position={'bottom-right'}>
          {bounds.height > DEFAULT_GRAPH_HEIGHT && <ExpandGraphButton onClick={onGraphExpandToggle} isExpanded={height > DEFAULT_GRAPH_HEIGHT} />}
        </Controls>
        {/* <DownloadButton /> */}
      </ReactFlow>
    </IssueDependencyChainStyled>
  );
};
