import React, { useState } from 'react';
import { Button } from '@Styles';
import { CreateSbomDialog } from './CreateSbomDialog';
import { CreateSbomIcon } from './styled';

export const CreateSbomButton: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button onClick={openDialog}>
        <CreateSbomIcon />
        Create SBOM
      </Button>
      {isDialogOpen && <CreateSbomDialog onClose={closeDialog} />}
    </>
  );
};
