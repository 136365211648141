import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pageTools } from '@Utils';
import { HeaderStyled, HeaderTitleStyled } from './styled';

interface IProps {
  emptyTitle?: boolean;
}

export const EmptyTitleHeader = () => (
  <HeaderStyled>
    <HeaderTitleStyled />
  </HeaderStyled>
);

export const Header: React.FC<IProps> = ({ emptyTitle = false }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathArray = useMemo(() => pathname.split('/').filter((p) => !!p), [pathname]);
  const pathName = pathArray[0];

  useEffect(() => {
    if (pathArray?.length === 0) {
      navigate(`${pageTools.dashboard.path}`);
    }
  }, [navigate, pathArray, pathname]);

  if (emptyTitle) {
    return <EmptyTitleHeader />;
  }

  return (
    <HeaderStyled>
      <HeaderTitleStyled>{pageTools[pathName]?.title || ''}</HeaderTitleStyled>
      {pathArray.length > 1 && <p>/</p>}
    </HeaderStyled>
  );
};
