import { useTheme } from 'styled-components';

import { isEmpty } from '@Utils';
import { useFilter } from 'core/Filter';
import { DashboardCardStyled } from '../styled';
import { useRepositoriesIssues } from '../hooks';
import { getRiskiestRepositoryOptions } from '../config';
import { formatRiskiestResponseSeries, sortRiskiestRepositoryData } from '../utils';
import { Chart } from './Chart';
import { useNavigate } from 'react-router-dom';

const defaultSeries = [{ data: [] }];

export const RiskiestRepository = () => {
  const theme = useTheme();
  const { appliedFilters } = useFilter();
  const { data, isLoading: isIssuesLoading } = useRepositoriesIssues({ filter: appliedFilters });

  const navigate = useNavigate();

  const dataExists = !isEmpty(data);

  const sortedData = dataExists ? sortRiskiestRepositoryData(data) : {};

  const series = dataExists ? formatRiskiestResponseSeries(sortedData, theme) : defaultSeries;
  const categories = dataExists ? Object.keys(sortedData) : [];

  const options = getRiskiestRepositoryOptions({ theme, categories, data, navigate });

  return (
    <DashboardCardStyled $maxHeight={'410px'} $paddingBottom={theme.spacing[1]}>
      <Chart isLoading={isIssuesLoading} isInteractiveLabels options={options} series={series} type="bar" width={'100%'} height={'360px'} />
    </DashboardCardStyled>
  );
};
