import styled, { css } from 'styled-components';

export const TooltipContainer = styled.span(
  ({ $fullWidth }: { $fullWidth: boolean }) => css`
    display: inline-block;

    ${$fullWidth &&
    css`
      display: flex;
      flex: 1;
    `}
  `,
);
