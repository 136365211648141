import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const ModalContentStyled = styled(Grid)(
  ({ theme }) => css`
    row-gap: ${theme.spacing[2]};
    width: 659px;
    max-height: 250px;
  `,
);
