import styled from 'styled-components';
import { Flex } from '@Styles';
import { FilePath } from './FilePath.styled';

export const FileCell = styled(Flex)`
  gap: ${({ theme }) => theme.spacing['0.5']};
  width: 114px;

  &:hover ${FilePath} {
    text-decoration: underline;
  }
`;
