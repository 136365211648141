import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationEffortGroupContainerStyled = styled(Flex)<{ $isOpen?: boolean }>`
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing[2.5]};
  height: 120px;
  width: 100%;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${({ theme, $isOpen }) => !$isOpen && `background-color: ${theme.colors.zinc[100]};`}
  }

  border-bottom: 1px solid ${({ theme }) => theme.colors.zinc[300]};
`;
