import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ActionsContainerStyled = styled(Flex)(
  ({ theme }) => css`
    width: 200px;
    justify-self: end;
    justify-content: space-between;
    margin-top: ${theme.spacing[1.5]};
    gap: ${theme.spacing[1]};
  `,
);
