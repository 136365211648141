import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const ScanConfigSetStyled = styled(Grid)(
  ({ theme }) => css`
    padding: 19px 61px 0 21px;
    grid-template-rows: repeat(4, auto) 1fr 52px;
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 24px;
    letter-spacing: 0.2px;
  `,
);
