import { IApiIssueCountByDepTreeLevels } from '../interfaces';

export const formatIssuesSeries = (data: IApiIssueCountByDepTreeLevels) => {
  const { total, severe, reachableSevere } = data;
  return [
    [total.direct, total.indirect],
    [severe.direct, severe.indirect],
    [reachableSevere.direct, reachableSevere.indirect],
  ];
};
