import styled from 'styled-components';

export const SliderContainerStyled = styled.div`
  margin-top: ${({ theme }) => theme.spacing[6]};

  & .slider-thumb {
    z-index: 0 !important;
    top: -40px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 11px;
    cursor: pointer;
  }
`;
