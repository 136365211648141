import React from 'react';
import { ScanConfigDescriptionStyled } from '../styled';
import { IScanConfigDependenciesInventoryValue } from '@blindspot/common/types/bff/configs';
import { dependenciesInventoryFileOptions } from 'Layouts/SettingsPage/components/ScanConfigs/components/ScanConfigDetails/components/DependenciesInventorySettings/constants';

export const DependenciesInventoryConfigSummary: React.FC<{ configValue: IScanConfigDependenciesInventoryValue }> = ({ configValue }) => {
  if (!configValue) {
    return null;
  }

  const file = dependenciesInventoryFileOptions.find((option) => option.value === configValue.file)?.title;

  return <ScanConfigDescriptionStyled>Dependencies Inventory File: {file}</ScanConfigDescriptionStyled>;
};
