import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { IPaginationProps } from './types';
import { PaginationButtonStyled, PaginationButtonsStyled, PaginationPagesStyled, PaginationRowStyled, PaginationStyled } from './styled';
import { PaginationWrapperStyled } from 'core/Table/styled/pagination/PaginationWrapper.styled';
import { Select } from 'core/Table/TableSelect';
import { SelectPosition, SelectStyleMode } from 'core/Table/types';
import { rowsInPageOptions } from './config';

export const Pagination: React.FC<IPaginationProps> = ({ pageIndex, pageSize, pageCount, itemCount, setPagination }) => {
  const currentPage = pageIndex + 1;
  const pageItems = pageIndex === 0 ? pageSize : currentPage * pageSize;
  const pageItemsFrom = pageItems - pageSize + 1;
  const pageItemsTo = pageItems <= itemCount ? pageItems : itemCount;

  const updatePageIndex = (currentPageIndex: number) => setPagination((pagination) => ({ ...pagination, pageIndex: currentPageIndex }));
  const updatePageSize = (currentPageSize: number) => setPagination((pagination) => ({ ...pagination, pageSize: currentPageSize }));

  return (
    <PaginationStyled>
      <PaginationRowStyled>
        <PaginationWrapperStyled>
          <span>Row per page:</span>
          <Select styleMode={SelectStyleMode.Empty} name={'inPage'} options={rowsInPageOptions} selectedOption={pageSize} openDirection={SelectPosition.Bottom} onChange={updatePageSize} />
          <PaginationPagesStyled>
            {`${pageItemsFrom}-${pageItemsTo}`} of {itemCount}
          </PaginationPagesStyled>
          {pageCount > 1 && (
            <PaginationButtonsStyled>
              <PaginationButtonStyled onClick={() => updatePageIndex(pageIndex - 1)} disabled={currentPage === 1}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </PaginationButtonStyled>
              <PaginationButtonStyled onClick={() => updatePageIndex(pageIndex + 1)} disabled={currentPage === pageCount}>
                <FontAwesomeIcon icon={faAngleRight} />
              </PaginationButtonStyled>
            </PaginationButtonsStyled>
          )}
        </PaginationWrapperStyled>
      </PaginationRowStyled>
    </PaginationStyled>
  );
};
