import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const TokenDetailsRowValueStyled = styled(Flex)(
  ({ theme }) => css`
    align-items: center;
    gap: ${theme.spacing[1]};
    word-break: break-all;
  `,
);
