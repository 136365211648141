import { EIssuesCategoryLabel } from '@ComponentsTypes';

export const issuesByCategoryDefaultSeries = [
  {
    name: EIssuesCategoryLabel.LOW,
    data: [0, 0, 0, 0],
  },
  {
    name: EIssuesCategoryLabel.MEDIUM,
    data: [0, 0, 0, 0],
  },
  {
    name: EIssuesCategoryLabel.HIGH,
    data: [0, 0, 0, 0],
  },
  {
    name: EIssuesCategoryLabel.CRITICAL,
    data: [0, 0, 0, 0],
  },
];
