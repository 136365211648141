import { EffortIcon } from '../EffortIcon';
import { ERemediationEffortLevels } from '@blindspot/common/types/bff/remediation';
import { EffortIconColorsMap } from '../types';

export const remediationEffortGroupConfig = () => ({
  [ERemediationEffortLevels.EASY]: {
    label: 'Easy to fix',
    description: `<b>Easy to fix</b> issues are issues that <b>have a newer version</b> that fixes the security issues, that version <b>doesn’t</b> require <b>source code changes </b>`,
    effortTimeText: 'Less than an hour',
    iconSet: (
      <>
        <EffortIcon color={EffortIconColorsMap.EASY} />
        <EffortIcon />
        <EffortIcon />
      </>
    ),
  },
  [ERemediationEffortLevels.MEDIUM]: {
    label: 'Medium fix',
    description: `Medium fix issue <b>have a newer version</b> that fixes the issues but also require <b>source code changes </b>`,
    effortTimeText: 'Less than a day',
    iconSet: (
      <>
        <EffortIcon color={EffortIconColorsMap.HARD} />
        <EffortIcon color={EffortIconColorsMap.HARD} />
        <EffortIcon />
      </>
    ),
  },
  [ERemediationEffortLevels.HARD]: {
    label: 'Hard to fix',
    description: `Hard to fix issues <b>have a newer version</b> that fixes the issues, but also requiree <b> many source code changes </b>`,
    effortTimeText: 'Less than a week',
    iconSet: (
      <>
        <EffortIcon color={EffortIconColorsMap.HARD} />
        <EffortIcon color={EffortIconColorsMap.HARD} />
        <EffortIcon color={EffortIconColorsMap.HARD} />
      </>
    ),
  },
});
