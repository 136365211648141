import { isEmpty } from '@Utils';
import { ISevereIssues } from '@APITypes';
import { CardInfoTextStyled } from '../styled';

export const RepositoryIssues = (props: ISevereIssues) => {
  if (isEmpty(props)) {
    return null;
  }
  const getValue = (name: string) => {
    const value = props[name];
    if (value) {
      return `${value} ${name}`;
    }
    return '';
  };
  const issuesValues = `${[getValue('critical'), getValue('high')].filter(Boolean).join(', ')}`;
  return (
    <CardInfoTextStyled>
      <b>Issues: </b>
      {issuesValues}
    </CardInfoTextStyled>
  );
};
