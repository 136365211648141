import React from 'react';
import { Loader } from '@Components';
import { ELoaderSize } from 'Layouts/components/Loader/types';
import { useRepositoryMonitorAll } from './hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRadar } from '@fortawesome/pro-light-svg-icons';
import { Button } from '@Styles';

export const MonitorAllButton: React.FC = () => {
  const { mutate: repositoryMonitorAll, isLoading } = useRepositoryMonitorAll();

  const onClickMonitorAll = () => repositoryMonitorAll();

  return (
    <Button onClick={onClickMonitorAll}>
      {isLoading ? (
        <Loader size={ELoaderSize.SMALL} />
      ) : (
        <>
          <FontAwesomeIcon icon={faRadar} />
          <span>Monitor all</span>
        </>
      )}
    </Button>
  );
};
