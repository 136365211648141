import { useMutation } from 'react-query';
import { updateUser } from '../api';
import { IUserUpdate } from '@APITypes';
import { useSession } from '@descope/react-sdk';

export const useUserUpdate = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async (update: IUserUpdate) => {
    if (isAuthenticated && !isSessionLoading) {
      return updateUser(update, sessionToken);
    }
  });
};
