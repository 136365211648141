import React from 'react';
import { RadioGroup } from '@FormElements';
import { EScanConfigName } from '@blindspot/common/types/bff/configs';
import { SectionSubtitleStyled, SectionTitleStyled } from '../../styled';
import { EmailUpdatesSettingsStyled } from './styled';
import { IEmailUpdatesSettingsProps } from './interfaces';
import { emailUpdatesOptions } from './constants';

export const EmailUpdatesSettings: React.FC<IEmailUpdatesSettingsProps> = ({ configValue, setDraftUpdates }) => {
  const onUpdateEmailUpdates = (value: string) => {
    setDraftUpdates((prevState) => ({
      ...prevState,
      configs: prevState.configs.map((prevConfig) => {
        if (prevConfig.name !== EScanConfigName.EMAIL_UPDATES) {
          return prevConfig;
        }
        return { ...prevConfig, value: JSON.parse(value) };
      }),
    }));
  };

  const emailUpdatesSelectOptions = emailUpdatesOptions.map((option) => ({ label: option.label, value: JSON.stringify(option.value) }));
  const selectedValue = configValue ? JSON.stringify(configValue) : emailUpdatesSelectOptions[0].value;

  return (
    <EmailUpdatesSettingsStyled>
      <SectionTitleStyled>Email updates</SectionTitleStyled>
      <SectionSubtitleStyled>
        Select events that would like to receive updates about.
        <br />
        Emails will be sent to all the registered users
      </SectionSubtitleStyled>
      <RadioGroup options={emailUpdatesSelectOptions} value={selectedValue} onChange={onUpdateEmailUpdates} />
    </EmailUpdatesSettingsStyled>
  );
};
