import React from 'react';
import { spacing } from '@Styles';
import { usePopperTooltip, Config, PopperOptions } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { TooltipContainer, TooltipBlock } from './styled';
import { ITooltipProps } from './interfaces';

export const Tooltip: React.FC<ITooltipProps> = ({ tooltip, placement = 'top', noWrap = true, fullWidth, children, delayShowMs = 100 }) => {
  const screenOverflowPaddingPx = parseInt(spacing['4'], 10);
  const popperConfig: Config = { placement, delayShow: delayShowMs };
  const popperOptions: PopperOptions = {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: screenOverflowPaddingPx,
        },
      },
    ],
  };
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(popperConfig, popperOptions);

  return (
    <>
      {visible && (
        <TooltipBlock ref={setTooltipRef} $noWrap={noWrap} {...getTooltipProps({ className: 'tooltip-container' })}>
          {tooltip}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </TooltipBlock>
      )}
      <TooltipContainer ref={setTriggerRef} $fullWidth={fullWidth}>
        {children}
      </TooltipContainer>
    </>
  );
};
