import { ContentBlock } from '@Styles';
import styled from 'styled-components';

export const Container = styled(ContentBlock)`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing['2']};
  grid-template-rows: auto auto;
  border-radius: 12px;
  padding: ${({ theme: { spacing } }) => `${spacing['2']} ${spacing['3']} ${spacing['3']}`};
  margin-bottom: ${({ theme }) => theme.spacing['2']};
`;
