import { SelectStyleMode } from 'core/Table/types';
import styled from 'styled-components';

export const SelectWrapperStyled = styled.div<{ $mode: SelectStyleMode }>`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 6px ${({ theme }) => theme.colors.zinc[300]};
  border-radius: 13px;
  display: block;

  ${({ $mode, theme }) => {
    if ($mode === SelectStyleMode.Additional) {
      return `
                background: ${theme.colors.zinc[700]};
                color: ${theme.colors.white};
                box-shadow: 0 0 6px ${theme.colors.indigo[900]};
            `;
    }

    if ($mode === SelectStyleMode.Empty) {
      return `
                background: transparent;
                color: ${theme.colors.zinc[700]};
                box-shadow: none;
            `;
    }
  }}
`;
