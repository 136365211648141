import { useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { TabPages } from '@Components';
import { TabContent } from '@Styles';
import { TabsContainer } from './styled';
import { DependencyDetailsAdditionalProps } from './interface';

export const DependencyDetailsAdditional = ({ isDiff }: DependencyDetailsAdditionalProps) => {
  const { depId } = useParams();
  const { pathname } = useLocation();
  let baseUrl = pathname;
  const splittedPath = baseUrl.split('/').reverse();

  if (splittedPath[0] === 'all') {
    baseUrl = splittedPath.slice(2).reverse().join('/');
  } else {
    baseUrl = splittedPath.slice(1).reverse().join('/');
  }

  const tabs = useMemo(() => {
    return [
      {
        title: 'Issues',
        key: 'issues',
        linkTo: `${baseUrl}/issues/all`,
      },
      {
        title: 'Repositories',
        key: 'repositories',
        linkTo: `${baseUrl}/repositories`,
      },
      {
        title: 'Dependencies Files',
        key: 'dependencies-files',
        linkTo: `${baseUrl}/dependencies-files`,
      },
    ];
  }, [depId]);

  return (
    <>
      <TabsContainer>
        <TabPages items={tabs} />
      </TabsContainer>
      <TabContent>
        <Outlet />
      </TabContent>
    </>
  );
};
