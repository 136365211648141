import { IScanConfigMonitoringEngineValue } from '@blindspot/common/types/bff/configs';

export const isMonitoringEngineConfigValuesEqual = (configValue?: IScanConfigMonitoringEngineValue, configValue2?: IScanConfigMonitoringEngineValue): boolean => {
  return (
    configValue?.tamperingAndRiskyCode === configValue2?.tamperingAndRiskyCode &&
    configValue?.maliciousPackages === configValue2?.maliciousPackages &&
    configValue?.vulnerabilities === configValue2?.vulnerabilities &&
    configValue?.sast === configValue2?.sast
  );
};
