import { faCodeCompare, faFolder } from '@fortawesome/free-solid-svg-icons';
import { faShieldExclamation } from '@fortawesome/pro-solid-svg-icons';

import { useDependenciesSummary, useRepositoriesSummary } from '@Hooks';
import { DashboardSectionStyled } from 'Layouts/DashboardPage/styled';
import { useFilter } from 'core/Filter';
import { formatNumberWithCommas, pageTools } from '@Utils';
import { SummaryBlock } from './SummaryBlock';
import { EIssueGroup } from 'Layouts/IssuesPage/interfaces';
import { useDashboardIssues } from 'Layouts/DashboardPage/hooks';

export const SummarySection = () => {
  const { appliedFilters } = useFilter();
  const { data: repositoriesSummaryData, isLoading: isRepositoriesSummaryLoading } = useRepositoriesSummary({ filter: appliedFilters });
  const { data: dependenciesSummaryData, isLoading: isDependenciesSummaryLoading } = useDependenciesSummary({ filter: appliedFilters });
  const { data: dashboardIssuesData, isLoading: isDashboardIssuesLoading } = useDashboardIssues({ filter: appliedFilters });

  const {
    reachableSevere: { direct, indirect },
  } = dashboardIssuesData || { reachableSevere: { direct: 0, indirect: 0 } };

  const reachableSevere = direct + indirect;

  return (
    <DashboardSectionStyled $gridTemplateColumns={'auto auto auto'}>
      <SummaryBlock
        link={`${pageTools.discovery.path}/repositories`}
        name={'Repositories'}
        value={formatNumberWithCommas(repositoriesSummaryData?.total)}
        icon={faFolder}
        isLoading={isRepositoriesSummaryLoading}
      />
      <SummaryBlock
        link={`${pageTools.discovery.path}/dependencies`}
        name={'Dependencies'}
        value={formatNumberWithCommas(dependenciesSummaryData?.total)}
        icon={faCodeCompare}
        isLoading={isDependenciesSummaryLoading}
      />
      <SummaryBlock
        link={`${pageTools.issues.path}/${EIssueGroup.ALL}`}
        name={'Recommended Issues'}
        value={formatNumberWithCommas(reachableSevere)}
        icon={faShieldExclamation}
        isLoading={isDashboardIssuesLoading}
      />
    </DashboardSectionStyled>
  );
};
