import styled, { css } from 'styled-components';

export const SubtitleStyled = styled.p(
  ({ theme }) => css`
    font-size: ${theme.text.size.subtitle2};
    font-weight: ${theme.text.weight.bold};
    letter-spacing: 0.1px;
    line-height: 24px;
  `,
);
