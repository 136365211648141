import { useCallback, useEffect } from 'react';
import { AuthErrorPageContainerStyled, AuthErrorPageMessageStyled } from './styled';
import { TIME_TO_LOGOUT } from './consts';
import { useDescope } from '@descope/react-sdk';

export const AuthErrorPage = () => {
  const { logout } = useDescope();

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  useEffect(() => {
    setTimeout(async () => {
      await handleLogout();
    }, TIME_TO_LOGOUT);
  }, []);

  return (
    <AuthErrorPageContainerStyled>
      <AuthErrorPageMessageStyled>
        <img alt="Myrror security logo" width={280} height={100} src={`${window.location.origin}/myrror-logo.svg`} />
        <span>Oops Something Went Wrong...</span>
        <span>Your organization isn't supporting this login method, please try a different one.</span>
        <span>Redirecting...</span>
      </AuthErrorPageMessageStyled>
    </AuthErrorPageContainerStyled>
  );
};
