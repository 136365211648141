import { ApexOptions } from 'apexcharts';
import { DefaultTheme } from 'styled-components';
import { chartAnimationOptions } from './chartAnimationOptions';
import { getChartTitleOptions } from './getChartTitleOptions';
import { getChartLegendOptions } from './getChartLegendOptions';
import { getChartTooltip } from './getChartTooltip';
import { NavigateFunction } from 'react-router-dom';

export const getIssuesPerCodeLanguagesOptions = ({ theme, categories, navigate }: { theme: DefaultTheme; categories: string[]; navigate: NavigateFunction }): ApexOptions => {
  return {
    ...getChartTitleOptions(theme, 'Issues per code language', true),
    ...getChartLegendOptions(theme, true),
    ...getChartTooltip(theme),
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      animations: chartAnimationOptions,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      events: {
        xAxisLabelClick: function (event) {
          navigate(`/issues?filter[languages]=${event.target.textContent.toLowerCase()}`);
        },
      },
    },
    colors: [theme.colors.zinc[200], theme.colors.zinc[400], theme.colors.orange[500], theme.colors.rose[600]],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      colors: ['transparent'],
      width: 3,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 15,
        borderRadius: 6,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        style: {
          colors: theme.colors.zinc[700],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: 'Languages',
        style: {
          color: theme.colors.zinc[400],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: 'right',
        style: {
          colors: theme.colors.zinc[400],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
      title: {
        text: 'Issues',
        style: {
          color: theme.colors.zinc[400],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    grid: {
      show: false,
    },
  };
};
