import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationSummaryTitleStyled = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing[1]};
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;
