import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationEffortSourceCodeSpoilerContainerStyled = styled(Flex)<{ $isOpen?: boolean }>`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[0.5]};

  margin: ${({ $isOpen: isOpen, theme }) => (isOpen ? `${theme.spacing[0.5]} 0` : '0')};
  color: ${({ theme }) => theme.colors.zinc[600]};
  max-height: ${({ $isOpen: isOpen }) => (isOpen ? 'fit-content' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: ${({ $isOpen: isOpen }) => (isOpen ? '1' : '0')};
`;
