import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const SelectTriggerWrapper = styled(Button)<{ $error: boolean }>`
  display: flex;
  flex: 1;
  gap: ${({ theme }) => theme.spacing[1]};
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-height: 32px;
  min-width: 182px;
  border-radius: 8px;
  transition: border 200ms;
  border: ${({ theme }) => css`1px solid ${theme.colors.zinc[200]}`};
  padding: ${({ theme }) =>
    css`
      ${theme.spacing[0.5]} ${theme.spacing[1.5]}
    `};
  background: ${({ theme }) => theme.colors.white};

  &:focus {
    outline: none;
    border: ${({ theme }) => css`1px solid ${theme.colors.indigo[500]}`};
  }

  &:disabled {
    cursor: default;
    background: ${({ theme }) => theme.colors.zinc[100]};
  }

  ${({ $error, theme }) =>
    $error &&
    css`
      border: 1px solid ${theme.colors.rose[600]};
    `}
`;
