import { useQuery } from 'react-query';
import { fetchUserOrg } from '@API';
import { useSession } from '@descope/react-sdk';

export const useUserOrganization = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['fetchUserOrg'],
    queryFn: async () => {
      return await fetchUserOrg(sessionToken);
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };
  return useQuery(queryOptions);
};
