import React from 'react';
import { faArrowUpFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { ENotificationType, toastService } from '@Notification';
import { Loader } from '@Components';
import { ELoaderSize } from 'Layouts/components/Loader/types';
import { ExportButtonStyled, ExportIcon } from './styled';
import { useCreateReport } from './hooks';
import { ICreateReportDto, IExportButtonProps } from './interfaces';
import { report } from 'process';

export const ExportButton: React.FC<IExportButtonProps> = ({ type, repoId, depId, branchUuid, text = 'Export table' }) => {
  const { mutate: createReport, isLoading } = useCreateReport();

  const onExport = () => {
    const reportPayload: ICreateReportDto = { type, ...((repoId || depId) && { filters: {} }) };

    if (repoId) {
      reportPayload.filters.repositories = { all: false, included: [repoId] };
    }
    if (depId) {
      reportPayload.filters.dependencies = { all: false, included: [depId] };
    }
    if (branchUuid) {
      reportPayload.filters.branches = { all: false, included: [branchUuid] };
    }

    createReport(reportPayload);
    toastService({
      header: 'Preparing export',
      message: 'We’ll let you know once your export file is ready for download',
      type: ENotificationType.SUCCESS,
    });
  };

  return (
    <ExportButtonStyled disabled={isLoading} onClick={onExport}>
      {isLoading ? <Loader size={ELoaderSize.SMALL} /> : <ExportIcon icon={faArrowUpFromSquare} />}
      {text}
    </ExportButtonStyled>
  );
};
