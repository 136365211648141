import { Flex } from '@Styles';
import { DetailsContainerStyled } from 'Layouts/DiscoveryPage/styled/Details';
import { IPullRequest } from '@blindspot/common/types/bff/api';

import { DotSeparator } from 'Layouts/components/DotSeparator';
import { AbortScanButton, BranchName, Opened, PullRequestId, PullRequestName, RescanButton, Updated } from './components';
import { PullRequestInfoStyled, PullRequestTitleContainerStyled, PullRequestTitleStyled } from './styled';
import { EPullRequestStatus } from '@blindspot/common/types/bff';

export const PullRequestInfo = ({ pullRequest }: { pullRequest: IPullRequest }) => {
  const { status, name, url, displayId, headBranch, createdAt, userName, updatedAt } = pullRequest;

  return (
    <DetailsContainerStyled>
      <PullRequestInfoStyled>
        <PullRequestTitleContainerStyled>
          <PullRequestTitleStyled>Pull Request</PullRequestTitleStyled>
          <Flex gap={1} alignItems="center">
            <RescanButton disabled={status === EPullRequestStatus.READY_TO_MERGE} />
            <AbortScanButton disabled={status !== EPullRequestStatus.WAITING_SCAN} />
          </Flex>
        </PullRequestTitleContainerStyled>

        <PullRequestName name={name} status={status} />
        <Flex alignItems={'center'}>
          <Opened createdAt={createdAt} userName={userName} />
          <DotSeparator />
          <Updated updatedAt={updatedAt} />
          <DotSeparator />
          <PullRequestId url={url} displayId={displayId} />
          <DotSeparator />
          <BranchName branchName={headBranch.name} />
        </Flex>
      </PullRequestInfoStyled>
    </DetailsContainerStyled>
  );
};
