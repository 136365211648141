import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const CodeBlockContentStyled = styled(Flex)(
  ({ theme }) => css`
    padding: ${theme.spacing[1]};
    border-radius: 9px;
    background: ${theme.colors.indigo['50']};
    height: 100%;
  `,
);
