import React from 'react';
import { Container, Title } from './styled';
import { IContentBlockProps } from './interfaces';

export const ContentBlock: React.FC<IContentBlockProps> = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};
