import { ListActionsStyled, ListActionsWrapperStyled, ListNotificationsWrapperStyled, ListWrapperStyled } from './styled';
import { ICardListProps } from './types';
import { Pagination, ListSelection, SelectableListItem } from './components';
import { FilterButton } from 'core/Filter';
import { LoadingScreen } from '@Components';
import { Search } from 'core/Search';

export const CardList: React.FC<ICardListProps> = ({ cardComponent, data, listFeatures, emptyState, isLoading, getCardLink }: ICardListProps) => {
  const { filters, search, actions, notifications, pagination, cardSelection } = listFeatures ?? {};
  const itemsKeys = data?.length ? data.map((item, index) => item?.uuid || index) : [];

  const noDataComponent = isLoading ? <LoadingScreen /> : emptyState;

  return (
    <div>
      <ListActionsWrapperStyled $marginLeft={!!cardSelection}>
        {cardSelection && <ListSelection keys={itemsKeys} selection={cardSelection} />}
        {filters && <FilterButton filters={filters} />}
        {search && <Search searchPrefix={listFeatures.search.prefix} value={search?.value ?? ''} onChange={(value) => search?.onChange(String(value))} placeholder="Search" />}
        <ListActionsStyled>{actions}</ListActionsStyled>
      </ListActionsWrapperStyled>
      <ListNotificationsWrapperStyled $marginLeft={!!cardSelection}>{notifications}</ListNotificationsWrapperStyled>
      {cardSelection?.summary && cardSelection.summary}
      {data.length ? (
        <ListWrapperStyled>
          {data.map((item, index) => {
            const key = item?.uuid || index;
            if (cardSelection) {
              const selected = cardSelection.allSelected ? !cardSelection.excluded[key] : !!cardSelection.included[key];
              return (
                <SelectableListItem key={key} selected={selected} onChange={() => cardSelection.onChange(key, !selected)}>
                  {cardComponent({ data: item, getCardLink })}
                </SelectableListItem>
              );
            }
            return cardComponent({ key, data: item, getCardLink });
          })}
        </ListWrapperStyled>
      ) : (
        <div>{noDataComponent}</div>
      )}
      <div>{pagination && !isLoading && <Pagination {...pagination} />}</div>
    </div>
  );
};
