import { useEffect } from 'react';
import { useQueryParams } from './useQueryParams.hook';
import { initialTablePagination } from 'core/Table';

export const usePagination = (pageIndex?: number, pageSize?: number): void => {
  const { queryParams, setQueryParams } = useQueryParams({});

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      page: pageIndex && pageIndex + 1,
    });
  }, [pageIndex]);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      size: pageSize === initialTablePagination.pageSize ? undefined : pageSize,
      page: 0,
    });
  }, [pageSize]);
};
