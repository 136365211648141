import styled from 'styled-components';

export const AuthErrorPageMessageStyled = styled.div`
  width: 400px;
  height: 500px;
  border-radius: ${({ theme }) => theme.spacing[0.5]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: ${({ theme }) => theme.spacing[2.5]};
  padding: ${({ theme }) => theme.spacing[2]};

  background-color: ${({ theme }) => theme.colors.white};
`;
