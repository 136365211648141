export const ScanCoverageText = () => {
  return (
    <p>
      Our scanning process prioritizes <b>coverage</b> and <b>frequency</b>. With each commit to your chosen <b>branch</b>, we promptly identify <b>vulnerabilities</b> and{' '}
      <b>tampering issues</b>. Scans include a thorough assessment of issues' <b>reachability</b> and a <b>remediation plan</b>. We ensure continuous vigilance by conducting these
      evaluations with <b>every commit</b> and at <b>three-day intervals</b>, whichever comes first. This dual approach ensures proactive and systematic handling of security concerns in your
      repository
    </p>
  );
};
