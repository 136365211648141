import React, { useEffect, useRef, useState } from 'react';
import { ISearchProps } from './types';
import { SearchIconStyled, SearchInputStyled, SearchInputWrapperStyled, SearchPrefixStyled } from './styled';

export const Search: React.FC<ISearchProps> = ({ value: initialValue, onChange, searchPrefix, debounce = 500, ...props }) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  const onChangeHandler = (e) => {
    setValue(e.target.value);
  };

  const handleClickOnContainer = () => (inputRef ? inputRef.current.focus() : undefined);

  return (
    <SearchInputWrapperStyled onClick={handleClickOnContainer}>
      <SearchIconStyled />
      {!!searchPrefix && <SearchPrefixStyled>{searchPrefix}/</SearchPrefixStyled>}
      <SearchInputStyled {...props} placeholder={searchPrefix ? '' : props.placeholder} value={value} onChange={onChangeHandler} ref={inputRef} />
    </SearchInputWrapperStyled>
  );
};
