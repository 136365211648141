import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationEffortClassNameSpoilerContainerStyled = styled(Flex)`
  gap: ${({ theme }) => theme.spacing[0.5]};

  align-items: center;
  cursor: pointer;
  font-size: ${({ theme }) => theme.text.size.caption};
  color: ${({ theme }) => theme.colors.zinc[600]};
`;
