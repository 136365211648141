import { components, ValueContainerProps, GroupBase } from 'react-select';
import { ValueContainerStyled } from './styled';

export const ValueContainer = ({ children, ...props }: ValueContainerProps<unknown[], boolean, GroupBase<unknown[]>>) => {
  const selectCount = props.selectProps.value.length;

  return (
    <components.ValueContainer {...props}>
      <ValueContainerStyled>
        {selectCount > 0 ? `${selectCount} selected` : ''}
        {children}
      </ValueContainerStyled>
    </components.ValueContainer>
  );
};
