import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ThreeCellWrapCellLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.zinc['800']};
  font-size: ${({ theme }) => theme.text.size.body1};
  text-decoration: underline;

  span:first-letter {
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }

  :hover,
  :visited,
  :active {
    color: ${({ theme }) => theme.colors.zinc['800']};
  }
`;
