import { useMutation } from 'react-query';
import { createOrganizationToken } from '../api';
import { useSession } from '@descope/react-sdk';

export const useOrganizationTokenCreate = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async (tokenName: string) => {
    if (isAuthenticated && !isSessionLoading) {
      return createOrganizationToken(tokenName, sessionToken);
    }
  });
};
