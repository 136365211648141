import PageNotFoundImage from '@Images/error/404.svg';
import { Button, ButtonModes } from '@FormElements';
import { ErrorPage } from './ErrorPage';
import { useNavigate } from 'react-router-dom';
import { ErrorDescriptionStyled, ErrorImageStyled, ErrorTitleStyled } from '@Error';

export const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ErrorPage>
      <ErrorImageStyled src={PageNotFoundImage} alt="Page not found" />
      <ErrorTitleStyled>Page not found</ErrorTitleStyled>
      <ErrorDescriptionStyled>{`The page you are looking for doesn't exist or has been moved.`}</ErrorDescriptionStyled>
      <Button styleMode={ButtonModes.Error} onClick={(): void => navigate('/')}>
        Go to Homepage
      </Button>
    </ErrorPage>
  );
};
