import React from 'react';
import { Checkbox } from '@FormElements';
import { SelectableListItemStyled } from './styled';
import { ISelectableListItemProps } from './interfaces';

export const SelectableListItem: React.FC<ISelectableListItemProps> = ({ selected, onChange, children }) => {
  return (
    <SelectableListItemStyled>
      <Checkbox checked={selected} onChange={onChange} />
      {children}
    </SelectableListItemStyled>
  );
};
