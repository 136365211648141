import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const InputRow = styled(Grid)(
  ({ theme }) => css`
    grid-template-columns: 182px auto;
    gap: ${theme.spacing[2]};
    margin-top: ${theme.spacing[2]};
  `,
);
