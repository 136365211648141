import { useQuery } from 'react-query';
import { getIntegrationOrganizations } from './api';
import { IIntegrationOrganization } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useIntegrationOrganizations = (provider: string) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useQuery<IIntegrationOrganization[]>({
    queryKey: 'getIntegrationOrganizations',
    queryFn: async () => {
      return await getIntegrationOrganizations(provider, sessionToken);
    },
    refetchOnWindowFocus: false,
    retry(failureCount) {
      return failureCount <= 10;
    },
    retryDelay: 1500,
    enabled: isAuthenticated && !isSessionLoading,
  });
};
