import { createGlobalStyle, css } from 'styled-components';

import ManropeExtraLight from '../../assets/fonts/Manrope/static/Manrope-ExtraLight.ttf';
import ManropeLight from '../../assets/fonts/Manrope/static/Manrope-Light.ttf';
import ManropeRegular from '../../assets/fonts/Manrope/static/Manrope-Regular.ttf';
import ManropeMedium from '../../assets/fonts/Manrope/static/Manrope-Medium.ttf';
import ManropeSemiBold from '../../assets/fonts/Manrope/static/Manrope-SemiBold.ttf';
import ManropeBold from '../../assets/fonts/Manrope/static/Manrope-Bold.ttf';

export const GlobalStyle = createGlobalStyle(
  ({ theme }) =>
    css`
      body {
        background: ${theme.colors.zinc[50]};
        color: ${theme.colors.indigo[900]};
        font-family: 'Manrope', sans-serif;
      }

      a {
        color: ${theme.colors.indigo[500]};
        text-decoration: none;
      }

      * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
      }
    `,
);
