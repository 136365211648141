import React from 'react';
import { ESign, SignRender, Tooltip } from '@Components';
import { Label, Logo, Button, Container, TitleWrapper, TopWrapper, ButtonWrapper, SubTitle } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IntegrationStatusEnum } from '../../types';
import { IIntegratorProps } from './types';
import { faCircleExclamation, faCircleCheck, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { format, isValid } from 'date-fns';
import { NotAvailable } from '@APITypes';

export const Integrator: React.FC<IIntegratorProps> = ({ name, status, disabled, comingSoon, onButtonClick, expiredAt, editButton }) => {
  let button;
  let label;
  let sign: ESign;

  const onClick = () => {
    onButtonClick();
  };

  if (comingSoon) {
    button = <Label>COMING SOON</Label>;
  } else if (status === IntegrationStatusEnum.ACTIVE) {
    const expiredDate = isValid(new Date(expiredAt)) ? format(new Date(expiredAt), 'dd-MMM-yyyy') : NotAvailable;

    button = editButton || null;
    label = (
      <SubTitle $color="integrated">
        <span>
          <FontAwesomeIcon icon={faCircleCheck} /> Connected
        </span>

        {expiredAt && <SubTitle $color="expiration">{`Expires ${expiredDate}`}</SubTitle>}
      </SubTitle>
    );
  } else if (status === IntegrationStatusEnum.FAILED) {
    button = <Button onClick={onClick}>Reconnect</Button>;
    label = (
      <SubTitle $color="failed">
        <span>
          <FontAwesomeIcon icon={faCircleExclamation} />
          Connection lost
        </span>
      </SubTitle>
    );
  } else if (status === IntegrationStatusEnum.NOT_INTEGRATED) {
    button = (
      <Button onClick={onClick} disabled={disabled}>
        Connect
      </Button>
    );

    if (disabled) {
      button = (
        <Tooltip fullWidth tooltip="At the moment, It is possible to connect only a single SCM">
          {button}
        </Tooltip>
      );
    }
  } else if (status === IntegrationStatusEnum.LOADING) {
    button = (
      <Button onClick={onClick} disabled>
        <FontAwesomeIcon icon={faSpinner} spin />
      </Button>
    );
  } else if (status === IntegrationStatusEnum.PENDING) {
    button = (
      <Button $color="pending" onClick={onClick} disabled={disabled}>
        Finish connecting
      </Button>
    );

    if (disabled) {
      button = (
        <Tooltip fullWidth tooltip="At the moment, It is possible to connect only a single SCM">
          {button}
        </Tooltip>
      );
    }

    label = (
      <SubTitle $color="pending">
        <span>
          Partially connected{' '}
          <Tooltip tooltip="Please select an organization to protect, to complete the integration">
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        </span>
      </SubTitle>
    );
  }

  switch (name) {
    case 'GitHub':
      sign = ESign.github;
      break;
    case 'GitLab':
      sign = ESign.gitlab;
      break;
    case 'GitLab CI':
      sign = ESign.gitlab;
      break;
    case 'Bitbucket':
      sign = ESign.bitbucket;
      break;
    case 'BitBucket Pipelines':
      sign = ESign.bitbucket;
      break;
    case 'Jenkins':
      sign = ESign.jenkins;
      break;
    case 'Slack':
      sign = ESign.slack;
      break;
    case 'Azure Devops':
      sign = ESign.azureDevops;
      break;
    case 'Jira':
      sign = ESign.jira;
      break;
  }

  return (
    <Container>
      <TopWrapper>
        <Logo>
          <SignRender name={sign} />
        </Logo>
        <TitleWrapper>
          <span>{name}</span>
          {label}
        </TitleWrapper>
      </TopWrapper>

      <ButtonWrapper>{button}</ButtonWrapper>
    </Container>
  );
};
