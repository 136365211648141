import styled from 'styled-components';
import { InfoLabelTypes } from '../interfaces';

export const InfoLabelContainer = styled.span<{ $type: string; $celled: boolean; $dot?: boolean; $capitalize?: boolean; $noContainer?: boolean }>`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing['0.5']} ${theme.spacing['1']}`};
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  letter-spacing: 0.17px;
  line-height: 19px;
  border-radius: 4px;
  margin-right: ${({ theme }) => theme.spacing['0.5']};
  ${({ $celled, theme }) => $celled && `padding: 0 ${theme.spacing['1']};`}

  ${({ $capitalize }) => $capitalize && 'text-transform: capitalize;'}
  ${({ $dot }) =>
    $dot &&
    `
      &:before {
        content: '';
        display: inline-block;
        position: relative;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        top: 2px;
        left: 0;
        margin-right: 5px;
      }
  `}
  ${({ $type, theme }) => {
    if ($type === InfoLabelTypes.Info || $type === InfoLabelTypes.Scanning) {
      return `
          color: ${theme.colors.indigo['500']};

          &:before {
            background: ${theme.colors.indigo['500']};
          }
        `;
    }

    if ($type === InfoLabelTypes.Default || $type === InfoLabelTypes.Low) {
      return `
          color: ${theme.colors.zinc['500']};
          background: ${theme.colors.zinc['100']};

          &:before {
            background: ${theme.colors.zinc['500']};
          }
        `;
    }

    if ($type === InfoLabelTypes.Bordered) {
      return `
          padding: 2.5px ${theme.spacing[1]};
          border: 1px solid ${theme.colors.zinc[200]};
          border-radius: 4px;
          font-size: ${theme.text.size.caption};
          font-weight: 600;
          color: ${theme.colors.zinc[500]};
          background-color: transparent;
          `;
    }

    if ($type === InfoLabelTypes.Success || $type === InfoLabelTypes.Monitored) {
      return `
          color: ${theme.colors.green['700']};
          background: ${theme.colors.green['50']};

          &:before {
            background: ${theme.colors.indigo['500']};
          }
        `;
    }

    if ($type === InfoLabelTypes.Error || $type === InfoLabelTypes.Unmonitored || $type === InfoLabelTypes.Critical) {
      return `
          color: ${theme.colors.rose['600']};
          background: ${theme.colors.rose['50']};

          &:before {
            background: ${theme.colors.rose['600']};
          }
        `;
    }

    if ($type === InfoLabelTypes.Warning || $type === InfoLabelTypes.High) {
      return `
          color: ${theme.colors.orange['600']};
          background: ${theme.colors.orange['50']};

          &:before {
            background: ${theme.colors.orange['600']};
          }
        `;
    }

    if ($type === InfoLabelTypes.Medium) {
      return `
          color: ${theme.colors.zinc['600']};
          background: ${theme.colors.zinc['200']};
        `;
    }

    if ($type === InfoLabelTypes.Fixed) {
      return `
          color: ${theme.colors.white};
          background: ${theme.colors.green['600']};
        `;
    }
  }}

  ${({ $noContainer: noContainer }) => noContainer && 'background: none;'}
  & > span {
    display: inline-block;
    margin: ${({ theme }) => `0 ${theme.spacing['0.5']}`};
  }

  & span:first-child {
    margin-left: 0;
  }
`;
