export const colors = {
  zinc: {
    '50': '#FAFAFA' as const,
    '100': '#F4F4F5' as const,
    '200': '#E4E4E7' as const,
    '300': '#D4D4D8' as const,
    '400': '#A1A1AA' as const,
    '500': '#71717A' as const,
    '600': '#52525B' as const,
    '700': '#3F3F46' as const,
    '800': '#27272A' as const,
    '900': '#18181B' as const,
  },
  indigo: {
    '50': '#F6F7FB' as const,
    '100': '#EEF0FB' as const,
    '200': '#CBD0FC' as const,
    '300': '#99A2F9' as const,
    '400': '#4F5FEB' as const,
    '500': '#2C3DD7' as const,
    '600': '#1324BD' as const,
    '700': '#0A189A' as const,
    '800': '#061071' as const,
    '900': '#030A47' as const,
  },
  rose: {
    '50': '#FFF1F2' as const,
    '100': '#FFE4E6' as const,
    '200': '#FECDD3' as const,
    '300': '#FDA4AF' as const,
    '400': '#FB7185' as const,
    '500': '#F43F5E' as const,
    '600': '#E11D48' as const,
    '700': '#BE123C' as const,
    '800': '#9F1239' as const,
    '900': '#881337' as const,
  },
  orange: {
    '50': '#FFF7ED' as const,
    '100': '#FFEDD5' as const,
    '200': '#FED7AA' as const,
    '300': '#FDBA74' as const,
    '400': '#FB923C' as const,
    '500': '#F97316' as const,
    '600': '#EA580C' as const,
    '700': '#C2410C' as const,
    '800': '#983210' as const,
    '900': '#7C2D12' as const,
  },
  green: {
    '50': '#F0FDF4' as const,
    '100': '#DCFCE7' as const,
    '200': '#BBF7D0' as const,
    '300': '#86EFAC' as const,
    '400': '#4ADE80' as const,
    '500': '#22C55E' as const,
    '600': '#16A34A' as const,
    '700': '#15803D' as const,
    '800': '#166534' as const,
    '900': '#365314' as const,
  },
  cyan: {
    '50': '#E5F6FA' as const,
    '100': '#C1F2FA' as const,
    '200': '#9AEAF8' as const,
    '300': '#75E1F3' as const,
    '400': '#4DD1E7' as const,
    '500': '#2ABDD5' as const,
    '600': '#149DB3' as const,
    '700': '#0A7F91' as const,
    '800': '#065F6D' as const,
    '900': '#033841' as const,
  },
  white: '#FFFFFF' as const,
  black: '#000000' as const,
  transparent: 'transparent' as const,
};
