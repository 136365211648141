import { text } from '@Styles';
import { getTextWidth } from '../../../IssueDependencyChain/utils/getTextWidth';

export function calculateWidth(primaryText: string, secondaryText: string, usagesText: string): number {
  const font = `6px ${text.fontFamily}`;
  const paddingHorizontal = 8 * 2;

  const title = `${primaryText} ${usagesText}`;
  const longestText = title.length > secondaryText.length ? title : secondaryText;
  const longestTextWidth = getTextWidth(longestText, font);

  return paddingHorizontal + longestTextWidth;
}
