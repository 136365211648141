import React from 'react';
import { RadioButtonStyled, RadioButtonWrapperStyled } from './styled';
import { IRadioGroupButtonProps } from './interfaces';

export const RadioGroupButton: React.FC<IRadioGroupButtonProps> = ({ value, checked, onChange }) => {
  return (
    <RadioButtonWrapperStyled>
      <RadioButtonStyled type="radio" value={value} checked={checked} onChange={() => onChange(value)} />
    </RadioButtonWrapperStyled>
  );
};
