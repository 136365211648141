import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'core/Table';
import { ActionsDropdown } from 'core/Dropdown';
import { CopyButton } from '@Components';
import { Flex, Button } from '@Styles';
import { DATE_TIME_LONG_FORMAT } from '@Utils';
import { ENotificationType, toastService } from '@Notification';
import { useModal } from '@Modal';
import { useOrganizationTokens, useOrganizationTokenRemove } from './hooks';
import { IOrganizationToken } from './interfaces';
import { CreateTokenButtonStyled, TokensTableNoDataStyled, TokensTableSecretCellStyled } from './styled';
import { CreateOrganizationTokenModal } from './components';

export const OrganizationTokensTable: React.FC = () => {
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const { data: organizationTokens = [], isLoading } = useOrganizationTokens();
  const { mutate: removeOrganizationToken } = useOrganizationTokenRemove();

  const onRemoveOrganizationToken = (tokenId: string) => {
    removeOrganizationToken(tokenId, {
      onSuccess() {
        queryClient.invalidateQueries('organizationTokens');
      },
      onError() {
        toastService({
          header: 'An error occurred while removing token',
          type: ENotificationType.ERROR,
        });
      },
    });
  };

  const onClickCreateOrganizationToken = () => {
    openModal(<CreateOrganizationTokenModal />);
  };

  const columnHelper = createColumnHelper<IOrganizationToken>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
      }),
      columnHelper.accessor('clientId', {
        header: 'Client ID',
        cell: ({ row }) => (
          <TokensTableSecretCellStyled>
            {row.original.clientId}
            <CopyButton data={row.original.clientId} />
          </TokensTableSecretCellStyled>
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Created at',
        cell: ({ row }) => format(new Date(row.original.createdAt), DATE_TIME_LONG_FORMAT),
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <Flex justifyContent="flex-end">
            <ActionsDropdown
              isIconView={true}
              menuItems={[
                <Button onClick={() => onRemoveOrganizationToken(row.original.uuid)} key={row.original.uuid}>
                  {<FontAwesomeIcon icon={faTrashCan} />}
                  Remove
                </Button>,
              ]}
            />
          </Flex>
        ),
      }),
    ],
    [],
  );

  const tableActions = <CreateTokenButtonStyled onClick={onClickCreateOrganizationToken}>Create token</CreateTokenButtonStyled>;

  const emptyStateComponent = (
    <TokensTableNoDataStyled>
      <FontAwesomeIcon icon={faCircleCheck} />
      <p>No tokens</p>
    </TokensTableNoDataStyled>
  );

  const options = {
    actions: tableActions,
  };

  return <Table columns={columns} data={organizationTokens} tableFeatures={options} emptyState={emptyStateComponent} isLoading={isLoading} />;
};
