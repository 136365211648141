import { IScanConfigPRChecksValue } from '@blindspot/common/types/bff/configs';
import { isPrimitivesArraysEqual } from '@Utils';

export const isPRChecksConfigValuesEqual = (configValue?: IScanConfigPRChecksValue, configValue2?: IScanConfigPRChecksValue): boolean => {
  const checkAllEqual = configValue?.checkAll === configValue2?.checkAll;
  const failConditionsSeveritiesEqual = isPrimitivesArraysEqual(configValue?.failConditions?.severity, configValue2?.failConditions?.severity);
  const failConditionsReachabilitiesEqual = isPrimitivesArraysEqual(configValue?.failConditions?.reachability, configValue2?.failConditions?.reachability);
  const prCommentsEqual = configValue?.PRComments === configValue2?.PRComments;
  const isRequiredEqual = configValue?.isRequired === configValue2?.isRequired;
  const isPRCommentMessageEqual = configValue?.PRCommentMessage === configValue2?.PRCommentMessage;

  return checkAllEqual && failConditionsSeveritiesEqual && failConditionsReachabilitiesEqual && prCommentsEqual && isRequiredEqual && isPRCommentMessageEqual;
};
