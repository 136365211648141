import styled from 'styled-components';

export const MetadataKeyTitleStyled = styled.span`
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  line-height: 160%;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.colors.zinc[500]};
  margin-bottom: 6px;
`;
