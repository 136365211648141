import React, { useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { TabPages } from '@Components';
import { IRepositoryDetailsAdditional } from './interfaces';
import { IRepositoryDetailsContext } from 'Layouts/DiscoveryPage/interfaces';
import { Flex, TabContent } from '@Styles';
import { TabsContainer } from './styled';

export const RepositoryDetailsAdditional: React.FC<IRepositoryDetailsAdditional> = ({ monitoringStatus, setMonitoringStatus }) => {
  const { repoId } = useParams();
  const baseUrl = `/discovery/repositories/${repoId}`;
  const location = useLocation();

  const tabs = useMemo(() => {
    return [
      {
        title: 'Issues',
        key: 'issues',
        linkTo: `${baseUrl}/issues/all`,
        forceActive: location.pathname.includes('issues'),
      },
      {
        title: 'Pull Requests',
        key: 'pull-requests',
        linkTo: `${baseUrl}/pull-requests`,
      },
      {
        title: 'Dependencies',
        key: `dependencies`,
        linkTo: `${baseUrl}/dependencies`,
      },
      {
        title: 'Dependencies Files',
        key: `dependencies-files`,
        linkTo: `${baseUrl}/dependencies-files`,
      },
      {
        title: 'Remediation Plan',
        key: 'remediation',
        linkTo: `${baseUrl}/remediation`,
      },
    ];
  }, [baseUrl, location.pathname.includes('issues')]);

  return (
    <TabsContainer>
      <Flex flexDirection="column">
        <TabPages items={tabs} />
        <TabContent>
          <Outlet
            context={
              {
                monitoringStatus,
                setMonitoringStatus,
              } as IRepositoryDetailsContext
            }
          />
        </TabContent>
      </Flex>
    </TabsContainer>
  );
};
