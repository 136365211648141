import styled from 'styled-components';

export const DrawerContentContainerStyled = styled.div<{ $open: boolean }>`
  height: 100vh;
  width: ${({ $open }) => ($open ? '700px' : 0)};
  transition: width 0.2s ease;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1501;
  overflow: scroll;
`;
