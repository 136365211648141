import { EIssueSubType, IIssue } from '@APITypes';

export const getIssueDescription = (issue: IIssue) => {
  if (issue.category === EIssueSubType.RISKY_CODE) {
    return issue.description || issue.riskyCode.tamperedDescriptions?.[0];
  }
  if (issue.category === EIssueSubType.TAMPERING) {
    return `We've detected an anomaly in the library ${issue.dependency?.name}: ${issue.dependency?.installedVersion}. 
    The following called functions were detected in the binary while we didn't find a matching code in the source code`;
  }
  if (issue.category === EIssueSubType.VULNERABILITY) {
    const vulnerability = issue.vulnerabilities[0];
    return vulnerability.description;
  }
};
