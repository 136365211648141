import { Flex } from '@Styles';
import styled from 'styled-components';

export const DrawerFilePathTextStyled = styled(Flex)`
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  line-height: ${({ theme }) => theme.spacing[3]};
  letter-spacing: 0.2px;
  gap: ${({ theme }) => theme.spacing[1]};
  align-items: center;
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
