import styled from 'styled-components';
import { Grid } from '@Styles';

export const LeftSide = styled(Grid)`
  gap: ${({ theme }) => theme.spacing['3']};
  grid-template-rows: auto 1fr auto;
  padding-right: calc(${({ theme }) => theme.spacing['6']} * 2);
  font-size: ${({ theme }) => theme.text.size.body1};
  line-height: 28px;
  letter-spacing: 0.15px;
`;
