import React, { useEffect, useMemo, useState } from 'react';
import { PaginationState, Row, VisibilityState } from '@tanstack/react-table';
import { ISastIssue, sastIssueFilters } from './types';
import { Table, TableNotifications } from 'core/Table';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ModalProvider } from '@Modal';
import { EMonitoringStatus, IIssue } from '@APITypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldExclamation } from '@fortawesome/pro-regular-svg-icons';
import { usePagination, usePostProcessing, usePostProtect, useQueryParams, useSearch } from '@Utils';
import { MonitorButton } from 'Layouts/DiscoveryPage/components/SubComponents/MonitorButton';
import { ExportButton } from 'Layouts/Exports/components/ExportButton';
import { EExportType } from 'Layouts/Exports/components/ExportButton/interfaces';
import { ENotificationType, toastService } from '@Notification';
import { TableNoData } from 'core/Table/styled';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { initialPaginatedData, initialTablePagination } from 'core/Table/config';
import { useRepositoryDetails, useRepositoryDetailsContext, useRepositoryMonitor, usePullRequestDetailsContext } from 'Layouts/DiscoveryPage/hooks';
import { useIssues1stParty, useIssuesGroups } from 'Layouts/IssuesPage/hooks';
import { EIssueGroup } from 'Layouts/IssuesPage/interfaces';
import { useQueryClient } from 'react-query';
import { useFilter } from 'core/Filter';
import { getColumns } from './getColumns';
import { FirstPartyIssueDrawer } from '../IssueDrawer';
import { InfoIcon, RecommendedSubtitle } from '../IssuesTable/styled';
import { IIssuesTableProps } from '../IssuesTable/types';
import { Drawer } from 'core/Drawer/Drawer';

export const IssuesTable1stParty: React.FC<IIssuesTableProps> = ({ showColumnVisibility = false, showSearch = false, showTips = false, group = EIssueGroup.ALL, isDiff = false }) => {
  const queryClient = useQueryClient();
  const { repoId, depId } = useParams();
  const { queryParams } = useQueryParams({});
  const location = useLocation();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: queryParams.page - 1 || initialTablePagination.pageIndex,
    pageSize: queryParams.size || initialTablePagination.pageSize,
  });
  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
  const [searchValue, setSearchValue] = useState(queryParams.search);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    repositories: false,
    lastCommitAuthorName: false,
    count: false,
    fixAvailable: false,
    category: false,
  });
  const [newIssuesCount, setNewIssuesCount] = useState(0);
  const { appliedFilters, onFiltersApplied } = useFilter();
  const { pullRequest } = usePullRequestDetailsContext() || {};
  const branchUuid = pullRequest?.headBranch?.uuid;
  const diff = useMemo(() => (branchUuid && isDiff ? { branchId: branchUuid } : undefined), [branchUuid, isDiff]);
  const [searchParams, setUrlSearchParams] = useSearchParams();
  const issueFingerprint = searchParams.get('openIssue');
  const { data: issues = initialPaginatedData, isLoading } = useIssues1stParty({
    pagination,
    repoId,
    depId,
    filters: { ...appliedFilters, ...{ 'filter[group]': group }, 'filter[search]': searchValue },
    diff,
  });
  const { data: currentRepository } = useRepositoryDetails(repoId, !!repoId);
  const { mutate: repositoryMonitor } = useRepositoryMonitor();
  const { setAllIssuesCount, setRecommendedIssuesCount, setLowRiskIssuesCount, setIgnoredIssuesCount, setFixedIssuesCount } = useIssuesGroups() || {};
  const { monitoringStatus, setMonitoringStatus } = useRepositoryDetailsContext() || {};

  const invalidateIssues = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['issues'],
    });
  };
  const updateIssueGroupCounter = {
    [EIssueGroup.ALL]: setAllIssuesCount,
    [EIssueGroup.RECOMMENDED]: setRecommendedIssuesCount,
    [EIssueGroup.LOW_RISK]: setLowRiskIssuesCount,
    [EIssueGroup.IGNORED]: setIgnoredIssuesCount,
    [EIssueGroup.FIXED]: setFixedIssuesCount,
  };

  useEffect(() => {
    const updateIssueGroupCounterFn = updateIssueGroupCounter[group];
    if (updateIssueGroupCounterFn && !isLoading) {
      updateIssueGroupCounterFn(issues.meta?.itemCount || 0);
    }
  }, [issues.meta?.itemCount, isLoading]);

  usePostProtect(invalidateIssues);
  usePostProcessing(invalidateIssues);
  usePagination(pageIndex, pageSize);
  useSearch(searchValue, { pageSize, setPagination });
  onFiltersApplied(() => {
    setPagination({ pageSize, pageIndex: 0 });
  });

  const getRowLink = (row: ISastIssue) => {
    return `${location.pathname}${location.search ? `${location.search}&openIssue=${row.fingerprint}` : `?openIssue=${row.fingerprint}`}`;
  };

  const onMonitorRepository = (branch?: string, scanConfigSetUuid?: string) => {
    if (currentRepository) {
      repositoryMonitor({
        scmOriginId: currentRepository.scmOriginId,
        scmOriginType: currentRepository.scmOriginType,
        branch,
        scanConfigSetUuid,
      });
      toastService({
        header: `${currentRepository.name} is now monitored`,
        message: 'We will start scanning the repository shortly',
        type: ENotificationType.SUCCESS,
      });
    }

    if (setMonitoringStatus) {
      setMonitoringStatus(EMonitoringStatus.SCANNING);
    }
  };

  const onDiscoveryClose = () => {
    setNewIssuesCount(0);
  };

  const onDiscoveryClicked = async () => {
    await invalidateIssues();
    onDiscoveryClose();
  };

  const renderEmptyStateComponent = () => {
    const repoMonitoringStatus = monitoringStatus || currentRepository?.monitoringStatus;

    if (repoId && repoMonitoringStatus === EMonitoringStatus.UNMONITORED) {
      return (
        <TableNoData>
          <p>Monitor repository for real-time scanning of upcoming changes before customer delivery</p>
          {MonitorButton({
            onClick: onMonitorRepository,
            languages: currentRepository.languages,
            repositoryId: currentRepository.uuid,
          })}
        </TableNoData>
      );
    }

    return (
      <TableNoData>
        <FontAwesomeIcon icon={faCircleCheck} />
        <p>No security issues</p>
      </TableNoData>
    );
  };
  const emptyStateComponent = renderEmptyStateComponent();

  const notifications = (
    <TableNotifications
      icon={faShieldExclamation}
      message={`${newIssuesCount} new ${newIssuesCount > 1 ? 'issues' : 'issue'} discovered`}
      onClick={onDiscoveryClicked}
      onClose={onDiscoveryClose}
    />
  );

  const columns = getColumns();

  const tableActions = <ExportButton type={EExportType.SAST_ISSUES} repoId={repoId} branchUuid={branchUuid} />;

  const tableFeatures = {
    pagination: {
      value: pagination,
      onChange: setPagination,
      pageCount: issues.meta.pageCount,
      itemCount: issues.meta.itemCount,
    },
    ...(showColumnVisibility && {
      columnVisibility: {
        show: true,
        value: columnVisibility,
        onChange: setColumnVisibility,
      },
    }),
    ...(showSearch && {
      search: {
        value: searchValue,
        onChange: (value: string) => setSearchValue(value),
      },
    }),
    ...(!!newIssuesCount && {
      notifications,
    }),
    ...(issues.meta.itemCount && {
      actions: tableActions,
    }),
    filters: sastIssueFilters,
  };

  const evaluateRowHighlight = (row: Row<ISastIssue>) => {
    const { original: rowData } = row;
    return rowData.fingerprint === issueFingerprint;
  };
  const getColspan = (cell) => {
    switch (cell.column.id) {
      case 'name':
      case 'weaknesses':
        return 2;
      default:
        return 1;
    }
  };

  const handleCloseDrawer = () => {
    setUrlSearchParams((prev) => {
      prev.delete('openIssue');
      return prev.toString();
    });
  };

  return (
    <>
      {group === EIssueGroup.RECOMMENDED && showTips && (
        <RecommendedSubtitle>
          <InfoIcon />A recommended issue is a high-risk issue that Myrror Security suggests fixing.
          <br />
          The issue severity will be either critical or high, the issue will be reachable.
        </RecommendedSubtitle>
      )}
      <Drawer open={issueFingerprint && !isLoading} onClose={handleCloseDrawer}>
        <ModalProvider>
          <FirstPartyIssueDrawer issues={issues.data} />
        </ModalProvider>
      </Drawer>
      <Table
        evaluateRowHighlight={evaluateRowHighlight}
        columns={columns}
        data={issues.data}
        tableFeatures={tableFeatures}
        emptyState={emptyStateComponent}
        isLoading={isLoading}
        getRowLink={getRowLink}
        getColspan={getColspan}
      />
    </>
  );
};
