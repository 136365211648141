import { axiosConfig as axios } from '@Utils';
import { IIntegrationData } from '../hooks/types';

export const updateIntegration = async (provider: string, data: IIntegrationData, token: string) => {
  return axios.put(
    `scm/integrate`,
    {
      provider: provider,
      data: data,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
