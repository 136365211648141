import { PullRequestNameContainerStyled, PullRequestNameStyled } from '../styled';
import { IPullRequestNameProps } from '../interface';
import { PullRequestStatusIcon } from './PullRequestStatusIcon';

export const PullRequestName = ({ name, status }: IPullRequestNameProps) => {
  return (
    <PullRequestNameContainerStyled>
      <PullRequestStatusIcon status={status}></PullRequestStatusIcon>
      <PullRequestNameStyled>{name}</PullRequestNameStyled>
    </PullRequestNameContainerStyled>
  );
};
