import React, { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { ActionContainer, GitLabModalTitleStyled, InfoGitLabModalContent, InputGitLabModalContent, LoaderContainerStyled, ModalContent, TextField } from './styled';
import { InfoGitLabTokenOrganizationModal } from './InfoGitLabTokenOrganizationModal';
import { SelectGitLabOrganizationModal } from './SelectGitLabOrganizationModal';
import { isGitLabTokenValid } from 'Layouts/IntegrationsPage/utils';
import { useIntegration } from 'Layouts/IntegrationsPage/hooks/useIntegration.hook';
import { GitLabModalTitle } from './GitLabModalTitle';
import { Button, Loader, ButtonColor, ButtonVariant, ButtonSize } from '@Components';
import { ModalStandard, useModal } from '@Modal';
import { useTokenUpdate } from 'Layouts/IntegrationsPage/hooks/useTokenUpdate.hook';
import { IntegrationsContext } from '@Utils';
import { ENotificationType, toastService } from '@Notification';
import { AxiosError } from 'axios';
import { InputLabel } from '@Components/Form';
import { Input } from '@FormElements';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const InputGitLabTokenOrganizationModal: React.FC<{ isUpdate?: boolean }> = ({ isUpdate }) => {
  const queryClient = useQueryClient();
  const invalidateIntegrations = () => queryClient.invalidateQueries({ queryKey: ['fetchUserOrg'] });
  const { integrations } = useContext(IntegrationsContext);
  const { mutate: setIntegration } = useIntegration();
  const { mutate: setNewToken } = useTokenUpdate();
  const { openModal, closeModal } = useModal();
  const [token, setToken] = React.useState<string>('');
  const [nextDisabled, setNextDisabled] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onTokenChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToken(event.target.value);
  };

  const onBackClickHandler = () => {
    openModal(<InfoGitLabTokenOrganizationModal />);
  };

  useEffect(() => {
    const gitLabIntegration = integrations.find((integration) => integration.type === EScmType.GITLAB);
    if (!gitLabIntegration) {
      return;
    }
    if (gitLabIntegration.status === 'ACTIVE' && isLoading) {
      closeModal();
    } else if (gitLabIntegration.status === 'PENDING') {
      setTimeout(() => {
        openModal(<SelectGitLabOrganizationModal />);
      }, 500);
    }
  }, [integrations]);

  const onNextClickHandler = () => {
    const gitLabIntegration = integrations.find((integration) => integration.type === EScmType.GITLAB);
    setNextDisabled(true);
    setIsLoading(true);
    if (isUpdate) {
      setNewToken(
        {
          provider: EScmType.GITLAB,
          data: { pat: token },
          scmUuid: gitLabIntegration.uuid,
        },
        {
          onSuccess: () => {
            invalidateIntegrations();
            closeModal();
          },
          onError: (error: AxiosError) => {
            const {
              response: { data },
            } = error;
            const message = (data as { message?: string })?.message || 'Something went wrong';
            setIsLoading(false);
            toastService({
              header: 'Update token error',
              message,
              type: ENotificationType.ERROR,
            });
          },
        },
      );
    } else {
      setIntegration(
        {
          provider: EScmType.GITLAB,
          data: { pat: token },
        },
        {
          onSuccess: () => {
            invalidateIntegrations();
          },
        },
      );
    }
  };

  useEffect(() => {
    setNextDisabled(!isGitLabTokenValid(token));
  }, [token]);

  return (
    <ModalStandard
      title={<GitLabModalTitle />}
      content={
        <ModalContent>
          <InputGitLabModalContent>
            <TextField>
              <InputLabel htmlFor="token">Group Access Token*</InputLabel>
              <Input id="token" value={token} onChange={onTokenChangeHandler} placeholder="glpat-ZyPoxJG9wXidPtNqJzxf" />
            </TextField>
            <InfoGitLabModalContent>
              <GitLabModalTitleStyled>Creating a Group Access Token</GitLabModalTitleStyled>
              <p>{`Log into Gitlab > Groups > Select Desired Group > Settings > Access Token > Add new

TokenName - Contains Myrror
Expiration -  One year (Recommended)
Role - Owner
Scopes - API

Copy Token`}</p>
            </InfoGitLabModalContent>
          </InputGitLabModalContent>
          <ActionContainer>
            <Button onClick={onBackClickHandler} color={ButtonColor.ZINC} variant={ButtonVariant.OUTLINED} size={ButtonSize.LARGE}>
              Back
            </Button>
            <Button onClick={onNextClickHandler} disabled={nextDisabled} variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE}>
              {isUpdate ? 'Update' : 'Next'}
            </Button>
          </ActionContainer>
          {isLoading && (
            <LoaderContainerStyled>
              <Loader />
            </LoaderContainerStyled>
          )}
        </ModalContent>
      }
    />
  );
};
