import { Flex } from '@Styles';
import styled from 'styled-components';

export const ThreeCellWrapCell = styled(Flex)`
  align-items: center;
  color: ${({ theme }) => theme.colors.zinc['800']};
  font-size: ${({ theme }) => theme.text.size.body1};

  span:first-letter {
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }
`;
