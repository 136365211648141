import React from 'react';
import { NotAvailable } from '@APITypes';
import { ScoreCircle, ScoreDetailsHeader, ScoreDetailsContent, MetricHeader, Divider, MetricsList, ScoreDetailsLabel, ScoreDetailsWrapper } from './styled';
import { parseCVSSVector, exploitabilityMetrics, impactMetrics } from '@Utils';
import { ISecurityScoreProps } from './interfaces';
import { MetricBlock } from '../MetricBlock';

export const SecurityScoreDetails: React.FC<ISecurityScoreProps> = ({ score, vulnerability, severity }) => {
  const metrics = parseCVSSVector(vulnerability.cvssV3Vector);

  return (
    <ScoreDetailsWrapper>
      <ScoreDetailsHeader>
        <ScoreDetailsLabel>CVSS 3 Score</ScoreDetailsLabel>
        {score && (
          <ScoreCircle $severity={severity} value={Number(score) * 10} strokeWidth={10}>
            <span>{score}</span>
          </ScoreCircle>
        )}
        {!score && NotAvailable}
      </ScoreDetailsHeader>

      <ScoreDetailsContent>
        <div>
          <MetricHeader>
            <span>Exploitability Metrics</span>
            <span>{vulnerability.cvssV3ExploitabilitySubScore || NotAvailable}</span>
          </MetricHeader>
          <Divider />
          <MetricsList>
            {exploitabilityMetrics.map(({ code }) => {
              const metric = metrics[code];
              return <MetricBlock key={code} metric={metric} />;
            })}
          </MetricsList>
        </div>

        <div>
          <MetricHeader>
            <span>Impact Metrics</span>
            <span>{vulnerability.cvssV3ImpactSubScore || NotAvailable}</span>
          </MetricHeader>
          <Divider />
          <MetricsList>
            {impactMetrics.map(({ code }) => {
              const metric = metrics[code];
              return <MetricBlock key={code} metric={metric} />;
            })}
          </MetricsList>
        </div>
      </ScoreDetailsContent>
    </ScoreDetailsWrapper>
  );
};
