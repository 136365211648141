import React from 'react';
import { IssueReachabilityIconStyled, IssueReachabilityLabelStyled } from './styled';
import { faLocationArrow } from '@fortawesome/pro-light-svg-icons';
import { IIssueReachabilityProps } from './types';
import { IssueRecommendationLabelStyled } from '../IssueRecommendation/styled/styled';

export const IssueReachabilityLabel: React.FC<IIssueReachabilityProps> = ({ reachabilityStatus, bolded = false }) => (
  <IssueReachabilityLabelStyled>
    <IssueReachabilityIconStyled icon={faLocationArrow} />
    <IssueRecommendationLabelStyled $bolded={bolded}>{reachabilityStatus}</IssueRecommendationLabelStyled>
  </IssueReachabilityLabelStyled>
);
