import React from 'react';
import { Loader, Tooltip } from '@Components';
import { ReportLoaderWrapper, ReportActionButton } from '../styled';
import { EReportStatus } from '../../../types';
import { IReportActionProps } from '../interfaces';
import { ReportDownload } from './ReportDownload';

export const ReportAction: React.FC<IReportActionProps> = ({ report }) => {
  if (report.status === EReportStatus.COMPLETED) {
    return <ReportDownload reportId={report.uuid} reportName={report.name} />;
  }

  if (report.status === EReportStatus.PROCESSING || report.status === EReportStatus.QUEUED) {
    return (
      <ReportLoaderWrapper>
        <Loader />
      </ReportLoaderWrapper>
    );
  }

  if (report.status === EReportStatus.ERROR) {
    return (
      <Tooltip tooltip="Sorry, but there was an error generating the export file, please try exporting the table again">
        <ReportActionButton disabled>Error</ReportActionButton>
      </Tooltip>
    );
  }
};
