import { useQuery } from 'react-query';
import { IScanConfigSet } from '@blindspot/common/types/bff/configs';
import { fetchScanConfigSetDetails } from './api';
import { useSession } from '@descope/react-sdk';

export const useScanConfigSetDetails = (scanConfigSetUuid: string) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['scanConfigSets', scanConfigSetUuid],
    queryFn: async () => {
      return await fetchScanConfigSetDetails(scanConfigSetUuid, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IScanConfigSet>(queryOptions);
};
