import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { AiIconWrapper } from './styled';

export const GptIcon = () => {
  const theme = useContext(ThemeContext);

  return (
    <AiIconWrapper>
      <FontAwesomeIcon icon={faSparkles} color={theme.colors.indigo[400]} size="lg" />
    </AiIconWrapper>
  );
};
