import styled, { css } from 'styled-components';
import { NodeStyled as DefaultNodeStyled } from 'Layouts/components/Graph';

export const NodeStyled = styled(DefaultNodeStyled)<{ $selected: boolean; $isVulnerableFunction: boolean }>(
  ({ theme, $selected, $isVulnerableFunction }) => css`
    flex-direction: column;
    font-weight: ${theme.text.weight.medium};
    transition: all 0.3s;
    color: ${$selected || $isVulnerableFunction ? theme.colors.white : theme.colors.zinc[700]};

    background: ${() => {
      if ($selected) {
        return theme.colors.indigo[400];
      }
      if ($isVulnerableFunction) {
        return theme.colors.rose[500];
      }
      return theme.colors.white;
    }};
  `,
);
