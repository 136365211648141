import React, { useMemo } from 'react';
import { NodeMouseEventHandler } from 'rc-tree/lib/contextTypes';
import { TreeNodeProps } from 'rc-tree/lib/interface';
import { Block } from '@Styles';
import { CodeFilesTitleStyled } from 'Layouts/components/CodeFiles/styled';
import { TamperedStyled } from '../../../CodeViewer/styled';
import { findTreeKeysById, getTreeData } from './utils';
import { TreeStyled, TreesContainerStyled } from './styled';
import { IReachabilityPathsTreeProps } from './types';
import { TreeNodeIcon } from './TreeNodeIcon';
import { TreeNodeSwitcherIcon } from './TreeNodeSwitcherIcon';
import { TreeNodeTitle } from './TreeNodeTitle';

export const ReachabilityPathsSidebar: React.FC<IReachabilityPathsTreeProps> = ({ chains, selectedNodeId, onNodeClick }) => {
  const treeData = useMemo(() => getTreeData(chains), [chains]);
  const selectedNodeKeys = useMemo(() => findTreeKeysById(treeData, selectedNodeId), [selectedNodeId]);

  const onClickTreeItem: NodeMouseEventHandler<TreeNodeProps> = (_event, node) => {
    if (!onNodeClick) {
      return;
    }
    onNodeClick(`${node.id}`);
  };

  return (
    <TamperedStyled>
      <Block>
        <CodeFilesTitleStyled>
          {chains.length} Reachable Path{chains.length > 1 && 's'}:
        </CodeFilesTitleStyled>
        <TreesContainerStyled>
          <TreeStyled
            defaultExpandAll
            treeData={treeData}
            onClick={onClickTreeItem}
            icon={TreeNodeIcon}
            switcherIcon={TreeNodeSwitcherIcon}
            titleRender={TreeNodeTitle}
            selectedKeys={selectedNodeKeys}
          />
        </TreesContainerStyled>
      </Block>
    </TamperedStyled>
  );
};
