import styled from 'styled-components';

export const SearchInputStyled = styled.input`
  flex: 1;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.zinc[500]};
  font-family: 'Manrope';
`;
