import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const ArrowIcon = styled(FontAwesomeIcon).attrs({ icon: faChevronUp })<{ $down: boolean }>(
  ({ theme, $down }) => css`
    cursor: pointer;
    align-self: flex-end;
    padding: ${theme.spacing[1]};
    transform: rotate(${$down ? '180deg' : '0'});
    transition: transform 0.2s;
  `,
);
