import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationsContainerStyled = styled(Flex)<{ $isOpen?: boolean }>`
  padding: ${({ $isOpen, theme }) => ($isOpen ? `${theme.spacing[2.5]} ${theme.spacing[3.5]}` : '0')};
  flex-direction: column;
  background: ${({ theme }) => theme.colors.zinc[100]};
  max-height: ${({ $isOpen }) => ($isOpen ? 'fit-content' : '0')};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
`;
