import { useState } from 'react';
import Switch from 'react-switch';
import { ReachableSwitchStyled, ReachableSwitchTextStyled } from '../styled';
import { useFilter } from 'core/Filter';

export const ReachableSwitch = () => {
  const { updateFilters, appliedFilters } = useFilter();
  const [checked, setChecked] = useState(appliedFilters[`filter[reachable]`] === 'true');

  const onChangeHandler = (checked) => {
    updateFilters('reachable', checked);
    setChecked(checked);
  };

  return (
    <ReachableSwitchStyled htmlFor="switch-control" data-hide-in-pdf>
      <Switch id={'switch-control'} onChange={onChangeHandler} checked={checked} uncheckedIcon={false} checkedIcon={false} height={14} width={26} />
      <ReachableSwitchTextStyled>Show data for reachable functions only</ReachableSwitchTextStyled>
    </ReachableSwitchStyled>
  );
};
