import styled, { css } from 'styled-components';

export const InputStyled = styled.input(
  ({ theme }) => css`
    font-size: ${theme.text.size.h2};
    font-weight: ${theme.text.weight.bold};
    line-height: 32px;
    letter-spacing: 0.2px;
    outline: none;
    border: none;
    font-family: ${theme.text.fontFamily};
    overflow: visible;
    width: auto;
    min-width: 1rem;
    grid-area: 1 / 2;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
  `,
);
