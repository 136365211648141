import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const TableActionsWrapper = styled(Flex)(
  ({ theme }) => css`
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing['1.5']};
    margin-bottom: ${theme.spacing[2]};
  `,
);
