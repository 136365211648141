import styled from 'styled-components';

export const TooltipBlock = styled.div<{ $noWrap?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.indigo['900']};
  background: ${({ theme }) => theme.colors.indigo['900']};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spacing['0.5']} ${theme.spacing['1']}`};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.text.size.caption};

  &[data-popper-placement*='top'] {
    margin-bottom: ${({ theme }) => theme.spacing['1']};

    .tooltip-arrow {
      ::after,
      ::before {
        border-color: ${({ theme }) => `${theme.colors.indigo['900']} transparent transparent transparent`};
        border-width: 8px 8px 0 8px;
      }
    }
  }

  &[data-popper-placement*='right'] {
    margin-left: ${({ theme }) => theme.spacing['1']};

    .tooltip-arrow {
      ::after,
      ::before {
        border-color: ${({ theme }) => `transparent ${theme.colors.indigo['900']} transparent transparent`};
        border-width: 8px 8px 8px 0;
      }
    }
  }

  &[data-popper-placement*='left'] {
    margin-right: ${({ theme }) => theme.spacing['1']};

    .tooltip-arrow {
      ::after,
      ::before {
        border-color: ${({ theme }) => `transparent transparent transparent ${theme.colors.indigo['900']}`};
        border-width: 8px 0 8px 8px;
      }
    }
  }

  &[data-popper-placement*='bottom'] {
    margin-top: ${({ theme }) => theme.spacing['1']};

    .tooltip-arrow {
      margin-top: -6px;

      ::after,
      ::before {
        border-color: ${({ theme }) => `transparent transparent ${theme.colors.indigo['900']} transparent`};
        border-width: 0 8px 8px 8px;
      }
    }
  }

  ${({ $noWrap }) => $noWrap && 'white-space: nowrap;'}
`;
