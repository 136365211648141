import styled from 'styled-components';

export const InputLabel = styled.label<{ $error?: boolean }>`
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  line-height: 22px;
  letter-spacing: 0.15px;
  color: ${({ $error, theme }) => ($error ? theme.colors.rose[600] : theme.colors.zinc[500])};
  margin-bottom: ${({ theme }) => theme.spacing[0.5]};
  display: block;
  width: fit-content;
`;
