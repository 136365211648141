import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const ButtonStyled = styled(Button)(
  ({ theme }) => css`
    height: 28px;
    padding: 0 ${theme.spacing[1.5]};
    color: ${theme.colors.indigo[500]};
    font-weight: ${theme.text.weight.semiBold};
    font-size: ${theme.text.size.caption};
    letter-spacing: 0.46px;
    line-height: 18px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  `,
);
