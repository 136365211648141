import { axiosConfig as axios } from '@Utils';
import { IApiPullRequestsParams } from '../types';

export const fetchPullRequests = async ({ search, repoId, filters, pagination }: IApiPullRequestsParams, token: string) => {
  const response = await axios(`repositories/${repoId}/pull-requests`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(search && { search }),
      ...(filters && filters),
      ...(pagination && {
        page: pagination?.pageIndex + 1,
        take: pagination?.pageSize,
      }),
    },
  });
  return response?.data;
};
