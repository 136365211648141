import styled from 'styled-components';

export const RepositoryCardInfoStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing[1.5]};
  padding-left: ${({ theme }) => theme.spacing[4]};
`;
