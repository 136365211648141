import styled from 'styled-components';

export const BackButtonStyled = styled.button<{ noSpacing?: boolean }>`
  margin-bottom: ${({ theme, noSpacing }) => (noSpacing ? 0 : theme.spacing[1.5])};
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  font-family: 'Manrope';
  font-size: ${({ theme }) => theme.text.size.body1};
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.colors.zinc[600]};
  cursor: pointer;

  svg {
    margin-right: ${({ theme }) => theme.spacing[1]};
    width: 10px;
    height: 10px;
    color: ${({ theme }) => theme.colors.black};
  }
`;
