import { EIssuesCategoryLabel } from '@ComponentsTypes';
import { IApiIssuesByCategoryResponse } from '../interfaces';

export const formatIssuesByCategoryToSeries = (issues: IApiIssuesByCategoryResponse) => {
  const { tampering, vulnerability, riskyCode, maliciousPackage } = issues;
  return [
    {
      name: EIssuesCategoryLabel.LOW,
      data: [tampering?.low || 0, vulnerability?.low || 0, riskyCode?.low || 0, maliciousPackage?.low || 0],
    },
    {
      name: EIssuesCategoryLabel.MEDIUM,
      data: [tampering?.medium || 0, vulnerability?.medium || 0, riskyCode?.medium || 0, maliciousPackage?.medium || 0],
    },
    {
      name: EIssuesCategoryLabel.HIGH,
      data: [tampering?.high || 0, vulnerability?.high || 0, riskyCode?.high || 0, maliciousPackage?.high || 0],
    },
    {
      name: EIssuesCategoryLabel.CRITICAL,
      data: [tampering?.critical || 0, vulnerability?.critical || 0, riskyCode?.critical || 0, maliciousPackage?.critical || 0],
    },
  ];
};
