import React, { useContext } from 'react';

import { EScanConfigName } from '@blindspot/common/types/bff/configs';
import { EScmAppType } from '@blindspot/common/types/bff';
import { EScmType } from '@blindspot/common/types/bff/scm';
import { DropdownSelect } from '@Components';
import { IntegrationsContext } from '@Utils';
import { IntegrationStatusEnum } from 'Layouts/IntegrationsPage/types';
import { findIntegrationAppType } from 'Layouts/IntegrationsPage/utils';
import { SectionTitleStyled } from '../../styled';
import { FailConditionsContainerStyled } from './styled';
import { IPRChecksSettingsProps } from './interfaces';
import { failConditionsOptions } from './constants';
import { CheckAllSwitch, IntegrationUpgradeMessage, PRCommentsSwitch, RequiredPrCheckSwitch, PRCommentMessageInput } from './components';
import { Flex } from '@Styles';

export const PRChecksSettings: React.FC<IPRChecksSettingsProps> = ({ configValue, setDraftUpdates }) => {
  const { integrations } = useContext(IntegrationsContext);
  const activeIntegration = integrations?.find((integration) => integration.status === IntegrationStatusEnum.ACTIVE);
  let isGithubAppManagementType = false;
  const isGithub = activeIntegration?.type === EScmType.GITHUB;
  if (isGithub) {
    isGithubAppManagementType = findIntegrationAppType(integrations, EScmType.GITHUB) === EScmAppType.MANAGEMENT;
  }

  const onUpdateCheckAll = (checkAllValue: boolean) => {
    setDraftUpdates((prevState) => ({
      ...prevState,
      configs: prevState.configs.map((prevConfig) => {
        if (prevConfig.name !== EScanConfigName.PR_CHECKS) {
          return prevConfig;
        }
        return {
          ...prevConfig,
          value: checkAllValue ? { checkAll: checkAllValue, failConditions: failConditionsOptions[0].value } : { checkAll: checkAllValue },
        };
      }),
    }));
  };
  const handlePRCommentsUpdate = (PRCommentValue: boolean) => {
    setDraftUpdates((prevState) => ({
      ...prevState,
      configs: prevState.configs.map((prevConfig) => {
        if (prevConfig.name !== EScanConfigName.PR_CHECKS) {
          return prevConfig;
        }
        return {
          ...prevConfig,
          value: { ...prevConfig.value, PRComments: PRCommentValue },
        };
      }),
    }));
  };

  const handlePRCommentMessageUpdate = (message: string) => {
    setDraftUpdates((prevState) => ({
      ...prevState,
      configs: prevState.configs.map((prevConfig) => {
        if (prevConfig.name !== EScanConfigName.PR_CHECKS) {
          return prevConfig;
        }
        return {
          ...prevConfig,
          value: { ...prevConfig.value, PRCommentMessage: message },
        };
      }),
    }));
  };

  const handlePRIsRequiredUpdate = (isRequiredValue: boolean) => {
    setDraftUpdates((prevState) => ({
      ...prevState,
      configs: prevState.configs.map((prevConfig) => {
        if (prevConfig.name !== EScanConfigName.PR_CHECKS) {
          return prevConfig;
        }
        return {
          ...prevConfig,
          value: { ...prevConfig.value, isRequired: isRequiredValue },
        };
      }),
    }));
  };

  const onUpdateFailConditions = (value: string) => {
    setDraftUpdates((prevState) => ({
      ...prevState,
      configs: prevState.configs.map((prevConfig) => {
        if (prevConfig.name !== EScanConfigName.PR_CHECKS) {
          return prevConfig;
        }
        return { ...prevConfig, value: { ...prevConfig.value, failConditions: JSON.parse(value) } };
      }),
    }));
  };

  const failConditionsSelectOptions = failConditionsOptions.map((option) => ({ name: option.name, value: JSON.stringify(option.value) }));

  const integrationUpgradeMessage = isGithub && !isGithubAppManagementType && <IntegrationUpgradeMessage scmLabel="GitHub" />;

  return (
    <>
      <SectionTitleStyled>Pull Requests</SectionTitleStyled>
      <CheckAllSwitch value={!!configValue?.checkAll} onChange={onUpdateCheckAll} />
      {!configValue?.checkAll && integrationUpgradeMessage}
      {!!configValue?.checkAll && (
        <Flex flexDirection="column" gap={2}>
          <FailConditionsContainerStyled>
            <DropdownSelect
              name="fail-conditions"
              label="Pull Request Failed Conditions"
              placeholder="Select"
              value={JSON.stringify(configValue?.failConditions)}
              options={failConditionsSelectOptions}
              onChange={onUpdateFailConditions}
            />
          </FailConditionsContainerStyled>
          {integrationUpgradeMessage}
          <PRCommentsSwitch value={!!configValue.PRComments} onChange={handlePRCommentsUpdate} />
          {!!configValue.PRComments && <PRCommentMessageInput value={configValue.PRCommentMessage} onChange={handlePRCommentMessageUpdate} />}
          <RequiredPrCheckSwitch value={!!configValue.isRequired} onChange={handlePRIsRequiredUpdate} />
        </Flex>
      )}
    </>
  );
};
