import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ScanConfigSetListItemStyled = styled(Flex)<{ $active: boolean }>(
  ({ theme, $active }) => css`
    gap: ${theme.spacing[1.5]};
    height: 56px;
    border-radius: 12px;
    align-items: center;
    padding: ${theme.spacing[1.5]} ${theme.spacing[1.5]} ${theme.spacing[1.5]} ${theme.spacing[2.5]};
    background: ${$active ? theme.colors.indigo[100] : 'transparent'};
    cursor: pointer;
    transition: background 0.3s ease;
  `,
);
