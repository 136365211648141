import { LinkBlank } from '@Components';
import styled, { css } from 'styled-components';

export const SourceCodeLinkStyled = styled(LinkBlank)(
  ({ theme }) => css`
    display: flex;
    align-items: baseline;
    color: ${theme.colors.zinc[800]} !important;
  `,
);
