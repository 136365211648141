import { ButtonStyled } from '@FormElements';
import styled, { css } from 'styled-components';

export const CreateSbomDialogButtonStyled = styled(ButtonStyled)(
  ({ theme }) => css`
    width: 125px;
    justify-content: center;
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.bold};
    letter-spacing: 0.4px;
    line-height: 22.4px;

    &:disabled {
      background-color: ${theme.colors.indigo[500]};
      border-color: ${theme.colors.indigo[500]};
    }
  `,
);
