import styled, { css } from 'styled-components';

export const SectionTitleStyled = styled.h3(
  ({ theme }) => css`
    font-size: ${theme.text.size.h3};
    font-weight: ${theme.text.weight.bold};
    letter-spacing: 0.15px;
    line-height: 28px;
    margin-bottom: ${theme.spacing[1]};
  `,
);
