import styled from 'styled-components';

export const ColumnVisibilityContentStyled = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 40px;
  right: 0;
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[2]} 0;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.zinc[700]};
  width: 222px;
  z-index: 100;
`;
