import { useContext } from 'react';
import { Header } from '@Layouts';
import { IntegrationsContext } from '@Utils';
import { LoadingScreen } from '@Components';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { ModalProvider } from '@Modal';

const IntegrationsContainer = styled.section`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-row-gap: ${({ theme }) => theme.spacing['2']};
`;

export const IntegrationsLayout = () => {
  const { integrationsLoading } = useContext(IntegrationsContext);

  if (integrationsLoading) {
    return <LoadingScreen />;
  }

  return (
    <IntegrationsContainer>
      <Header />
      <ModalProvider>
        <Outlet />
      </ModalProvider>
    </IntegrationsContainer>
  );
};
