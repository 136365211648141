import React from 'react';
import { useModal } from '@Modal';
import { ModalHeader, CloseIcon } from 'Modal/components/ModalStandard';
import { RemindAfterModalWrapperStyled, RemindAfterSelectContainerStyled } from './styled';
import { CancelButton, PreferencesActions, PreferencesModalTitle } from 'Layouts/DiscoveryPage/components/SubComponents/MonitoringPreferences/styled';
import { Controller, SubmitHandler, UseControllerReturn, useForm } from 'react-hook-form';
import { ERemindLetterPeriod, IRemindLatterModalProps, IRemindLetterFormData, remindLetterOptions } from './interface';
import { DropdownSelect } from '@Components';
import { Button, ButtonModes } from '@FormElements';
import { addWeeks, addMonths } from 'date-fns';

export const RemindLatterModal = ({ setRemindAfter }: IRemindLatterModalProps) => {
  const { closeModal } = useModal();

  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (e) => e.stopPropagation();

  const defaultValues: IRemindLetterFormData = { remindAt: ERemindLetterPeriod.ONE_WEEK };
  const { control, handleSubmit } = useForm<IRemindLetterFormData>({ defaultValues });

  const renderSelect = ({ field: { onChange, onBlur, value }, fieldState: { error } }: UseControllerReturn<IRemindLetterFormData, 'remindAt'>) => (
    <DropdownSelect
      name="remindAt"
      placeholder=""
      value={value}
      options={remindLetterOptions}
      error={error?.message}
      onBlur={onBlur}
      onChange={(value) => {
        onChange(value);
      }}
    />
  );

  const onSubmit: SubmitHandler<IRemindLetterFormData> = (data) => {
    let date = new Date();
    switch (data.remindAt) {
      case ERemindLetterPeriod.ONE_WEEK:
        date = addWeeks(date, 1);
        break;
      case ERemindLetterPeriod.TWO_WEEKS:
        date = addWeeks(date, 2);
        break;
      case ERemindLetterPeriod.ONE_MONTH:
        date = addMonths(date, 1);
        break;
      default:
        break;
    }
    setRemindAfter(date);
    closeModal();
  };

  return (
    <RemindAfterModalWrapperStyled onClick={stopPropagation}>
      <ModalHeader>
        <PreferencesModalTitle>Remind me about this in:</PreferencesModalTitle>
        <CloseIcon onClick={closeModal} />
      </ModalHeader>
      <RemindAfterSelectContainerStyled>
        <Controller name="remindAt" control={control} render={renderSelect} />
      </RemindAfterSelectContainerStyled>
      <PreferencesActions justifyContent="flex-end">
        <CancelButton $mode={ButtonModes.TransparentAdditional} onClick={closeModal}>
          Cancel
        </CancelButton>
        <Button styleMode={ButtonModes.Monitor} onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </PreferencesActions>
    </RemindAfterModalWrapperStyled>
  );
};
