import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ReportLoaderWrapper = styled(Flex)(
  ({ theme }) => css`
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing[1]} ${theme.spacing[2]};
  `,
);
