import { useMutation } from 'react-query';
import { ICreateTicketRequestPayload } from '../types';
import { createIssueTicket } from './api';
import { useSession } from '@descope/react-sdk';

export const useCreateIssueTicket = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async (data: ICreateTicketRequestPayload) => {
    if (isAuthenticated && !isSessionLoading) {
      return createIssueTicket(sessionToken, data);
    }
  });
};
