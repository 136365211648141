import React from 'react';
import { Tooltip } from '@Components';
import { transformNumberToLabelText } from '@Utils';
import { ISummaryBlockProps } from './types';
import { right } from '@popperjs/core';
import { SummaryBlockIssueStyled, SummaryBlockStyled, SummaryBlockSubTitleStyled, SummaryBlockTitleStyled, TooltipInfoIconStyled } from './styled';
import { SummaryBlockValueStyled } from './styled/SummaryBlockValue.styled';

export const SummaryBlock: React.FC<ISummaryBlockProps> = ({ name, monitoring, issues }) => {
  const formatNumber = transformNumberToLabelText;

  const monitored = monitoring?.monitored + monitoring?.scanning;

  const protectedDiff = monitoring?.total - monitored;

  const severityCounters = issues?.severityCounters;

  const warningInfoElem = (
    <Tooltip placement={right} tooltip={<span>{`${protectedDiff} of your entities aren’t monitored, so they might contain security issues`}</span>}>
      <TooltipInfoIconStyled />
    </Tooltip>
  );
  return (
    <SummaryBlockStyled>
      <SummaryBlockTitleStyled>
        {name} {protectedDiff > 0 && warningInfoElem}
      </SummaryBlockTitleStyled>

      <>
        <Tooltip tooltip={`${formatNumber(monitored)} out of ${formatNumber(monitoring?.total)} ${name}`}>
          <>
            <SummaryBlockSubTitleStyled>Monitoring</SummaryBlockSubTitleStyled>
            <p>
              <SummaryBlockValueStyled>{formatNumber(monitored)}</SummaryBlockValueStyled>
              <SummaryBlockSubTitleStyled> / {formatNumber(monitoring?.total)}</SummaryBlockSubTitleStyled>
            </p>
          </>
        </Tooltip>
        <SummaryBlockIssueStyled>
          <Tooltip tooltip={`${issues?.total} issues`}>
            <>
              <SummaryBlockSubTitleStyled>Issues</SummaryBlockSubTitleStyled>
              <p>
                <SummaryBlockValueStyled>{formatNumber(issues?.total)}</SummaryBlockValueStyled>{' '}
                <SummaryBlockSubTitleStyled>
                  {'/ '}
                  {severityCounters?.critical ? `${formatNumber(severityCounters.critical)} Critical  ` : ''} {severityCounters?.critical && severityCounters?.high ? '|' : ''}{' '}
                  {severityCounters?.high ? `${formatNumber(severityCounters.high)} High` : ''}
                </SummaryBlockSubTitleStyled>
              </p>
            </>
          </Tooltip>
        </SummaryBlockIssueStyled>
      </>
    </SummaryBlockStyled>
  );
};
