import styled from 'styled-components';
import { Flex } from '@Styles';

export const IssueReachabilityLabelStyled = styled(Flex)`
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing['0.5']};
  color: ${({ theme }) => theme.colors.zinc['700']};
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  letter-spacing: 0.16px;
  text-transform: capitalize;
`;
