import { useState, useCallback } from 'react';

export const useAsyncError = () => {
  const [_, setError] = useState();
  return useCallback(
    (error) => {
      setError(() => {
        throw error;
      });
    },
    [setError],
  );
};
