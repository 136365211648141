import React, { useEffect, useRef } from 'react';
import { TicketSummaryInputStyled } from './styled';
import { ITicketSummaryProps } from './types';

export const TicketSummaryInput: React.FC<ITicketSummaryProps> = ({ value, isError, onChange }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      // update textarea height to fit content
      ref.current.style.height = 'auto';
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [value]);

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return <TicketSummaryInputStyled ref={ref} as="textarea" rows={1} id="summary" value={value} $error={isError} name="Controls" onChange={onChange} onKeyDown={onKeyDown} />;
};
