import { useQuery } from 'react-query';
import { fetchDependencyDetails } from '../api';
import { IDependency } from '@APITypes';
import { IApiDependencyDetailsParams } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useDependencyDetails = (params: IApiDependencyDetailsParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['dependencyDetails', params],
    queryFn: async () => {
      return await fetchDependencyDetails(params, sessionToken);
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IDependency>(queryOptions);
};
