import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  RemediationEffortChangeContainerStyled,
  RemediationEffortClassNameContainerStyled,
  RemediationEffortClassNameSpoilerContainerStyled,
  RemediationEffortShowMoreStyled,
  RemediationEffortSourceCodeSpoilerContainerStyled,
  RemediationEffortSourceCodeStyled,
  RemediationEffortSpoilerIconStyled,
} from './styled';
import { RemediationEffortChangeProps, ERemediationEffortDiffLabels } from './types';
import { faChevronRight, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';

export const RemediationEffortsChanges: React.FC<RemediationEffortChangeProps> = ({ apiUsage }: RemediationEffortChangeProps) => {
  const effortLabel = ERemediationEffortDiffLabels[apiUsage.apiChanges[0].methodDiffs[0]];
  const [spoilerOpen, setSpoilerOpen] = useState(false);
  const [visibleItemsCount, setVisibleItemsCount] = useState(10);
  const handleOpenSpoiler = () => setSpoilerOpen(!spoilerOpen);
  const handleShowMore = useCallback(() => setVisibleItemsCount((prevCount) => prevCount + 10), []);

  const displayedSourceName = apiUsage.clazz ?? apiUsage.method;
  const displayedSourceLabel = apiUsage.clazz ? 'Class' : 'Method';

  return (
    <RemediationEffortChangeContainerStyled>
      <RemediationEffortClassNameSpoilerContainerStyled onClick={handleOpenSpoiler}>
        <RemediationEffortSpoilerIconStyled icon={faChevronRight} $isOpen={spoilerOpen} /> {displayedSourceLabel}
        <RemediationEffortClassNameContainerStyled>{displayedSourceName}</RemediationEffortClassNameContainerStyled>
      </RemediationEffortClassNameSpoilerContainerStyled>
      <span>
        Api Change - <b>{effortLabel}</b>
      </span>
      <RemediationEffortSourceCodeSpoilerContainerStyled $isOpen={spoilerOpen}>
        <span>Function(s)</span>
        <RemediationEffortSourceCodeStyled>{apiUsage.method}</RemediationEffortSourceCodeStyled>
        <span>Source Code File name(s) and line number ({apiUsage.sourceCodeReferences.length})</span>
        {apiUsage.sourceCodeReferences.slice(0, visibleItemsCount).map((sourceRef, index) => {
          const fileName = sourceRef.path.split('/').pop();
          return (
            <RemediationEffortSourceCodeStyled key={index}>
              <a href={sourceRef.usageFileUrlWithLine} target="_blank" rel="noreferrer">
                {fileName} Line #{sourceRef.lineNumber} <FontAwesomeIcon icon={faExternalLink} />
              </a>
            </RemediationEffortSourceCodeStyled>
          );
        })}
        {apiUsage.sourceCodeReferences.length > visibleItemsCount && <RemediationEffortShowMoreStyled onClick={handleShowMore}>Show More</RemediationEffortShowMoreStyled>}
      </RemediationEffortSourceCodeSpoilerContainerStyled>
    </RemediationEffortChangeContainerStyled>
  );
};
