import React from 'react';
import { ModalStandard } from '@Modal';
import { IRemediationModalProps } from './types';
import { RemediationIssuesTable } from './RemediationIssuesTable';
import { RemediationModalStyled } from './styled';

export const RemediationModal: React.FC<IRemediationModalProps> = ({ repoId, depFileUuid, effortLevel }) => {
  return (
    <ModalStandard
      content={
        <RemediationModalStyled>
          <RemediationIssuesTable repoId={repoId} depFileUuid={depFileUuid} effortLevel={effortLevel} />
        </RemediationModalStyled>
      }
    />
  );
};
