import { axiosConfig as axios } from '@Utils';
import { IApiDependenciesParams } from '../types';

export const fetchDependencies = async ({ pagination, search, repoId, filters, diff }: IApiDependenciesParams, token: string) => {
  const response = await axios('dependencies', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(pagination && {
        page: pagination?.pageIndex + 1,
        take: pagination?.pageSize,
      }),
      ...(search && { search }),
      repoId,
      ...(filters && filters),
      diff,
    },
  });
  return response?.data;
};
