import React from 'react';
import { ModalStandard } from '@Modal';
import { getFullRepositoryName } from '@Utils';
import { Loader } from '@Components';
import { ModalContentStyled, ScanConfigSetRepositoryLinkStyled, LinkIconStyled } from './styled';
import { IScanConfigSetRepositoriesModalProps } from './interfaces';
import { useScanConfigSetRepositories } from './hooks';

export const ScanConfigSetRepositoriesModal: React.FC<IScanConfigSetRepositoriesModalProps> = ({ configSetUuid, name }) => {
  const { data: repositories = [], isLoading } = useScanConfigSetRepositories(configSetUuid);

  return (
    <ModalStandard
      title={`Repositories using '${name}' configuration`}
      content={
        <ModalContentStyled>
          {isLoading ? (
            <Loader />
          ) : (
            repositories.map((repository) => (
              <ScanConfigSetRepositoryLinkStyled to={`/discovery/repositories/${repository.uuid}`} key={repository.uuid} target="_blank" rel="noreferrer">
                <span>{getFullRepositoryName(repository.owner, repository.name)}</span>
                <LinkIconStyled />
              </ScanConfigSetRepositoryLinkStyled>
            ))
          )}
        </ModalContentStyled>
      }
    />
  );
};
