import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { ResourceContext, resourceContextDefault } from '@Contexts';
import { TabPages } from '@Components';
import { useIssuesSummary } from '../../hooks';
import { EIssueGroup, IIssuesGroupsContextType } from '../../interfaces';
import { IssueTabStyled } from './styled';
import { usePullRequestDetailsContext } from 'Layouts/DiscoveryPage/hooks';
import { IPullRequestDetailsContext } from 'Layouts/DiscoveryPage/components/PullRequestDetails/types';
import { IIssueTabProps } from './interfaces';
import { IssuesSourceTypeSwitch } from '../IssuesSourceTypeSwitch';
import { Flex } from '@Styles';
import { EIssueSourceType } from '@blindspot/common/types/bff';

export const IssuesTab: React.FC<IIssueTabProps> = ({ isDiff }: IIssueTabProps) => {
  const { repoId, depId, branchUuid } = useParams();

  const [allIssuesCount, setAllIssuesCount] = useState(0);
  const [recommendedIssuesCount, setRecommendedIssuesCount] = useState(0);
  const [lowRiskIssuesCount, setLowRiskIssuesCount] = useState(0);
  const [ignoredIssuesCount, setIgnoredIssuesCount] = useState(0);
  const [fixedIssuesCount, setFixedIssuesCount] = useState(0);

  const { pullRequest } = usePullRequestDetailsContext() || {};
  const branchId = pullRequest?.headBranch?.uuid || branchUuid;
  const location = useLocation();
  const issueSourceType = location.pathname.includes('first-party') ? EIssueSourceType.FIRST_PARTY : null;
  const { data: issuesSummary } = useIssuesSummary({ issueSourceType, ...(isDiff && branchId ? { diff: { branchId }, repoId, depId } : { repoId, depId }) });
  const { fixed = 0, ignored = 0, lowRisk = 0, recommended = 0, total = 0 } = issuesSummary || {};

  useEffect(() => {
    resetIssuesCounts();
  }, [fixed, ignored, lowRisk, recommended, total]);

  const resetIssuesCounts = () => {
    setAllIssuesCount(total);
    setRecommendedIssuesCount(recommended);
    setLowRiskIssuesCount(lowRisk);
    setIgnoredIssuesCount(ignored);
    setFixedIssuesCount(fixed);
  };

  const tabItems = useMemo(() => {
    return [
      {
        title: 'All',
        key: EIssueGroup.ALL,
        linkTo: EIssueGroup.ALL,
        number: allIssuesCount,
      },
      {
        title: 'Recommended',
        key: EIssueGroup.RECOMMENDED,
        linkTo: EIssueGroup.RECOMMENDED,
        number: recommendedIssuesCount,
      },
      {
        title: 'Low Risk',
        key: EIssueGroup.LOW_RISK,
        linkTo: EIssueGroup.LOW_RISK,
        number: lowRiskIssuesCount,
      },
      {
        title: 'Ignored',
        key: EIssueGroup.IGNORED,
        linkTo: EIssueGroup.IGNORED,
        number: ignoredIssuesCount,
      },
      {
        title: 'Fixed',
        key: EIssueGroup.FIXED,
        linkTo: EIssueGroup.FIXED,
        number: fixedIssuesCount,
      },
    ];
  }, [allIssuesCount, recommendedIssuesCount, lowRiskIssuesCount, fixedIssuesCount, ignoredIssuesCount]);

  return (
    <IssueTabStyled>
      <ResourceContext.Provider value={resourceContextDefault}>
        <Flex gap={2}>
          <IssuesSourceTypeSwitch />
          <TabPages items={tabItems} onClick={resetIssuesCounts} />
        </Flex>
        <Outlet
          context={
            {
              setAllIssuesCount,
              setRecommendedIssuesCount,
              setLowRiskIssuesCount,
              setIgnoredIssuesCount,
              setFixedIssuesCount,
              pullRequest,
            } as IIssuesGroupsContextType & IPullRequestDetailsContext
          }
        />
      </ResourceContext.Provider>
    </IssueTabStyled>
  );
};
