import { Flex } from '@Styles';
import styled from 'styled-components';

export const DrawerRemediationRecommendationContainerStyled = styled(Flex)`
  flex-direction: column;
  background: ${({ theme }) => theme.colors.zinc[100]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  padding: ${({ theme }) => theme.spacing[1]} ${({ theme }) => theme.spacing[2.5]};
  line-height: ${({ theme }) => theme.spacing[3]};
`;
