import { useQuery } from 'react-query';
import { ITicket } from '@blindspot/common/types/bff/tickets';
import { fetchRepositoryTicket } from './api';
import { useSession } from '@descope/react-sdk';

export const useRepositoryTicket = (repoUuid: string, enabled: boolean) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['repoTicket', repoUuid],
    queryFn: async () => {
      return await fetchRepositoryTicket(repoUuid, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && enabled && !isSessionLoading,
  };

  return useQuery<ITicket>(queryOptions);
};
