import styled, { css } from 'styled-components';

export const CodeBlockNoDataLabelStyled = styled.span(
  ({ theme }) => css`
    text-align: center;
    align-self: center;
    width: 100%;
    color: ${theme.colors.zinc[600]};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 22.4px;
    letter-spacing: 0.15px;
  `,
);
