import React from 'react';
import { ReachabilityNoDataIconStyled, ReachabilityNoDataStyled } from './styled';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { Loader } from '@Components';

export const ReachabilityNoData: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <ReachabilityNoDataStyled>
      <ReachabilityNoDataIconStyled icon={faCircleCheck} />
      <p>No affected repositories</p>
    </ReachabilityNoDataStyled>
  );
};
