import React from 'react';
import { DiscoveryProgressBar } from './DiscoveryProgressBar';
import { ProgressBarPopup } from './styled';

export const DiscoveryProgressBarPopup: React.FC = () => {
  const disablePageScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enablePageScroll = () => {
    document.body.style.overflow = 'initial';
  };

  return (
    <ProgressBarPopup onMouseEnter={disablePageScroll} onMouseLeave={enablePageScroll}>
      <DiscoveryProgressBar />
    </ProgressBarPopup>
  );
};
