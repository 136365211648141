import { ERelationship } from '@APITypes';
import { ESeverityLevelFilter } from '@blindspot/common/types/bff';
import { supportedLanguageFilterOptions } from 'core/Filter/types';

export const dependencyFilters = [
  {
    id: 'relationship',
    label: 'Relationship',
    options: [
      {
        label: 'Direct',
        value: ERelationship.DIRECT,
        count: 0,
      },
      {
        label: 'Indirect',
        value: ERelationship.INDIRECT,
        count: 0,
      },
      {
        label: 'Both',
        value: ERelationship.BOTH,
        count: 0,
      },
    ],
  },
  {
    id: 'languages',
    label: 'Languages',
    options: supportedLanguageFilterOptions,
  },
  {
    id: 'security_status',
    label: 'Security Status',
    options: [
      { label: 'Low', value: ESeverityLevelFilter.LOW, count: 0 },
      { label: 'Medium', value: ESeverityLevelFilter.MEDIUM, count: 0 },
      { label: 'High', value: ESeverityLevelFilter.HIGH, count: 0 },
      { label: 'Critical', value: ESeverityLevelFilter.CRITICAL, count: 0 },
      { label: 'No Issues', value: ESeverityLevelFilter.SAFE, count: 0 },
    ],
  },
  {
    id: 'repositoriesCountRange',
    label: 'Repositories count',
    min: 0,
    max: 30,
    isMulti: true,
  },
];
