import { Flex } from '@Styles';
import { TooltipContainer } from 'Layouts/components/Tooltip/styled';
import styled from 'styled-components';

export const RemediationSummaryIssueLabelsStyled = styled(Flex)`
  ${TooltipContainer}:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing[0.5]};
  }

  ${TooltipContainer}:last-child {
    margin-right: 0;
  }
`;
