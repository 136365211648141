import { useQuery, UseQueryOptions } from 'react-query';
import { fetchOrganizationTokens } from '../api';
import { IOrganizationToken } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useOrganizationTokens = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions: UseQueryOptions<IOrganizationToken[]> = {
    queryKey: ['organizationTokens'],
    enabled: isAuthenticated && !isSessionLoading,
    queryFn: async () => {
      return await fetchOrganizationTokens(sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  };
  return useQuery<IOrganizationToken[]>(queryOptions);
};
