import styled from 'styled-components';
import { Flex } from '@Styles';

export const LoaderContainerStyled = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: center;
  align-items: center;
`;
