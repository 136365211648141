import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useForm, Controller, SubmitHandler, UseControllerReturn } from 'react-hook-form';
import { ENotificationType, toastService } from '@Notification';
import { useModal } from '@Modal';
import { useIntegrationOrganizations, useUpdateIntegrationOrganization } from './hooks';
import { Form } from './styled';
import { ISelectIntegrationOrganizationFormProps, ISelectIntegrationOrganizationFormData } from './interfaces';
import { pageTools } from '@Utils';
import { SubmitButton } from '@Components/Form';
import { DropdownSelect } from '@Components';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const SelectIntegrationOrganizationForm: React.FC<ISelectIntegrationOrganizationFormProps> = ({ provider }) => {
  const { data: organizations = [] } = useIntegrationOrganizations(provider);
  const { mutate: updateIntegrationOrganization } = useUpdateIntegrationOrganization();
  const { closeModal } = useModal();
  const defaultValues: ISelectIntegrationOrganizationFormData = { organizationId: '' };
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm<ISelectIntegrationOrganizationFormData>({
    defaultValues,
  });

  const organizationSelectOptions = organizations.map((organization) => {
    return { value: organization.id, name: organization.name };
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  const onSubmit: SubmitHandler<ISelectIntegrationOrganizationFormData> = async ({ organizationId }) => {
    updateIntegrationOrganization(organizationId, {
      onSuccess() {
        closeModal();
        window.location.href = pageTools.dashboard.path;
      },
      onError(error) {
        if (error instanceof AxiosError) {
          toastService({ header: 'Something went wrong', type: ENotificationType.ERROR });
        }
      },
    });
  };

  const renderOrganizationSelect = ({ field: { onChange, onBlur, value }, fieldState: { error } }: UseControllerReturn<ISelectIntegrationOrganizationFormData>) => (
    <DropdownSelect
      name="organization"
      placeholder={`Select ${provider === EScmType.GITLAB ? 'group' : 'organization'}`}
      value={value}
      options={organizationSelectOptions}
      error={error?.message}
      onBlur={onBlur}
      onChange={(value) => {
        onChange(value);
      }}
    />
  );

  const submitDisabled = !isDirty || !isValid || !!Object.keys(errors).length;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller name="organizationId" control={control} render={renderOrganizationSelect} />
      <SubmitButton type="submit" disabled={submitDisabled}>
        Apply
      </SubmitButton>
    </Form>
  );
};
