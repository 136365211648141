import styled from 'styled-components';

import { Flex } from '@Styles';

export const NoRemediationContentStyled = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing[3.5]};
`;
