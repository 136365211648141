import { useMutation } from 'react-query';
import { deleteOrganizationUser } from '../api';
import { useSession } from '@descope/react-sdk';

export const useOrganizationUsersDelete = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async (userId: string) => {
    if (isAuthenticated && !isSessionLoading) {
      return deleteOrganizationUser(userId, sessionToken);
    }
  });
};
