import styled from 'styled-components';

export const RemediationItemStyled = styled.li<{ $isToggled?: boolean }>`
  position: relative;
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
  width: 100%;
  font-size: ${({ theme }) => theme.text.size.body2};
  line-height: 2;
  letter-spacing: 0.2px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;

  ${({ $isToggled }) => $isToggled && `box-shadow: ${({ theme }) => theme.shadows[3]};`}

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[1.5]};
  }
`;
