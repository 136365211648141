import { axiosConfig as axios } from '@Utils';
import { ITicketDescriptionRequestParams } from '../../types';

export const getTicketDescription = async (token: string, { baseIssueUuid, repoUuid }: ITicketDescriptionRequestParams) => {
  const response = await axios.post(
    `/tickets/generate/description`,
    { baseIssueUuid, repoUuid },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response?.data;
};
