import React from 'react';
import { useModal } from '@Modal';
import { AttentionIconStyled, ExpirationTokenModalText, ExpirationTokenModalTitle, ExpirationTokenModalWrapper, LogoStyled } from './styled';
import { IExpirationTokenModalProps } from './interfaces';
import { ESign, SignRender } from '../SignRender';
import { pageTools } from '@Utils';
import { ActionContainer } from './styled/ActionContainer.styled';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Button, ButtonVariant, ButtonSize } from '@Components';
import { NotAvailable } from '@APITypes';
import { format, isValid } from 'date-fns';

export const ExpirationTokenModal: React.FC<IExpirationTokenModalProps> = ({ isExpired, expiredAt }) => {
  const { closeModal } = useModal();
  const expiredDate = isValid(new Date(expiredAt)) ? format(new Date(expiredAt), 'dd MMM yyyy') : NotAvailable;
  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (e) => e.stopPropagation();

  const goToIntegrationClickHandler = () => {
    window.location.href = pageTools.integrations.path;
  };

  return (
    <ExpirationTokenModalWrapper onClick={stopPropagation}>
      <LogoStyled>
        <SignRender name={ESign.gitlab} />
        <AttentionIconStyled icon={faCircleExclamation} />
      </LogoStyled>
      {isExpired ? (
        <>
          <ExpirationTokenModalTitle>Your GitLab token has expired</ExpirationTokenModalTitle>
          <ExpirationTokenModalText>You have been disconnected from GitLab.</ExpirationTokenModalText>
          <ExpirationTokenModalText>To reconnect to GitLab go to the integrations page.</ExpirationTokenModalText>
        </>
      ) : (
        <>
          <ExpirationTokenModalTitle>Your GitLab token is about to expire</ExpirationTokenModalTitle>
          <ExpirationTokenModalText>{`The token used for your GitLab integration will expire on ${expiredDate}.`}</ExpirationTokenModalText>
          <ExpirationTokenModalText>To remain connected to GitLab you must update the token through the integrations page.</ExpirationTokenModalText>
        </>
      )}
      <ActionContainer>
        <Button onClick={goToIntegrationClickHandler} variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE}>
          Go to integrations
        </Button>
        <Button onClick={closeModal} size={ButtonSize.LARGE}>
          Dismiss
        </Button>
      </ActionContainer>
    </ExpirationTokenModalWrapper>
  );
};
