import { MutateOptions, useMutation } from 'react-query';
import { ignoreSastIssue } from '../api';
import { useSession } from '@descope/react-sdk';

export const useIgnoreSastIssueByFingerprint = (fingerprint: string, options?: MutateOptions<unknown, unknown, { ignored: boolean; reason: string }>) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(
    async ({ ignored, reason }: { ignored: boolean; reason: string }) => {
      if (isAuthenticated && !isSessionLoading) {
        return ignoreSastIssue(sessionToken, fingerprint, { ignored, reason });
      }
    },
    {
      retry: false,
      ...options,
    },
  );
};
