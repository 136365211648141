import styled, { css } from 'styled-components';

export const ReportDescriptionWrapper = styled.p<{ $small?: boolean; $lowercase?: boolean }>(
  ({ theme, $small, $lowercase }) => css`
    line-height: 22.4px;
    letter-spacing: 0.15px;
    color: ${theme.colors.zinc[400]};
    font-size: ${$small ? theme.text.size.caption : theme.text.size.body2};

    ${$lowercase &&
    css`
      text-transform: lowercase;
    `}
  `,
);
