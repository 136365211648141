import React, { useMemo, useState } from 'react';
import { createColumnHelper, PaginationState } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { Table, initialTablePagination } from 'core/Table';
import { useParams } from 'react-router-dom';
import { useRepositoryDependenciesFiles } from './hooks';
import { IDependenciesFilesTableData } from './interfaces';
import { FilePath, TableNoData } from './styled';
import { CopyButton } from '@Components';
import { Flex } from '@Styles';
import { usePagination, useQueryParams } from '@Utils';
import { initialPaginatedData } from 'core/Table/config';

export const RepositoryDependenciesFilesTable: React.FC = () => {
  const { repoId } = useParams();
  const { queryParams } = useQueryParams({});
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: queryParams.page - 1 || initialTablePagination.pageIndex,
    pageSize: queryParams.size || initialTablePagination.pageSize,
  });
  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
  const { data: repoDependenciesFiles = initialPaginatedData, isLoading } = useRepositoryDependenciesFiles(repoId, pagination);

  usePagination(pageIndex, pageSize);

  const columnHelper = createColumnHelper<IDependenciesFilesTableData>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('path', {
        header: () => 'File Path',
        cell: ({ row }) => {
          const { path } = row.original;
          return (
            <Flex>
              <FilePath>{path}</FilePath>
              <CopyButton data={path} />
            </Flex>
          );
        },
      }),
    ],
    [],
  );

  const emptyStateComponent = (
    <TableNoData>
      <FontAwesomeIcon icon={faCircleCheck} />
      <p>No dependencies files</p>
    </TableNoData>
  );

  const options = {
    pagination: {
      value: pagination,
      pageCount: repoDependenciesFiles.meta.pageCount,
      itemCount: repoDependenciesFiles.meta.itemCount,
      onChange: setPagination,
    },
  };

  return <Table columns={columns} data={repoDependenciesFiles.data} tableFeatures={options} isLoading={isLoading} emptyState={emptyStateComponent} />;
};
