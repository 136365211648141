import styled, { css } from 'styled-components';

export const ScanConfigsNoteStyled = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.zinc[100]};
    border-radius: 9px;
    padding: ${theme.spacing[1]} ${theme.spacing[2]};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 24px;
    letter-spacing: 0.2px;
    margin-top: ${theme.spacing[3]};
  `,
);
