import { Flex } from '@Styles';
import styled from 'styled-components';

export const IconCircleStyled = styled(Flex)`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.indigo[100]};
  align-items: center;
  justify-content: center;
`;
