import { ReachabilityTreeNodeTitleProps } from './types';
import { SecondaryTextStyled } from './styled';

export const TreeNodeTitle: React.FC<ReachabilityTreeNodeTitleProps> = (node) => {
  const usages = Array.isArray(node.children) ? node.children.length : 0;
  return (
    <>
      {node.name}
      {usages > 1 && <SecondaryTextStyled> ({usages} usages)</SecondaryTextStyled>}
      <SecondaryTextStyled> ({node.file})</SecondaryTextStyled>
    </>
  );
};
