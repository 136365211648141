import React, { useMemo, useState } from 'react';
import { createColumnHelper, PaginationState, VisibilityState } from '@tanstack/react-table';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCodeCompare, faFolder } from '@fortawesome/pro-regular-svg-icons';
import { SignRender, InfoLabel, Tooltip } from '@Components';
import { Table, TableNotifications, initialTablePagination } from 'core/Table';
import { NotAvailable } from '@APITypes';
import { ModalProvider } from '@Modal';
import { useLocation, useParams } from 'react-router-dom';
import { DependencyFilesCell } from '../DependencyFileCell';
import { DependencyFullnameCell } from '../DependencyFullnameCell';
import { InfoLabelTypes } from 'Layouts/components/InfoLabel/interfaces';
import { ExportButton } from 'Layouts/Exports/components/ExportButton';
import { EExportType } from 'Layouts/Exports/components/ExportButton/interfaces';
import { DATE_SHORT_FORMAT, getFullDependencyName, isEmpty, useDepTreeDiscoverFinish, usePostProcessing, usePostProtect, useProtectFinish, useProtectStart } from '@Utils';
import { useDependencies } from './hooks';
import { IDependenciesTableProps, IDependencyNormalized, dependencyFilters } from './types';
import { TableNoData, IssuesCell, Relationship } from './styled';
import { usePagination, useQueryParams, useSearch } from 'Utils/hooks';
import { initialPaginatedData } from 'core/Table/config';
import { useQueryClient } from 'react-query';
import { useFilter } from 'core/Filter';
import { ActionsDropdown } from 'core/Dropdown';
import { usePullRequestDetailsContext } from 'Layouts/DiscoveryPage/hooks';
import { useDependenciesSummary } from '@Hooks';

export const DependenciesTable: React.FC<IDependenciesTableProps> = ({ showColumnVisibility = false, withFixed = false, isDiff = false }) => {
  const queryClient = useQueryClient();
  const { repoId } = useParams();

  const { queryParams } = useQueryParams({});
  const [searchValue, setSearchValue] = useState(queryParams.search);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    fullName: false,
    scanDate: false,
    totalIssues: false,
  });
  const [newDependenciesCount, setNewDependenciesCount] = useState(0);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: queryParams.page - 1 || initialTablePagination.pageIndex,
    pageSize: queryParams.size || initialTablePagination.pageSize,
  });
  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
  const { appliedFilters, onFiltersApplied } = useFilter();
  const filtersWithFixedIssues = { ...appliedFilters, 'filter[withFixed]': 'true' };
  const { pullRequest } = usePullRequestDetailsContext() || {};
  const branchId = pullRequest?.headBranch?.uuid;
  const diff = useMemo(() => (branchId && isDiff ? { branchId } : undefined), [branchId, isDiff]);
  const location = useLocation();
  const { data: filtersData } = useDependenciesSummary({ filter: appliedFilters });

  const dependenciesFiltersWithMaxRepoRange = dependencyFilters.map((filter) => {
    if (filter.id === 'repositoriesCountRange') {
      filter.max = filtersData?.maxRepositoryCount;
    }

    return filter;
  });
  const { data: dependencies = initialPaginatedData, isLoading } = useDependencies({
    pagination,
    search: searchValue,
    repoId,
    filters: withFixed ? filtersWithFixedIssues : appliedFilters,
    diff,
  });

  const invalidateDependencies = () =>
    queryClient.invalidateQueries({
      queryKey: [
        'dependencies',
        {
          ...(repoId && { repoId }),
          ...(!isEmpty(appliedFilters) && { filters: withFixed ? filtersWithFixedIssues : appliedFilters }),
          ...(diff && { diff }),
          ...(!isEmpty(searchValue) && { search: searchValue }),
        },
      ],
    });
  useProtectFinish(invalidateDependencies);
  useProtectStart(invalidateDependencies);
  useDepTreeDiscoverFinish((data) => {
    const dependenciesCount = data?.dependenciesCount || 0;
    setNewDependenciesCount((prevState) => prevState + dependenciesCount);
  });
  usePostProcessing(invalidateDependencies);
  usePostProtect(invalidateDependencies);
  usePagination(pageIndex, pageSize);
  useSearch(searchValue, { pageSize, setPagination });
  onFiltersApplied(() => {
    setPagination({ pageSize, pageIndex: 0 });
  });

  const getRowLink = (row: IDependencyNormalized) => (diff ? `${location.pathname}/${row.uuid}/branch/${branchId}/issues/all` : `/discovery/dependencies/${row.uuid}/issues/all`);

  const onDiscoveryClose = () => {
    setNewDependenciesCount(0);
  };

  const onDiscoveryClicked = () => {
    invalidateDependencies();
    onDiscoveryClose();
  };

  const notifications = (
    <TableNotifications
      icon={faCodeCompare}
      message={`${newDependenciesCount} new ${newDependenciesCount > 1 ? 'dependencies' : 'dependency'} discovered`}
      onClick={onDiscoveryClicked}
      onClose={onDiscoveryClose}
    />
  );

  const columnHelper = createColumnHelper<IDependencyNormalized>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        size: 120,
      }),
      columnHelper.accessor('fullName', {
        header: 'Full Name',
        cell: ({ row }) => {
          const { owner, name, installedVersion } = row.original;
          return <DependencyFullnameCell fullName={getFullDependencyName(owner, name, installedVersion)} />;
        },
        size: 150,
      }),
      columnHelper.accessor('installedVersion', {
        header: 'Installed Version',
        size: 100,
      }),
      columnHelper.accessor('dependencyOrigin', {
        header: 'Origin',
        cell: ({ row }) => {
          const { dependencyOrigin } = row.original;
          return <SignRender size={dependencyOrigin.toLowerCase() === 'maven' ? 'default' : 'semi-small'} name={dependencyOrigin} />;
        },
        size: 100,
      }),
      columnHelper.accessor('securityStatus', {
        header: 'Security Status',
        cell: ({ row }) => {
          const { securityStatus } = row.original;
          return (
            <InfoLabel capitalize type={securityStatus ? securityStatus : InfoLabelTypes.Success}>
              <span data-test={typeof securityStatus}>{securityStatus ? securityStatus : 'no issues'}</span>
            </InfoLabel>
          );
        },
      }),
      columnHelper.accessor('monitoringStatus', {
        header: 'Monitoring Status',
        cell: ({ row }) => {
          const { monitoringStatus } = row.original;
          return (
            <InfoLabel capitalize type={monitoringStatus}>
              {monitoringStatus}
            </InfoLabel>
          );
        },
      }),
      columnHelper.accessor('totalIssues', {
        header: 'Issues',
      }),
      columnHelper.accessor('severeIssues', {
        header: 'Severe Issues',
        cell: ({ row }) => {
          const { severeIssues } = row.original;
          return (
            <IssuesCell>
              <span>{`${severeIssues.critical || 0} Critical`}</span>
              <span>{`${severeIssues.high || 0} High`}</span>
            </IssuesCell>
          );
        },
      }),
      columnHelper.accessor('buildCount', {
        header: 'Connections',
        cell: ({ row }) => {
          const dependency = row.original;
          return (
            <Tooltip tooltip={`${dependency.repositoriesCount} ${dependency.repositoriesCount == 1 ? 'Repository' : 'Repositories'}`}>
              <InfoLabel icon={faFolder}>{dependency.repositoriesCount}</InfoLabel>
            </Tooltip>
          );
        },
      }),
      columnHelper.accessor('relationship', {
        header: 'Relationship',
        cell: ({ row }) => {
          const { relationship } = row.original;
          return <Relationship>{relationship}</Relationship>;
        },
        size: 100,
      }),
      columnHelper.accessor('repositories', {
        header: 'Dependency Files',
        cell: ({ row }) => {
          const { repositories } = row.original;
          return <DependencyFilesCell repositories={repositories} />;
        },
      }),
      columnHelper.accessor('latestVersion', {
        header: 'Latest Version',
        size: 100,
      }),
      columnHelper.accessor('scanDate', {
        header: 'Last Scan Date',
        cell: ({ row }) => {
          const { scanDate } = row.original;
          return <span>{scanDate ? format(new Date(scanDate), DATE_SHORT_FORMAT) : NotAvailable}</span>;
        },
      }),
    ],
    [],
  );

  const tableActions = <ActionsDropdown menuItems={[<ExportButton key="export-dependencies" type={EExportType.DEPENDENCIES} repoId={repoId} />]} />;

  const options = {
    pagination: {
      value: pagination,
      onChange: setPagination,
      pageCount: dependencies.meta.pageCount,
      itemCount: dependencies.meta.itemCount,
    },
    ...(showColumnVisibility && {
      columnVisibility: {
        show: true,
        value: columnVisibility,
        onChange: setColumnVisibility,
      },
    }),
    search: {
      value: searchValue,
      onChange: (value: string) => setSearchValue(value),
    },
    ...(!!newDependenciesCount && {
      notifications,
    }),
    ...(dependencies.meta.itemCount && {
      actions: tableActions,
    }),
    filters: dependenciesFiltersWithMaxRepoRange,
  };

  const emptyStateComponent = (
    <TableNoData>
      <FontAwesomeIcon icon={faCircleCheck} />
      <p>No dependencies</p>
    </TableNoData>
  );

  return (
    <ModalProvider>
      <Table columns={columns} data={dependencies.data} tableFeatures={options} isLoading={isLoading} getRowLink={getRowLink} emptyState={emptyStateComponent} />
    </ModalProvider>
  );
};
