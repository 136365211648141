import { axiosConfig as axios } from '@Utils';
import { IApiReachabilityParams } from '../types';

export const fetchReachability = async ({ baseIssueUuid, search }: IApiReachabilityParams, token: string) => {
  const response = await axios(`issues/details/${baseIssueUuid}/reachability`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { ...(search && { search }) },
  });
  return response?.data;
};
