import React from 'react';
import { faChevronDown, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownIndicatorProps, components } from 'react-select';

export const FilterDropdownIcon: React.FC<DropdownIndicatorProps> = (props) => {
  const icon = props.isFocused ? faSearch : faChevronDown;

  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={icon} />
    </components.DropdownIndicator>
  );
};
