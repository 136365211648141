import styled from 'styled-components';
import { Grid } from '@Styles';

export const BoxWrap = styled(Grid)<{ $twoCells: boolean; $small?: boolean }>`
  margin-top: ${({ theme }) => theme.spacing['3']};
  gap: ${({ theme, $small }) => theme.spacing[$small ? '0.5' : '1']};

  ${({ $twoCells, theme }) =>
    $twoCells &&
    `
    grid-template-columns: repeat(2, 1fr);
    gap: 0;

    ${BoxWrap}:first-child {
      padding-right: ${theme.spacing['3']};
    }
    ${BoxWrap}:last-child {
      padding-left: ${theme.spacing['3']};
      border-left: 1px solid ${theme.colors.zinc['200']};
    }
  `}
`;
