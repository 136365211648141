import { ContentBlock } from '@Styles';
import styled from 'styled-components';

export const ScoreBlock = styled(ContentBlock)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 411px;
  padding: ${({ theme: { spacing } }) => `${spacing['3']} ${spacing['4']} ${spacing['2.5']} ${spacing['4']}`};
  color: ${({ theme }) => theme.colors.zinc['800']};
`;
