import React, { useEffect, useState } from 'react';
import { RemediationRecommendedLabelStyled, RemediationTargetContentStyled, RemediationTargetIssuesListStyled, RemediationTargetStyled } from './styled';
import { IRemediationTargetProps } from './types';
import { RemediationTargetIssues } from './RemediationTargetIssues';
import { Loader } from '@Components';

export const RemediationTarget: React.FC<IRemediationTargetProps> = ({ targets, dependency, isLoading, onChange, selectedTarget }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(
      targets.map((target) => ({
        value: target.version,
        ...(target.recommended && {
          label: <RemediationRecommendedLabelStyled>Recommended</RemediationRecommendedLabelStyled>,
        }),
      })),
    );
  }, [targets?.length]);

  return (
    <RemediationTargetStyled>
      <RemediationTargetContentStyled>
        {isLoading ? (
          <Loader />
        ) : (
          selectedTarget && (
            <>
              <RemediationTargetIssuesListStyled>
                <RemediationTargetIssues title="Current issues" issues={selectedTarget.issues.currentStatus} />
                <RemediationTargetIssues title="Issues Fixed" issues={selectedTarget.issues.fixesAvailable} />
                <RemediationTargetIssues title="Issued Introduced" issues={selectedTarget.issues.introduced} />
              </RemediationTargetIssuesListStyled>
              <RemediationTargetIssues title="Status after upgrade" issues={selectedTarget.issues.statusAfterFixes} isBold />
            </>
          )
        )}
      </RemediationTargetContentStyled>
    </RemediationTargetStyled>
  );
};
