import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationSummaryContentStyled = styled(Flex)`
  align-items: center;
  width: 100%;
  min-height: 64px;
  padding-top: ${({ theme }) => theme.spacing[2]};
  border-top: 1px solid ${({ theme }) => theme.colors.zinc[200]};
`;
