import React from 'react';
import { ICopyClipboard } from './types';
import { CopyClipboardIconStyled, CopyClipboardStyled } from './styled';
import { copyToClipboard } from '@Utils';

export const CopyClipboard: React.FC<ICopyClipboard> = ({ copyText, children, name }) => {
  return (
    <CopyClipboardStyled onClick={() => copyToClipboard(copyText, name)}>
      {children}
      <CopyClipboardIconStyled />
    </CopyClipboardStyled>
  );
};
