import styled, { css } from 'styled-components';

export const RadioButtonStyled = styled.input(
  ({ theme }) => css`
    cursor: pointer;
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1.5px solid ${theme.colors.zinc[500]};
    transition: 0.2s all linear;
    position: relative;

    &:checked {
      border: 1.5px solid ${theme.colors.indigo[500]};

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background-color: ${theme.colors.indigo[500]};
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  `,
);
