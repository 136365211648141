import { css } from 'styled-components';
import { ButtonVariant } from '../interfaces';

export const indigoButtonVariants = ($variant: ButtonVariant) => {
  switch ($variant) {
    case ButtonVariant.TEXT: {
      return css`
        background-color: ${({ theme }) => theme.colors.transparent};
        color: ${({ theme }) => theme.colors.indigo[500]};
        &:hover {
          background-color: ${({ theme }) => theme.colors.zinc[100]};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.transparent};
          color: ${({ theme }) => theme.colors.zinc[400]};
        }
      `;
    }
    case ButtonVariant.OUTLINED: {
      return css`
        background-color: ${({ theme }) => theme.colors.transparent};
        color: ${({ theme }) => theme.colors.indigo[500]};
        border: 1px solid ${({ theme }) => theme.colors.indigo[300]};
        &:hover {
          background-color: ${({ theme }) => theme.colors.zinc[100]};
        }
        &:disabled {
          color: ${({ theme }) => theme.colors.zinc[200]};
          border: 1px solid ${({ theme }) => theme.colors.zinc[200]};
        }
      `;
    }
    case ButtonVariant.CONTAINED: {
      return css`
        background-color: ${({ theme }) => theme.colors.indigo[500]};
        color: ${({ theme }) => theme.colors.white};
        &:hover {
          background-color: ${({ theme }) => theme.colors.indigo[600]};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.zinc[200]};
          color: ${({ theme }) => theme.colors.zinc[400]};
        }
      `;
    }
    default: {
      return css`
        background-color: ${({ theme }) => theme.colors.transparent};
        color: ${({ theme }) => theme.colors.indigo[500]};
        &:hover {
          background-color: ${({ theme }) => theme.colors.zinc[100]};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.colors.transparent};
          color: ${({ theme }) => theme.colors.zinc[400]};
        }
      `;
    }
  }
};
