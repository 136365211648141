import styled from 'styled-components';
import { Flex } from '@Styles';

export const IssuesCell = styled(Flex)`
  gap: ${({ theme }) => theme.spacing[1]};

  span {
    white-space: nowrap;
  }
`;
