import { createColumnHelper } from '@tanstack/react-table';
import { IIssueColumns1stParty } from './types';
import { NotAvailable } from '@APITypes';
import { InfoLabel, InfoLabelTypes } from '@Components';
import { DATE_TIME_LONG_FORMAT, firstLetterToUpperCase } from '@Utils';
import { format } from 'date-fns';
import { IssueCategoryLabel } from '@blindspot/common/types/bff';
import { FixAvailableDotStyled } from './styled';
import { CreateTicketButton } from '../issueDetails/IssueNavContainer/CreateTicketModal';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { AlignCenter } from '../IssuesTable/styled';
import { isNewIssue } from 'Layouts/IssuesPage/utils';
import { NewChip } from '../IssuesTable/NewChip';

const columnHelper = createColumnHelper<IIssueColumns1stParty>();

export const getColumns = () => [
  columnHelper.accessor('severity', {
    header: 'Severity',
    cell: (severity) => (
      <InfoLabel type={severity.getValue()} capitalize>
        {severity.getValue()}
      </InfoLabel>
    ),
    size: 50,
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    cell: (cell) => {
      const isNew = isNewIssue(cell.row.original.createdAt);
      return (
        <span>
          {firstLetterToUpperCase(cell.getValue())}
          {isNew && <NewChip />}
        </span>
      );
    },
    size: 100,
  }),
  columnHelper.accessor('weaknesses', {
    header: 'CWE',
    cell: (cell) => {
      return (
        <span>
          {cell
            .getValue()
            .map((weakness) => weakness?.name)
            .join(', ')}
        </span>
      );
    },
    size: 100,
  }),
  columnHelper.accessor('repositoryName', {
    header: 'Repository',
    cell: (cell) => {
      return <span>{cell.getValue()}</span>;
    },
    size: 120,
  }),
  columnHelper.accessor('path', {
    header: 'Path',
    cell: (cell) => {
      return <span>{cell.getValue()}</span>;
    },
    size: 120,
  }),
  // columnHelper.accessor('tickets', {
  //   header: 'Ticket',
  //   cell: ({ row }) => {
  //     const sastIssue = row.original;
  //     // TODO implement the create -- probably it will be by it's fingerprint not uuid
  //     return (
  //       <AlignCenter>
  //         <CreateTicketButton baseIssueUuid={sastIssue.uuid} isIconView tickets={sastIssue.tickets} />
  //       </AlignCenter>
  //     );
  //   },
  //   size: 10,
  // }),
  columnHelper.accessor('count', {
    header: 'Count',
    cell: (cell) => {
      return <span>{cell.getValue()}</span>;
    },
    size: 10,
  }),

  columnHelper.accessor('category', {
    header: 'Category', // should alwasy be vulerability for 1st party code
    cell: (category) => <InfoLabel type={InfoLabelTypes.Default}>{IssueCategoryLabel.vulnerability}</InfoLabel>,
    size: 150,
  }),

  columnHelper.accessor('fixAvailable', {
    header: 'Fix Available', //at first we won't have a fix so it will always be red.
    cell: (fixAvailable) => <FixAvailableDotStyled size="xs" $status={fixAvailable.getValue() ? 'success' : 'error'} icon={faCircle} />,

    size: 10,
  }),

  columnHelper.accessor('createdAt', {
    header: 'First Seen',
    cell: (cell) => {
      const createdAt = cell.getValue();
      return <span>{createdAt ? format(new Date(createdAt), DATE_TIME_LONG_FORMAT) : NotAvailable}</span>;
    },
    size: 120,
  }),
];
