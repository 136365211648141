export interface IPageTool {
  path: string;
  title: string;
  name: string;
}

export enum pagesEnum {
  DASHBOARD = 'dashboard',
  ISSUES = 'issues',
  PRODUCTION = 'production',
  DISCOVERY = 'discovery',
  INTTEGRATIONS = 'integrations',
  SETTINGS = 'settings',
}

export type PageToolCollectionType = {
  [key in pagesEnum]: IPageTool;
};
