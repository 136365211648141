import { useEffect } from 'react';
import { useQueryParams } from './useQueryParams.hook';
import { ISearchPagination } from './types';

export const useSearch = (searchValue: string, pagination?: ISearchPagination): void => {
  const { queryParams, setQueryParams } = useQueryParams({});

  useEffect(() => {
    const isNewSearchValue = searchValue !== queryParams.search;

    if (searchValue && isNewSearchValue && pagination) {
      pagination.setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
    }

    setQueryParams({
      ...queryParams,
      ...(searchValue && isNewSearchValue && { page: 0 }),
      search: searchValue,
    });
  }, [searchValue]);
};
