export enum ERemediationEffortDiffLabels {
  STABLE = 'No API changes needed',
  GENERIC_SIGNATURE_CHANGE = 'Generic signature change',
  CLASS_REMOVED = 'Class removed',
  REMOVED = 'Method removed',
  STATIC = 'Static Function Changed',
  ABSTRACT = 'Abstract Function Changed',
  FINAL = 'Final Function Changed',
  SYNCHRONIZED = 'Synchronized Function Changed',
  NATIVE = 'Native Function Changed',
  VARARGS = 'Additional Parameter Required',
  PARAMETER_COUNT = 'Parameter Count Changed',
  RETURN_TYPE = 'Return Type Changed',
  PARAMETER_TYPE = 'Parameter Type Changed',
}
