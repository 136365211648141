import { Flex } from '@Styles';
import styled from 'styled-components';

export const DrawerContentFooterContainerStyled = styled(Flex)`
  position: sticky;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  height: 80px;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing[1.5]};
  padding: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[4]};
  margin-top: auto;
`;
