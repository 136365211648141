import React from 'react';
import { ModalStandard, useModal } from '@Modal';
import { ModalContent } from './styled';
import { GithubModalTitle } from './components';
import { Controller, UseControllerReturn, useForm } from 'react-hook-form';
import { ActionContainer } from '../InputGitLabTokenOrganizationModal/styled';
import { Button, ButtonColor, ButtonSize, ButtonVariant } from '@Components';
import { useIntegrationLink } from 'Layouts/IntegrationsPage/hooks/useIntegrationLink.hook';
import { ScanConfigsWrapperStyled } from 'Layouts/DiscoveryPage/components/SubComponents/MonitoringPreferences/components/ScanConfigSetSelect/styled';
import { RadioGroup } from '@FormElements';
import { IConnectToGithubFormData, appTypeRadioOptions, defaultConnectToGithubFormData } from './interfaces';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const ConnectToGithubModal: React.FC = () => {
  const { mutate: getScmIntegrationLink } = useIntegrationLink();
  const { control, handleSubmit } = useForm({
    defaultValues: defaultConnectToGithubFormData,
  });
  const { closeModal } = useModal();

  const onSubmit = (data) => {
    getScmIntegrationLink(
      { provider: EScmType.GITHUB, appType: data.appType },
      {
        onSuccess: (data) => {
          window.location.href = data.data.link;
        },
      },
    );
  };

  return (
    <ModalStandard
      title={<GithubModalTitle />}
      description="First, please select a connection type"
      content={
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="appType"
            control={control}
            render={({ field: { onChange, value } }: UseControllerReturn<IConnectToGithubFormData, 'appType'>) => (
              <ScanConfigsWrapperStyled>
                <RadioGroup options={appTypeRadioOptions} value={value} onChange={onChange} />
              </ScanConfigsWrapperStyled>
            )}
          />
          <ActionContainer>
            <Button onClick={closeModal} color={ButtonColor.ZINC} variant={ButtonVariant.OUTLINED} size={ButtonSize.LARGE}>
              Cancel
            </Button>
            <Button variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE} type="submit">
              Continue
            </Button>
          </ActionContainer>
        </ModalContent>
      }
    />
  );
};
