import { useContext, useEffect, useMemo, useState } from 'react';
import { ExpirationTokenModal } from './ExpirationTokenModal';
import { isPast, subDays } from 'date-fns';
import { IntegrationsContext, pageTools } from '@Utils';
import { useModal } from '@Modal';
import { useLocation } from 'react-router-dom';
import { getClosestExpiredAt } from 'Utils/integrations/getClosesExpiredAt';

export const ExpirationTokenContainer = () => {
  const { integrations } = useContext(IntegrationsContext);
  const [showedModal, setShowedModal] = useState(false);
  const { openModal } = useModal();
  const location = useLocation();
  const integrationExpiredAt = useMemo(() => getClosestExpiredAt(integrations), [integrations]);

  const isExpired = useMemo(() => {
    if (!integrationExpiredAt) {
      return false;
    }
    const expiredDate = new Date(integrationExpiredAt);
    return isPast(expiredDate);
  }, [integrationExpiredAt]);

  const isExpiring = useMemo(() => {
    if (!integrationExpiredAt) {
      return false;
    }
    const expiredDate = new Date(integrationExpiredAt);
    const sub3Days = subDays(expiredDate, 3);
    return isPast(sub3Days);
  }, [integrationExpiredAt]);

  const isIntegrationPage = location.pathname.includes(pageTools.integrations.path);

  useEffect(() => {
    if (isIntegrationPage || showedModal) {
      return;
    }
    if (isExpired || isExpiring) {
      setShowedModal(true);
      openModal(<ExpirationTokenModal isExpired={isExpired} expiredAt={integrationExpiredAt} />);
    }
  }, [isExpired, isExpiring, location.pathname]);

  return null;
};
