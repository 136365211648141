import { useQuery } from 'react-query';
import { fetchDependenciesOutOfDate } from '../api';
import { IApiDashboardFilterParams } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useDependenciesOutOfDate = (params?: IApiDashboardFilterParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['dependenciesOutOfDate', params],
    queryFn: async () => {
      return await fetchDependenciesOutOfDate(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<number>(queryOptions);
};
