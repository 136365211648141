import { axiosConfig as axios } from '@Utils';
import { IApiPullRequestParams } from '../types';

export const pullRequestRerunCli = async ({ repoId, pullRequestUuid }: IApiPullRequestParams, token: string) => {
  const response = await axios.post(
    `repositories/rerun/cli`,
    {
      repositoryUuid: repoId,
      pullRequestUuid,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response?.data;
};
