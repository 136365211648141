import { axiosConfig as axios } from '@Utils';
import { IReminder } from '@blindspot/common/types/bff/api';

export const updateUserReminder = async ({ type, remindAt }: Omit<IReminder, 'uuid'>, token: string) => {
  return axios.post(
    `reminders/${type}`,
    { remindAt },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
