import styled from 'styled-components';

export const FilterOptionCheckboxStyled = styled.input.attrs({ type: 'checkbox' })`
  border-style: solid;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.colors.zinc[300]};
  border-width: 4px;
  &:checked {
    border-color: ${({ theme }) => theme.colors.indigo[500]};
  }
`;
