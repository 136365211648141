import { axiosConfig as axios } from '@Utils';

export const rediscoverApi = async (scmUuid: string, token: string) => {
  const response = await axios.post(
    `scm/discovery/${scmUuid}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response?.data;
};
