import React, { useEffect, useRef, useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Loader, Popper } from '@Components';
import { FormControlError } from '@FormElements';
import { InputLabel } from '@Components/Form';
import { SelectWrapper, SelectOption, SelectTriggerWrapper, SelectOptionsWrapper, SelectLabel, Arrow } from './styled';
import { IDropdownSelectOption, IDropdownSelectProps } from './interfaces';
import { DropdownOption } from './components/DropdownOption';
import { ELoaderSize } from '../Loader/types';

export const DropdownSelect: React.FC<IDropdownSelectProps> = ({ name, placeholder, options, onChange, value, error, onBlur, disabled, label, loading }) => {
  const emptyOption = { name: '', value: '' };
  const [activeItem, setActiveItem] = useState<IDropdownSelectOption>(emptyOption);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const activeOption = [emptyOption, ...options].find((option) => option.value === value);
    if (activeOption) {
      setActiveItem(activeOption);
    }
  }, [options, value]);

  const onToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSelectOption = (option: IDropdownSelectOption) => {
    setActiveItem(option);
    onChange(option.value);
    setIsOpen(false);
  };

  return (
    <SelectWrapper>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <Popper
        placement="bottom-start"
        open={isOpen}
        onClose={onClose}
        triggerElement={(open) => (
          <SelectTriggerWrapper ref={selectRef} id={name} type="button" onBlur={onBlur} onClick={onToggle} $error={!!error} disabled={disabled}>
            <SelectLabel>
              {loading ? (
                <Loader size={ELoaderSize.SMALL} />
              ) : (
                <>
                  {activeItem.name || activeItem.value || placeholder}
                  {activeItem.label && activeItem.label}
                </>
              )}
            </SelectLabel>
            <Arrow icon={faChevronDown} $rotated={open} />
          </SelectTriggerWrapper>
        )}
      >
        <SelectOptionsWrapper $width={selectRef?.current?.offsetWidth}>
          {options.map((option, index) => {
            const onClick = () => {
              onSelectOption(option);
            };
            return <DropdownOption option={option} key={index} onClick={onClick} />;
          })}
        </SelectOptionsWrapper>
      </Popper>
      {error && <FormControlError>{error}</FormControlError>}
    </SelectWrapper>
  );
};
