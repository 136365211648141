import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDrawer } from '@Drawer';
import { CloseIcon, DrawerHeader, DrawerTitle } from './styled';
import { IDrawerStandardProps } from './interfaces';

export const DrawerStandard: React.FC<IDrawerStandardProps> = ({ title, content, header = true }) => {
  const { closeDrawer } = useDrawer();

  return (
    <div role="dialog">
      {header && (
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
          <CloseIcon icon={faXmark} onClick={closeDrawer} />
        </DrawerHeader>
      )}

      <main>{content}</main>
    </div>
  );
};
