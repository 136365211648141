import { useQuery } from 'react-query';
import { fetchIssuesSummary } from '../api';
import { IApiIssuesSummaryParams, IIssuesSummary } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useIssuesSummary = (params?: IApiIssuesSummaryParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useQuery<IIssuesSummary>({
    queryKey: ['issues', 'summary', params],
    queryFn: async () => {
      return await fetchIssuesSummary(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  });
};
