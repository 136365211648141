import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const CheckIcon = styled(FontAwesomeIcon).attrs({ icon: faCheck })(
  ({ theme }) => css`
    width: 10px;
    height: 10px;
    color: ${theme.colors.white};
  `,
);
