import React, { useState } from 'react';
import { faColumns3 } from '@fortawesome/pro-regular-svg-icons';
import { TableCheckbox } from 'core/Table/TableCheckbox';
import { useOutsideClick } from '@Utils';
import {
  ColumnVisibilityStyled,
  ColumnVisibilityItemStyled,
  ColumnVisibilityContentStyled,
  ColumnVisibilityWrapperStyled,
  ITableColumnVisibilityProps,
  ColumnVisibilityButton,
  ColumnVisibilityIcon,
} from './styled/ColumnVisibility';

export const TableColumnVisibility: React.FC<ITableColumnVisibilityProps> = ({ columns, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));
  return (
    <ColumnVisibilityWrapperStyled>
      <ColumnVisibilityStyled ref={ref}>
        <ColumnVisibilityButton onClick={() => setIsOpen(true)}>
          <ColumnVisibilityIcon icon={faColumns3} /> Columns
        </ColumnVisibilityButton>
        <ColumnVisibilityContentStyled $isOpen={isOpen}>
          {columns.map(
            (column) =>
              column.columnDef.enableHiding !== false && (
                <ColumnVisibilityItemStyled key={`columnVisibility-${column.id}`}>
                  <TableCheckbox text={column.columnDef.header} checked={column.getIsVisible()} onChange={() => onChange(column)} />
                </ColumnVisibilityItemStyled>
              ),
          )}
        </ColumnVisibilityContentStyled>
      </ColumnVisibilityStyled>
    </ColumnVisibilityWrapperStyled>
  );
};
