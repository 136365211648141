import { DefaultTheme } from 'styled-components';
import { TIssuesSeverities } from '../interfaces';
import { EIssuesCategoryLabel } from '@ComponentsTypes';

export const formatRiskiestResponseSeries = (data: Record<string, TIssuesSeverities>, theme: DefaultTheme) => {
  return Object.entries(data).reduce(
    (acc, [key, value]) => {
      const [lowSeries, mediumSeries, highSeries, criticalSeries] = acc;
      const { low, medium, high, critical } = value;

      criticalSeries.data.push({ x: key, y: critical || 0, fillColor: theme.colors.rose[600] });
      highSeries.data.push({ x: key, y: high || 0, fillColor: theme.colors.orange[500] });
      mediumSeries.data.push({ x: key, y: medium || 0, fillColor: theme.colors.zinc[400] });
      lowSeries.data.push({ x: key, y: low || 0, fillColor: theme.colors.zinc[200] });
      return acc;
    },
    [
      {
        name: EIssuesCategoryLabel.LOW,
        data: [],
      },
      {
        name: EIssuesCategoryLabel.MEDIUM,
        data: [],
      },
      {
        name: EIssuesCategoryLabel.HIGH,
        data: [],
      },
      {
        name: EIssuesCategoryLabel.CRITICAL,
        data: [],
      },
    ],
  );
};
