import { axiosConfig as axios } from '@Utils';
import { IApiDashboardFilterParams } from '../interfaces';

export const fetchRepositoriesFailedPullRequests = async (token: string, params?: IApiDashboardFilterParams) => {
  const response = await axios(`repositories/pull-requests/failed`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(params?.filter && params?.filter),
    },
  });
  return response?.data;
};
