import { EMonitoringStatus, ESeverityLevel } from '@APITypes';
import { EIssuesCategoryLabel } from '@ComponentsTypes';
import { languageFilterOptions } from 'core/Filter/types';

export const repositoryFilters = [
  {
    id: 'monitoringStatus',
    label: 'Monitoring Status',
    options: [
      {
        label: 'Monitored',
        value: EMonitoringStatus.MONITORED,
        count: 0,
      },
      {
        label: 'Unmonitored',
        value: EMonitoringStatus.UNMONITORED,
        count: 0,
      },
      {
        label: 'Scanning',
        value: EMonitoringStatus.SCANNING,
        count: 0,
      },
    ],
  },
  {
    id: 'severity',
    label: 'Severity',
    options: [
      {
        label: EIssuesCategoryLabel.CRITICAL,
        value: ESeverityLevel.CRITICAL,
        count: 0,
      },
      {
        label: EIssuesCategoryLabel.HIGH,
        value: ESeverityLevel.HIGH,
        count: 0,
      },
      {
        label: EIssuesCategoryLabel.MEDIUM,
        value: ESeverityLevel.MEDIUM,
        count: 0,
      },
      {
        label: EIssuesCategoryLabel.LOW,
        value: ESeverityLevel.LOW,
        count: 0,
      },
    ],
  },
  {
    id: 'languages',
    label: 'Languages',
    options: languageFilterOptions,
  },
  {
    id: 'dependencyCountRange',
    label: 'Dependencies count',
    min: 0,
    max: 5000,
    isMulti: true,
  },
];
