import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/pro-solid-svg-icons';

export const UserRenderEmptyAvatarStyled = styled(FontAwesomeIcon).attrs({ icon: faCircleUser })`
  width: 14px;
  height: auto;
  border-radius: 100%;
  margin-right: 3px;
`;
