import React from 'react';
import { ModalStandard } from '@Modal';
import { ModalContent } from './styled';
import { SelectIntegrationOrganizationForm } from '../SelectIntegrationOrganizationForm';
import { ISelectIntegrationOrganizationModalProps } from './interfaces';

export const SelectIntegrationOrganizationModal: React.FC<ISelectIntegrationOrganizationModalProps> = ({ provider }) => {
  return (
    <ModalStandard
      title="Please select an organization to protect"
      description="To complete the protection process an organization must be selected"
      content={
        <ModalContent>
          <SelectIntegrationOrganizationForm provider={provider} />
        </ModalContent>
      }
    />
  );
};
