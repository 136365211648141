import styled from 'styled-components';

export const FilePathGroupLabel = styled.p`
  font-size: ${({ theme }) => theme.text.size.subtitle2};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.zinc['400']};
  margin-bottom: ${({ theme }) => theme.spacing['1.5']};
`;
