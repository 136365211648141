import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InfoLabel, InfoLabelTypes, Loader } from '@Components';
import { EMonitoringStatus, ESeverityLevel, ISevereIssues, NotAvailable } from '@APITypes';
import { format } from 'date-fns';
import {
  faClock,
  faClockRotateLeft,
  faCodeBranch,
  faCodeCompare,
  faCube,
  faFolder,
  faShieldExclamation,
  faSitemap,
  faBracketsCurly,
  faSlidersSimple,
  faShareNodes,
} from '@fortawesome/pro-regular-svg-icons';
import { DATE_TIME_LONG_FORMAT, getFullRepositoryName, isEmpty, useDepTreeDiscoverFinish, usePostProcessing, useProtectFinish, useProtectStart } from '@Utils';
import { RepositoryDetailsAdditional, StatusInfo, RepositoryProgressBarList } from './';
import { IRepositoryProgressBar } from './RepositoryProgressBarList/interfaces';
import { createResourceContext, ResourceContext } from '@Contexts';
import { LanguageList } from 'Layouts/DiscoveryPage/components/SubComponents/LanguageList';
import { BackButton, SourceCodeLink, MonitorPreferencesTrigger } from './SubComponents';
import { useRepositoryDetails, useRepositoryMonitor, useRepositoryReMonitor } from '../hooks';
import { useQueryClient } from 'react-query';
import { EIssuesCategoryLabel } from '@ComponentsTypes';
import { useSubscribe } from '@Utils';
import { ENotificationType, toastService } from '@Notification';
import {
  DetailsCellStyled,
  DetailsConnectionsIcon,
  DetailsContainerStyled,
  DetailsDescriptionWrapperStyled,
  DetailsHeaderStyled,
  DetailsLeftStyled,
  DetailsRightSectionStyled,
  DetailsSubtitleStyled,
  DetailsTableSectionStyled,
  DetailsTagStyled,
  DetailsTagsStyled,
  DetailsTitleStyled,
  Divider,
  ScreenHeightStyled,
  DetailsIcon,
  DetailsIssuesWrapperStyled,
  DetailsIssueCategoryStyled,
  VerticalDivider,
  DetailsHeaderActionsStyled,
  MonitorPreferencesIcon,
  DetailsScoreFlagContainer,
} from '../styled/Details';
import { Button, ButtonModes } from '@FormElements';
import { Flex, Button as IconButton } from '@Styles';
import { FailedPullRequestsRemainder } from './FailedPullRequestsRemainder';
import { ScoreFlag } from 'Layouts/components/ScoreFlag';
import { DevLanguage } from '@blindspot/common/types/bff/scm';
import { useCanAccess } from '../../../Utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModal } from '@Modal';
import { RepositoryShareModal } from './RepositoryShareModal';
import { Permission } from '@Permissions';

export const RepositoryDetails: React.FC = () => {
  const queryClient = useQueryClient();
  const { repoId } = useParams();
  const { openModal, closeModal } = useModal();
  const { data: repository, isLoading } = useRepositoryDetails(repoId);
  const { mutate: repositoryMonitor } = useRepositoryMonitor();
  const { mutate: repositoryRemonitor } = useRepositoryReMonitor();
  const [localMonitoringStatus, setLocalMonitoringStatus] = React.useState<EMonitoringStatus>(repository?.monitoringStatus);
  const canRerun = useCanAccess(Permission.REPOSITORIES_REMONITOR);

  const handleClickShare = useCallback(() => {
    openModal(<RepositoryShareModal onCopy={() => closeModal()} />);
  }, []);

  useEffect(() => {
    setLocalMonitoringStatus(repository?.monitoringStatus);
  }, [repository?.monitoringStatus]);

  const invalidateRepositoryDetails = () => queryClient.invalidateQueries({ queryKey: ['repositoryDetails', repoId] });
  const useProtectFinishHandler = () => {
    invalidateRepositoryDetails();
    setLocalMonitoringStatus(EMonitoringStatus.MONITORED);
  };
  useSubscribe(repoId);
  useProtectFinish(useProtectFinishHandler);
  useProtectStart(invalidateRepositoryDetails);
  useDepTreeDiscoverFinish(invalidateRepositoryDetails);
  usePostProcessing(invalidateRepositoryDetails);

  const onMonitor = (branch?: string, scanConfigSetUuid?: string): void => {
    const { name, scmOriginId, scmOriginType } = repository;
    repositoryMonitor({ scmOriginId, scmOriginType, branch, scanConfigSetUuid });
    toastService({
      header: `${name} is now monitored`,
      message: `We will start scanning the repository shortly`,
      type: ENotificationType.SUCCESS,
    });
    setLocalMonitoringStatus(EMonitoringStatus.SCANNING);
  };

  const onRemonitorClick = () => {
    repositoryRemonitor({ uuid: repository.uuid, monitoringStatus: repository.monitoringStatus });
    toastService({
      header: `Remonitor`,
      message: `We will start scanning the repository shortly`,
      type: ENotificationType.SUCCESS,
    });
    setLocalMonitoringStatus(EMonitoringStatus.SCANNING);
  };

  const severeIssueToEnum = (severity: keyof ISevereIssues): EIssuesCategoryLabel => {
    switch (severity) {
      case 'low':
        return EIssuesCategoryLabel.LOW;
      case 'medium':
        return EIssuesCategoryLabel.MEDIUM;
      case 'high':
        return EIssuesCategoryLabel.HIGH;
      case 'critical':
        return EIssuesCategoryLabel.CRITICAL;
    }
  };

  if (isLoading) {
    return (
      <ScreenHeightStyled>
        <DetailsContainerStyled>
          <Loader />
        </DetailsContainerStyled>
      </ScreenHeightStyled>
    );
  }

  if (!repository) {
    return <DetailsContainerStyled />;
  }

  const isRepositoryMonitored = localMonitoringStatus === EMonitoringStatus.MONITORED;
  const isRepositoryUnmonitored = localMonitoringStatus === EMonitoringStatus.UNMONITORED;
  const isRepositoryScanning = localMonitoringStatus === EMonitoringStatus.SCANNING;
  const hasScore = repository.repoScore !== null;
  return (
    <div>
      <BackButton />
      <DetailsContainerStyled $hasScore={hasScore} $score={repository.repoScore}>
        {isRepositoryUnmonitored && <StatusInfo repository={repository} onMonitorClick={onMonitor} />}
        {hasScore && (
          <DetailsScoreFlagContainer>
            <ScoreFlag score={repository.repoScore} />
          </DetailsScoreFlagContainer>
        )}

        <DetailsHeaderStyled>
          <div>
            <DetailsSubtitleStyled>Repository</DetailsSubtitleStyled>
            <DetailsTitleStyled>
              <b>{getFullRepositoryName(repository.owner, repository.name)}</b>
            </DetailsTitleStyled>
            <DetailsTagsStyled>
              <DetailsTagStyled>
                <InfoLabel type={InfoLabelTypes.Bordered} capitalize>
                  {repository.private ? 'private' : 'public'}
                </InfoLabel>
              </DetailsTagStyled>
            </DetailsTagsStyled>
          </div>
          <Flex gap={2}>
            {isRepositoryMonitored && (
              <DetailsHeaderActionsStyled>
                {canRerun && (
                  <Button styleMode={ButtonModes.Monitor} onClick={onRemonitorClick}>
                    ReScan
                  </Button>
                )}
                <DetailsSubtitleStyled $small>Scan frequency: Every commit</DetailsSubtitleStyled>
                <MonitorPreferencesTrigger onMonitor={onMonitor}>
                  <MonitorPreferencesIcon />
                </MonitorPreferencesTrigger>
              </DetailsHeaderActionsStyled>
            )}
            {isRepositoryScanning && canRerun && (
              <Button styleMode={ButtonModes.Monitor} onClick={onRemonitorClick}>
                ReScan
              </Button>
            )}
            {!isRepositoryUnmonitored && (
              <IconButton onClick={handleClickShare}>
                <FontAwesomeIcon icon={faShareNodes} size="lg" />
              </IconButton>
            )}
          </Flex>
        </DetailsHeaderStyled>
        <RepositoryProgressBarList monitoringStatus={localMonitoringStatus} progressBars={repository.progressBars as IRepositoryProgressBar[]} />
        <FailedPullRequestsRemainder />
        <Divider />
        <DetailsDescriptionWrapperStyled>
          <DetailsTableSectionStyled>
            <DetailsLeftStyled>
              <DetailsIcon icon={faFolder} />
              Source code:
            </DetailsLeftStyled>
            <SourceCodeLink src={repository.url} text={repository.name} origin={repository.scmOriginType} />

            <DetailsLeftStyled>
              <DetailsIcon icon={faSitemap} />
              Connections:
            </DetailsLeftStyled>
            <DetailsRightSectionStyled $right>
              <DetailsCellStyled>
                <DetailsConnectionsIcon icon={faCodeCompare} />
                <span>
                  <b>{repository.dependenciesCount}</b> {repository.dependenciesCount === 1 ? 'Dependency' : 'Dependencies'}
                </span>
              </DetailsCellStyled>
              <VerticalDivider />
              <DetailsCellStyled>
                <DetailsConnectionsIcon icon={faCodeBranch} />
                <span>
                  <b>{repository.branchesCount || 0}</b> {repository.branchesCount === 1 ? 'Branch' : 'Branches'}
                </span>
              </DetailsCellStyled>
              <VerticalDivider />
              <DetailsCellStyled>
                <DetailsConnectionsIcon icon={faCube} />
                <span>
                  <b>{repository.buildsCount || 0}</b> {repository.buildsCount === 1 ? 'Build' : 'Builds'}
                </span>
              </DetailsCellStyled>
            </DetailsRightSectionStyled>
            <DetailsLeftStyled>
              <DetailsIcon icon={faShieldExclamation} />
              Security issues:
            </DetailsLeftStyled>
            <DetailsIssuesWrapperStyled>
              {Object.entries(ESeverityLevel || {})
                .map(([enumKey, enumValue]) => {
                  return (
                    <DetailsIssueCategoryStyled key={`repository-issues-${enumKey}`}>
                      <InfoLabel celled type={enumValue as ESeverityLevel}>
                        {repository?.severeIssues[enumValue] || 0}
                      </InfoLabel>
                      <DetailsCellStyled>{severeIssueToEnum(enumValue as keyof ISevereIssues)}</DetailsCellStyled>
                    </DetailsIssueCategoryStyled>
                  );
                })
                .reverse()}
            </DetailsIssuesWrapperStyled>

            <DetailsLeftStyled>
              <DetailsIcon icon={faBracketsCurly} />
              <span>Languages:</span>
            </DetailsLeftStyled>
            <DetailsRightSectionStyled $minRows $right>
              <LanguageList
                languages={
                  (!isEmpty(repository.languages) && (Object.keys(repository.languages).map((lang) => lang.replaceAll('_', ' ')) as Array<DevLanguage>)) || [DevLanguage.NOT_AVAILABLE]
                }
              />
            </DetailsRightSectionStyled>
          </DetailsTableSectionStyled>

          <DetailsTableSectionStyled>
            <DetailsLeftStyled>
              <DetailsIcon icon={faClock} />
              First Seen:
            </DetailsLeftStyled>
            <DetailsCellStyled>{format(new Date(repository.createdAt), DATE_TIME_LONG_FORMAT)}</DetailsCellStyled>

            <DetailsLeftStyled>
              <DetailsIcon icon={faClockRotateLeft} />
              Last Commit:
            </DetailsLeftStyled>
            <DetailsCellStyled>{format(new Date(repository.lastCommitDate), DATE_TIME_LONG_FORMAT)}</DetailsCellStyled>

            <DetailsLeftStyled>
              <DetailsIcon icon={faCodeBranch} />
              Monitored branch:
            </DetailsLeftStyled>
            <DetailsCellStyled $underline={!isRepositoryUnmonitored}>
              {isRepositoryUnmonitored ? NotAvailable : <MonitorPreferencesTrigger onMonitor={onMonitor}>{repository.monitoringBranch}</MonitorPreferencesTrigger>}
            </DetailsCellStyled>

            <DetailsLeftStyled>
              <DetailsIcon icon={faSlidersSimple} />
              Scan configuration:
            </DetailsLeftStyled>
            <DetailsCellStyled $underline={!!repository.scanConfigSetName}>
              {!repository.scanConfigSetName ? NotAvailable : <MonitorPreferencesTrigger onMonitor={onMonitor}>{repository.scanConfigSetName}</MonitorPreferencesTrigger>}
            </DetailsCellStyled>
          </DetailsTableSectionStyled>
        </DetailsDescriptionWrapperStyled>
      </DetailsContainerStyled>

      <ResourceContext.Provider value={createResourceContext({ repoId })}>
        <RepositoryDetailsAdditional monitoringStatus={localMonitoringStatus} setMonitoringStatus={setLocalMonitoringStatus} />
      </ResourceContext.Provider>
    </div>
  );
};
