import { useQuery } from 'react-query';
import { fetchIssuesSeveritySummary } from '@API';
import { IApiFilterParams, IApiIssuesSeveritySummaryResponse } from '@ComponentsTypes';
import { useSession } from '@descope/react-sdk';

export const useIssuesSeveritySummary = (params?: IApiFilterParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['issuesSeveritySummary', params],
    queryFn: async () => {
      return await fetchIssuesSeveritySummary(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IApiIssuesSeveritySummaryResponse>(queryOptions);
};
