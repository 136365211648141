import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ProgressBarList = styled(Flex)(
  ({ theme }) => css`
    overflow: auto;
    flex-direction: column;
    max-height: 260px;
    overflow-y: scroll;
    padding: ${theme.spacing[0.5]} ${theme.spacing[1.5]} 0 ${theme.spacing[1]};
  `,
);
