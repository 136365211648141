import styled from 'styled-components';

export const HeaderStyled = styled.header`
  display: flex;
  grid-column-gap: 8px;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  grid-template-columns: repeat(4, auto);
  font-weight: 100;
`;
