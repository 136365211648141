import styled, { css } from 'styled-components';

export const DropdownMenuContainerStyled = styled.div<{ $hidden: boolean }>(
  ({ theme, $hidden }) => css`
    min-width: 225px;
    background: ${theme.colors.white};
    padding: ${theme.spacing[1]};
    border-radius: 8px;
    box-shadow: ${theme.shadows[2]};
    z-index: 1;
    visibility: ${$hidden ? 'hidden' : 'visible'};
  `,
);
