import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const PreferencesScanCoverageStyled = styled(Flex)(
  ({ theme }) => css`
    border-radius: 20px;
    padding: ${theme.spacing[3]} ${theme.spacing[5]} ${theme.spacing[4]} ${theme.spacing[3]};
    background: ${theme.colors.indigo[50]};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 24px;
    letter-spacing: 0.2px;
  `,
);
