import { axiosConfig as axios } from '@Utils';
import { IApiIssuesDetailsParams } from '../interfaces';

export const fetchIssueDetails = async ({ issueUuid, diff }: IApiIssuesDetailsParams, token: string) => {
  const response = await axios(`issues/details/${issueUuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { diff },
  });
  return response?.data;
};
