import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

import { useProtectFinish, useProtectStart } from '@Utils';
import { useRepositoriesFailedPullRequests } from 'Layouts/DashboardPage/hooks/useRepositoriesFailedPullrequests.hook';
import { Remainder } from 'Layouts/components';
import { AlarmIconStyled, ButtonStyled, ReminderContainerStyled } from './styled';

export const FailedPullRequestsRemainder = () => {
  const { repoId } = useParams();
  const { data, refetch } = useRepositoriesFailedPullRequests({ filter: { 'filter[repoUuid]': repoId } });
  const navigate = useNavigate();

  const failedPullRequestsCount = data?.length ? data[0].failedPullRequestsCount : 0;

  const message = useMemo(() => `${failedPullRequestsCount} PR check${failedPullRequestsCount > 1 ? 's' : ''} failed. Merging is blocked.`, [failedPullRequestsCount]);

  useProtectFinish(refetch);
  useProtectStart(refetch);

  if (!failedPullRequestsCount) {
    return null;
  }

  const onClickHandler = () => {
    navigate(`/discovery/repositories/${repoId}/pull-requests`);
  };

  return (
    <ReminderContainerStyled>
      <Remainder
        message={message}
        icon={<AlarmIconStyled icon={faCircleExclamation} />}
        action={
          <>
            <ButtonStyled onClick={onClickHandler}>See details</ButtonStyled>
          </>
        }
      ></Remainder>
    </ReminderContainerStyled>
  );
};
