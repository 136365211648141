import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const TokenDetailsRowStyled = styled(Flex)(
  ({ theme }) => css`
    align-items: flex-start;
    flex-direction: column;
    gap: ${theme.spacing[0.5]};
    font-size: ${theme.text.size.body2};
  `,
);
