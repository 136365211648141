import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ProgressBarItem = styled(Flex)(
  ({ theme }) => css`
    align-items: center;
    gap: ${theme.spacing[1.5]};
    padding: ${theme.spacing[1.5]} ${theme.spacing[2]};
    border-radius: 8px;
    background-color: ${theme.colors.indigo[100]};
  `,
);
