import { firstLetterToUpperCase } from '@Utils';
import { DevLanguage } from '@blindspot/common/types/bff/scm';

export const languageFilterOptions = Object.values(DevLanguage).map((language) => {
  let label = '';

  switch (language) {
    case DevLanguage.CPLUSPLUS:
      label = 'C++';
      break;
    case DevLanguage.CSHARP:
      label = 'C#';
      break;
    case DevLanguage.PHP:
      label = 'PHP';
      break;
    case DevLanguage.JUPYTER_NOTEBOOK:
      label = 'Jupyter Notebook';
      break;
    default:
      label = firstLetterToUpperCase(language);
      break;
  }

  return { label, value: language, count: 0 };
});
