import styled from 'styled-components';

export const ExternalLink = styled.p`
  color: ${({ theme }) => theme.colors.indigo['500']};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  display: inline-flex;
  flex-direction: row-reverse;
  cursor: pointer;
  text-decoration: none;

  &:before {
    padding-left: ${({ theme }) => theme.spacing['1']};
    position: relative;
    top: ${({ theme }) => theme.spacing['0.5']};
  }
`;
