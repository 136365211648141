import { useQuery } from 'react-query';
import { fetchIssuesByCodeLanguages } from '../api';
import { IApiDashboardFilterParams, TIssuesSeverities } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useIssuesByCodeLanguages = (params?: IApiDashboardFilterParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['issuesByCodeLanguages', params],
    queryFn: async () => {
      return await fetchIssuesByCodeLanguages(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<Record<string, TIssuesSeverities>>(queryOptions);
};
