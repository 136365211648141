import styled, { css } from 'styled-components';

export const ScanConfigSetRepositoriesLabelStyled = styled.p(
  ({ theme }) => css`
    font-size: ${theme.text.size.body1};
    font-weight: ${theme.text.weight.regular};
    line-height: 23px;
    letter-spacing: 0.4px;
    text-align: left;
    margin-top: ${theme.spacing[0.5]};
  `,
);
