import { Flex } from '@Styles';
import styled from 'styled-components';

export const PaginationStyled = styled(Flex)`
  text-align: right;
  color: ${({ theme }) => theme.colors.zinc[500]};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  align-items: center;
  justify-content: flex-end;
`;
