import { Tooltip } from '@Components';
import React from 'react';
import { ReachabilityTooltip } from './IssueReachabilityTooltip';
import { IIssueReachabilityProps } from './types';
import { IssueReachabilityLabel } from './IssueReachabilityLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IssueReachabilityStyled } from './styled';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

export const IssueReachability: React.FC<IIssueReachabilityProps> = ({ reachabilityStatus, bolded = false }) => (
  <IssueReachabilityStyled>
    <IssueReachabilityLabel bolded={bolded} reachabilityStatus={reachabilityStatus} />
    <Tooltip tooltip={<ReachabilityTooltip reachabilityStatus={reachabilityStatus} />}>
      <FontAwesomeIcon icon={faCircleInfo} />
    </Tooltip>
  </IssueReachabilityStyled>
);
