import { TitleContainer } from '../../InputGitLabTokenOrganizationModal/styled';
import { Logo } from '../../Integrator/styled';
import { ESign, SignRender } from '@Components';

export const GithubModalTitle = () => (
  <TitleContainer>
    <Logo>
      <SignRender name={ESign.github} />
    </Logo>
    Connect to Github
  </TitleContainer>
);
