import React, { useEffect, useState } from 'react';
import { IReachability } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/pro-light-svg-icons';
import { ReachabilityRepositoryHeaderStyled, ReachabilityRepositoryMoreStyled, ReachabilityRepositoryTitleStyled, ReachabiltiyRepositoryStyled } from './styled';
import { ReachabilityIssue } from './ReachabilityIssue';

const DEFAULT_REACHABILITIES_TO_SHOW = 3;

export const ReachabilityRepository: React.FC<IReachability> = ({ repository, issuesTotalCount, issues }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [issuesToShow, setIssuesToShow] = useState(DEFAULT_REACHABILITIES_TO_SHOW);

  useEffect(() => {
    setIssuesToShow(isExpanded ? issues.length : DEFAULT_REACHABILITIES_TO_SHOW);
  }, [issues, isExpanded]);

  const onExpand = () => setIsExpanded(!isExpanded);

  return (
    <ReachabiltiyRepositoryStyled>
      <ReachabilityRepositoryHeaderStyled>
        <FontAwesomeIcon icon={faFolder} size="xs" />
        <ReachabilityRepositoryTitleStyled
          to={`/discovery/repositories/${repository.uuid}`}
        >{`${repository.owner}/${repository.name} (${issuesTotalCount})`}</ReachabilityRepositoryTitleStyled>
      </ReachabilityRepositoryHeaderStyled>
      {issues.slice(0, issuesToShow).map((issue) => (
        <ReachabilityIssue key={issue.issueUuid} issue={issue} />
      ))}
      {issues.length > DEFAULT_REACHABILITIES_TO_SHOW && (
        <ReachabilityRepositoryMoreStyled onClick={onExpand}>{`Show ${isExpanded ? 'less' : `${issues.length - DEFAULT_REACHABILITIES_TO_SHOW} more`}`}</ReachabilityRepositoryMoreStyled>
      )}
    </ReachabiltiyRepositoryStyled>
  );
};
