import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const IssuesSourceTypeButtonStyled = styled(Link)<{ $isActive: boolean; $position: 'left' | 'middle' | 'right' }>`
  border: 1px solid ${({ theme, $isActive }) => ($isActive ? theme.colors.indigo['500'] : theme.colors.zinc['200'])};
  border-radius: ${({ $position }) => ($position === 'left' ? '8px 0 0 8px' : $position === 'right' ? '0 8px 8px 0' : '0')};
  padding: 6px 12px;
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.white : theme.colors.black)};
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.colors.indigo['500'] : theme.colors.white)};
  font-size: ${({ theme }) => theme.text.size.caption};
  cursor: pointer;
`;
