import { Flex } from '@Styles';
import styled from 'styled-components';

export const SummaryBlockStyled = styled(Flex)`
  height: 130px;
  padding: ${({ theme }) => theme.spacing[3.5]};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 30px;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  background-color: ${({ theme }) => theme.colors.white};
`;
