import React from 'react';
import { ModalStandard } from '@Modal';
import { ModalContent, FilePathGroup, FilePathGroupLabel } from '../styled';
import { IDependencyFileModalProps } from '../types';
import { DependencyFilePath } from '../../DependencyFilePath';

export const DependencyFilesModal: React.FC<IDependencyFileModalProps> = ({ repositories }) => {
  return (
    <ModalStandard
      title="Source Files"
      content={
        <ModalContent>
          {repositories.map((repository) => (
            <div key={`${repository.owner}-${repository.name}-${repository.uuid}`}>
              <FilePathGroupLabel>
                {repository.owner}/{repository.name}
              </FilePathGroupLabel>
              <FilePathGroup>
                {repository.files.map((file) => {
                  const filePath = `${repository.owner}/${repository.name}/${file.path}`;
                  return <DependencyFilePath key={filePath} path={filePath} />;
                })}
              </FilePathGroup>
            </div>
          ))}
        </ModalContent>
      }
    />
  );
};
