import { IPageRequestOptions } from '@APITypes';
import { createContext } from 'react';

export interface IResourceContext {
  repoId?: string;
  depId?: string;
  pageOptions?: IPageRequestOptions;
  search?: string;
}

export const ResourceContext = createContext<IResourceContext>(null);

export const resourceContextDefault: IResourceContext = {
  repoId: '',
  depId: '',
  pageOptions: {},
  search: '',
};

export const createResourceContext = (context?: Partial<IResourceContext>) => {
  return {
    ...resourceContextDefault,
    ...context,
  };
};
