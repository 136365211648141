import { useContext, useEffect } from 'react';
import { faEquals, faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Loader } from '@Components';

import { useFilter } from 'core/Filter';
import { CardTitleStyled, DashboardCardStyled } from '../../styled';
import { useRemediationSummary } from '../../hooks';
import { ChartLoadingContext } from '../../context';
import { RemediationSymbolIconStyled } from './styled';
import { RemediationIssues } from './RemediationIssues';
import { isNoRemediation } from 'Layouts/DashboardPage/utils';
import { NoRemediationContent } from './NoRemediationContent';

export const Remediation = () => {
  const { appliedFilters } = useFilter();
  const { data, isLoading } = useRemediationSummary({ filter: appliedFilters });

  const noRemediation = isNoRemediation(data);

  const { setLoadingChart } = useContext(ChartLoadingContext);

  useEffect(() => {
    setLoadingChart(isLoading);
  }, [isLoading]);

  return (
    <DashboardCardStyled>
      <CardTitleStyled>Remediation</CardTitleStyled>
      {isLoading && <Loader />}
      {!isLoading &&
        (noRemediation ? (
          <NoRemediationContent />
        ) : (
          <>
            <RemediationIssues title="Current status" issues={data?.currentStatus} />
            <RemediationSymbolIconStyled icon={faMinus} size={'2xs'} />
            <RemediationIssues title="Fixes available" issues={data?.fixesAvailable} />
            <RemediationSymbolIconStyled icon={faPlus} size={'2xs'} />
            <RemediationIssues title="Vulnerabilities introduced" issues={data?.introduced} />
            <RemediationSymbolIconStyled icon={faEquals} size={'2xs'} />
            <RemediationIssues title="Status after fixes" issues={data?.statusAfterFixes} isBold />
          </>
        ))}
    </DashboardCardStyled>
  );
};
