import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DrawerEffortLevelTitleStyled, DrawerFilePathTextStyled, DrawerSubTitleStyled, DrawerTitleStyled } from './styled';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { IRemediationDrawerNavigation } from './types';
import { DrawerNavigator } from 'Layouts/components/DrawerNavigator';

export const RemediationDrawerNavigation = ({ dependency, currentDependencyIndex, dependenciesAmount, handleChangeDependency }: IRemediationDrawerNavigation) => {
  return (
    <>
      <DrawerTitleStyled>
        <span>Remediation details</span>
        <DrawerNavigator currentIndex={currentDependencyIndex} total={dependenciesAmount} onChangeDirection={handleChangeDependency} />
      </DrawerTitleStyled>
      <DrawerSubTitleStyled>
        Remediation Effort - <DrawerEffortLevelTitleStyled>{dependency.efforts.effortLevel}</DrawerEffortLevelTitleStyled> to Fix
      </DrawerSubTitleStyled>
      <DrawerFilePathTextStyled>
        <FontAwesomeIcon icon={faFile} />
        <span>{dependency.depFileUrlWithLine}</span>
      </DrawerFilePathTextStyled>
    </>
  );
};
