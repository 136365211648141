export const bitbucketWorkspaceAccessTokenSteps = [
  'Login to Bitbucket',
  'Go to settings',
  'Click Workspace Settings',
  'Go to Access Tokens',
  'Press the new Workspace Access Token',
  'Select read/read & write according to your organization needs',
  'Create the Workspace Access Token',
];

export const bitbucketAppPasswordSteps = [
  'Login to Bitbucket',
  'Go to settings',
  'Click Personal Bitbucket Settings',
  'Go to App Passwords',
  'Press the new App Password',
  'Select read/read & write according to your organization needs',
  'Create the App Password',
];
