import React from 'react';
import { Outlet } from 'react-router-dom';
import { TabPages } from '@Components';
import { pageTools } from '@Utils';
import { PageWrapper, Header, Title, TabsContainer } from './styled';

export const SettingsPage: React.FC = () => {
  const baseUrl = pageTools.settings.path;

  const tabs = [
    {
      title: 'User management',
      key: 'user-management',
      linkTo: `${baseUrl}/user-management`,
    },
    {
      title: 'Scan configurations',
      key: 'scan-configurations',
      linkTo: `${baseUrl}/scan-configurations`,
    },
  ];

  return (
    <PageWrapper>
      <Header>
        <Title>Settings</Title>
        <TabsContainer>
          <TabPages items={tabs} isOldTab />
        </TabsContainer>
      </Header>
      <Outlet />
    </PageWrapper>
  );
};
