import { useParams } from 'react-router-dom';
import { useModal } from '@Modal';
import { CreateTicketModal } from 'Layouts/IssuesPage/components/issueDetails/IssueNavContainer/CreateTicketModal';
import { CreateRepositoryTicketIconStyled, CreateRepositoryTicketButtonStyled } from './styled';
import { useContext } from 'react';
import { ParagonContext } from '@Utils';
import { toastService } from '@Notification';
import { ETicketManager } from '@blindspot/common/types/bff/tickets';

export const CreateRepositoryTicket = () => {
  const { repoId } = useParams();
  const { ticketManagers, isLoading } = useContext(ParagonContext);
  const { openModal } = useModal();

  const onClickCreateTicket = () => {
    const enabledTicketManager = ticketManagers.find(({ isEnabled }) => isEnabled)?.type as ETicketManager;
    enabledTicketManager ? openModal(<CreateTicketModal repoUuid={repoId} ticketManager={enabledTicketManager} />) : toastService({ header: 'Please integrate your ticketing system.' });
  };

  return (
    <CreateRepositoryTicketButtonStyled onClick={onClickCreateTicket} disabled={isLoading}>
      <CreateRepositoryTicketIconStyled />
      Open Ticket
    </CreateRepositoryTicketButtonStyled>
  );
};
