import { DashboardSectionStyled } from '../styled';
import { MonitoredDependencies, LanguagesInUse, OutOfDate, MonitoredRepositories } from '.';

export const DashboardVisibilitySection = () => (
  <>
    <DashboardSectionStyled>
      <MonitoredRepositories />
      <MonitoredDependencies />
    </DashboardSectionStyled>
    <DashboardSectionStyled $gridTemplateColumns={'auto 310px'}>
      <LanguagesInUse />
      <OutOfDate />
    </DashboardSectionStyled>
  </>
);
