import React from 'react';
import { RemediationSummaryContentStyled, RemediationSummaryStyled, RemediationSummarySymbolIconsStyled, RemediationSummaryTitleIconStyled, RemediationSummaryTitleStyled } from './styled';
import { faEquals, faMinus, faPlus, faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons';
import { Flex } from '@Styles';
import { ModalProvider } from '@Modal';
import { IRemediationSummaryProps } from './types';
import { RemediationSummaryIssues } from './RemediationSummaryIssues';
import { RemediationLoader } from './RemediationLoader';
import { CreateRepositoryTicket, RepositoryTicket } from '../SubComponents/RepositoryTicket';

export const RemediationSummary: React.FC<IRemediationSummaryProps> = ({ data, isLoading }) => (
  <RemediationSummaryStyled>
    <RemediationSummaryTitleStyled>
      <Flex alignItems="center" gap={1}>
        <RemediationSummaryTitleIconStyled icon={faWandMagicSparkles} />
        <span>Recommended Remediation Plan</span>
        <RepositoryTicket />
      </Flex>
      <ModalProvider>
        <CreateRepositoryTicket />
      </ModalProvider>
    </RemediationSummaryTitleStyled>
    <RemediationSummaryContentStyled>
      {isLoading ? (
        <RemediationLoader />
      ) : (
        <>
          <RemediationSummaryIssues title="Current status" issues={data?.currentStatus} score={data?.scores?.currentStatusScore} />
          <RemediationSummarySymbolIconsStyled icon={faMinus} />
          <RemediationSummaryIssues title="Fixes available" issues={data?.fixesAvailable} />
          <RemediationSummarySymbolIconsStyled icon={faPlus} />
          <RemediationSummaryIssues title="Vulnerabilities introduced" issues={data?.introduced} />
          <RemediationSummarySymbolIconsStyled icon={faEquals} />
          <RemediationSummaryIssues title="Status after fixes" issues={data?.statusAfterFixes} score={data?.scores?.statusAfterFixesScore} />
        </>
      )}
    </RemediationSummaryContentStyled>
  </RemediationSummaryStyled>
);
