import { axiosConfig as axios } from '@Utils';

export const getOrganizationParagonIntegrationsToken = async (token: string) => {
  const response = await axios.get('org/integration/token', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response?.data;
};
