import { SelectPosition, SelectStyleMode } from 'core/Table/types';
import styled from 'styled-components';

export const SelectOptionsStyled = styled.div<{ $isOpen: boolean; $mode: SelectStyleMode; $position: SelectPosition }>`
  position: absolute;
  left: 0;
  right: 0;
  background: inherit;
  max-height: 0;
  height: auto;
  transition: 200ms;
  overflow: hidden;
  opacity: 0;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  border-top: 1px solid ${({ theme }) => theme.colors.zinc[300]};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  box-shadow: 0 0 6px ${({ theme }) => theme.colors.zinc[200]};
  z-index: 10;

  ${({ $mode, theme }) => {
    if ($mode === SelectStyleMode.Empty) {
      return `
                background: ${theme.colors.zinc[50]};
            `;
    }
  }}

  ${({ $position }) => {
    if ($position === SelectPosition.Top) {
      return `
                top: 100%;
            `;
    }

    if ($position === SelectPosition.Bottom) {
      return `
                bottom: 100%;
            `;
    }
  }}

    ${({ $isOpen }) => {
    if ($isOpen) {
      return `
                max-height: 400px;
                opacity: 1;
                transition: 200ms;
            `;
    }
  }}
`;
