import { axiosConfig as axios } from '@Utils';
import { IIgnoreIssueRequestPayload } from '../interfaces';

export const ignoreIssue = async (token: string, issueId: string, data: IIgnoreIssueRequestPayload) => {
  const response = await axios.post(`/issues/${issueId}/ignore`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
