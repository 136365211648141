import React, { useCallback } from 'react';
import { OrganizationOptionStyled, OrganizationSelectArrow, OrganizationSelectContainer, OrganizationSelectStyled } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useDescope, useSession, useUser } from '@descope/react-sdk';
import { getCurrentTenant } from './utils';

export const OrganizationSelect = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const { selectTenant } = useDescope();
  const { user } = useUser();

  const onSwitchTenant = useCallback(
    async (e) => {
      const { value } = e.target;
      await selectTenant(value);
      location.reload();
    },
    [selectTenant],
  );

  if (!isAuthenticated && !isSessionLoading) {
    return null;
  }

  const tenants = user?.userTenants?.map((t) => ({ id: t.tenantId, label: t.tenantName }));

  const currentTenant = getCurrentTenant(sessionToken);
  if (!tenants) {
    return null;
  }
  return (
    <OrganizationSelectContainer>
      <OrganizationSelectStyled defaultValue={currentTenant} onChange={onSwitchTenant}>
        {tenants.map((tenant) => (
          <OrganizationOptionStyled value={tenant.id} key={`OrganizationSelect-${tenant.id}`}>
            {tenant.label}
          </OrganizationOptionStyled>
        ))}
      </OrganizationSelectStyled>
      <OrganizationSelectArrow>
        <FontAwesomeIcon icon={faChevronDown} />
      </OrganizationSelectArrow>
    </OrganizationSelectContainer>
  );
};
