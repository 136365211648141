import { ICVSSVector } from './interfaces';
import { ParsedCVSSVector } from './types';
import { CVSSMetrics } from './metrics';

const findMetric = function (code: string): ICVSSVector {
  return CVSSMetrics.find((def) => def.code === code);
};

export function parseCVSSVector(vector: string): ParsedCVSSVector {
  const [_version, ...vectorArray] = vector.split('/');
  const vectorObject = vectorArray.reduce((acc, vectorItem) => {
    const [metric, metricValue] = vectorItem.split(':');
    const vectorDefinition = findMetric(metric);
    const vectorMetricDefinition = vectorDefinition?.metrics.find((def) => def.code === metricValue);
    const detailedVectorObject = {
      name: vectorDefinition?.name,
      code: vectorDefinition?.code,
      value: vectorMetricDefinition?.name,
      codeDescription: vectorMetricDefinition?.description,
      metricDescription: vectorDefinition?.description,
    };
    return { ...acc, [metric.trim()]: detailedVectorObject };
  }, {});
  return vectorObject;
}
