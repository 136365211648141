import { useMutation } from 'react-query';
import { rediscoverApi } from './rediscover.api';
import { useSession } from '@descope/react-sdk';

export const useRediscover = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(
    async (scmUuid: string) => {
      if (isAuthenticated && !isSessionLoading) {
        return await rediscoverApi(scmUuid, sessionToken);
      }
    },
    {
      retry: false,
    },
  );
};
