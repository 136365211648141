import { createContext } from 'react';

export const ItemSelectionContext = createContext({
  selectionEnabled: false,
  isAllItemsSelected: false,
  totalItemsCount: 0,
  selectedItemsCount: 0,
  includedItems: {},
  excludedItems: {},
  setTotalItemsCount: (value: number) => undefined,
  enableSelection: () => undefined,
  disableSelection: () => undefined,
  toggleItemSelection: (itemId: string | number, value?: boolean) => undefined,
  clearSelectedItems: () => undefined,
  selectAllItems: () => undefined,
});
