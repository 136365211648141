import { Flex } from '@Styles';
import styled from 'styled-components';

export const DrawerRemediationTargetContainerStyled = styled(Flex)`
  font-size: ${({ theme }) => theme.text.size.overline};
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing[4]};
  padding-top: ${({ theme }) => theme.spacing[1]};

  gap: ${({ theme }) => theme.spacing[1]};
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  border-top: 1px solid rgba(161, 161, 170, 0.25);

  & > span {
    opacity: 0.5;
  }
`;
