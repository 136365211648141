import React from 'react';
import { NotificationIcon } from './NotificationIcon';
import { NotificationContentStyled, NotificationContentWrapperStyled, NotificationTitleStyled } from './styled';
import { IToastItemProps } from './types';

export const ToastItem = ({ header, message, type, children }: IToastItemProps) => {
  return (
    <NotificationContentWrapperStyled>
      {type && <NotificationIcon type={type} />}
      <NotificationContentStyled>
        <NotificationTitleStyled>{header}</NotificationTitleStyled>
        {message && <div>{message}</div>}
      </NotificationContentStyled>
      {children}
    </NotificationContentWrapperStyled>
  );
};
