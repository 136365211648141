import { IRemediationEffortUpdateApiUsage } from '@blindspot/common/types/bff/remediation';
import { RemediationEffortChangeContainerStyled, RemediationEffortSourceCodeStyled, RemediationEffortStableChangeMethodStyled } from './styled';

export const RemediationEffortStableChange = ({ apiUsages }: { apiUsages: IRemediationEffortUpdateApiUsage[] }) => {
  return (
    <RemediationEffortChangeContainerStyled>
      <span>Function Name In Use</span>
      {apiUsages.map((apiUsage, index) => (
        <RemediationEffortStableChangeMethodStyled key={apiUsage.method}>
          {index + 1}. {apiUsage.method}
        </RemediationEffortStableChangeMethodStyled>
      ))}
    </RemediationEffortChangeContainerStyled>
  );
};
