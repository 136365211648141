import React from 'react';
import { IntegrationsContext } from '@Utils';
import { useContext } from 'react';
import { useRediscover } from './hook';
import { Loader } from '@Components';
import { ELoaderSize } from '../../../../../../components/Loader/types';
import { RediscoverButtonStyled } from './styled';
import { IntegrationStatusEnum } from 'Layouts/IntegrationsPage/types';
import { faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const RediscoverButton: React.FC = () => {
  const { integrations } = useContext(IntegrationsContext);
  const { mutate: reDiscover, isLoading } = useRediscover();
  /** limit for azure devops, gitlab and bitbucket */
  const availableProviders = [EScmType.AZURE_DEVOPS, EScmType.GITLAB, EScmType.BITBUCKET];
  const integration = integrations.find((integration) => integration.status === IntegrationStatusEnum.ACTIVE);
  if (!integration || !availableProviders.includes(integration.type)) {
    return null;
  }
  if (isLoading) {
    return <Loader size={ELoaderSize.SMALL} />;
  }
  return (
    <>
      <RediscoverButtonStyled onClick={() => reDiscover(integration.uuid)}>
        <FontAwesomeIcon icon={faRefresh} />
        Rediscover
      </RediscoverButtonStyled>
    </>
  );
};
