import { useQuery } from 'react-query';
import { ETicketManager, ITicketManagerProject } from '@blindspot/common/types/bff/tickets';
import { getTicketManagerProjects } from './api';
import { useSession } from '@descope/react-sdk';

export const useTicketManagerProjects = (ticketManager: ETicketManager) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const queryOptions = {
    queryKey: ['getTicketManagerProjects', ticketManager],
    queryFn: async () => {
      return await getTicketManagerProjects(sessionToken, ticketManager);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };
  return useQuery<ITicketManagerProject[]>(queryOptions);
};
