import { ECodeViewerIconTypes, IFolderMap } from '../types';

export const getFolderTree = (paths: string[], functionsNames: string[], decompileEnrich?: string[], sourceCode?: string[], gptScore?: number[], gptDescription?: string[]): IFolderMap => {
  const folderTree = new Map();

  const addFolderToTree = (folders: string[], foldersList: IFolderMap, functionIndex: number, fileName: string): void => {
    const currentFolder = folders.shift();
    const isFile = currentFolder === fileName;

    if (!foldersList.has(currentFolder)) {
      foldersList.set(currentFolder, {
        name: currentFolder,
        type: isFile ? ECodeViewerIconTypes.file : ECodeViewerIconTypes.folder,
        ...(!isFile && { filesList: new Map() }),
        ...(isFile && { functions: new Set<string>() }),
      });
    }

    if (isFile) {
      foldersList.get(currentFolder).functions.add(
        JSON.stringify({
          functionName: !functionsNames[functionIndex] ? 'Global Code' : functionsNames[functionIndex],
          decompileEnrich: decompileEnrich?.[functionIndex],
          sourceCode: sourceCode?.[functionIndex],
          gptScore: gptScore?.[functionIndex],
          gptDescription: gptDescription?.[functionIndex],
        }),
      );
    } else {
      const nextFoldersList = foldersList.get(currentFolder).filesList;
      addFolderToTree(folders, nextFoldersList, functionIndex, fileName);
    }
  };

  paths.forEach((path, index) => {
    if (!path) return;
    const folders = path.split('/');
    addFolderToTree(folders, folderTree, index, folders[folders.length - 1]);
  });

  return folderTree;
};
