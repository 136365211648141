import styled from 'styled-components';
import { Flex } from '@Styles';

export const TableNoData = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[4]} 0;
  min-height: 260px;
  color: ${({ theme }) => theme.colors.indigo[800]};
  font-size: ${({ theme }) => theme.text.size.body1};
  font-weight: ${({ theme }) => theme.text.weight.regular};

  p {
    font-size: ${({ theme }) => theme.text.size.body1};
    font-weight: ${({ theme }) => theme.text.weight.regular};
    margin: 0 0 ${({ theme }) => theme.spacing[2]} 0;
    color: ${({ theme }) => theme.colors.zinc[800]};
  }

  svg {
    margin-bottom: ${({ theme }) => theme.spacing[0.5]};
    color: ${({ theme }) => theme.colors.green[700]};
    font-size: ${({ theme }) => theme.text.size.body1};
  }
`;
