import styled from 'styled-components';
import { NoIssuesDescriptionStyled } from './NoIssuesDescription.styled';

export const NoMonitoringDescriptionStyled = styled(NoIssuesDescriptionStyled)`
  font-size: ${({ theme }) => theme.text.size.subtitle1};
  margin-top: ${({ theme }) => theme.spacing[4]};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  text-align: center;
  max-width: 400px;
`;
