import { axiosConfig as axios } from '@Utils';

export const updateIntegrationOrganization = async (organizationId: string, token: string) => {
  const response = await axios.post(
    'scm/integrate/organizations',
    { organizationId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response?.data;
};
