export const transformNumberToLabelText = (number: number): string => {
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}m`;
  }
  if (number >= 1000) {
    return `${(number / 1000).toFixed(1)}k`;
  }
  if (!number && number !== 0) {
    return '0';
  }
  return number.toString();
};
