import styled, { css } from 'styled-components';

export const RadioButtonLabelStyled = styled.label(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing[1]};
    cursor: pointer;
    user-select: none;
  `,
);
