import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { IInfoLabelProps, InfoLabelTypes } from './interfaces';
import { InfoLabelContainer, InfoLabelPrefixStyled, InfoLabelIconStyled } from './styled';

export const InfoLabel: React.FC<IInfoLabelProps> = ({ children, type = InfoLabelTypes.Default, dot = false, icon, prefix, celled, noContainer = false, capitalize = false }) => (
  <InfoLabelContainer $dot={dot} $capitalize={capitalize} $type={type} $celled={!!celled} $noContainer={noContainer}>
    {prefix && <InfoLabelPrefixStyled>{prefix}</InfoLabelPrefixStyled>}
    {prefix && <FontAwesomeIcon icon={faArrowRight} />}
    {icon && <InfoLabelIconStyled icon={icon} />}
    {children}
  </InfoLabelContainer>
);
