import { TitleContainer } from './styled';
import { Logo } from '../Integrator/styled';
import { ESign, SignRender } from '@Components';

export const GitLabModalTitle = () => (
  <TitleContainer>
    <Logo>
      <SignRender name={ESign.gitlab} />
    </Logo>
    Connect to GitLab
  </TitleContainer>
);
