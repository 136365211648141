import React from 'react';
import { Tooltip } from '@Components';
import { useModal } from '@Modal';
import { Fullname, FullnameCell } from './styled';
import { IDependencyFullnameCellProps } from './interfaces';
import { DependencyFullnameModal } from './components';

export const DependencyFullnameCell: React.FC<IDependencyFullnameCellProps> = ({ fullName }) => {
  const { openModal } = useModal();
  const onClickDependencyFullname = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    openModal(<DependencyFullnameModal fullName={fullName} />);
  };

  return (
    <Tooltip tooltip="Click to show more">
      <FullnameCell onClick={onClickDependencyFullname}>
        <Fullname>{fullName}</Fullname>
      </FullnameCell>
    </Tooltip>
  );
};
