import { axiosConfig as axios } from '@Utils';
import { IApiIssuesBaseParams } from '../interfaces';

export const fetchIssues1stParty = async (params: IApiIssuesBaseParams, token: string) => {
  const { pagination, repoId, filters, diff } = params;
  const response = await axios('issues/first-party', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(pagination && {
        page: pagination?.pageIndex + 1,
        take: pagination?.pageSize,
      }),
      repoId,
      diff,
      ...(filters && filters),
    },
  });
  return response?.data;
};
