import { useMutation } from 'react-query';
import { updateToken } from '../api';
import { IUseUpdateToken } from './types';
import { useSession } from '@descope/react-sdk';

export const useTokenUpdate = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation({
    mutationFn: async (payload: IUseUpdateToken) => {
      if (isAuthenticated && !isSessionLoading) {
        return updateToken(payload, sessionToken);
      }
    },
    retry: false,
  });
};
