import styled from 'styled-components';

import { ReachabilityDetailsStyled } from '../../styled';
import { IIssueDependencyChainStyledProps } from '../types';

export const IssueDependencyChainStyled = styled(ReachabilityDetailsStyled)<IIssueDependencyChainStyledProps>`
  min-height: 300px;
  max-height: 100vh;
  height: ${({ $height }) => $height}px;
`;
