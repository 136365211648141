import { IconDefinition, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ECodeViewerIconTypes } from './folderType.enum';
import { faFileAlt, faFolder, faRectangleCode } from '@fortawesome/pro-regular-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

export const folderTypeToIcon: Record<
  ECodeViewerIconTypes,
  {
    icon: IconDefinition;
    size: SizeProp;
  }
> = {
  [ECodeViewerIconTypes.folder]: {
    icon: faFolder,
    size: 'sm',
  },
  [ECodeViewerIconTypes.file]: {
    icon: faFileAlt,
    size: 'sm',
  },
  [ECodeViewerIconTypes.code]: {
    icon: faRectangleCode,
    size: 'sm',
  },
  [ECodeViewerIconTypes.true]: {
    icon: faChevronDown,
    size: '2xs',
  },
  [ECodeViewerIconTypes.false]: {
    icon: faChevronRight,
    size: '2xs',
  },
};
