import React, { useMemo, useState } from 'react';
import { RemediationStyled } from './styled';
import { usePagination, useQueryParams, useRemediationEffortUpdate } from '@Utils';
import { PaginationState } from '@tanstack/react-table';
import { initialPagination } from './Pagination';
import { useRemediationSummary } from './hooks';
import { useParams } from 'react-router-dom';
import { RemediationSummary } from './RemediationSummary';
import { RemediationListTitle } from './RemediationListTitle';
import { RemediationNoData } from './RemediationNoData';
import { initialSummaryData } from './config';
import { IRemediationProps } from './types';
import { usePullRequestDetailsContext } from 'Layouts/DiscoveryPage/hooks';
import { RemediationEffortGroup } from './RemediationEffortGroup';
import { useQueryClient } from 'react-query';
import { ERemediationEffortLevels } from '@blindspot/common/types/bff/remediation';
import { Loader } from '@Components';

export const Remediation: React.FC<IRemediationProps> = ({ isDiff = false }) => {
  const { repoId } = useParams();
  const { queryParams } = useQueryParams({});
  const queryClient = useQueryClient();
  const [{ pageIndex, pageSize }] = useState<PaginationState>({
    pageIndex: queryParams.page - 1 || initialPagination.pageIndex,
    pageSize: queryParams.size || initialPagination.pageSize,
  });

  const invalidateRemediation = () => {
    queryClient.invalidateQueries(['repoRemediationSummary', 'repoRemediation']);
  };

  useRemediationEffortUpdate(invalidateRemediation);

  const { pullRequest } = usePullRequestDetailsContext() || {};
  const branchId = pullRequest?.headBranch?.uuid;
  const diff = useMemo(() => (branchId && isDiff ? { branchId } : undefined), [branchId, isDiff]);

  const { data: summaryData = initialSummaryData, isFetching: isSummaryFetching } = useRemediationSummary({ repoId, diff });

  const remediationEffortLevels = summaryData.effortLevelsCount;

  usePagination(pageIndex, pageSize);

  const isDataExists =
    remediationEffortLevels[ERemediationEffortLevels.EASY] || remediationEffortLevels[ERemediationEffortLevels.MEDIUM] || remediationEffortLevels[ERemediationEffortLevels.HARD];

  if (isSummaryFetching) {
    return <Loader />;
  }

  if (!isDataExists) {
    return <RemediationNoData />;
  }

  return (
    <RemediationStyled>
      <RemediationSummary data={summaryData} isLoading={isSummaryFetching} />
      <RemediationListTitle />
      <>
        {Object.values(ERemediationEffortLevels).map((effortLevel) =>
          remediationEffortLevels[effortLevel] ? <RemediationEffortGroup key={effortLevel} effortLevel={effortLevel} summaryData={summaryData} /> : null,
        )}
      </>
    </RemediationStyled>
  );
};
