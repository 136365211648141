import React from 'react';
import { RadioButtonLabelStyled, RadioGroupStyled } from './styled';
import { IRadioGroupProps } from './interfaces';
import { RadioGroupButton } from './RadioGroupButton';

export const RadioGroup: React.FC<IRadioGroupProps> = ({ options, value, onChange }) => {
  return (
    <RadioGroupStyled>
      {options.map((option) => (
        <RadioButtonLabelStyled key={option.value}>
          <RadioGroupButton value={option.value} checked={option.value === value} onChange={onChange} />
          {option.label}
        </RadioButtonLabelStyled>
      ))}
    </RadioGroupStyled>
  );
};
