import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const FailConditionsContainerStyled = styled(Flex)(
  ({ theme }) => css`
    min-width: 461px;
    max-width: fit-content;
    margin-top: ${theme.spacing[2.5]};
  `,
);
