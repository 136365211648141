import { useQuery, useQueryParams } from '@Utils';
import { useEffect, useRef, useState } from 'react';
import { IFilterOption } from '../../types';

const getFiltersFromQuery = (query: URLSearchParams) => {
  const filterParams = {};

  query.forEach((value, key) => {
    if (key.startsWith('filter')) {
      filterParams[key] = value;
    }
  });

  return filterParams;
};

export const useURLFilters = () => {
  const callbackOnApply = useRef(null);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const { setQueryParams } = useQueryParams({});
  const query = useQuery();
  const filterParams = getFiltersFromQuery(query);
  const [appliedFilters, setAppliedFilters] = useState(filterParams);

  useEffect(() => {
    const filterParams = getFiltersFromQuery(query);

    setAppliedFilters(filterParams);
  }, [query]);

  useEffect(() => {
    const count = Object.entries(appliedFilters).reduce((accumulator: number, filter: [string, string]) => {
      if (filter[0].endsWith('Range]')) {
        return ++accumulator;
      }
      return accumulator + (filter ? filter[1]?.split('+').length : 0);
    }, 0) as number;

    setAppliedFiltersCount(count);
    setAppliedFilters(appliedFilters);
    setQueryFilters();
  }, [appliedFilters]);

  const updateFilters = (filterId: string, newFilters: IFilterOption[] | boolean) => {
    let filterValue;
    if (Array.isArray(newFilters)) {
      const filterValues = newFilters.map((option) => option.value);
      filterValue = filterValues.length ? filterValues.join('+') : undefined;
    } else {
      filterValue = newFilters?.toString();
    }

    setAppliedFilters((prevFilters) => ({ ...prevFilters, [`filter[${filterId}]`]: filterValue }));
    setTimeout(() => {
      if (callbackOnApply.current) {
        callbackOnApply.current();
      }
    }, 0);
  };

  const setQueryFilters = () => {
    const allQueryParams = query.entries();
    const newQuery = {};

    for (const [key, value] of allQueryParams) {
      if (!key.startsWith('filter')) {
        newQuery[key] = value;
      }
    }
    setQueryParams({
      ...newQuery,
      ...appliedFilters,
    });
  };

  const clearFilters = () => {
    const newQuery = {};
    query.forEach((value, key) => {
      if (!key.startsWith('filter')) {
        newQuery[key] = value;
      }
    });

    setAppliedFilters({});
    setQueryParams(newQuery);

    if (callbackOnApply.current) {
      callbackOnApply.current();
    }
  };

  const onFiltersApplied = (callback: () => void) => {
    callbackOnApply.current = callback;
  };

  return { appliedFilters, appliedFiltersCount, updateFilters, setQueryFilters, clearFilters, onFiltersApplied };
};
