import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const DetailsTitleStyled = styled(Grid)(
  ({ theme }) => css`
    grid-template-columns: 1fr auto;
    gap: ${theme.spacing[1]};
    align-items: center;
    margin: ${theme.spacing[0.5]} 0;
    font-size: ${theme.text.size.h2};
  `,
);
