import styled from 'styled-components';
import { Flex } from '@Styles';
import { PaginationStyled } from '../Pagination/styled';

export const RemediationStyled = styled(Flex)`
  color: ${({ theme }) => theme.colors.black};
  flex-direction: column;
  width: 100%;
  min-height: 115px;

  ${PaginationStyled} {
    margin-left: auto;
  }
`;
