import { Flex } from '@Styles';
import { ISpacing } from 'Styles/types';
import styled from 'styled-components';

export const ControlInputBlockStyled = styled(Flex)<{ margin?: keyof ISpacing; gap?: keyof ISpacing }>`
  flex: 1;
  flex-direction: column;
  margin: ${({ theme, margin }) => `${theme.spacing[margin || '0']} 0`};
  gap: ${({ theme, gap }) => `${theme.spacing[gap || '0']}`};
`;
