export const spacing = {
  '0.5': '4px' as const,
  '1': '8px' as const,
  '1.5': '12px' as const,
  '2': '16px' as const,
  '2.5': '20px' as const,
  '3': '24px' as const,
  '3.5': '28px' as const,
  '4': '32px' as const,
  '4.5': '36px' as const,
  '5': '40px' as const,
  '6': '48px' as const,
  '7': '56px' as const,
};
