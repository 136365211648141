import { Flex } from '@Styles';
import styled from 'styled-components';

export const SelectOptionsWrapper = styled.div<{ $width?: number }>`
  flex-direction: column;
  border-radius: 10px;
  min-width: 177px;
  width: ${({ $width }) => ($width ? `${$width}px` : '177px')};
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 2px 12px rgba(10, 24, 154, 0.05);
  padding: ${({ theme }) => theme.spacing['1']};
  background: ${({ theme }) => theme.colors.white};
`;
