import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReachability } from './hooks';
import { ReachabilityRepository } from './ReachabilityRepository';
import { ReachabilityStyled } from './styled';
import { Flex } from '@Styles';
import { useSearch } from '@Utils';
import { ReachabilityNoData } from './ReachabilityNoData';
import { Search } from 'core/Search';

export const Reachability: React.FC = () => {
  const { baseIssueUuid } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const { data: reachabilities = [], isLoading } = useReachability({ baseIssueUuid, search: searchValue });

  useSearch(searchValue);
  const onSearch = (value: string) => setSearchValue(value);

  return (
    <ReachabilityStyled>
      <Search value={searchValue} onChange={onSearch} placeholder="Search" />
      {!reachabilities.length || isLoading ? (
        <ReachabilityNoData isLoading={isLoading} />
      ) : (
        <Flex flexDirection="column" gap={1.5}>
          {reachabilities.map(({ repository, issuesTotalCount, issues }) => (
            <ReachabilityRepository key={repository.name} repository={repository} issuesTotalCount={issuesTotalCount} issues={issues} />
          ))}
        </Flex>
      )}
    </ReachabilityStyled>
  );
};
