import React from 'react';
import { DashboardLayout, DiscoveryProgressBarPopup } from '@Layouts';
import { ToastCenter } from '@Notification';
import { DrawerProvider } from '@Drawer';
import { FilterProvider } from 'core/Filter';
import { useSubscribe } from '@Utils';
import { useSession } from '@descope/react-sdk';
import { LoginPage } from './LoginPage';

export const Layout: React.FC = () => {
  useSubscribe();
  const { isAuthenticated, isSessionLoading } = useSession();

  if (!isAuthenticated && !isSessionLoading) {
    return <LoginPage />;
  }

  return (
    isAuthenticated && (
      <FilterProvider>
        <DrawerProvider>
          <main>
            <DashboardLayout />
          </main>
          <DiscoveryProgressBarPopup />
          <ToastCenter />
        </DrawerProvider>
      </FilterProvider>
    )
  );
};
