import { Flex } from '@Styles';
import styled from 'styled-components';

export const ErrorContainerStyled = styled(Flex)<{ $fullHeight: boolean }>`
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ $fullHeight }) => ($fullHeight ? '100vh' : '100%')};
`;
