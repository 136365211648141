import { useNavigate } from 'react-router-dom';
import { Descope } from '@descope/react-sdk';

import { Flex } from '@Styles';

export const LoginMagicLink = () => {
  const navigate = useNavigate();
  return (
    <Flex>
      <Descope flowId="magic-link" onSuccess={() => navigate('/')} onError={(e) => console.log('Could not log in!')} />
    </Flex>
  );
};
