import { Button } from '@Styles';
import styled from 'styled-components';

export const SubmitButton = styled(Button)`
  height: 40px;
  border-radius: 8px;
  line-height: 160%;
  letter-spacing: 0.4px;
  padding: ${({ theme }) => `0 ${theme.spacing[2]}`};
  background: ${({ theme }) => theme.colors.indigo[500]};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  margin-top: ${({ theme }) => theme.spacing[1]};

  &:disabled {
    background: ${({ theme }) => theme.colors.zinc[200]};
    color: ${({ theme }) => theme.colors.zinc[400]};
    cursor: default;
    pointer-events: none;
  }
`;
