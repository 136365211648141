import React from 'react';
import { IButtonProps } from './interfaces';
import { ButtonWrapperStyled } from './styled';

export const Button: React.FC<IButtonProps> = ({
  onClick,
  children,
  disabled,
  backgroundColor,
  textColor,
  border,
  hoverBackgroundColor,
  hoverTextColor,
  hoverBorder,
  disabledBackgroundColor,
  disabledTextColor,
  disabledBorder,
  type,
  padding,
  width,
  height,
  size,
  variant,
  color,
  ...props
}) => {
  return (
    <ButtonWrapperStyled
      as={'button'}
      onClick={onClick}
      $backgroundColor={backgroundColor}
      $border={border}
      $textColor={textColor}
      $hoverBackgroundColor={hoverBackgroundColor}
      $hoverTextColor={hoverTextColor}
      $hoverBorder={hoverBorder}
      $disabledBackgroundColor={disabledBackgroundColor}
      $disabledTextColor={disabledTextColor}
      $disabledBorder={disabledBorder}
      $padding={padding}
      $width={width}
      $height={height}
      disabled={disabled}
      type={type}
      $size={size}
      $variant={variant}
      $color={color}
      justifyContent="center"
      alignItems="center"
      gap={1}
      {...props}
    >
      {children}
    </ButtonWrapperStyled>
  );
};
