import { axiosConfig as axios } from '@Utils';

export const repositoryMonitorAll = async (token: string) => {
  return axios.post(
    `repositories/monitor/all`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
