import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const CreateTokenButtonStyled = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background: transparent;
  border-radius: 8px;
  line-height: 155%;
  letter-spacing: 0.4px;
  padding: ${({ theme }) => css`0 ${theme.spacing[1.5]}`};
  border: ${({ theme }) => css`1px solid ${theme.colors.indigo[300]}`};
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.indigo[500]};
`;
