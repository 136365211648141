import { format, parseISO } from 'date-fns';
import { CardInfoTextStyled } from '../styled';
import { DATE_TIME_LONG_FORMAT } from '@Utils';

export const LatestCommit = ({ lastCommitDate }: { lastCommitDate?: string }) => {
  if (!lastCommitDate) {
    return null;
  }
  const parsedDate = parseISO(lastCommitDate);
  const formattedDate = format(parsedDate, DATE_TIME_LONG_FORMAT);
  return <CardInfoTextStyled>{`Latest commit: ${formattedDate}`}</CardInfoTextStyled>;
};
