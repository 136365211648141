import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DrawerApiChangesContainerStyled,
  DrawerContentCloseButtonStyled,
  DrawerContentContainerStyled,
  DrawerContentFooterContainerStyled,
  DrawerHeaderContainerStyled,
  DrawerRemediationRecommendationContainerStyled,
  DrawerRemediationSummaryContainerStyled,
  DrawerRemediationTargetContainerStyled,
  DrawerTargetDependencyStyled,
  RemediationEditTargetText,
  RemmediationRecommendationContainerStyled,
} from './styled';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';
import { RemediationDepLineNumberStyled } from '../styled';
import { IRemediationEditDrawerContent } from './types';
import { RemediationTarget } from '../RemediationTarget';
import { useRemediationTargets } from '../hooks';
import { RemediationEffortsChanges, RemediationEffortStableChange } from './RemediationEffortChange';
import { useDrawer } from '@Drawer';
import { ERemediationEffortLevels, IRemediationEffortUpdateApiUsage, RemediationEffortMethodDiffs } from '@blindspot/common/types/bff/remediation';
import { RemediationDrawerNavigation } from 'Layouts/DiscoveryPage/components/Remediation/RemediationEditDrawer/RemediationDrawerNavigation';
import { RemediationEffortStableChangeTextStyled } from './RemediationEffortChange/styled';

export const RemediationEditDrawerContent: React.FC<IRemediationEditDrawerContent> = ({
  dependency,
  handleChangeDependency,
  dependenciesAmount,
  currentDependencyIndex,
  repoId,
}: IRemediationEditDrawerContent) => {
  const { closeDrawer } = useDrawer();

  const { data: remediationTargets = [] } = useRemediationTargets(repoId, dependency.source.uuid);
  const selectedRemediation = remediationTargets.find((item) => item.recommended);

  const isPrevDependencyDisabled = currentDependencyIndex === 0;
  const isNextDependencyDisabled = currentDependencyIndex === dependenciesAmount - 1;

  const { stableApiUsages, nonStableApiUsages } = dependency.efforts.apiUsages.reduce(
    (acc, apiUsage) => {
      const isStable = apiUsage.apiChanges.every((apiChange) => apiChange.methodDiffs.every((methodDiff) => methodDiff === RemediationEffortMethodDiffs.STABLE));

      if (isStable) {
        acc.stableApiUsages.push(apiUsage);
      } else {
        acc.nonStableApiUsages.push(apiUsage);
      }

      return acc;
    },
    { stableApiUsages: [], nonStableApiUsages: [] } as {
      stableApiUsages: IRemediationEffortUpdateApiUsage[];
      nonStableApiUsages: IRemediationEffortUpdateApiUsage[];
    },
  );

  return (
    <DrawerContentContainerStyled>
      <DrawerHeaderContainerStyled>
        <RemediationDrawerNavigation
          dependency={dependency}
          dependenciesAmount={dependenciesAmount}
          currentDependencyIndex={currentDependencyIndex}
          handleChangeDependency={handleChangeDependency}
          isNextDependencyDisabled={isNextDependencyDisabled}
          isPrevDependencyDisabled={isPrevDependencyDisabled}
        />
      </DrawerHeaderContainerStyled>
      <DrawerTargetDependencyStyled>
        <RemediationDepLineNumberStyled href={dependency.depFileUrlWithLine} target="_blank">
          Line #{dependency.depFileLineNumber}
        </RemediationDepLineNumberStyled>
        <RemediationEditTargetText>
          <b>{dependency.source.name}</b> {dependency.source.version} <FontAwesomeIcon icon={faArrowRightLong} /> {dependency.target.version}
        </RemediationEditTargetText>
      </DrawerTargetDependencyStyled>
      <DrawerRemediationTargetContainerStyled>
        <span>Selected Package Version Issues</span>
        <DrawerRemediationSummaryContainerStyled>
          <RemediationTarget targets={remediationTargets} dependency={dependency} isLoading={false} onChange={undefined} selectedTarget={selectedRemediation} />
        </DrawerRemediationSummaryContainerStyled>
      </DrawerRemediationTargetContainerStyled>
      {nonStableApiUsages.length > 0 && (
        <DrawerApiChangesContainerStyled>
          <span>Selected Package Version API Changes</span>
          {dependency.efforts.effortLevel === ERemediationEffortLevels.EASY && <div>API didn’t change, no source code changes needed</div>}
          {(dependency.efforts.effortLevel === ERemediationEffortLevels.MEDIUM || dependency.efforts.effortLevel === ERemediationEffortLevels.HARD) &&
            nonStableApiUsages.map((apiUsage, index) => <RemediationEffortsChanges apiUsage={apiUsage} key={index} />)}
        </DrawerApiChangesContainerStyled>
      )}
      {stableApiUsages.length > 0 && dependency.efforts.effortLevel !== ERemediationEffortLevels.EASY && (
        <DrawerApiChangesContainerStyled>
          <RemediationEffortStableChangeTextStyled>FYI No Changes Need</RemediationEffortStableChangeTextStyled>

          <RemediationEffortStableChange apiUsages={stableApiUsages} />
        </DrawerApiChangesContainerStyled>
      )}
      <RemmediationRecommendationContainerStyled>
        <span>Remediation Recommendation</span>
        <DrawerRemediationRecommendationContainerStyled>
          <div>1. Upgrade the package to the selected version</div>
          {dependency.efforts.effortLevel === ERemediationEffortLevels.EASY && (
            <>
              <div>2. Perform a regression test</div>
            </>
          )}
          {[ERemediationEffortLevels.HARD, ERemediationEffortLevels.MEDIUM].includes(dependency.efforts.effortLevel) && (
            <>
              <div>2. Fix all the required API changes</div>
              <div>3. Perform a regression test</div>
            </>
          )}
        </DrawerRemediationRecommendationContainerStyled>
      </RemmediationRecommendationContainerStyled>
      <DrawerContentFooterContainerStyled>
        <DrawerContentCloseButtonStyled onClick={closeDrawer}>Close</DrawerContentCloseButtonStyled>
      </DrawerContentFooterContainerStyled>
    </DrawerContentContainerStyled>
  );
};
