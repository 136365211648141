import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const GptResponseWrapperStyled = styled(Grid)(
  ({ theme }) => css`
    grid-template-columns: 32px 1fr;
    grid-column-gap: ${theme.spacing[2]};
    padding: ${theme.spacing[2]} ${theme.spacing[2]} ${theme.spacing[1.5]};
    border: 1px solid ${theme.colors.zinc[200]};
    border-radius: 11px;
  `,
);
