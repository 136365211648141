import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const ModalContentStyled = styled(Grid)(
  ({ theme }) => css`
    width: 600px;
    margin-top: ${theme.spacing[1.5]};
    row-gap: ${theme.spacing[3]};
  `,
);
