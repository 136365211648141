import React from 'react';
import { IssueReachabilityTooltipStyled } from './styled';
import { IIssueReachabilityProps } from './types';
import { EReachabilityStatus } from '@APITypes';

export const ReachabilityTooltip: React.FC<IIssueReachabilityProps> = ({ reachabilityStatus }) => {
  if (reachabilityStatus === EReachabilityStatus.REACHABLE) {
    return <IssueReachabilityTooltipStyled>At least one of the issues instances has a reachable path for the vulnerable function</IssueReachabilityTooltipStyled>;
  }

  if (reachabilityStatus === EReachabilityStatus.UNREACHABLE) {
    return <IssueReachabilityTooltipStyled>The vulnerable function is unreachable across all issue instances</IssueReachabilityTooltipStyled>;
  }
  return null;
};
