import { axiosConfig as axios } from '@Utils';
import { IApiDashboardFilterParams } from '../interfaces';

export const fetchIssuesByCategory = async (token: string, params?: IApiDashboardFilterParams) => {
  const response = await axios('issues/summary/category', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(params?.filter && params?.filter),
    },
  });
  return response?.data;
};
