import { axiosConfig as axios } from '@Utils';

export const fetchUserRoles = async (token: string) => {
  const response = await axios('user/roles', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
