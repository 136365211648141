import styled from 'styled-components';

export const RemediationEditTargetText = styled.span`
  font-size: ${({ theme }) => theme.text.size.body1};
  color: ${({ theme }) => theme.colors.black};
  margin-left: ${({ theme }) => theme.spacing[3]};

  & > svg {
    margin: 0 ${({ theme }) => theme.spacing[1]};
  }
`;
