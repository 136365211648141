import { useQuery } from 'react-query';
import { fetchRepositoriesName } from '../api';
import { IApiDashboardFilterParams, IRepositoryNameItem } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useRepositoriesName = (params?: IApiDashboardFilterParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const key = 'repositoriesName';

  const queryOptions = {
    queryKey: [key, params],
    queryFn: async () => {
      return await fetchRepositoriesName(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IRepositoryNameItem[]>(queryOptions);
};
