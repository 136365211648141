import styled from 'styled-components';

export const UserRenderContainerStyled = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.indigo['50']};
  border-radius: 24px;
  display: inline-flex;
  height: 25px;
  align-items: center;
  font-size: ${({ theme }) => theme.text.size.caption};
  padding: 0 8px;
  cursor: default;
`;
