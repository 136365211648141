import styled from 'styled-components';

export const ReachabilityRepositoryMoreStyled = styled.button`
  margin-left: ${({ theme }) => theme.spacing['2']};
  font-family: 'Manrope';
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.indigo['500']};
  letter-spacing: 0.4px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;
