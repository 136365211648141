import { useQuery } from 'react-query';
import { IScanConfigSet } from '@blindspot/common/types/bff/configs';
import { fetchScanConfigSets } from './api';
import { useSession } from '@descope/react-sdk';

export const useScanConfigSets = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['scanConfigSets'],
    queryFn: async () => {
      return await fetchScanConfigSets(sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IScanConfigSet[]>(queryOptions);
};
