import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const DropdownTriggerStyled = styled(Button)<{ $isIconView?: boolean }>(
  ({ theme, $isIconView }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing[1]};
    padding: ${theme.spacing[0.5]} ${theme.spacing[1.5]};
    height: ${theme.spacing[4]};
    border: ${$isIconView ? 'none' : `1px solid ${theme.colors.zinc[500]}`};
    border-radius: 8px;
    font-size: ${theme.text.size.body2};
    color: ${theme.colors.zinc[900]};
    letter-spacing: 0.4px;
  `,
);
