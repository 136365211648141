import Shape2 from '@Images/login/shape2.png';
import styled from 'styled-components';

export const Shape2Styled = styled.img.attrs({
  src: Shape2,
})`
  position: absolute;
  bottom: 70px;
  left: 505px;
`;
