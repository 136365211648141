import { axiosConfig as axios } from '@Utils';
import { IApiIssuesParams } from '../interfaces';

export const fetchIssues = async (params: IApiIssuesParams, token: string) => {
  const { pagination, search, repoId, depId, issueId, filters, diff } = params;
  const response = await axios('issues', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(pagination && {
        page: pagination?.pageIndex + 1,
        take: pagination?.pageSize,
      }),
      ...(search && { search }),
      repoId,
      depId,
      issueId,
      diff,
      ...(filters && filters),
    },
  });
  return response?.data;
};
