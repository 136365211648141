import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const FunnelGraphStyled = styled(Flex)(
  ({ theme }) => css`
    flex-direction: column;
    width: 100%;

    #funnel {
      width: 100%;
      height: 100%;
      font-family: ${theme.text.fontFamily};
      font-weight: ${theme.text.weight.bold};
      font-size: ${theme.text.size.h3};

      & .label__title {
        color: ${theme.colors.black};
        font-size: ${theme.text.size.overline};
        font-weight: ${theme.text.weight.regular};
        cursor: pointer;
        text-decoration: underline;
        &:visited {
          color: ${({ theme }) => theme.colors.black};
        }
      }
      & .label-1 {
        padding-left: 0 !important;
      }
      & .label__value {
        color: ${theme.colors.black};
        font-size: ${theme.text.size.h3};
        font-family: ${theme.text.fontFamily};
        font-weight: ${theme.text.weight.bold};
      }
      & .svg-funnel-js__subLabels {
        & .svg-funnel-js__subLabel--title {
          color: ${theme.colors.black} !important;
          font-size: ${theme.text.size.overline};
          font-family: ${theme.text.fontFamily};
          font-weight: ${theme.text.weight.regular};
        }
        display: flex;
        justify-content: end;
        position: absolute;
        top: -60px;
        right: 0;
      }
      & .svg-funnel-js__label:not(:first-child) {
        border-left: ${theme.colors.zinc[300]} dashed 1px !important;
      }
    }
  `,
);
