import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemmediationRecommendationContainerStyled = styled(Flex)`
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing[4]};

  margin-top: ${({ theme }) => theme.spacing[1]};
  & > span {
    font-size: ${({ theme }) => theme.text.size.overline};
    font-weight: ${({ theme }) => theme.text.weight.bold};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    opacity: 0.5;
  }
`;
