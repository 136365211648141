import React, { useEffect, useRef, useState } from 'react';
import {
  TableBodyStyled,
  TableDataStyled,
  TableHeaderStyled,
  TableHeadStyled,
  TableStyled,
  TableRowStyled,
  TableFooterStyled,
  TableResizerStyled,
  TableActionsWrapper,
  TableActions,
  TableLinkCellStyled,
} from './styled';
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from '@tanstack/react-table';

import { Search } from 'core/Search';
import { Pagination } from './styled/pagination';
import { Sorting } from './styled/sorting';
import { TableLoadingStyled } from './styled/TableLoading.styled';

import { TableCheckbox } from './TableCheckbox';
import { TableColumnVisibility } from './TableColumnVisibility';
import { FilterButton } from '../Filter';
import { ITableProps } from './types';

export const Table: React.FC<ITableProps> = ({ initialState, columns, data, tableFeatures, emptyState, isLoading, getRowLink, getColspan, getHiddenCell, evaluateRowHighlight }) => {
  const [tableData, setTableData] = useState(() => []);
  const tableWrapperRef = useRef(null);

  if (data.length && tableFeatures?.rowSelection) {
    columns.unshift({
      id: 'select',
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <TableCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    });
  }

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const tableInstance = useReactTable({
    initialState,
    state: {
      globalFilter: tableFeatures?.search?.value ?? '',
      sorting: tableFeatures?.sorting?.value ?? [],
      pagination: tableFeatures?.pagination?.value ?? { pageIndex: 0, pageSize: 15 },
      rowSelection: tableFeatures?.rowSelection?.value ?? {},
      columnVisibility: tableFeatures?.columnVisibility?.value ?? {},
    },
    pageCount: tableFeatures?.pagination?.pageCount ?? 0,
    itemCount: tableFeatures?.pagination?.itemCount ?? 0,
    onGlobalFilterChange: tableFeatures?.search?.onChange,
    data: tableData,
    columns: columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: !!tableFeatures?.sorting,
    onSortingChange: tableFeatures?.sorting?.onChange,
    manualPagination: !!tableFeatures?.pagination,
    onPaginationChange: (paginationState) => {
      if (!tableFeatures?.pagination) {
        return;
      }
      tableFeatures.pagination.onChange(paginationState);
      tableWrapperRef.current.scrollIntoView();
    },
    onRowSelectionChange: tableFeatures?.rowSelection?.onChange,
    onColumnVisibilityChange: tableFeatures?.columnVisibility?.onChange,
  });

  const tableDataLoading = tableInstance.getHeaderGroups().map((headerGroup) => (
    <TableRowStyled key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <TableHeaderStyled key={header.id} style={{ width: header.getSize() }}>
          {flexRender(<TableLoadingStyled />, {})}
        </TableHeaderStyled>
      ))}
    </TableRowStyled>
  ));

  const tableDataRows = tableInstance.getRowModel().rows.map((row) => (
    <TableRowStyled $highlight={evaluateRowHighlight ? evaluateRowHighlight(row) : false} key={row.id}>
      {row.getVisibleCells().map((cell) => (
        <TableDataStyled key={cell.id} colSpan={getColspan ? getColspan(cell) : 1} $hiddenCell={getHiddenCell && getHiddenCell(cell)}>
          <TableLinkCellStyled
            to={getRowLink && getRowLink(row.original)}
            $align={cell.column.columnDef.meta?.align}
            $padding={cell.column.columnDef.meta?.padding}
            $isLink={Boolean(getRowLink)}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableLinkCellStyled>
        </TableDataStyled>
      ))}
    </TableRowStyled>
  ));

  const tableRows = isLoading ? tableDataLoading : tableDataRows;

  const tableEmptyState = (
    <tr>
      <td colSpan={tableInstance.getAllColumns().length}>{emptyState}</td>
    </tr>
  );

  return (
    <div ref={tableWrapperRef}>
      <TableActionsWrapper>
        {tableFeatures?.filters && <FilterButton filters={tableFeatures.filters} />}
        {tableFeatures?.search && <Search value={tableFeatures?.search?.value ?? ''} onChange={(value) => tableFeatures?.search?.onChange(String(value))} placeholder="Search" />}
        <TableActions>
          {tableFeatures?.actions && tableFeatures.actions}
          {tableFeatures?.columnVisibility?.show && <TableColumnVisibility columns={tableInstance.getAllLeafColumns()} onChange={(column) => column.toggleVisibility()} />}
        </TableActions>
      </TableActionsWrapper>
      {tableFeatures?.notifications && tableFeatures.notifications}
      <TableStyled style={{ width: tableInstance.getTotalSize() }}>
        <TableHeadStyled>
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <TableRowStyled key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderStyled
                  colSpan={getColspan ? getColspan(header) : 1}
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{ width: header.getSize() }}
                  $align={header.column.columnDef.meta?.align}
                >
                  {!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getIsSorted() && <Sorting direction={header.column.getIsSorted()} />}
                  {header.column.getCanResize() && (
                    <TableResizerStyled onMouseDown={header.getResizeHandler()} onTouchStart={header.getResizeHandler()} $isResizing={header.column.getIsResizing()} />
                  )}
                </TableHeaderStyled>
              ))}
            </TableRowStyled>
          ))}
        </TableHeadStyled>
        <TableBodyStyled>{(tableInstance.getRowModel().rows.length && data.length) || isLoading ? tableRows : tableEmptyState}</TableBodyStyled>
        <TableFooterStyled>{tableFeatures?.pagination && !isLoading && <Pagination table={tableInstance} />}</TableFooterStyled>
      </TableStyled>
    </div>
  );
};
