import { MarkerType } from 'reactflow';
import { Edge } from 'reactflow';

export function createEdge(from: string, to: string): Edge {
  return {
    id: `${from}-${to}`,
    source: from,
    target: to,
    type: 'bezier',
    markerEnd: {
      type: MarkerType.Arrow,
      width: 20,
      height: 20,
      color: 'gray',
    },
    style: {
      strokeWidth: 0.38,
      stroke: 'gray',
    },
    interactionWidth: 0,
  };
}
