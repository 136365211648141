import { InfoLabel } from '@Components';
import { Button, Flex } from '@Styles';
import { ITicketsListProps } from './types';
import { useModal } from '@Modal';
import { TicketLabel } from './TicketLabel';
import { TicketsListModal } from './TicketsListModal';

export const TicketsList: React.FC<ITicketsListProps> = ({ tickets }) => {
  const { openModal } = useModal();

  const onShowMoreTickets = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    openModal(<TicketsListModal tickets={tickets} />);
  };

  return (
    <Flex gap={0.5} justifyContent="center">
      <TicketLabel ticket={tickets[0]} />
      {tickets.length > 1 && (
        <Button onClick={onShowMoreTickets}>
          <InfoLabel>+{tickets.length - 1}</InfoLabel>
        </Button>
      )}
    </Flex>
  );
};
