import styled, { css } from 'styled-components';

export const SwitchDescriptionStyled = styled.span(
  ({ theme }) => css`
    display: block;
    font-size: ${theme.text.size.body2};
    font-style: normal;
    line-height: 24px;
    margin-top: ${theme.spacing[1]};
  `,
);
