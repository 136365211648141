import styled, { css } from 'styled-components';

export const ScanConfigSetNameStyled = styled.h2(
  ({ theme }) => css`
    word-break: break-all;
    font-size: ${theme.text.size.h2};
    font-weight: ${theme.text.weight.bold};
    line-height: 32px;
    letter-spacing: 0.2px;
    border: 1px solid transparent;
    padding: 0 ${theme.spacing[0.5]};
  `,
);
