import { useLocation } from 'react-router-dom';
import { IssuesSourceTypeContainerStyled } from './styled';
import { IssuesSourceTypeButtonStyled } from './styled/IssuesSourceTypeButton.styled';

export const IssuesSourceTypeSwitch: React.FC = () => {
  const location = useLocation();
  const isFirstParty = location.pathname.includes('first-party');
  const [basePathname] = location.pathname.split('/issues');

  return (
    <IssuesSourceTypeContainerStyled>
      <IssuesSourceTypeButtonStyled relative="path" to={`${basePathname}/issues/`} $isActive={!isFirstParty} $position="left">
        3rd Party Code
      </IssuesSourceTypeButtonStyled>
      <IssuesSourceTypeButtonStyled relative="path" to={`${basePathname}/issues/first-party`} $isActive={isFirstParty} $position="right">
        1st Party Code
      </IssuesSourceTypeButtonStyled>
    </IssuesSourceTypeContainerStyled>
  );
};
