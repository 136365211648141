import { useQuery } from 'react-query';
import { ETicketManager, ITicketManagerProjectResources } from '@blindspot/common/types/bff/tickets';
import { getTicketManagerProjectResources } from './api';
import { useSession } from '@descope/react-sdk';

export const useTicketManagerProjectResources = (ticketManager: ETicketManager, projectId: string, isEnabled = true) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const queryOptions = {
    queryKey: ['getTicketManagerProjectResources', ticketManager, projectId],
    queryFn: async () => {
      return await getTicketManagerProjectResources(sessionToken, ticketManager, projectId);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && isEnabled && !isSessionLoading,
  };
  return useQuery<ITicketManagerProjectResources>(queryOptions);
};
