import { CircleContainerStyled, NoRemediationContentStyled, NoRemediationSymbolIconStyled, NoRemediationTextStyled } from './styled';

export const NoRemediationContent = () => {
  return (
    <NoRemediationContentStyled>
      <CircleContainerStyled>
        <NoRemediationSymbolIconStyled />
      </CircleContainerStyled>
      <NoRemediationTextStyled>No remediation available</NoRemediationTextStyled>
    </NoRemediationContentStyled>
  );
};
