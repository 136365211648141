import styled from 'styled-components';

export const InfoGitLabModalContent = styled.div`
  width: 430px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.indigo[50]};
  padding: ${({ theme }) => theme.spacing[3]};
  font-size: ${({ theme }) => theme.text.size.body2};
  white-space: pre-wrap;
`;
