import { useQuery } from 'react-query';
import { ProgressBar } from '@APITypes';
import { fetchOrganizationDiscoveryProgressBar } from './api';
import { useSession } from '@descope/react-sdk';

export const useOrganizationDiscoveryProgressBar = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['orgDiscoveryProgressBar'],
    queryFn: async () => {
      return await fetchOrganizationDiscoveryProgressBar(sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };
  return useQuery<ProgressBar[]>(queryOptions);
};
