import React from 'react';
import { useParams } from 'react-router-dom';
import { ModalProvider, useModal } from '@Modal';
import { MonitoringPreferencesModal } from './MonitoringPreferences';
import { IMonitorPreferencesTriggerProps } from './interfaces';
import { Button } from '@Styles';

const MonitorPreferencesTriggerBase: React.FC<IMonitorPreferencesTriggerProps> = ({ onMonitor, children }) => {
  const { repoId } = useParams();
  const { openModal } = useModal();

  const openMonitoringPreferences = () => {
    openModal(<MonitoringPreferencesModal isEditMode={true} repositoryId={repoId} onMonitor={onMonitor} />);
  };

  return <Button onClick={openMonitoringPreferences}>{children}</Button>;
};

export const MonitorPreferencesTrigger: React.FC<IMonitorPreferencesTriggerProps> = (props) => (
  <ModalProvider>
    <MonitorPreferencesTriggerBase {...props} />
  </ModalProvider>
);
