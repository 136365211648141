import styled from 'styled-components';
import { InfoGitLabModalContent } from './InfoGitLabModalContent.styled';

export const InputGitLabModalContent = styled(InfoGitLabModalContent)`
  background: ${({ theme }) => theme.colors.transparent};
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[3]};
  align-items: flex-start;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black};
  line-height: ${({ theme }) => theme.spacing[3]};
  letter-spacing: 0.2px;
`;
