import { ICVSSVector } from '../interfaces';

const confidentialityImpact: ICVSSVector = {
  name: 'Confidentiality Impact',
  code: 'C',
  metrics: [
    { name: 'None', code: 'N', description: 'There is no loss of confidentiality within the impacted component.' },
    {
      name: 'Low',
      code: 'L',
      description:
        'There is some loss of confidentiality. Access to some restricted information is obtained, but the attacker does not have control over what information is obtained, or the amount or kind of loss is constrained. The information disclosure does not cause a direct, serious loss to the impacted component.',
    },
    {
      name: 'High',
      code: 'H',
      description:
        'There is total loss of confidentiality, resulting in all resources within the impacted component being divulged to the attacker. Alternatively, access to only some restricted information is obtained, but the disclosed information presents a direct, serious impact.',
    },
  ],
  description:
    'This metric measures the impact to the confidentiality of the information resources managed by a software component due to a successfully exploited vulnerability. Confidentiality refers to limiting information access and disclosure to only authorized users, as well as preventing access by, or disclosure to, unauthorized ones.',
};

const integrityImpact: ICVSSVector = {
  name: 'Integrity Impact',
  code: 'I',
  metrics: [
    { name: 'None', code: 'N', description: 'There is no loss of integrity within the impacted component.' },
    {
      name: 'Low',
      code: 'L',
      description:
        'Modification of data is possible, but the attacker does not have control over the consequence of a modification, or the amount of modification is constrained. The data modification does not have a direct, serious impact on the impacted component.',
    },
    {
      name: 'High',
      code: 'H',
      description:
        'There is a total loss of integrity, or a complete loss of protection. For example, the attacker is able to modify any/all files protected by the impacted component. Alternatively, only some files can be modified, but malicious modification would present a direct, serious consequence to the impacted component.',
    },
  ],
  description: 'This metric measures the impact to integrity of a successfully exploited vulnerability. Integrity refers to the trustworthiness and veracity of information.',
};

const availabilityImpact: ICVSSVector = {
  name: 'Availability Impact',
  code: 'A',
  metrics: [
    { name: 'None', code: 'N', description: 'There is no impact on availability within the impacted component.' },
    {
      name: 'Low',
      code: 'L',
      description:
        'There is reduced performance or interruptions in resource availability. Even if repeated exploitation of the vulnerability is possible, the attacker does not have the ability to completely deny service to legitimate users. The resources in the impacted component are either partially available all of the time or fully available only some of the time, but overall there is no direct, serious consequence to the impacted component.',
    },
    {
      name: 'High',
      code: 'H',
      description:
        'There is a total loss of availability, resulting in the attacker being able to fully deny access to resources in the impacted component; this loss is either sustained (while the attacker continues to deliver the attack) or persistent (the condition persists even after the attack has completed). Alternatively, the attacker has the ability to deny some availability, but the loss of availability presents a direct, serious consequence to the impacted component (e.g., the attacker cannot disrupt existing connections, but can prevent new connections; the attacker can repeatedly exploit a vulnerability that, in each instance of a successful attack, leaks a only small amount of memory, but after repeated exploitation causes a service to become completely unavailable).',
    },
  ],
  description:
    'This metric measures the impact to the availability of the impacted component resulting from a successfully exploited vulnerability. While the Confidentiality and Integrity impact metrics apply to the loss of confidentiality or integrity of data (e.g., information, files) used by the impacted component, this metric refers to the loss of availability of the impacted component itself, such as a networked service (e.g., web, database, email). Since availability refers to the accessibility of information resources, attacks that consume network bandwidth, processor cycles, or disk space, all impact the availability of an impacted component.',
};

export const impactMetrics = [confidentialityImpact, integrityImpact, availabilityImpact];
