import { axiosConfig as axios } from '@Utils';

export const fetchRepositoryTicket = async (repoUuid: string, token: string) => {
  const response = await axios(`repositories/${repoUuid}/ticket`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
