import styled, { keyframes } from 'styled-components';

const loader = keyframes`
  0% {
    background-position: -30vw 0
  }
  100% {
    background-position: 30vw 0
  }
`;
export const TableLoadingStyled = styled.div`
  animation-duration: 20s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${loader};
  animation-timing-function: linear;
  border-radius: 1em;
  background: ${({ theme }) => `${theme.colors.white}`};
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  height: 1em;
  width: 100%;
  position: relative;
  -webkit-backface-visibility: hidden;
`;
