import React, { useState } from 'react';
import { useOutsideClick } from '@Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { ITableSelect, OptionType, SelectPosition } from './types';
import { SelectButtonStyled, SelectLabelStyled, SelectOptionStyled, SelectOptionsStyled, SelectWrapperStyled } from './styled/Select';

export const Select: React.FC<ITableSelect> = ({ options, label, icon, selectedOption, styleMode, openDirection = SelectPosition.Top, onChange }) => {
  const [currentValue, setCurrentValue] = useState<OptionType>(selectedOption || options[0]);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleValueChange = (value: OptionType) => {
    setCurrentValue(value);
  };
  const handleChange = (event: React.MouseEvent<HTMLElement>, value: OptionType) => {
    event.stopPropagation();

    handleValueChange(value);

    if (onChange) onChange(value);

    handleClose();
  };

  const ref = useOutsideClick(handleClose);

  return (
    <SelectWrapperStyled $mode={styleMode}>
      <SelectButtonStyled ref={ref} $isOpen={isOpen}>
        <SelectLabelStyled onClick={handleOpen}>
          {icon && <FontAwesomeIcon icon={icon} />}
          <span>
            {label ? <span data-label>{label}: </span> : ''}
            {currentValue || label}
          </span>
          <FontAwesomeIcon icon={faAngleDown} />
        </SelectLabelStyled>
      </SelectButtonStyled>

      <SelectOptionsStyled $isOpen={isOpen} $mode={styleMode} $position={openDirection}>
        {options.map((option) => (
          <SelectOptionStyled $isSelected={option === currentValue} onClick={(event: React.MouseEvent<HTMLElement>) => handleChange(event, option)} key={option}>
            {option}
          </SelectOptionStyled>
        ))}
      </SelectOptionsStyled>
    </SelectWrapperStyled>
  );
};
