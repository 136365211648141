import { ERemediationEffortLevels } from '@blindspot/common/types/bff/remediation';
import { axiosConfig as axios } from '@Utils';

export const fetchRemediationFixedIssues = async (repoId: string, depFileUuid: string, effortLevel: ERemediationEffortLevels, token: string) => {
  const response = await axios(`remediation/repository/${repoId}/depfile/${depFileUuid}/issues/fixes/${effortLevel}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
