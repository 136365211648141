import { useTheme } from 'styled-components';

import { useFilter } from 'core/Filter';
import { isEmpty } from '@Utils';
import { EIssuesCategoryLabel } from '@ComponentsTypes';
import { DashboardCardStyled } from '../styled';
import { useDependenciesByIssuesCategory } from '../hooks';
import { getDependenciesByCategoriesOptions } from '../config';
import { formatDependenciesByCategoriesSeries } from '../utils';
import { Chart } from './Chart';
import { useNavigate } from 'react-router-dom';

export const DependenciesByCategories = () => {
  const theme = useTheme();
  const defaultSeries = [
    {
      data: [
        { x: EIssuesCategoryLabel.CRITICAL, y: 0, fillColor: theme.colors.rose[600] },
        { x: EIssuesCategoryLabel.HIGH, y: 0, fillColor: theme.colors.orange[500] },
        { x: EIssuesCategoryLabel.MEDIUM, y: 0, fillColor: theme.colors.zinc[400] },
        { x: EIssuesCategoryLabel.LOW, y: 0, fillColor: theme.colors.zinc[200] },
      ],
    },
  ];
  const { appliedFilters } = useFilter();
  const { data, isLoading } = useDependenciesByIssuesCategory({ filter: appliedFilters });

  const series = !isEmpty(data) ? formatDependenciesByCategoriesSeries(data, theme) : defaultSeries;

  const navigate = useNavigate();

  const options = getDependenciesByCategoriesOptions({ theme, navigate });

  return (
    <DashboardCardStyled $paddingBottom={theme.spacing[0.5]}>
      <Chart isLoading={isLoading} options={options} series={series} type="bar" width={'100%'} height={'250px'} isInteractiveCategories />
    </DashboardCardStyled>
  );
};
