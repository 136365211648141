import styled from 'styled-components';

export const FormButton = styled.button<{ $variant?: 'text' | 'contained' }>`
  cursor: pointer;
  border-radius: 8px;
  border: none;
  padding: ${({ theme }) => `0 ${theme.spacing['1']}`};
  line-height: 18px;
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  letter-spacing: 0.46px;

  &:disabled {
    background: ${({ theme }) => theme.colors.zinc['200']};
    color: ${({ theme }) => theme.colors.zinc['400']};
    cursor: default;
    pointer-events: none;
  }

  ${({ $variant, theme }) => {
    if ($variant === 'text') {
      return `
        background: transparent;
        color: ${theme.colors.zinc['800']}
      `;
    }
    if ($variant === 'contained') {
      return `
        transition: background 500ms;
        padding: 0 20px;
        background: ${theme.colors.indigo['500']};
        color: ${theme.colors.white}
      `;
    }
  }}
`;
