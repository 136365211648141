import { axiosConfig as axios } from '@Utils';
import { IApiRemediationSummaryParams } from '../types';

export const fetchRemediationSummary = async ({ repoId, diff }: IApiRemediationSummaryParams, token: string) => {
  const response = await axios(`remediation/repository/${repoId}/summary`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      diff,
    },
  });
  return response?.data;
};
