import { axiosConfig as axios } from '@Utils';

export const validateIntegrationToken = async (provider: string, integrationToken: string, token: string) => {
  return axios.post(
    `scm/integrate/${provider}/token/validation`,
    {
      token: integrationToken,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
