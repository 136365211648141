import React from 'react';
import { ListSelectionSummaryButtonStyled, ListSelectionSummaryWrapperStyled, SelectableListItemStyled } from './styled';
import { IListSelectionSummaryProps } from './interfaces';

export const ListSelectionSummary: React.FC<IListSelectionSummaryProps> = ({ label, buttonLabel, buttonOnClick }) => {
  return (
    <SelectableListItemStyled>
      <div />
      <ListSelectionSummaryWrapperStyled>
        <span>{label}</span>
        <ListSelectionSummaryButtonStyled onClick={buttonOnClick}>{buttonLabel}</ListSelectionSummaryButtonStyled>
      </ListSelectionSummaryWrapperStyled>
    </SelectableListItemStyled>
  );
};
