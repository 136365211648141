import { EScmType } from '@blindspot/common/types/bff/scm';
import { IIntegrations } from '../../../Utils/integrations/types';
import { IntegrationStatusEnum } from '../types';

export const findIntegrationStatus = (integrations: IIntegrations[], provider: EScmType): IntegrationStatusEnum => {
  if (!integrations) {
    return IntegrationStatusEnum.NOT_INTEGRATED;
  }
  const integration = integrations.find((integration) => integration.type === provider);
  if (!integration) {
    return IntegrationStatusEnum.NOT_INTEGRATED;
  }

  switch (integration.status) {
    case 'ACTIVE':
      return IntegrationStatusEnum.ACTIVE;
    case 'FAILED':
      return IntegrationStatusEnum.FAILED;
    case 'LOADING':
      return IntegrationStatusEnum.LOADING;
    case 'PENDING':
      return IntegrationStatusEnum.PENDING;
    default:
      return IntegrationStatusEnum.NOT_INTEGRATED;
  }
};
