import React, { useState, useMemo } from 'react';
import { TabPages } from '@Components';
import { IssuesContainer } from '../styled';
import { IIssueDetailsAdditionalProps } from './interfaces';
import { EIssueSubType } from '@APITypes';
import { Outlet, useParams } from 'react-router-dom';
import { TabContent } from '@Styles';

export const IssueDetailsAdditional: React.FC<IIssueDetailsAdditionalProps> = ({ issueDetails, isDiff }) => {
  const { repoId, pullRequestUuid, branchUuid } = useParams();

  const baseUrl = isDiff ? `/discovery/repositories/${repoId}/pull-requests/${pullRequestUuid}/issues/${issueDetails.uuid}/branch/${branchUuid}` : `/issues/${issueDetails.uuid}`;

  const tabs = useMemo(() => {
    const tabsArray = isDiff
      ? [
          {
            title: 'Details',
            key: 'details',
            linkTo: `${baseUrl}/details`,
          },
        ]
      : [
          {
            title: 'Details',
            key: 'details',
            linkTo: `${baseUrl}/details`,
          },
          {
            title: 'Affected Repositories',
            key: 'affected-repositories',
            linkTo: `${baseUrl}/reachabilities`,
          },
        ];

    if (issueDetails.category === EIssueSubType.RISKY_CODE) {
      tabsArray.push({
        title: 'Risky Code Files',
        key: 'risky-code-files',
        linkTo: `${baseUrl}/risky-code-files`,
      });
    } else if (issueDetails.category === EIssueSubType.TAMPERING) {
      tabsArray.push({
        title: 'Code Injection',
        key: 'code-injection',
        linkTo: `${baseUrl}/code-injection`,
      });
    }

    return tabsArray;
  }, [baseUrl, isDiff, issueDetails.category]);

  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const onTabClick = (tab) => {
    setActiveTab(tab.key);
  };

  return (
    <div>
      <IssuesContainer>
        <TabPages activeTab={activeTab} onClick={onTabClick} items={tabs} />
      </IssuesContainer>
      <TabContent>
        <Outlet context={{ issueDetails }} />
      </TabContent>
    </div>
  );
};
