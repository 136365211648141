export enum Permission {
  /** ORGANIZATION */
  ORGANIZATION_READ = 'organization:read',
  ORGANIZATION_USERS_READ = 'organization:users:read',
  ORGANIZATION_USERS_UPDATE = 'organization:users:update',
  ORGANIZATION_CREATE = 'organizations:create',
  ORGANIZATION_UPDATE = 'organization:update',
  ORGANIZATION_DELETE = 'organization:delete',
  ORGANIZATION_CREATE_ROLE = 'organization:create_role',
  ORGANIZATION_INVITATIONS = 'organization:invitations',
  ORGANIZATION_TOKEN_READ = 'organization:token:read',
  ORGANIZATION_TOKEN_CREATE = 'organization:token:create',
  ORGANIZATION_TOKEN_DELETE = 'organization:token:delete',
  ORGANIZATION_CONNECTION_READ = 'organization:connections:read',
  ORGANIZATION_CONNECTION_CREATE = 'organization:connections:create',
  ORGANIZATION_REPORTS_CREATE = 'organization:reports:create',
  ORGANIZATION_REPORTS_READ = 'organization:reports:read',
  ORGANIZATION_INTEGRATIONS_TOKEN = 'organization:integrations_token',

  USER_ORGANIZATIONS_CHANGE = 'organizations:change',

  /** USER */
  USER_READ = 'user:read',
  USER_UPDATE = 'user:update',
  USER_DELETE = 'user:delete',

  /** SUMMERY */
  SUMMARY_READ = 'summary:read',

  /** REPOSITORIES */
  REPOSITORIES_READ = 'repositories:read',
  REPOSITORIES_MONITOR = 'repositories:monitor',
  REPOSITORIES_REMONITOR = 'repositories:remonitor',
  REPOSITORIES_COMMIT_STATUS_READ = 'repositories:commit_status:read',

  /** SCM */
  SCM_DISCOVERY = 'scm:discovery',
  SCM_READ = 'scm:read',
  SCM_DELETE = 'scm:delete',

  /** ISSUES */
  ISSUES_DEPENDENCIES_READ = 'issues:dependencies:read',
  ISSUES_FIRST_PARTY_UPDATE = 'issues:first-party:update',

  /** DEPENDENCIES */
  DEPENDENCIES_READ = 'dependencies:read',

  /** INTEGRATION */
  INTEGRATION_READ = 'integration:read',
  INTEGRATION_CREATE = 'integration:create',
  INTEGRATION_UPDATE = 'integration:update',

  /** REMEDIATION */
  REMEDIATION_READ = 'remediation:read',
  REMEDIATION_UPDATE = 'remediation:update',

  /** CONFIGS*/
  CONFIGS_SCAN_READ = 'configs:scan:read',
  CONFIGS_SCAN_CREATE = 'configs:scan:create',
  CONFIGS_SCAN_UPDATE = 'configs:scan:update',
  CONFIGS_SCAN_DELETE = 'configs:scan:delete',

  /**AUTH */
  MAGIC_LINK_CREATE = 'magic_link:create',

  /** WEBHOOK */
  WEBHOOK_EVENT_CREATE = 'webhook:event:create',
}
