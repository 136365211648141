import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const CreateSbomDialogCancelButtonStyled = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacing[1]} ${theme.spacing[2]};
    color: ${theme.colors.white};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.bold};
    letter-spacing: 0.4px;
    line-height: 22.4px;
  `,
);
