import styled from 'styled-components';

import { ContentBlock } from './ContentBlock';

export const TabContent = styled(ContentBlock)`
  background-color: ${({ theme }) => theme.colors.white};
  padding-left: ${({ theme }) => theme.spacing[3]};
  padding-right: ${({ theme }) => theme.spacing[3]};
  position: relative;
`;
