import styled, { css } from 'styled-components';
import { NumberChip } from './NumberChip.styled';

export const TabWrapper = styled.span<{ $active?: boolean; $disabled?: boolean }>`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.body2};
  letter-spacing: 0.4px;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[1.5]}`};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.zinc[500]};
  border-bottom: 2px solid transparent;
  border-radius: ${({ theme }) => theme.spacing[0.5]} ${({ theme }) => theme.spacing[0.5]} 0 0;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.zinc[300]};
  }

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.indigo[500]};
      border-bottom: 2px solid ${({ theme }) => theme.colors.indigo[500]};
      position: relative;

      &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.colors.indigo[500]};
      }

      ${NumberChip} {
        color: ${({ theme }) => theme.colors.indigo[500]};
        background: ${({ theme }) => theme.colors.indigo[100]};
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
      pointer-events: none;

      &:after {
        display: none;
      }
    `}
`;
