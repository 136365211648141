import { useQuery } from 'react-query';
import { fetchPullRequestsIssues } from '../api';
import { IApiPullRequestsIssuesParams } from '../types';
import { IIssueDiff } from '@blindspot/common/types/bff/api';
import { IPage } from '@APITypes';
import { useSession } from '@descope/react-sdk';

export const usePullRequestsIssues = (params: IApiPullRequestsIssuesParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['pullRequestsIssues', params],
    queryFn: async () => {
      return await fetchPullRequestsIssues(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IPage<IIssueDiff>>(queryOptions);
};
