import React from 'react';
import { ITabsProps } from './interfaces';
import { TabLink } from './styled';
import { Tab } from './components';
import { Flex } from '@Styles';

export const TabPages: React.FC<ITabsProps> = ({ items, onClick, activeTab, isOldTab }) => {
  const activeKey = activeTab;

  return (
    <Flex>
      {items.map((item) => {
        if (item.linkTo) {
          return (
            <TabLink key={item.key} to={item.linkTo}>
              {({ isActive }) => <Tab item={item} onClick={onClick} isActive={item.forceActive ?? isActive} isOldTab={isOldTab} />}
            </TabLink>
          );
        }

        return <Tab key={item.key} item={item} onClick={onClick} isActive={activeKey === item.key} isOldTab={isOldTab} />;
      })}
    </Flex>
  );
};
