import { Flex } from '@Styles';
import styled from 'styled-components';

export const DrawerHeaderContainerStyled = styled(Flex)`
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  filter: drop-shadow(0px 2px 12px rgba(10, 24, 154, 0.05));
  box-shadow: ${({ theme }) => theme.shadows[1]};

  padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[4]} ${theme.spacing[4]} ${theme.spacing[4]}`};
`;
