import React from 'react';
import { Metric, MetricTooltip } from './styled';
import { Tooltip } from '@Components';
import { ISecurityScoreMetricProps } from './interfaces';

export const MetricBlock: React.FC<ISecurityScoreMetricProps> = ({ metric }) => {
  return (
    <Metric key={metric.code}>
      <Tooltip placement="bottom" noWrap={false} tooltip={<MetricTooltip>{metric.metricDescription}</MetricTooltip>}>
        <span>{metric.name}</span>
      </Tooltip>
      <Tooltip placement="bottom" noWrap={false} tooltip={<MetricTooltip>{metric.codeDescription}</MetricTooltip>}>
        <span>{metric.value}</span>
      </Tooltip>
    </Metric>
  );
};
