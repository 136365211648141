import styled from 'styled-components';

export const FilterListStyled = styled.ul`
  list-style-type: none;
  margin-bottom: ${({ theme }) => theme.spacing['3']};

  li:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing['3']};
  }
`;
