import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const RemediationEffortSpoilerIconStyled = styled(FontAwesomeIcon)<{ $isOpen: boolean }>`
  color: ${({ theme }) => theme.colors.black};
  width: 20px;
  transition: transform 0.3s ease;

  ${({ $isOpen: isOpen }) =>
    isOpen &&
    `
  transform: rotate(90deg);
  `}
`;
