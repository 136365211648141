import { useMutation } from 'react-query';
import { removeOrganizationToken } from '../api';
import { useSession } from '@descope/react-sdk';

export const useOrganizationTokenRemove = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async (tokenId: string) => {
    if (isAuthenticated && !isSessionLoading) {
      return removeOrganizationToken(tokenId, sessionToken);
    }
  });
};
