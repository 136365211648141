import { Flex } from '@Styles';
import { TooltipContainer } from 'Layouts/components/Tooltip/styled';
import styled from 'styled-components';

export const RemediationIssueLabelsContainerStyled = styled(Flex)`
  margin-top: ${({ theme }) => theme.spacing[1]};

  ${TooltipContainer}:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing[1.5]};
  }

  ${TooltipContainer}:last-child {
    margin-right: 0;
  }
  float: right;
`;
