import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PaginationState } from '@tanstack/react-table';
import { IRepositoriesListProps, IRepositoryListCardProps } from './interfaces';
import { TableNotifications } from 'core/Table';
import { useParams } from 'react-router-dom';
import { IRepository } from '@APITypes';
import { isEmpty, useDepTreeDiscoverFinish, useNewRepositoryDiscovered, usePagination, usePostProcessing, useProtectFinish, useProtectStart, useQueryParams, useSearch } from '@Utils';
import { ENotificationType, toastService } from '@Notification';
import { TableNoData } from 'core/Table/styled';
import { initialPaginatedData, initialListPagination } from 'core/CardList/config';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faFolder } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRepositories } from './hooks';
import { useRepositoryMonitor } from 'Layouts/DiscoveryPage/hooks';
import { useQueryClient } from 'react-query';
import { CardList } from 'core/CardList';
import { RepositoryCard } from './RepositoryCard';
import { useFilter } from 'core/Filter';
import { repositoryFilters } from './config/repositoryFilters.const';
import { useRepositoriesSummary } from '@Hooks';
import { ActionsDropdown } from 'core/Dropdown';
import { RediscoverButton } from './RepositoryActions/actions/rediscover';
import { ExportButton } from './RepositoryActions/actions/export';
import { MonitorAllButton } from './RepositoryActions/actions/monitorAll';
import { CreateSbomButton } from './RepositoryActions/actions/sbom';
import { ItemSelectionProvider, useItemSelection } from './contexts';
import { RepositoriesSelectionSummary } from './RepositoriesSelectionSummary';
import { ICardListProps } from 'core/CardList/types';
import { useUserOrganization } from '@Hooks';
import { IntegrationStatusEnum } from 'Layouts/IntegrationsPage/types';

export const RepositoriesListBase: React.FC<IRepositoriesListProps> = ({ showSearch = true }) => {
  const { depId } = useParams();
  const queryClient = useQueryClient();
  const { data: repositorySummary } = useRepositoriesSummary({ filter: {} });

  const { queryParams } = useQueryParams({});
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: queryParams.page - 1 || initialListPagination.pageIndex,
    pageSize: queryParams.size || initialListPagination.pageSize,
  });
  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
  const [searchValue, setSearchValue] = useState(queryParams.search);
  const itemSelection = useItemSelection();
  const { appliedFilters, onFiltersApplied } = useFilter();

  const { data: repositories = initialPaginatedData, isLoading } = useRepositories({
    pagination,
    search: searchValue,
    depId,
    filters: {
      ...appliedFilters,
    },
  });
  const [newRepositoriesCount, setNewRepositoriesCount] = useState(0);
  const { mutate: repositoryMonitor } = useRepositoryMonitor();
  const { data: userOrg } = useUserOrganization();
  const invalidateRepositories = () =>
    queryClient.invalidateQueries({ queryKey: ['repositories', { ...(depId && { depId }), ...(!isEmpty(appliedFilters) && { filters: appliedFilters }) }] });

  useProtectFinish(invalidateRepositories);
  useProtectStart(invalidateRepositories);
  useDepTreeDiscoverFinish(invalidateRepositories);
  usePostProcessing(invalidateRepositories);
  useNewRepositoryDiscovered(() => {
    setNewRepositoriesCount(newRepositoriesCount + 1);
  });
  usePagination(pageIndex, pageSize);
  useSearch(searchValue, { pageSize, setPagination });
  onFiltersApplied(() => {
    setPagination({ pageSize, pageIndex: 0 });
  });

  useEffect(() => {
    itemSelection.setTotalItemsCount(repositories.meta.itemCount);
  }, [repositories.meta.itemCount]);

  const onMonitor = (repository: Record<any, any>, branch?: string, scanConfigSetUuid?: string) => {
    repositoryMonitor({
      scmOriginId: repository.scmOriginId,
      scmOriginType: repository.scmOriginType,
      branch,
      scanConfigSetUuid,
    });
    toastService({
      header: `${repository.name} is now monitored`,
      message: `We will start scanning the repository shortly`,
      type: ENotificationType.SUCCESS,
    });
  };

  const onDiscoveryClose = () => {
    setNewRepositoriesCount(0);
  };

  const onDiscoveryClicked = () => {
    invalidateRepositories();
    onDiscoveryClose();
  };

  const getCardLink = (repository: Pick<IRepository, 'uuid'>) => `/discovery/repositories/${repository.uuid}/issues/all`;

  const calculateShouldDialogRender = useCallback(() => !repositorySummary?.monitored && !repositorySummary?.scanning, [repositorySummary?.monitored, repositorySummary?.scanning]);
  const [dialogRenderId, setDialogRenderId] = useState(calculateShouldDialogRender() && repositories.data?.[0]?.uuid);

  useEffect(() => {
    setDialogRenderId(calculateShouldDialogRender() && repositories.data?.[0]?.uuid);
  }, [calculateShouldDialogRender, repositories.data]);

  const emptyStateComponent = (
    <TableNoData>
      <FontAwesomeIcon icon={faCircleCheck} />
      <p>No repositories</p>
    </TableNoData>
  );

  const notifications = (
    <TableNotifications
      icon={faFolder}
      message={`${newRepositoriesCount} new ${newRepositoriesCount > 1 ? 'repositories' : 'repository'} discovered`}
      onClick={onDiscoveryClicked}
      onClose={onDiscoveryClose}
    />
  );

  const listActions = (
    <ActionsDropdown
      menuItems={[
        <CreateSbomButton key={`create-sbom-${depId}`} />,
        <ExportButton depId={depId} key={`export-${depId}`} />,
        <RediscoverButton key={`rediscover-${depId}`} />,
        <MonitorAllButton key={`monitor-all`} />,
      ]}
    />
  );

  const repositoryFiltersWithMaxDepsRange = repositoryFilters.map((filter) => {
    if (filter.id === 'dependencyCountRange') {
      filter.max = repositorySummary?.maxDepsInRepo;
      return filter;
    }
    return filter;
  });

  const selectionSummary = itemSelection.selectionEnabled && <RepositoriesSelectionSummary />;
  const currentScm = userOrg?.scm?.find((scmOrg) => scmOrg.status === IntegrationStatusEnum.ACTIVE).name;
  const options: ICardListProps['listFeatures'] = {
    pagination: {
      value: pagination,
      onChange: setPagination,
      pageCount: repositories.meta.pageCount,
      itemCount: repositories.meta.itemCount,
    },
    ...(showSearch && {
      search: {
        value: searchValue,
        onChange: (value: string) => setSearchValue(value),
        ...(!!currentScm && { prefix: currentScm }),
      },
    }),
    ...(!!newRepositoriesCount && {
      notifications,
    }),
    ...(repositories.meta.itemCount && {
      actions: listActions,
    }),
    filters: repositoryFiltersWithMaxDepsRange,
    ...(itemSelection.selectionEnabled && {
      cardSelection: {
        onChange: itemSelection.toggleItemSelection,
        summary: selectionSummary,
        allSelected: itemSelection.isAllItemsSelected,
        included: itemSelection.includedItems,
        excluded: itemSelection.excludedItems,
      },
    }),
  };

  const RepositoryListCard = (props: IRepositoryListCardProps) => {
    const { getCardLink, data, key } = props;
    return <RepositoryCard onMonitor={onMonitor} dialogRenderId={dialogRenderId} getCardLink={getCardLink} repository={data} key={key} />;
  };
  return <CardList cardComponent={RepositoryListCard} data={repositories.data} listFeatures={options} emptyState={emptyStateComponent} isLoading={isLoading} getCardLink={getCardLink} />;
};

export const RepositoriesList: React.FC<IRepositoriesListProps> = (props) => {
  return (
    <ItemSelectionProvider>
      <RepositoriesListBase {...props} />
    </ItemSelectionProvider>
  );
};
