import React from 'react';
import { FilterDrawer } from './components';
import { FilterButtonCountStyled, FilterButtonStyled } from './styled';
import { useDrawer } from '@Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { IFilterProps } from './types';
import { useFilter } from './context';

export const FilterButton: React.FC<IFilterProps> = ({ filters }) => {
  const { openDrawer } = useDrawer();
  const { appliedFiltersCount } = useFilter();

  const onViewFilters = () => {
    openDrawer(<FilterDrawer filters={filters} />);
  };

  return (
    <FilterButtonStyled onClick={onViewFilters}>
      <FontAwesomeIcon icon={faBarsFilter} />
      <span>Filters</span>
      {appliedFiltersCount ? <FilterButtonCountStyled>{appliedFiltersCount}</FilterButtonCountStyled> : null}
    </FilterButtonStyled>
  );
};
