import { axiosConfig as axios } from '@Utils';
import { IApiOrganizationUsersParams } from '../interfaces';

export const fetchOrganizationUsers = async (params: IApiOrganizationUsersParams, token: string) => {
  const response = await axios('org/users', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: params?.pagination?.pageIndex + 1,
      take: params?.pagination?.pageSize,
      search: params?.search,
    },
  });
  return response?.data;
};
