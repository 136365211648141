import styled, { css } from 'styled-components';

export const DropdownActionContentStyled = styled.div(
  ({ theme }) => css`
    & svg {
      height: ${theme.spacing[2]};
      width: ${theme.spacing[2]};
      color: ${theme.colors.zinc[800]};
    }
    & button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ${theme.spacing[2]};
      font-size: ${theme.text.size.body1};
      letter-spacing: 0.15px;
      line-height: 28px;
      color: ${theme.colors.zinc[800]};
      border-radius: 8px;
      padding: ${theme.spacing[0.5]} ${theme.spacing[1.5]};
      height: 52px;
      width: 100%;
      border: none;
      &:hover {
        background-color: ${theme.colors.zinc[100]};
      }
    }
  `,
);
