import { Shape1Styled, Shape2Styled, Shape3Styled, Shape4Styled } from './styled';

export const LoginBackgroundShapes = () => {
  return (
    <>
      <Shape1Styled />
      <Shape2Styled />
      <Shape3Styled />
      <Shape4Styled />
    </>
  );
};
