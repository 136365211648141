import { RegisterOptions } from 'react-hook-form';
import { ICreateOrganizationTokenFormData } from './interfaces';
import { tokenNameMaxLength, tokenNameMinLength } from './constants';

export const createOrganizationTokenValidationRules: Record<keyof ICreateOrganizationTokenFormData, RegisterOptions> = {
  name: {
    required: 'Please fill in name for token',
    minLength: { value: tokenNameMinLength, message: `Name should have at least ${tokenNameMinLength} symbols.` },
    maxLength: { value: tokenNameMaxLength, message: 'Name exceeds the maximum character limit.' },
    validate(value: string) {
      const tokenName = value.trim();
      if (!tokenName || tokenName.length < tokenNameMinLength || tokenName.length > tokenNameMaxLength) {
        return 'Invalid name';
      }
      return true;
    },
  },
};
