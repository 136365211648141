import { axiosConfig as axios } from '@Utils';

export const fetchSastIssueMetadata = async (token: string, fingerprint: string) => {
  const response = await axios.get(`/issues/first-party/ignore/${fingerprint}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
