import { useContext, useEffect } from 'react';
import { SocketContext } from '@Utils';
import { useUserOrganization } from '@Hooks';
import Pusher from 'pusher-js';

const subscribe = (pusher: Pusher, channelName: string) => {
  const channel = pusher.channel(channelName);
  if (!channel) {
    pusher.subscribe(channelName);
  }
};

export const useSubscribe = (subChannel?: string) => {
  const { pusher } = useContext(SocketContext);
  const { data: userOrganization, isLoading } = useUserOrganization();

  useEffect(() => {
    if (pusher?.connection?.state !== 'connected' || isLoading) {
      return;
    }
    const baseChannelName = `presence-${userOrganization.org}`;
    subscribe(pusher, baseChannelName);

    if (subChannel) {
      const subChannelName = baseChannelName.concat(`-${subChannel}`);
      subscribe(pusher, subChannelName);
    }
    return () => {
      if (subChannel) {
        const subChannelName = baseChannelName.concat(`-${subChannel}`);
        pusher.unsubscribe(subChannelName);
      }
    };
  }, [pusher?.connection?.state, isLoading]);
};
