import styled from 'styled-components';

export const LinkBlankStyled = styled.a`
  display: flex;
  text-transform: none;
  text-decoration: none;
  color: inherit !important;
  align-items: center;
  line-height: 11px;
`;
