import styled from 'styled-components';

export const ReachabilityIssueInfoItemStyled = styled.span<{ $capitalize?: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.text.size.body2};
  color: ${({ theme }) => theme.colors.zinc['500']};
  line-height: 24px;
  ${({ $capitalize }) => $capitalize && 'text-transform: capitalize;'}

  &:not(:last-child):after {
    content: '';
    display: block;
    margin: 0 ${({ theme }) => theme.spacing['1.5']};
    width: 3px;
    height: 3px;
    background: ${({ theme }) => theme.colors.zinc['500']};
    border-radius: 50%;
  }
`;
