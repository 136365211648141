import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { MonitoredStyled } from '../styled/Monitored.styled';
import { GrayTextStyled } from '../styled/GrayText.styled';

export const Monitored = () => {
  return (
    <GrayTextStyled>
      <FontAwesomeIcon icon={faCheck} />
      <MonitoredStyled>Monitored</MonitoredStyled>
    </GrayTextStyled>
  );
};
