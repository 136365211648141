import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IssueDrawerActionButton } from '.';
import { faClose, faShield, faShieldSlash, faTicketSimple } from '@fortawesome/pro-regular-svg-icons';
import { DrawerCloseIconContainerStyled, IssueDrawerActionButtonsContainerStyled } from './styled';
import { Tooltip } from '@Components';
import { IIssueDrawerActionButtonsProps } from './types';
import { useSearchParams } from 'react-router-dom';

export const IssueDrawerActionButtons = ({ onIgnoreIssue, onOpenTicket, ignored, isMutating }: IIssueDrawerActionButtonsProps) => {
  const [searchParams, setUrlSearchParams] = useSearchParams();

  const handleCloseDrawer = () => {
    setUrlSearchParams((prev) => {
      prev.delete('openIssue');
      return prev.toString();
    });
  };

  return (
    <IssueDrawerActionButtonsContainerStyled>
      {/* <Tooltip tooltip="Open Ticket" placement="bottom">
        <IssueDrawerActionButton icon={faTicketSimple} onClick={onOpenTicket} />
      </Tooltip> */}
      <Tooltip tooltip={ignored ? 'Unignore Issue' : 'Ignore Issue'} placement="bottom">
        <IssueDrawerActionButton icon={ignored ? faShield : faShieldSlash} onClick={onIgnoreIssue} disable={isMutating} />
      </Tooltip>
      <DrawerCloseIconContainerStyled onClick={handleCloseDrawer}>
        <FontAwesomeIcon icon={faClose} />
      </DrawerCloseIconContainerStyled>
    </IssueDrawerActionButtonsContainerStyled>
  );
};
