import styled, { css } from 'styled-components';

export const ProgressBarEventsCount = styled.span(
  ({ theme }) => css`
    border-radius: 100px;
    padding: 2px ${theme.spacing[1]};
    margin-left: ${theme.spacing[1]};
    background: ${theme.colors.indigo[500]};
    color: ${theme.colors.white};
    font-size: ${theme.text.size.caption};
    font-weight: ${theme.text.weight.regular};
    letter-spacing: 0.15px;
    line-height: 16px;
  `,
);
