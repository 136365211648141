import { ESeverityLevel } from '@APITypes';
import { EIssuesCategoryLabel } from '@ComponentsTypes';

export const sastIssueFilters = [
  {
    id: 'severity',
    label: 'Severity',
    options: [
      {
        label: EIssuesCategoryLabel.CRITICAL,
        value: ESeverityLevel.CRITICAL,
        count: 0,
      },
      {
        label: EIssuesCategoryLabel.HIGH,
        value: ESeverityLevel.HIGH,
        count: 0,
      },
      {
        label: EIssuesCategoryLabel.MEDIUM,
        value: ESeverityLevel.MEDIUM,
        count: 0,
      },
      {
        label: EIssuesCategoryLabel.LOW,
        value: ESeverityLevel.LOW,
        count: 0,
      },
    ],
  },
  {
    id: 'count',
    label: 'Count',
    min: 1,
    max: 100,
  },
];
