export enum InfoLabelTypes {
  Default = 'default',
  Info = 'info',
  Scanning = 'scanning',
  Low = 'low',
  Bordered = 'bordered',
  Success = 'success',
  Fixed = 'fixed',
  Error = 'error',
  Monitored = 'monitored',
  Unmonitored = 'unmonitored',
  Warning = 'warning',
  Medium = 'medium',
  High = 'high',
  Critical = 'critical',
}
