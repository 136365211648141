import { useQuery } from 'react-query';
import { IPage, IRemediation } from '@APITypes';
import { fetchRepositoryRemediation } from '../api';
import { IApiRemediationParams } from '../types';
import { useSession } from '@descope/react-sdk';

export const useRepositoryRemediation = (params: IApiRemediationParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['repoRemediation', params],
    queryFn: async () => {
      return await fetchRepositoryRemediation(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IPage<IRemediation>>(queryOptions);
};
