import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IDrawerNavigator } from './types';
import { NavigatorContainerStyled, NavigatorIconStyled, NavigatorTextStyled } from './styled';

export const DrawerNavigator = ({ currentIndex, total, onChangeDirection }: IDrawerNavigator) => {
  const isPrevDependencyDisabled = currentIndex === 0;
  const isNextDependencyDisabled = currentIndex === total - 1;

  return (
    <NavigatorContainerStyled>
      <NavigatorIconStyled icon={faChevronLeft} onClick={() => onChangeDirection('prev')} $disabled={isPrevDependencyDisabled} />
      <NavigatorTextStyled>
        {currentIndex + 1} / {total}
      </NavigatorTextStyled>
      <NavigatorIconStyled icon={faChevronRight} onClick={() => onChangeDirection('next')} $disabled={isNextDependencyDisabled} />
    </NavigatorContainerStyled>
  );
};
