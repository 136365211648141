import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { faCodeCompare, faFolder } from '@fortawesome/pro-regular-svg-icons';
import { Outlet } from 'react-router-dom';

import { useRepositoriesSummary, useDependenciesSummary } from '@Hooks';
import { TabContent } from '@Styles';
import { TabPages } from '@Components';
import { useDepTreeDiscoverFinish } from '@Utils';
import { useFilter } from 'core/Filter';
import { DiscoveryContentStyled } from './SubComponents/styled';
import { StartMonitoring } from './StartMonitoring';
import { Summaries } from './Summaries';

export const DiscoveryContent: React.FC = () => {
  const queryClient = useQueryClient();
  const { appliedFilters, onFiltersApplied } = useFilter();
  const { data: repositoriesSummary } = useRepositoriesSummary({ filter: appliedFilters });
  const { data: dependenciesSummary } = useDependenciesSummary({ filter: appliedFilters });

  const invalidateSummary = () => {
    queryClient.invalidateQueries({ queryKey: ['summary'] });
  };

  onFiltersApplied(() => {
    invalidateSummary();
  });

  useDepTreeDiscoverFinish(invalidateSummary);

  const tabItems = useMemo(() => {
    return [
      {
        title: 'Repositories',
        key: 'repositories',
        linkTo: '/discovery/repositories',
        icon: faFolder,
        number: repositoriesSummary?.total,
      },
      {
        title: 'Dependencies',
        key: 'dependencies',
        linkTo: '/discovery/dependencies',
        icon: faCodeCompare,
        number: dependenciesSummary?.total,
      },
    ];
  }, [dependenciesSummary?.total, repositoriesSummary?.total]);

  const shouldRenderEmptyState = !repositoriesSummary?.monitored && !repositoriesSummary?.scanning;

  return (
    <DiscoveryContentStyled>
      {shouldRenderEmptyState ? <StartMonitoring /> : <Summaries />}
      <div>
        <TabPages items={tabItems} />
        <TabContent>
          <Outlet />
        </TabContent>
      </div>
    </DiscoveryContentStyled>
  );
};
