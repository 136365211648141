import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const CreateTicketIconButtonStyled = styled(Button)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${theme.colors.transparent};
    border: 1px solid ${theme.colors.zinc[200]};
    width: ${({ theme }) => theme.spacing[3]};
    height: ${({ theme }) => theme.spacing[3]};
    svg {
      width: ${({ theme }) => theme.spacing[1]};
      height: ${({ theme }) => theme.spacing[1]};
    }
  `,
);
