import { useQuery } from 'react-query';
import { fetchIssueDetails } from '../api';
import { IIssueDetails } from '@APITypes';
import { IApiIssuesDetailsParams } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useIssueDetails = (params: IApiIssuesDetailsParams, enabled = true) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  return useQuery<IIssueDetails>({
    queryKey: ['issueDetails', params],
    queryFn: async () => {
      return await fetchIssueDetails(params, sessionToken);
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading && enabled,
  });
};
