import { EReachabilityStatus, ESeverityLevel, EIssueSubType, ERelationship } from '@APITypes';
import { supportedLanguageFilterOptions } from 'core/Filter/types';
import { EExploitationsAvailability } from './exploitationsAvailability.enum';
import { EIssuesCategoryLabel } from '@ComponentsTypes';

export const issueFilters = [
  {
    id: 'severity',
    label: 'Severity',
    options: [
      {
        label: EIssuesCategoryLabel.CRITICAL,
        value: ESeverityLevel.CRITICAL,
        count: 0,
      },
      {
        label: EIssuesCategoryLabel.HIGH,
        value: ESeverityLevel.HIGH,
        count: 0,
      },
      {
        label: EIssuesCategoryLabel.MEDIUM,
        value: ESeverityLevel.MEDIUM,
        count: 0,
      },
      {
        label: EIssuesCategoryLabel.LOW,
        value: ESeverityLevel.LOW,
        count: 0,
      },
    ],
  },
  {
    id: 'reachability',
    label: 'Reachability',
    options: [
      {
        label: 'Reachable',
        value: EReachabilityStatus.REACHABLE,
        count: 0,
      },
      {
        label: 'Unreachable',
        value: EReachabilityStatus.UNREACHABLE,
        count: 0,
      },
      {
        label: 'Undetermined',
        value: EReachabilityStatus.UNDEFINED,
        count: 0,
      },
    ],
  },
  {
    id: 'relationship',
    label: 'Relationship',
    options: [
      {
        label: 'Direct',
        value: ERelationship.DIRECT,
        count: 0,
      },
      {
        label: 'Indirect',
        value: ERelationship.INDIRECT,
        count: 0,
      },
      {
        label: 'Both',
        value: ERelationship.BOTH,
        count: 0,
      },
    ],
  },
  {
    id: 'category',
    label: 'Category',
    options: [
      {
        label: 'Tampering',
        value: EIssueSubType.TAMPERING,
        count: 0,
      },
      {
        label: 'Vulnerability',
        value: EIssueSubType.VULNERABILITY,
        count: 0,
      },
      {
        label: 'Risky code',
        value: EIssueSubType.RISKY_CODE,
        count: 0,
      },
      {
        label: 'Malicious Package',
        value: EIssueSubType.MALICIOUS_PACKAGE,
        count: 0,
      },
    ],
  },
  {
    id: 'languages',
    label: 'Languages',
    options: supportedLanguageFilterOptions,
  },
  {
    id: 'exploitationsAvailability',
    label: 'Exploitations Availability',
    options: [
      {
        label: 'True',
        value: EExploitationsAvailability.True,
        count: 0,
      },
      {
        label: 'False',
        value: EExploitationsAvailability.False,
        count: 0,
      },
      {
        label: 'Not Available',
        value: EExploitationsAvailability.NotAvailable,
        count: 0,
      },
    ],
  },
  {
    id: 'kev',
    label: 'KEV',
    options: [
      {
        label: 'True',
        value: EExploitationsAvailability.True,
        count: 0,
      },
      {
        label: 'False',
        value: EExploitationsAvailability.False,
        count: 0,
      },
      {
        label: 'Not Available',
        value: EExploitationsAvailability.NotAvailable,
        count: 0,
      },
    ],
  },
  {
    id: 'epss',
    label: 'EPSS',
    min: 0,
    max: 100,
  },
];
