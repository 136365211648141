export const text = {
  size: {
    h1: '34px' as const,
    h2: '24px' as const,
    h3: '18px' as const,
    subtitle1: '16px' as const,
    subtitle2: '14px' as const,
    body1: '16px' as const,
    body2: '14px' as const,
    caption: '12px' as const,
    overline: '10px' as const,
  },
  weight: {
    extraBold: '900' as const,
    heavy: '800' as const,
    bold: '700' as const,
    semiBold: '600' as const,
    medium: '500' as const,
    regular: '400' as const,
    light: '300' as const,
    ultraLight: '200' as const,
    extraLight: '100' as const,
  },
  fontFamily: 'Manrope' as const,
  fontFamilySecondary: 'Roboto Mono' as const,
};
