import { axiosConfig as axios } from '@Utils';
import { IApiIssuesSummaryParams } from '../interfaces';

export const fetchIssuesSummary = async (token: string, params?: IApiIssuesSummaryParams) => {
  const { repoId, depId, diff, issueSourceType } = params || {};

  const requestOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      filter: {
        issueSourceType,
        repositoryUuids: repoId,
        depsUuids: [depId],
      },
      diff,
    },
  };
  const response = await axios('issues/summary/groups', requestOptions);
  return response?.data;
};
