import { Flex } from '@Styles';
import styled from 'styled-components';

export const OrganizationSelectArrow = styled(Flex)`
  color: ${({ theme }) => theme.colors.zinc['50']};
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  cursor: pointer;
`;
