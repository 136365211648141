import { useDescope, useSession } from '@descope/react-sdk';
import { useMemo } from 'react';
import { getDctFromToken } from '../utils';

export const useCanAccess = (requiredPermissions: string | string[]): boolean => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const { getJwtPermissions } = useDescope();
  return useMemo(() => {
    if (!isAuthenticated || isSessionLoading) {
      return;
    }
    const tenant = getDctFromToken(sessionToken);
    const permissions = getJwtPermissions(sessionToken, tenant);

    if (!permissions) {
      return false;
    } else if (typeof requiredPermissions === 'string') {
      return permissions.includes(requiredPermissions);
    } else {
      return requiredPermissions.every((requiredPermission) => permissions.includes(requiredPermission));
    }
  }, [sessionToken]);
};
