import styled, { css } from 'styled-components';

export const ProgressBarItemSecondaryText = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.zinc[700]};
    font-size: ${theme.text.size.caption};
    font-weight: ${theme.text.weight.regular};
    line-height: 19px;
    letter-spacing: 0.2px;
    word-break: break-all;
  `,
);
