import { ScanCoverageText } from 'Layouts/DiscoveryPage/components/SubComponents/MonitoringPreferences/components';
import { SectionTitleStyled } from '../../styled';
import { ScanConfigSetCoverageStyled } from './styled';

export const ScanConfigSetCoverage = () => {
  return (
    <ScanConfigSetCoverageStyled>
      <SectionTitleStyled>Coverage and Frequency</SectionTitleStyled>
      <ScanCoverageText />
    </ScanConfigSetCoverageStyled>
  );
};
