import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { initialPaginatedData, initialTablePagination } from 'core/Table/config';
import { useRepositories } from 'Layouts/DiscoveryPage/components/RepositoriesList/hooks';
import { EMonitoringStatus, IRepository } from '@APITypes';
import { IProgressBarEventData, isEmpty, useProgressBar, useProtectFinish, useProtectStart } from '@Utils';
import { DiscoveryItem, RepositoryItem, DiscoveryProgressBarHeader } from './components';
import { IDiscoveryProgressBar, IRepositoriesProgressBar } from './interfaces';
import { ProgressBarContainer, ProgressBarList } from './styled';
import { clearProgressBarItemTimeout } from './constants';
import { useOrganizationDiscoveryProgressBar } from './hooks';

const timeoutIds = [];

export const DiscoveryProgressBar: React.FC = () => {
  const queryClient = useQueryClient();
  const [isExpanded, setIsExpanded] = useState(false);
  const [unreadEventsCount, setUnreadEventsCount] = useState(0);
  const [discoveryProgressBar, setDiscoveryProgressBar] = useState<IDiscoveryProgressBar>();
  const [scanningRepositoriesProgressBar, setScanningRepositoriesProgressBar] = useState<IRepositoriesProgressBar>({});
  const { data: repositories = initialPaginatedData } = useRepositories({
    pagination: initialTablePagination,
    filters: {
      'filter[monitoringStatus]': EMonitoringStatus.SCANNING,
    },
  });
  const { data: orgDiscoveryProgressBar = [] } = useOrganizationDiscoveryProgressBar();

  const invalidateRepositories = () => {
    queryClient.invalidateQueries({ queryKey: ['repositories', { filters: { 'filter[monitoringStatus]': EMonitoringStatus.SCANNING } }] });
  };

  const updateRepositoriesProgressBar = (repositories: IRepository[]) => {
    setScanningRepositoriesProgressBar((prevState) => {
      const newState = { ...prevState };
      for (const repository of repositories) {
        newState[repository.uuid] = {
          uuid: repository.uuid,
          name: repository.name,
          owner: repository.owner,
        };
      }
      return newState;
    });
  };

  useEffect(() => {
    updateRepositoriesProgressBar(repositories.data);
  }, [repositories.data]);

  useEffect(() => {
    if (orgDiscoveryProgressBar[0]) {
      setDiscoveryProgressBar({
        total: orgDiscoveryProgressBar[0].total,
        seen: orgDiscoveryProgressBar[0].seen,
      });
    }
  }, [orgDiscoveryProgressBar]);

  useEffect(() => {
    return () => {
      timeoutIds.forEach(clearTimeout);
    };
  }, []);

  useEffect(() => {
    if (isEmpty(discoveryProgressBar) && isEmpty(scanningRepositoriesProgressBar)) {
      setUnreadEventsCount(0);
      setIsExpanded(false);
    }
  }, [discoveryProgressBar, scanningRepositoriesProgressBar]);

  const scheduleClearScanningRepository = (repoUuid: string) => {
    const timeoutId = setTimeout(() => {
      setScanningRepositoriesProgressBar((prevState) => {
        const newState = { ...prevState };
        delete newState[repoUuid];
        return newState;
      });
    }, clearProgressBarItemTimeout);
    timeoutIds.push(timeoutId);
  };

  const scheduleClearDiscovery = () => {
    const timeoutId = setTimeout(() => {
      setDiscoveryProgressBar(undefined);
    }, clearProgressBarItemTimeout);
    timeoutIds.push(timeoutId);
  };

  const markRepositoryScanningComplete = (repoUuid: string) => {
    setScanningRepositoriesProgressBar((prevState) => {
      const repository = prevState[repoUuid];
      if (!repository || repository?.scanningComplete) {
        return prevState;
      }

      scheduleClearScanningRepository(repoUuid);
      const newState = { ...prevState };
      newState[repoUuid].scanningComplete = true;
      return newState;
    });
  };

  useProtectFinish(({ repoUuid }) => {
    markRepositoryScanningComplete(repoUuid);
  });

  useProtectStart(({ repoUuid }) => {
    if (!scanningRepositoriesProgressBar[repoUuid]) {
      setUnreadEventsCount(unreadEventsCount + 1);
    }
    invalidateRepositories();
  });

  useProgressBar(({ jobType, total, seen }: IProgressBarEventData) => {
    if (jobType !== 'discovery') {
      return;
    }

    setDiscoveryProgressBar((prevState) => {
      const discoveryComplete = seen >= total;

      if (!discoveryComplete && !prevState) {
        setUnreadEventsCount((prevState) => prevState + 1);
      }

      if (discoveryComplete && prevState) {
        scheduleClearDiscovery();
        return { total, seen: total };
      }

      if (!discoveryComplete) {
        return { total, seen };
      }
    });
  });

  const toggleOpenState = () => {
    setIsExpanded((prevState) => !prevState);
    setUnreadEventsCount(0);
  };

  const activeProgressBars = Object.keys(scanningRepositoriesProgressBar).length || discoveryProgressBar;
  if (!activeProgressBars) {
    return null;
  }

  return (
    <ProgressBarContainer $expanded={isExpanded}>
      <DiscoveryProgressBarHeader expanded={isExpanded} eventsCount={unreadEventsCount} toggleExpanded={toggleOpenState} />
      {isExpanded && (
        <ProgressBarList>
          {discoveryProgressBar && <DiscoveryItem discovery={discoveryProgressBar} />}
          {Object.keys(scanningRepositoriesProgressBar).map((repoUuid) => (
            <RepositoryItem key={`progress-bar-repo-${repoUuid}`} repository={scanningRepositoriesProgressBar[repoUuid]} />
          ))}
        </ProgressBarList>
      )}
    </ProgressBarContainer>
  );
};
