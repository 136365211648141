import { axiosConfig as axios } from '@Utils';
import { IApiUserOrganizationResponse } from 'types';

export async function fetchUserOrg(token: string): Promise<IApiUserOrganizationResponse> {
  const response = await axios('/user/org', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
