import { EffortContainerStyled, EffortTextStyled, GroupIconStyled, RemediationEffortGroupContainerStyled, RemediationListContainerStyled, RemediationsContainerStyled } from './styled';
import { EffortDescriptionStyled } from './styled/EffortDescription.styled';
import { RemediationList } from '../RemediationList';
import { Pagination, initialPaginatedData, initialPagination } from '../Pagination';
import { useParams } from 'react-router-dom';
import { useRepositoryRemediation } from '../hooks';
import { usePagination, useQueryParams } from '@Utils';
import { PaginationState } from '@tanstack/react-table';
import { Flex } from '@Styles';
import { remediationEffortGroupConfig } from './utils';
import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ERemediationEffortLevels } from '@blindspot/common/types/bff/remediation';
import { IRemediationSummary } from '@APITypes';
import { useState } from 'react';

export const RemediationEffortGroup = ({ effortLevel, summaryData }: { effortLevel: ERemediationEffortLevels; summaryData: IRemediationSummary; isDiff?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { repoId } = useParams();
  const { queryParams } = useQueryParams({});
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: queryParams.page - 1 || initialPagination.pageIndex,
    pageSize: queryParams.size || initialPagination.pageSize,
  });

  const { data: remediations = initialPaginatedData } = useRepositoryRemediation({
    repoId,
    pagination: {
      pageIndex,
      pageSize,
    },
    effortLevel,
  });

  usePagination(pageIndex, pageSize);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const config = remediationEffortGroupConfig()[effortLevel];

  const effortLevelIssuesCount = summaryData.effortLevelsCount[effortLevel];

  return (
    <Flex flexDirection="column">
      <RemediationEffortGroupContainerStyled $isOpen={isOpen} onClick={handleDropdownClick}>
        <GroupIconStyled icon={isOpen ? faChevronUp : faChevronRight} />
        <EffortDescriptionStyled>
          <div>
            {config.label} <b>({effortLevelIssuesCount})</b>
          </div>
          <EffortTextStyled dangerouslySetInnerHTML={{ __html: config.description }}></EffortTextStyled>
        </EffortDescriptionStyled>
        <EffortContainerStyled>
          <EffortTextStyled>{config.effortTimeText}</EffortTextStyled>
          {config.iconSet}
        </EffortContainerStyled>
      </RemediationEffortGroupContainerStyled>

      <RemediationsContainerStyled $isOpen={isOpen}>
        <EffortTextStyled>Fixes available for the following dependency files:</EffortTextStyled>
        <RemediationListContainerStyled>
          <RemediationList repoId={repoId} remediations={remediations.data} effortLevel={effortLevel} />
          <Pagination pageIndex={pageIndex} pageSize={pageSize} pageCount={remediations.meta.pageCount} itemCount={remediations.meta.itemCount} setPagination={setPagination} />
        </RemediationListContainerStyled>
      </RemediationsContainerStyled>
    </Flex>
  );
};
