import React from 'react';
import { ArrowIcon, ProgressBarLabel, ProgressBarHeader, ProgressBarEventsCount } from '../styled';
import { IDiscoveryProgressBarHeader } from '../interfaces';

export const DiscoveryProgressBarHeader: React.FC<IDiscoveryProgressBarHeader> = ({ expanded, eventsCount, toggleExpanded }) => {
  const toggleExpandedState: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    toggleExpanded();
  };

  return (
    <ProgressBarHeader $expanded={expanded} onClick={!expanded ? toggleExpandedState : undefined}>
      <ProgressBarLabel $hidden={expanded}>
        Scanning in progress
        {!!eventsCount && <ProgressBarEventsCount>{eventsCount} New</ProgressBarEventsCount>}
      </ProgressBarLabel>
      <ArrowIcon $down={expanded} onClick={toggleExpandedState} />
    </ProgressBarHeader>
  );
};
