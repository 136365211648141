import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const NotificationMessage = styled(Flex)(
  ({ theme }) => css`
    line-height: 20px;
    letter-spacing: 0.1px;
    flex: 1 0 0;
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.bold};
    color: ${theme.colors.zinc[800]};
    margin-left: ${theme.spacing[1.5]};
  `,
);
