import { axiosConfig as axios } from '@Utils';
import { IApiRemediationParams } from '../types';

export const fetchRepositoryRemediation = async ({ repoId, pagination, effortLevel, diff }: IApiRemediationParams, token: string) => {
  const response = await axios(`remediation/repository/${repoId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: pagination?.pageIndex + 1,
      take: pagination?.pageSize,
      diff,
      ...(effortLevel && { filters: { effortLevel: effortLevel } }),
    },
  });
  return response?.data;
};
