import { ICVSSVector } from '../interfaces';

const exploitCodeMaturity: ICVSSVector = {
  name: 'Exploit Code Maturity',
  code: 'E',
  metrics: [
    { name: 'Not Defined', code: 'X' },
    { name: 'High', code: 'H' },
    { name: 'Functional', code: 'F' },
    { name: 'Proof of Concept', code: 'P' },
    { name: 'Unproven', code: 'U' },
  ],
};

const remediationLevel: ICVSSVector = {
  name: 'Remediation Level',
  code: 'RL',
  metrics: [
    { name: 'Not Defined', code: 'X' },
    { name: 'Unavailable', code: 'U' },
    { name: 'Workaround', code: 'W' },
    { name: 'Temporary Fix', code: 'T' },
    { name: 'Official Fix', code: 'O' },
  ],
};

const reportConfidence: ICVSSVector = {
  name: 'Report Confidence',
  code: 'RC',
  metrics: [
    { name: 'Not Defined', code: 'X' },
    { name: 'Confirmed', code: 'C' },
    { name: 'Reasonable', code: 'R' },
    { name: 'Unknown', code: 'U' },
  ],
};

export const temporalScoreMetrics = [exploitCodeMaturity, remediationLevel, reportConfidence];
