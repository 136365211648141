import React from 'react';
import { InfoLabel, InfoLabelTypes, SignRender, ESign } from '@Components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faFolder } from '@fortawesome/pro-regular-svg-icons';

import { ESeverityLevel, NotAvailable } from '@APITypes';
import { EIssuesCategoryLabel } from '@ComponentsTypes';
import {
  SourceBox,
  BoxWrap,
  Description,
  SourceTitle,
  Header,
  ExternalLink,
  ThreeCellWrap,
  ThreeCellWrapCell,
  SourceType,
  SourceTypeLink,
  ThreeCellWrapCellLink,
  SourceIconStyled,
} from './styled';
import { IDetailsSourceProps } from './interfaces';

export const DetailsSource: React.FC<IDetailsSourceProps> = ({ issue, isDiff }) => {
  if (!issue) {
    return null;
  }

  const dependency = issue.dependency;
  const dependencyUrl = `/discovery/dependencies/${dependency.uuid}`;

  return (
    <div>
      <SourceBox>
        <Header>
          <b>Origin</b>
          <Link to={dependencyUrl} target="_blank">
            <ExternalLink data-icon="open">Full origin details</ExternalLink>
          </Link>
        </Header>

        <BoxWrap $small>
          <SourceType>Dependency</SourceType>
          <SourceTitle>
            <span>{dependency.name}</span>
            <SignRender size="big" name={dependency?.dependencyOrigin?.toLowerCase() as unknown as ESign} />
          </SourceTitle>
          <p>
            <InfoLabel capitalize type={dependency.securityStatus ? dependency.securityStatus : InfoLabelTypes.Success}>
              {dependency.securityStatus ? dependency.securityStatus : 'No issues'}
            </InfoLabel>
          </p>
        </BoxWrap>

        <BoxWrap>
          <SourceTypeLink to={`${dependencyUrl}/issues`} target="_blank">
            <span>Security Issues</span>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </SourceTypeLink>
          <ThreeCellWrap>
            <ThreeCellWrapCell>
              <InfoLabel type={dependency.severeIssues?.critical && ESeverityLevel.CRITICAL}>{dependency.severeIssues?.critical || 0}</InfoLabel>
              <span>{EIssuesCategoryLabel.CRITICAL}</span>
            </ThreeCellWrapCell>
            <ThreeCellWrapCell>
              <InfoLabel type={dependency.severeIssues?.high && ESeverityLevel.HIGH}>{dependency.severeIssues?.high || 0}</InfoLabel>
              <span>{EIssuesCategoryLabel.HIGH}</span>
            </ThreeCellWrapCell>
            <ThreeCellWrapCell>
              <InfoLabel type={dependency.severeIssues?.medium && ESeverityLevel.MEDIUM}>{dependency.severeIssues?.medium || 0}</InfoLabel>
              <span>{EIssuesCategoryLabel.MEDIUM}</span>
            </ThreeCellWrapCell>
            <ThreeCellWrapCell>
              <InfoLabel type={dependency.severeIssues?.low && ESeverityLevel.LOW}>{dependency.severeIssues?.low || 0}</InfoLabel>
              <span>{EIssuesCategoryLabel.LOW}</span>
            </ThreeCellWrapCell>
          </ThreeCellWrap>
        </BoxWrap>

        <BoxWrap $twoCells>
          <BoxWrap>
            <SourceType>Installed Version</SourceType>
            <Description>{dependency.installedVersion}</Description>
          </BoxWrap>
          <BoxWrap>
            <SourceType>Latest Version</SourceType>
            <Description>{dependency.latestVersion ? dependency.latestVersion : NotAvailable}</Description>
          </BoxWrap>
        </BoxWrap>
        {!isDiff && (
          <BoxWrap>
            <SourceType>Connections</SourceType>
            <ThreeCellWrap>
              <ThreeCellWrapCellLink to={`${dependencyUrl}/repositories`}>
                <SourceIconStyled icon={faFolder} />
                <span>{`${dependency.repositoriesCount || 0} ${dependency.repositoriesCount === 1 ? 'Repository' : 'Repositories'}`}</span>
              </ThreeCellWrapCellLink>
              <ThreeCellWrapCell>
                <SourceIconStyled icon={faFolder} />
                <span>{`${dependency.branchesCount || 0} ${dependency.branchesCount === 1 ? 'Branch' : 'Branches'}`}</span>
              </ThreeCellWrapCell>
              <ThreeCellWrapCell>
                <SourceIconStyled icon={faFolder} />
                <span>{`${dependency.buildCount || 0} ${dependency.buildCount === 1 ? 'Build' : 'Builds'}`}</span>
              </ThreeCellWrapCell>
            </ThreeCellWrap>
          </BoxWrap>
        )}
      </SourceBox>
    </div>
  );
};
