import React from 'react';
import { useModal } from '@Modal';
import { ScanConfigSetRepositoriesModal } from '../ScanConfigSetRepositoriesModal';
import { ScanConfigSetNameInput } from '../ScanConfigSetNameInput';
import { IScanConfigSetHeaderProps } from './interfaces';
import { ScanConfigSetHeaderStyled, ScanConfigSetRepositoriesLabelStyled, ClickableStyled } from './styled';

export const ScanConfigSetHeader: React.FC<IScanConfigSetHeaderProps> = ({ configSetUuid, repositoriesCount, name, setDraftUpdates }) => {
  const { openModal } = useModal();

  const onUpdateName = (name: string) => {
    setDraftUpdates((prev) => ({ ...prev, name }));
  };

  const openRepositoriesModal = () => {
    if (!repositoriesCount) return;
    openModal(<ScanConfigSetRepositoriesModal configSetUuid={configSetUuid} name={name} />);
  };

  return (
    <ScanConfigSetHeaderStyled>
      <ScanConfigSetNameInput name={name} onChange={onUpdateName} />
      <ScanConfigSetRepositoriesLabelStyled>
        In use in{' '}
        <ClickableStyled $clickable={repositoriesCount > 0} onClick={openRepositoriesModal}>
          {repositoriesCount} {repositoriesCount === 1 ? 'repository' : 'repositories'}
        </ClickableStyled>
      </ScanConfigSetRepositoriesLabelStyled>
    </ScanConfigSetHeaderStyled>
  );
};
