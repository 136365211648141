import { ScoreFlag } from 'Layouts/components/ScoreFlag';
import { ScoreFlagInfoTooltip } from 'Layouts/components/ScoreFlag/components/ScoreFlagInfoTooltip';
import { EScoreFlagSize } from 'Layouts/components/ScoreFlag/types';
import { DashboardScoreTitleStyled } from '../styled/DashboardScoreTitle.styled';
import { DashboardScoreContainerStyled } from '../styled/DashboardScoreContainer.style';
import { useRepositoriesScore } from '../hooks/useRepositoriesScore';
import { useFilter } from 'core/Filter';

export const DashboardScore = () => {
  const { appliedFilters } = useFilter();
  const { data: repositoriesScore } = useRepositoriesScore({ filter: appliedFilters });

  if (!repositoriesScore) {
    return null;
  }

  return (
    <DashboardScoreContainerStyled>
      <ScoreFlag size={EScoreFlagSize.LARGE} score={repositoriesScore} />
      <DashboardScoreTitleStyled>Organization grade</DashboardScoreTitleStyled>
      <ScoreFlagInfoTooltip score={repositoriesScore} />
    </DashboardScoreContainerStyled>
  );
};
