import styled from 'styled-components';

export const DrawerContainerStyled = styled.div<{ $open: boolean }>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  transition: all 0.2s ease;
  left: 0;
  top: 0;
  z-index: 1500;
  display: flex;
`;
