import { CodeInject } from '@Components';
import { CodeBlockStyled, CodeBlockLabelStyled, CodeBlockContentStyled, CodeBlockNoDataLabelStyled } from './styled';
import { ICodeBlockProps } from './interfaces';

export const CodeBlock: React.FC<ICodeBlockProps> = ({ label, code, language }) => {
  return (
    <CodeBlockStyled>
      <CodeBlockLabelStyled>{label}</CodeBlockLabelStyled>
      <CodeBlockContentStyled>
        {code ? (
          <CodeInject code={code} language={language} />
        ) : (
          <CodeBlockNoDataLabelStyled>
            We didn’t find the matching
            <br />
            class or function
          </CodeBlockNoDataLabelStyled>
        )}
      </CodeBlockContentStyled>
    </CodeBlockStyled>
  );
};
