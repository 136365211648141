import { BackButton } from 'Layouts/DiscoveryPage/components/SubComponents';
import { Flex } from '@Styles';
import { ContainerStyled } from './styled';
import { IgnoreIssueButton } from './IgnoreIssueModal';
import { CreateTicketButton } from './CreateTicketModal';
import { useParams } from 'react-router-dom';
import { useIssueDetails } from 'Layouts/IssuesPage/hooks';
import { useMemo } from 'react';
import { IIssueNavContainerProps } from './interfaces';
import { useCanAccess } from 'Utils/auth';
import { Permission } from '@Permissions';

export const IssueNavContainer: React.FC<IIssueNavContainerProps> = ({ isDiff }: IIssueNavContainerProps) => {
  const { baseIssueUuid, branchUuid } = useParams();
  const canIgnoreIssue = useCanAccess([Permission.ISSUES_DEPENDENCIES_READ]);
  const diff = useMemo(() => (isDiff ? { branchId: branchUuid } : undefined), [isDiff, branchUuid]);
  const { data: issueDetails } = useIssueDetails({ issueUuid: baseIssueUuid, diff });

  return (
    <ContainerStyled>
      <BackButton noSpacing={true} />
      <Flex gap={1}>
        <CreateTicketButton baseIssueUuid={baseIssueUuid} tickets={issueDetails.tickets} />
        {canIgnoreIssue && <IgnoreIssueButton ignoreIssue={issueDetails.ignoredIssue} baseIssueUuid={baseIssueUuid} isFixed={!!issueDetails.fixedAt} />}
      </Flex>
    </ContainerStyled>
  );
};
