import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationEditStyled = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing[0.5]};
  margin: 0 ${({ theme }) => theme.spacing[2]} 0 auto;
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.indigo[500]};
  font-size: ${({ theme }) => theme.text.size.body2};
  cursor: pointer;
`;
