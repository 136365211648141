import React from 'react';
import { DefaultLabel } from '../../styled';
import { ScanConfigNameStyled } from './styled';
import { IScanConfigSetSelectOptionProps } from './interfaces';
import { getConfigValueByConfigName } from '@blindspot/common/utils/configs';
import { EScanConfigName } from '@blindspot/common/types/bff/configs';
import { EmailUpdatesConfigSummary, MonitoringEnginesConfigSummary, PRChecksConfigSummary, DependenciesInventoryConfigSummary } from './components';

export const ScanConfigSetSelectOption: React.FC<IScanConfigSetSelectOptionProps> = ({ scanConfigSet }) => {
  const prChecksConfigValue = getConfigValueByConfigName(scanConfigSet.configs, EScanConfigName.PR_CHECKS);
  const enginesConfigValue = getConfigValueByConfigName(scanConfigSet.configs, EScanConfigName.MONITORING_ENGINE);
  const emailsConfigValue = getConfigValueByConfigName(scanConfigSet.configs, EScanConfigName.EMAIL_UPDATES);
  const dependenciesInventoryConfigValue = getConfigValueByConfigName(scanConfigSet.configs, EScanConfigName.DEPENDENCIES_INVENTORY);

  return (
    <div>
      <ScanConfigNameStyled>
        {scanConfigSet.name}
        {scanConfigSet.isDefault && <DefaultLabel>Default</DefaultLabel>}
        {scanConfigSet.active && <DefaultLabel>Active</DefaultLabel>}
      </ScanConfigNameStyled>
      <DependenciesInventoryConfigSummary configValue={dependenciesInventoryConfigValue} />
      <MonitoringEnginesConfigSummary configValue={enginesConfigValue} />
      <EmailUpdatesConfigSummary configValue={emailsConfigValue} />
      <PRChecksConfigSummary configValue={prChecksConfigValue} />
    </div>
  );
};
