import React from 'react';
import { CheckboxInput, StyledCheckbox, CheckboxLabel, CheckboxLabelText, CheckIcon, StyledCheckboxWrapper } from './styled';
import { ICheckboxProps } from './interfaces';

export const Checkbox: React.FC<ICheckboxProps> = ({ label, checked, onChange }) => {
  return (
    <CheckboxLabel>
      <CheckboxInput checked={checked} onChange={onChange} />
      <StyledCheckboxWrapper>
        <StyledCheckbox $checked={checked}>{checked && <CheckIcon />}</StyledCheckbox>
      </StyledCheckboxWrapper>
      {label && <CheckboxLabelText>{label}</CheckboxLabelText>}
    </CheckboxLabel>
  );
};
