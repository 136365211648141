import React, { useState } from 'react';
import {
  RemediationDepLineNumberStyled,
  RemediationDepLineSeparatorStyled,
  RemediationDependenciesStyled,
  RemediationDependencyIconStyled,
  RemediationDependencyStyled,
  RemediationDetailsStyled,
  RemediationEditStyled,
  RemediationItemStyled,
  RemediationNameStyled,
  RemediationToggleIconStyled,
  RemediationToggleStyled,
} from './styled';
import { RemediationIssues } from './RemediationIssues';
import { faArrowRightLong, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { IRemediationItemProps } from './types';
import { IRemediationDependency } from '@APITypes';
import { useDrawer } from '@Drawer';
import { RemediationEditDrawer } from './RemediationEditDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RemediationItem: React.FC<IRemediationItemProps> = ({ repoId, depFileName, depFileUuid, dependencies, depFilefixedIssues, effortLevel }) => {
  const [isToggled, setIsToggled] = useState(false);

  const onToggle = () => setIsToggled(!isToggled);
  const { openDrawer } = useDrawer();

  const handleClickEdit = (dependency: IRemediationDependency) => (e: React.MouseEvent<any, MouseEvent>) => {
    e.stopPropagation();
    openDrawer(<RemediationEditDrawer dependency={dependency} dependencies={dependencies} repoId={repoId} />);
  };

  return (
    <RemediationItemStyled $isToggled={isToggled}>
      <RemediationToggleStyled onClick={onToggle} $isToggled={isToggled}>
        <RemediationToggleIconStyled $isToggled={isToggled} icon={faChevronRight} />
        <RemediationNameStyled>{depFileName}</RemediationNameStyled>
        <RemediationIssues repoId={repoId} depFileUuid={depFileUuid} issues={depFilefixedIssues} effortLevel={effortLevel} />
      </RemediationToggleStyled>
      <RemediationDependenciesStyled $isToggled={isToggled}>
        {dependencies.map((dependency, index) => (
          <RemediationDependencyStyled key={`${dependency.source.uuid}-${index}`} onClick={handleClickEdit(dependency)}>
            {dependency.depFileUrlWithLine && dependency.depFileLineNumber ? (
              <>
                <RemediationDepLineNumberStyled href={dependency.depFileUrlWithLine} target="_blank">
                  Line #{dependency.depFileLineNumber}
                </RemediationDepLineNumberStyled>
                <RemediationDepLineSeparatorStyled>•</RemediationDepLineSeparatorStyled>
              </>
            ) : null}
            <span>{`${dependency.source.name} ${dependency.source.version}`}</span>
            <RemediationDependencyIconStyled icon={faArrowRightLong} />
            <span>{dependency.target.version}</span>
            <RemediationEditStyled>
              <RemediationDetailsStyled>Remediation details</RemediationDetailsStyled>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </RemediationEditStyled>
          </RemediationDependencyStyled>
        ))}
      </RemediationDependenciesStyled>
    </RemediationItemStyled>
  );
};
