import styled from 'styled-components';

export const TableStyled = styled.table`
  background: ${({ theme }) => `${theme.colors.white}`};
  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 12px;
  border-style: hidden;
  table-layout: auto;
  min-width: 100%;
`;
