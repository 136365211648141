import { EScmAppType } from '@blindspot/common/types/bff';
import { IIntegrations } from '../../../Utils/integrations/types';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const findIntegrationAppType = (integrations: IIntegrations[], provider: EScmType): EScmAppType | undefined => {
  if (!integrations) {
    return;
  }
  const integration = integrations.find((integration) => integration.type === provider);

  if (!integration) {
    return;
  }

  return integration.appType;
};
