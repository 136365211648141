import { EMonitoringStatus } from '@APITypes';
import { isEmpty } from '@Utils';
import { FilterSelect } from 'core/Filter/components/FilterSelect';
import { useRepositoriesName } from '../../hooks';
import { RepositoryFilterStyled, FilterTitleStyled, RepositoryFilterContainerStyled } from './styled';
import { ValueContainer } from './ValueContainer';
import { ClearIndicator } from './ClearIndicator';
import { useMemo } from 'react';
import { useFilter } from 'core/Filter';

export const RepositoryFilter = () => {
  const { data, isLoading } = useRepositoriesName({ filter: { 'filter[monitoringStatus]': `${EMonitoringStatus.MONITORED}+${EMonitoringStatus.SCANNING}` } });
  const { appliedFiltersCount } = useFilter();
  const options = useMemo(() => {
    return !isEmpty(data) ? data?.map((repository) => ({ label: repository.name, value: repository.uuid, count: 0 })) : [];
  }, [data]);

  return (
    <RepositoryFilterStyled data-hide-in-pdf>
      <FilterTitleStyled htmlFor={'repositoryUuids'}>{'Filter by repository:'}</FilterTitleStyled>
      <RepositoryFilterContainerStyled>
        <FilterSelect
          filterId={'repositoryUuids'}
          options={options}
          placeholder={appliedFiltersCount > 0 ? ' ' : 'All'}
          maxMenuHeight={360}
          isLoading={isLoading}
          valueContainer={ValueContainer}
          clearIndicator={ClearIndicator}
        />
      </RepositoryFilterContainerStyled>
    </RepositoryFilterStyled>
  );
};
