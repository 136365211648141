import styled from 'styled-components';

export const RemediationDepLineNumberStyled = styled.a`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  text-decoration-line: underline;
  cursor: pointer;
  &:visited {
    color: ${({ theme }) => theme.colors.black};
  }
`;
