import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ListSelectionSummaryWrapperStyled = styled(Flex)(
  ({ theme }) => css`
    gap: ${theme.spacing[1]};
    justify-content: center;
    align-items: center;
    margin-bottom: ${theme.spacing[1.5]};
    background: ${theme.colors.indigo[100]};
    mix-blend-mode: multiply;
    border-radius: 8px;
    padding: ${theme.spacing[2]};
    font-weight: ${theme.text.weight.regular};
    font-size: ${theme.text.size.body2};
    letter-spacing: 0.2px;
    line-height: 24px;
  `,
);
