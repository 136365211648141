import { faCodeCompare, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { Node } from 'reactflow';
import { NODE_HEIGHT } from '../types';
import { calculateWidth } from './calculateWidth';

export function createNode({ label, icon = faCodeCompare, isIssueDependency = false }: { label: string; icon: IconDefinition; isIssueDependency?: boolean }): Node {
  return {
    id: label,
    data: { label, icon, isIssueDependency },
    type: 'chainNode',
    position: { x: 0, y: 0 },
    width: calculateWidth(label),
    height: NODE_HEIGHT,
  };
}
