import styled from 'styled-components';

export const SelectLabelStyled = styled.span`
  display: grid;
  grid-column: 1/3;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  align-content: center;
  grid-gap: 6px;

  [data-icon] + & {
    grid-column: 2/3;
  }

  & [data-label] {
    opacity: 0.5;
  }
`;
