import { IRepositoryFailedPullRequestsItem } from 'Layouts/DashboardPage/interfaces';
import { FailedPullRequestItemStyled, IconStyled, LinkStyled, TextStyled } from './styled';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

export const FailedPullRequestItem = ({ failedPullRequest }: { failedPullRequest: IRepositoryFailedPullRequestsItem }) => {
  const { uuid, name, failedPullRequestsCount } = failedPullRequest;
  const repositoryUrl = `/discovery/repositories/${uuid}`;
  return (
    <FailedPullRequestItemStyled>
      <IconStyled icon={faCircle} />
      <span>
        <LinkStyled to={`${repositoryUrl}/pull-requests`}>{failedPullRequestsCount > 1 ? `${failedPullRequestsCount} failed checks` : '1 failed check'}</LinkStyled>
        <TextStyled> in repository </TextStyled>
        <LinkStyled to={repositoryUrl}>{name}</LinkStyled>
      </span>
    </FailedPullRequestItemStyled>
  );
};
