import { useMutation } from 'react-query';
import { repositoryMonitor } from '../api';
import { IMonitorRepositoryDto } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useRepositoryMonitor = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(
    async (payload: IMonitorRepositoryDto) => {
      if (isAuthenticated && !isSessionLoading) {
        return await repositoryMonitor(payload, sessionToken);
      }
    },
    {
      retry: false,
    },
  );
};
