import { DrawerStandard } from '@Drawer';
import { RemediationEditDrawerContent } from './RemediationEditDrawerContent';
import { IRemediationEditDrawer } from './types';
import { useState } from 'react';

export const RemediationEditDrawer: React.FC<IRemediationEditDrawer> = ({ dependency, dependencies, repoId }: IRemediationEditDrawer) => {
  const [selectedDependency, setNewDependency] = useState(dependency);

  const currentDependencyIndex = dependencies.findIndex((item) => item.source.uuid === selectedDependency.source.uuid);
  const dependenciesAmount = dependencies.length;

  const handleChangeDependency = (direction: 'next' | 'prev') => {
    const newIndex = direction === 'next' ? currentDependencyIndex + 1 : currentDependencyIndex - 1;
    if (newIndex >= 0 && newIndex < dependenciesAmount) {
      setNewDependency(dependencies[newIndex]);
    }
  };

  return (
    <DrawerStandard
      content={
        <RemediationEditDrawerContent
          dependency={selectedDependency}
          repoId={repoId}
          dependenciesAmount={dependenciesAmount}
          currentDependencyIndex={currentDependencyIndex}
          handleChangeDependency={handleChangeDependency}
        />
      }
      header={false}
    />
  );
};
