import { DependencyEnrichmentsOriginToDevLanguage, EIssueSubType, IIssueDetails } from '@APITypes';
import { ICodeDetails } from 'Layouts/components/CodeFiles/types';
import { ICodeViewerData } from '../components/GptResponse/interfaces';

export const prepareComponentData = (issueDetails: IIssueDetails, codeDetails: ICodeDetails): ICodeViewerData => {
  const issueCategoryDetails = issueDetails[issueDetails.category];
  const commonIssueData = {
    filePaths: issueCategoryDetails.filesPath,
    isInvalidCodeData: !issueCategoryDetails.filesPath?.some((value) => value) || !issueCategoryDetails.functionsNames?.some((value) => value),
    functionsNames: issueCategoryDetails.functionsNames,
    decompileEnrich: issueCategoryDetails.decompileEnrich,
    sourceCodes: issueCategoryDetails.sourceCode,
    scoreLabel: 'Maliciousness Score',
    language: DependencyEnrichmentsOriginToDevLanguage[issueDetails.dependency?.dependencyOrigin],
  };
  switch (issueDetails.category) {
    case EIssueSubType.TAMPERING:
      return Object.assign(commonIssueData, {
        aiScores: issueCategoryDetails.gptScore,
        aiDescriptions: issueCategoryDetails.gptDescription,
        code: codeDetails.sourceCode,
        binaryCode: codeDetails.decompileEnrich,
        isSingleColumn: false,
      });
    case EIssueSubType.RISKY_CODE:
      return Object.assign(commonIssueData, {
        aiScores: issueCategoryDetails.maliciousnessScores,
        aiDescriptions: issueCategoryDetails.tamperedDescriptions,
        code: codeDetails.decompileEnrich,
        isSingleColumn: true,
      });
    default:
      return null;
  }
};
