import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@Components';
import { CreateTicketIconButtonStyled } from './styled';
import { ICreateTicketIconButtonProps } from './types';

export const CreateTicketIconButton: React.FC<ICreateTicketIconButtonProps> = ({ onClick, disabled }) => {
  return (
    <Tooltip tooltip="Create Ticket">
      <CreateTicketIconButtonStyled onClick={onClick} disabled={disabled}>
        <FontAwesomeIcon icon={faPlus} />
      </CreateTicketIconButtonStyled>
    </Tooltip>
  );
};
