import { axiosConfig as axios } from '@Utils';
import { IApiIssueRemediationParams } from '../types';

export const fetchIssueRemediation = async ({ issueUuid, diff }: IApiIssueRemediationParams, token: string) => {
  const response = await axios(`remediation/issues/${issueUuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      diff,
    },
  });
  return response?.data;
};
