import styled from 'styled-components';
import { NodeStyled as DefaultNodeStyled } from 'Layouts/components/Graph';

interface NodeStyledProps {
  $isIssue?: boolean;
}

export const NodeStyled = styled(DefaultNodeStyled)<NodeStyledProps>`
  gap: ${({ theme }) => theme.spacing[0.5]};

  color: ${({ theme, $isIssue }) => ($isIssue ? theme.colors.rose[600] : theme.colors.zinc[700])};
`;
