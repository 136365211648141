import { useTheme } from 'styled-components';

import { useDependenciesSummary } from '@Hooks';
import { useFilter } from 'core/Filter';
import { CardTitleStyled, DashboardCardStyled } from '../styled';
import { getMonitoredChartOptions } from '../config';
import { Chart } from './Chart';

export const MonitoredDependencies = () => {
  const theme = useTheme();
  const { appliedFilters } = useFilter();
  const { data, isLoading } = useDependenciesSummary({ filter: appliedFilters });

  const total = data?.total || 0;
  const monitored = data?.monitored + data?.scanning || 0;
  const unmonitored = total - monitored;

  const series = [monitored, unmonitored];
  const options = getMonitoredChartOptions({ theme, monitored, total });

  return (
    <DashboardCardStyled $maxHeight="410px">
      <CardTitleStyled>Dependencies Scanned</CardTitleStyled>
      <Chart isLoading={isLoading} options={options} series={series} type="donut" width={400} />
    </DashboardCardStyled>
  );
};
