import {
  EScanConfigName,
  IScanConfigPRChecksValue,
  IScanConfigMonitoringEngineValue,
  IScanConfigEmailUpdatesValue,
  IScanConfigDependenciesInventoryValue,
} from '@blindspot/common/types/bff/configs';

export type ConfigValueByConfigName = {
  [EScanConfigName.MONITORING_ENGINE]: IScanConfigMonitoringEngineValue;
  [EScanConfigName.PR_CHECKS]: IScanConfigPRChecksValue;
  [EScanConfigName.EMAIL_UPDATES]: IScanConfigEmailUpdatesValue;
  [EScanConfigName.DEPENDENCIES_INVENTORY]: IScanConfigDependenciesInventoryValue;
};
