import { useMutation } from 'react-query';
import { IReminder } from '@blindspot/common/types/bff/api';
import { updateUserReminder } from '../api';
import { useSession } from '@descope/react-sdk';

export const useUserReminderUpdate = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async (update: Omit<IReminder, 'uuid'>) => {
    if (isAuthenticated && !isSessionLoading) {
      return updateUserReminder(update, sessionToken);
    }
  });
};
