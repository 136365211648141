import React from 'react';
import styled from 'styled-components';

type AvatarSize = 'small' | 'medium' | 'large';

interface IAvatarRenderStyled {
  $url: string;
  $size?: AvatarSize;
}

const AvatarRenderStyled = styled.figure<IAvatarRenderStyled>`
  background: ${({ theme }) => theme.colors.zinc[300]};
  background-image: url(${(props) => props.$url});
  border-radius: 100%;
  background-size: cover;
  display: inline-block;
  ${(props) => {
    switch (props.$size) {
      case 'small':
        return `
          width: 24px;
          height: 24px;
        `;
      case 'medium':
        return `
          width: 32px;
          height: 32px;
        `;
      case 'large':
        return `
          width: 40px;
          height: 40px;
        `;
      default:
        return `
          width: 40px;
          height: 40px;
        `;
    }
  }}
`;

export const AvatarRender: React.FC<{ url: string; size?: AvatarSize }> = ({ url, size }) => {
  return <AvatarRenderStyled $size={size} $url={url} />;
};
