import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ReachabilityRepositoryTitleStyled = styled(Link)`
  margin-left: ${({ theme }) => theme.spacing['1']};
  font-size: ${({ theme }) => theme.text.size.h3};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.zinc['700']};
  letter-spacing: 0.15px;
  cursor: pointer;

  :hover,
  :visited,
  :active {
    color: ${({ theme }) => theme.colors.zinc['700']};
    text-decoration: underline;
  }
`;
