import styled from 'styled-components';

export const InputStyled = styled.input`
  height: 32px;
  padding: 0px ${({ theme }) => theme.spacing[1.5]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.zinc[200]};
  outline: none;
  width: 100%;
  font-size: ${({ theme }) => theme.text.size.body2};
  color: ${({ theme }) => theme.colors.black};
`;
