import styled, { css } from 'styled-components';

export const CodeFileNameStyled = styled.p(
  ({ theme }) => css`
    padding: 0 0 0 ${theme.spacing[0.5]};
    width: max-content;
    cursor: pointer;
    position: relative;
    font-size: ${theme.text.size.subtitle2};
    font-weight: ${theme.text.weight.extraLight};
  `,
);
