import styled, { css } from 'styled-components';

export const DetailsSubtitleStyled = styled.span<{ $small?: boolean }>(
  ({ theme, $small }) => css`
    color: ${theme.colors.zinc[500]};
    letter-spacing: 0.15px;
    font-size: ${$small ? theme.text.size.caption : theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    margin: ${theme.spacing[0.5]} 0;
  `,
);
