import React from 'react';
import { NotAvailable } from '@APITypes';
import { UserRenderAvatarStyled, UserRenderContainerStyled, UserRenderEmptyAvatarStyled, UserRenderNameStyled } from './styled';
import { IUserRenderProps } from './types';

export const UserRender: React.FC<IUserRenderProps> = ({ userName, userAvatar }) => {
  return (
    <UserRenderContainerStyled>
      {!userAvatar ? <UserRenderEmptyAvatarStyled /> : <UserRenderAvatarStyled $backgroundImageUrl={userAvatar} />}
      <UserRenderNameStyled>{userName || NotAvailable}</UserRenderNameStyled>
    </UserRenderContainerStyled>
  );
};
