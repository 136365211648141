import { Flex } from '@Styles';
import styled from 'styled-components';

export const CategoryScoreBlock = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.text.size.body2};
  line-height: 24px;
  letter-spacing: 0.2px;

  span:last-of-type {
    font-weight: ${({ theme }) => theme.text.weight.bold};
    letter-spacing: 0.1px;
  }
`;
