import { Flex } from '@Styles';
import { TooltipContainer } from 'Layouts/components/Tooltip/styled';
import styled from 'styled-components';

export const RemediationIssueLabelsStyled = styled(Flex)`
  gap: ${({ theme }) => theme.spacing[1.5]};
  align-items: center;
  ${TooltipContainer}:last-child {
    margin-right: 0;
  }
`;
