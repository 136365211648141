import styled from 'styled-components';

export const MonitoringEngineOptionLabelStyled = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  cursor: pointer;
  user-select: none;
`;
