import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Link = styled(ReactRouterLink)`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing['2']};
  align-items: center;
  align-content: center;
  font-size: ${({ theme }) => theme.text.size['body-1']};
  user-select: none;
`;
