import { useMutation } from 'react-query';
import { deleteScanConfigSet } from './api';
import { useSession } from '@descope/react-sdk';

export const useScanConfigSetDelete = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async (scanConfigSetUuid: string) => {
    if (isAuthenticated && !isSessionLoading) {
      return deleteScanConfigSet(scanConfigSetUuid, sessionToken);
    }
  });
};
