import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

import { Button, ButtonSize, ButtonVariant } from '@Components';
import { useModal } from '@Modal';
import { BitbucketWorkspaceAccessTokenModal } from '../../BitbucketWorkspaceAccessTokenModal';
import { BitbucketAppPasswordModal } from '../../BitbucketAppPasswordModal';
import { IConnectionFlowSelectionProps } from './types';
import { ConnectionFlowTitleStyled } from './styled';

export const ConnectFlowSelection = ({ isUpdate }: IConnectionFlowSelectionProps) => {
  const { openModal } = useModal();
  const onWorkspaceAccessTokenClick = () => {
    openModal(<BitbucketWorkspaceAccessTokenModal isUpdate={isUpdate} />);
  };

  const onAppPasswordClick = () => {
    openModal(<BitbucketAppPasswordModal isUpdate={isUpdate} />);
  };
  return (
    <>
      <ConnectionFlowTitleStyled>How would you like to integrate with Bitbucket?</ConnectionFlowTitleStyled>
      <Button onClick={onWorkspaceAccessTokenClick} variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE} justifyContent="flex-start">
        <FontAwesomeIcon icon={faTag} />
        Workspace Access Token
      </Button>
      <Button onClick={onAppPasswordClick} variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE} justifyContent="flex-start">
        <FontAwesomeIcon icon={faEyeSlash} />
        App Password
      </Button>
    </>
  );
};
