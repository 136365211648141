import { Button } from '@Styles';
import styled from 'styled-components';

export const DrawerContentSubmitButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.indigo[500]};
  padding: 0 ${({ theme }) => theme.spacing[2]};
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.indigo[300]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
`;
