import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ReportName = styled.p(
  ({ theme }) => css`
    line-height: 28px;
    letter-spacing: 0.15px;
    font-size: ${theme.text.size.body1};
    font-weight: ${theme.text.weight.bold};
    color: ${theme.colors.zinc[800]};
    word-break: break-all;
  `,
);
