import styled, { css } from 'styled-components';

export const NameInput = styled.input(
  ({ theme }) => css`
    font-size: ${theme.text.size.body1};
    font-weight: ${theme.text.weight.regular};
    line-height: 28px;
    outline: none;
    border: none;
    font-family: ${theme.text.fontFamily};
    overflow: visible;
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
  `,
);
