import React from 'react';
import { DashboardPage, DiscoveryPage, IntegrationsLayout, IssuesPage, IssuesTab, Layout, SettingsPage } from '@Layouts';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthErrorPage, ErrorBlock, ErrorHandler, PageNotFound } from '@Error';
import { SocketProvider, IntegratedProvider, pageTools, IntegratedGuard, ParagonProvider } from '@Utils';
import { IssueContainerDetails } from './Layouts/IssuesPage/components/issueDetails';
import { IssuesTable } from '@Layouts';
import { DependenciesDetails, DependenciesTable, DependencyFilesTable, RepositoryDependenciesFilesTable, RepositoryDetails } from './Layouts/DiscoveryPage/components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IntegrationsContent } from './Layouts/IntegrationsPage/IntegrationsContent';
import { IssuesRemediation, Remediation } from 'Layouts/DiscoveryPage/components/Remediation';
import {
  Reachability as IssueReachability,
  ReachabilityDetails as IssueReachabilityDetails,
  ReachabilityPathsContent as IssueReachabilityPathsContent,
} from 'Layouts/IssuesPage/components/issueDetails/Reachability';
import { Details as IssueDetails } from 'Layouts/IssuesPage/components/issueDetails/Details';
import { CodeViewer } from 'Layouts/IssuesPage/components/issueDetails/CodeViewer';
import { RepositoriesList } from 'Layouts/DiscoveryPage/components/RepositoriesList';
import { UserManagement, ScanConfigSets, ScanConfigSetDetails } from 'Layouts/SettingsPage/components';
import { EIssueGroup } from 'Layouts/IssuesPage/interfaces';
import * as Sentry from '@sentry/react';
import { ScrollToTop } from '@Components';
import { PullRequestsList } from 'Layouts/DiscoveryPage/components/PullRequestsList';
import { PullRequestDetails } from 'Layouts/DiscoveryPage/components/PullRequestDetails';
import { IssuesTable1stParty } from 'Layouts/IssuesPage/components/IssuesTable1stParty';
import 'Styles/theme/App.css';
import { AuthProvider } from '@descope/react-sdk';
import { AccessGuard } from 'Utils/auth/components/AccessGuard';
import { Permission } from '@Permissions';
import { ModalProvider } from '@Modal';
import { EIssueSourceType } from '@blindspot/common/types/bff';

const queryClient = new QueryClient();
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
export const App = () => {
  return (
    <AuthProvider baseUrl={process.env.REACT_APP_DESCOPE_BASE_URL} projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID}>
      <BrowserRouter>
        <ScrollToTop>
          <QueryClientProvider client={queryClient}>
            <IntegratedProvider>
              <ParagonProvider>
                <SocketProvider>
                  <div data-commit={process.env.REACT_APP_GITHUB_SHA || 'cant find'}></div>
                  <ErrorHandler errorTemplate={<ErrorBlock isFullHeight />}>
                    <SentryRoutes>
                      <Route path="/error/auth" element={<AuthErrorPage />} />
                      <Route element={<Layout />}>
                        <Route index path={'/'} element={<DashboardPage />} />
                        <Route path={`/${pageTools.dashboard.path}`} element={<DashboardPage />} />
                        <Route
                          element={
                            <AccessGuard
                              permissions={[
                                Permission.CONFIGS_SCAN_READ,
                                Permission.CONFIGS_SCAN_CREATE,
                                Permission.CONFIGS_SCAN_UPDATE,
                                Permission.CONFIGS_SCAN_DELETE,
                                Permission.USER_READ,
                                Permission.USER_UPDATE,
                                Permission.USER_DELETE,
                                Permission.ORGANIZATION_USERS_READ,
                                Permission.ORGANIZATION_USERS_UPDATE,
                              ]}
                            />
                          }
                        >
                          <Route path={`/${pageTools.settings.path}`} element={<SettingsPage />}>
                            <Route index element={<Navigate to="user-management" />} />
                            <Route path={`user-management`} element={<UserManagement />} />
                            <Route element={<IntegratedGuard />}>
                              <Route path={`scan-configurations`} element={<ScanConfigSets />}>
                                <Route path={`:scanConfigSetUuid`} element={<ScanConfigSetDetails />} />
                              </Route>
                            </Route>
                          </Route>
                        </Route>

                        <Route element={<AccessGuard permissions={[Permission.INTEGRATION_CREATE, Permission.INTEGRATION_READ, Permission.INTEGRATION_UPDATE]} />}>
                          <Route path={`/${pageTools.integrations.path}`} element={<IntegrationsLayout />}>
                            <Route index element={<IntegrationsContent />} />
                            <Route path={':provider/integrated'} element={<IntegrationsContent />} />
                          </Route>
                        </Route>
                        <Route element={<IntegratedGuard />}>
                          <Route path={`/${pageTools.issues.path}`}>
                            <Route path={':baseIssueUuid'}>
                              <Route element={<IssueContainerDetails />}>
                                <Route index element={<Navigate to="details" />} />
                                <Route path={`details`} element={<IssueDetails />} />
                                <Route path={`reachabilities`} element={<IssueReachability />} />
                                <Route path={`remediation-plan`} element={<IssuesRemediation />} />
                                <Route path={`risky-code-files`} element={<CodeViewer />} />
                                <Route path={`code-injection`} element={<CodeViewer />} />
                              </Route>
                              <Route path={`reachabilities/:issueUuid`} element={<IssueReachabilityDetails />}>
                                <Route index element={<Navigate to="paths" />} />
                                <Route path={`paths`} element={<IssueReachabilityPathsContent />} />
                              </Route>
                            </Route>
                            <Route element={<IssuesPage />}>
                              <Route index element={<Navigate to="all" />} />
                              <Route path={`all`} element={<IssuesTable showColumnVisibility={true} showSearch={true} />} />
                              <Route path={`recommended`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.RECOMMENDED} />} />
                              <Route path={`low-risk`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.LOW_RISK} />} />
                              <Route path={`ignored`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.IGNORED} />} />
                              <Route path={`fixed`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.FIXED} />} />
                              <Route path={'*'} element={<IssuesTable showColumnVisibility={true} showSearch={true} />} />
                            </Route>
                          </Route>
                          <Route path={`/${pageTools.issues.path}/first-party`}>
                            <Route element={<IssuesPage issueSourceType={EIssueSourceType.FIRST_PARTY} />}>
                              <Route index element={<Navigate to="all" />} />
                              <Route path={`all`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} />} />
                              <Route path={`recommended`} element={<IssuesTable1stParty showSearch={true} group={EIssueGroup.RECOMMENDED} />} />
                              <Route path={`low-risk`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.LOW_RISK} />} />
                              <Route path={`ignored`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.IGNORED} />} />
                              <Route path={`fixed`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.FIXED} />} />
                              <Route path={'*'} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} />} />
                            </Route>
                          </Route>

                          <Route path={`/${pageTools.discovery.path}`}>
                            <Route element={<DiscoveryPage />}>
                              <Route index element={<Navigate to="repositories" />} />
                              <Route path={`repositories`} element={<RepositoriesList />} />
                              <Route path={`dependencies`} element={<DependenciesTable showColumnVisibility={true} />} />
                            </Route>
                            <Route
                              path={`repositories/:repoId`}
                              element={
                                <ModalProvider>
                                  <RepositoryDetails />
                                </ModalProvider>
                              }
                            >
                              <Route index element={<Navigate to="issues" />} />
                              <Route path="issues" element={<IssuesTab />}>
                                <Route index element={<Navigate to="all" />} />
                                <Route path={`all`} element={<IssuesTable showColumnVisibility={true} showSearch={true} />} />
                                <Route path={`recommended`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.RECOMMENDED} />} />
                                <Route path={`low-risk`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.LOW_RISK} />} />
                                <Route path={`fixed`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.FIXED} />} />
                                <Route path={`ignored`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.IGNORED} />} />
                              </Route>
                              <Route path="issues/first-party" element={<IssuesTab />}>
                                <Route index element={<Navigate to="all" />} />
                                <Route path={`all`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} />} />
                                <Route path={`recommended`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.RECOMMENDED} />} />
                                <Route path={`low-risk`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.LOW_RISK} />} />
                                <Route path={`fixed`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.FIXED} />} />
                                <Route path={`ignored`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.IGNORED} />} />
                              </Route>
                              <Route path={`pull-requests`} element={<PullRequestsList />} />
                              <Route path={`dependencies`} element={<DependenciesTable showColumnVisibility={true} />} />
                              <Route path={`dependencies-files`} element={<RepositoryDependenciesFilesTable />} />
                              <Route path={`remediation`} element={<Remediation />} />
                            </Route>
                            <Route path={`dependencies/:depId`} element={<DependenciesDetails />}>
                              <Route index element={<Navigate to="issues" />} />
                              <Route path="issues" element={<IssuesTab />}>
                                <Route index element={<Navigate to="all" />} />
                                <Route path={`all`} element={<IssuesTable showColumnVisibility={true} showSearch={true} />} />
                                <Route path={`recommended`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.RECOMMENDED} />} />
                                <Route path={`low-risk`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.LOW_RISK} />} />
                                <Route path={`fixed`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.FIXED} />} />
                                <Route path={`ignored`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.IGNORED} />} />
                              </Route>
                              <Route path={`repositories`} element={<RepositoriesList showColumnVisibility={true} />} />
                              <Route path={`dependencies-files`} element={<DependencyFilesTable />} />
                            </Route>
                            <Route path={`repositories/:repoId/pull-requests`}>
                              <Route path={':pullRequestUuid'}>
                                <Route element={<PullRequestDetails />}>
                                  <Route index element={<Navigate to="issues" />} />
                                  <Route path={`issues`} element={<IssuesTab isDiff={true} />}>
                                    <Route index element={<Navigate to="all" />} />
                                    <Route path={`all`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.ALL} isDiff={true} />} />
                                    <Route path={`recommended`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.RECOMMENDED} isDiff={true} />} />
                                    <Route path={`low-risk`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.LOW_RISK} isDiff={true} />} />
                                    <Route path={`fixed`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.FIXED} isDiff={true} />} />
                                    <Route path={`ignored`} element={<IssuesTable showColumnVisibility={true} showSearch={true} group={EIssueGroup.IGNORED} isDiff={true} />} />
                                  </Route>
                                  <Route path={`issues/first-party`} element={<IssuesTab isDiff={true} />}>
                                    <Route index element={<Navigate to="all" />} />
                                    <Route path={`all`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.ALL} isDiff={true} />} />
                                    <Route
                                      path={`recommended`}
                                      element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.RECOMMENDED} isDiff={true} />}
                                    />
                                    <Route path={`low-risk`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.LOW_RISK} isDiff={true} />} />
                                    <Route path={`fixed`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.FIXED} isDiff={true} />} />
                                    <Route path={`ignored`} element={<IssuesTable1stParty showColumnVisibility={true} showSearch={true} group={EIssueGroup.IGNORED} isDiff={true} />} />
                                  </Route>
                                  <Route path={'issues/:baseIssueUuid/branch/:branchUuid'}>
                                    <Route element={<IssueContainerDetails isDiff={true} />}>
                                      <Route index element={<Navigate to="details" />} />
                                      <Route path={`details`} element={<IssueDetails isDiff={true} />} />
                                      <Route path={`reachabilities`} element={<IssueReachability />} />
                                      <Route path={`remediation-plan`} element={<IssuesRemediation isDiff={true} />} />
                                      <Route path={`risky-code-files`} element={<CodeViewer />} />
                                      <Route path={`code-injection`} element={<CodeViewer />} />
                                    </Route>
                                  </Route>
                                  <Route path={`dependencies`} element={<DependenciesTable showColumnVisibility={true} isDiff={true} />} />
                                  <Route path={`remediation`} element={<Remediation isDiff={true} />} />
                                  <Route path={`dependencies/:depId/branch/:branchUuid`} element={<DependenciesDetails isDiff={true} />}>
                                    <Route index element={<Navigate to="issues" />} />
                                    <Route path={`issues`} element={<IssuesTable showColumnVisibility={true} showSearch={true} isDiff={true} />}>
                                      <Route index element={<Navigate to="all" />} />
                                      <Route path={`all`} element={<IssuesTable showColumnVisibility={true} showSearch={true} />} />
                                    </Route>
                                    <Route path={`repositories`} element={<RepositoriesList showColumnVisibility={true} />} />
                                    <Route path={`dependencies-files`} element={<DependencyFilesTable isDiff={true} />} />
                                  </Route>
                                </Route>
                              </Route>
                            </Route>
                          </Route>

                          <Route path={'*'} element={<PageNotFound />} />
                        </Route>
                      </Route>
                    </SentryRoutes>
                  </ErrorHandler>
                </SocketProvider>
              </ParagonProvider>
            </IntegratedProvider>
            <ReactQueryDevtools position={'bottom-right'} initialIsOpen={false} />
          </QueryClientProvider>
        </ScrollToTop>
      </BrowserRouter>
    </AuthProvider>
  );
};
