import { CopyClipboard, InfoLabel } from '@Components';
import { FixAvailableDotStyled, ISastIssue } from '../../IssuesTable1stParty';
import {
  ExternalLinkStyled,
  MetadataCategoryTitleStyled,
  MetadataContainerStyled,
  MetadataKeyContainerStyled,
  MetadataKeyTitleStyled,
  MetadataLinkStyled,
  MetadataOverflowTextStyled,
  MetadataSectionContainerStyled,
  SummaryContainerStyled,
  SummaryTitleStyled,
} from './styled';
import { faArrowUpRightFromSquare, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { Flex } from '@Styles';
import { DATE_TIME_LONG_FORMAT, firstLetterToUpperCase } from '@Utils';
import { format } from 'date-fns';
import { useSastIssueFile } from '../../IssueDrawer/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SastIssueDetailsMetadata = ({ issue }: { issue: ISastIssue }) => {
  const { data: scmFileMeta } = useSastIssueFile(issue.uuid);
  const { metadata: sastIssueMetadata } = issue;
  const topCWE25Key = Object.keys(sastIssueMetadata).find((key) => key.includes('top25'));
  const isTopCWE25 = !!sastIssueMetadata[topCWE25Key];
  const isFixed = issue.isFixed < 1;
  return (
    <MetadataContainerStyled>
      <SummaryContainerStyled>
        <SummaryTitleStyled>Summary</SummaryTitleStyled>
        <span>{issue.description}</span>
      </SummaryContainerStyled>
      <Flex gap={4}>
        <MetadataKeyContainerStyled $bordered $padding>
          <MetadataKeyTitleStyled>First seen</MetadataKeyTitleStyled>
          <span>{format(new Date(issue.createdAt), DATE_TIME_LONG_FORMAT)}</span>
        </MetadataKeyContainerStyled>
        <MetadataKeyContainerStyled>
          <MetadataKeyTitleStyled>Last seen</MetadataKeyTitleStyled>
          <span>{format(new Date(issue.lastDiscovered), DATE_TIME_LONG_FORMAT)}</span>
        </MetadataKeyContainerStyled>
      </Flex>
      <Flex>
        <MetadataSectionContainerStyled $boredered>
          <MetadataCategoryTitleStyled>Detection:</MetadataCategoryTitleStyled>
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Repository</MetadataKeyTitleStyled>
            <span>{issue.repositoryName}</span>
          </MetadataKeyContainerStyled>
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Path</MetadataKeyTitleStyled>
            <span>{issue.path}</span>
            <ExternalLinkStyled href={scmFileMeta?.url} target="_blank" rel="noreferrer">
              <span>
                Line: #{issue.lineStart} <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </span>
            </ExternalLinkStyled>
          </MetadataKeyContainerStyled>
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Subcategory</MetadataKeyTitleStyled>
            <span>{sastIssueMetadata?.subcategory.join(', ')}</span>
          </MetadataKeyContainerStyled>
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Confidence</MetadataKeyTitleStyled>
            <div>
              <InfoLabel capitalize type={sastIssueMetadata?.confidence?.toLowerCase()}>
                {sastIssueMetadata?.confidence?.toLowerCase()}
              </InfoLabel>
            </div>
          </MetadataKeyContainerStyled>
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Impact</MetadataKeyTitleStyled>
            <div>
              <InfoLabel capitalize type={sastIssueMetadata?.impact?.toLowerCase()}>
                {sastIssueMetadata?.impact?.toLowerCase()}
              </InfoLabel>
            </div>
          </MetadataKeyContainerStyled>
          {!!issue.weaknesses?.length && (
            <MetadataKeyContainerStyled>
              <MetadataKeyTitleStyled>CWE</MetadataKeyTitleStyled>
              <span>{issue.weaknesses?.map((weakness) => weakness?.name).join(', ')}</span>
            </MetadataKeyContainerStyled>
          )}
          {!!isTopCWE25 &&
            Object.entries(sastIssueMetadata)
              .filter(([key, value]) => key.includes('top25'))
              .map(([key, value], index) => (
                <MetadataKeyContainerStyled key={`top25-${key}-${value}`}>
                  <MetadataKeyTitleStyled>{key}</MetadataKeyTitleStyled>
                  <Flex gap={1} alignItems="center">
                    <FixAvailableDotStyled size="2xs" $status="success" icon={faCircle} />
                    <span>{firstLetterToUpperCase((value as string).toString())}</span>
                  </Flex>
                </MetadataKeyContainerStyled>
              ))}
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Fix Available</MetadataKeyTitleStyled>
            <Flex gap={1} alignItems="center">
              <FixAvailableDotStyled size="2xs" $status={isFixed ? 'success' : 'error'} icon={faCircle} />
              <span>{isFixed ? 'True' : 'False'}</span>
            </Flex>
          </MetadataKeyContainerStyled>
          {sastIssueMetadata.owasp && (
            <MetadataKeyContainerStyled>
              <MetadataKeyTitleStyled>OWASP</MetadataKeyTitleStyled>
              {sastIssueMetadata.owasp.map((owasp, index) => (
                <span key={`owasp-${owasp}-${index}`}>{owasp}</span>
              ))}
            </MetadataKeyContainerStyled>
          )}
        </MetadataSectionContainerStyled>
        <MetadataSectionContainerStyled $maxWidth="46%">
          {sastIssueMetadata.references && (
            <MetadataKeyContainerStyled>
              <MetadataCategoryTitleStyled>References:</MetadataCategoryTitleStyled>
              {sastIssueMetadata.references.map((reference, index) => (
                <MetadataLinkStyled key={`reference-${reference}-${index}`} $isLast={index === sastIssueMetadata.references.length - 1} to={reference}>
                  {reference}
                </MetadataLinkStyled>
              ))}
            </MetadataKeyContainerStyled>
          )}

          <MetadataCategoryTitleStyled>Meta Data:</MetadataCategoryTitleStyled>
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Branch</MetadataKeyTitleStyled>
            <MetadataOverflowTextStyled>{issue.branchName}</MetadataOverflowTextStyled>
          </MetadataKeyContainerStyled>
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Commit Hash</MetadataKeyTitleStyled>
            <Flex gap={1}>
              <CopyClipboard name="Commit sha" copyText={issue.commit} />
              <MetadataOverflowTextStyled> {issue.commit}</MetadataOverflowTextStyled>
            </Flex>
          </MetadataKeyContainerStyled>
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Fingerprint</MetadataKeyTitleStyled>

            <Flex gap={1}>
              <CopyClipboard name="Issue fingerprint" copyText={issue.fingerprint} />
              <MetadataOverflowTextStyled>{issue.fingerprint}</MetadataOverflowTextStyled>
            </Flex>
          </MetadataKeyContainerStyled>
          <MetadataKeyContainerStyled>
            <MetadataKeyTitleStyled>Technology</MetadataKeyTitleStyled>
            <span>{firstLetterToUpperCase(sastIssueMetadata?.technology[0])}</span>
          </MetadataKeyContainerStyled>
        </MetadataSectionContainerStyled>
      </Flex>
    </MetadataContainerStyled>
  );
};
