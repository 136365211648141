import styled from 'styled-components';

export const TitleWrapper = styled.p`
  font-size: ${({ theme }) => theme.text.size['body1']};
  color: ${({ theme }) => theme.colors.indigo[900]};
  font-weight: ${({ theme }) => theme.text.size};
  grid-column: 2;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
