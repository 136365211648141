import { useTheme } from 'styled-components';
import { isEmpty } from '@Utils';

import { DashboardCardStyled } from '../styled';
import { useIssuesByCodeLanguages } from '../hooks';
import { getIssuesPerCodeLanguagesOptions } from '../config';
import { formatRiskiestResponseSeries, sortIssuesPerCodeLanguages } from '../utils';
import { Chart } from './Chart';
import { useFilter } from 'core/Filter';
import { useNavigate } from 'react-router-dom';
import { supportedLanguagesTransformer } from '../../../Utils/language/supportedLanguagesTransformer';

const defaultSeries = [{ data: [] }];

export const IssuesPerCodeLanguages = () => {
  const theme = useTheme();
  const { appliedFilters } = useFilter();
  const navigate = useNavigate();
  const { data, isLoading } = useIssuesByCodeLanguages({ filter: appliedFilters });

  const sortedData = !isEmpty(data) ? sortIssuesPerCodeLanguages(data) : {};

  const series = data ? formatRiskiestResponseSeries(sortedData, theme) : defaultSeries;
  const categories = data ? Object.keys(sortedData).map(supportedLanguagesTransformer) : [];

  const options = getIssuesPerCodeLanguagesOptions({ theme, categories, navigate });

  return (
    <DashboardCardStyled $maxHeight="410px" $paddingBottom={theme.spacing[1]}>
      <Chart isLoading={isLoading} options={options} series={series} type="bar" width={'100%'} height={'360px'} isInteractiveLabels />
    </DashboardCardStyled>
  );
};
