import { EDependencyOrigin } from '@APITypes';
import { EScmType } from '@blindspot/common/types/bff/scm';
import React from 'react';
import styled from 'styled-components';

export enum ESign {
  npm = 'npm',
  python = 'python',
  pypi = 'pypi',
  mvn = 'maven',
  amazon = 'amazon',
  github = 'github',
  gitlab = 'gitlab',
  google = 'google',
  java = 'java',
  c = 'c',
  csharp = 'csharp',
  typescript = 'typescript',
  javascript = 'javascript',
  html = 'html',
  go = 'go',
  jupyter_notebook = 'jupyter_notebook',
  blindspot = 'blindspot',
  slack = 'slack',
  bitbucket = 'bitbucket',
  jenkins = 'jenkins',
  conan = 'conan',
  cplusplus = 'cplusplus',
  kotlin = 'kotlin',
  swift = 'swift',
  antlr = 'antlr',
  blade = 'blade',
  css = 'css',
  docker = 'docker',
  groovy = 'groovy',
  mustache = 'mustache',
  php = 'php',
  powershell = 'powershell',
  r = 'r',
  shell = 'shell',
  nuget = 'nuget',
  unknown = 'unknown',
  hcl = 'hcl',
  lua = 'lua',
  azureDevops = 'azure_devops',
  jira = 'jira',
  json = 'json',
  yaml = 'yaml',
}

const signs: Record<ESign, string> = {
  npm: require('@Images/sign-npm.svg'),
  python: require('@Images/sign-python.svg'),
  pypi: require('@Images/sign-python.svg'),
  maven: require('@Images/sign-maven.svg'),
  amazon: require('@Images/sign-amazon.svg'),
  github: require('@Images/sign-github.svg'),
  gitlab: require('@Images/sign-gitlab.svg'),
  google: require('@Images/sign-google.svg'),
  java: require('@Images/sign-java.svg'),
  html: require('@Images/sign-html.svg'),
  c: require('@Images/sign-c.svg'),
  csharp: require('@Images/sign-csharp.svg'),
  typescript: require('@Images/sign-typescript.svg'),
  javascript: require('@Images/sign-javascript.svg'),
  go: require('@Images/sign-go.svg'),
  jupyter_notebook: require('@Images/sign-jupyter_notebook.svg'),
  blindspot: require('@Images/sign-blindspot.svg'),
  slack: require('@Images/sign-slack.svg'),
  bitbucket: require('@Images/sign-bitbucket.svg'),
  jenkins: require('@Images/sign-jenkins.svg'),
  swift: require('@Images/sign-swift.svg'),
  kotlin: require('@Images/sign-kotlin.svg'),
  conan: require('@Images/sign-conan.svg'),
  cplusplus: require('@Images/sign-cplusplus.svg'),
  antlr: require('@Images/sign-antlr.svg'),
  blade: require('@Images/sign-blade.svg'),
  css: require('@Images/sign-css.svg'),
  docker: require('@Images/sign-docker.svg'),
  groovy: require('@Images/sign-groovy.svg'),
  mustache: require('@Images/sign-mustache.svg'),
  php: require('@Images/sign-php.svg'),
  powershell: require('@Images/sign-powershell.svg'),
  r: require('@Images/sign-r.svg'),
  shell: require('@Images/sign-shell.svg'),
  nuget: require('@Images/sign-nuget.svg'),
  unknown: require('@Images/sign-unknown.svg'),
  hcl: require('@Images/sign-hcl.svg'),
  lua: require('@Images/sign-lua.svg'),
  azure_devops: require('@Images/sign-azure-devops.svg'),
  jira: require('@Images/sign-jira.svg'),
  json: require('@Images/sign-json.svg'),
  yaml: require('@Images/sign-yaml.svg'),
};

interface IProps {
  name: ESign | EDependencyOrigin | EScmType;
  size?: 'default' | 'small' | 'semi-small' | 'big' | 'full' | 'very-big';
  top?: string;
}

const SignRenderStyled = styled.span<{ $mode?: IProps['size']; $top?: string }>`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
  top: ${({ theme, $top }) => $top || theme.spacing['0.5']};

  ${({ $mode }) => {
    switch ($mode) {
      case 'full':
        return `    
        width: 100%;
        height: 100%;`;
      case 'big':
        return `
        width: 32px;
        height: 32px;`;
      case 'very-big':
        return `
        width: 50px
        height: 50px`;
      case 'small':
        return `
        width: 17px;
        height: 17px`;
      case 'semi-small':
        return `
        width: 20px;
        height: 20px`;
      default:
        return `
        width: 24px;
        height: 24px`;
    }
  }}
`;

export const SignRender: React.FC<IProps> = ({ name, size, top }) => {
  const unknown = signs.unknown as string;

  return (
    <SignRenderStyled
      data-sign-render={name}
      title={name}
      $top={top}
      $mode={size}
      style={{
        backgroundImage: `url(${signs[name?.toLowerCase() as ESign] || unknown})`,
      }}
    />
  );
};
