import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const DetailsDescriptionWrapperStyled = styled(Grid)(
  ({ theme }) => css`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${theme.spacing[1.5]};
    align-items: baseline;
  `,
);
