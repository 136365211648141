import { useParams } from 'react-router-dom';

import { DotSeparator } from 'Layouts/components/DotSeparator';
import { IPullRequestListCardProps } from '../interfaces';
import { PullRequestCardStyled, PullRequestNameStyled, PullRequestCardInfoStyled } from './styled';
import { PullRequestStatusIcon, BranchName, Opened, Updated, PullRequestId } from './components';

export const PullRequestCard = (props: IPullRequestListCardProps) => {
  const { repoId } = useParams();
  const { data, key } = props;
  const { uuid, name, createdAt, userName, updatedAt, headBranch, displayId, status } = data;

  const linkTo = `/discovery/repositories/${repoId}/pull-requests/${uuid}/issues/all`;

  return (
    <PullRequestCardStyled to={linkTo} state={{ pullRequest: data }} key={key}>
      <PullRequestStatusIcon status={status} />
      <div>
        <PullRequestNameStyled>{name}</PullRequestNameStyled>
        <PullRequestCardInfoStyled>
          <PullRequestId displayId={displayId} />
          <DotSeparator />
          <BranchName branchName={headBranch.name} />
          <DotSeparator />
          <Opened createdAt={createdAt} userName={userName} />
          <DotSeparator />
          <Updated updatedAt={updatedAt} />
        </PullRequestCardInfoStyled>
      </div>
    </PullRequestCardStyled>
  );
};
