import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler, UseControllerReturn } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { ENotificationType, toastService } from '@Notification';
import { CopyButton } from '@Components';
import { Input, FormControlError } from '@FormElements';
import { FormStyled, SubmitButtonStyled, TokenDetailsWrapperStyled, TokenDetailsRowStyled, TokenDetailsRowValueStyled, TokenDetailsHelperTextStyled, FormInputWrapperStyled } from './styled';
import { createOrganizationTokenValidationRules } from './validation';
import { ICreateOrganizationTokenResponse, ICreateOrganizationTokenFormData } from './interfaces';
import { useOrganizationTokenCreate } from './hooks';

export const CreateOrganizationTokenForm: React.FC = () => {
  const queryClient = useQueryClient();
  const [tokenDetails, setTokenDetails] = useState<ICreateOrganizationTokenResponse | null>(null);
  const { mutate: createOrganizationToken, isLoading: isCreatingToken } = useOrganizationTokenCreate();
  const defaultValues = { name: '' };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICreateOrganizationTokenFormData>({
    defaultValues,
    shouldFocusError: false,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  const onSubmit: SubmitHandler<ICreateOrganizationTokenFormData> = async ({ name }: ICreateOrganizationTokenFormData) => {
    createOrganizationToken(name.trim(), {
      onSuccess: ({ data }) => {
        setTokenDetails(data);
        queryClient.invalidateQueries('organizationTokens');
      },
      onError: () => {
        toastService({
          header: 'An error occurred while creating token',
          type: ENotificationType.ERROR,
        });
      },
    });
  };

  const renderTokenNameInput = ({ field: { onChange, onBlur, name, value }, fieldState: { error } }: UseControllerReturn<ICreateOrganizationTokenFormData, 'name'>) => (
    <FormInputWrapperStyled>
      <Input name={name} placeholder="Name*" value={value} onChange={onChange} onBlur={onBlur} $error={!!error} />
      <FormControlError>{error?.message}</FormControlError>
    </FormInputWrapperStyled>
  );

  const submitDisabled = !!Object.keys(errors).length || isCreatingToken;

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {tokenDetails ? (
        <TokenDetailsWrapperStyled>
          <TokenDetailsRowStyled>
            <b>Client ID:</b>
            <TokenDetailsRowValueStyled>
              {tokenDetails.clientId}
              <CopyButton data={tokenDetails.clientId} />
            </TokenDetailsRowValueStyled>
          </TokenDetailsRowStyled>
          <TokenDetailsRowStyled>
            <b>Client secret:</b>
            <TokenDetailsRowValueStyled>
              {tokenDetails.clientSecret}
              <CopyButton data={tokenDetails.clientSecret} />
            </TokenDetailsRowValueStyled>
          </TokenDetailsRowStyled>
          <TokenDetailsHelperTextStyled>Make sure you save it - you won't be able to access it again.</TokenDetailsHelperTextStyled>
        </TokenDetailsWrapperStyled>
      ) : (
        <>
          <Controller name="name" control={control} rules={createOrganizationTokenValidationRules.name} render={renderTokenNameInput} />
          <SubmitButtonStyled type="submit" disabled={submitDisabled}>
            Create token
          </SubmitButtonStyled>
        </>
      )}
    </FormStyled>
  );
};
