import styled from 'styled-components';

export const ExternalLinkStyled = styled.a`
  font-family: ${({ theme }) => theme.text.fontFamilySecondary};
  color: ${({ theme }) => theme.colors.zinc[500]};
  font-size: ${({ theme }) => theme.text.size.caption};
  margin-top: ${({ theme }) => theme.spacing[1]};

  &:hover {
    text-decoration: underline;
  }
`;
