import { format, parseISO } from 'date-fns';

import { DATE_DATE_FORMAT } from '@Utils';
import { CardInfoTextStyled } from '../styled';
import { IOpenedProps } from '../../interfaces';

export const Opened = ({ createdAt, userName }: IOpenedProps) => {
  if (!createdAt) {
    return null;
  }
  const parsedDate = parseISO(createdAt);
  const formattedDate = format(parsedDate, DATE_DATE_FORMAT);
  return (
    <CardInfoTextStyled>
      Opened: {formattedDate} by {userName}
    </CardInfoTextStyled>
  );
};
