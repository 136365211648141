import { useMutation } from 'react-query';
import { createReportApi } from './api';
import { ICreateReportDto } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useCreateReport = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(
    async (payload: ICreateReportDto) => {
      if (isAuthenticated && !isSessionLoading) {
        return await createReportApi(payload, sessionToken);
      }
    },
    {
      retry: false,
    },
  );
};
