import styled, { css } from 'styled-components';
import { Grid } from '@Styles';

export const CodeViewerStyled = styled(Grid)<{ $singleColumn?: boolean }>(
  ({ $singleColumn }) => css`
    grid-template-columns: ${$singleColumn ? '1fr' : '270px 1px 1fr'};
    height: 100%;
    min-height: 444px;
    overflow: hidden;
  `,
);
