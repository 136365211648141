import { ModalStandard } from '@Modal';
import { useParams } from 'react-router-dom';
import { useMagicLink } from '../../hooks/useMagicLink.hook';
import { CopyClipboard, Loader } from '@Components';
import { CopyToClipboardContainerStyled, ShareModalLinkContainerStyled, ShareModalTextStyled } from './styles';
import { Flex } from '@Styles';
import { Button, ButtonModes } from '@FormElements';
import { copyToClipboard } from '@Utils';

type IRepositoryShareModalProps = {
  onCopy?: () => void;
};

export const RepositoryShareModal: React.FC<IRepositoryShareModalProps> = ({ onCopy }) => {
  const { repoId: repoUuid } = useParams();
  const { data: magicLink, isLoading: isLoadingMagicLink, isError } = useMagicLink(repoUuid);

  const handleCopyAndClose = () => {
    copyToClipboard(magicLink, 'Share link');
    onCopy && onCopy();
  };

  return (
    <ModalStandard
      title="Share repository"
      content={
        <div>
          <ShareModalTextStyled>Copy the link and share it</ShareModalTextStyled>
          <ShareModalLinkContainerStyled>
            {isError && <span>Failed to generate magic link</span>}
            {isLoadingMagicLink ? (
              <Loader />
            ) : (
              <span>
                {magicLink}{' '}
                <CopyToClipboardContainerStyled>
                  <CopyClipboard copyText={magicLink} name={'Share link'}>
                    <div />
                  </CopyClipboard>
                </CopyToClipboardContainerStyled>
              </span>
            )}
          </ShareModalLinkContainerStyled>
          <Flex justifyContent="space-between">
            <ShareModalTextStyled>Note: this link will be open only to users with work domain</ShareModalTextStyled>
            <Button styleMode={ButtonModes.Monitor} onClick={handleCopyAndClose}>
              Copy & Close
            </Button>
          </Flex>
        </div>
      }
    />
  );
};
