import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ControlInputContainer = styled(Flex)<{ $focused: boolean; $error: boolean }>`
  flex: 1;
  flex-wrap: wrap;
  border-radius: 8px;
  padding: ${({ theme: { spacing } }) => `${spacing['0.5']} ${spacing['1']}`};
  border: ${({ theme }) => css`1px solid ${theme.colors.zinc[200]}`};
  gap: ${({ theme }) => theme.spacing[0.5]};
  transition: border 200ms;

  ${({ $focused, $error, theme }) => {
    if ($error) {
      return css`
        border: 1px solid ${theme.colors.rose[600]};
      `;
    }

    if ($focused) {
      return css`
        border: 1px solid ${theme.colors.indigo[500]};
      `;
    }
  }}
`;
