import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const DetailsRightSectionStyled = styled(Grid)<{ $right?: boolean; $minRows?: boolean }>(
  ({ theme, $right, $minRows }) => css`
    grid-template-columns: repeat(6, auto);
    grid-auto-rows: ${$minRows ? 'min-content' : theme.spacing[3]};
    align-items: center;

    ${$right &&
    css`
      grid-column: 2;
    `}
  `,
);
