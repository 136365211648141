import React from 'react';
import { ModalStandard } from '@Modal';
import { CreateTicketForm } from './CreateTicketForm';
import { ICreateTicketModalProps } from './types';
import { CreateTicketModalStyled } from './styled';

export const CreateTicketModal: React.FC<ICreateTicketModalProps> = ({ ticketManager, baseIssueUuid, repoUuid }) => {
  return (
    <ModalStandard
      title="Create ticket"
      content={
        <CreateTicketModalStyled>
          <CreateTicketForm ticketManager={ticketManager} baseIssueUuid={baseIssueUuid} repoUuid={repoUuid} />
        </CreateTicketModalStyled>
      }
    />
  );
};
