import styled from 'styled-components';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ESeverityLevel } from '@APITypes';
import React from 'react';
import { CircularProgressbarWrapperProps } from 'react-circular-progressbar/dist/types';

export const ScoreCircle = styled(CircularProgressbarWithChildren)<
  CircularProgressbarWrapperProps & {
    $severity: ESeverityLevel;
    children?: React.ReactNode;
    $small?: boolean;
  }
>`
  transform: scale(-1, 1);

  + div {
    font-weight: ${({ theme }) => theme.text.weight.bold};
    font-size: ${({ theme, $small }) => theme.text.size[$small ? 'body1' : 'h3']};
    line-height: 24px;
    ${({ $small }) => $small && 'letter-spacing: 0.15px'};
  }

  .CircularProgressbar-trail {
    stroke-linecap: butt;
    stroke: ${({ theme }) => theme.colors.zinc['100']};
  }

  .CircularProgressbar-path {
    stroke-linecap: butt;
    stroke: ${({ $severity, theme }) => {
      if ($severity === ESeverityLevel.CRITICAL) return theme.colors.rose['600'];
      if ($severity === ESeverityLevel.HIGH) return theme.colors.orange['500'];
      if ($severity === ESeverityLevel.MEDIUM) return theme.colors.zinc['400'];
      if ($severity === ESeverityLevel.LOW) return theme.colors.zinc['300'];
    }};
  }
`;
