import { Flex } from '@Styles';
import styled from 'styled-components';

export const FailedPullRequestItemStyled = styled(Flex)`
  margin-left: ${({ theme }) => theme.spacing[3]};
  padding-top: ${({ theme }) => theme.spacing[1.5]};
  align-items: center;
  &:last-child {
    margin-bottom: ${({ theme }) => theme.spacing[2.5]};
  }
`;
