import { ApexOptions } from 'apexcharts';
import { DefaultTheme } from 'styled-components';

import { formatNumberWithCommas, pageTools } from '@Utils';
import { chartAnimationOptions } from './chartAnimationOptions';
import { getChartTitleOptions } from './getChartTitleOptions';
import { getChartLegendOptions } from './getChartLegendOptions';
import { NavigateFunction } from 'react-router-dom';
import { TIssuesSeverities } from '../interfaces';

export const getRiskiestRepositoryOptions = ({
  theme,
  categories,
  data,
  navigate,
}: {
  theme: DefaultTheme;
  categories: string[];
  data: Record<string, TIssuesSeverities>;
  navigate: NavigateFunction;
}): ApexOptions => {
  return {
    ...getChartTitleOptions(theme, 'Riskiest Repository', true),
    ...getChartLegendOptions(theme, true),
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      animations: chartAnimationOptions,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      events: {
        xAxisLabelClick: function (event, chartContext) {
          const repoObject = data[event.target.textContent];

          navigate(`${pageTools.discovery.path}/repositories/${repoObject.uuid}/issues/all`);
        },
      },
    },
    colors: [theme.colors.zinc[200], theme.colors.zinc[400], theme.colors.orange[500], theme.colors.rose[600]],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 12,
        borderRadius: 6,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      y: {
        formatter: (value) => formatNumberWithCommas(value),
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        style: {
          colors: theme.colors.zinc[700],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: 'Repositories',
        style: {
          color: theme.colors.zinc[400],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: 'right',
        style: {
          colors: theme.colors.zinc[400],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
      title: {
        text: 'Issues',
        style: {
          color: theme.colors.zinc[400],
          fontSize: theme.text.size.overline,
          fontFamily: theme.text.fontFamily,
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    grid: {
      show: false,
    },
  };
};
