import { axiosConfig as axios } from '@Utils';

export const getReportDownloadLinkApi = async (reportId: string, token: string) => {
  const response = await axios.get(`report/${reportId}/link`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text',
    },
  });

  return response?.data;
};
