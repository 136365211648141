export const isSomeEntitiesProtected = (discoverySummary: Record<any, any>): boolean => {
  let monitoredEntities = false;
  Object.keys(discoverySummary).forEach((key) => {
    if (discoverySummary[key]?.monitoring?.monitored > 0) {
      monitoredEntities = true;
    }
  });

  return monitoredEntities;
};
