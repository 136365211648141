import { ISpacing } from 'Styles/types';
import styled from 'styled-components';

export type FlexProps = {
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  flexDirection?: 'row' | 'column';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch';
  gap?: keyof ISpacing;
};

export const Flex = styled.div<FlexProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  align-items: ${(props) => props.alignItems || 'unset'};
  flex-wrap: ${(props) => props.flexWrap || 'unset'};
  align-content: ${(props) => props.alignContent || 'unset'};
  ${(props) => props.gap && `gap: ${props.theme.spacing[props.gap]};`}
`;
