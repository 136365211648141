import React from 'react';
import { IRemediationIssuesProps } from './types';
import { RemediationIssuesStyled, RemediationIssuesTitleStyled, RemediationLabelsStyled } from './styled';
import { RemediationModal } from './RemediatonModal';
import { RemediationIssueLabels } from './RemediationIssueLabels';
import { useModal } from '@Modal';

export const RemediationIssues: React.FC<IRemediationIssuesProps> = ({ repoId, depFileUuid, issues, effortLevel }) => {
  const { openModal } = useModal();
  const issuesCount = Object.values(issues || []).reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const onClickIssues = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    openModal(<RemediationModal repoId={repoId} depFileUuid={depFileUuid} effortLevel={effortLevel} />);
  };

  return (
    <RemediationIssuesStyled>
      <RemediationIssuesTitleStyled onClick={onClickIssues}>{issuesCount} fixes available</RemediationIssuesTitleStyled>
      <RemediationLabelsStyled>
        <RemediationIssueLabels issues={issues} />
      </RemediationLabelsStyled>
    </RemediationIssuesStyled>
  );
};
