import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Loader, Tooltip } from '@Components';
import { FormControlError } from '@FormElements';
import { Form, FormActions, FormButton, NameInput, Name, NameWrapper, NameInputContainer, LoaderWrapper, Email } from './styled';
import { IUserNameFormFields } from './interfaces';
import { displayNameValidationRules } from './validation';
import { useUserUpdate } from './hooks';
import { useUser } from '../../hooks';
import { useQueryClient } from 'react-query';

export const UserNameInput: React.FC = () => {
  const queryClient = useQueryClient();
  const { isLoading, data: user } = useUser();
  const { mutate: updateUser, isLoading: isUpdating } = useUserUpdate();

  const [isEdit, setIsEdit] = useState(false);
  const defaultValues = { displayName: user?.displayName };
  const {
    register,
    handleSubmit,
    setFocus,
    reset,
    watch,
    formState: { isDirty, isValid, errors },
  } = useForm<IUserNameFormFields>({ defaultValues });

  useEffect(() => {
    if (isEdit) {
      setFocus('displayName');
      reset(defaultValues);
    }
  }, [setFocus, isEdit]);

  useEffect(() => {
    const shouldResetForm = !isLoading && !isUpdating;
    if (shouldResetForm) {
      reset(defaultValues);
    }
  }, [isLoading, isUpdating]);

  const openEditMode = () => setIsEdit(true);

  const closeEditMode = () => {
    setIsEdit(false);
    reset(defaultValues);
  };

  const onSubmit: SubmitHandler<IUserNameFormFields> = (formData: IUserNameFormFields) => {
    updateUser(formData, {
      onSuccess() {
        queryClient.invalidateQueries('userData');
      },
    });
    closeEditMode();
  };

  const submitDisabled = !isDirty || !isValid || !!Object.keys(errors).length;
  const displayNameValue = watch('displayName');
  const error = errors.displayName?.message;

  if (isLoading || isUpdating) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      {isEdit ? (
        <div>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <NameInputContainer data-value={displayNameValue}>
              <NameInput placeholder="Name" size={4} {...register('displayName', displayNameValidationRules)} />
            </NameInputContainer>
            <FormActions>
              <FormButton type="submit" $variant="contained" disabled={submitDisabled}>
                Save
              </FormButton>
              <FormButton $variant="text" onClick={closeEditMode}>
                Cancel
              </FormButton>
            </FormActions>
          </Form>
          {error ? <FormControlError>{error}</FormControlError> : <Email>{user.email}</Email>}
        </div>
      ) : (
        <NameWrapper>
          <Tooltip tooltip="Click to edit">
            <Name onClick={openEditMode}>{user.displayName}</Name>
          </Tooltip>
          <Email>{user.email}</Email>
        </NameWrapper>
      )}
    </>
  );
};
