import React, { useMemo } from 'react';
import { IssueIgnoredContainerStyled } from './styled/IssueIgnoredContainer.styled';
import { faInfoCircle, faShieldSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@Components';
import { useIssueDetails } from 'Layouts/IssuesPage/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { DATE_TIME_LONG_FORMAT } from '@Utils';
import { EIssueSourceType } from '@blindspot/common/types/bff';
import { useSastIssueIgnoreMetadata } from 'Layouts/IssuesPage/components/IssueDrawer/hooks/useSastIssueIgnoreMetadata.hook';

export const IssueIgnored: React.FC<{ isIgnored: boolean; issueSourceType: EIssueSourceType }> = ({ isIgnored, issueSourceType }) => {
  const { baseIssueUuid, pullRequestUuid, branchUuid } = useParams();
  const [searchParams, setUrlSearchParams] = useSearchParams();

  const isDiff = !!pullRequestUuid;
  const diff = useMemo(() => (isDiff ? { branchId: branchUuid } : undefined), [isDiff, branchUuid]);

  const { data: issueDetails } = useIssueDetails({ issueUuid: baseIssueUuid, diff }, issueSourceType === EIssueSourceType.THIRD_PARTY);
  const { data: sastIssueIgnoreMeta } = useSastIssueIgnoreMetadata(searchParams.get('openIssue'), issueSourceType === EIssueSourceType.FIRST_PARTY);
  if (!isIgnored) return null;

  let ignoredBy, reason, ignoredAt, humanReadableDate;

  if (issueSourceType === EIssueSourceType.THIRD_PARTY) {
    ignoredBy = sastIssueIgnoreMeta?.ignoredBy;
    reason = issueDetails.ignoredIssue.reason;
    ignoredAt = issueDetails.ignoredIssue.ignoredAt;
  }

  if (issueSourceType === EIssueSourceType.FIRST_PARTY) {
    ignoredAt = sastIssueIgnoreMeta?.createdAt;
    reason = sastIssueIgnoreMeta?.reason;
    ignoredBy = sastIssueIgnoreMeta?.ignoredBy;
  }

  if (ignoredAt) {
    humanReadableDate = format(new Date(ignoredAt), DATE_TIME_LONG_FORMAT);
  }

  return (
    <>
      <IssueIgnoredContainerStyled>
        <FontAwesomeIcon icon={faShieldSlash} />
        <p>Issue Ignored</p>
        <Tooltip
          tooltip={
            <>
              <p>Ignored by {ignoredBy}.</p>
              <p>At {humanReadableDate}.</p>
              <p>Reason: {reason}. </p>
            </>
          }
          placement="top"
          noWrap={true}
          fullWidth={false}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </IssueIgnoredContainerStyled>
    </>
  );
};
