import { useQuery } from 'react-query';
import { fetchDependencies } from '../api';
import { IDependency, IPage } from '@APITypes';
import { IApiDependenciesParams } from '../types';
import { useSession } from '@descope/react-sdk';

export const useDependencies = (params: IApiDependenciesParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['dependencies', params],
    queryFn: async () => {
      return await fetchDependencies(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IPage<IDependency>>(queryOptions);
};
