import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter } from '@fortawesome/pro-light-svg-icons';
import { ControlButton, useReactFlow } from 'reactflow';

import { IExpandGraphButtonProps } from './types';

export const ExpandGraphButton = ({ onClick, isExpanded }: IExpandGraphButtonProps) => {
  const reactFlow = useReactFlow();

  const onClickHandler = () => {
    onClick();
    setTimeout(() => reactFlow.fitView(), 50);
  };

  return (
    <ControlButton onClick={onClickHandler}>
      <FontAwesomeIcon icon={isExpanded ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} />
    </ControlButton>
  );
};
