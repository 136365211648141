import styled from 'styled-components';

export const SliderMultiTrackStyled = styled.div<{ $index?: number }>`
  position: relative;
  height: ${({ theme }) => theme.spacing['0.5']};
  border-radius: ${({ theme }) => theme.spacing['1.5']};
  background: ${({ theme, $index }) => ($index === 1 ? theme.colors.indigo[500] : theme.colors.zinc[300])};
  opacity: ${({ theme, $index }) => ($index === 1 ? 1 : 0.38)};
  height: 6px;
`;
