import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const CodeColumnsStyled = styled(Grid)<{ $singleColumn?: boolean }>(
  ({ theme, $singleColumn }) => css`
    grid-template-columns: repeat(${$singleColumn ? 1 : 2}, 1fr);
    grid-column-gap: ${theme.spacing[2]};
    height: 100%;
  `,
);
