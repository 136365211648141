import { Grid } from '@Styles';
import styled from 'styled-components';

export const TableTwoCells = styled(Grid)`
  grid-template-columns: 72px auto 1fr;
  grid-gap: ${({ theme }) => theme.spacing[1]};

  span {
    white-space: nowrap;
  }
`;
