import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ProgressBarItemWrapper = styled(Flex)(
  ({ theme }) => css`
    justify-content: space-between;
    align-items: center;
    gap: ${theme.spacing[2.5]};
    padding: ${theme.spacing[1]} ${theme.spacing[2]} ${theme.spacing[1.5]} ${theme.spacing[1.5]};
    background: ${theme.colors.white};
    transition: background 0.3s ease;
    border-radius: 11px;

    &:hover {
      background: ${theme.colors.indigo[100]};
    }
  `,
);
