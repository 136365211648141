import { axiosConfig as axios } from '@Utils';
import { IBitbucketIntegrationToken } from '../hooks/types';

export const getIntegrationTokens = async (provider: string, token: string): Promise<IBitbucketIntegrationToken[]> => {
  const response = await axios.get(`scm/integrate/${provider}/token`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response?.data;
};
