import styled, { css } from 'styled-components';

export const IssueDescriptionStyled = styled.div<{ $isLongDescription: boolean }>`
  overflow: hidden;

  ${({ $isLongDescription }) =>
    $isLongDescription &&
    css`
      max-height: 84px;
    `};
  h2,
  h3 {
    font-size: ${({ theme }) => theme.text.size.body1};
    color: ${({ theme }) => theme.colors.zinc[800]};
    margin-bottom: ${({ theme }) => theme.spacing[0.5]};
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }
  code {
    font-size: ${({ theme }) => theme.text.size.body2};
    color: ${({ theme }) => theme.colors.zinc[800]};
    background-color: ${({ theme }) => theme.colors.zinc[100]};
    padding: ${({ theme }) => theme.spacing[0.5]};
    border-radius: ${({ theme }) => theme.spacing[0.5]};
    font-style: italic;
  }
`;
