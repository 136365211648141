import styled from 'styled-components';

export const ShareModalLinkContainerStyled = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing[2]};
  border: 1px solid ${({ theme }) => theme.colors.zinc[200]};
  background-color: ${({ theme }) => theme.colors.zinc[200]};
  border-radius: 4px;
  margin: ${({ theme }) => theme.spacing[2]} 0;
  font-family: ${({ theme }) => theme.text.fontFamilySecondary};
  font-size: ${({ theme }) => theme.text.size.caption};
`;
