import { useQuery } from 'react-query';
import { IReport } from '../types';
import { fetchReportsApi } from './api';
import { useSession } from '@descope/react-sdk';

export const useReports = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['reports'],
    queryFn: async () => {
      return await fetchReportsApi(sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IReport[]>(queryOptions);
};
