import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { rgba } from 'polished';
import { ERepositoryScore } from '@blindspot/common/types/bff/repositories';

const handleContainerGradientColor = (score: ERepositoryScore, theme: DefaultTheme) => {
  switch (score) {
    case ERepositoryScore.A:
      return theme.colors.green[600];
    case ERepositoryScore.B:
      return theme.colors.cyan[500];
    case ERepositoryScore.C:
      return theme.colors.orange[300];
    case ERepositoryScore.D:
      return theme.colors.orange[500];
    case ERepositoryScore.F:
      return theme.colors.rose[600];
    default:
      return theme.colors.green[600];
  }
};

export const DetailsContainerStyled = styled.div<{ $hasScore?: boolean; $score?: ERepositoryScore }>(
  ({ theme, $hasScore, $score }) => css`
    padding: ${$hasScore ? '34px' : theme.spacing[2.5]} ${theme.spacing[3]} ${theme.spacing[2.5]} ${theme.spacing[3]};
    border-radius: 20px;
    background: ${$hasScore
      ? `linear-gradient(0deg, ${rgba(handleContainerGradientColor($score, theme), 0.0)} 62.71%, ${rgba(handleContainerGradientColor($score, theme), 0.1)} 103.18%), ${theme.colors.white};`
      : theme.colors.white};
    box-shadow: ${theme.shadows[1]};
    margin-top: ${$hasScore ? '18px' : '0px'};
    position: relative;
  `,
);
