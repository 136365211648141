import styled from 'styled-components';

export const FiltersTitleStyled = styled.h2`
  padding-bottom: ${({ theme }) => theme.spacing['3']};
  font-size: ${({ theme }) => theme.text.size.body1};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
