import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const DetailsTableSectionStyled = styled(Grid)(
  ({ theme }) => css`
    grid-template-columns: auto auto 1fr;
    grid-template-rows: repeat(2, 1fr) auto;
    grid-gap: ${theme.spacing[1.5]};
    line-height: 24px;
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.extraLight};
  `,
);
