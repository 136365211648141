import React from 'react';
import { ProgressBarItemWrapper, ProgressBarItemSecondaryText, ProgressBarItemPrimaryText, CheckIcon, CircularProgress } from '../styled';
import { IDiscoveryItem } from '../interfaces';

export const DiscoveryItem: React.FC<IDiscoveryItem> = ({ discovery }) => {
  const completed = discovery.seen === discovery.total;

  return (
    <ProgressBarItemWrapper>
      <div>
        <ProgressBarItemPrimaryText>Discovery {completed && 'complete'}</ProgressBarItemPrimaryText>
        <ProgressBarItemSecondaryText>
          {discovery.seen} of {discovery.total} {discovery.total > 1 ? 'repositories' : 'repository'}
        </ProgressBarItemSecondaryText>
      </div>
      {completed ? <CheckIcon /> : <CircularProgress value={discovery.seen} maxValue={discovery.total} />}
    </ProgressBarItemWrapper>
  );
};
