import { Flex } from '@Styles';
import styled from 'styled-components';

export const IssueRecommendationContainerStyled = styled(Flex)`
  height: 24px;
  padding: ${({ theme }) => `2px ${theme.spacing[1]}`};
  gap: ${({ theme }) => theme.spacing[0.5]};
  border: 1px solid ${({ theme }) => theme.colors.zinc[200]};
  border-radius: ${({ theme }) => theme.spacing[0.5]};
`;
