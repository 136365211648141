import { useMutation } from 'react-query';
import { setIntegration } from '../api';
import { IUseIntegration } from './types';
import { useSession } from '@descope/react-sdk';

export const useIntegration = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation({
    mutationFn: async ({ provider, data }: IUseIntegration) => {
      if (isAuthenticated && !isSessionLoading) {
        return setIntegration(provider, data, sessionToken);
      }
    },
    retry: false,
  });
};
