import styled from 'styled-components';

export const ScoreDetailsLabel = styled.span<{ $underline?: boolean }>`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  font-size: ${({ theme }) => theme.text.size.h3};
  line-height: 28px;
  letter-spacing: 0.15px;

  ${({ $underline }) =>
    $underline &&
    `
    &:hover {
      text-decoration: underline;
    }
  `}
`;
