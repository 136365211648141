import { Flex } from '@Styles';
import styled from 'styled-components';

export const DrawerTitleStyled = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.text.size.h2};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  line-height: ${({ theme }) => theme.spacing[3.5]};
  letter-spacing: 0.15px;
`;
