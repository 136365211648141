import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ScanConfigSetHeaderStyled = styled(Flex)(
  ({ theme }) => css`
    justify-content: space-between;
    flex-direction: column;
    gap: ${theme.spacing[0.5]};
    width: 100%;
  `,
);
