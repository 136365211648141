import React from 'react';
import { SummaryBlock } from './components';
import { useFilter } from 'core/Filter';
import { useDependenciesSummary, useIssuesSeveritySummary, useRepositoriesSummary } from '@Hooks';
import { isEmpty } from '@Utils';
import { SummariesContainer } from './styled';

export const Summaries: React.FC = () => {
  const { appliedFilters } = useFilter();
  const { data: repositoriesSummary } = useRepositoriesSummary({ filter: appliedFilters });
  const { data: dependenciesSummary } = useDependenciesSummary({ filter: appliedFilters });
  const { data: issuesSummary } = useIssuesSeveritySummary({ filter: appliedFilters });

  if (isEmpty(repositoriesSummary) && isEmpty(dependenciesSummary) && isEmpty(issuesSummary)) {
    return <SummariesContainer />;
  }

  return (
    <SummariesContainer>
      <SummaryBlock name={'Repositories'} monitoring={repositoriesSummary} issues={issuesSummary} />
      <SummaryBlock name={'Dependencies'} monitoring={dependenciesSummary} issues={issuesSummary} />
    </SummariesContainer>
  );
};
