import React from 'react';
import { Tooltip } from '@Components';
import { Button, ButtonModes } from '@FormElements';
import { IMonitorButtonProps } from './interfaces';
import { isAnyLanguageSupported } from '@Utils';
import { ModalProvider, useModal } from '@Modal';
import { MonitoringPreferencesModal } from './MonitoringPreferences';

const MonitorButtonBase: React.FC<IMonitorButtonProps> = ({ buttonText = 'Monitor', languages, onClick, styleMode = ButtonModes.Monitor, repositoryId }) => {
  const monitoringPreferencesDisabled = localStorage.getItem('monitoringPreferencesDisabled');
  const { openModal } = useModal();

  const onClickMonitor = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();

    if (monitoringPreferencesDisabled) {
      onClick();
    } else {
      openModal(<MonitoringPreferencesModal repositoryId={repositoryId} onMonitor={onClick} />);
    }
  };

  const disabled = !isAnyLanguageSupported(languages);

  if (disabled) {
    return (
      <Tooltip tooltip={"Language isn't supported"}>
        <Button disabled={true} styleMode={ButtonModes.TransparentAdditional}>
          {buttonText}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button styleMode={styleMode} onClick={onClickMonitor}>
      {buttonText}
    </Button>
  );
};

export const MonitorButton: React.FC<IMonitorButtonProps> = (props) => (
  <ModalProvider>
    <MonitorButtonBase {...props} />
  </ModalProvider>
);
