import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NotificationIcon = styled(FontAwesomeIcon)(
  ({ theme }) => css`
    width: 18px;
    height: 18px;
    color: ${theme.colors.indigo[400]};
  `,
);
