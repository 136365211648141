import { useQuery, UseQueryOptions } from 'react-query';
import { fetchUserRoles } from '../api';
import { IUserRole } from '@APITypes';
import { useSession } from '@descope/react-sdk';

export const useUserRoles = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions: UseQueryOptions<IUserRole[]> = {
    queryKey: ['userRoles'],
    enabled: isAuthenticated && !isSessionLoading,
    queryFn: async () => {
      if (isAuthenticated) {
        return await fetchUserRoles(sessionToken);
      }
    },
    refetchOnWindowFocus: false,
  };
  return useQuery<IUserRole[]>(queryOptions);
};
