import { axiosConfig as axios } from '@Utils';

export const deleteScanConfigSet = async (scanConfigSetUuid: string, token: string) => {
  const response = await axios.delete(`configs/scan/${scanConfigSetUuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
