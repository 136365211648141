import { FailedPullRequestsAlertStyled, FailedPullRequestsRemainderStyled, MessageStyled } from './styled';
import { IRemainderProps } from './interface';

export const Remainder = ({ icon, action, message, children }: IRemainderProps) => {
  return (
    <FailedPullRequestsRemainderStyled>
      <FailedPullRequestsAlertStyled>
        {icon}
        <MessageStyled>{message}</MessageStyled>
        {action}
      </FailedPullRequestsAlertStyled>
      {children}
    </FailedPullRequestsRemainderStyled>
  );
};
