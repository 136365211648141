import { PreferencesSectionTitle } from '../../styled';
import { ScanCoverageText } from '../ScanCoverageText';
import { PreferencesScanCoverageStyled } from './styled';

export const PreferencesScanCoverage = () => {
  return (
    <div>
      <PreferencesSectionTitle $indented>Coverage and Frequency</PreferencesSectionTitle>
      <PreferencesScanCoverageStyled>
        <ScanCoverageText />
      </PreferencesScanCoverageStyled>
    </div>
  );
};
