export enum DevLanguage {
  JAVA = 'java',
  TYPESCRIPT = 'typescript',
  PYTHON = 'python',
  JAVASCRIPT = 'javascript',
  JAVASCRIPT_TYPESCRIPT = 'javascript/typescript', // Special case for dependencies since theree is no way to identify them
  C = 'c',
  CSHARP = 'csharp',
  CPLUSPLUS = 'cplusplus',
  SWIFT = 'swift',
  KOTLIN = 'kotlin',
  HTML = 'html',
  GO = 'go',
  JUPYTER_NOTEBOOK = 'jupyter_notebook',
  NODEJS = 'nodejs',
  ANTLR = 'antlr',
  BLADE = 'blade',
  CSS = 'css',
  DOCKER = 'docker',
  GROOVY = 'groovy',
  MUSTACHE = 'mustache',
  PHP = 'php',
  R = 'r',
  POWERSHELL = 'powershell',
  SHELL = 'shell',
  UNSUPPORTED = 'unsupported',
  NOT_AVAILABLE = 'N/A', // Special case for repositories with no code
  LUA = 'lua',
  HCL = 'hcl',
}
