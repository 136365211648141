import { format, parseISO } from 'date-fns';

import { DATE_TIME_LONG_FORMAT } from '@Utils';
import { CardInfoTextStyled } from '../styled';
import { IUpdatedProps } from '../interface';

export const Updated = ({ updatedAt }: IUpdatedProps) => {
  if (!updatedAt) {
    return null;
  }
  const parsedDate = parseISO(updatedAt);
  const formattedDate = format(parsedDate, DATE_TIME_LONG_FORMAT);
  return <CardInfoTextStyled>{`Updated: ${formattedDate}`}</CardInfoTextStyled>;
};
