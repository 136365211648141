import { DataNode, FieldDataNode } from 'rc-tree/lib/interface';

export const convertToTree = (node: FieldDataNode<DataNode>): DataNode[] => {
  return Object.values(node).map((child) => {
    const convertedChild = { ...child };
    if (Object.keys(child.children).length > 0) {
      convertedChild.children = convertToTree(child.children);
    } else {
      delete convertedChild.children;
    }

    return convertedChild;
  });
};
