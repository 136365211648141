import { ClearIndicatorProps } from 'react-select';
import { ClearIndicatorStyled, ClearTextStyled } from './styled';
import { useFilter } from 'core/Filter';

export const ClearIndicator = (props: ClearIndicatorProps<unknown[], true>) => {
  const { clearFilters } = useFilter();
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  const onMouseDownHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    clearFilters();
    restInnerProps.onMouseDown(event);
  };

  const onTouchEndHandler = (event: React.TouchEvent<HTMLDivElement>) => {
    clearFilters();
    restInnerProps.onTouchEnd(event);
  };

  return (
    <div {...restInnerProps} ref={ref} onMouseDown={onMouseDownHandler} onTouchEnd={onTouchEndHandler}>
      <ClearIndicatorStyled>
        <ClearTextStyled>{'Clear all'}</ClearTextStyled>
      </ClearIndicatorStyled>
    </div>
  );
};
