import styled, { css } from 'styled-components';

export const IssuesContainerStyled = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing[2.5]} ${theme.spacing[3]} ${theme.spacing[2.5]} ${theme.spacing[3]};
    border-top-left-radius: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadows[1]};
  `,
);
