import styled, { css } from 'styled-components';

export const Caption = styled.p(
  ({ theme }) => css`
    font-size: ${theme.text.size.caption};
    font-weight: ${theme.text.weight.regular};
    letter-spacing: 0.2px;
    line-height: 20px;
  `,
);
