import styled from 'styled-components';
import { Flex } from '@Styles';

export const MetricHeader = styled(Flex)`
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.body2};
  line-height: 24px;
  letter-spacing: 0.1px;
  padding: ${({ theme }) => `0 ${theme.spacing['1']}`};
`;
