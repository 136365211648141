import { useEffect, useState } from 'react';
import { IDynamicProgressBar } from '../interfaces';

export const useDynamicProgressBar = (initialProgressBar: IDynamicProgressBar = { total: 0, seen: 0 }) => {
  const [progressBar, setProgressBar] = useState<IDynamicProgressBar>(initialProgressBar);

  const updateProgressBar = (update: IDynamicProgressBar) => {
    setProgressBar((prevProgressBar) => {
      const { interval: prevInterval, seen } = prevProgressBar;
      const newProgressBar = { ...prevProgressBar };

      if (update.total !== prevProgressBar.total) {
        newProgressBar.total = update.total;
      }

      let diff = update.seen - seen;

      if (diff > 0) {
        clearInterval(prevInterval);

        newProgressBar.interval = setInterval(() => {
          setProgressBar((prevState) => {
            return {
              ...prevState,
              seen: prevState.seen + 1,
              total: update.total,
            };
          });

          diff--;

          if (diff === 0) {
            clearInterval(newProgressBar.interval);
          }
        }, 500);
      }

      return newProgressBar;
    });
  };

  useEffect(() => {
    return () => {
      clearInterval(progressBar.interval);
    };
  }, []);

  return { data: progressBar, updateProgressBar };
};
