import styled from 'styled-components';
import { Flex } from '@Styles';

export const IssueReachabilityTooltipStyled = styled(Flex)`
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing['0.5']};
  gap: ${({ theme }) => theme.spacing['0.5']};
  max-width: 247px;
  white-space: normal;
`;
