import React from 'react';
import { FilterClearButtonStyled, FilterListStyled, FiltersContainerStyled, FiltersTitleStyled } from '../styled';
import { IFilterProps } from '../types';
import { FilterItem } from './FilterItem';
import { useFilter } from '../context';

export const Filters: React.FC<IFilterProps> = ({ filters }) => {
  const { clearFilters } = useFilter();

  return (
    <FiltersContainerStyled>
      <FiltersTitleStyled>
        <span>Filters</span>
        <FilterClearButtonStyled onClick={clearFilters}>Clear all</FilterClearButtonStyled>
      </FiltersTitleStyled>
      <FilterListStyled>
        {filters.map((filter) => (
          <FilterItem key={filter.id} filter={filter} />
        ))}
      </FilterListStyled>
    </FiltersContainerStyled>
  );
};
