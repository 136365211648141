import React, { useContext } from 'react';
import Switch from 'react-switch';
import { colors } from '@Styles';
import { ICheckAllSwitchProps } from '../CheckAllSwitch/interfaces';
import { SwitchDescriptionCaptionStyled, SwitchDescriptionStyled, SwitchLabelStyled } from '../CheckAllSwitch/styled';
import { useUserOrganization } from '@Hooks';
import { IntegrationStatusEnum } from 'Layouts/IntegrationsPage/types';
import { EScmAppType } from '@blindspot/common/types/bff';
import { PRCommentsContainerStyled } from '../PRCommentsSwitch/styled';
import { IntegrationsContext } from '@Utils';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const RequiredPrCheckSwitch: React.FC<ICheckAllSwitchProps> = ({ value, onChange }) => {
  const { integrations } = useContext(IntegrationsContext);
  const { data: userOrg } = useUserOrganization();
  const isDisabled = userOrg?.scm.find((scm) => scm.status === IntegrationStatusEnum.ACTIVE).appType !== EScmAppType.MANAGEMENT;
  const activeIntegration = integrations?.find((integration) => integration.status === IntegrationStatusEnum.ACTIVE);
  const isGithub = activeIntegration?.type === EScmType.GITHUB;

  if (!isGithub) {
    return null;
  }

  return (
    <PRCommentsContainerStyled $disabled={isDisabled}>
      <SwitchLabelStyled $disabled={isDisabled} htmlFor="isRequired">
        Pull Request check required
        <Switch
          disabled={isDisabled}
          id="isRequired"
          checked={value}
          onChange={onChange}
          height={14}
          width={26}
          uncheckedIcon={false}
          checkedIcon={false}
          offColor={colors.zinc[400]}
          onColor={colors.indigo[500]}
        />
      </SwitchLabelStyled>
      <SwitchDescriptionStyled>Require status checks to pass before merging</SwitchDescriptionStyled>
      {isDisabled && <SwitchDescriptionCaptionStyled>* Status checks is not possible when connecting with read-only access to your SCM</SwitchDescriptionCaptionStyled>}
    </PRCommentsContainerStyled>
  );
};
