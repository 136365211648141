import { EWorkflowEvents } from '@APITypes';
import { SocketEventHandlerType, useSocketBind } from '@Utils';

export const usePostProcessing = (commonEventHandler?: SocketEventHandlerType, eventHandler?: SocketEventHandlerType, vulnerabilitiesEventHandler?: SocketEventHandlerType): void => {
  useSocketBind(EWorkflowEvents.POST_PROCESSING, (data) => {
    if (commonEventHandler) {
      commonEventHandler(data);
    }
    if (eventHandler) {
      eventHandler(data);
    }
  });
  useSocketBind(EWorkflowEvents.VULNERABILITIES_POST_PROCESSING, (data) => {
    if (commonEventHandler) {
      commonEventHandler(data);
    }
    if (vulnerabilitiesEventHandler) {
      vulnerabilitiesEventHandler(data);
    }
  });
};
