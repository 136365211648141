import React from 'react';

import { Flex } from '@Styles';
import { MaliciousPackageSummary } from './styled';
import { IMaliciousPackagesDetailsProps } from './interfaces';

import { MaliciousPackagesLinkLabel } from './MaliciousPackageLinkLabel';

export const MaliciousPackagesDetails: React.FC<IMaliciousPackagesDetailsProps> = ({ issue }) => {
  const maliciousPackage = issue.maliciousPackage;

  const parsedSummary = maliciousPackage.description.replace('\n---\n_-= Per source details. Do not edit below this line.=-_\n\n', '');

  return (
    <>
      <Flex>
        <MaliciousPackageSummary markdown={parsedSummary}></MaliciousPackageSummary>
      </Flex>
      <MaliciousPackagesLinkLabel maliciousPackage={maliciousPackage} />
    </>
  );
};
