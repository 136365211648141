export const isPrimitivesArraysEqual = <T>(arr1: T[] = [], arr2: T[] = []): boolean => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false;
  }

  if (arr1 === arr2) {
    return true;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  return sortedArr1.every((element, index) => element === sortedArr2?.[index]);
};
