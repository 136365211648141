import { IUserUpdate } from '@APITypes';
import { axiosConfig as axios } from '@Utils';

export const updateUser = async (data: IUserUpdate, token: string) => {
  return axios.put(`user/me`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
