import React from 'react';
import { ScanConfigDescriptionStyled } from '../styled';
import { IScanConfigPRChecksValue } from '@blindspot/common/types/bff/configs';

export const PRChecksConfigSummary: React.FC<{ configValue?: IScanConfigPRChecksValue }> = ({ configValue }) => {
  if (!configValue) {
    return null;
  }

  return (
    <ScanConfigDescriptionStyled>
      Pull-Requests: {configValue.checkAll ? 'On' : 'Off'}
      {configValue.checkAll && `, Comments: ${configValue.PRComments ? 'On' : 'Off'}`}
    </ScanConfigDescriptionStyled>
  );
};
