import styled, { css } from 'styled-components';

export const ProgressBarDescription = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.zinc[800]};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 24px;
    letter-spacing: 0.2px;
    margin: ${theme.spacing[1]} 0 ${theme.spacing[1]} ${theme.spacing[0.5]};
  `,
);
