import { DasboardCardLinkStyled } from 'Layouts/DashboardPage/components/DashboardCardLink';
import styled from 'styled-components';

export const FunnelHeaderText = styled(DasboardCardLinkStyled)`
  margin-bottom: 18px;
  max-width: 60px;
  z-index: 1;
  font-size: ${({ theme }) => theme.text.size.h3};
  font-family: ${({ theme }) => theme.text.fontFamily};
  user-select: none;
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;
