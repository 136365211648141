import React, { useEffect, useRef } from 'react';
import { CheckboxIconStyled, CheckboxInputStyled, CheckboxLabelStyled } from './styled/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ITableCheckbox } from './types';

export const TableCheckbox: React.FC<ITableCheckbox> = ({ indeterminate, text, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <CheckboxLabelStyled>
      <CheckboxInputStyled ref={ref} {...rest} />
      <CheckboxIconStyled $isChecked={rest.checked}>
        <FontAwesomeIcon icon={faCheck} />
      </CheckboxIconStyled>
      {text && <span>{text}</span>}
    </CheckboxLabelStyled>
  );
};
