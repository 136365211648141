import styled from 'styled-components';

export const NameInputContainer = styled.div`
  display: inline-grid;
  position: relative;
  align-content: center;
  padding: ${({ theme: { spacing } }) => `0 ${spacing['1']} 0 ${spacing['0.5']}`};
  height: 26px;
  border: 1px solid ${({ theme }) => theme.colors.indigo[500]};
  border-radius: 5px;

  &::after {
    content: attr(data-value) ${'" "'};
    visibility: hidden;
    white-space: pre-wrap;
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
  }
`;
