import { Controller } from 'react-hook-form';

import { InputLabel } from '@Components/Form';
import { DropdownSelect } from '@Components';
import { TextFieldStyled, WorkspaceSelectStyled } from '../styled';
import { IWorkspaceSelectProps } from '../types';

export const WorkspaceSelect = ({ control, organizationSelectOptions }: IWorkspaceSelectProps) => {
  return (
    <WorkspaceSelectStyled>
      <Controller
        name="organizationId"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextFieldStyled>
            <InputLabel htmlFor={'organizationId'}>Workspace</InputLabel>
            <DropdownSelect
              name="organization"
              placeholder="Select Workspace"
              value={value}
              options={organizationSelectOptions}
              error={error?.message}
              onBlur={onBlur}
              onChange={(value) => {
                onChange(value);
              }}
            />
          </TextFieldStyled>
        )}
      />
    </WorkspaceSelectStyled>
  );
};
