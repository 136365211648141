import { ContentBlock } from '@Styles';
import styled from 'styled-components';

export const ReachabilityNoDataStyled = styled(ContentBlock)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  padding: ${({ theme }) => theme.spacing[4]} 0;
  height: 100%;
  color: ${({ theme }) => theme.colors.zinc[800]};
  font-size: ${({ theme }) => theme.text.size.body1};
  font-weight: ${({ theme }) => theme.text.weight.regular};
`;
