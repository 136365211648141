import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const CodeBlockStyled = styled(Flex)(
  ({ theme }) => css`
    flex-direction: column;
    gap: ${theme.spacing[1.5]};
    overflow: auto;
  `,
);
