import { useMutation } from 'react-query';
import { updateIntegrationOrganization } from './api';
import { useSession } from '@descope/react-sdk';

export const useUpdateIntegrationOrganization = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(
    async (organizationId: string) => {
      if (isAuthenticated && !isSessionLoading) {
        return updateIntegrationOrganization(organizationId, sessionToken);
      }
    },
    {
      retry: false,
    },
  );
};
