import React from 'react';
import { LogoImage, LogoWrapper } from './styled';
import LogoWhite from '@Images/logo-white.svg';

export const Logo: React.FC = () => {
  return (
    <LogoWrapper>
      <LogoImage src={LogoWhite} alt="Myrror" />
    </LogoWrapper>
  );
};
