import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const SelectableListItemStyled = styled(Grid)(
  ({ theme }) => css`
    grid-template-columns: 32px 1fr;
    grid-column-gap: ${theme.spacing[1.5]};
    align-items: center;
    margin-left: -${theme.spacing[0.5]};
  `,
);
