import styled from 'styled-components';

export const HeaderTitleStyled = styled.p`
  color: ${({ theme }) => `${theme.colors.zinc['800']}`};
  font-size: ${({ theme }) => `${theme.text.size['h3']}`};
  font-weight: ${({ theme }) => `${theme.text.weight.bold}`};
  color: ${({ theme }) => `${theme.colors.zinc['800']}`};
  text-transform: capitalize;
  user-select: none;
`;
