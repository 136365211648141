import { createQueryString, queryStringToObject, useQuery } from '@Utils';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IQueryParams } from './types';

export const useQueryParams = (params: IQueryParams) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = useQuery();

  const queryParams: IQueryParams = useMemo(() => queryStringToObject(search, params), [params, search]);

  const setQueryParams = (newQueryParams: IQueryParams) => {
    navigate({ search: createQueryString(newQueryParams) }, { replace: true });
  };

  return {
    queryParams: {
      ...queryParams,
      ...(queryParams.page && { page: Number(query.get('page')) }),
      ...(queryParams.size && { size: Number(query.get('size')) }),
    },
    setQueryParams,
  };
};
