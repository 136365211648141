import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const RemediationRepositoryTitleStyled = styled(Link)`
  display: flex;
  align-self: baseline;
  padding: ${({ theme }) => `${theme.spacing['3']} ${theme.spacing['3']} 0 ${theme.spacing['3']}`};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.zinc['500']};
  cursor: pointer;

  :hover,
  :visited,
  :active {
    color: ${({ theme }) => theme.colors.zinc['700']};
    text-decoration: underline;
  }
`;
