import styled, { css } from 'styled-components';

export const DividerStyled = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 1px;
    margin: ${theme.spacing[3]} 0 ${theme.spacing[1.5]} 0;
    background-color: ${theme.colors.zinc[200]};
  `,
);
