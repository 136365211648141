import { axiosConfig as axios } from '@Utils';
import { IMonitorRepositoryDto } from '../interfaces';

export const repositoryMonitor = async (data: IMonitorRepositoryDto, token: string) => {
  return axios.post(
    `repositories/monitor`,
    {
      repository: {
        scmOriginId: data.scmOriginId,
        scmOriginType: data.scmOriginType,
        branch: data.branch,
      },
      scanConfigSetUuid: data.scanConfigSetUuid,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
