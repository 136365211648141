import styled from 'styled-components';
import { Flex } from '@Styles';
import { Fullname } from './Fullname.styled';

export const FullnameCell = styled(Flex)`
  gap: ${({ theme }) => theme.spacing['0.5']};
  width: 114px;

  &:hover ${Fullname} {
    text-decoration: underline;
  }
`;
