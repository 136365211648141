import { useMutation } from 'react-query';
import { IApiPullRequestParams } from '../types';
import { pullRequestRerunCli } from '../api';
import { useSession } from '@descope/react-sdk';

export const usePullRequestRerunCli = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(
    async (payload: IApiPullRequestParams) => {
      if (isAuthenticated && !isSessionLoading) {
        return await pullRequestRerunCli(payload, sessionToken);
      }
    },
    {
      retry: false,
    },
  );
};
