import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const DasboardCardLinkStyled = styled(Link)`
  font-size: ${({ theme }) => theme.text.size.h3};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.black};
  text-decoration: underline;
  &:visited {
    color: ${({ theme }) => theme.colors.black};
  }
`;
