import styled, { css } from 'styled-components';

export const InputContainerStyled = styled.div(
  ({ theme }) => css`
    display: inline-grid;
    position: relative;
    align-content: center;
    padding: 0 ${theme.spacing[1]} 0 ${theme.spacing[0.5]};
    border: 1px solid ${theme.colors.indigo[500]};
    border-radius: 5px;

    &::after {
      content: attr(data-value) ${'" "'};
      visibility: hidden;
      white-space: pre-wrap;
      width: auto;
      min-width: 1em;
      grid-area: 1 / 2;
      margin: 0;
      resize: none;
      background: none;
      appearance: none;
      border: none;
    }
  `,
);
