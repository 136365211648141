import styled from 'styled-components';

export const LinkStyled = styled.a`
  font-size: ${({ theme }) => theme.text.size.body2};
  line-height: 28px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.colors.indigo['500']};

  :hover {
    text-decoration: underline;
  }
`;
