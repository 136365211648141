import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import styled, { css } from 'styled-components';

export const EditIconStyled = styled(FontAwesomeIcon).attrs({ icon: faPen })(
  ({ theme }) => css`
    vertical-align: middle;
    font-size: initial;
    cursor: pointer;
    padding: 0 ${theme.spacing[1]} 0 ${theme.spacing[1.5]};
  `,
);
