import { Outlet } from 'react-router-dom';
import { useCanAccess } from '../hooks';
import { Permission } from '@Permissions';

export const AccessGuard = ({ permissions }: { permissions: Permission[] }) => {
  const canAccessSettingsPage = useCanAccess(permissions);
  if (!canAccessSettingsPage) {
    return <div>Not Authorized</div>;
  }

  return <Outlet />;
};
