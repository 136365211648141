import Shape3 from '@Images/login/shape3.png';
import styled from 'styled-components';

export const Shape3Styled = styled.img.attrs({
  src: Shape3,
})`
  position: absolute;
  top: 0px;
  right: 0px;
`;
