import styled from 'styled-components';

export const CheckboxLabelStyled = styled.label`
  position: relative;
  display: flex;
  padding: ${({ theme }) => theme.spacing[0.5]};
  cursor: pointer;

  span {
    padding-left: ${({ theme }) => theme.spacing[0.5]};
  }
`;
