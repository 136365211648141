import React, { useEffect } from 'react';
import { useFloating, useDismiss, useRole, useClick, useInteractions, useId, FloatingFocusManager, FloatingPortal, useTransitionStyles } from '@floating-ui/react';
import { ButtonModes } from '@FormElements';
import { useCreateReport } from 'Layouts/Exports/components/ExportButton/hooks';
import { ENotificationType, toastService } from '@Notification';
import { EExportType } from 'Layouts/Exports/components/ExportButton/interfaces';
import { Loader } from '@Components';
import { ELoaderSize } from 'Layouts/components/Loader/types';
import { colors } from '@Styles';
import { useItemSelection } from '../../../contexts';
import { CreateSbomDialogStyled, CreateSbomDialogTextStyled, CreateSbomDialogButtonStyled, CreateSbomDialogCancelButtonStyled, CreateSbomDialogActionsStyled } from './styled/dialog';
import { ICreateSbomDialogProps } from './interfaces';

export const CreateSbomDialog: React.FC<ICreateSbomDialogProps> = ({ onClose }) => {
  const {
    enableSelection: enableRepositoriesSelection,
    disableSelection: disableRepositoriesSelection,
    isAllItemsSelected: isAllRepositoriesSelected,
    includedItems: includedRepositories,
    excludedItems: excludedRepositories,
    selectedItemsCount: selectedRepositoriesCount,
  } = useItemSelection();
  const { mutate: createReport, isLoading } = useCreateReport();

  useEffect(() => {
    enableRepositoriesSelection();

    return () => {
      disableRepositoriesSelection();
    };
  }, []);

  const { refs, context } = useFloating({
    strategy: 'fixed',
    open: true,
    onOpenChange: onClose,
  });

  const click = useClick(context, { enabled: false });
  const role = useRole(context);
  const dismiss = useDismiss(context, { escapeKey: true, outsidePress: false });
  const { styles: transitionStyle } = useTransitionStyles(context, {
    duration: 500,
  });

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  const headingId = useId();
  const descriptionId = useId();

  const onDialogClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const onCreateSbom = async () => {
    const reportPayload = {
      type: EExportType.SBOM,
      filters: {
        repositories: {
          all: isAllRepositoriesSelected,
          included: Object.keys(includedRepositories),
          excluded: Object.keys(excludedRepositories),
        },
      },
    };
    await createReport(reportPayload);
    toastService({
      header: 'Preparing export',
      message: 'We’ll let you know once your export file is ready for download',
      type: ENotificationType.SUCCESS,
    });
    onClose();
  };

  return (
    <FloatingPortal>
      <FloatingFocusManager context={context}>
        <CreateSbomDialogStyled ref={refs.setFloating} aria-labelledby={headingId} aria-describedby={descriptionId} onClick={onDialogClick} style={transitionStyle} {...getFloatingProps()}>
          <CreateSbomDialogTextStyled>{selectedRepositoriesCount ? `${selectedRepositoriesCount} Selected` : 'Start selecting repositories to create SBOM'}</CreateSbomDialogTextStyled>
          <CreateSbomDialogActionsStyled>
            <CreateSbomDialogCancelButtonStyled onClick={onClose}>Cancel</CreateSbomDialogCancelButtonStyled>
            {!!selectedRepositoriesCount && (
              <CreateSbomDialogButtonStyled disabled={isLoading} $mode={ButtonModes.ServiceFilled} onClick={onCreateSbom}>
                {isLoading ? <Loader color={colors.white} size={ELoaderSize.SMALL} /> : 'Create SBOM'}
              </CreateSbomDialogButtonStyled>
            )}
          </CreateSbomDialogActionsStyled>
        </CreateSbomDialogStyled>
      </FloatingFocusManager>
    </FloatingPortal>
  );
};
