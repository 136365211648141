import { Tooltip } from '@Components';
import { GrayTextStyled } from '../styled/GrayText.styled';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonitoredStyled } from '../styled/Monitored.styled';

export const UnsupportedLanguage = () => {
  return (
    <GrayTextStyled>
      Unsupported
      <Tooltip tooltip={'Unsupported language'}>
        <MonitoredStyled>
          <FontAwesomeIcon icon={faCircleInfo} size={'lg'} />
        </MonitoredStyled>
      </Tooltip>
    </GrayTextStyled>
  );
};
