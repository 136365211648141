export const inviteTeamMemberValidationRules = {
  emails: {
    required: 'Please fill in an email address',
    pattern: {
      value: /^(?:[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\s*,\s*)?)+$/,
      message: 'Invalid email address',
    },
  },
  role: {
    required: 'Select role',
  },
  loginMethod: {
    required: 'Select login method',
  },
};
