import styled, { css } from 'styled-components';

export const TicketDescriptionStyled = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.zinc[200]};
    border-radius: 8px;
    max-height: 200px;
    overflow-y: scroll;
    padding: ${theme.spacing[1]} ${theme.spacing[1.5]};
  `,
);
