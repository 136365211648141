import { axiosConfig as axios } from '@Utils';
import { PaginationState } from '@tanstack/react-table';

export const fetchRepositoryDependenciesFiles = async (repoId: string, pagination: PaginationState, token: string) => {
  const response = await axios(`repositories/${repoId}/files`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: pagination?.pageIndex + 1,
      take: pagination?.pageSize,
    },
  });
  return response?.data;
};
