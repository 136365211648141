import { useQuery } from 'react-query';
import { IRemediationSummary } from '@APITypes';
import { fetchRemediationSummary } from '../api';
import { IApiRemediationSummaryParams } from '../types';
import { useSession } from '@descope/react-sdk';

export const useRemediationSummary = (params: IApiRemediationSummaryParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['repoRemediationSummary', params],
    queryFn: async () => {
      return await fetchRemediationSummary(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IRemediationSummary>(queryOptions);
};
