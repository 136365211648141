import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ProgressBarHeader = styled(Flex)<{ $expanded: boolean }>(
  ({ theme, $expanded }) => css`
    justify-content: space-between;
    align-items: center;
    padding: ${$expanded ? `${theme.spacing[1.5]} ${theme.spacing[3]} ${theme.spacing[0.5]}` : `${theme.spacing[1]} ${theme.spacing[2]} ${theme.spacing[1]} ${theme.spacing[2]}`};
    transition: padding 0.3s ease;
    cursor: ${$expanded ? 'auto' : 'pointer'};
  `,
);
