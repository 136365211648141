import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { BackButtonStyled } from './styled';

export const BackButton = ({ noSpacing }: { noSpacing?: boolean }) => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <BackButtonStyled onClick={goBack} noSpacing={noSpacing}>
      <FontAwesomeIcon icon={faChevronLeft} />
      <span>Back</span>
    </BackButtonStyled>
  );
};
