import { useContext, useEffect, useId } from 'react';
import { EWorkflowEvents } from '@APITypes';
import { SocketContext, SocketEventHandlerType, useAsyncError } from '@Utils';
import { bindEvent } from '../utils';

export const useUserSocketBind = (eventName: EWorkflowEvents, eventHandler: SocketEventHandlerType): void => {
  const throwError = useAsyncError();
  const { pusher } = useContext(SocketContext);
  const sourceId = useId();

  useEffect(() => {
    if (pusher?.connection?.state !== 'connected') {
      return;
    }
    return bindEvent(eventName, eventHandler, pusher, throwError, sourceId);
  }, [eventHandler, pusher]);
};
