import { ESeverityLevel } from '@APITypes';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ReachabilityIssueStyled = styled(Link)<{ $severity: ESeverityLevel; $isLink: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing['2']} ${theme.spacing['3']}`};
  letter-spacing: 0.15px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 12px rgba(10, 24, 154, 0.05);
  pointer-events: ${({ $isLink }) => ($isLink ? 'auto' : 'none')};

  &:hover,
  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.zinc[900]};
  }

  ${({ theme, $isLink }) =>
    $isLink &&
    css`
      &:hover {
        background: ${theme.colors.zinc[100]};
      }
    `}

  ${({ theme, $severity }) => {
    if ($severity === ESeverityLevel.CRITICAL) {
      return `
        border-left: 5px solid ${theme.colors.rose['600']};
      `;
    }

    if ($severity === ESeverityLevel.HIGH) {
      return `
        border-left: 5px solid ${theme.colors.orange['500']};
      `;
    }

    if ($severity === ESeverityLevel.MEDIUM) {
      return `
        border-left: 5px solid ${theme.colors.zinc['200']};
      `;
    }

    if ($severity === ESeverityLevel.LOW) {
      return `
        border-left: 5px solid ${theme.colors.zinc['100']};
      `;
    }
  }}
`;
