import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { ModalStandard, useModal } from '@Modal';
import { ActionContainer, InputGitLabModalContent, LoaderContainerStyled, ModalContent, TextField } from './styled';
import { Button, ButtonColor, ButtonSize, ButtonVariant, DropdownSelect, Loader } from '@Components';
import { InputLabel } from '@Components/Form';
import { InputGitLabTokenOrganizationModal } from './InputGitLabTokenOrganizationModal';
import { ISelectIntegrationOrganizationFormData } from '../SelectIntegrationOrganizationForm/interfaces';
import { useIntegrationOrganizations, useUpdateIntegrationOrganization } from '../SelectIntegrationOrganizationForm/hooks';
import { Controller, SubmitHandler, UseControllerReturn, useForm } from 'react-hook-form';
import { ENotificationType, toastService } from '@Notification';
import { pageTools } from '@Utils';
import { GitLabModalTitle } from './GitLabModalTitle';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const SelectGitLabOrganizationModal: React.FC = () => {
  const { data: organizations = [], isLoading } = useIntegrationOrganizations(EScmType.GITLAB);
  const { mutate: updateIntegrationOrganization, isLoading: isSendingOrganization } = useUpdateIntegrationOrganization();
  const { closeModal, openModal } = useModal();
  const defaultValues: ISelectIntegrationOrganizationFormData = { organizationId: '' };
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm<ISelectIntegrationOrganizationFormData>({
    defaultValues,
  });

  const onBackClickHandler = () => {
    openModal(<InputGitLabTokenOrganizationModal />);
  };

  const organizationSelectOptions = organizations.map((organization) => {
    return { value: organization.id, name: organization.name };
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  const onSubmit: SubmitHandler<ISelectIntegrationOrganizationFormData> = async ({ organizationId }) => {
    updateIntegrationOrganization(organizationId, {
      onSuccess() {
        closeModal();
        window.location.href = pageTools.dashboard.path;
      },
      onError(error) {
        if (error instanceof AxiosError) {
          toastService({ header: 'Something went wrong', type: ENotificationType.ERROR });
        }
      },
    });
  };

  const renderOrganizationSelect = ({ field: { onChange, onBlur, value }, fieldState: { error } }: UseControllerReturn<ISelectIntegrationOrganizationFormData>) => (
    <DropdownSelect
      name="organization"
      placeholder="Select group"
      value={value}
      options={organizationSelectOptions}
      error={error?.message}
      onBlur={onBlur}
      onChange={(value) => {
        onChange(value);
      }}
    />
  );

  const submitDisabled = !isDirty || !isValid || !!Object.keys(errors).length || isLoading || organizations.length === 0;
  const showLoader = isLoading || organizations.length === 0 || isSendingOrganization;

  return (
    <ModalStandard
      title={<GitLabModalTitle />}
      content={
        <ModalContent as={'form'} onSubmit={handleSubmit(onSubmit)}>
          <InputGitLabModalContent>
            <TextField>
              <InputLabel htmlFor={'organizationId'}>Group</InputLabel>
              <Controller name="organizationId" control={control} render={renderOrganizationSelect} />
            </TextField>
          </InputGitLabModalContent>
          <ActionContainer>
            <Button onClick={onBackClickHandler} color={ButtonColor.ZINC} variant={ButtonVariant.OUTLINED} size={ButtonSize.LARGE}>
              Back
            </Button>
            <Button type="submit" disabled={submitDisabled} variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE}>
              Apply
            </Button>
          </ActionContainer>
          {showLoader && (
            <LoaderContainerStyled>
              <Loader />
            </LoaderContainerStyled>
          )}
        </ModalContent>
      }
    />
  );
};
