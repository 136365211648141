import React from 'react';
import { ScanConfigDescriptionStyled } from '../styled';
import { IScanConfigEmailUpdatesValue } from '@blindspot/common/types/bff/configs';
import { emailUpdatesOptions } from 'Layouts/SettingsPage/components/ScanConfigs/components/ScanConfigDetails/components/EmailUpdatesSettings/constants';
import { isPrimitivesArraysEqual } from '@Utils';

export const EmailUpdatesConfigSummary: React.FC<{ configValue?: IScanConfigEmailUpdatesValue }> = ({ configValue }) => {
  const selectedEmailUpdateOption = emailUpdatesOptions.find((option) => {
    return option.value.enabled === configValue?.enabled && isPrimitivesArraysEqual(option.value.updates, configValue?.updates);
  });

  if (!configValue || !selectedEmailUpdateOption) {
    return null;
  }

  return <ScanConfigDescriptionStyled>Emails: {selectedEmailUpdateOption.label}</ScanConfigDescriptionStyled>;
};
