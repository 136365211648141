import React, { useEffect, useState } from 'react';
import {
  ReachabilityIssueArrowStyled,
  ReachabilityIssueContentStyled,
  ReachabilityIssueInfoItemStyled,
  ReachabilityIssueInfoStyled,
  ReachabilityIssueStyled,
  ReachabilityIssueTitleStyled,
} from './styled';
import { InfoLabel, InfoLabelTypes, UserRender } from '@Components';
import { format } from 'date-fns';
import { DATE_TIME_LONG_FORMAT } from '@Utils';
import { EReachabilityStatus, NotAvailable, Undetermined } from '@APITypes';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { IReachabilityIssueProps } from './types';
import { useParams } from 'react-router-dom';
import { Flex } from '@Styles';

export const ReachabilityIssue: React.FC<IReachabilityIssueProps> = ({ issue, hasAction = true }) => {
  const { baseIssueUuid } = useParams();
  const [isLink, setIsLink] = useState(false);

  useEffect(() => {
    if (hasAction && !issue.fixedAt) {
      setIsLink(true);
    }
  }, [hasAction, issue.fixedAt]);

  return (
    <ReachabilityIssueStyled to={isLink ? `/issues/${baseIssueUuid}/reachabilities/${issue.issueUuid}` : ''} $severity={issue.severity} $isLink={isLink}>
      <ReachabilityIssueContentStyled>
        <Flex gap={1}>
          <InfoLabel type={issue.severity} capitalize>
            {issue.severity}
          </InfoLabel>
          {issue.fixedAt && <InfoLabel type={InfoLabelTypes.Fixed}>Fixed</InfoLabel>}
        </Flex>
        <ReachabilityIssueTitleStyled>{issue.filePath}</ReachabilityIssueTitleStyled>
        <ReachabilityIssueInfoStyled>
          <ReachabilityIssueInfoItemStyled>{`First seen: ${issue.firstSeen ? format(new Date(issue.firstSeen), DATE_TIME_LONG_FORMAT) : NotAvailable}`}</ReachabilityIssueInfoItemStyled>
          <ReachabilityIssueInfoItemStyled $capitalize>{issue.relationship}</ReachabilityIssueInfoItemStyled>
          <ReachabilityIssueInfoItemStyled $capitalize>
            {issue?.reachabilityStatus === EReachabilityStatus.UNDEFINED ? Undetermined : issue?.reachabilityStatus}
          </ReachabilityIssueInfoItemStyled>
          <ReachabilityIssueInfoItemStyled>
            <span>Introduced by:&nbsp;</span>
            <UserRender userName={issue.committedBy || NotAvailable} />
          </ReachabilityIssueInfoItemStyled>
        </ReachabilityIssueInfoStyled>
      </ReachabilityIssueContentStyled>
      {isLink && <ReachabilityIssueArrowStyled icon={faChevronRight} />}
    </ReachabilityIssueStyled>
  );
};
