import { Flex } from '@Styles';
import { faBan, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';

import { ENotificationType, toastService } from '@Notification';
import { Button, ButtonModes } from '@FormElements';
import { usePullRequestAbortScan } from '../../../hooks';
import { useContext } from 'react';
import { IntegrationsContext } from '@Utils';
import { IntegrationStatusEnum } from 'Layouts/IntegrationsPage/types';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const AbortScanButton = ({ disabled }: { disabled: boolean }) => {
  const { repoId, pullRequestUuid } = useParams();
  const queryClient = useQueryClient();
  const { mutate: pullRequestAbortScan, isLoading } = usePullRequestAbortScan();

  const { integrations } = useContext(IntegrationsContext);

  const availableProviders = [EScmType.GITHUB, EScmType.GITLAB, EScmType.BITBUCKET];
  const integration = integrations.find((integration) => integration.status === IntegrationStatusEnum.ACTIVE);

  if (!integration || !availableProviders.includes(integration.type)) {
    return null;
  }

  const invalidatePullRequest = () => {
    queryClient.refetchQueries({ queryKey: ['pullRequest', { repoId, pullRequestUuid }] });
  };

  const onAbortScanClick = () => {
    pullRequestAbortScan(
      { repoId, pullRequestUuid },
      {
        onSuccess: () => {
          invalidatePullRequest();
        },
        onError: (error: AxiosError<{ message?: string }>) => {
          const {
            response: { data },
          } = error;
          const message = data?.message || 'Something went wrong';
          toastService({
            header: 'Abort scan pull request error',
            message,
            type: ENotificationType.ERROR,
          });
        },
      },
    );
  };

  return (
    <Button styleMode={ButtonModes.Monitor} onClick={onAbortScanClick} disabled={isLoading || disabled}>
      <Flex gap={1} alignItems="center">
        Abort scan
        {isLoading ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : <FontAwesomeIcon icon={faBan} />}
      </Flex>
    </Button>
  );
};
