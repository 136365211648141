import styled from 'styled-components';

export const PaginationButtonStyled = styled.button`
  display: inline-block;
  padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[0.5]}`};
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.indigo[900]};
  background: transparent;
  font-size: ${({ theme }) => theme.text.size.body1};
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.indigo[100]};
  }

  &:first-child {
    padding-left: ${({ theme }) => theme.spacing[1]};
    padding-right: ${({ theme }) => theme.spacing[2.5]};
  }

  &:last-child {
    padding-right: ${({ theme }) => theme.spacing[1]};
    padding-left: ${({ theme }) => theme.spacing[2.5]};
  }
`;
