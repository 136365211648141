import { Flex } from '@Styles';
import styled from 'styled-components';

export const Name = styled(Flex)`
  align-items: center;
  cursor: pointer;
  padding: ${({ theme: { spacing } }) => `0 ${spacing['0.5']}`};
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.zinc['800']};
  font-size: ${({ theme }) => theme.text.size.body1};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  line-height: 28px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 600px;
  height: 26px;

  &:hover {
    transition: border 300ms;
    border: 1px solid ${({ theme }) => theme.colors.zinc[600]};
    border-radius: 5px;
  }
`;
