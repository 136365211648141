import ReactFlow, { Controls, getNodesBounds, isNode } from 'reactflow';
import { useMemo, useState } from 'react';
import 'reactflow/dist/style.css';

import { ExpandGraphButton, getLayoutedElements, DEFAULT_GRAPH_PROPS, EFlowLayout } from 'Layouts/components/Graph';
import { createNodesAndEdges } from './utils';
import { IReachabilityPathsGraphProps, NODE_HEIGHT, NODE_TYPES, DEFAULT_GRAPH_HEIGHT } from './types';

export const ReachabilityPathsGraph: React.FC<IReachabilityPathsGraphProps> = ({ chains, selectedNodeId, onNodeClick }) => {
  const [height, setHeight] = useState(DEFAULT_GRAPH_HEIGHT);

  const { nodes, edges, bounds } = useMemo(() => {
    const { nodes: initialNodes, edges: initialEdges } = createNodesAndEdges(chains);
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(initialNodes, initialEdges, EFlowLayout.HORIZONTAL, NODE_HEIGHT);
    return {
      nodes: layoutedNodes,
      edges: layoutedEdges,
      bounds: getNodesBounds(layoutedNodes),
    };
  }, [chains]);

  const displayedNodes = useMemo(() => {
    return nodes.map((elem) => {
      if (isNode(elem) && elem.id === selectedNodeId) {
        return { ...elem, selected: true };
      }
      return elem;
    });
  }, [nodes, selectedNodeId]);

  const handleNodeClick = (_event, node) => {
    onNodeClick(node.id);
  };

  const onGraphExpandToggle = () => {
    if (height > DEFAULT_GRAPH_HEIGHT) {
      setHeight(DEFAULT_GRAPH_HEIGHT);
    } else {
      setHeight(bounds.height);
    }
  };

  return (
    <ReactFlow {...DEFAULT_GRAPH_PROPS} nodes={displayedNodes} edges={edges} nodeTypes={NODE_TYPES} onNodeClick={handleNodeClick}>
      <Controls showInteractive={false} position={'bottom-right'}>
        {bounds.height > DEFAULT_GRAPH_HEIGHT && <ExpandGraphButton onClick={onGraphExpandToggle} isExpanded={height > DEFAULT_GRAPH_HEIGHT} />}
      </Controls>
    </ReactFlow>
  );
};
