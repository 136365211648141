import { useQuery } from 'react-query';
import { fetchRepositories } from '../api';
import { IPage, IRepository } from '@APITypes';
import { IApiRepositoriesParams } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useRepositories = (params?: IApiRepositoriesParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['repositories', params],
    queryFn: async () => {
      return await fetchRepositories(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IPage<IRepository>>(queryOptions);
};
