import React from 'react';
import { ListSelectionSummary } from 'core/CardList';
import { useItemSelection } from '../contexts';

export const RepositoriesSelectionSummary: React.FC = () => {
  const { selectedItemsCount, clearSelectedItems, selectAllItems, totalItemsCount } = useItemSelection();

  if (!selectedItemsCount) {
    return null;
  }

  const isAllKeysSelected = selectedItemsCount === totalItemsCount;
  const repositoriesCountLabel = selectedItemsCount === 1 ? `${selectedItemsCount} repository is` : `${selectedItemsCount} repositories are`;
  const label = `${isAllKeysSelected ? 'All' : ''} ${repositoriesCountLabel} selected.`;
  const buttonLabel = isAllKeysSelected ? 'Clear selection' : `Select all ${totalItemsCount} repositories`;
  const buttonOnClick = isAllKeysSelected ? clearSelectedItems : selectAllItems;

  return <ListSelectionSummary label={label} buttonLabel={buttonLabel} buttonOnClick={buttonOnClick} />;
};
