import Shape1 from '@Images/login/shape1.png';
import styled from 'styled-components';

export const Shape1Styled = styled.img.attrs({
  src: Shape1,
})`
  position: absolute;
  bottom: 0;
  left: 273px;
`;
