import { useMemo, useState } from 'react';
import { QueryClient } from 'react-query';
import { faCircleExclamation, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import { useRepositoriesFailedPullRequests } from 'Layouts/DashboardPage/hooks/useRepositoriesFailedPullrequests.hook';
import { useProtectFinish, useProtectStart } from '@Utils';
import { useModal } from '@Modal';
import { EReminderType } from '@blindspot/common/types/bff';
import { useUserReminderUpdate, useUserReminders } from 'Layouts/DashboardPage/hooks';
import { Remainder } from 'Layouts/components';
import { FailedPullRequestItem, RemindLatterModal } from './components';
import { AlarmIconStyled, ButtonStyled, IconStyled } from './styled';

export const FailedPullRequestsRemainder = () => {
  const { data: reminder, isLoading } = useUserReminders({ filter: { 'filter[type]': EReminderType.PR_CHECK } });
  const { data, refetch } = useRepositoriesFailedPullRequests();
  const [expanded, setExpanded] = useState(false);
  const { mutate: userReminderUpdate } = useUserReminderUpdate();

  const pullRequestCheckRemindAt = reminder?.[0]?.remindAt;

  const [show, setShow] = useState(true);

  useProtectFinish(refetch);
  useProtectStart(refetch);

  const setRemindAfter = (date: Date) => {
    userReminderUpdate({ remindAt: date, type: EReminderType.PR_CHECK });
    setShow(false);
  };

  const { openModal } = useModal();
  const totalFailedPullRequests = useMemo(() => data?.reduce((acc, repository) => acc + repository.failedPullRequestsCount, 0), [data]);
  const message = useMemo(
    () =>
      `There  ${totalFailedPullRequests > 1 ? `are ${totalFailedPullRequests}` : 'is 1'} failed PR check${totalFailedPullRequests > 1 ? 's' : ''} in ${
        data?.length > 1 ? `${data?.length} repositories` : '1 repository'
      }`,
    [data?.length, totalFailedPullRequests],
  );

  if (!data?.length || !show || isLoading) {
    return null;
  }

  if (pullRequestCheckRemindAt) {
    const remindAfter = new Date(pullRequestCheckRemindAt);
    if (remindAfter > new Date()) {
      return null;
    }
  }

  const openRemindLatterModal = () => {
    openModal(<RemindLatterModal setRemindAfter={setRemindAfter} />);
  };

  const expandHandler = () => {
    setExpanded(!expanded);
  };

  return (
    <Remainder
      message={message}
      icon={<AlarmIconStyled icon={faCircleExclamation} />}
      action={
        <>
          <ButtonStyled onClick={openRemindLatterModal}>Remind me later</ButtonStyled>
          <ButtonStyled onClick={expandHandler}>
            See details <IconStyled icon={faChevronDown} />
          </ButtonStyled>
        </>
      }
    >
      {expanded && (
        <div>
          {data?.map((failedPullRequest) => (
            <FailedPullRequestItem key={failedPullRequest.uuid} failedPullRequest={failedPullRequest} />
          ))}
        </div>
      )}
    </Remainder>
  );
};
