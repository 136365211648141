import React from 'react';
import { IDialogTooltipProps } from './types';
import { DialogTooltipContainerStyled, DialogTooltipContentStyled } from './styled';

export const DialogTooltip: React.FC<IDialogTooltipProps> = ({ children, triggerElement }) => {
  return (
    <DialogTooltipContainerStyled>
      <div>{triggerElement}</div>
      <DialogTooltipContentStyled>{children}</DialogTooltipContentStyled>
    </DialogTooltipContainerStyled>
  );
};
