import React from 'react';
import { FilterTitleStyled } from '../styled';
import { IFilterItemProps } from '../types';
import { FilterSelect } from './FilterSelect';
import { FilterRange } from './FilterRange/FilterRange';

export const FilterItem: React.FC<IFilterItemProps> = ({ filter }) => (
  <li>
    <FilterTitleStyled>{filter.label}</FilterTitleStyled>
    {filter.options ? (
      <FilterSelect isMulti={filter.isMulti} filterId={filter.id} options={filter.options} controlShouldRenderValue />
    ) : (
      <FilterRange filterId={filter.id} min={filter.min} max={filter.max} isMulti={filter.isMulti} />
    )}
  </li>
);
