import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const AttentionIconStyled = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.rose[600]};

  position: absolute;
  top: -${({ theme }) => theme.spacing[1]};
  right: -${({ theme }) => theme.spacing[1]};
`;
