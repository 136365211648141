import React from 'react';
import { ESign, SignRender } from '@Components';
import { SourceCodeLinkStyled, SourceCodeTextStyled } from './styled';
import { ISourceCodeLinkProps } from './interfaces';
import { Flex } from '@Styles';
import { NotAvailable } from '@APITypes';

export const SourceCodeLink: React.FC<ISourceCodeLinkProps> = ({ src, text, origin }) => (
  <Flex>
    {src ? (
      <SourceCodeLinkStyled href={src}>
        <SignRender size="small" name={ESign[origin]} />
        <SourceCodeTextStyled>{text}</SourceCodeTextStyled>
      </SourceCodeLinkStyled>
    ) : (
      NotAvailable
    )}
  </Flex>
);
