import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const CloseIconStyled = styled(FontAwesomeIcon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: ${({ theme }) => theme.spacing[0.5]};
  color: ${({ theme }) => theme.colors.zinc[800]};
`;
