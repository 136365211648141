import styled, { css } from 'styled-components';

export const ScanConfigSetListItemLabelStyled = styled.span(
  ({ theme }) => css`
    font-size: ${theme.text.size.body1};
    font-weight: ${theme.text.weight.regular};
    line-height: 28px;
    letter-spacing: 0.15px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${theme.colors.black};
  `,
);
