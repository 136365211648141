import { Input } from '@FormElements';
import styled, { css } from 'styled-components';

export const ControlInputStyled = styled(Input)<{ $error?: boolean }>`
  border: none;
  flex: 1;
  padding: ${({ theme }) => css`0 ${theme.spacing[0.5]}`};

  &:focus {
    border: none;
  }
  font-family: Manrope;
  letter-spacing: 0.2px;
  line-height: 21px;
  outline: none;
  padding: ${({ theme }) => css`0 ${theme.spacing[0.5]}`};
  color: ${({ theme }) => theme.colors.zinc[800]};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  border-color: ${({ theme, $error }) => ($error ? theme.colors.rose[600] : theme.colors.zinc[200])};

  ::placeholder {
    color: ${({ theme }) => theme.colors.zinc[500]};
  }
`;
