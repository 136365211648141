import { InfoLabelTypes } from '@Components';
import { InfoLabelContainer } from 'Layouts/components/InfoLabel/styled';
import styled from 'styled-components';

export const RemediationInfoLabelStyled = styled(InfoLabelContainer)`
  ${({ theme, $type }) => {
    if ($type === InfoLabelTypes.Low) {
      return `
        color: ${theme.colors.zinc['600']};
        background: ${theme.colors.zinc['200']};
      `;
    }
  }}
  min-width: ${({ theme }) => theme.spacing[3]};
`;
