import styled from 'styled-components';

export const ColumnVisibilityStyled = styled.div`
  display: inline-grid;
  grid-template-columns: auto;
  grid-gap: ${({ theme }) => theme.spacing[2]};

  button {
    background: transparent !important;
    font-size: ${({ theme }) => theme.text.size.body2} !important;
  }
`;
