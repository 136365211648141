import { useEffect, useRef, useState, ChangeEvent } from 'react';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { ENotificationType, toastService } from '@Notification';
import { AxiosError } from 'axios';

import { Loader } from '@Components';
import { ControlInputContainer, ControlInputStyled, ControlInputChipStyled, ControlInputBlockStyled } from '@Components/Form';
import { FormControlHelperText } from 'Layouts/SettingsPage/components/InviteMemberForm/styled';
import { useIntegrationTokenValidate } from 'Layouts/IntegrationsPage/hooks';
import { IBitbucketAccessToken } from '../../../hooks/types';
import { IWorkspaceAccessTokenProps } from '../types';
import { ChipCloseIconStyled, InputChipStyled, WorkspaceSelectStyled } from '../styled';
import { ELoaderSize } from 'Layouts/components/Loader/types';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const WorkspaceAccessTokenInputs = ({ refCallback, onChange, onBlur, error, setError, clearError, tokens }: IWorkspaceAccessTokenProps) => {
  const [inputValue, setInputValue] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate: tokenValidation, isLoading: tokenValidateLoading } = useIntegrationTokenValidate();

  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [isLoading]);

  const onTokenRemove = (removeToken: IBitbucketAccessToken) => {
    onChange(tokens.filter((token) => token !== removeToken));
  };

  const onInputFocus = () => {
    setIsInputFocused(true);
    if (inputValue) {
      clearError();
    }
  };

  const onInputBlur = () => {
    setIsInputFocused(false);
    onInputValidation(inputValue);
    onBlur();
  };

  const createTokenName = () => {
    if (tokens.length) {
      const { name: lastTokenName } = tokens.at(-1);
      const tokenNumber = lastTokenName.split(' ')[1];
      return `Token ${parseInt(tokenNumber, 10) + 1}`;
    }
    return 'Token 1';
  };

  const addToken = (accessToken: string) => {
    const newToken = { accessToken, name: createTokenName(), isValid: true };
    const newTokens = [...tokens, newToken];

    onChange(newTokens);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 10) {
      return;
    }
    clearError();
    event.preventDefault();
    onInputValidation(event.target.value);
  };

  const onInputValidation = async (value: string) => {
    const inputToken = value.trim();

    if (!inputToken || inputToken.length < 10) {
      return;
    }

    const alreadyExist = tokens.some((token) => token.accessToken === inputToken);

    if (alreadyExist) {
      setIsLoading(true);
      setError('Token already exist');
      return;
    }

    await tokenValidation(
      {
        provider: EScmType.BITBUCKET,
        token: inputToken,
      },
      {
        onSuccess: async () => {
          addToken(inputToken);
          setInputValue('');
          clearError();
        },
        onError: (error: AxiosError<{ message?: string }>) => {
          const {
            response: { data },
          } = error;
          let message = data?.message || 'Something went wrong';

          if (message.startsWith('Invalid character')) {
            message = 'Invalid token character';
          }

          setError(message);
          toastService({
            header: 'validation token error',
            message,
            type: ENotificationType.ERROR,
          });
        },
      },
    );
  };

  const loading = tokenValidateLoading || isLoading;

  return (
    <WorkspaceSelectStyled>
      <ControlInputBlockStyled>
        <ControlInputContainer $error={!!error} $focused={isInputFocused}>
          {tokens.map((token) => {
            const { name, isValid } = token;
            const onClickChip = () => onTokenRemove(token);
            return (
              <InputChipStyled key={name} $isValid={isValid}>
                {name}
                <ChipCloseIconStyled icon={faClose} onClick={onClickChip} $isValid={isValid} />
              </InputChipStyled>
            );
          })}
          {loading && (
            <ControlInputChipStyled key={'loader'}>
              <Loader size={ELoaderSize.SMALL} />
            </ControlInputChipStyled>
          )}
          <ControlInputStyled
            name="tokens"
            placeholder="Access Token*"
            value={inputValue}
            onChange={onInputChange}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            ref={(el: HTMLInputElement) => {
              refCallback(el);
              inputRef.current = el;
            }}
          />
        </ControlInputContainer>
        <FormControlHelperText $error={!!error}>{error || 'Copy and paste access token'}</FormControlHelperText>
      </ControlInputBlockStyled>
    </WorkspaceSelectStyled>
  );
};
