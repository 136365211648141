import { ContentBlock } from '@Styles';
import styled, { css } from 'styled-components';

export const ScanConfigSetsStyled = styled(ContentBlock)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 296px 1fr;
    min-height: 700px;
    padding: ${theme.spacing[1]} 0 ${theme.spacing[4.5]};
  `,
);
