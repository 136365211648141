import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { ESign } from '@Components';
import { useModal } from '@Modal';
import { IntegrationsContext } from '@Utils';
import { ENotificationType, toastService } from '@Notification';
import { useIntegration, useTokenUpdate } from 'Layouts/IntegrationsPage/hooks';
import { IntegrationModal } from '../IntegrationModal';
import { BitbucketIntegrationModal } from './BitbucketIntegrationModal';
import { IBitbucketAppPasswordFormData, bitbucketAppPasswordSteps, IBitbucketAppPasswordModalProps } from './types';
import { BitbucketListInfo, AppPasswordInputs } from './components';
import { BitbucketWorkspaceSelectModal } from './BitbucketWorkspaceSelectModal';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const BitbucketAppPasswordModal = ({ isUpdate }: IBitbucketAppPasswordModalProps) => {
  const queryClient = useQueryClient();
  const invalidateIntegrations = () => queryClient.invalidateQueries({ queryKey: ['fetchUserOrg'] });
  const { integrations } = useContext(IntegrationsContext);
  const { mutate: setIntegration, isLoading: isIntegrationLoading } = useIntegration();
  const { mutate: updateToken, isLoading: isUpdateLoading } = useTokenUpdate();

  const isLoading = isIntegrationLoading || isUpdateLoading;

  const defaultValues: IBitbucketAppPasswordFormData = {
    userName: '',
    password: '',
  };

  const { openModal, closeModal } = useModal();
  const formData = useForm<IBitbucketAppPasswordFormData>({
    defaultValues,
  });
  const { control, formState } = formData;
  const { errors } = formState;

  const onSubmit = ({ userName, password }): void => {
    const bitbucketIntegration = integrations.find((integration) => integration.type === EScmType.BITBUCKET);

    if (isUpdate && bitbucketIntegration) {
      updateToken(
        {
          provider: EScmType.BITBUCKET,
          data: { tokens: [{ name: userName, userName, password, isValid: true }] },
          scmUuid: bitbucketIntegration.uuid,
        },
        {
          onSuccess: async () => {
            await invalidateIntegrations();
            closeModal();
          },
          onError: (error: AxiosError<{ message?: string }>) => {
            const {
              response: { data },
            } = error;
            const message = data?.message || 'Something went wrong';
            toastService({
              header: 'Update app password error',
              message,
              type: ENotificationType.ERROR,
            });
          },
        },
      );
      return;
    }
    setIntegration(
      {
        provider: EScmType.BITBUCKET,
        data: { tokens: [{ name: userName, userName, password, isValid: true }] },
      },
      {
        onSuccess: async () => {
          await invalidateIntegrations();
          return openModal(<BitbucketWorkspaceSelectModal />);
        },
        onError: (error: AxiosError<{ message?: string }>) => {
          const {
            response: { data },
          } = error;
          const message = data?.message || 'Something went wrong';
          toastService({
            header: 'Set app password error',
            message,
            type: ENotificationType.ERROR,
          });
        },
      },
    );
  };

  const onBackClickHandler = () => {
    return openModal(<BitbucketIntegrationModal />);
  };

  return (
    <IntegrationModal
      nextButtonType="submit"
      title={'Bitbucket'}
      titleSign={ESign.bitbucket}
      info={<BitbucketListInfo steps={bitbucketAppPasswordSteps} />}
      formData={formData}
      onSubmit={onSubmit}
      onBackClickHandler={onBackClickHandler}
      backButtonTitle={'Integration Type'}
      inputs={<AppPasswordInputs control={control} errors={errors} />}
      isLoading={isLoading}
      nextButtonDisabled={isLoading}
    />
  );
};
