import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const RadioButtonWrapperStyled = styled(Flex)(
  ({ theme }) => css`
    padding: ${theme.spacing[1]};
    justify-content: center;
    align-items: center;
    align-self: flex-start;
  `,
);
