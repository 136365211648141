import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const NavigatorIconStyled = styled(FontAwesomeIcon)<{ $disabled?: boolean }>`
  padding: ${({ theme }) => theme.spacing[1.5]};
  width: ${({ theme }) => theme.spacing[2]};
  height: ${({ theme }) => theme.spacing[2]};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.zinc[900]};

  ${({ $disabled }) => `pointer-events: ${$disabled ? 'none' : 'auto'};`}

  &:hover {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 0.04;
  }

  &:focus {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 0.12;
  }

  ${({ $disabled }) =>
    $disabled &&
    `
   color: ${({ theme }) => theme.colors.zinc[900]};
   opacity: 0.26;
   cursor: not-allowed;
   `}
`;
