import { useTheme } from 'styled-components';

import { isEmpty } from '@Utils';
import { useFilter } from 'core/Filter';
import { DashboardCardStyled } from '../styled';
import { useIssuesByCategory } from '../hooks';
import { getIssuesByCategoryOptions, issuesByCategoryDefaultSeries } from '../config';
import { formatIssuesByCategoryToSeries } from '../utils';
import { Chart } from './Chart';
import { useNavigate } from 'react-router-dom';

export const IssuesByCategory = () => {
  const theme = useTheme();
  const { appliedFilters } = useFilter();
  const { data, isLoading } = useIssuesByCategory({ filter: appliedFilters });
  const navigate = useNavigate();

  const series = !isEmpty(data) ? formatIssuesByCategoryToSeries(data) : issuesByCategoryDefaultSeries;

  const options = getIssuesByCategoryOptions({ theme, navigate });

  return (
    <DashboardCardStyled $paddingBottom={theme.spacing[0.5]}>
      <Chart isLoading={isLoading} options={options} series={series} isInteractiveCategories type="bar" width={'100%'} height={'250px'} />
    </DashboardCardStyled>
  );
};
