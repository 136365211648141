import React from 'react';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import { Wrapper, Spinner } from './styled';
import { ILoaderProps, ELoaderSize } from './types';

export const Loader: React.FC<ILoaderProps> = ({ color, size = ELoaderSize.NORMAL }) => {
  return (
    <Wrapper>
      <Spinner $color={color} $size={size} icon={faLoader} spin />
    </Wrapper>
  );
};
