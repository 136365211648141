import { useTheme } from 'styled-components';

import { DashboardCardStyled } from '../styled';
import { useDashboardIssues } from '../hooks';
import { formatIssuesSeries } from '../utils';
import { isEmpty } from '@Utils';
import { useFilter } from 'core/Filter';
import { FunnelGraph } from './FunnelGraph';

export const Issues = () => {
  const theme = useTheme();
  const { appliedFilters } = useFilter();
  const { data, isLoading } = useDashboardIssues({ filter: appliedFilters });

  const series = !isEmpty(data) ? formatIssuesSeries(data) : null;

  return (
    <DashboardCardStyled $paddingBottom={theme.spacing[1]}>
      <FunnelGraph series={series} isLoading={isLoading} />
    </DashboardCardStyled>
  );
};
