import { DefaultTheme } from 'styled-components';

export const getChartLegendOptions = (theme: DefaultTheme, inverseOrder?: boolean): { legend: ApexLegend } => ({
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    fontSize: theme.text.size.overline,
    fontFamily: theme.text.fontFamily,
    inverseOrder,
    markers: {
      shape: 'circle',
      size: 12,
    },
  },
});
