import styled from 'styled-components';

export const PageStyled = styled.div`
  padding: ${({ theme }) => `${theme.spacing[2.5]} ${theme.spacing[4]}`};
  padding-bottom: ${({ theme }) => theme.spacing[5]};
  padding-left: 0;
  margin-left: 263px;
  width: 100%;
  max-width: calc(100vw - 278px);
  box-sizing: border-box;
`;
