import React from 'react';
import { ErrorWrapperStyled, ErrorSideBarWrapperStyled, ErrorContentStyled } from '@Error';
import { IErrorPage } from '@Error';
import { SideBar } from 'Layouts/DashboardLayout';

export const ErrorPage: React.FC<IErrorPage> = ({ children, showSideBar = false, ...props }) => {
  return (
    <ErrorWrapperStyled>
      {showSideBar ? (
        <ErrorSideBarWrapperStyled $twoColumns={showSideBar}>
          <SideBar />
          <ErrorContentStyled {...props}>{children}</ErrorContentStyled>
        </ErrorSideBarWrapperStyled>
      ) : (
        <ErrorContentStyled {...props}>{children}</ErrorContentStyled>
      )}
    </ErrorWrapperStyled>
  );
};
