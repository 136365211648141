import React, { useState } from 'react';
import { Button, ButtonModes } from '@FormElements';
import { DialogTooltip } from '@Components';
import { IProtectTourMsgProps } from './types';
import { TooltipButtonStyled, TooltipDescriptionStyled, TooltipStyled, TooltipTitleStyled } from './styled';

export const ProtectTourMsg: React.FC<IProtectTourMsgProps> = ({ children }) => {
  const [isTourDone, setIsTourDone] = useState<string>(localStorage.getItem('isProtectingTourDone'));

  const onDone = () => {
    localStorage.setItem('isProtectingTourDone', 'true');
    setIsTourDone('true');
  };

  if (isTourDone === 'true') {
    return children;
  }

  return (
    <DialogTooltip triggerElement={children}>
      <TooltipStyled>
        <TooltipTitleStyled>Monitor your first repository</TooltipTitleStyled>
        <TooltipDescriptionStyled>{`After clicking "Monitor", we will scan it for security issues.`}</TooltipDescriptionStyled>
        <TooltipDescriptionStyled>It will be then monitored in real-time to ensure its safety.</TooltipDescriptionStyled>
        <TooltipButtonStyled>
          <Button styleMode={ButtonModes.Opacity} onClick={onDone}>
            Got It
          </Button>
        </TooltipButtonStyled>
      </TooltipStyled>
    </DialogTooltip>
  );
};
