import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { HandleStyled } from '../../IssueDependencyChain/styled';
import { NodeStyled, NodeSecondaryTextStyled } from './styled';
import { IChainNode } from './types';

export const ChainNode = memo(({ data, targetPosition, sourcePosition, selected }: NodeProps<IChainNode>) => {
  const isHighlighted = selected || data.isVulnerableFunction;

  return (
    <div key={`${data.primaryText}-${data.secondaryText}`}>
      <HandleStyled type="target" position={targetPosition} isConnectable={false} />
      <NodeStyled $selected={selected} $isVulnerableFunction={data.isVulnerableFunction}>
        <div>
          {data.primaryText}
          {data.usagesText && <NodeSecondaryTextStyled $isHighlighted={isHighlighted}> {data.usagesText}</NodeSecondaryTextStyled>}
        </div>
        {data.secondaryText && <NodeSecondaryTextStyled $isHighlighted={isHighlighted}>{data.secondaryText}</NodeSecondaryTextStyled>}
      </NodeStyled>
      <HandleStyled type="source" position={sourcePosition} isConnectable={false} />
    </div>
  );
});

ChainNode.displayName = 'ChainNode';
