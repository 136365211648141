import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const StatusInfoActionsStyled = styled(Flex)(
  ({ theme }) => css`
    align-self: center;
    margin-right: ${theme.spacing[1]};
    flex-direction: column;
    gap: 10px;
  `,
);
