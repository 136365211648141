import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const RepositoryCardStyled = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[3]}`};
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.colors.zinc[900]};
  transition: box-shadow 0.3s ease;
  border-radius: ${({ theme }) => theme.spacing[2]};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 12px rgba(10, 24, 154, 0.05);

  &:hover {
    color: ${({ theme }) => theme.colors.zinc[900]};
    box-shadow: 0px 2px 14px 0px rgba(0, 5, 47, 0.2);
  }

  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.zinc[900]};
  }
`;
