import React from 'react';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { CloseIcon, NotificationsWrapper, NotificationMessageWrapper, NotificationIcon, NotificationMessage } from './styled/Notifications';
import { ITableNotificationsProps } from './types';

export const TableNotifications: React.FC<ITableNotificationsProps> = ({ message, icon, onClick, onClose }) => {
  const handleOnClose = (event: React.MouseEvent<HTMLOrSVGElement>) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <NotificationsWrapper onClick={onClick}>
      <NotificationMessageWrapper>
        <NotificationIcon icon={icon} />
        <NotificationMessage>{message}</NotificationMessage>
      </NotificationMessageWrapper>
      <CloseIcon icon={faClose} onClick={handleOnClose} />
    </NotificationsWrapper>
  );
};
