import React from 'react';
import { Loader } from '@Components';
import { ELoaderSize } from 'Layouts/components/Loader/types';
import { ProgressBarItem, ProgressBarItemTitle } from './styled';

export const RepositoryProgressBarPlaceholder: React.FC = () => {
  return (
    <ProgressBarItem>
      <Loader size={ELoaderSize.SMALL} />
      <ProgressBarItemTitle>Scanning...</ProgressBarItemTitle>
    </ProgressBarItem>
  );
};
