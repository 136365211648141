import React from 'react';
import { IError } from '@Error';

interface IProps {
  children: React.ReactNode;
  errorTemplate: React.ReactElement;
}

interface IState {
  error: IError;
}

export class ErrorHandler extends React.Component<IProps, IState> {
  state = {
    error: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { children, errorTemplate } = this.props;
    const { error } = this.state;
    return error ? React.cloneElement(errorTemplate, { error }) : children;
  }
}
