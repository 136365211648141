import { Flex } from '@Styles';
import styled from 'styled-components';

export const SelectLabel = styled(Flex)`
  align-items: center;
  text-align: left;
  gap: 6px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.zinc[800]};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.regular};
`;
