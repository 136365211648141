import styled, { css } from 'styled-components';

export const ExpirationTokenModalText = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.black};
    text-align: center;

    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 24px;
    letter-spacing: 0.2px;

    margin-top: 18px;
  `,
);
