import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ProgressBarContainer = styled(Flex)<{ $expanded: boolean }>(
  ({ theme, $expanded }) => css`
    flex-direction: column;
    justify-self: flex-end;
    width: 368px;
    position: fixed;
    top: 0;
    right: ${theme.spacing[4]};
    height: auto;
    transform: translateY(${$expanded ? '20px' : 'calc(100vh - 48px)'});
    border-radius: ${$expanded ? '12px' : '12px 12px 0 0'};
    transition: all ${$expanded ? '600ms' : '400ms'} ease-out;
    background-color: ${theme.colors.white};
    filter: drop-shadow(0px 1px 18px rgba(39, 39, 42, 0.12)) drop-shadow(0px 6px 10px rgba(39, 39, 42, 0.14));
    padding-bottom: ${$expanded ? theme.spacing[2] : 0};
    z-index: 100;
  `,
);
