import styled, { css } from 'styled-components';

export const ScanConfigDescriptionStyled = styled.p(
  ({ theme }) => css`
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
    color: ${theme.colors.zinc[500]};
  `,
);
