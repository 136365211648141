import React from 'react';
import { IssueTitle } from '../styled';
import { IIssueTitleByCategory } from './types';
import { EIssueSubType, IIssue } from '@APITypes';
import { IssueTitleContainer } from '../styled/IssueTitleContainer.styled';
import { NewChip } from '../../IssuesTable/NewChip';
import { isNewIssue } from 'Layouts/IssuesPage/utils';
import { ISastIssue } from '../../IssuesTable1stParty';
import { firstLetterToUpperCase } from '@Utils';

export const getIssueTitle = (issue: Pick<IIssue, 'category' | 'name'>) => {
  if (issue.category === EIssueSubType.TAMPERING) {
    return `Code Injection Detected ${issue.name}`;
  }
  if (issue.category === EIssueSubType.RISKY_CODE) {
    return `Risky Code detected on ${issue.name}`;
  }

  return issue.name;
};

export const IssueTitleByCategory: React.FC<IIssueTitleByCategory> = ({ issue }) => {
  const isNew = isNewIssue((issue as IIssue).discoveredAt || new Date((issue as ISastIssue).createdAt));
  return (
    <IssueTitleContainer>
      <IssueTitle>{(issue as ISastIssue).fingerprint ? firstLetterToUpperCase((issue as ISastIssue).name) : getIssueTitle(issue as IIssue)}</IssueTitle>
      {isNew && <NewChip />}
    </IssueTitleContainer>
  );
};
