import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const CreateSbomDialogStyled = styled(Flex)(
  ({ theme }) => css`
    position: fixed;
    align-items: center;
    justify-content: space-between;
    bottom: ${theme.spacing[4]};
    left: 45%;
    height: 74px;
    width: 436px;
    padding: ${theme.spacing[2]} ${theme.spacing[2.5]} ${theme.spacing[2]} ${theme.spacing[4]};
    background: ${theme.colors.indigo[800]};
    color: ${theme.colors.white};
    box-shadow: ${theme.shadows[2]};
    border-radius: ${({ theme }) => theme.spacing[1]};
  `,
);
