import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { SideBar } from '@Layouts';
import { useDrawer } from '@Drawer';
import { ExportsDrawer } from 'Layouts/Exports/components';
import { useQuery } from '@Utils';
import { ContainerStyled, PageStyled } from './styled';
import { ModalProvider } from '@Modal';
import { ExpirationTokenContainer } from '@Components';

export const DashboardLayout: React.FC = () => {
  const queryParams = useQuery();
  const { openDrawer } = useDrawer();

  useEffect(() => {
    if (queryParams.has('exports')) {
      openDrawer(<ExportsDrawer />);
    }
  }, [queryParams]);

  return (
    <ContainerStyled>
      <SideBar />
      <PageStyled>
        <Outlet />
        <ModalProvider>
          <ExpirationTokenContainer />
        </ModalProvider>
      </PageStyled>
    </ContainerStyled>
  );
};
