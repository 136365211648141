import { axiosConfig as axios } from '@Utils';
import { IUser } from '@APITypes';

export const fetchUser = async (token: string): Promise<IUser> => {
  const response = await axios(`user/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
