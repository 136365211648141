import { faHashtag } from '@fortawesome/pro-regular-svg-icons';

import { Flex } from '@Styles';
import { SourceCodeLinkStyled, SourceCodeTextStyled } from 'Layouts/DiscoveryPage/components/SubComponents/styled';
import { IconStyled } from '../styled';
import { IPullRequestIdProps } from '../interface';

export const PullRequestId = ({ displayId, url }: IPullRequestIdProps) => {
  return (
    <>
      <IconStyled icon={faHashtag} />
      <Flex>
        <SourceCodeLinkStyled href={url}>
          <SourceCodeTextStyled>ID: {displayId}</SourceCodeTextStyled>
        </SourceCodeLinkStyled>
      </Flex>
    </>
  );
};
