import React from 'react';
import { IRemediationSummaryIssuesProps } from './types';
import {
  RemediationSummaryIssueLabelsStyled,
  RemediationSummaryIssuesContainerStyled,
  RemediationSummaryIssuesScoreFlagContainerStyled,
  RemediationSummaryIssuesTitleStyled,
} from './styled';
import { RemediationIssueLabels } from './RemediationIssueLabels';
import { ScoreFlag } from 'Layouts/components/ScoreFlag';

export const RemediationSummaryIssues: React.FC<IRemediationSummaryIssuesProps> = ({ title, issues, score }) => {
  return (
    <RemediationSummaryIssuesContainerStyled>
      <RemediationSummaryIssuesTitleStyled>{title}</RemediationSummaryIssuesTitleStyled>
      <RemediationSummaryIssueLabelsStyled>
        <RemediationIssueLabels issues={issues} />
      </RemediationSummaryIssueLabelsStyled>
      {!!score && (
        <RemediationSummaryIssuesScoreFlagContainerStyled>
          <ScoreFlag score={score} />
        </RemediationSummaryIssuesScoreFlagContainerStyled>
      )}
    </RemediationSummaryIssuesContainerStyled>
  );
};
