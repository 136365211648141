import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ScanConfigSetRepositoryLinkStyled = styled(Link)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing[1]};
    font-size: ${theme.text.size.body1};
    font-weight: ${theme.text.weight.regular};
    line-height: 28px;
    letter-spacing: 0.15px;
    text-align: left;
    color: ${theme.colors.zinc[900]};

    :hover,
    :visited,
    :active {
      color: ${theme.colors.zinc[900]};
      text-decoration: underline;
    }
  `,
);
