import { IBitbucketListInfoProps } from '../types';

export const BitbucketListInfo = ({ steps }: IBitbucketListInfoProps) => {
  return (
    <ol>
      {steps.map((step, index) => (
        <li key={index}>{step}</li>
      ))}
    </ol>
  );
};
