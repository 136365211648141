import React from 'react';
import { EScanConfigName, IScanConfigMonitoringEngineValue } from '@blindspot/common/types/bff/configs';
import { SectionSubtitleStyled, SectionTitleStyled } from '../../styled';
import { IDependenciesInventorySettingsProps } from './interfaces';
import { EScanConfigDependenciesInventoryFile } from '@blindspot/common/types/bff/configs/dependenciesInventory';
import { DependenciesInventoryFileSelect } from './DependenciesInventoryFileSelect';
import { DependenciesInventorySettingsStyled } from './styled';

export const DependenciesInventorySettings: React.FC<IDependenciesInventorySettingsProps> = ({ configValue, setDraftUpdates }) => {
  const onUpdateDependenciesInventoryFileConfig = (file: EScanConfigDependenciesInventoryFile) => {
    setDraftUpdates((prevState) => ({
      ...prevState,
      configs: prevState.configs.map((prevConfig) => {
        if (prevConfig.name !== EScanConfigName.DEPENDENCIES_INVENTORY) {
          return prevConfig;
        }
        const prevConfigValue = prevConfig.value as IScanConfigMonitoringEngineValue;
        return {
          ...prevConfig,
          value: {
            ...prevConfigValue,
            file: file,
          },
        };
      }),
    }));
  };
  return (
    <DependenciesInventorySettingsStyled>
      <SectionTitleStyled>Dependencies Inventory</SectionTitleStyled>
      <SectionSubtitleStyled>
        Myrror uses the repository's manifest file to analyze and discover the dependencies that were added to a repository, and their relationship (directly added, or indirectly added).
      </SectionSubtitleStyled>
      <DependenciesInventoryFileSelect value={configValue?.file} onChange={onUpdateDependenciesInventoryFileConfig} />
    </DependenciesInventorySettingsStyled>
  );
};
