import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

import { Loader } from '@Components';
import { IconCircleStyled, LoaderContainerStyled, SummaryBlockContentStyled, SummaryBlockNameStyled, SummaryBlockStyled, SummaryBlockValueStyled } from './styled';
import { ISummaryBlockProps } from './interfaces';

export const SummaryBlock = ({ name, value, icon, isLoading, link }: ISummaryBlockProps) => {
  const theme = useTheme();

  return (
    <SummaryBlockStyled>
      <IconCircleStyled>
        <FontAwesomeIcon icon={icon} color={theme.colors.indigo[400]} size={'lg'} />
      </IconCircleStyled>
      <SummaryBlockContentStyled>
        <SummaryBlockNameStyled to={link}>{name}</SummaryBlockNameStyled>
        {isLoading ? (
          <LoaderContainerStyled>
            <Loader />
          </LoaderContainerStyled>
        ) : (
          <SummaryBlockValueStyled>{value}</SummaryBlockValueStyled>
        )}
      </SummaryBlockContentStyled>
    </SummaryBlockStyled>
  );
};
