import React from 'react';
import { useLocation } from 'react-router-dom';
import { pageTools } from '@Utils';
import { SideBarLink } from '@Layouts';
import { Loader, Popper } from '@Components';
import { UserDropdown } from './User';
import { Line } from './Line';
import { UserTrigger } from './User/UserTrigger';
import { Logo } from './Logo';
import { OrganizationSelect } from './OrganizationSelect';
import { useUser } from '../../../SettingsPage/hooks';
import { faShieldExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare, faChartTreeMap, faGrid2, faList } from '@fortawesome/pro-solid-svg-icons';
import { colors } from '@Styles';
import { NavContainerStyled } from './styled';
import { useCanAccess } from 'Utils/auth';
import { Permission } from '@Permissions';

export const SideBar: React.FC = () => {
  const { pathname } = useLocation();
  const { data: user, isLoading } = useUser();
  const canAccessIntegrations = useCanAccess([Permission.INTEGRATION_CREATE, Permission.INTEGRATION_READ, Permission.INTEGRATION_UPDATE]);

  const onClickDocumentationLink = () => {
    window.open(process.env.REACT_APP_DOCS_URL, '_blank');
  };

  return (
    <NavContainerStyled>
      <aside>
        <Logo />
        <OrganizationSelect />
        <SideBarLink title={pageTools.dashboard.title} path={pageTools.dashboard.path} pathname={pathname} icon={faChartTreeMap} />
        <SideBarLink title={pageTools.issues.title} path={`${pageTools.issues.path}`} pathname={pathname} icon={faShieldExclamation} />
        <SideBarLink title={pageTools.discovery.title} path={pageTools.discovery.path} pathname={pathname} icon={faList} />
        <Line />

        {canAccessIntegrations && <SideBarLink title={pageTools.integrations.title} path={pageTools.integrations.path} pathname={pathname} icon={faGrid2} />}
      </aside>

      <aside>
        <SideBarLink icon={faArrowUpRightFromSquare} title="Documentation" pathname="/docs" path="/" onClick={onClickDocumentationLink} />

        <Line />
        {isLoading ? (
          <Loader color={`${colors.white}`} />
        ) : (
          <Popper triggerElement={(open) => <UserTrigger user={user} open={open} />}>
            <UserDropdown />
          </Popper>
        )}
      </aside>
    </NavContainerStyled>
  );
};
