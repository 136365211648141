import React from 'react';
import { LinkBlankIconStyled, LinkBlankStyled } from './styled';
import { ILinkBlankProps } from './types';

export const LinkBlank: React.FC<ILinkBlankProps> = ({ href, children }) => {
  return (
    <LinkBlankStyled href={href} target="_blank" rel="noreferrer">
      {children}
      <LinkBlankIconStyled />
    </LinkBlankStyled>
  );
};
