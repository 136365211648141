import styled from 'styled-components';
import { ESeverityLevel } from '@APITypes';
import { ContentBlock } from '@Styles';

export const IssueDetailsOverview = styled(ContentBlock)<{ $severity: ESeverityLevel }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: ${({ theme }) => theme.spacing['2.5']};
  color: ${({ theme }) => theme.colors.zinc['800']};
  border-left: 8px solid;

  ${({ $severity, theme }) => {
    if ($severity === ESeverityLevel.CRITICAL) {
      return `border-left-color: ${theme.colors.rose['600']};`;
    }
    if ($severity === ESeverityLevel.HIGH) {
      return `border-left-color: ${theme.colors.orange['500']};`;
    }
    if ($severity === ESeverityLevel.MEDIUM) {
      return `border-left-color: ${theme.colors.zinc['400']};`;
    }
    if ($severity === ESeverityLevel.LOW) {
      return `border-left-color: ${theme.colors.zinc['300']};`;
    }
  }}
`;
