import styled from 'styled-components';
import Quotes from '@Images/login/quotes.svg';

export const LoginAdvertisementTextStyled = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.text.size.h1};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  font-family: ${({ theme }) => theme.text.fontFamily};
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -26px;
    left: -62px;
    width: 75px;
    height: 57px;
    background-image: url(${Quotes});
    background-size: contain;
    background-repeat: no-repeat;
    fill: ${({ theme }) => theme.colors.indigo[800]};
    z-index: -1;
  }
`;
