import { format, parseISO } from 'date-fns';
import { faClock } from '@fortawesome/pro-regular-svg-icons';

import { DATE_TIME_LONG_FORMAT } from '@Utils';
import { UserRender } from '@Components';

import { CardInfoTextStyled, IconStyled } from '../styled';
import { IOpenedProps } from '../interface';

export const Opened = ({ createdAt, userName }: IOpenedProps) => {
  if (!createdAt) {
    return null;
  }
  const parsedDate = parseISO(createdAt);
  const formattedDate = format(parsedDate, DATE_TIME_LONG_FORMAT);
  return (
    <CardInfoTextStyled>
      <IconStyled icon={faClock} />
      {`Opened: ${formattedDate} by `}
      <UserRender userName={userName} />
    </CardInfoTextStyled>
  );
};
