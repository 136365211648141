import styled, { css } from 'styled-components';

export const DialogTooltipContentStyled = styled.div(
  ({ theme }) => css`
    position: absolute;
    background: ${theme.colors.indigo[900]};
    color: ${theme.colors.white};
    padding: ${theme.spacing['2.5']};
    border-radius: 4px;
    top: calc(100% + 12px);
    right: 0;
    z-index: 100;
    cursor: default;

    &:before {
      content: '';
      display: inline-block;
      background: inherit;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      position: absolute;
      top: -6px;
      right: 24px;
    }
  `,
);
