import dagre from '@dagrejs/dagre';
import { Position, Node, Edge } from 'reactflow';

import { EFlowLayout } from '../types';

export function getLayoutedElements(nodes, edges, direction = EFlowLayout.HORIZONTAL, nodeHeight: number): { nodes: Node[]; edges: Edge[] } {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === EFlowLayout.HORIZONTAL;
  dagreGraph.setGraph({ rankdir: isHorizontal ? 'LR' : 'TB', nodesep: 10, ranksep: 50, edgesep: 0, acyclicer: 'greedy', ranker: 'tight-tree' });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: node.width, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    node.position = {
      x: nodeWithPosition.x - node.width / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
}
