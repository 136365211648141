import React from 'react';
import { IRemediationRepositoryListProps } from './types';
import { RemediationRepositoryTitleStyled } from './styled';
import { RemediationList } from './RemediationList';
import { firstLetterToUpperCase } from '@Utils';

export const RemediationRepositoryList: React.FC<IRemediationRepositoryListProps> = ({ issueRemediations }) => {
  return (
    <>
      {issueRemediations.map((item) => (
        <div key={item.repository.name}>
          <RemediationRepositoryTitleStyled to={`/discovery/repositories/${item.repository.uuid}`}>{firstLetterToUpperCase(item.repository.name)}</RemediationRepositoryTitleStyled>
          <RemediationList repoId={item.repository.uuid} remediations={item.remediation} />
        </div>
      ))}
    </>
  );
};
