import styled, { css } from 'styled-components';

export const DefaultLabel = styled.span<{ $transparent?: boolean }>(
  ({ theme, $transparent }) => css`
    border-radius: 100px;
    padding: 2px ${theme.spacing[1]};
    background: ${$transparent ? theme.colors.transparent : theme.colors.indigo[100]};
    border: ${$transparent ? `1px solid ${theme.colors.indigo[500]}` : 'none'};
    color: ${theme.colors.indigo[500]};
    font-size: ${theme.text.size.caption};
    font-weight: ${theme.text.weight.bold};
    letter-spacing: 0.15px;
    line-height: 18.6px;
  `,
);
