import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CloseIcon = styled(FontAwesomeIcon)(
  ({ theme }) => css`
    width: 16px;
    height: 16px;
    padding: ${theme.spacing[1]};
  `,
);
