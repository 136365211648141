import styled, { css } from 'styled-components';

export const UserStatusChip = styled.div(
  ({ theme }) => css`
    border-radius: 100px;
    letter-spacing: 0.15px;
    line-height: 16px;
    padding: 2px ${theme.spacing[1]};
    font-size: ${theme.text.size.overline};
    font-weight: ${theme.text.weight.bold};
    background: ${theme.colors.indigo[100]};
    color: ${theme.colors.indigo[500]};
    margin-left: ${theme.spacing[0.5]};
  `,
);
