import { useQuery } from 'react-query';
import { useSession } from '@descope/react-sdk';
import { fetchMagicLink } from '../api';

export const useMagicLink = (repoUuid: string) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['magic-link-generate', repoUuid],
    queryFn: async () => {
      return await fetchMagicLink(repoUuid, sessionToken);
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<string>(queryOptions);
};
