import React from 'react';
import { CopyButton } from '@Components';
import { FilePathContainer, FilePath } from './styled';
import { IDependencyFilePathProps } from './interfaces';

export const DependencyFilePath: React.FC<IDependencyFilePathProps> = ({ path }) => {
  return (
    <FilePathContainer>
      <FilePath>{path}</FilePath>
      <CopyButton data={path} />
    </FilePathContainer>
  );
};
