import { axiosConfig } from '@Utils';

export const fetchScanConfigSetRepositories = async (scanConfigSetUuid: string, token: string) => {
  const response = await axiosConfig(`configs/scan/${scanConfigSetUuid}/repositories`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
