import styled from 'styled-components';
import { Grid } from '@Styles';

export const DetailsContainer = styled(Grid)`
  grid-template-columns: 1fr auto;
  gap: ${({ theme }) => theme.spacing['3']};
  font-size: ${({ theme }) => theme.text.size.body1};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  line-height: 24px;
  min-height: 450px;
  height: calc(100vh - 380px);
`;
