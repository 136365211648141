import { axiosConfig as axios } from '@Utils';

export const fetchScanConfigSetDetails = async (scanConfigSetUuid: string, token: string) => {
  const response = await axios(`configs/scan/${scanConfigSetUuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
