import styled, { css } from 'styled-components';

export const TooltipDescriptionStyled = styled.div(
  ({ theme }) => css`
    display: block;
    font-weight: 100;
    padding-bottom: ${theme.spacing['1']};
    line-height: 22.5px;
  `,
);
