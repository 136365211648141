import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { usePostProtect, isEmpty } from '@Utils';
import { InfoLabel, InfoLabelTypes, Loader } from '@Components';
import { EIssueSubType, EReachabilityStatus } from '@APITypes';
import { ModalProvider } from '@Modal';
import { Flex } from '@Styles';
import { useIssueDetails } from 'Layouts/IssuesPage/hooks';
import { IssueDetailsAdditional } from './IssueDetailsAdditional';
import { SecurityScore } from './SecurityScore';
import { IssueTitleByCategory, IssueReachability, IssueIgnored } from './components';
import { IssueDetailsContainer, IssueDetailsOverview, IssueTags, IssueDetailsEmptyStyled } from './styled';
import { IIssueContainerDetailsProps } from './types';
import { EIssueSourceType, IssueCategoryLabel } from '@blindspot/common/types/bff';
import { IssueNavContainer } from './IssueNavContainer';
import { IssueRecommendation } from './components/IssueRecommendation';
import { SeverityLabelStyled } from './styled/SeverityLabel.styled';
import { IssueRecommendationLabelStyled } from './components/IssueRecommendation/styled/styled';

export const IssueContainerDetails: React.FC<IIssueContainerDetailsProps> = ({ isDiff }) => {
  const queryClient = useQueryClient();
  const { baseIssueUuid, branchUuid } = useParams();

  const diff = useMemo(() => (isDiff ? { branchId: branchUuid } : undefined), [isDiff, branchUuid]);
  const { data: issueDetails, isLoading } = useIssueDetails({ issueUuid: baseIssueUuid, diff });

  const invalidateIssueDetails = () => queryClient.invalidateQueries({ queryKey: ['issueDetails', { issueUuid: baseIssueUuid, diff }] });
  usePostProtect(invalidateIssueDetails);

  if (isLoading)
    return (
      <IssueDetailsEmptyStyled>
        <Loader />
      </IssueDetailsEmptyStyled>
    );

  if (!issueDetails) {
    return <IssueDetailsEmptyStyled />;
  }

  const vulnerability = issueDetails.vulnerabilities?.[0];
  const isVulnerability = issueDetails.category === EIssueSubType.VULNERABILITY && vulnerability;
  const isFixed = issueDetails.fixedAt;

  return (
    <ModalProvider>
      <IssueNavContainer isDiff={isDiff} />
      <IssueDetailsContainer $twoColumns={!!isVulnerability}>
        <IssueDetailsOverview $severity={issueDetails.severity}>
          <Flex flexDirection="row" justifyContent="flex-start" alignItems="center" gap={1}>
            <SeverityLabelStyled>
              <InfoLabel capitalize type={issueDetails.severity}>
                {issueDetails.severity}
              </InfoLabel>
            </SeverityLabelStyled>
            {isFixed ? (
              <SeverityLabelStyled>
                <InfoLabel type={InfoLabelTypes.Fixed}>Fixed</InfoLabel>
              </SeverityLabelStyled>
            ) : (
              <SeverityLabelStyled>
                <InfoLabel noContainer>Unresolved</InfoLabel>
              </SeverityLabelStyled>
            )}
            <IssueIgnored issueSourceType={EIssueSourceType.THIRD_PARTY} isIgnored={!isEmpty(issueDetails.ignoredIssue)} />
          </Flex>
          <IssueTitleByCategory issue={issueDetails} />
          <IssueTags>
            <InfoLabel type={InfoLabelTypes.Bordered}>
              <IssueRecommendationLabelStyled>{IssueCategoryLabel[issueDetails.category]}</IssueRecommendationLabelStyled>
            </InfoLabel>
            {issueDetails.recommended && (
              <InfoLabel type={InfoLabelTypes.Bordered}>
                <IssueRecommendation />
              </InfoLabel>
            )}
            {issueDetails.reachabilityStatus !== EReachabilityStatus.UNDEFINED && (
              <InfoLabel capitalize={false} type={InfoLabelTypes.Bordered}>
                <IssueReachability reachabilityStatus={issueDetails.reachabilityStatus} />
              </InfoLabel>
            )}
          </IssueTags>
        </IssueDetailsOverview>
        {isVulnerability && <SecurityScore score={issueDetails.score} vulnerability={vulnerability} severity={issueDetails.severity} />}
      </IssueDetailsContainer>
      <IssueDetailsAdditional issueDetails={issueDetails} isDiff={isDiff} />
    </ModalProvider>
  );
};
