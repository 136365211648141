import styled, { css } from 'styled-components';

export const ScanConfigSetCoverageStyled = styled.div(
  ({ theme }) => css`
    border-radius: 20px;
    padding: ${theme.spacing[2]} ${theme.spacing[3]} ${theme.spacing[3.5]};
    margin: ${theme.spacing[3]} 0 ${theme.spacing[3.5]};
    background: ${theme.colors.indigo[50]};
    max-width: 600px;
  `,
);
