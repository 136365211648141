import { IChainItem } from '@blindspot/common/types/bff/api';

export function getNodeLabel(chain: IChainItem): string {
  if (!chain) {
    return '';
  }
  const { group_id, artifact_id, version } = chain;
  if (group_id) {
    return `${group_id}:${artifact_id}:${version}`;
  }
  return `${artifact_id}:${version}`;
}
