import { useQuery } from 'react-query';
import { fetchUser } from '../api';
import { IUser } from '@APITypes';
import { setUser } from '@sentry/react';
import { useSession } from '@descope/react-sdk';

export const useUser = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useQuery<IUser>({
    queryKey: 'userData',
    queryFn: async () => {
      if (isAuthenticated) {
        const user = await fetchUser(sessionToken);
        setUser({ email: user?.email, uuid: user?.uuid });
        return user;
      }
    },
    enabled: isAuthenticated && !isSessionLoading,
  });
};
