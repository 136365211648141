import styled from 'styled-components';

export const ComingSoon = styled.span`
  color: ${({ theme }) => theme.colors.indigo[500]};
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  background: ${({ theme }) => theme.colors.indigo[100]};
  border-radius: 100px;
  padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[1]}`};
  margin-left: ${({ theme }) => theme.spacing[0.5]};
`;
