import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { pageTools } from '../pageTools';
import { IntegrationsContext } from '@Utils';
import { LoadingScreen } from '@Components';

export const IntegratedGuard = () => {
  const { integrationsLoading, isIntegrated } = useContext(IntegrationsContext);
  if (integrationsLoading) {
    return <LoadingScreen />;
  }

  if (!isIntegrated) {
    return <Navigate to={`${pageTools.dashboard.path}`} />;
  }

  return <Outlet />;
};
