import { axiosConfig as axios } from '@Utils';
import { IApiReachabilityIssueParams } from '../types';

export const fetchReachabilityIssue = async ({ baseIssueUuid, issueUuid }: IApiReachabilityIssueParams, token: string) => {
  const response = await axios(`issues/details/${baseIssueUuid}/reachability/${issueUuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
