import { Flex } from '@Styles';
import styled from 'styled-components';

export const FiltersContainerStyled = styled(Flex)`
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing['3']} ${theme.spacing['2.5']}`};
  width: 330px;
  height: 100%;
  min-height: 100vh;
`;
