import React from 'react';
import { faCheckCircle, faShieldExclamation } from '@fortawesome/pro-solid-svg-icons';
import { NotificationSuccessIcon, NotificationErrorIcon } from './styled';
import { ENotificationType } from '@Notification';
import { INotificationIconProps } from './types';

export const NotificationIcon = ({ type }: INotificationIconProps) => {
  switch (type) {
    case ENotificationType.SUCCESS:
      return <NotificationSuccessIcon icon={faCheckCircle} />;
    case ENotificationType.ERROR:
      return <NotificationErrorIcon icon={faShieldExclamation} />;
    default:
      return null;
  }
};
