import styled, { css } from 'styled-components';

export const ProgressBarItemPrimaryText = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.zinc[800]};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.bold};
    line-height: 24px;
    letter-spacing: 0.1px;
  `,
);
