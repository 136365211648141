import styled, { css } from 'styled-components';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { ELoaderSize } from '../types';

export const Spinner = styled(FontAwesomeIcon)<FontAwesomeIconProps & { $color?: string; $size?: ELoaderSize }>(
  ({ theme, $color, $size }) => css`
    color: ${$color ? $color : theme.colors.indigo['500']};

    ${() => {
      if ($size === ELoaderSize.NORMAL) {
        return css`
          width: 24px;
          height: 24px;
        `;
      }
      if ($size === ELoaderSize.SMALL) {
        return css`
          width: 16px;
          height: 16px;
        `;
      }
    }}
  `,
);
