import React from 'react';
import styled from 'styled-components';
import { Link } from './Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';

interface IProps {
  path: string;
  title: string;
  pathname: string;
  icon: IconDefinition;
  onClick?: () => void;
}

const SideBarLinkStyled = styled(Link)`
  padding: ${({ theme }) => theme.spacing['2']};
  color: ${({ theme }) => theme.colors.zinc['50']} !important;
  font-weight: ${({ theme }) => theme.text.weight.regular};
  transition: 200ms;
  border-radius: 10px;
  cursor: pointer;
  margin: ${({ theme }) => theme.spacing['2']} 0;
  font-size: ${({ theme }) => theme.text.size['body2']} !important;

  &:before {
    color: ${({ theme }) => theme.colors.indigo['100']} !important;
    transition: 200ms;
    font-size: ${({ theme }) => theme.text.size['h3']} !important;
  }

  &:hover,
  &[data-active='true'] {
    background: ${({ theme }) => theme.colors.indigo['500']};

    &:before {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;
const SideBarIcon = styled(FontAwesomeIcon)`
  height: 22px;
  width: 22px;
`;
export const SideBarLink: React.FC<IProps> = ({ path, title, pathname, icon, onClick }) => {
  return (
    <SideBarLinkStyled onClick={onClick} to={`${path}`} data-active={pathname.split('/')[1] === path.split('/')[1]}>
      <SideBarIcon icon={icon} />
      {title}
    </SideBarLinkStyled>
  );
};
