import { DashboardCardStyled } from '../../styled';
import NoIssuesIcon from '@Images/shield-check.svg';
import { NoIssuesContentStyled, NoIssuesDescriptionStyled, EmptyStateTitleStyled, DashboardImageStyled } from './styled';

export const NoIssuesContent = () => {
  return (
    <DashboardCardStyled $maxHeight="300px">
      <NoIssuesContentStyled>
        <DashboardImageStyled src={NoIssuesIcon} alt="shield-check" />
        <EmptyStateTitleStyled>No Issues!</EmptyStateTitleStyled>
        <NoIssuesDescriptionStyled>We haven’t detected any issues, congratulations!</NoIssuesDescriptionStyled>
      </NoIssuesContentStyled>
    </DashboardCardStyled>
  );
};
