import React from 'react';
import { RadioGroupButton } from '@FormElements';
import { Caption } from '@Styles';
import { dependenciesInventoryFileOptions } from './constants';
import { DependenciesInventoryOptionStyled, FileSelectWrapper, InputLabelStyled } from './styled';
import { IDependenciesInventoryFileSelectProps } from './interfaces';

export const DependenciesInventoryFileSelect: React.FC<IDependenciesInventoryFileSelectProps> = ({ value, onChange }) => {
  return (
    <div>
      <p>Choose which file to parse -</p>
      <FileSelectWrapper>
        {dependenciesInventoryFileOptions.map((option) => (
          <DependenciesInventoryOptionStyled key={option.value} flexDirection="column" gap={1}>
            <InputLabelStyled key={option.value}>
              <RadioGroupButton value={option.value} checked={option.value === value} onChange={onChange} />
              {option.title}
            </InputLabelStyled>
            <Caption>{option.description}</Caption>
          </DependenciesInventoryOptionStyled>
        ))}
      </FileSelectWrapper>
    </div>
  );
};
