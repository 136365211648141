import { axiosConfig as axios } from '@Utils';

export const fetchReportsApi = async (token: string) => {
  const response = await axios.get(`report`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response?.data;
};
