import styled, { css } from 'styled-components';

export const ScanConfigSetListTitleStyled = styled.p(
  ({ theme }) => css`
    font-size: ${theme.text.size.subtitle1};
    font-weight: ${theme.text.weight.bold};
    letter-spacing: 0.15px;
    line-height: 28px;
  `,
);
