import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { NodeStyled, HandleStyled } from './styled';

export const ChainNode = memo(({ data, targetPosition, sourcePosition }: NodeProps) => {
  return (
    <div key={data.label}>
      <HandleStyled type="target" position={targetPosition} isConnectable={false} />
      <NodeStyled $isIssue={data.isIssueDependency}>
        <FontAwesomeIcon icon={data.icon} />
        {data.label}
      </NodeStyled>
      <HandleStyled type="source" position={sourcePosition} isConnectable={false} />
    </div>
  );
});

ChainNode.displayName = 'ChainNode';
