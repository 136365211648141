import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const RediscoverButtonStyled = styled(Button)(
  ({ theme }) => css`
    background: transparent;
    padding: ${theme.spacing[0.5]} ${theme.spacing[1.5]};
    font-size: ${theme.text.size.body2};
    color: ${theme.colors.zinc[900]};
    border-radius: 8px;

    &:disabled {
      border-color: ${theme.colors.zinc[300]};
      color: ${theme.colors.zinc[300]};
      cursor: default;
      text-transform: none;
      pointer-events: none;
    }
  `,
);
