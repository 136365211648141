import React, { useMemo } from 'react';
import { IRemediationIssuesColumns, IRemediationIssuesTableProps } from './types';
import { usePostProcessing, usePostProtect } from '@Utils';
import { createColumnHelper } from '@tanstack/react-table';
import { InfoLabel, InfoLabelTypes } from '@Components';
import { useQueryClient } from 'react-query';
import { getIssueTitle } from 'Layouts/IssuesPage/components/issueDetails/components';
import { IRemediationIssue } from '@APITypes';
import { IssueCategoryLabel } from '@blindspot/common/types/bff';
import { CreateTicketButton } from 'Layouts/IssuesPage/components/issueDetails/IssueNavContainer/CreateTicketModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { TableNoData } from 'core/Table/styled';
import { Table } from 'core/Table';
import { useRemediationFixedissues } from './hooks';
import { AlignCenter } from './styled';

export const RemediationIssuesTable: React.FC<IRemediationIssuesTableProps> = ({ repoId, depFileUuid, effortLevel }) => {
  const queryClient = useQueryClient();
  const { data: issues = [], isLoading } = useRemediationFixedissues(repoId, depFileUuid, effortLevel);

  const invalidateIssues = () => queryClient.invalidateQueries({ queryKey: ['repoRemediationFixedIssues', { repoId, depFileUuid }] });
  usePostProtect(invalidateIssues);
  usePostProcessing(invalidateIssues);

  const emptyStateComponent = (
    <TableNoData>
      <FontAwesomeIcon icon={faCircleCheck} />
      <p>No issues</p>
    </TableNoData>
  );

  const getRowLink = (row: IRemediationIssue) => `/issues/${row.baseIssueUuid}/details`;

  const columnHelper = createColumnHelper<IRemediationIssuesColumns>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('severity', {
        header: 'Severity',
        cell: (severity) => (
          <InfoLabel type={severity.getValue()} capitalize>
            {severity.getValue()}
          </InfoLabel>
        ),
        size: 150,
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ({ row }) => getIssueTitle(row.original),
        size: 200,
      }),
      columnHelper.accessor('category', {
        header: 'Category',
        cell: (category) => <InfoLabel type={InfoLabelTypes.Default}>{IssueCategoryLabel[category.getValue()]}</InfoLabel>,
        size: 150,
      }),
      columnHelper.accessor('tickets', {
        header: 'Ticket',
        cell: ({ row }) => {
          const issue = row.original;
          return (
            <AlignCenter>
              <CreateTicketButton isIconView baseIssueUuid={issue.baseIssueUuid} tickets={issue.tickets} />
            </AlignCenter>
          );
        },
        size: 150,
      }),
    ],
    [],
  );

  return <Table columns={columns} data={issues} tableFeatures={{}} emptyState={emptyStateComponent} isLoading={isLoading} getRowLink={getRowLink} />;
};
