import { TooltipContainer } from 'Layouts/components/Tooltip/styled';
import styled from 'styled-components';

export const RemediationIssueLabelsStyled = styled.div`
  ${TooltipContainer}:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing[1.5]};
  }

  ${TooltipContainer}:last-child {
    margin-right: 0;
  }
`;
