import React from 'react';
import Switch from 'react-switch';
import { colors } from '@Styles';
import { ICheckAllSwitchProps } from '../CheckAllSwitch/interfaces';
import { SwitchDescriptionCaptionStyled, SwitchDescriptionStyled, SwitchLabelStyled } from '../CheckAllSwitch/styled';
import { useUserOrganization } from '@Hooks';
import { IntegrationStatusEnum } from 'Layouts/IntegrationsPage/types';
import { EScmAppType } from '@blindspot/common/types/bff';
import { PRCommentsContainerStyled } from './styled';

export const PRCommentsSwitch: React.FC<ICheckAllSwitchProps> = ({ value, onChange }) => {
  const { data: userOrg } = useUserOrganization();
  const isDisabled = userOrg?.scm.find((scm) => scm.status === IntegrationStatusEnum.ACTIVE).appType !== EScmAppType.MANAGEMENT;

  return (
    <PRCommentsContainerStyled $disabled={isDisabled}>
      <SwitchLabelStyled $disabled={isDisabled} htmlFor="pr-comments">
        Pull Request Comments
        <Switch
          disabled={isDisabled}
          id="pr-comments"
          checked={value}
          onChange={onChange}
          height={14}
          width={26}
          uncheckedIcon={false}
          checkedIcon={false}
          offColor={colors.zinc[400]}
          onColor={colors.indigo[500]}
        />
      </SwitchLabelStyled>
      <SwitchDescriptionStyled>By enabling Pull Request Comments Myrror will add a comment with all the detected issues that were detected during the PR scan.</SwitchDescriptionStyled>
      {isDisabled && <SwitchDescriptionCaptionStyled>* Commenting is not possible when connecting with read-only access to your SCM</SwitchDescriptionCaptionStyled>}
    </PRCommentsContainerStyled>
  );
};
