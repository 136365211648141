import React from 'react';
import { useNotificationSockets } from './useNotificationSockets.hook';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainerStyled } from './styled';

export const ToastCenter: React.FC = () => {
  useNotificationSockets();
  return (
    <ToastContainerStyled
      position="bottom-center"
      autoClose={5000}
      limit={3}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover
      stacked
    />
  );
};
