import { useQuery } from 'react-query';
import { fetchReachability } from '../api';
import { IApiReachabilityParams, IReachability } from '../types';
import { useSession } from '@descope/react-sdk';

export const useReachability = (params: IApiReachabilityParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const queryOptions = {
    queryKey: ['issueReachability', params],
    queryFn: async () => {
      return await fetchReachability(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };
  return useQuery<IReachability[]>(queryOptions);
};
