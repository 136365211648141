import styled from 'styled-components';
import { Link } from '../../../Link';

export const UserDropdownLinkStyled = styled(Link)`
  padding: ${(props) => `${props.theme.spacing['1']} ${props.theme.spacing['2']}`};
  border-radius: 10px;
  transition: 200ms;
  color: ${(props) => props.theme.colors.zinc['800']} !important;
  font-size: ${({ theme }) => theme.text.size.body2};
  line-height: 28px;
  letter-spacing: 0.15px;

  &:before {
    color: ${(props) => props.theme.colors.zinc['900']};
  }

  &:hover {
    background: ${(props) => props.theme.colors.zinc['100']};
  }
`;
