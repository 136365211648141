import React from 'react';
import { useDrawer } from '@Drawer';
import { ViewExportButton } from './styled';
import { ExportsDrawer } from '../ExportsDrawer';

export const ExportsViewButton: React.FC = () => {
  const { openDrawer } = useDrawer();

  const onViewExports = () => {
    openDrawer(<ExportsDrawer />);
  };

  return <ViewExportButton onClick={onViewExports}>view</ViewExportButton>;
};
