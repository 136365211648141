import React from 'react';
import { LinkStyled, IntegrationUpgradeMessageStyled } from './styled';
import { pageTools } from '@Utils';
import { IIntegrationUpgradeMessageProps } from './interfaces';

export const IntegrationUpgradeMessage: React.FC<IIntegrationUpgradeMessageProps> = ({ scmLabel }) => {
  return (
    <IntegrationUpgradeMessageStyled>
      To allow commenting on Pull Requests checks you must upgrade your {scmLabel} application on the <LinkStyled to={pageTools.integrations.path}>Integrations page</LinkStyled>
    </IntegrationUpgradeMessageStyled>
  );
};
