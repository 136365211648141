import React from 'react';
import { IMaliciousPackage } from '@APITypes';
import { LinkLabelsStyled, SubTitleStyled } from './styled';
import { LinkStyled } from '../styled';

export const MaliciousPackagesLinkLabel: React.FC<{ maliciousPackage: IMaliciousPackage }> = ({ maliciousPackage }) => {
  return (
    <LinkLabelsStyled>
      <SubTitleStyled>OSV Detection Details:</SubTitleStyled>
      <LinkStyled target="_blank" rel="noreferrer" href={maliciousPackage.url}>
        {maliciousPackage.malId}
      </LinkStyled>
    </LinkLabelsStyled>
  );
};
