import { faCodeBranch, faCodeCompare, faCube } from '@fortawesome/pro-regular-svg-icons';
import { IRepositoryCardConnectionsProps } from '../interfaces';
import { Tooltip } from '@Components';
import { InfoLabel } from './InfoLabel';
import { RepositoryCardConnectionsStyled } from '../styled';

export const RepositoryCardConnections: React.FC<IRepositoryCardConnectionsProps> = ({ dependenciesCount, branchesCount, buildsCount }: IRepositoryCardConnectionsProps) => {
  return (
    <RepositoryCardConnectionsStyled>
      <Tooltip tooltip={`${dependenciesCount} ${dependenciesCount == 1 ? 'Dependency' : 'Dependencies'}`}>
        <InfoLabel icon={faCodeCompare}>{dependenciesCount}</InfoLabel>
      </Tooltip>
      <Tooltip tooltip={`${branchesCount || 0} ${branchesCount == 1 ? 'Branch' : 'Branches'}`}>
        <InfoLabel icon={faCodeBranch}>{branchesCount || 0}</InfoLabel>
      </Tooltip>
      <Tooltip tooltip={`${buildsCount || 0}  ${buildsCount == 1 ? 'Build' : 'Builds'}`}>
        <InfoLabel icon={faCube}>{buildsCount || 0}</InfoLabel>
      </Tooltip>
    </RepositoryCardConnectionsStyled>
  );
};
