import styled from 'styled-components';

export const DetailsStyled = styled.div`
  font-size: ${({ theme }) => theme.text.size.body1};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing[3]} 0px;
  border-radius: ${({ theme }) => theme.spacing[1]};
  line-height: 24px;
  min-height: 450px;
`;
