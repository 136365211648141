import { ERepositoryProgressBarType, IRepositoryProgressBar } from '../interfaces';

export const getProgressBarTitle = (progressBar: IRepositoryProgressBar): string => {
  const { type, total, seen } = progressBar;
  if (type === ERepositoryProgressBarType.TAMPERING) {
    return `Scanning for tampered dependencies - ${seen} of ${total} Dependencies`;
  }
  if (type === ERepositoryProgressBarType.REMEDIATION) {
    return `Building a remediation plan for - ${seen} of ${total} Severe Issues`;
  }
  if (type === ERepositoryProgressBarType.REACHABILITY) {
    return `Calculating issue reachability for - ${seen} of ${total} Severe Issues`;
  }
  if (type === ERepositoryProgressBarType.VULNERABILITIES) {
    return `Scanning for vulnerabilities - ${seen} of ${total} Dependency Files`;
  }
  return '';
};
