import { useEffect, useState } from 'react';
import { IDrawerProps } from './types';
import { DrawerBackgroundStyled, DrawerContainerStyled, DrawerContentContainerStyled } from './styled';

export const Drawer = ({ open, onClose, children }: IDrawerProps) => {
  const [showContent, setShowConent] = useState(false);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setShowConent(false);
      }, 200);
    }
    if (open) {
      setShowConent(true);
    }
  }, [open]);

  const handleClickContainer = () => {
    onClose && onClose();
  };

  return (
    <DrawerContainerStyled $open={open}>
      <DrawerBackgroundStyled onClick={handleClickContainer} />
      <DrawerContentContainerStyled $open={open}>{showContent && children}</DrawerContentContainerStyled>
    </DrawerContainerStyled>
  );
};
