import styled, { css } from 'styled-components';

export const NoDataLabel = styled.p(
  ({ theme }) => css`
    font-size: ${theme.text.size.body1};
    font-weight: ${theme.text.weight.regular};
    margin: 0 0 ${theme.spacing[2]} 0;
    color: ${theme.colors.zinc[800]};
  `,
);
