import styled from 'styled-components';

export const RemediationEffortClassNameContainerStyled = styled.div`
  padding: 0 ${({ theme }) => theme.spacing[0.5]};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.zinc[300]};
  border-radius: ${({ theme }) => theme.spacing[0.5]};
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  line-height: 155%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
