import { Logo } from 'Layouts/IntegrationsPage/components/Integrator/styled';
import { SignRender } from '@Components';

import { TitleContainerStyled } from './styled';
import { IIntegrationTitleProps } from './types';

export const IntegrationTitle = ({ titleSign, title }: IIntegrationTitleProps) => (
  <TitleContainerStyled>
    <Logo>
      <SignRender name={titleSign} />
    </Logo>
    Connect to {title}
  </TitleContainerStyled>
);
