import styled from 'styled-components';

export const MaliciousPackagePreStyled = styled.pre`
  font-size: ${({ theme }) => theme.text.size.body1};
  font-family: ${({ theme }) => theme.text.fontFamily};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  padding: 0;
  white-space: pre-wrap;

  & > h2 {
    font-size: ${({ theme }) => theme.text.size.subtitle1};
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }

  & > ul {
    list-style: disc;
    padding-left: ${({ theme }) => theme.spacing['2']};
    line-height: 0.5;
  }
`;
