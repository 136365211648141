import styled, { css } from 'styled-components';
import { Flex } from '@Styles';

export const DrawerHeader = styled(Flex)(
  ({ theme }) => css`
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing[4.5]} ${theme.spacing[3]} ${theme.spacing[3.5]} ${theme.spacing[4.5]};
  `,
);
