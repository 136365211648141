import React, { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { LoadingScreen, Tooltip } from '@Components';
import { ModalProvider } from '@Modal';
import { ENotificationType, toastService } from '@Notification';
import { Button } from '@Styles';
import { DefaultLabel } from 'Layouts/DiscoveryPage/components/SubComponents/MonitoringPreferences/styled';
import {
  ScanConfigSetsStyled,
  ScanConfigSetsHeaderStyled,
  ScanConfigSetListStyled,
  ScanConfigSetListItemStyled,
  ScanConfigSetListTitleStyled,
  ScanConfigSetsLeftStyled,
  ScanConfigSetListItemLabelStyled,
  AddIconStyled,
} from './styled';
import { useScanConfigSets, useScanConfigSetCreate } from './hooks';
import { untitledScanConfigSetName } from './constants';

export const ScanConfigSets: React.FC = () => {
  const navigate = useNavigate();
  const { scanConfigSetUuid } = useParams();
  const queryClient = useQueryClient();
  const { data: scanConfigSets = [], isLoading } = useScanConfigSets();
  const { mutate: createScanConfigSetApi, isLoading: isCreatingScanConfigSet } = useScanConfigSetCreate();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (scanConfigSets.length && isInitialLoad && !scanConfigSetUuid) {
      setIsInitialLoad(false);
      navigate(scanConfigSets[0].uuid);
    }
  }, [scanConfigSets]);

  const createScanConfigSet = () => {
    createScanConfigSetApi(
      { name: untitledScanConfigSetName },
      {
        onSuccess: (createdScanConfig) => {
          queryClient.invalidateQueries('scanConfigSets');
          navigate(createdScanConfig.uuid);
        },
        onError: () => {
          toastService({
            header: 'An error occurred while creating scan config',
            type: ENotificationType.ERROR,
          });
        },
      },
    );
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ModalProvider>
      <ScanConfigSetsStyled>
        <ScanConfigSetsLeftStyled>
          <ScanConfigSetsHeaderStyled>
            <ScanConfigSetListTitleStyled>Scan configurations</ScanConfigSetListTitleStyled>
            <Tooltip tooltip="Create new">
              <Button disabled={isCreatingScanConfigSet} onClick={createScanConfigSet}>
                <AddIconStyled />
              </Button>
            </Tooltip>
          </ScanConfigSetsHeaderStyled>
          <ScanConfigSetListStyled>
            {scanConfigSets.map((scanConfigSet) => (
              <Link to={`/settings/scan-configurations/${scanConfigSet.uuid}`} key={scanConfigSet.uuid}>
                <ScanConfigSetListItemStyled $active={scanConfigSet.uuid === scanConfigSetUuid}>
                  <ScanConfigSetListItemLabelStyled>{scanConfigSet.name}</ScanConfigSetListItemLabelStyled>
                  {scanConfigSet.isDefault && <DefaultLabel $transparent>Default</DefaultLabel>}
                </ScanConfigSetListItemStyled>
              </Link>
            ))}
          </ScanConfigSetListStyled>
        </ScanConfigSetsLeftStyled>
        <Outlet />
      </ScanConfigSetsStyled>
    </ModalProvider>
  );
};
