import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useModal } from '@Modal';
import { ActionsDropdown } from 'core/Dropdown';
import { Button } from '@Styles';
import { ENotificationType, toastService } from '@Notification';
import { useScanConfigSetCreate, useScanConfigSetDelete, useScanConfigSetUpdate } from 'Layouts/SettingsPage/components/ScanConfigs/hooks';
import { ScanConfigSetActionModal } from '../ScanConfigSetActionModal';
import { IScanConfigSetActionsMenuProps } from './interfaces';

export const ScanConfigSetActionsMenu: React.FC<IScanConfigSetActionsMenuProps> = ({ configSet }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openModal } = useModal();
  const { mutate: deleteScanConfigSetApi } = useScanConfigSetDelete();
  const { mutate: createScanConfigSetApi } = useScanConfigSetCreate();
  const { mutate: updateScanConfigSetApi } = useScanConfigSetUpdate();

  const deleteScanConfigSet = () => {
    deleteScanConfigSetApi(configSet.uuid, {
      onSuccess: () => {
        queryClient.invalidateQueries('scanConfigSets');
        toastService({
          header: 'Scan config deleted successfully',
          type: ENotificationType.SUCCESS,
        });
        navigate('/settings/scan-configurations');
      },
      onError: () => {
        toastService({
          header: 'An error occurred while deleting scan config',
          type: ENotificationType.ERROR,
        });
      },
    });
  };

  const duplicateScanConfigSet = () => {
    const name = `${configSet.name} (copy)`;

    createScanConfigSetApi(
      { ...configSet, name },
      {
        onSuccess: (createdScanConfig) => {
          queryClient.invalidateQueries('scanConfigSets');
          navigate(`/settings/scan-configurations/${createdScanConfig.uuid}`);
        },
        onError: () => {
          toastService({
            header: 'An error occurred while duplicating scan config',
            type: ENotificationType.ERROR,
          });
        },
      },
    );
  };

  const setScanConfigAsDefault = async () => {
    await updateScanConfigSetApi(
      { isDefault: true, uuid: configSet.uuid },
      {
        onSuccess: () => queryClient.invalidateQueries('scanConfigSets'),
        onError: () => {
          toastService({
            header: 'An error occurred while updating scan config',
            type: ENotificationType.ERROR,
          });
        },
      },
    );
  };

  const onClickDelete = () => {
    if (configSet.repositoriesCount > 0) {
      const content = <p>All repositories using that configuration will switch to current default configuration.</p>;
      openModal(<ScanConfigSetActionModal repositoriesCount={configSet.repositoriesCount} content={content} onSave={deleteScanConfigSet} buttonLabel="Delete" />);
    } else {
      deleteScanConfigSet();
    }
  };

  const menuItems = [
    <Button key="scan-config-duplicate" onClick={duplicateScanConfigSet}>
      Duplicate
    </Button>,
  ];

  if (!configSet.isDefault) {
    menuItems.push(
      <Button key="scan-config-delete" onClick={onClickDelete}>
        Delete
      </Button>,
    );
    menuItems.push(
      <Button key="scan-config-default" onClick={setScanConfigAsDefault}>
        Set as default
      </Button>,
    );
  }

  return <ActionsDropdown isIconView={true} menuItems={menuItems} />;
};
