export function isEmpty(value: unknown): boolean {
  // Null or undefined are "empty"
  if (value == null) {
    return true;
  }

  // Check if the value is a boolean or a number
  if (typeof value === 'boolean' || typeof value === 'number') {
    return true;
  }

  // If it's an array, check its length
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  // If it's an object, check the number of keys
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  // If it's a string, check its length
  if (typeof value === 'string') {
    return value.length === 0;
  }

  // For anything else, return false (it's not "empty")
  return false;
}
