import { firstLetterToUpperCase } from 'Utils/stringTransformers';
import { DevLanguage } from '@blindspot/common/types/bff/scm';

export const supportedLanguagesTransformer = (language) => {
  let label = '';

  switch (language) {
    case DevLanguage.CSHARP:
      label = 'C#';
      break;
    case DevLanguage.JAVASCRIPT_TYPESCRIPT:
      label = 'JavaScript/TypeScript';
      break;
    case DevLanguage.NODEJS:
      label = 'JavaScript/TypeScript';
      break;
    default:
      label = firstLetterToUpperCase(language);
      break;
  }

  return label;
};
