import { axiosConfig as axios } from '@Utils';
import { ICreateTicketRequestPayload } from '../../types';

export const createIssueTicket = async (token: string, data: ICreateTicketRequestPayload) => {
  const response = await axios.post(`tickets/${data.ticketManager}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
