import styled, { css } from 'styled-components';

export const IssuesHeading = styled.span(
  ({ theme }) => css`
    font-size: ${theme.text.size['h2']};
    font-weight: ${theme.text.weight.bold};
    color: ${theme.colors.zinc['800']};
    margin-right: 25px;
  `,
);
