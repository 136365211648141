import React from 'react';
import { IPRCommentMessageInputProps } from './interfaces';
import { PRCommentMessageContainerStyled, PRCommentMessageInputStyled } from './styled';

export const PRCommentMessageInput: React.FC<IPRCommentMessageInputProps> = ({ value, onChange }) => {
  const onCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <PRCommentMessageContainerStyled>
      <PRCommentMessageInputStyled name="pr-comments-message" placeholder="Add custom message" value={value} onChange={onCommentChange} />
    </PRCommentMessageContainerStyled>
  );
};
