import React from 'react';
import { Loader } from '../Loader';
import { Container } from './styled';

export const LoadingScreen: React.FC = () => {
  return (
    <Container>
      <Loader />
    </Container>
  );
};
