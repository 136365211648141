import { useMutation } from 'react-query';
import { repositoryRemonitor } from '../api';
import { EMonitoringStatus } from '@APITypes';
import { useSession } from '@descope/react-sdk';

export const useRepositoryReMonitor = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(
    async (payload: { uuid: string; monitoringStatus: EMonitoringStatus }) => {
      if (isAuthenticated && !isSessionLoading) {
        return await repositoryRemonitor(payload, sessionToken);
      }
    },
    {
      retry: false,
    },
  );
};
