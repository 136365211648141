import React, { useState } from 'react';
import { IChartLoadingProviderProps } from '../interfaces';
import { ChartLoadingContext } from './ChartLoadingContext';

export const ChartLoadingProvider: React.FC<IChartLoadingProviderProps> = ({ children }) => {
  const [loadingCharts, setLoadingCharts] = useState(0);

  const setLoadingChart = (isLoading: boolean) => {
    if (isLoading) {
      setLoadingCharts((prev) => prev + 1);
    } else {
      setLoadingCharts((prev) => (prev - 1 ? prev - 1 : 0));
    }
  };

  const value = React.useMemo(
    () => ({
      loadingCharts,
      setLoadingChart,
    }),
    [loadingCharts],
  );

  return <ChartLoadingContext.Provider value={value}>{children}</ChartLoadingContext.Provider>;
};
