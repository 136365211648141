import { useTheme } from 'styled-components';
import { faCircleXmark, faCircleCheck, faTimer } from '@fortawesome/pro-solid-svg-icons';

import { EPullRequestStatus } from '@blindspot/common/types/bff';
import { IconStyled } from './styled';
import { IPullRequestStatusIconProps } from './interface';

export const PullRequestStatusIcon = ({ status }: IPullRequestStatusIconProps) => {
  const theme = useTheme();
  let icon;
  let color;
  switch (status) {
    case EPullRequestStatus.MERGE_BLOCKED:
      icon = faCircleXmark;
      color = theme.colors.rose[600];
      break;
    case EPullRequestStatus.READY_TO_MERGE:
    case EPullRequestStatus.MERGED:
      icon = faCircleCheck;
      color = theme.colors.green[600];
      break;
    case EPullRequestStatus.WAITING_SCAN:
      icon = faTimer;
      color = theme.colors.zinc[600];
      break;
    default:
      icon = faCircleXmark;
      color = theme.colors.rose[600];
  }

  return <IconStyled icon={icon} color={color} />;
};
