import styled from 'styled-components';

export const AuthErrorPageContainerStyled = styled.div`
  background-position: right;
  background-image: url('${window.location.origin}/myrror_bg.svg');
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;
