import React, { createContext } from 'react';
import { useConnection } from '@Utils';
import { IIntegrations } from './types';
import { isIntegrated } from './isInegrated';

export const IntegrationsContext = createContext<IntegratedProvider>({
  integrations: [],
  integrationsLoading: false,
  isIntegrated: false,
});

export interface IntegratedProvider {
  integrations: IIntegrations[];
  integrationsLoading: boolean;
  isIntegrated: boolean;
}

export const IntegratedProvider = ({ children }: { children: React.ReactNode }) => {
  const { integrations, integrationsLoading } = useConnection();

  const value = React.useMemo(() => {
    return {
      integrations,
      integrationsLoading,
      isIntegrated: isIntegrated(integrations),
    };
  }, [integrations, integrationsLoading]);

  return <IntegrationsContext.Provider value={value}>{children}</IntegrationsContext.Provider>;
};
