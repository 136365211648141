import { useQuery } from 'react-query';
import { fetchPullRequest } from '../api';
import { IApiPullRequestParams } from '../types';
import { IPullRequest } from '@blindspot/common/types/bff/api';
import { useSession } from '@descope/react-sdk';

export const usePullRequest = ({ isEnabled, ...params }: IApiPullRequestParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['pullRequest', params],
    queryFn: async () => {
      return await fetchPullRequest(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && isEnabled && !isSessionLoading,
  };

  return useQuery<IPullRequest>(queryOptions);
};
