export const isGitLabTokenValid = (token: string) => {
  const prefix = 'glpat-';
  const remainingTokenLength = 20;

  if (!token.startsWith(prefix)) {
    return false;
  }

  const remainingToken = token.slice(prefix.length);
  if (remainingToken.length !== remainingTokenLength) {
    return false;
  }

  const alphanumericRegex = /^[a-z0-9-_]+$/i;
  if (!alphanumericRegex.test(remainingToken)) {
    return false;
  }

  return true;
};
