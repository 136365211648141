import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/pro-regular-svg-icons';

export const AddIconStyled = styled(FontAwesomeIcon).attrs({
  icon: faAdd,
})(
  ({ theme }) => css`
    padding: ${theme.spacing[1.5]};
    color: ${theme.colors.indigo[400]};
    cursor: pointer;
  `,
);
