import React, { createContext } from 'react';
import { ETicketManager } from '@blindspot/common/types/bff/tickets';
import { IParagonProvider } from './types';
import { useParagon } from './hooks';

export const ParagonContext = createContext<IParagonProvider>({
  ticketManagers: [],
  isLoading: false,
});

export const ParagonProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, paragon, isLoading } = useParagon();

  const paragonIntegrations = paragon.getIntegrationMetadata().map((integration) => {
    const isEnabled = user.authenticated && user.integrations[integration.type]?.enabled;
    const onConnect = () => paragon.connect(integration.type, {});
    return { ...integration, isEnabled, onConnect };
  });

  const ticketManagers = paragonIntegrations.filter((integration) => {
    return Object.values(ETicketManager).includes(integration.type as ETicketManager);
  });

  const value = React.useMemo(() => {
    return {
      ticketManagers,
      isLoading,
    };
  }, [paragon, paragonIntegrations, isLoading]);

  return <ParagonContext.Provider value={value}>{children}</ParagonContext.Provider>;
};
