import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ScanConfigSetsHeaderStyled = styled(Flex)(
  ({ theme }) => css`
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing[2]} ${theme.spacing[1.5]} ${theme.spacing[2]} ${theme.spacing[2]};
  `,
);
