import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationListContainerStyled = styled(Flex)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.zinc[100]};
  margin-top: ${({ theme }) => theme.spacing[2]};
  gap: ${({ theme }) => theme.spacing[2.5]};
  border-radius: ${({ theme }) => theme.spacing[1]};
`;
