import styled from 'styled-components';

export const RemediationDependenciesStyled = styled.ul<{ $isToggled: boolean }>`
  position: relative;
  list-style-type: none;
  height: ${({ $isToggled }) => ($isToggled ? 'auto' : '0')};
  transition: all 200ms ease;
  z-index: 0;
  overflow: hidden;
`;
