import { PageToolCollectionType, pagesEnum } from './types';

export const pageTools: PageToolCollectionType = {
  [pagesEnum.DASHBOARD]: {
    path: `/dashboard`,
    title: 'Dashboard',
    name: pagesEnum.DASHBOARD,
  },
  [pagesEnum.ISSUES]: {
    path: `/${pagesEnum.ISSUES}`,
    title: 'Issues',
    name: pagesEnum.ISSUES,
  },
  [pagesEnum.PRODUCTION]: {
    path: `/${pagesEnum.PRODUCTION}`,
    title: 'Production',
    name: pagesEnum.PRODUCTION,
  },
  [pagesEnum.DISCOVERY]: {
    path: `/${pagesEnum.DISCOVERY}`,
    title: 'Discovery',
    name: pagesEnum.DISCOVERY,
  },
  [pagesEnum.INTTEGRATIONS]: {
    path: `/${pagesEnum.INTTEGRATIONS}`,
    title: 'Integrations',
    name: pagesEnum.INTTEGRATIONS,
  },
  [pagesEnum.SETTINGS]: {
    path: `/${pagesEnum.SETTINGS}`,
    title: 'Settings',
    name: pagesEnum.SETTINGS,
  },
};
