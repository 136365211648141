import { axiosConfig as axios } from '@Utils';

export const repositoryRemonitor = async (payload: { uuid: string; monitoringStatus }, token: string) => {
  return axios.post(
    'repositories/remonitor',
    {
      repository: {
        uuid: payload.uuid,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
