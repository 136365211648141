import { Flex } from '@Styles';
import styled from 'styled-components';

export const FailedPullRequestsRemainderStyled = styled(Flex)`
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  font-color: ${({ theme }) => theme.colors.zinc[800]};
`;
