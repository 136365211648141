import { Button } from '@Styles';
import styled from 'styled-components';

export const NavActionContainerStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[0.5]};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  padding: 0 ${({ theme }) => theme.spacing[1]};
  margin: 0;
  font-weight: ${({ theme }) => theme.text.weight.bold};
  border: 1px solid ${({ theme }) => theme.colors.indigo[300]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  height: 28px;
  & > * {
    color: ${({ theme }) => theme.colors.indigo[500]};
  }
`;
