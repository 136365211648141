import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, UseControllerReturn, useForm } from 'react-hook-form';
import { DropdownSelect, Loader } from '@Components';
import { Checkbox, Button, ButtonModes } from '@FormElements';
import {
  DefaultLabel,
  PreferencesLoaderWrapper,
  PreferencesSectionTitle,
  PreferencesWrapper,
  PreferencesActionsWrapper,
  PreferencesActions,
  Caption,
  CancelButton,
  PreferencesRightStyled,
} from './styled';
import { IMonitoringPreferencesProps, IMonitoringPreferencesFormData } from './interfaces';
import { useRepositoryMonitoringPreferences } from './hooks';
import { ScanConfigSetSelect, PreferencesScanCoverage } from './components';

export const MonitoringPreferences: React.FC<IMonitoringPreferencesProps> = ({ repositoryId, isEditMode = false, onMonitor, onClose }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const { data: monitoringPreferences = { branches: [], scanConfigSets: [] }, isLoading } = useRepositoryMonitoringPreferences(repositoryId);

  const defaultScanConfig = monitoringPreferences?.scanConfigSets?.find(({ isDefault }) => isDefault);
  const activeScanConfig = monitoringPreferences?.scanConfigSets?.find(({ active }) => active);
  const defaultValues: IMonitoringPreferencesFormData | {} = monitoringPreferences
    ? {
        branch: monitoringPreferences?.branches[0]?.name,
        scanConfigSet: activeScanConfig ? activeScanConfig?.uuid : defaultScanConfig?.uuid,
      }
    : {};
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, errors },
  } = useForm<IMonitoringPreferencesFormData>({ defaultValues: { ...defaultValues }, mode: 'onChange' });

  useEffect(() => {
    const shouldResetForm = !isLoading;
    if (shouldResetForm) {
      reset(defaultValues);
    }
  }, [isLoading]);

  const branchSelectOptions = monitoringPreferences?.branches?.map((branch) => {
    const labels = [];
    if (branch.active && isEditMode) {
      labels.push('Monitored');
    }
    if (branch.default) {
      labels.push('Default');
    }
    return {
      value: branch.name,
      name: branch.name,
      label: labels.length ? labels.map((label) => <DefaultLabel key={label}>{label}</DefaultLabel>) : undefined,
    };
  });

  const renderBranchSelect = ({ field: { onChange, onBlur, value }, fieldState: { error } }: UseControllerReturn<IMonitoringPreferencesFormData, 'branch'>) => (
    <DropdownSelect
      name="branch"
      label="Branch to Scan"
      placeholder=""
      value={value}
      options={branchSelectOptions ?? []}
      error={error?.message}
      onBlur={onBlur}
      onChange={(value) => {
        onChange(value);
      }}
    />
  );

  const toggleDontShowAgain = () => {
    setDontShowAgain((prevState) => !prevState);
  };

  const onSubmit: SubmitHandler<IMonitoringPreferencesFormData> = (formData: IMonitoringPreferencesFormData) => {
    if (dontShowAgain) {
      localStorage.setItem('monitoringPreferencesDisabled', 'true');
    }
    onMonitor(formData.branch, formData.scanConfigSet);
    onClose();
  };

  const submitDisabled = !isValid || !!Object.keys(errors).length || (isEditMode && !isDirty);

  if (isLoading || !monitoringPreferences) {
    return (
      <PreferencesLoaderWrapper>
        <Loader />
      </PreferencesLoaderWrapper>
    );
  }

  return (
    <>
      <PreferencesWrapper>
        <PreferencesScanCoverage />
        <PreferencesRightStyled>
          <div>
            <Controller name="branch" control={control} render={renderBranchSelect} rules={{ required: 'Please select branch' }} />
          </div>
          <div>
            <PreferencesSectionTitle>Scan configuration</PreferencesSectionTitle>
            <ScanConfigSetSelect control={control} scanConfigSets={monitoringPreferences.scanConfigSets} />
          </div>
        </PreferencesRightStyled>
      </PreferencesWrapper>

      <PreferencesActionsWrapper>
        <Checkbox
          label={
            <>
              <p>Don't show the preference screen again</p>
              <Caption>By selecting this option, we will automatically select the default branch on your next new repository monitoring</Caption>
            </>
          }
          checked={dontShowAgain}
          onChange={toggleDontShowAgain}
        />
        <PreferencesActions>
          <CancelButton $mode={ButtonModes.TransparentAdditional} onClick={onClose}>
            Cancel
          </CancelButton>
          <Button disabled={submitDisabled} styleMode={ButtonModes.Monitor} onClick={handleSubmit(onSubmit)}>
            Monitor
          </Button>
        </PreferencesActions>
      </PreferencesActionsWrapper>
    </>
  );
};
