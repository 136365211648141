import styled from 'styled-components';

export const Container = styled.section`
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing['2']};
  border-radius: 13px;
  box-shadow: 0 0 12px #2d3ea30a;
  width: 245px;
  font-family: 'Manrope', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;
