import styled, { css } from 'styled-components';

export const ControlInputChipStyled = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  word-break: break-word;
  line-height: 155%;
  letter-spacing: 0.16px;
  padding: ${({ theme }) => css`2px ${theme.spacing[1]}`};
  background: ${({ theme }) => theme.colors.zinc[100]};
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.zinc[500]};
`;
