import styled, { css } from 'styled-components';
import { Flex } from '@Styles';

export const StyledCheckbox = styled(Flex)<{ $checked: boolean }>(
  ({ theme, $checked }) => css`
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1.5px solid ${theme.colors.zinc[500]};
    border-radius: 4px;
    background-color: ${$checked ? theme.colors.indigo[500] : 'transparent'};
    border-color: ${$checked ? theme.colors.indigo[500] : theme.colors.zinc[500]};
    transition: background-color 0.1s;
  `,
);
