import { useContext, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { LoadingScreen } from '@Components';
import { IntegrationsContext } from '@Utils';
import { useFilter } from 'core/Filter';
import { useRepositoriesSummary } from '@Hooks';
import { ModalProvider } from '@Modal';
import {
  RepositoryFilter,
  SummarySection,
  FailedPullRequestsRemainder,
  NoIssuesContent,
  NoMonitoringContentDetection,
  NoMonitoringContentVisibility,
  DashboardScore,
  DashboardDetectionSection,
  DashboardVisibilitySection,
  NoIntegrationContent,
} from './components';
import { ChartLoadingProvider } from './context';
import { ActionContainerStyled, DashboardContainerStyled, DashboardHederStyled, LoaderContainerStyled, SectionTitleStyled } from './styled';
import { useDashboardIssues } from './hooks';

export const DashboardPage = () => {
  const theme = useTheme();
  const { isIntegrated, integrationsLoading } = useContext(IntegrationsContext);
  const dashboardPageRef = useRef<HTMLDivElement>(null);
  const { appliedFilters } = useFilter();
  const [isReportLoading, setIsReportLoading] = useState(false);

  const { data: repositoriesSummary, isLoading: repositoriesSummaryLoading } = useRepositoriesSummary({ filter: appliedFilters });
  const { data: dashboardIssues, isLoading: dashboardIssuesLoading } = useDashboardIssues({ filter: appliedFilters });

  const hasIssues = !!(dashboardIssues?.total?.direct + dashboardIssues?.total?.indirect);

  const isLoading = repositoriesSummaryLoading || integrationsLoading || dashboardIssuesLoading;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isIntegrated) {
    return <NoIntegrationContent />;
  }

  const isNotMonitored = isIntegrated && !repositoriesSummary?.monitored && !repositoriesSummary?.scanning;

  return (
    <ModalProvider>
      <ChartLoadingProvider>
        {isReportLoading && (
          <LoaderContainerStyled>
            <LoadingScreen />
          </LoaderContainerStyled>
        )}
        <div ref={dashboardPageRef}>
          <DashboardContainerStyled id="pdfPage1">
            {!isNotMonitored && (
              <DashboardHederStyled>
                <DashboardScore />
                <ActionContainerStyled>
                  <RepositoryFilter />
                  {/*<CreateReportButton html={dashboardPageRef.current} setIsReportLoading={setIsReportLoading} />*/}
                </ActionContainerStyled>
              </DashboardHederStyled>
            )}
            <FailedPullRequestsRemainder />
            {!isNotMonitored && <SummarySection />}
            <SectionTitleStyled>Detection</SectionTitleStyled>
            {isNotMonitored && <NoMonitoringContentDetection />}
            {!isNotMonitored && !hasIssues && <NoIssuesContent />}
            {hasIssues && <DashboardDetectionSection />}
          </DashboardContainerStyled>
          <DashboardContainerStyled id="pdfPage2" $marginTop={theme.spacing[4]}>
            <SectionTitleStyled>Visibility</SectionTitleStyled>
            {isNotMonitored ? <NoMonitoringContentVisibility /> : <DashboardVisibilitySection />}
          </DashboardContainerStyled>
        </div>
      </ChartLoadingProvider>
    </ModalProvider>
  );
};
