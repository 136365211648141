import { axiosConfig as axios } from '@Utils';
import { IApiPullRequestParams } from '../types';

export const fetchPullRequest = async ({ repoId, pullRequestUuid }: IApiPullRequestParams, token: string) => {
  const response = await axios(`repositories/${repoId}/pull-requests/${pullRequestUuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
