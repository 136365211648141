import { axiosConfig as axios } from '@Utils';

export const getIntegrationOrganizations = async (provider: string, token: string) => {
  const response = await axios.get(`scm/integrate/${provider}/organizations`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
