import { Node, Edge } from 'reactflow';
import { uniqBy } from 'lodash';
import { createNode } from './createNode';
import { IReachabilityChain } from '@blindspot/common/types/bff/api';
import { createEdge } from '../../../IssueDependencyChain/utils/createEdge';
import { getChainItemId } from '../../utils';
import { calculateWidth } from './calculateWidth';
import { IChainNode } from '../types';

export function createNodesAndEdges(chains: IReachabilityChain[]): { nodes: Node[]; edges: Edge[] } {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  if (!chains || !chains.length) {
    return { nodes, edges };
  }

  chains.forEach((chain) => {
    const chainItems = Object.values(chain);
    chainItems.map((chainItem, chainItemIndex) => {
      const chainItemId = getChainItemId(chainItem);

      const nodeData: IChainNode = {
        id: chainItemId,
        primaryText: chainItem.name || '',
        secondaryText: `(${chainItem.file})`,
        usagesText: '',
        isVulnerableFunction: chainItemIndex === chainItems.length - 1,
      };

      const node: Node = createNode(nodeData);
      nodes.push(node);

      if (chainItemIndex > 0) {
        const previousChainItemId = getChainItemId(chainItems[chainItemIndex - 1]);
        edges.push(createEdge(previousChainItemId, chainItemId));
      }
    });
  });

  nodes.forEach((node) => {
    const usageCount = uniqBy(edges, 'id').filter((edge) => edge.source === node.id).length;
    node.data.usagesText = usageCount > 1 ? `(${usageCount} usages)` : '';
    node.width = calculateWidth(node.data.primaryText, node.data.secondaryText, node.data.usagesText);
  });

  return { nodes: uniqBy(nodes, 'id'), edges: uniqBy(edges, 'id') };
}
