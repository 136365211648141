import { ICVSSVector } from '../interfaces';

const attackVector: ICVSSVector = {
  name: 'Attack Vector',
  code: 'AV',
  metrics: [
    {
      name: 'Network',
      code: 'N',
      description:
        "A vulnerability exploitable with Network access means the vulnerable component is bound to the network stack, and the attacker's path is through OSI layer 3 (the network layer). Such a vulnerability is often termed 'remotely exploitable' and can be thought of as an attack being exploitable one or more network hops away (e.g., across layer three boundaries from routers).",
    },
    {
      name: 'Adjacent',
      code: 'A',
      description:
        'A vulnerability exploitable with Adjacent Network access means the vulnerable component is bound to the network stack. However, the attack is limited to the same shared physical (e.g., Bluetooth, IEEE 802.11) or logical (e.g., local IP subnet) network and cannot be performed across an OSI layer 3 boundary (e.g., a router).',
    },
    {
      name: 'Local',
      code: 'L',
      description:
        "A vulnerability exploitable with Local access means that the vulnerable component is not bound to the network stack, and the attacker's path is via read/write/execute capabilities. In some cases, the attacker may be logged in locally in order to exploit the vulnerability, or may rely on User Interaction to execute a malicious file.",
    },
    {
      name: 'Physical',
      code: 'P',
      description:
        'A vulnerability exploitable with Physical access requires the attacker to physically touch or manipulate the vulnerable component, such as attaching an peripheral device to a system.',
    },
  ],
  description:
    'This metric reflects the context in which vulnerability exploitation is possible. This metric value will be larger the more remote (logically and physically) an attacker can be in exploiting the vulnerable component.',
};

const attackComplexity: ICVSSVector = {
  name: 'Attack Complexity',
  code: 'AC',
  metrics: [
    {
      name: 'Low',
      code: 'L',
      description: 'Specialized access conditions or extenuating circumstances do not exist. An attacker can expect repeatable success against the vulnerable component.',
    },
    {
      name: 'High',
      code: 'H',
      description:
        "A successful attack depends on conditions beyond the attacker's control. That is, a successful attack cannot be accomplished at will, but requires the attacker to invest in some measurable amount of effort in preparation or execution against the vulnerable component before a successful attack can be expected.",
    },
  ],
  description:
    "The Attack Complexity metric describes the conditions beyond the attacker's control that must exist in order to exploit the vulnerability. Such conditions may require the collection of more information about the target, the presence of certain system configuration settings, or computational exceptions.",
};

const privilegesRequired: ICVSSVector = {
  name: 'Privileges Required',
  code: 'PR',
  metrics: [
    { name: 'None', code: 'N', description: 'The attacker is unauthorized prior to attack, and therefore does not require any access to settings or files to carry out an attack.' },
    {
      name: 'Low',
      code: 'L',
      description:
        'The attacker is authorized with (i.e. requires) privileges that provide basic user capabilities that could normally affect only settings and files owned by a user. Alternatively, an attacker with Low privileges may have the ability to cause an impact only to non-sensitive resources.',
    },
    {
      name: 'High',
      code: 'H',
      description:
        'The attacker is authorized with (i.e. requires) privileges that provide significant (e.g. administrative) control over the vulnerable component that could affect component-wide settings and files.',
    },
  ],
  description: 'This metric describes the level of privileges an attacker must possess before successfully exploiting the vulnerability.',
};

const userInteraction: ICVSSVector = {
  name: 'User Interaction',
  code: 'UI',
  metrics: [
    { name: 'None', code: 'N', description: 'The vulnerable system can be exploited without interaction from any user.' },
    {
      name: 'Required',
      code: 'R',
      description:
        'Successful exploitation of this vulnerability requires a user to take some action before the vulnerability can be exploited, such as convincing a user to click a link in an email.',
    },
  ],
  description:
    'This metric captures the requirement for a user, other than the attacker, to participate in the successful compromise of the vulnerable component. This metric determines whether the vulnerability can be exploited solely at the will of the attacker, or whether a separate user (or user-initiated process) must participate in some manner.',
};

const scope: ICVSSVector = {
  name: 'Scope',
  code: 'S',
  metrics: [
    {
      name: 'Unchanged',
      code: 'U',
      description: 'An exploited vulnerability can only affect resources managed by the same authority. In this case the vulnerable component and the impacted component are the same.',
    },
    {
      name: 'Changed',
      code: 'C',
      description:
        'An exploited vulnerability can affect resources beyond the authorization privileges intended by the vulnerable component. In this case the vulnerable component and the impacted component are different.',
    },
  ],
  description: 'The ability for a vulnerability in one software component to impact resources beyond its means, or privileges.',
};

export const exploitabilityMetrics = [attackVector, attackComplexity, privilegesRequired, userInteraction, scope];
