import { Descope, SignInFlow } from '@descope/react-sdk';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoginAdvertisementStyled, LoginAdvertisementTextStyled, LoginContainerStyled, LoginLayoutStyled, LoginLogoStyled, LoginTermsStyled } from './styled';
import { Flex } from '@Styles';
import { LoginBackgroundShapes } from './LoginBackgroundShapes';

export const LoginPage = () => {
  const navigate = useNavigate();
  const [searchParams, setUrlSearchParams] = useSearchParams();

  const isMagicLinkFlow = searchParams.get('login-flow') === 'magic-link';
  const repoUuid = searchParams.get('r');

  const handleSuccessMagicLinkFlow = () => {
    navigate(`/discovery/repositories/${repoUuid}`);
  };

  const handleLoginSucess = () => {
    navigate('/');
  };

  return (
    <LoginLayoutStyled>
      <LoginBackgroundShapes />
      <LoginContainerStyled>
        <Flex justifyContent="flex-start">
          <LoginLogoStyled src={`${window.location.origin}/myrror-logo.svg`} />
        </Flex>
        {isMagicLinkFlow ? (
          <Descope
            flowId="magic-link"
            onSuccess={handleSuccessMagicLinkFlow}
            onError={(e) => {
              console.log(`Could not log in!, reason: ${e}`);
            }}
          />
        ) : (
          <SignInFlow onSuccess={handleLoginSucess} onError={(e) => console.log(`Could not log in!, reason: ${e}`)} />
        )}
        <LoginTermsStyled>
          By continuing, you agree to Myrror’s Terms of Service and
          <br /> Privacy Policy, and to receive periodic emails with updates
        </LoginTermsStyled>
      </LoginContainerStyled>
      <LoginAdvertisementStyled>
        <LoginAdvertisementTextStyled>
          Bringing Context To <br />
          Application Security ...
        </LoginAdvertisementTextStyled>
      </LoginAdvertisementStyled>
    </LoginLayoutStyled>
  );
};
