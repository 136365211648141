import { ETicketManager } from '@blindspot/common/types/bff/tickets';
import { axiosConfig as axios } from '@Utils';

export const getTicketManagerProjectResources = async (token: string, ticketManager: ETicketManager, projectId: string) => {
  const response = await axios.get(`/tickets/${ticketManager}/projects/${projectId}/resources`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
