import styled from 'styled-components';

export const PaginationButtonsStyled = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing[2]};
  padding: 0;
  border-radius: 13px;
  overflow: hidden;
  z-index: 2;
`;
