import { ETicketStatus, ITicket } from '@blindspot/common/types/bff/tickets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ESign, SignRender } from '@Components';
import { TicketLabelStyled } from './styled';

export const TicketLabel: React.FC<{ ticket: ITicket }> = ({ ticket }) => {
  const stopPropagation = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  const isResolved = ticket.status === ETicketStatus.RESOLVED;

  return (
    <a href={ticket.url} target="_blank" rel="noreferrer" onClick={stopPropagation}>
      <TicketLabelStyled>
        <SignRender name={ESign[ticket.manager]} size="small" top="0px" />
        {ticket.originTicketId}
        {isResolved && <FontAwesomeIcon icon={faCheck} />}
      </TicketLabelStyled>
    </a>
  );
};
