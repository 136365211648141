import styled, { css } from 'styled-components';
import { ButtonSize, ButtonVariant, ButtonColor } from '../interfaces';
import { indigoButtonVariants } from './indigoButtonVariants.styled';
import { zincButtonVariants } from './zincButtonVariants.styled';
import { Flex } from '@Styles';

export const ButtonWrapperStyled = styled(Flex)<{
  $width?: string;
  $height?: string;
  $padding?: string;
  $backgroundColor?: string;
  $textColor?: string;
  $border?: string;
  $hoverBackgroundColor?: string;
  $hoverTextColor?: string;
  $hoverBorder?: string;
  $disabledBackgroundColor?: string;
  $disabledTextColor?: string;
  $disabledBorder?: string;
  $size?: ButtonSize;
  $variant?: ButtonVariant;
  $color?: ButtonColor;
}>`
  display: inline-flex;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  font-weight: ${({ theme }) => theme.text.weight.semiBold};

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, ${({ theme }) => theme.colors.black} 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.3s, opacity 0.5s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }

  width: ${({ $width }) => ($width ? $width : '100%')};

  ${({ $size, $height, $padding }) => {
    switch ($size) {
      case ButtonSize.SMALL: {
        return css`
          height: ${({ theme }) => $height || theme.spacing[3.5]};
          padding: 0px ${({ theme }) => $padding || theme.spacing[1]};
        `;
      }
      case ButtonSize.MEDIUM: {
        return css`
          height: ${({ theme }) => $height || theme.spacing[4]};
          padding: 0px ${({ theme }) => $padding || theme.spacing[1.5]};
        `;
      }
      case ButtonSize.LARGE: {
        return css`
          height: ${({ theme }) => $height || theme.spacing[5]};
          padding: 0px ${({ theme }) => $padding || theme.spacing[2]};
        `;
      }
      default: {
        return css`
          height: ${({ theme }) => $height || theme.spacing[4]};
          padding: 0px ${({ theme }) => $padding || theme.spacing[1.5]};
        `;
      }
    }
  }}

  ${({ $color = ButtonColor.INDIGO, $variant = ButtonVariant.TEXT }) => {
    switch ($color) {
      case ButtonColor.INDIGO: {
        return indigoButtonVariants($variant);
      }
      case ButtonColor.ZINC: {
        return zincButtonVariants($variant);
      }
    }
  }}

  ${({ $border, $backgroundColor, $textColor }) => {
    return css`
      ${$border && `border: ${$border};`}
      ${$backgroundColor && `background-color: ${$backgroundColor};`}
      ${$textColor && `color: ${$textColor};`}
    `;
  }}


    ${({ $hoverBorder, $hoverBackgroundColor, $hoverTextColor }) => {
    return css`
      &:hover {
        ${$hoverBorder && `border: ${$hoverBorder};`}
        ${$hoverBackgroundColor && `background-color: ${$hoverBackgroundColor};`}
        ${$hoverTextColor && `color: ${$hoverTextColor};`}
      }
    `;
  }}


  ${({ $disabledBorder, $disabledBackgroundColor, $disabledTextColor }) => {
    return css`
      &:disabled {
        cursor: default;
        pointer-events: none;
        ${$disabledBorder && `border: ${$disabledBorder};`}
        ${$disabledBackgroundColor && `background-color: ${$disabledBackgroundColor};`}
        ${$disabledTextColor && `color: ${$disabledTextColor};`}
      `;
  }}
`;
