import { useQuery } from 'react-query';
import { IBitbucketIntegrationToken } from './types';
import { getIntegrationTokens } from '../api';
import { useSession } from '@descope/react-sdk';

export const useIntegrationTokens = (provider, enabled: boolean) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['integrationTokens'],
    queryFn: async () => {
      return await getIntegrationTokens(provider, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: enabled && isAuthenticated && !isSessionLoading,
  };

  return useQuery<IBitbucketIntegrationToken[]>(queryOptions);
};
