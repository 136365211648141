import React from 'react';
import { ScanConfigDescriptionStyled } from '../styled';
import { IScanConfigMonitoringEngineValue } from '@blindspot/common/types/bff/configs';
import {
  firstPartyMonitoringEngineOptions,
  monitoringEngineOptions,
} from 'Layouts/SettingsPage/components/ScanConfigs/components/ScanConfigDetails/components/MonitoringEngineSettings/constants';

export const MonitoringEnginesConfigSummary: React.FC<{ configValue?: IScanConfigMonitoringEngineValue }> = ({ configValue }) => {
  if (!configValue) {
    return null;
  }

  const enabledEngines = [
    ...monitoringEngineOptions.filter((option) => configValue[option.value]).map((option) => option.title),
    ...firstPartyMonitoringEngineOptions.filter((option) => configValue[option.value]).map((option) => option.title),
  ];

  return <ScanConfigDescriptionStyled>Engines: {enabledEngines.join(', ')}</ScanConfigDescriptionStyled>;
};
