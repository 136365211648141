import styled from 'styled-components';

export const FilterClearButtonStyled = styled.button`
  padding: ${({ theme }) => `${theme.spacing['1']} ${theme.spacing['1.5']}`};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.zinc['400']};
  letter-spacing: 0.4px;
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;
