import React from 'react';
import { components } from 'react-select';
import { FilterOptionCheckboxStyled, FilterOptionLabelStyled, FilterOptionStyled } from './styled';
import { IFilterOptionProps } from './types';

export const FilterOption: React.FC<IFilterOptionProps> = (props) => {
  const { data, isSelected, selectProps, selectOption } = props;

  const selectedOptionsCount = (selectProps?.value as [])?.length;

  const maxOptions = 20;

  const isDisabled = selectedOptionsCount >= maxOptions && !isSelected;
  if (isDisabled) {
    data.isDisabled = true;
  } else {
    data.isDisabled = false;
  }
  return (
    <components.Option {...props}>
      <FilterOptionStyled $disabled={isDisabled || props.isDisabled}>
        <FilterOptionCheckboxStyled name={data.label} checked={isSelected} onChange={() => selectOption(data)} disabled={isDisabled} />
        <FilterOptionLabelStyled>{data.label}</FilterOptionLabelStyled>
        {/* <FilterOptionCountStyled>{data.count}</FilterOptionCountStyled> */}
      </FilterOptionStyled>
    </components.Option>
  );
};
