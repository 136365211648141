import { colors, spacing } from '@Styles';
import { CSSObject } from 'styled-components';

export const styles = {
  control: (baseStyles: CSSObject) => ({
    ...baseStyles,
    minHeight: '32px',
    boxShadow: 'none',
    borderRadius: '8px',
    ':hover': {
      borderColor: colors.indigo['500'],
    },
    cursor: 'pointer',
  }),
  dropdownIndicator: (baseStyles: CSSObject) => ({
    ...baseStyles,
    padding: `${spacing['1']} ${spacing['1.5']}`,
    color: colors.zinc['800'],
  }),
  menu: (baseStyles: CSSObject) => ({
    ...baseStyles,
    borderRadius: '8px',
    boxShadow: '0px 2px 12px 0px rgba(10, 24, 154, 0.05)',
  }),
  menuList: (baseStyles: CSSObject) => ({
    ...baseStyles,
    '::-webkit-scrollbar': {
      width: '4px',
      height: '100%',
    },
    '::-webkit-scrollbar-track': {
      background: colors.zinc['50'],
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.zinc['500'],
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: colors.zinc['800'],
    },
  }),
  option: (baseStyles: CSSObject) => ({
    ...baseStyles,
    minHeight: '44px',
    backgroundColor: colors.white,
    ':active': {
      backgroundColor: colors.white,
    },
  }),
  multiValueRemove: (baseStyles: CSSObject) => ({
    ...baseStyles,
    ':hover': {
      backgroundColor: 'transparent',
    },
  }),
};
