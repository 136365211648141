import NoIssuesIcon from '@Images/shield-check.svg';
import { NoMonitoringDescriptionStyled, DashboardImageStyled } from './styled';
import { NoMonitoringContent } from './NoMonitoringContent';

export const NoMonitoringContentDetection = () => {
  return (
    <NoMonitoringContent>
      <DashboardImageStyled src={NoIssuesIcon} alt="shield-check" />
      <NoMonitoringDescriptionStyled>
        There are no <b>Monitored</b> repositories, please navigate to the Discovery page and select repositories to <b>Monitor</b>.
      </NoMonitoringDescriptionStyled>
    </NoMonitoringContent>
  );
};
