import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ScanningStyled = styled(Flex)(
  ({ theme }) => css`
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing[1]};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.bold};
    color: ${theme.colors.indigo[500]};
    text-transform: capitalize;
  `,
);
