import styled from 'styled-components';

export const ExpirationTokenModalButton = styled.button<{ $isBlue?: boolean }>`
  display: flex;
  width: 210px;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[1]};
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.spacing[1]};
  border: 0px;
  background-color: ${({ theme, $isBlue }) => ($isBlue ? theme.colors.indigo[500] : theme.colors.transparent)};
  color: ${({ theme, $isBlue }) => ($isBlue ? theme.colors.white : theme.colors.indigo[500])};
  text-align: center;
  font-style: normal;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  line-height: 160%;
  letter-spacing: 0.4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, $isBlue }) => ($isBlue ? theme.colors.indigo[600] : theme.colors.zinc[100])};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.zinc[100]};
    color: ${({ theme }) => theme.colors.zinc[500]};
    cursor: default;
  }
`;
