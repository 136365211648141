import styled from 'styled-components';

export const BackLinkStyled = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;
