import { DefaultTheme } from 'styled-components';

import { EIssuesCategoryLabel } from '@ComponentsTypes';
import { TIssuesSeverities } from '../interfaces';

export const formatDependenciesByCategoriesSeries = (data: TIssuesSeverities, theme: DefaultTheme) => {
  const { low, medium, high, critical } = data;
  return [
    {
      data: [
        { x: EIssuesCategoryLabel.CRITICAL, y: critical || 0, fillColor: theme.colors.rose[600] },
        { x: EIssuesCategoryLabel.HIGH, y: high || 0, fillColor: theme.colors.orange[500] },
        { x: EIssuesCategoryLabel.MEDIUM, y: medium || 0, fillColor: theme.colors.zinc[400] },
        { x: EIssuesCategoryLabel.LOW, y: low || 0, fillColor: theme.colors.zinc[200] },
      ],
    },
  ];
};
