import React, { useContext } from 'react';
import { EScoreFlagSize, IScoreFlagProps } from './types';
import { ThemeContext } from 'styled-components';
import { FlagContainerStyled, FlagLetterIconStyled } from './styled';
import { ERepositoryScore } from '@blindspot/common/types/bff/repositories';
import { faC, faD, faF, faA, faB, IconDefinition, faBookmark } from '@fortawesome/pro-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ScoreFlag: React.FC<IScoreFlagProps> = ({ score, size }: IScoreFlagProps) => {
  const themeContext = useContext(ThemeContext);
  const handleFlagProps = (score: ERepositoryScore): { color: string; letterIcon: IconDefinition } => {
    const flagProps: { letterIcon: IconDefinition; color: string } = { color: themeContext.colors.green[600], letterIcon: faA };
    switch (score) {
      case ERepositoryScore.A:
        flagProps.color = themeContext.colors.green[600];
        flagProps.letterIcon = faA;
        return flagProps;
      case ERepositoryScore.B:
        flagProps.color = themeContext.colors.cyan[500];
        flagProps.letterIcon = faB;
        return flagProps;
      case ERepositoryScore.C:
        flagProps.color = themeContext.colors.orange[300];
        flagProps.letterIcon = faC;
        return flagProps;
      case ERepositoryScore.D:
        flagProps.color = themeContext.colors.orange[500];
        flagProps.letterIcon = faD;
        return flagProps;
      case ERepositoryScore.F:
        flagProps.color = themeContext.colors.rose[600];
        flagProps.letterIcon = faF;
        return flagProps;
      default:
        flagProps.color = themeContext.colors.green[600];
        flagProps.letterIcon = faA;
        return flagProps;
    }
  };
  const handleFlagSizes = (size: EScoreFlagSize | undefined): { letterSize: SizeProp; flagSize: SizeProp } => {
    switch (size) {
      case EScoreFlagSize.SMALL:
        return {
          letterSize: '2xs',
          flagSize: 'lg',
        };
      case EScoreFlagSize.MEDIUM:
        return {
          letterSize: 'sm',
          flagSize: '2xl',
        };
      case EScoreFlagSize.LARGE:
        return {
          letterSize: 'lg',
          flagSize: '3x',
        };
      default:
        return {
          letterSize: 'sm',
          flagSize: '2xl',
        };
    }
  };
  const flagProps = handleFlagProps(score);
  const flagSizes = handleFlagSizes(size);
  return (
    <FlagContainerStyled>
      <FontAwesomeIcon icon={faBookmark} size={flagSizes.flagSize} color={flagProps.color} />
      <FlagLetterIconStyled color={themeContext.colors.white} size={flagSizes.letterSize} icon={flagProps.letterIcon} />
    </FlagContainerStyled>
  );
};
