import { AvatarRender } from '@Components';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { IUser } from '@APITypes';
import { ArrowStyled, UserNameStyled, UserWrapperStyled } from './styled';

export const UserTrigger = ({ user, open }: { user: IUser; open: boolean }) => {
  return (
    <UserWrapperStyled>
      <AvatarRender size="medium" url={user?.avatarUrl} />
      <UserNameStyled>{user?.displayName || user?.username}</UserNameStyled>
      <ArrowStyled icon={faChevronDown} $rotated={open} />
    </UserWrapperStyled>
  );
};
