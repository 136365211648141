import { Flex } from '@Styles';
import styled from 'styled-components';

export const IssueIgnoredContainerStyled = styled(Flex)`
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[0.5]};
  color: ${({ theme }) => theme.colors.zinc[500]};
  font-size: ${({ theme }) => theme.text.size.caption};
  margin-bottom: ${({ theme }) => theme.spacing[1.5]};
`;
