import React, { useState } from 'react';
import { Tooltip } from '@Components';
import { untitledScanConfigSetName } from 'Layouts/SettingsPage/components/ScanConfigs/constants';
import { InputStyled, ScanConfigSetNameStyled, InputContainerStyled, EditIconStyled, OffsetStyled } from './styled';
import { IScanConfigSetNameInputProps } from './interfaces';

export const ScanConfigSetNameInput: React.FC<IScanConfigSetNameInputProps> = ({ name, onChange }) => {
  const [isEdit, setIsEdit] = useState(false);

  const openEditMode = () => setIsEdit(true);

  const closeEditMode = () => {
    if (!name.length) {
      return onChange(untitledScanConfigSetName);
    }

    onChange(name.trim());
    setIsEdit(false);
  };

  const onInputChange = (e) => onChange(e.target.value);

  return (
    <OffsetStyled>
      {isEdit ? (
        <InputContainerStyled data-value={name}>
          <InputStyled autoFocus placeholder={untitledScanConfigSetName} value={name} onChange={onInputChange} onBlur={closeEditMode} />
        </InputContainerStyled>
      ) : (
        <ScanConfigSetNameStyled>
          {name}
          <Tooltip tooltip="Click to edit">
            <EditIconStyled onClick={openEditMode} />
          </Tooltip>
        </ScanConfigSetNameStyled>
      )}
    </OffsetStyled>
  );
};
