import React, { useContext } from 'react';
import { ParagonContext } from '@Utils';
import { IntegrationsCategory, IntegrationTitle, Integrator } from './components';
import { Button } from './components/Integrator/styled';
import { IntegrationStatusEnum } from './types';
import { UpgradeTextStyled } from './styled';

export const TicketingIntegrations: React.FC = () => {
  const { ticketManagers } = useContext(ParagonContext);

  if (!ticketManagers.length) {
    return null;
  }

  return (
    <>
      <IntegrationTitle>Ticketing</IntegrationTitle>
      <IntegrationsCategory>
        {ticketManagers.map((ticketManager) => (
          <Integrator
            key={ticketManager.type}
            onButtonClick={ticketManager.onConnect}
            status={ticketManager.isEnabled ? IntegrationStatusEnum.ACTIVE : IntegrationStatusEnum.NOT_INTEGRATED}
            name={ticketManager.name}
            editButton={
              <Button onClick={ticketManager.onConnect}>
                <UpgradeTextStyled>{ticketManager.isEnabled ? 'Manage' : 'Connect'}</UpgradeTextStyled>
              </Button>
            }
          />
        ))}
      </IntegrationsCategory>
    </>
  );
};
