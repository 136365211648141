export const queryStringToObject = (queryString = '', options = {}) => {
  const queryObject = {};

  queryString &&
    decodeURIComponent(queryString.replace('?', ''))
      .split('&')
      .map((itemString) => {
        const [itemKey, itemValue] = itemString.split('=');
        if (!queryObject[itemKey]) {
          queryObject[itemKey] = [];
        }
        queryObject[itemKey] = typeof options[itemKey] === 'number' ? parseInt(itemValue, 10) : itemValue;
      });

  return queryObject;
};
