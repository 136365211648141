import styled from 'styled-components';

export const Logo = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 13px;
  background: ${({ theme }) => theme.colors.zinc['100']};
  grid-column: 1;
  grid-row: 1 / 3;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    top: 0;
  }
`;
