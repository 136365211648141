import { useContext, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Loader } from '@Components';

import { formatNumberWithCommas } from '@Utils';
import { useFilter } from 'core/Filter';
import { CardTitleStyled, ChartContainerStyled, DashboardCardStyled, OutOfDateTextStyled } from '../styled';
import { useDependenciesOutOfDate } from '../hooks';
import { ChartLoadingContext } from '../context';

export const OutOfDate = () => {
  const theme = useTheme();
  const { appliedFilters } = useFilter();
  const { data, isLoading } = useDependenciesOutOfDate({ filter: appliedFilters });

  const { setLoadingChart } = useContext(ChartLoadingContext);

  useEffect(() => {
    setLoadingChart(isLoading);
  }, [isLoading]);

  const outOfDate = data || 0;
  const value = useMemo(() => formatNumberWithCommas(outOfDate), [outOfDate]);
  return (
    <DashboardCardStyled $paddingBottom={theme.spacing[2]}>
      <CardTitleStyled>Out of date dependencies</CardTitleStyled>
      {isLoading && (
        <ChartContainerStyled>
          <Loader />
        </ChartContainerStyled>
      )}
      {!isLoading && <OutOfDateTextStyled>{value}</OutOfDateTextStyled>}
    </DashboardCardStyled>
  );
};
