import styled from 'styled-components';

const ChipContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing['0.5']} ${theme.spacing['1']}`};
  background: ${({ theme }) => theme.colors.zinc['200']};
  border-radius: 100px;
`;

const ChipLabel = styled.div`
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.zinc['700']};
`;

interface IProps {
  children: React.ReactElement | string;
}

export const Chip: React.FC<IProps> = ({ children }) => {
  return (
    <ChipContainer>
      <ChipLabel>{children}</ChipLabel>
    </ChipContainer>
  );
};
