import { Controller, UseControllerReturn, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { ESign } from '@Components';
import { useModal } from '@Modal';
import { IntegrationsContext } from '@Utils';
import { ENotificationType, toastService } from '@Notification';
import { IntegrationModal } from '../IntegrationModal';
import { BitbucketListInfo } from './components';
import { IBitbucketWorkspaceAccessTokenFormData, IBitbucketWorkspaceAccessTokenModalProps, bitbucketWorkspaceAccessTokenSteps } from './types';
import { BitbucketIntegrationModal } from './BitbucketIntegrationModal';
import { useIntegration, useIntegrationTokens, useTokenUpdate } from 'Layouts/IntegrationsPage/hooks';
import { WorkspaceAccessTokenInputs } from './components';
import { BitbucketWorkspaceSelectModal } from './BitbucketWorkspaceSelectModal';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const BitbucketWorkspaceAccessTokenModal = ({ isUpdate }: IBitbucketWorkspaceAccessTokenModalProps) => {
  const queryClient = useQueryClient();
  const invalidateIntegrations = () => queryClient.invalidateQueries({ queryKey: ['fetchUserOrg'] });
  const { integrations } = useContext(IntegrationsContext);
  const { mutate: setIntegration, isLoading: isIntegrationLoading } = useIntegration();
  const { mutate: setNewToken, isLoading: isUpdateLoading } = useTokenUpdate();
  const { data: tokens, isLoading: isIntegrationTokenLoading } = useIntegrationTokens(EScmType.BITBUCKET, !!isUpdate);

  const { openModal, closeModal } = useModal();

  const isLoading = isIntegrationLoading || isUpdateLoading || isIntegrationTokenLoading;

  const defaultValues: IBitbucketWorkspaceAccessTokenFormData = {
    tokens: [],
  };

  const formData = useForm<IBitbucketWorkspaceAccessTokenFormData>({
    defaultValues,
    shouldFocusError: false,
  });
  const { control, setError, clearErrors, reset } = formData;

  useEffect(() => {
    if (tokens) {
      reset({
        tokens,
      });
    } else {
      reset(defaultValues);
    }
  }, [reset, tokens]);

  const onSubmit = ({ tokens }) => {
    const bitbucketIntegration = integrations.find((integration) => integration.type === EScmType.BITBUCKET);
    if (isUpdate && bitbucketIntegration) {
      setNewToken(
        {
          provider: EScmType.BITBUCKET,
          data: { tokens },
          scmUuid: bitbucketIntegration.uuid,
        },
        {
          onSuccess: () => {
            invalidateIntegrations();
            closeModal();
          },
          onError: ({ response: { data } }: AxiosError<{ message?: string }>) => {
            const message = data?.message || 'Something went wrong';
            toastService({
              header: 'Update workspace access token error',
              message,
              type: ENotificationType.ERROR,
            });
          },
        },
      );
      return;
    }
    setIntegration(
      {
        provider: EScmType.BITBUCKET,
        data: { tokens },
      },
      {
        onSuccess: async () => {
          await invalidateIntegrations();
          return openModal(<BitbucketWorkspaceSelectModal />);
        },
        onError: ({ response: { data } }: AxiosError<{ message?: string }>) => {
          const message = data?.message || 'Something went wrong';
          toastService({
            header: 'Set workspace access token error',
            message,
            type: ENotificationType.ERROR,
          });
        },
      },
    );
  };

  const onBackClickHandler = () => {
    return openModal(<BitbucketIntegrationModal />);
  };

  const renderTokenInput = ({ field: { onChange, onBlur, ref, value }, fieldState: { error } }: UseControllerReturn<IBitbucketWorkspaceAccessTokenFormData, 'tokens'>) => (
    <WorkspaceAccessTokenInputs
      tokens={value}
      refCallback={ref}
      onBlur={onBlur}
      onChange={onChange}
      error={error?.message}
      setError={(message: string) => {
        setError('tokens', { message });
      }}
      clearError={() => {
        clearErrors('tokens');
      }}
    />
  );

  return (
    <IntegrationModal
      nextButtonType="submit"
      title={'Bitbucket'}
      titleSign={ESign.bitbucket}
      info={<BitbucketListInfo steps={bitbucketWorkspaceAccessTokenSteps} />}
      formData={formData}
      onSubmit={onSubmit}
      onBackClickHandler={onBackClickHandler}
      backButtonTitle={'Integration Type'}
      inputs={<Controller name="tokens" control={control} render={renderTokenInput} />}
      nextButtonDisabled={isLoading}
      isLoading={isLoading}
    />
  );
};
