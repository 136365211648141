import React from 'react';
import { CopyButton } from '@Components';
import { FullnameModalContent, Fullname } from '../styled';
import { IDependencyFullnameModalContentProps } from '../interfaces';

export const DependencyFullnameModalContent: React.FC<IDependencyFullnameModalContentProps> = ({ fullName }) => {
  return (
    <FullnameModalContent>
      <Fullname>{fullName}</Fullname>
      <CopyButton data={fullName} />
    </FullnameModalContent>
  );
};
