import { axiosConfig as axios } from '@Utils';
import { IApiDashboardFilterParams } from '../interfaces';

export const fetchIssuesByCodeLanguages = async (token: string, params?: IApiDashboardFilterParams) => {
  const response = await axios('issues/summary/languages', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(params?.filter && params?.filter),
    },
  });
  return response?.data;
};
