import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@Components';
import { ParagonContext } from '@Utils';
import { ELoaderSize } from 'Layouts/components/Loader/types';
import { TicketLabel } from 'Layouts/IssuesPage/components/issueDetails/IssueNavContainer/CreateTicketModal';
import { useRepositoryTicket } from './hooks';

export const RepositoryTicket = () => {
  const { repoId } = useParams();
  const { ticketManagers } = useContext(ParagonContext);
  const { data: repoTicket, isFetching } = useRepositoryTicket(
    repoId,
    ticketManagers.some(({ isEnabled }) => isEnabled),
  );

  if (isFetching) {
    return <Loader size={ELoaderSize.SMALL} />;
  }

  if (!repoTicket) {
    return null;
  }

  return <TicketLabel ticket={repoTicket} />;
};
