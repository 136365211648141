import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const CodeDetailsStyled = styled(Flex)(
  ({ theme }) => css`
    flex-direction: column;
    gap: ${theme.spacing[2]};
    padding: ${theme.spacing[3]} ${theme.spacing[2.5]} ${theme.spacing[3]};
  `,
);
