import { Grid } from '@Styles';
import styled from 'styled-components';

export const DiscoveryContentStyled = styled(Grid)`
  grid-gap: ${({ theme }) => theme.spacing[2]};
  grid-template-rows: auto 1fr;

  td [data-sign-render] {
    position: relative;
    top: 1px;
  }
`;
