import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const PreferencesActionsWrapper = styled(Flex)(
  ({ theme }) => css`
    justify-content: space-between;
    align-items: center;
    margin-top: ${theme.spacing[4]};
  `,
);
