import { EIssuesCategoryLabel } from '@ComponentsTypes';
import { ICVSSVector } from '../interfaces';

const confidentialityRequirement: ICVSSVector = {
  name: 'Confidentiality Req.',
  code: 'CR',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: EIssuesCategoryLabel.HIGH, code: 'H' },
    { name: EIssuesCategoryLabel.MEDIUM, code: 'M' },
    { name: EIssuesCategoryLabel.LOW, code: 'L' },
  ],
};

const integrityRequirement: ICVSSVector = {
  name: 'Integrity Req.',
  code: 'IR',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: EIssuesCategoryLabel.HIGH, code: 'H' },
    { name: EIssuesCategoryLabel.MEDIUM, code: 'M' },
    { name: EIssuesCategoryLabel.LOW, code: 'L' },
  ],
};

const availabilityRequirement: ICVSSVector = {
  name: 'Availability Req.',
  code: 'AR',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: EIssuesCategoryLabel.HIGH, code: 'H' },
    { name: EIssuesCategoryLabel.MEDIUM, code: 'M' },
    { name: EIssuesCategoryLabel.LOW, code: 'L' },
  ],
};

const modifiedAttackVector: ICVSSVector = {
  name: 'Modified Attack Vector',
  code: 'MAV',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: 'Network', code: 'N' },
    { name: 'Adjacent', code: 'A' },
    { name: 'Local', code: 'L' },
    { name: 'Physical', code: 'P' },
  ],
};

const modifiedAttackComplexity: ICVSSVector = {
  name: 'Modified Attack Complexity',
  code: 'MAC',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: EIssuesCategoryLabel.LOW, code: 'L' },
    { name: EIssuesCategoryLabel.HIGH, code: 'H' },
  ],
};

const modifiedPrivilegesRequired: ICVSSVector = {
  name: 'Modified Privileges Required',
  code: 'MPR',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: 'None', code: 'N' },
    { name: EIssuesCategoryLabel.LOW, code: 'L' },
    { name: EIssuesCategoryLabel.HIGH, code: 'H' },
  ],
};

const modifiedUserInteraction: ICVSSVector = {
  name: 'Modified User Interaction',
  code: 'MUI',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: 'None', code: 'N' },
    { name: 'Required', code: 'R' },
  ],
};

const modifiedScope: ICVSSVector = {
  name: 'Modified Scope',
  code: 'MS',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: 'Unchanged', code: 'U' },
    { name: 'Changed', code: 'C' },
  ],
};

const modifiedConfidentiality: ICVSSVector = {
  name: 'Modified Confidentiality',
  code: 'MC',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: 'None', code: 'N' },
    { name: EIssuesCategoryLabel.LOW, code: 'L' },
    { name: EIssuesCategoryLabel.HIGH, code: 'H' },
  ],
};

const modifiedIntegrity: ICVSSVector = {
  name: 'Modified Integrity',
  code: 'MI',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: 'None', code: 'N' },
    { name: EIssuesCategoryLabel.LOW, code: 'L' },
    { name: EIssuesCategoryLabel.HIGH, code: 'H' },
  ],
};

const modifiedAvailability: ICVSSVector = {
  name: 'Modified Availability',
  code: 'MA',
  metrics: [
    { name: EIssuesCategoryLabel.NOT_DEFINED, code: 'X' },
    { name: 'None', code: 'N' },
    { name: EIssuesCategoryLabel.LOW, code: 'L' },
    { name: EIssuesCategoryLabel.HIGH, code: 'H' },
  ],
};

export const environmentalScoreMetrics = [
  modifiedAttackVector,
  modifiedAttackComplexity,
  modifiedPrivilegesRequired,
  modifiedUserInteraction,
  modifiedScope,
  modifiedConfidentiality,
  modifiedIntegrity,
  modifiedAvailability,
  confidentialityRequirement,
  integrityRequirement,
  availabilityRequirement,
];
