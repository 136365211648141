import { useQuery } from 'react-query';
import { useSession } from '@descope/react-sdk';
import { fetchSastIssueFileById } from '../api';

export const useSastIssueFile = (sastIssueUuid: string) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  return useQuery({
    queryKey: ['sastIssueFile', sastIssueUuid],
    queryFn: async () => {
      return await fetchSastIssueFileById(sessionToken, sastIssueUuid);
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  });
};
