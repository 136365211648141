import { EMonitoringStatus } from '@APITypes';
import { ScanningStyled } from '../styled';
import { Loader } from '@Components';
import { ELoaderSize } from 'Layouts/components/Loader/types';

export const Scanning = () => {
  return (
    <ScanningStyled>
      <Loader size={ELoaderSize.SMALL} />
      {EMonitoringStatus.SCANNING}
    </ScanningStyled>
  );
};
