import { useMutation, useQueryClient } from 'react-query';
import { unIgnoreIssue } from '../api';
import { useSession } from '@descope/react-sdk';

export const useUnIgnoreIssue = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueId }: { issueId: string }) => {
      if (isAuthenticated && !isSessionLoading) {
        return unIgnoreIssue(sessionToken, issueId);
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('issueDetails');
      },
    },
  );
};
