import { createColumnHelper } from '@tanstack/react-table';
import { IIssueColumns } from './types';
import { EReachabilityStatus, Undetermined, IIssue, NotAvailable } from '@APITypes';
import { InfoLabel, InfoLabelTypes, UserRender } from '@Components';
import { DATE_TIME_LONG_FORMAT } from '@Utils';
import { faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DependencyFilesCell } from 'Layouts/DiscoveryPage/components';
import { isNewIssue } from 'Layouts/IssuesPage/utils';
import { format } from 'date-fns';
import { IssueReachability, getIssueTitle } from '../issueDetails/components';
import { IssueReachabilityLabelStyled } from '../issueDetails/components/IssueReachability/styled';
import { IssueCategoryLabel } from '@blindspot/common/types/bff';
import { NewChip } from './NewChip';
import { IssueIconText, Capitalized, AlignCenter } from './styled';
import { EExploitationsAvailabilityLables } from './types/exploitationsAvailabilityLabels.enum';
import { CreateTicketButton } from '../issueDetails/IssueNavContainer/CreateTicketModal';

const columnHelper = createColumnHelper<IIssueColumns>();

export const getColumns = () => [
  columnHelper.accessor('severity', {
    header: 'Severity',
    cell: (severity) => (
      <InfoLabel type={severity.getValue()} capitalize>
        {severity.getValue()}
      </InfoLabel>
    ),
    size: 100,
  }),
  columnHelper.accessor('reachabilityStatus', {
    header: 'Reachability',
    cell: (reachability) => {
      const reachabilityStatus = reachability.getValue();
      if (!reachabilityStatus || reachabilityStatus === EReachabilityStatus.UNDEFINED) {
        return <IssueReachabilityLabelStyled>{Undetermined}</IssueReachabilityLabelStyled>;
      }
      return <IssueReachability bolded reachabilityStatus={reachabilityStatus}></IssueReachability>;
    },
    size: 100,
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    cell: ({ row }) => {
      const issue = row.original;
      const name = getIssueTitle(issue as unknown as IIssue);
      const isNew = isNewIssue(issue.discoveredAt);

      return (
        <span>
          {name}
          {isNew ? <NewChip /> : null}
        </span>
      );
    },
    size: 350,
  }),
  columnHelper.accessor('tickets', {
    header: 'Ticket',
    cell: ({ row }) => {
      const issue = row.original;
      return (
        <AlignCenter>
          <CreateTicketButton baseIssueUuid={issue.uuid} isIconView tickets={issue.tickets} />
        </AlignCenter>
      );
    },
    size: 150,
  }),
  columnHelper.accessor('dependencyName', {
    header: 'Origin',
    cell: ({ row }) => {
      const { dependencyName, dependencyInstalledVersion } = row.original;

      if (dependencyName === '' || dependencyInstalledVersion === '') {
        return NotAvailable;
      }

      return (
        <IssueIconText>
          <FontAwesomeIcon icon={faCodeCompare} />
          {`${dependencyName}:${dependencyInstalledVersion}`}
        </IssueIconText>
      );
    },
    size: 150,
  }),
  columnHelper.accessor('repositories', {
    header: 'Dependencies Files',
    cell: ({ row }) => {
      const { repositories } = row.original;
      if (!repositories || !repositories.length) {
        return NotAvailable;
      }
      return <DependencyFilesCell repositories={repositories} />;
    },
    size: 120,
  }),
  columnHelper.accessor('category', {
    header: 'Category',
    cell: (category) => <InfoLabel type={InfoLabelTypes.Default}>{IssueCategoryLabel[category.getValue()]}</InfoLabel>,
    size: 150,
  }),
  columnHelper.accessor('exploitationAvailability', {
    header: 'Exploitation Availability',
    cell: (exploitationAvailability) => {
      const label = EExploitationsAvailabilityLables[exploitationAvailability.getValue()];
      return <Capitalized>{label}</Capitalized>;
    },
    size: 100,
  }),
  columnHelper.accessor('fixAvailable', {
    header: 'Fix Available',
    cell: (fixAvailable) => <Capitalized>{fixAvailable.getValue() ? 'Yes' : 'No'}</Capitalized>,
    size: 50,
  }),
  columnHelper.accessor('depTreeLevel', {
    header: 'Dependency Relationship',
    cell: (depTreeLevel) => <Capitalized>{depTreeLevel.getValue()}</Capitalized>,
    size: 50,
  }),
  columnHelper.accessor('discoveredAt', {
    header: 'First Seen',
    cell: ({ row }) => {
      const { discoveredAt } = row.original;
      return <span>{discoveredAt ? format(new Date(discoveredAt), DATE_TIME_LONG_FORMAT) : NotAvailable}</span>;
    },
    size: 120,
  }),
  columnHelper.accessor('lastCommitAuthorName', {
    header: 'Commit',
    cell: (lastCommitAuthorName) => <UserRender userName={lastCommitAuthorName.getValue() || NotAvailable} />,
    size: 120,
  }),
];
