import React, { useEffect, useState } from 'react';
import { ItemSelectionContext } from './ItemSelectionContext';
import { IItemsSelectionProviderProps } from './types';

export const ItemSelectionProvider: React.FC<IItemsSelectionProviderProps> = ({ children }) => {
  const [selectionEnabled, setSelectionEnabled] = useState(false);
  const [isAllItemsSelected, setIsAllItemsSelected] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [includedItems, setIncludedItems] = useState<Record<string | number, boolean>>({});
  const [excludedItems, setExcludedItems] = useState<Record<string | number, boolean>>({});

  useEffect(() => {
    setIncludedItems({});
    setExcludedItems({});
  }, [isAllItemsSelected]);

  const toggleItemSelection = (itemId: string | number, value?: boolean) => {
    if (isAllItemsSelected) {
      setExcludedItems((prevItems) => {
        const updatedItems = { ...prevItems };
        if (!value) {
          updatedItems[itemId] = true;
        } else {
          delete updatedItems[itemId];
        }
        return updatedItems;
      });
    } else {
      setIncludedItems((prevItems) => {
        const updatedItems = { ...prevItems };
        if (value) {
          updatedItems[itemId] = true;
        } else {
          delete updatedItems[itemId];
        }
        return updatedItems;
      });
    }
  };

  const clearSelectedItems = () => {
    setIsAllItemsSelected(false);
    setIncludedItems({});
  };

  const selectAllItems = () => {
    setIsAllItemsSelected(true);
  };

  const enableSelection = () => {
    setSelectionEnabled(true);
  };

  const disableSelection = () => {
    setSelectionEnabled(false);
    clearSelectedItems();
  };

  const selectedItemsCount = isAllItemsSelected ? totalItemsCount - Object.keys(excludedItems).length : Object.keys(includedItems).length;

  const contextValue = {
    selectionEnabled,
    enableSelection,
    disableSelection,
    selectedItemsCount,
    totalItemsCount,
    setTotalItemsCount,
    toggleItemSelection,
    clearSelectedItems,
    isAllItemsSelected,
    selectAllItems,
    includedItems,
    excludedItems,
  };

  return <ItemSelectionContext.Provider value={contextValue}>{children}</ItemSelectionContext.Provider>;
};
