import { useQuery } from 'react-query';
import { fetchUserReminders } from '../api';
import { IReminder } from '@blindspot/common/types/bff/api';
import { IApiDashboardFilterParams } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useUserReminders = (params?: IApiDashboardFilterParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['userReminders'],
    queryFn: async () => {
      return await fetchUserReminders(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IReminder[]>(queryOptions);
};
