import { axiosConfig as axios } from '@Utils';
import { IScanConfigSet } from '@blindspot/common/types/bff/configs';

export const updateScanConfigSet = async (scanConfigSetUuid: string, update: Partial<IScanConfigSet>, token: string) => {
  const response = await axios.put(`configs/scan/${scanConfigSetUuid}`, update, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
