import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const ViewExportButton = styled(Button)(
  ({ theme }) => css`
    background: transparent;
    letter-spacing: 0.4px;
    height: fit-content;
    margin: 0 ${theme.spacing[1.5]};
    white-space: nowrap;
    font-size: ${theme.text.size.body2};
    color: ${theme.colors.white};
  `,
);
