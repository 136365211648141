import React, { useState } from 'react';
import { FloatingFocusManager, autoUpdate, flip, offset, shift, useClick, useDismiss, useFloating, useId, useInteractions, useRole, useTransitionStyles } from '@floating-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { spacing } from '@Styles';
import { DropdownMenuContainerStyled, DropdownTriggerStyled, DropdownActionContentStyled, DropdownArrowStyled } from './styled';
import { ActionsDropdownProps } from './types';

export const ActionsDropdown: React.FC<ActionsDropdownProps> = ({ menuItems, isIconView }) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-end',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(parseInt(spacing[2], 16)), shift(), flip()],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);
  const { styles: transitionStyle } = useTransitionStyles(context, {
    duration: 200,
  });
  const headingId = useId();

  return (
    <>
      <DropdownTriggerStyled $isIconView={isIconView} ref={refs.setReference} {...getReferenceProps()}>
        {isIconView ? (
          <FontAwesomeIcon icon={faEllipsisVertical} />
        ) : (
          <>
            <span>Actions</span>
            <DropdownArrowStyled $rotate={isOpen} />
          </>
        )}
      </DropdownTriggerStyled>
      <FloatingFocusManager context={context}>
        <DropdownMenuContainerStyled ref={refs.setFloating} style={Object.assign(floatingStyles, transitionStyle)} aria-labelledby={headingId} {...getFloatingProps()} $hidden={!isOpen}>
          {menuItems.map((menuItem, index) => (
            <DropdownActionContentStyled key={index} onClick={close}>
              {menuItem}
            </DropdownActionContentStyled>
          ))}
        </DropdownMenuContainerStyled>
      </FloatingFocusManager>
    </>
  );
};
