import styled from 'styled-components';

export const InputLabelStyled = styled.label`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[1]};
  cursor: pointer;
  user-select: none;
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;
