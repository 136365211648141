import { createContext } from 'react';
import { IFilterOption } from '../types';

export const FilterContext = createContext({
  appliedFilters: {},
  appliedFiltersCount: 0,
  updateFilters: (id: string, filters: IFilterOption[] | boolean | string) => undefined,
  setQueryFilters: () => undefined,
  clearFilters: () => undefined,
  onFiltersApplied: (callback: () => void) => undefined,
});
