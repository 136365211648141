import React from 'react';
import { NotAvailable } from '@APITypes';
import { DATE_TIME_LONG_FORMAT } from '@Utils';
import { Flex } from '@Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DetailsColumn, DetailsLabel, VersionsContainer, VersionsBlock, DetailsBlockLabelStyled } from './styled';
import { IVulnerabilityDetailsProps } from './interfaces';
import { format } from 'date-fns';

import { VulnerabilityLinkLabels } from './VulnerabilityLinkLabels';
import { DetailsStyled } from '../Details/styled';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from '@Components';

export const VulnerabilityDetails: React.FC<IVulnerabilityDetailsProps> = ({ issue }) => {
  const vulnerability = issue.vulnerabilities?.[0];
  const exploitationsAvailability = vulnerability.weaknesses?.length;
  const {
    versions: { introduced, fixed, lastAffected },
  } = vulnerability;

  const renderVersions = (versions: string[], prefix: 'fixed' | 'introduced' | 'lastAffected') => {
    if (!versions.length) {
      return NotAvailable;
    }
    return versions.map((version) => <p key={`${prefix}-${version}`}>{version}</p>);
  };

  const epssPercent = vulnerability?.epss ? Math.round(vulnerability.epss * 10000) / 100 : undefined;
  const epssPercentile = vulnerability?.percentile ? `(${Math.round(vulnerability.percentile * 100)}th percentile)` : '';
  const isEppsDefined = epssPercent || epssPercentile;

  return (
    <>
      <DetailsStyled>
        <Flex>
          <DetailsColumn>
            <div>
              <DetailsBlockLabelStyled>References:</DetailsBlockLabelStyled>
              <VulnerabilityLinkLabels vulnerability={vulnerability} />
            </div>
            <div>
              <DetailsBlockLabelStyled>Detection:</DetailsBlockLabelStyled>
              <div>
                <DetailsLabel>First Discovered</DetailsLabel>
                <p>{vulnerability.published ? format(new Date(vulnerability.published), DATE_TIME_LONG_FORMAT) : NotAvailable}</p>
              </div>
              <VersionsBlock>
                <DetailsLabel>Introduced Version</DetailsLabel>
                <VersionsContainer>{renderVersions(introduced, 'introduced')}</VersionsContainer>
              </VersionsBlock>
              <VersionsBlock>
                <DetailsLabel>Affected Versions</DetailsLabel>
                <VersionsContainer>{renderVersions(lastAffected, 'lastAffected')}</VersionsContainer>
              </VersionsBlock>
            </div>
          </DetailsColumn>

          <DetailsColumn>
            <div>
              <DetailsBlockLabelStyled>Fix:</DetailsBlockLabelStyled>
              <div>
                <DetailsLabel>Fix Availability</DetailsLabel>
                <p>{fixed.length ? 'True' : 'False'}</p>
              </div>
              <VersionsBlock>
                <DetailsLabel>Fixed Version</DetailsLabel>
                <VersionsContainer>{renderVersions(fixed, 'fixed')}</VersionsContainer>
              </VersionsBlock>
            </div>
            <div>
              <DetailsBlockLabelStyled>Exploit:</DetailsBlockLabelStyled>
              <div>
                <DetailsLabel>
                  <p>EPSS</p>
                  <Tooltip
                    tooltip={
                      <span>
                        <p>The EPSS score provides both a probability and the percentile of an exploit activity in the next 30 days</p>
                        <p>
                          The <b>probability</b> - The likelihood of the CVE to be exploited in the next 30 days.
                        </p>
                        <p>
                          The <b>percentile</b> - Represents the percentage of CVEs that fall below this particular CVE.
                        </p>
                      </span>
                    }
                  >
                    <FontAwesomeIcon icon={faCircleInfo} title="Exploit Probability Score" cursor="pointer" />
                  </Tooltip>
                </DetailsLabel>
                <p>{isEppsDefined ? `${epssPercent}% ${epssPercentile}` : NotAvailable}</p>
              </div>
              <VersionsBlock>
                <DetailsLabel>
                  <p>KEV</p>
                  <Tooltip
                    tooltip={
                      <span>
                        <p>The Known Exploited Vulnerabilities Catalog (KEV) is a database of actively exploited software vulnerabilities.</p>
                      </span>
                    }
                  >
                    <FontAwesomeIcon icon={faCircleInfo} title="KEV" cursor="pointer" />
                  </Tooltip>
                </DetailsLabel>
                <p>{vulnerability?.kev ? 'True' : 'False'}</p>
              </VersionsBlock>
              <VersionsBlock>
                <DetailsLabel>Exploitations Availability</DetailsLabel>
                <p>{exploitationsAvailability ? 'True' : 'False'}</p>
              </VersionsBlock>
            </div>
          </DetailsColumn>
        </Flex>
      </DetailsStyled>
    </>
  );
};
