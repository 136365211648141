import styled from 'styled-components';

export const MetadataSectionContainerStyled = styled.div<{ $boredered?: boolean; $maxWidth?: string }>`
  flex: 1;
  margin: 20px 0;
  padding-right: 30px;
  ${({ $boredered }) => ($boredered ? `margin-right: 30px;` : '')}
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth};` : '')}
  border-right: ${({ $boredered, theme }) => ($boredered ? `1px solid ${theme.colors.zinc[200]}` : 'none')};
  max-width: 310px;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
`;
