import { DataNode } from 'rc-tree/lib/interface';
import { IReachabilityChain, IReachabilityChainItem } from '@blindspot/common/types/bff/api';
import { getChainItemId } from '../../utils';
import { convertToTree } from './convertToTree.util';

export const getTreeData = (chains: IReachabilityChain[]): DataNode[] => {
  const root = {} as DataNode;

  chains.forEach((chain) => {
    let currentNode = root;

    Object.values(chain).forEach((chainItem: IReachabilityChainItem, index) => {
      const id = getChainItemId(chainItem);
      const key = `${id}-${index}`;
      if (!currentNode[id]) {
        currentNode[id] = {
          key,
          id: id,
          title: chainItem.name,
          name: chainItem.name,
          file: chainItem.file,
          kind: chainItem.kind,
          children: {},
        };
      }
      currentNode = currentNode[id].children;
    });
  });

  return convertToTree(root);
};
