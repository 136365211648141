import React from 'react';
import { AvatarRender, Loader } from '@Components';
import { UserNameInput } from '../UserNameInput';
import { UserWrapper, UserInfo } from './styled';
import { useUser } from '../../hooks';

export const Profile: React.FC = () => {
  const { data: user, isLoading } = useUser();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <UserWrapper>
      <AvatarRender size="large" url={user?.avatarUrl} />
      <UserInfo>
        <UserNameInput />
      </UserInfo>
    </UserWrapper>
  );
};
