import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationEffortChangeContainerStyled = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[0.5]};
  padding: ${({ theme }) => theme.spacing[2]};

  border-radius: ${({ theme }) => theme.spacing[1]};
  background: ${({ theme }) => theme.colors.zinc[100]};
  margin: 0 0 ${({ theme }) => theme.spacing[2]} 0;
  text-overflow: ellipsis;
`;
