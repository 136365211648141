export const chartAnimationOptions = {
  enabled: true,
  speed: 1600,
  animateGradually: {
    enabled: true,
    delay: 150,
  },
  dynamicAnimation: {
    enabled: true,
    speed: 700,
  },
};
