import { axiosConfig as axios } from '@Utils';

export const fetchRepositoryMonitoringPreferences = async (repositoryId: string, token: string) => {
  const response = await axios(`repositories/${repositoryId}/monitor/preferences`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
