import styled, { css } from 'styled-components';

export const BackdropStyled = styled.div<{ $open: boolean; $transitioning: boolean; $transitionSpeedMs: number }>`
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
  transition: opacity
    ${({ $transitionSpeedMs }) =>
      css`
        ${$transitionSpeedMs}ms ease, visibility ${$transitionSpeedMs}ms ease
      `};

  ${({ $open, $transitioning }) =>
    $open &&
    $transitioning &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
      z-index: 100;
    `}
`;
