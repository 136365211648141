import { ModalTitle } from 'Modal/components/ModalStandard';
import styled, { css } from 'styled-components';

export const ExpirationTokenModalTitle = styled(ModalTitle)(
  ({ theme }) => css`
    color: ${theme.colors.black};
    text-align: center;

    margin-top: ${theme.spacing[1.5]};
  `,
);
