import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DrawerIconButtonStyled } from './styled';
import { IIssueDrawerActionButtonProps } from './types';

export const IssueDrawerActionButton = ({ icon, onClick, disable }: IIssueDrawerActionButtonProps) => {
  return (
    <DrawerIconButtonStyled $disable={disable} onClick={onClick}>
      <FontAwesomeIcon size="sm" icon={icon} />
    </DrawerIconButtonStyled>
  );
};
