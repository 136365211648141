import { ConnectionLineType } from 'reactflow';

export const DEFAULT_GRAPH_PROPS = {
  fitView: true,
  connectionLineType: ConnectionLineType.SmoothStep,
  proOptions: { hideAttribution: true },
  minZoom: 0.1,
  edgesUpdatable: false,
  nodesDraggable: false,
  nodesConnectable: false,
  edgesFocusable: false,
};
