import styled from 'styled-components';

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.indigo['500']};
  font-size: ${({ theme }) => theme.text.size['caption']};
  font-weight: 500;
  background: rgba(44, 61, 215, 0.08);
  border-radius: 100px;
  padding: ${({ theme }) => theme.spacing['0.5']} ${({ theme }) => theme.spacing['1']};
  line-height: ${({ theme }) => theme.spacing['2']};
  letter-spacing: 0.15px;
  user-select: none;
  -webkit-user-select: none;
`;
