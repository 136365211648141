import styled from 'styled-components';

export const IssuesContainer = styled.section`
  margin-top: ${({ theme }) => theme.spacing['2']};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: ${({ theme }) => theme.spacing['0.5']};

  & [data-component='tabPages'] {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    span {
      padding-left: ${({ theme }) => theme.spacing['3']};
      padding-right: ${({ theme }) => theme.spacing['3']};
      margin-right: 0 !important;
    }
  }
`;
