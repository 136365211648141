import styled from 'styled-components';
import { ReachabiltiyRepositoryStyled } from './ReachabilityRepository.styled';

export const ReachabilityStyled = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing[3]};
  padding-top: 1px;

  ${ReachabiltiyRepositoryStyled}:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing['4']};
  }
`;
