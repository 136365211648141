import { Flex } from '@Styles';
import styled from 'styled-components';

export const SearchInputWrapperStyled = styled(Flex)`
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.colors.zinc[200]}`};
  border-radius: 5px;
  color: ${({ theme }) => `${theme.colors.zinc[500]}`};
  padding: 4px;
  height: 2em;
  margin-top: ${({ theme }) => theme.spacing[3]};
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  background: ${({ theme }) => `${theme.colors.white}`};
`;
