import React, { useMemo } from 'react';
import { RemediationStyled } from './styled';
import { useParams } from 'react-router-dom';
import { useIssueRemediation } from './hooks';
import { RemediationListTitle } from './RemediationListTitle';
import { RemediationRepositoryList } from './RemediationRepositoryList';
import { RemediationLoader } from './RemediationLoader';
import { IssueRemediationNoData } from './IssueRemediationNoData';
import { IIssueRemediationProps } from './types';

export const IssuesRemediation: React.FC<IIssueRemediationProps> = ({ isDiff }) => {
  const { baseIssueUuid, branchUuid } = useParams();

  const diff = useMemo(() => (isDiff ? { branchId: branchUuid } : undefined), [isDiff, branchUuid]);
  const { data: issueRemediation = [], isFetching } = useIssueRemediation({
    issueUuid: baseIssueUuid,
    diff,
  });

  if (!issueRemediation?.length && !isFetching) {
    return (
      <RemediationStyled>
        <IssueRemediationNoData />
      </RemediationStyled>
    );
  }

  return (
    <RemediationStyled>
      <RemediationListTitle />
      {isFetching ? <RemediationLoader /> : <RemediationRepositoryList issueRemediations={issueRemediation} />}
    </RemediationStyled>
  );
};
