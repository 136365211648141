import React from 'react';
import { IssueSecondTabLink } from './styled';
import { EIssueSubType } from '@APITypes';
import { IIssueSecondTabLinkByCategory } from './types';

const getIssueSecondTabLink = (category) => {
  if (category === EIssueSubType.TAMPERING) {
    return `See tampered files`;
  }
  if (category === EIssueSubType.RISKY_CODE) {
    return `See risky code files`;
  }
};

export const IssueSecondTabLinkByCategory: React.FC<IIssueSecondTabLinkByCategory> = ({ category, onTabChange }) => {
  if (category === EIssueSubType.VULNERABILITY) return null;

  return (
    <div>
      <IssueSecondTabLink onClick={onTabChange}>{getIssueSecondTabLink(category)}</IssueSecondTabLink>
    </div>
  );
};
