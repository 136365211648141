import { EScmType } from '@blindspot/common/types/bff/scm';
import { IIntegrations } from '../../../Utils/integrations/types';

export const findIntegrationExpiredAt = (integrations: IIntegrations[], provider: EScmType): string => {
  if (!integrations) {
    return;
  }
  const integration = integrations.find((integration) => integration.type === provider);

  if (!integration) {
    return;
  }

  return integration.expiredAt;
};
