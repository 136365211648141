import React from 'react';
import { Checkbox } from '@FormElements';
import { IListSelectionProps } from './interfaces';

export const ListSelection: React.FC<IListSelectionProps> = ({ keys, selection }) => {
  const isAllKeysSelected = keys.every((key) => (selection.allSelected ? !selection.excluded[key] : selection.included[key]));

  const toggleAllItemsSelection = () => {
    keys.forEach((key) => {
      selection.onChange(key, !isAllKeysSelected);
    });
  };

  return <Checkbox checked={isAllKeysSelected} onChange={toggleAllItemsSelection} />;
};
