import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { IFilterOption, IFilterOptionsProps } from '../../types';
import { FilterDropdownIcon } from './FilterDropdownIcon';
import { FilterOption } from './FilterOption';
import { styles } from './config';
import { useQueryParams } from '@Utils';
import { useFilter } from '../../context';

export const FilterSelect: React.FC<IFilterOptionsProps> = ({
  filterId,
  options,
  menu,
  valueContainer,
  clearIndicator,
  placeholder,
  maxMenuHeight,
  isLoading,
  controlShouldRenderValue,
  isMulti = true,
}) => {
  const { queryParams } = useQueryParams({});
  const [selectedValues, setSelectedValues] = useState([]);
  const { updateFilters, appliedFilters } = useFilter();

  useEffect(() => {
    const currentFilter = queryParams[`filter[${filterId}]`];

    if (currentFilter) {
      const defaultOptions = options.filter(({ value }) => currentFilter.split('+').includes(value));
      setSelectedValues(defaultOptions.map(({ label, value }) => ({ label, value })));
    } else {
      setSelectedValues([]);
    }
  }, [options, appliedFilters]);

  const onOptionChange = (options: IFilterOption[]) => {
    setTimeout(() => {
      setSelectedValues(options);
    }, 0);
    updateFilters(filterId, options);
  };

  return (
    <Select
      id={filterId}
      isOptionDisabled={(option) => (isMulti ? false : selectedValues.length === 1 && selectedValues[0].value !== option.value)}
      options={options}
      value={selectedValues}
      placeholder={placeholder || 'Select'}
      components={{
        ClearIndicator: clearIndicator || null,
        IndicatorSeparator: null,
        DropdownIndicator: FilterDropdownIcon,
        Option: FilterOption,
        ...(menu ? { Menu: menu } : {}),
        ...(valueContainer ? { ValueContainer: valueContainer } : {}),
      }}
      styles={styles}
      isMulti
      onChange={onOptionChange}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      maxMenuHeight={maxMenuHeight || 190}
      isLoading={isLoading}
      controlShouldRenderValue={controlShouldRenderValue || false}
    />
  );
};
