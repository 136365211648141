import React from 'react';
import { convertBytesToDecimalUnit } from '@Utils';
import { EReportFileType, EReportStatus } from '../../../types';
import { IReportProps } from '../interfaces';
import { ReportDescriptionWrapper } from '../styled';

export const ReportDescription: React.FC<IReportProps> = ({ report }) => {
  const fileSize = report.fileSize === null ? 0 : report.fileSize;
  const isSBOMReport = report.fileType === EReportFileType.JSON && report.name.startsWith('SBOM');

  if (report.status === EReportStatus.COMPLETED) {
    return (
      <ReportDescriptionWrapper $lowercase>
        {convertBytesToDecimalUnit(fileSize)} · {report.fileType}
      </ReportDescriptionWrapper>
    );
  }

  if (report.status === EReportStatus.ERROR) {
    return <ReportDescriptionWrapper $small>Something went wrong with with exporting the data, please try again</ReportDescriptionWrapper>;
  }

  return <ReportDescriptionWrapper>{isSBOMReport ? 'SBOM' : 'Table'} is being created</ReportDescriptionWrapper>;
};
