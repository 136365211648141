import { axiosConfig as axios } from '@Utils';

export const fetchScanConfigSets = async (token: string) => {
  const response = await axios('configs/scan', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
