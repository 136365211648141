import styled, { css } from 'styled-components';

export const VerticalDivider = styled.span(
  ({ theme }) => css`
    display: inline-block;
    width: 1px;
    height: 16px;
    background: ${theme.colors.zinc[200]};
    margin: 0 ${theme.spacing[1]};
  `,
);
