import React, { ReactNode, useState } from 'react';
import { IDrawerProviderProps } from './interfaces';
import { DrawerContext } from './DrawerContext';
import { DrawerWindow } from '../components';

export const DrawerProvider: React.FC<IDrawerProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);

  const openDrawer = (content: ReactNode) => {
    setContent(content);
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
      <DrawerWindow isOpen={isOpen} content={content} closeDrawer={closeDrawer} />
      {children}
    </DrawerContext.Provider>
  );
};
