import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const StatusInfoContainerStyled = styled(Flex)(
  ({ theme }) => css`
    align-items: flex-start;
    margin-top: ${theme.spacing[1]};
    margin-bottom: ${theme.spacing[2]};
    padding: ${theme.spacing[1.5]} ${theme.spacing[2]};
    font-size: ${theme.text.size.body2};
    background-color: ${theme.colors.rose[100]};
    border-radius: 8px;
  `,
);
