import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const ColumnVisibilityButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing[1]};
    background: transparent;
    line-height: 22.4px;
    letter-spacing: 0.4px;
    height: fit-content;
    border: 1px solid ${theme.colors.zinc[500]};
    border-radius: 8px;
    padding: ${theme.spacing[0.5]} ${theme.spacing[1.5]};
    font-size: ${theme.text.size.body2};
    color: ${theme.colors.zinc[900]};
  `,
);
