import styled from 'styled-components';

export const SelectOptionStyled = styled.div<{ $isSelected: boolean }>`
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
  transition: 200ms;
  background: inherit;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.zinc[300]};
  }

  ${({ $isSelected, theme }) => {
    if ($isSelected) {
      return `
                background: ${theme.colors.indigo[100]};
            `;
    }
  }}
`;
