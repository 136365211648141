import styled from 'styled-components';

export const CheckboxIconStyled = styled.span<{ $isChecked: boolean }>`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: inline-block;
  background: ${({ $isChecked, theme }) => ($isChecked ? theme.colors.indigo[500] : theme.colors.white)};
  border: 2px solid;
  border-color: ${({ $isChecked, theme }) => ($isChecked ? theme.colors.indigo[500] : theme.colors.zinc[300])};

  svg {
    position: absolute;
    top: 0;
    left: 0;
    display: ${({ $isChecked }) => ($isChecked ? 'inline-block' : 'none')};
    height: 14px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
