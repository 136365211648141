import styled, { css } from 'styled-components';
import { Flex } from '@Styles';

export const TicketLabelStyled = styled(Flex)(
  ({ theme }) => css`
    height: 28px;
    border-radius: 4px;
    padding: 2px ${theme.spacing[1]};
    gap: ${theme.spacing[1]};
    align-items: center;
    align-self: center;
    white-space: nowrap;
    background: ${theme.colors.zinc[50]};
    color: ${theme.colors.zinc[500]};
    font-size: ${theme.text.size.caption};
    font-weight: ${theme.text.weight.bold};
    line-height: 18.6px;
    letter-spacing: 0.16px;
  `,
);
