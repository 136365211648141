import { useCallback, useEffect, useState } from 'react';
import { paragon, SDK_EVENT } from '@useparagon/connect';
import * as Sentry from '@sentry/react';
import { useUserOrganization } from '@Hooks';
import { buildParagonUserMetadata } from '../utils';
import { useOrganizationParagonIntegrationsToken } from './useOrganizationParagonIntegrationsToken.hook';

const paragonProjectId = process.env.REACT_APP_PARAGON_PROJECT_ID;

export function useParagon() {
  const { data: paragonUserToken, isLoading: isLoadingParagonUserToken } = useOrganizationParagonIntegrationsToken();
  const { data: userOrganization, isLoading: isLoadingUserOrganization } = useUserOrganization();
  const [user, setUser] = useState(paragon.getUser());

  const isLoading = isLoadingParagonUserToken || isLoadingUserOrganization;

  const updateUser = useCallback(() => {
    const authedUser = paragon.getUser();
    if (authedUser.authenticated) {
      setUser({ ...authedUser });
    }
  }, []);

  useEffect(() => {
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, updateUser);
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateUser);

    return () => {
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, updateUser);
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateUser);
    };
  }, []);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const metadata = buildParagonUserMetadata(userOrganization);
        await paragon.authenticate(paragonProjectId, paragonUserToken, { metadata });

        const authedUser = paragon.getUser();
        if (authedUser.authenticated) {
          setUser(authedUser);
        }
      } catch (err) {
        Sentry.captureException(err);
        Sentry.captureMessage(`Paragon integration error on user authentication: ${err.message}`);
      }
    };

    if (!isLoading) {
      authenticateUser();
    }
  }, [paragonUserToken, isLoading]);

  return { paragon, user, isLoading };
}
