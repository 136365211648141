import React from 'react';
import { useParams } from 'react-router-dom';
import { IProgressBarEventData, useProgressBar } from '@Utils';
import { RepositoryProgressBarListItem } from './RepositoryProgressBarListItem';
import { IRepositoryProgressBarList, IRepositoryProgressBarMap, ERepositoryProgressBarType, IRepositoryProgressBar } from './interfaces';
import { ProgressBarDescription, ProgressBarsList } from './styled';
import { EMonitoringStatus } from '@APITypes';
import { RepositoryProgressBarPlaceholder } from './RepositoryProgressBarPlaceholder';

export const RepositoryProgressBarList: React.FC<IRepositoryProgressBarList> = ({ monitoringStatus, progressBars = [] }) => {
  const progressBarsMap: IRepositoryProgressBarMap = progressBars.reduce((acc, progressBar) => {
    const { type, processId } = progressBar;
    if (!acc[processId]) {
      acc[processId] = { [type]: progressBar };
    } else {
      acc[processId][type] = progressBar;
    }
    return acc;
  }, {});
  const [progressBarsByCommitMap, setProgressBarsByCommitMap] = React.useState<IRepositoryProgressBarMap>(progressBarsMap);
  const { repoId } = useParams();

  React.useEffect(() => {
    setProgressBarsByCommitMap(progressBarsMap);
  }, [repoId]);

  const handleProgressBarEvent = (progressBarEvent: IProgressBarEventData) => {
    const { jobType, processId, total, seen } = progressBarEvent;
    setProgressBarsByCommitMap((prevState) => {
      const newState = { ...prevState };
      if (!newState[processId]) {
        newState[processId] = {};
      }
      newState[processId][jobType] = { seen, total, processId, type: jobType as ERepositoryProgressBarType };
      return newState;
    });
  };

  const removeProgressBar = (progressBar: IRepositoryProgressBar) => {
    setProgressBarsByCommitMap((prevState) => {
      const newState = { ...prevState };
      delete newState[progressBar.processId]?.[progressBar.type];
      return newState;
    });
  };
  const handleProgressBar = (progressBarEvent: IProgressBarEventData) => {
    if (repoId == progressBarEvent.repository) {
      handleProgressBarEvent(progressBarEvent);
    }
  };

  useProgressBar(handleProgressBar, repoId);

  const progressBarsList = Object.values(progressBarsByCommitMap).flatMap((progressMap) => {
    return Object.keys(progressMap).map((type) => progressMap[type]);
  });

  const isScanning = monitoringStatus === EMonitoringStatus.SCANNING;
  const showScanningDescription = isScanning || progressBarsList.length > 0;
  const showScanningPlaceholder = isScanning && progressBarsList.length === 0;

  return (
    <>
      {showScanningDescription && <ProgressBarDescription>It will take just a few minutes. We’ll let you know once the scan is complete.</ProgressBarDescription>}
      <ProgressBarsList>
        {showScanningPlaceholder && <RepositoryProgressBarPlaceholder />}
        {progressBarsList.map((progressBar) => (
          <RepositoryProgressBarListItem key={`${progressBar.type}-${progressBar.processId}`} progressBar={progressBar} onFinish={removeProgressBar} />
        ))}
      </ProgressBarsList>
    </>
  );
};
