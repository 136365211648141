import styled from 'styled-components';

export const OrganizationSelectStyled = styled.select`
  font-family: 'Manrope';
  appearance: none;
  background: ${({ theme }) => theme.colors.indigo['800']};
  color: ${({ theme }) => theme.colors.zinc['50']};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  padding: ${({ theme }) => `${theme.spacing['1']} ${theme.spacing['4']} ${theme.spacing['1']} ${theme.spacing['1.5']}`};
  border-radius: 5px;
  border: none;
  width: 100%;
  margin: 1em 0;
  cursor: pointer;
`;
