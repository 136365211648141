import { useMutation, useQueryClient } from 'react-query';
import { repositoryMonitorAll } from '../apis';
import { useSession } from '@descope/react-sdk';

export const useRepositoryMonitorAll = () => {
  const queryClient = useQueryClient();
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const invalidateRepositories = async () => {
    await queryClient.invalidateQueries({ queryKey: ['repositories'] });
  };

  return useMutation(
    async () => {
      if (isAuthenticated && !isSessionLoading) {
        return await repositoryMonitorAll(sessionToken);
      }
    },
    {
      retry: false,
      onSuccess: async () => {
        await invalidateRepositories();
        return true;
      },
    },
  );
};
