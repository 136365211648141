import { useQuery } from 'react-query';
import { IIssue, IPage } from '@APITypes';
import { IApiIssuesParams } from '../interfaces';
import { fetchIssues1stParty } from '../api';
import { useSession } from '@descope/react-sdk';

export const useIssues1stParty = (params: IApiIssuesParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['issues-first-party', params],
    queryFn: async () => {
      return await fetchIssues1stParty(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IPage<IIssue>>(queryOptions);
};
