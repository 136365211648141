import styled, { css } from 'styled-components';

export const Button = styled.button<{ $color?: 'pending' }>`
  cursor: pointer;
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing['1']} ${({ theme }) => theme.spacing['1.5']};
  transition: 200ms;
  font-size: ${({ theme }) => theme.text.size['body2']};
  font-weight: 500;
  letter-spacing: 0.17px;
  white-space: nowrap;
  align-items: center;
  display: inline-flex;
  border: 1px solid;
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  min-height: 40px;
  width: 100%;
  text-align: center;
  justify-content: center;

  &:disabled {
    border-color: ${({ theme }) => theme.colors.zinc['300']};
    color: ${({ theme }) => theme.colors.zinc['300']};
    cursor: default;
    pointer-events: none;
  }

  ${(props) => {
    switch (props.$color) {
      case 'pending':
        return css`
          border-color: ${props.theme.colors.zinc['500']};
          color: ${props.theme.colors.zinc['600']};
        `;
      default:
        return css`
          border-color: ${props.theme.colors.indigo['500']};
          color: ${props.theme.colors.indigo['500']};
        `;
    }
  }}
`;
