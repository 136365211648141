import styled, { css } from 'styled-components';

export const CheckboxLabelText = styled.span(
  ({ theme }) => css`
    padding-top: ${theme.spacing[0.5]};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 24px;
    letter-spacing: 0.2px;
  `,
);
