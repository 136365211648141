import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const ToastContainerStyled = styled(ToastContainer)`
  .Toastify__toast {
    color: ${({ theme }) => theme.colors.white};
    width: 500px;
    background: ${({ theme }) => theme.colors.indigo[900]};
    box-shadow: ${({ theme }) => theme.shadows[1]};
  }

  .Toastify__close-button {
    cursor: pointer;
    transition: 200ms;
    color: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.spacing[1.5]};

    &:hover {
      transform: rotate(90deg);
    }
  }
`;
