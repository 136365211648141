import { EScanConfigEmailUpdatesValueUpdate, IScanConfigEmailUpdatesValue } from '@blindspot/common/types/bff/configs';

export const emailUpdatesOptions: { value: IScanConfigEmailUpdatesValue; label: string }[] = [
  {
    value: { enabled: true, updates: [EScanConfigEmailUpdatesValueUpdate.NEW_ISSUES] },
    label: 'New issues',
  },
  {
    value: { enabled: true, updates: [EScanConfigEmailUpdatesValueUpdate.NEW_ISSUES, EScanConfigEmailUpdatesValueUpdate.NEW_DEPENDENCIES] },
    label: 'New issues and dependencies',
  },
  { value: { enabled: false }, label: 'No emails' },
];
