import { Flex } from '@Styles';
import styled from 'styled-components';

export const CodeFileContainer = styled(Flex)<{ isSelected?: boolean }>`
  padding: 5px 0 5px 0;
  align-items: center;

  position: relative;
  left: 0px;
  width: max-content;

  cursor: pointer;
  ${({ isSelected, theme }) =>
    isSelected &&
    `
    color: ${theme.colors.indigo[600]};
    background-color: ${theme.colors.zinc[100]};
  `}
`;
