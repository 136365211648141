import { DashboardSectionStyled } from '../styled';
import { DependenciesByCategories } from './DependenciesByCategories';
import { Issues } from './Issues';
import { IssuesByCategory } from './IssuesByCategory';
import { IssuesPerCodeLanguages } from './IssuesPerCodeLanguages';
import { ReachableSwitch } from './ReachableSwitch';
import { Remediation } from './Remediation';
import { RiskiestRepository } from './RiskiestRepository';

export const DashboardDetectionSection = () => (
  <>
    <DashboardSectionStyled>
      <Issues />
      <Remediation />
    </DashboardSectionStyled>
    <ReachableSwitch />
    <DashboardSectionStyled>
      <DependenciesByCategories />
      <IssuesByCategory />
      <IssuesPerCodeLanguages />
      <RiskiestRepository />
    </DashboardSectionStyled>
  </>
);
