import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const PopperContent = styled(Flex)<{ $open: boolean }>`
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 2px 12px rgba(10, 24, 154, 0.05);
  z-index: 100;
  transition: all 200ms;

  ${({ $open }) =>
    $open &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
