import React, { useEffect, useRef, useState, KeyboardEvent, ChangeEvent } from 'react';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FormControlHelperText } from '../../../SettingsPage/components/InviteMemberForm/styled';
import { inviteTeamMemberValidationRules } from '../../../SettingsPage/components/InviteMemberForm/validation';
import { ControlInputContainer, ControlInputStyled, ControlInputChipStyled, CloseIconStyled, ControlInputBlockStyled } from './styled';
import { IEmailsInputProps } from './interfaces';

export const EmailsInput: React.FC<IEmailsInputProps> = ({ refCallback, onChange, onBlur, error, setError, clearError }) => {
  const [inputValue, setInputValue] = useState('');
  const [emails, setEmails] = useState<string[]>([]);
  const [isEmailInputFocused, setIsEmailInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, []);

  const onRemoveEmail = (email: string) => {
    setEmails((prevEmails) => prevEmails.filter((e) => e !== email));
  };

  const onInputFocus = () => {
    setIsEmailInputFocused(true);
    if (inputValue) {
      clearError();
    }
  };

  const onInputBlur = () => {
    setIsEmailInputFocused(false);
    onInputParseValue(inputValue);
    onBlur();
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const eventTarget = event.target as HTMLInputElement;

    if (event.key === 'Enter' || event.code === 'Comma') {
      event.preventDefault();
      onInputParseValue(eventTarget.value);
    }
  };

  const onInputParseValue = (value: string) => {
    if (!value) {
      return;
    }

    const isValidEmails = inviteTeamMemberValidationRules.emails.pattern.value.test(value);
    if (!isValidEmails) {
      const errorMessage = inviteTeamMemberValidationRules.emails.pattern.message;
      return setError(errorMessage);
    } else {
      clearError();
    }

    const parsedEmails = value
      .split(',')
      .map((email) => email.trim())
      .filter(Boolean);

    const updatedEmails: string[] = [...new Set([...emails, ...parsedEmails])];
    setEmails(updatedEmails);
    onChange(updatedEmails);
    setInputValue('');
  };

  return (
    <ControlInputBlockStyled>
      <ControlInputContainer $error={!!error} $focused={isEmailInputFocused}>
        {emails.map((email) => {
          const onClickChip = () => onRemoveEmail(email);
          return (
            <ControlInputChipStyled key={email}>
              {email}
              <CloseIconStyled icon={faClose} onClick={onClickChip} />
            </ControlInputChipStyled>
          );
        })}
        <ControlInputStyled
          name="emails"
          placeholder="Email*"
          value={inputValue}
          onKeyDown={onKeyDown}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          ref={(el: HTMLInputElement) => {
            refCallback(el);
            inputRef.current = el;
          }}
        />
      </ControlInputContainer>
      <FormControlHelperText $error={!!error}>{error || 'Separate emails with comma'}</FormControlHelperText>
    </ControlInputBlockStyled>
  );
};
