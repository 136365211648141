import styled from 'styled-components';
import { Flex } from '@Styles';

export const NodeStyled = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[1]}`};
  border-radius: ${({ theme }) => theme.spacing[4]};
  border-bottom: 0.759px solid ${({ theme }) => theme.colors.zinc[200]};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.right};

  color: ${({ theme }) => theme.colors.zinc[700]};
  font-size: 6px;
  font-family: ${({ theme }) => theme.text.fontFamily};
`;
