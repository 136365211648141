import styled, { css } from 'styled-components';
import { Flex } from '@Styles';

export const MonitoringEngineDisabledWarningStyled = styled(Flex)(
  ({ theme }) => css`
    flex-direction: column;
    margin-top: ${theme.spacing[2]};
    gap: ${theme.spacing[2]};
  `,
);
