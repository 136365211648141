import { useQuery } from 'react-query';
import { IScanConfigSetRepository } from '@blindspot/common/types/bff/configs';
import { fetchScanConfigSetRepositories } from './api';
import { useSession } from '@descope/react-sdk';

export const useScanConfigSetRepositories = (scanConfigSetUuid: string) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['scanConfigSetRepositories', scanConfigSetUuid],
    queryFn: async () => {
      return await fetchScanConfigSetRepositories(scanConfigSetUuid, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IScanConfigSetRepository[]>(queryOptions);
};
