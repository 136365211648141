import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReachabilityTreeNode } from './types';
import { treeNodeIconMap } from './utils';

export const TreeNodeIcon: React.FC<ReachabilityTreeNode> = ({ data }) => {
  const icon = treeNodeIconMap[data.kind];
  if (!icon) {
    return null;
  }

  return <FontAwesomeIcon icon={icon} size="sm" />;
};
