import React from 'react';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { SortingStyled } from './Sorting.styled';
import { SortDirection } from '@tanstack/react-table';

export const Sorting: React.FC<{ direction: false | SortDirection }> = ({ direction }) => {
  if (direction === 'asc') {
    return <SortingStyled icon={faSortUp} $direction={direction} />;
  }

  if (direction === 'desc') {
    return <SortingStyled icon={faSortDown} $direction={direction} />;
  }
  return null;
};
