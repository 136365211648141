import React from 'react';
import { ModalStandard } from '@Modal';
import { ModalContent } from './styled';
import { InviteMemberForm } from '../InviteMemberForm';

export const InviteMemberModal: React.FC = () => {
  return (
    <ModalStandard
      title="Invite team member"
      content={
        <ModalContent>
          <InviteMemberForm />
        </ModalContent>
      }
    />
  );
};
