import styled, { css } from 'styled-components';

export const CodeFileLineStyled = styled.div<{ isLast?: boolean }>(
  ({ theme, isLast }) => css`
    position: relative;
    left: ${isLast ? '0px' : '-15px'};

    &:only-child {
      &:before {
        content: '';
        position: absolute;
        top: 30px;
        width: 1px;
        height: calc(100% - (${theme.spacing[2]} * 2));
        border-left: 1px dashed ${theme.colors.zinc[200]};
      }

      &:after {
        content: '';
        position: absolute;
        width: ${theme.spacing[1.5]};
        height: ${theme.spacing[1]};
        border-bottom: 1px dashed ${theme.colors.zinc[200]};
        top: 38px;
      }
    }
  `,
);
