import styled from 'styled-components';

export const RedirectButtonStyled = styled.button`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.spacing[1]};
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[1.5]}`};
  transition: 200ms;
  font-size: ${({ theme }) => theme.text.size.subtitle2};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  letter-spacing: 0.17px;
  white-space: nowrap;
  outline: none;
  align-items: center;
  display: inline-flex;

  &:disabled {
    border-color: ${({ theme }) => theme.colors.zinc[300]};
    color: ${({ theme }) => theme.colors.white};
    cursor: default;
    pointer-events: none;
    background: ${({ theme }) => theme.colors.zinc[300]};
  }

  border: 1px solid ${({ theme }) => theme.colors.indigo[500]};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.indigo[500]};

  height: 40px;
`;
