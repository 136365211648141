import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useParams } from 'react-router-dom';

import { Table } from 'core/Table';
import { useDependencyDetails, usePullRequestDetailsContext } from '../../hooks';
import { DependencyFilePath } from '../DependencyFilePath';
import { Relationship } from './styled';
import { IDependencyFilesTableData, IDependencyFilesTableProps } from './interfaces';

export const DependencyFilesTable: React.FC<IDependencyFilesTableProps> = ({ isDiff }) => {
  const { depId, branchUuid } = useParams();
  const columnHelper = createColumnHelper<IDependencyFilesTableData>();

  const { pullRequest } = usePullRequestDetailsContext() || {};
  const branchId = pullRequest?.headBranch?.uuid || branchUuid;
  const diff = useMemo(() => (branchId && isDiff ? { branchId } : undefined), [branchId, isDiff]);

  const { data: dependency, isLoading } = useDependencyDetails({ depId, diff, filters: { 'filter[withFixed]': 'true' } });

  const dependenciesFiles = (dependency?.repositories || [])
    .map((repository) =>
      repository.files.map((file) => ({
        ...file,
        repositoryName: `${repository.owner}/${repository.name}`,
      })),
    )
    .flat();

  const columns = useMemo(
    () => [
      columnHelper.accessor('repositoryName', {
        header: 'Repository Name',
      }),
      columnHelper.accessor('path', {
        header: 'File Path',
        cell: ({ row }) => {
          const { path } = row.original;
          return <DependencyFilePath path={path} />;
        },
      }),
      columnHelper.accessor('relationship', {
        header: 'Relationship',
        cell: ({ row }) => {
          const { relationship } = row.original;
          return <Relationship>{relationship}</Relationship>;
        },
        size: 100,
      }),
    ],
    [],
  );

  return <Table columns={columns} data={dependenciesFiles} isLoading={isLoading} />;
};
