import { useQuery } from 'react-query';
import { IRemediationTargetFe } from '@APITypes';
import { fetchRemediationTargets } from '../api';
import { useSession } from '@descope/react-sdk';

export const useRemediationTargets = (repoId: string, depId: string) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['remediationTargets', repoId, depId],
    queryFn: async () => {
      return await fetchRemediationTargets(repoId, depId, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IRemediationTargetFe[]>(queryOptions);
};
