import React from 'react';

import { IRemediationIssuesProps } from './interfaces';
import { RemediationIssueLabelsContainerStyled, RemediationIssuesTitleStyled, RemediationIssuesStyled } from './styled';
import { RemediationIssueLabels } from './RemediationIssueLabels';

export const RemediationIssues: React.FC<IRemediationIssuesProps> = ({ title, issues, isBold }) => {
  return (
    <RemediationIssuesStyled>
      <RemediationIssuesTitleStyled $bold={isBold}>{title}</RemediationIssuesTitleStyled>
      <RemediationIssueLabelsContainerStyled>
        <RemediationIssueLabels issues={issues} />
      </RemediationIssueLabelsContainerStyled>
    </RemediationIssuesStyled>
  );
};
