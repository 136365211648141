import { Flex } from '@Styles';
import styled from 'styled-components';

export const EffortDescriptionStyled = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[0.5]};

  width: 600px;
  margin-left: ${({ theme }) => theme.spacing[1]};
`;
