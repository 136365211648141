import React, { createContext, useMemo } from 'react';
import { usePusher } from './hooks/usePusher.hook';
import Pusher from 'pusher-js';

export const SocketContext = createContext<{ pusher: Pusher }>({ pusher: undefined });

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const { pusher } = usePusher();

  const value = useMemo(() => {
    return { pusher };
  }, [pusher]);

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};
