import styled, { css } from 'styled-components';

export const FormControlHelperText = styled.span<{ $error: boolean }>`
  height: 19px;
  line-height: 160%;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.zinc[500]};
  margin-top: ${({ theme }) => theme.spacing[0.5]};
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.regular};

  ${({ $error, theme }) =>
    $error &&
    css`
      color: ${theme.colors.rose['600']};
    `}
`;
