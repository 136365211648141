import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationToggleStyled = styled(Flex)<{ $isToggled?: boolean }>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2]}`};
  margin: ${({ theme }) => `0 -${theme.spacing[4]}`};
  cursor: pointer;

  align-items: center;
  gap: ${({ theme }) => theme.spacing[1]};

  ${({ $isToggled }) => $isToggled && `box-shadow: ${({ theme }) => theme.shadows[3]};`}
`;
