import { Flex } from '@Styles';
import styled from 'styled-components';

export const NoIssuesContentStyled = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
`;
