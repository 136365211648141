import { useQuery } from 'react-query';
import { fetchReachabilityIssue } from '../api';
import { IApiReachabilityIssueParams, IReachabilityIssueDetails } from '../types';
import { useSession } from '@descope/react-sdk';

export const useReachabilityIssue = (params: IApiReachabilityIssueParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const queryOptions = {
    queryKey: ['issueReachabilityDetails', params],
    queryFn: async () => {
      return await fetchReachabilityIssue(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };
  return useQuery<IReachabilityIssueDetails>(queryOptions);
};
