import { useQuery } from 'react-query';
import { fetchPullRequests } from '../api';
import { IApiPullRequestsParams } from '../types';
import { IPullRequest } from '@blindspot/common/types/bff/api';
import { IPage } from '@APITypes';
import { useSession } from '@descope/react-sdk';

export const usePullRequests = (params: IApiPullRequestsParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['pullRequests', params],
    queryFn: async () => {
      return await fetchPullRequests(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IPage<IPullRequest>>(queryOptions);
};
