import React from 'react';
import { ModalStandard } from '@Modal';
import { ModalContentStyled } from './styled';
import { CreateOrganizationTokenForm } from '../CreateOrganizationTokenForm';

export const CreateOrganizationTokenModal: React.FC = () => {
  return (
    <ModalStandard
      title="Create new token"
      content={
        <ModalContentStyled>
          <CreateOrganizationTokenForm />
        </ModalContentStyled>
      }
    />
  );
};
