import React, { useContext } from 'react';
import Switch from 'react-switch';
import { colors } from '@Styles';
import { Tooltip } from '@Components';
import { IntegrationsContext } from '@Utils';
import { IntegrationStatusEnum } from 'Layouts/IntegrationsPage/types';
import { SCM_LABELS } from '../../constants';
import { SwitchDescriptionCaptionStyled, SwitchDescriptionStyled, SwitchLabelStyled } from './styled';
import { ICheckAllSwitchProps } from './interfaces';
import { useUserOrganization } from '@Hooks';
import { EScmAppType } from '@blindspot/common/types/bff';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const CheckAllSwitch: React.FC<ICheckAllSwitchProps> = ({ value, onChange }) => {
  const { integrations } = useContext(IntegrationsContext);
  const { data: userOrg } = useUserOrganization();
  const isActiveScmManagement = userOrg?.scm.find((scm) => scm.status === IntegrationStatusEnum.ACTIVE).appType !== EScmAppType.MANAGEMENT;
  const activeIntegration = integrations?.find((integration) => integration.status === IntegrationStatusEnum.ACTIVE);
  const isAzureIntegration = activeIntegration?.type === EScmType.AZURE_DEVOPS;
  const shouldShowCliNote = activeIntegration?.type !== EScmType.GITHUB && !isActiveScmManagement;

  const checkAllSwitch = (
    <div>
      <SwitchLabelStyled htmlFor="check-all" $disabled={isAzureIntegration}>
        Scanning
        <Switch
          id="check-all"
          disabled={isAzureIntegration}
          checked={value}
          onChange={onChange}
          height={14}
          width={26}
          uncheckedIcon={false}
          checkedIcon={false}
          offColor={colors.zinc[400]}
          onColor={colors.indigo[500]}
        />
      </SwitchLabelStyled>
      <SwitchDescriptionStyled>By enabling Pull Requests Scans Myrror will scan every newly created Pull Request targeted to be merged to the selected branch.</SwitchDescriptionStyled>
      {shouldShowCliNote && <SwitchDescriptionCaptionStyled>* In order to scan a Pull Request please add Myrror's CLI to your repository's root directory</SwitchDescriptionCaptionStyled>}
    </div>
  );

  if (isAzureIntegration) {
    return <Tooltip tooltip={`PR checks will be available for ${SCM_LABELS[activeIntegration.type]} soon`}>{checkAllSwitch}</Tooltip>;
  }
  return checkAllSwitch;
};
