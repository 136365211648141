import React from 'react';
import Switch from 'react-switch';
import { Caption, colors } from '@Styles';
import { IMonitoringEngineOptionProps } from './interfaces';
import { MonitoringEngineOptionLabelStyled } from './styled';

export const MonitoringEngineOption: React.FC<IMonitoringEngineOptionProps> = ({ name, value, title, description, onChange }) => {
  return (
    <MonitoringEngineOptionLabelStyled htmlFor={name}>
      <div>
        <b>{title}</b>
        <Caption>{description}</Caption>
      </div>
      <Switch id={name} checked={!!value} onChange={onChange} height={14} width={26} uncheckedIcon={false} checkedIcon={false} offColor={colors.zinc[400]} onColor={colors.indigo[500]} />
    </MonitoringEngineOptionLabelStyled>
  );
};
