import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

export const TooltipInfoIconStyled = styled(FontAwesomeIcon).attrs({ icon: faCircleInfo })(
  ({ theme }) => css`
    color: ${theme.colors.orange[500]};
    padding-left: ${theme.spacing['1']};
    position: relative;
    top: 2px;
  `,
);
