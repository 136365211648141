import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const AiIconWrapper = styled(Flex)(
  ({ theme }) => css`
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: ${theme.spacing[0.5]};
    background-color: ${theme.colors.indigo[100]};
  `,
);
