import { ContentBlock } from '@Styles';
import styled from 'styled-components';

export const DashboardCardStyled = styled(ContentBlock)<{ $maxHeight?: string; $paddingBottom?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[3]}`};
  padding-bottom: ${({ theme, $paddingBottom }) => ($paddingBottom ? $paddingBottom : theme.spacing[3])};
  border-radius: ${({ theme }) => theme.spacing[4]};
  max-height: ${({ $maxHeight }) => $maxHeight};
  width: 100%;
  & .apexcharts-tooltip.apexcharts-theme-light {
    background: ${({ theme }) => theme.colors.indigo[900]};
    color: ${({ theme }) => theme.colors.white};
  }

  & .apexcharts-legend.apexcharts-align-right .apexcharts-legend-series,
  .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    display: flex;
    align-items: center;
  }

  & .apexcharts-title-text {
    font-family: ${({ theme }) => theme.text.fontFamily};
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.text.size.h3};
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }

  & div[options] {
    display: flex;
    justify-content: center;
  }
`;
