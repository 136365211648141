import { axiosConfig as axios } from '@Utils';

export const unIgnoreIssue = async (token: string, issueId: string) => {
  const response = await axios.delete(`/issues/${issueId}/ignore`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
