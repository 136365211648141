import { useQuery } from 'react-query';
import { fetchRepositoryDetails } from '../api';
import { IRepository } from '@APITypes';
import { useSession } from '@descope/react-sdk';

export const useRepositoryDetails = (repoId: string, isEnabled = true) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['repositoryDetails', repoId],
    queryFn: async () => {
      return await fetchRepositoryDetails(repoId, sessionToken);
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && isEnabled && !isSessionLoading,
  };

  return useQuery<IRepository>(queryOptions);
};
