import styled, { css } from 'styled-components';

export const SwitchDescriptionCaptionStyled = styled.span(
  ({ theme }) => css`
    display: block;
    font-size: ${theme.text.size.caption};
    font-style: normal;
    line-height: 20px;
  `,
);
