import React from 'react';
import { useQueryClient } from 'react-query';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { Loader } from '@Components';
import { useReportGenerationCompleted, useReportGenerationError } from '@Utils';
import { Report } from './components';
import { useReports } from './hooks';
import { ExportsWrapper, NoDataIcon, NoDataLabel, NoDataWrapper } from './styled';

export const Exports: React.FC = () => {
  const queryClient = useQueryClient();
  const { data: reports = [], isLoading } = useReports();

  const invalidateReports = () => queryClient.invalidateQueries('reports');
  useReportGenerationCompleted(invalidateReports);
  useReportGenerationError(invalidateReports);

  if (isLoading) {
    return (
      <ExportsWrapper>
        <Loader />
      </ExportsWrapper>
    );
  }

  if (!reports.length) {
    return (
      <ExportsWrapper>
        <NoDataWrapper>
          <NoDataIcon icon={faCircleCheck} />
          <NoDataLabel>No exports available</NoDataLabel>
        </NoDataWrapper>
      </ExportsWrapper>
    );
  }

  return (
    <ExportsWrapper>
      {reports.map((report) => (
        <Report key={report.uuid} report={report} />
      ))}
    </ExportsWrapper>
  );
};
