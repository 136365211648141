import styled from 'styled-components';
import { RemediationDetailsStyled } from './RemediationDetails.styled';

export const RemediationDependencyStyled = styled.li`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing[1.5]} 0`};
  padding: ${({ theme }) => `0 ${theme.spacing[0.5]}`};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.indigo[100]};
    mix-blend-mode: multiply;
    border-radius: ${({ theme }) => theme.spacing[0.5]};
  }

  &:hover ${RemediationDetailsStyled} {
    visibility: visible;
  }

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colors.zinc[200]};
  }
`;
