import React from 'react';
import { IRemediationTargetIssuesProps } from './types';
import { RemediationIssueLabels } from './RemediationIssueLabels';
import { RemediationTargetIssuesStyled, RemediationTargetIssuesTitleStyled } from './styled';

export const RemediationTargetIssues: React.FC<IRemediationTargetIssuesProps> = ({ title, issues, isBold = false }) => (
  <RemediationTargetIssuesStyled $isBold={isBold}>
    <RemediationTargetIssuesTitleStyled>{title}</RemediationTargetIssuesTitleStyled>
    <RemediationIssueLabels issues={issues} />
  </RemediationTargetIssuesStyled>
);
