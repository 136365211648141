import styled from 'styled-components';

export const PaginationSelectStyled = styled.select`
  margin: ${({ theme }) => `0 ${theme.spacing[2]}`};
  padding-right: ${({ theme }) => theme.spacing[0.5]};
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.zinc[900]};
  background: transparent;
  cursor: pointer;

  option {
    padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
  }
`;
