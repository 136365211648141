import { DetailsContainerStyled, ScreenHeightStyled } from 'Layouts/DiscoveryPage/styled/Details';
import { BackButton } from '../SubComponents';
import { useLocation, useParams } from 'react-router-dom';
import { PullRequestDetailsAdditional, PullRequestInfo } from './components';
import { usePullRequest } from './hooks';
import { Loader } from '@Components';
import { useEffect, useState } from 'react';

export const PullRequestDetails = () => {
  const { repoId, pullRequestUuid } = useParams();
  const location = useLocation();
  const [pullRequest, setPullRequest] = useState(location?.state?.pullRequest);

  const { data: pullRequestData, isLoading } = usePullRequest({ repoId, pullRequestUuid, isEnabled: !pullRequest });

  useEffect(() => {
    if (pullRequestData) {
      setPullRequest(pullRequestData);
    }
  }, [pullRequestData]);

  if (isLoading || !pullRequest) {
    return (
      <ScreenHeightStyled>
        <DetailsContainerStyled>
          <Loader />
        </DetailsContainerStyled>
      </ScreenHeightStyled>
    );
  }

  return (
    <>
      <BackButton />
      <PullRequestInfo pullRequest={pullRequest} />

      <PullRequestDetailsAdditional pullRequest={pullRequest} />
    </>
  );
};
