import React from 'react';
import { ECodeViewerIconTypes, IFolderLineProps, IFunctionLine, folderTypeToIcon } from './types';
import { CodeFileChildListStyled, CodeFileContainer, CodeFileFunctionNameStyled, CodeFileIcon, CodeFileLineStyled, CodeFileNameStyled } from './styled';

const FunctionLine: React.FC<IFunctionLine> = ({ fnName }) => {
  return (
    <CodeFileNameStyled>
      <CodeFileFunctionNameStyled>{fnName}</CodeFileFunctionNameStyled>
    </CodeFileNameStyled>
  );
};

export const FolderLine: React.FC<IFolderLineProps> = ({ name, type, filesList, functions, selectedKey, onFunctionClick }) => {
  const [isOpened, setIsOpened] = React.useState(true);

  const handleOpen = () => {
    setIsOpened(!isOpened);
  };
  const handleFileClick = (fn, key) => () => onFunctionClick(fn, key);
  const arrowIcon = folderTypeToIcon[String(isOpened)];
  const folderIcon = folderTypeToIcon[type];

  return (
    <CodeFileLineStyled>
      <CodeFileContainer onClick={handleOpen}>
        <CodeFileIcon icon={arrowIcon.icon} size={arrowIcon.size} />

        <CodeFileIcon icon={folderIcon.icon} size={folderIcon.size} />
        <CodeFileNameStyled>{name}</CodeFileNameStyled>
      </CodeFileContainer>

      {functions && isOpened && (
        <CodeFileChildListStyled>
          {[...functions.values()].map((fn, i) => {
            const funcData = JSON.parse(fn);
            const isLastItem = i === functions.size - 1;
            const key = `function-${funcData.functionName}-${i}`;
            const isSelected = selectedKey === key;
            const codeIcon = folderTypeToIcon[ECodeViewerIconTypes.code];
            return (
              <CodeFileLineStyled key={key} onClick={handleFileClick(funcData, key)} isLast={isLastItem}>
                <CodeFileContainer isSelected={isSelected}>
                  <CodeFileIcon icon={codeIcon.icon} size={codeIcon.size} />
                  <FunctionLine fnName={funcData.functionName} isLast={isLastItem} />
                </CodeFileContainer>
              </CodeFileLineStyled>
            );
          })}
        </CodeFileChildListStyled>
      )}
      {filesList?.size > 0 && isOpened && (
        <CodeFileChildListStyled>
          {[...filesList.values()].map((child) => (
            <FolderLine
              key={child.name}
              name={child.name}
              type={child.type}
              filesList={child.filesList}
              functions={child.functions}
              onFunctionClick={onFunctionClick}
              selectedKey={selectedKey}
            />
          ))}
        </CodeFileChildListStyled>
      )}
    </CodeFileLineStyled>
  );
};
