import React from 'react';
import { PaginationButtonStyled } from './PaginationButton.styled';
import { PaginationPagesStyled } from './PaginationPages.styled';
import { PaginationStyled } from './Pagination.styled';
import { PaginationWrapperStyled } from './PaginationWrapper.styled';
import { PaginationRowStyled } from './PaginationRow.styled';
import { Select } from 'core/Table/TableSelect';
import { PaginationButtonsStyled } from './PaginationButtons.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { ITablePagination, SelectPosition, SelectStyleMode } from 'core/Table/types';
import { rowsInPageOptions } from 'core/Table';

export const Pagination: React.FC<ITablePagination> = ({ table }) => {
  const tableLength = table.getAllColumns().length;
  const itemCount = table.options.itemCount;
  const currentPage = table.getState().pagination.pageIndex + 1;
  const pageSize = table.getState().pagination.pageSize;
  const pageItems = table.getState().pagination.pageIndex === 0 ? pageSize : currentPage * pageSize;
  const pageItemsFrom = pageItems - pageSize + 1;
  const pageItemsTo = pageItems <= itemCount ? pageItems : itemCount;

  const handleSelect = (value: number) => {
    table.setPageSize(value);
    table.setPageIndex(0);
  };

  return (
    <PaginationStyled>
      <PaginationRowStyled colSpan={tableLength}>
        <PaginationWrapperStyled>
          <span>Row per page:</span>
          <Select styleMode={SelectStyleMode.Empty} name={'inPage'} options={rowsInPageOptions} selectedOption={pageSize} openDirection={SelectPosition.Bottom} onChange={handleSelect} />
          <PaginationPagesStyled>
            {`${pageItemsFrom}-${pageItemsTo}`} of {itemCount}
          </PaginationPagesStyled>
          {table.getPageCount() > 1 && (
            <PaginationButtonsStyled>
              <PaginationButtonStyled onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </PaginationButtonStyled>
              <PaginationButtonStyled onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                <FontAwesomeIcon icon={faAngleRight} />
              </PaginationButtonStyled>
            </PaginationButtonsStyled>
          )}
        </PaginationWrapperStyled>
      </PaginationRowStyled>
    </PaginationStyled>
  );
};
