import React from 'react';
import { ModalStandard, useModal } from '@Modal';
import { Button, ButtonColor, ButtonSize, ButtonVariant } from '@Components';
import { ModalContentStyled } from './styled';
import { ActionsContainerStyled } from '../../styled';
import { IScanConfigSetActionModalProps } from './interfaces';

export const ScanConfigSetActionModal: React.FC<IScanConfigSetActionModalProps> = ({ repositoriesCount = 0, content, onSave, buttonLabel = 'Save' }) => {
  const { closeModal } = useModal();

  const onClickSave = () => {
    onSave();
    closeModal();
  };

  return (
    <ModalStandard
      title="Please note"
      content={
        <ModalContentStyled>
          <div>
            This configuration is in use in {repositoriesCount} {repositoriesCount === 1 ? 'repository' : 'repositories'}.
            <br />
            <br />
            {content}
          </div>
          <ActionsContainerStyled>
            <Button onClick={closeModal} color={ButtonColor.ZINC} variant={ButtonVariant.OUTLINED} size={ButtonSize.LARGE}>
              Cancel
            </Button>
            <Button onClick={onClickSave} variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE}>
              {buttonLabel}
            </Button>
          </ActionsContainerStyled>
        </ModalContentStyled>
      }
    />
  );
};
