import styled from 'styled-components';

export const InfoLabelStyled = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  letter-spacing: 0.16px;
  line-height: 19px;
  border-radius: ${({ theme }) => theme.spacing[0.5]};
  background: ${({ theme }) => theme.colors.indigo[100]};
  color: ${({ theme }) => theme.colors.zinc[800]};
  padding: ${({ theme }) => `2px ${theme.spacing[1]}`};

  & > span {
    display: inline-block;
    margin: ${({ theme }) => `0 ${theme.spacing[0.5]}`};
  }

  & span:first-child {
    margin-left: 0;
  }

  & span:last-child {
    margin-right: 0;
  }
`;
