import styled, { css } from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbarWrapperProps } from 'react-circular-progressbar/dist/types';

export const CircularProgress = styled(CircularProgressbar).attrs({ strokeWidth: 15 })<CircularProgressbarWrapperProps>(
  ({ theme }) => css`
    width: ${theme.spacing[2.5]};
    height: ${theme.spacing[2.5]};

    .CircularProgressbar-trail {
      stroke-linecap: butt;
      stroke: ${theme.colors.zinc['200']};
    }

    .CircularProgressbar-path {
      stroke-linecap: butt;
      stroke: ${theme.colors.indigo['400']};
    }
  `,
);
