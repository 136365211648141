import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldExclamation } from '@fortawesome/pro-regular-svg-icons';
import styled, { css } from 'styled-components';

export const StatusInfoIcon = styled(FontAwesomeIcon).attrs({ icon: faShieldExclamation })(
  ({ theme }) => css`
    margin-top: calc(${theme.spacing[0.5]} / 1.25);
    color: ${theme.colors.rose[600]};
  `,
);
