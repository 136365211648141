import { axiosConfig as axios } from '@Utils';
import { IUseUpdateToken } from '../hooks/types';

export const updateToken = async ({ provider, data, scmUuid }: IUseUpdateToken, token: string) => {
  return axios.put(
    `scm/${scmUuid}/token`,
    {
      provider,
      data,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
