import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationTargetIssuesStyled = styled(Flex)<{ $isBold: boolean }>`
  align-items: center;
  font-weight: ${({ theme, $isBold }) => ($isBold ? theme.text.weight.bold : theme.text.weight.regular)};
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[1]};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[2]};
  }
`;
