import { useQuery } from 'react-query';
import { fetchSastIssueMetadata } from '../api';
import { useSession } from '@descope/react-sdk';

export const useSastIssueIgnoreMetadata = (fingerprint: string, enabled = true) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  return useQuery({
    queryKey: ['sastIssueIgnoreMetadata', fingerprint],
    queryFn: async () => {
      return await fetchSastIssueMetadata(sessionToken, fingerprint);
    },
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading && enabled,
  });
};
