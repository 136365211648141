import { Flex } from '@Styles';
import styled from 'styled-components';

export const RemediationTargetContainerStyled = styled(Flex)`
  padding: ${({ theme }) => theme.spacing[1]} ${({ theme }) => theme.spacing[2.5]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: ${({ theme }) => theme.spacing[2.5]};
  top: 10px;
`;
