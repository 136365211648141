import styled from 'styled-components';

export const DashboardContainerStyled = styled.section<{ $centeredCell?: boolean; $marginTop?: string }>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-row-gap: ${({ theme }) => theme.spacing[2]};
  position: relative;
  max-width: 1620px;
  margin-right: auto;
  margin-left: auto;
  background-color: ${({ theme }) => theme.colors.indigo[50]};
  margin-top: ${({ $marginTop }) => $marginTop || '0px'};

  ${({ $centeredCell }) => {
    if ($centeredCell) {
      return `
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 100px);
        flex-direction: column;
      `;
    }
  }}
`;
