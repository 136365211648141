import { useMutation } from 'react-query';
import { updateRemediationTarget } from '../api';
import { IApiRemediationTargetUpdateParams } from '../types';
import { useSession } from '@descope/react-sdk';

export const useRemediationTargetUpdate = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async (params: IApiRemediationTargetUpdateParams) => {
    if (!isAuthenticated && !isSessionLoading) {
      return;
    }

    return updateRemediationTarget(params, sessionToken);
  });
};
