import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { AxiosError } from 'axios';

import { ESign } from '@Components';
import { useModal } from '@Modal';
import { pageTools } from '@Utils';
import { ENotificationType, toastService } from '@Notification';
import { IntegrationModal } from '../IntegrationModal';
import { useIntegrationOrganizations, useUpdateIntegrationOrganization } from '../SelectIntegrationOrganizationForm/hooks';
import { IBitbucketWorkspaceSelectFormData } from './types';
import { WorkspaceSelect } from './components';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const BitbucketWorkspaceSelectModal = () => {
  const { data: organizations = [], isLoading } = useIntegrationOrganizations(EScmType.BITBUCKET);
  const { mutate: updateIntegrationOrganization, isLoading: isSendingOrganization } = useUpdateIntegrationOrganization();
  const { closeModal } = useModal();

  const defaultValues: IBitbucketWorkspaceSelectFormData = { organizationId: '' };

  const formData = useForm<IBitbucketWorkspaceSelectFormData>({
    defaultValues,
  });
  const { control, formState, reset } = formData;
  const { errors } = formState;

  const organizationSelectOptions = organizations.map((organization) => {
    return { value: organization.id, name: organization.name };
  });

  useEffect(() => {
    reset(defaultValues);
  }, []);

  const onSubmit: SubmitHandler<IBitbucketWorkspaceSelectFormData> = async ({ organizationId }) => {
    updateIntegrationOrganization(organizationId, {
      onSuccess() {
        closeModal();
        window.location.href = pageTools.dashboard.path;
      },
      onError(error) {
        if (error instanceof AxiosError) {
          toastService({ header: 'Something went wrong', type: ENotificationType.ERROR });
        }
      },
    });
  };

  return (
    <IntegrationModal
      nextButtonType="submit"
      title={'Bitbucket'}
      titleSign={ESign.bitbucket}
      formData={formData}
      onSubmit={onSubmit}
      inputs={<WorkspaceSelect control={control} errors={errors} organizationSelectOptions={organizationSelectOptions} />}
      nextButtonDisabled={isLoading || isSendingOrganization}
      isLoading={isLoading || isSendingOrganization}
    />
  );
};
