import styled, { css } from 'styled-components';

export const ProgressBarLabel = styled.p<{ $hidden: boolean }>(
  ({ theme, $hidden }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.bold};
    line-height: normal;
    opacity: ${$hidden ? 0 : 1};
    transition: opacity 0.2s ease;
    user-select: none;
  `,
);
