import { Flex } from '@Styles';
import styled from 'styled-components';

export const ChartStyled = styled(Flex)<{ isInteractiveCategories?: boolean; isInteractiveTitle?: boolean; isInteractiveLabels?: boolean }>`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  ${({ isInteractiveCategories: isInteractive }) =>
    isInteractive &&
    ` & .apexcharts-yaxis-label {
      text-decoration: underline;
      cursor: pointer;
    }
  `}
  ${({ isInteractiveTitle }) =>
    isInteractiveTitle &&
    ` & .apexcharts-title-text {
      text-decoration: underline;
      cursor: pointer;
    }
  `}
  ${({ isInteractiveLabels: isInteractiveLavels }) =>
    isInteractiveLavels &&
    ` & .apexcharts-xaxis-label {
      text-decoration: underline;
      cursor: pointer;
    }
  `}
`;
