import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { faBoxTaped, faCircleNodes, faFileLines, faFolder, faRectangleVerticalHistory, faShieldExclamation, faSitemap } from '@fortawesome/pro-regular-svg-icons';
import { InfoLabel, ESign, CopyClipboard, Loader, LinkBlank, SignRender } from '@Components';
import { ESeverityLevel, NotAvailable } from '@APITypes';
import { EIssuesCategoryLabel } from '@ComponentsTypes';
import { ResourceContext, createResourceContext } from '@Contexts';
import { DATE_TIME_LONG_FORMAT, convertBytesToDecimalUnit, usePostProcessing } from '@Utils';
import { BackButton, SourceCodeLink } from './SubComponents';
import { useDependencyDetails } from '../hooks';
import { DependencyDetailsAdditional } from './';
import {
  DetailsIcon,
  DetailsDescriptionStyled,
  DetailsCellStyled,
  DetailsConnectionsIcon,
  DetailsContainerStyled,
  DetailsDescriptionWrapperStyled,
  DetailsHeaderStyled,
  DetailsLeftStyled,
  DetailsRightSectionStyled,
  DetailsSubtitleStyled,
  DetailsTableSectionStyled,
  DetailsTitleStyled,
  Divider,
  ScreenHeightStyled,
  SmallDetailsStyled,
  DetailsIssuesWrapperStyled,
  DetailsIssueCategoryStyled,
  DetailsLinkLabelStyled,
  VerticalDivider,
} from '../styled/Details';
import { IDependenciesDetailsProps } from './types';

export const DependenciesDetails: React.FC<IDependenciesDetailsProps> = ({ isDiff }) => {
  const queryClient = useQueryClient();
  const { depId, branchUuid } = useParams();
  const diff = React.useMemo(() => (branchUuid && isDiff ? { branchId: branchUuid } : undefined), [branchUuid, isDiff]);
  const { data: dependency, isLoading } = useDependencyDetails({ depId, diff, filters: { 'filter[withFixed]': 'true' } });

  const invalidateDependencyDetails = () => queryClient.invalidateQueries({ queryKey: ['dependencyDetails', { depId, diff }] });
  usePostProcessing(invalidateDependencyDetails);

  if (isLoading)
    return (
      <ScreenHeightStyled>
        <DetailsContainerStyled>
          <Loader />
        </DetailsContainerStyled>
      </ScreenHeightStyled>
    );

  if (!dependency) {
    return <DetailsContainerStyled />;
  }

  const scanDate = dependency.scanDate ? format(new Date(dependency.scanDate), DATE_TIME_LONG_FORMAT) : NotAvailable;
  const releaseDate = dependency.releaseDate ? format(new Date(dependency.releaseDate), DATE_TIME_LONG_FORMAT) : NotAvailable;
  const latestVersionReleaseDate = dependency.latestVersionReleaseDate ? format(new Date(dependency.latestVersionReleaseDate), DATE_TIME_LONG_FORMAT) : NotAvailable;

  return (
    <div>
      <BackButton />
      <DetailsContainerStyled>
        <DetailsHeaderStyled>
          <div>
            <DetailsSubtitleStyled>Dependency</DetailsSubtitleStyled>
            <DetailsTitleStyled>
              <b>{dependency.name}</b>
              {dependency.securityStatus && (
                <InfoLabel type={dependency.securityStatus} capitalize>
                  {dependency.securityStatus}
                </InfoLabel>
              )}
            </DetailsTitleStyled>
          </div>
          <DetailsSubtitleStyled $small>Last scan: {scanDate}</DetailsSubtitleStyled>
        </DetailsHeaderStyled>

        {dependency.description && <DetailsDescriptionStyled>{dependency.description}</DetailsDescriptionStyled>}

        <Divider />

        <DetailsDescriptionWrapperStyled>
          <DetailsTableSectionStyled>
            <DetailsLeftStyled>
              <DetailsIcon icon={faFolder} />
              Package origin:
            </DetailsLeftStyled>
            {dependency.packageManagerUrl ? (
              <LinkBlank href={dependency.packageManagerUrl}>
                <span>
                  <SignRender size="small" name={dependency.dependencyOrigin.toLowerCase() as ESign} />
                </span>
                <DetailsLinkLabelStyled>{dependency.dependencyOrigin}</DetailsLinkLabelStyled>
              </LinkBlank>
            ) : (
              NotAvailable
            )}

            <DetailsLeftStyled>
              <DetailsIcon icon={faFolder} />
              Source code:
            </DetailsLeftStyled>
            <SourceCodeLink src={dependency.srcHomepage} text={dependency.srcHomepage?.split('/').reverse()[0]} origin={ESign.github} />

            <DetailsLeftStyled>
              <DetailsIcon icon={faSitemap} />
              Connections:
            </DetailsLeftStyled>
            <DetailsRightSectionStyled $right>
              <DetailsCellStyled>
                <DetailsConnectionsIcon icon={faFolder} />
                <span>
                  <b>{dependency.repositoriesCount}</b> {`${dependency.repositoriesCount === 1 ? 'Repository' : 'Repositories'}`}
                </span>
              </DetailsCellStyled>
            </DetailsRightSectionStyled>

            <DetailsLeftStyled>
              <DetailsIcon icon={faCircleNodes} />
              Relationship:
            </DetailsLeftStyled>
            <DetailsCellStyled $capitalize>{dependency.relationship}</DetailsCellStyled>

            <DetailsLeftStyled>
              <DetailsIcon icon={faShieldExclamation} />
              Security issues:
            </DetailsLeftStyled>
            <DetailsIssuesWrapperStyled>
              <DetailsIssueCategoryStyled>
                <InfoLabel celled type={dependency.severeIssues?.critical && ESeverityLevel.CRITICAL}>
                  {dependency.severeIssues?.critical || 0}
                </InfoLabel>
                <DetailsCellStyled>{EIssuesCategoryLabel.CRITICAL}</DetailsCellStyled>
              </DetailsIssueCategoryStyled>
              <DetailsIssueCategoryStyled>
                <InfoLabel celled type={dependency.severeIssues?.high && ESeverityLevel.HIGH}>
                  {dependency.severeIssues?.high || 0}
                </InfoLabel>
                <DetailsCellStyled>{EIssuesCategoryLabel.HIGH}</DetailsCellStyled>
              </DetailsIssueCategoryStyled>
              <DetailsIssueCategoryStyled>
                <InfoLabel celled type={dependency.severeIssues?.medium && ESeverityLevel.MEDIUM}>
                  {dependency.severeIssues?.medium || 0}
                </InfoLabel>
                <DetailsCellStyled>{EIssuesCategoryLabel.MEDIUM}</DetailsCellStyled>
              </DetailsIssueCategoryStyled>
              <DetailsIssueCategoryStyled>
                <InfoLabel celled type={dependency.severeIssues?.low && ESeverityLevel.LOW}>
                  {dependency.severeIssues?.low || 0}
                </InfoLabel>
                <DetailsCellStyled>{EIssuesCategoryLabel.LOW}</DetailsCellStyled>
              </DetailsIssueCategoryStyled>
            </DetailsIssuesWrapperStyled>
          </DetailsTableSectionStyled>

          <DetailsTableSectionStyled>
            <DetailsLeftStyled>
              <DetailsIcon icon={faRectangleVerticalHistory} />
              Current Version:
            </DetailsLeftStyled>
            <DetailsCellStyled>
              {dependency.installedVersion || NotAvailable}
              <SmallDetailsStyled>{releaseDate}</SmallDetailsStyled>
            </DetailsCellStyled>

            <DetailsLeftStyled>
              <DetailsIcon icon={faRectangleVerticalHistory} />
              Latest Version:
            </DetailsLeftStyled>
            <DetailsCellStyled>
              {dependency.latestVersion || NotAvailable}
              <SmallDetailsStyled>{latestVersionReleaseDate}</SmallDetailsStyled>
            </DetailsCellStyled>

            <DetailsLeftStyled>
              <DetailsIcon icon={faFileLines} />
              Package size:
            </DetailsLeftStyled>
            <DetailsCellStyled>{dependency.artifactSize ? convertBytesToDecimalUnit(parseInt(`${dependency.artifactSize}`, 10)) : NotAvailable}</DetailsCellStyled>

            <DetailsLeftStyled>
              <DetailsIcon icon={faBoxTaped} />
              Package Hash:
            </DetailsLeftStyled>
            <DetailsCellStyled>
              {dependency.sha1 ? (
                <CopyClipboard copyText={dependency.sha1} name={'SHA1'}>
                  SHA1
                </CopyClipboard>
              ) : null}
              {dependency.sha1 && dependency.sha256 ? <VerticalDivider /> : null}
              {dependency.sha256 ? (
                <CopyClipboard copyText={dependency.sha256} name={'SHA256'}>
                  SHA256
                </CopyClipboard>
              ) : null}
            </DetailsCellStyled>
          </DetailsTableSectionStyled>
        </DetailsDescriptionWrapperStyled>
      </DetailsContainerStyled>

      <ResourceContext.Provider value={createResourceContext({ depId })}>
        <DependencyDetailsAdditional isDiff={isDiff} />
      </ResourceContext.Provider>
    </div>
  );
};
