import React, { useEffect, useState } from 'react';
import { FloatingFocusManager, autoUpdate, offset, shift, useClick, useDismiss, useFloating, useId, useInteractions, useRole, useTransitionStyles } from '@floating-ui/react';
import { spacing } from '@Styles';
import { PopperContent, PopperTrigger } from './styled';
import { IPopperProps } from './interfaces';
import { PopperContext } from './PopperContext';

export const Popper: React.FC<IPopperProps> = ({ children, triggerElement: trigger, placement = 'top', open: propIsOpen, onClose: propOnClose }) => {
  const [isOpen, setIsOpen] = useState(!!propIsOpen);

  const { refs, floatingStyles, context } = useFloating({
    placement: placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(parseInt(spacing[0.5], 16)), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);
  const { styles: transitionStyle } = useTransitionStyles(context, {
    duration: 200,
  });
  const headingId = useId();

  const onClose = () => {
    setIsOpen(false);

    if (propOnClose) {
      propOnClose();
    }
  };

  useEffect(() => {
    if (propIsOpen !== undefined) {
      setIsOpen(propIsOpen);
    }
  }, [propIsOpen]);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const triggerElement = typeof trigger === 'function' ? trigger(isOpen) : trigger;

  return (
    <PopperContext.Provider value={{ open: isOpen, setOpen: setIsOpen }}>
      <>
        <PopperTrigger onClick={onClick} ref={refs.setReference} {...getReferenceProps()}>
          {triggerElement}
        </PopperTrigger>
        <FloatingFocusManager context={context}>
          <PopperContent ref={refs.setFloating} $open={isOpen} style={Object.assign(floatingStyles, transitionStyle)} aria-labelledby={headingId} {...getFloatingProps()}>
            {children}
          </PopperContent>
        </FloatingFocusManager>
      </>
    </PopperContext.Provider>
  );
};
