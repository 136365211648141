import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MetadataLinkStyled = styled(Link)<{ $isLast?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${({ $isLast }) => ($isLast ? '0' : '12px')};
  font-size: ${({ theme }) => theme.text.size.body2};
`;
