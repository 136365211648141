import { useQuery } from 'react-query';
import { fetchRepositoryBranches } from './api';
import { IBranch } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useRepositoryBranches = (repositoryId: string) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['repositoryBranches', repositoryId],
    queryFn: async () => {
      return await fetchRepositoryBranches(repositoryId, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };
  return useQuery<IBranch[]>(queryOptions);
};
