import { useNavigate } from 'react-router-dom';

import { DashboardCardStyled, RedirectButtonStyled } from '../../styled';
import NoIntegrationIcon from '@Images/graph.svg';
import { pageTools } from '@Utils';
import { EmptyStateTitleStyled, NoIntegrationDescriptionStyled, NoIntegrationContentStyled, NoIntegrationImage } from './styled';

export const NoIntegrationContent = () => {
  const navigate = useNavigate();
  return (
    <DashboardCardStyled $maxHeight="100vh">
      <NoIntegrationContentStyled>
        <NoIntegrationImage src={NoIntegrationIcon} alt="graph" />
        <EmptyStateTitleStyled>Integrate your SCM.</EmptyStateTitleStyled>
        <NoIntegrationDescriptionStyled>
          {`By integrating your SCM, we will be able monitor and scan your repositories and dependencies on every change in real-time.`}
        </NoIntegrationDescriptionStyled>
        <RedirectButtonStyled onClick={() => navigate(pageTools.integrations.path)}>Go to integrations</RedirectButtonStyled>
      </NoIntegrationContentStyled>
    </DashboardCardStyled>
  );
};
