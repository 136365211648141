import Shape4 from '@Images/login/shape4.png';
import styled from 'styled-components';

export const Shape4Styled = styled.img.attrs({
  src: Shape4,
})`
  position: absolute;
  bottom: 32px;
  right: 42px;
`;
