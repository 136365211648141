import styled from 'styled-components';

import { Flex } from '@Styles';

export const SummaryBlockContentStyled = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: ${({ theme }) => theme.spacing[3.5]};
`;
