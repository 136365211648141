import styled from 'styled-components';

export const NavContainerStyled = styled.nav`
  padding: ${({ theme }) => theme.spacing['2.5']};
  background: ${({ theme }) => theme.colors.indigo['900']};
  position: fixed;
  top: 0;
  left: 0;
  width: 235px;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
`;
