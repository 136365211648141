import { useQuery } from 'react-query';
import { fetchRepositoryMonitoringPreferences } from './api';
import { IMonitoringPreferences } from '../interfaces';
import { useSession } from '@descope/react-sdk';

export const useRepositoryMonitoringPreferences = (repositoryId: string) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['repositoryMonitoringPreferences', repositoryId],
    queryFn: async () => {
      return await fetchRepositoryMonitoringPreferences(repositoryId, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };
  return useQuery<IMonitoringPreferences>(queryOptions);
};
