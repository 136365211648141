import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { faLocationArrow } from '@fortawesome/pro-light-svg-icons';
import { format } from 'date-fns';

import { DATE_TIME_LONG_FORMAT, firstLetterToUpperCase } from '@Utils';
import { EReachabilityStatus, NotAvailable, Undetermined } from '@APITypes';
import { BackButton } from 'Layouts/DiscoveryPage/components/SubComponents/BackButton';
import { IssueReachabilityIconStyled } from '../components/IssueReachability/styled';
import { useReachabilityIssue } from './hooks';
import { EReachabilityText, IReachabilityIssue } from './types';
import { ReachabilityIssue } from './ReachabilityIssue';
import {
  ReachabilityDetailsDescriptionStyled,
  ReachabilityDetailsInfoLabelStyled,
  ReachabilityDetailsInfoStyled,
  ReachabilityDetailsInfoValueStyled,
  ReachabilityDetailsStyled,
  ReachabilityDetailsTitleStyled,
} from './styled';
import { ReachabilityDetailsNoData } from './ReachabilityDetailsNoData';
import { IssueDependencyChain } from './IssueDependencyChain/IssueDependencyChain';
import { ReachabilityPaths } from './ReachabilityPaths';

export const ReachabilityDetails: React.FC = () => {
  const { baseIssueUuid, issueUuid } = useParams();
  const { data: reachabilityIssue = null, isLoading } = useReachabilityIssue({ baseIssueUuid, issueUuid });
  const [reachabilityStatus, setReachabilityStatus] = useState(undefined);
  const [reachabilityDescription, setReachabilityDescription] = useState(undefined);

  useEffect(() => {
    if (reachabilityIssue) {
      setReachabilityStatus(firstLetterToUpperCase(reachabilityIssue?.reachabilityStatus === EReachabilityStatus.UNDEFINED ? Undetermined : reachabilityIssue?.reachabilityStatus));
      setReachabilityDescription(EReachabilityText[reachabilityIssue.reachabilityStatus.toUpperCase()]);
    }
  }, [reachabilityIssue, reachabilityIssue?.reachabilityStatus]);

  return (
    <>
      <BackButton />
      {!reachabilityIssue || isLoading ? (
        <ReachabilityDetailsNoData isLoading={isLoading} />
      ) : (
        <>
          <ReachabilityIssue issue={reachabilityIssue as IReachabilityIssue} hasAction={false} />
          <IssueDependencyChain />
          <ReachabilityDetailsStyled>
            <div>
              <ReachabilityDetailsTitleStyled>Reachability:</ReachabilityDetailsTitleStyled>
              {reachabilityStatus && (
                <ReachabilityDetailsDescriptionStyled>
                  <IssueReachabilityIconStyled icon={faLocationArrow} />
                  <span>{`${reachabilityStatus} - ${reachabilityDescription}`}</span>
                </ReachabilityDetailsDescriptionStyled>
              )}
              <ReachabilityDetailsInfoStyled>
                {reachabilityIssue.lastSeen && (
                  <li>
                    <ReachabilityDetailsInfoLabelStyled>Last scanned</ReachabilityDetailsInfoLabelStyled>
                    <ReachabilityDetailsInfoValueStyled>{format(new Date(reachabilityIssue.lastSeen), DATE_TIME_LONG_FORMAT)}</ReachabilityDetailsInfoValueStyled>
                  </li>
                )}
                {reachabilityIssue.hash && (
                  <li>
                    <ReachabilityDetailsInfoLabelStyled>Last Version Scanned</ReachabilityDetailsInfoLabelStyled>
                    <ReachabilityDetailsInfoValueStyled>{reachabilityIssue.hash}</ReachabilityDetailsInfoValueStyled>
                  </li>
                )}
              </ReachabilityDetailsInfoStyled>
            </div>
            <ReachabilityPaths />
          </ReachabilityDetailsStyled>
        </>
      )}
    </>
  );
};
