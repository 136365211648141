import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const DetailsCellStyled = styled(Flex)<{ $capitalize?: boolean; $underline?: boolean }>(
  ({ theme, $capitalize, $underline }) => css`
    align-items: center;
    color: ${theme.colors.zinc[800]};
    font-weight: ${theme.text.weight.extraLight};
    text-transform: ${$capitalize ? 'capitalize' : 'inherit'};
    text-decoration: ${$underline ? 'underline' : 'none'};
  `,
);
