export enum ButtonColor {
  ZINC = 'zinc',
  INDIGO = 'indigo',
  ROSE = 'rose',
  ORANGE = 'orange',
  GREEN = 'green',
  CYAN = 'cyan',
  WHITE = 'white',
  BLACK = 'black',
  TRANSPARENT = 'transparent',
}
