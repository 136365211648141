import React from 'react';
import { Tooltip } from '@Components';
import { GptIcon } from './AiIcon';
import { GptResponseWrapperStyled, GptResponseInfoStyled, InfoIcon, GptResponseScoreValueStyled } from './styled';
import { IGptResponseProps } from './interfaces';

export const GptResponse: React.FC<IGptResponseProps> = ({ score, description, scoreLabel }) => {
  const isScoreValid = score || score === 0;

  if (!isScoreValid && !description) {
    return null;
  }

  return (
    <GptResponseWrapperStyled>
      <GptIcon />
      <GptResponseInfoStyled>
        {isScoreValid && (
          <div>
            <b>
              {scoreLabel}: <GptResponseScoreValueStyled>{score}%</GptResponseScoreValueStyled>
            </b>
            <Tooltip
              tooltip={
                <span>
                  {'The percentage represents the probability that the given code is harmful or poses'}
                  <br /> {'a security risk based on its characteristics and behavior.'}
                </span>
              }
            >
              <InfoIcon />
            </Tooltip>
          </div>
        )}
        {description && <span>{description}</span>}
      </GptResponseInfoStyled>
    </GptResponseWrapperStyled>
  );
};
