import { DataNode } from 'rc-tree/lib/interface';

export const findTreeKeysById = (tree: DataNode[], id: string): string[] => {
  const treeKeys = [];
  for (const treeNode of tree) {
    if (`${treeNode.key}`.startsWith(id)) {
      treeKeys.push(treeNode.key);
    }
    if (treeNode.children) {
      treeKeys.push(...findTreeKeysById(treeNode.children, id));
    }
  }
  return treeKeys;
};
