import { getOrganizationParagonIntegrationsToken } from './api';
import { useQuery } from 'react-query';
import { useSession } from '@descope/react-sdk';

export const useOrganizationParagonIntegrationsToken = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['orgIntegrationsToken'],
    queryFn: async () => {
      return await getOrganizationParagonIntegrationsToken(sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<string>(queryOptions);
};
