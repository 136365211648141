import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const NotificationContentStyled = styled(Flex)(
  ({ theme }) => css`
    flex-direction: column;
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 24px;
    letter-spacing: 0.1px;
  `,
);
