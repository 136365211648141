import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const ListActionsWrapperStyled = styled(Flex)<{ $marginLeft?: boolean }>(
  ({ theme, $marginLeft }) => css`
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing['1.5']};
    margin-bottom: ${theme.spacing[2]};
    margin-left: ${$marginLeft ? `-${theme.spacing[0.5]}` : 0};
  `,
);
