import React from 'react';
import { DiscoveryContent } from './components';
import { ResourceContext, resourceContextDefault } from '@Contexts';
import { ContainerStyled } from './styled';

export const DiscoveryPage: React.FC = () => {
  return (
    <ContainerStyled>
      <ResourceContext.Provider value={resourceContextDefault}>
        <DiscoveryContent />
      </ResourceContext.Provider>
    </ContainerStyled>
  );
};
