import styled, { css } from 'styled-components';

export const RemediationRecommendedLabelStyled = styled.span(
  ({ theme }) => css`
    border-radius: 100px;
    padding: 2px ${theme.spacing[1]};
    background: ${theme.colors.indigo[100]};
    color: ${theme.colors.indigo[500]};
    font-size: ${theme.text.size.caption};
    font-weight: ${theme.text.weight.bold};
    letter-spacing: 0.15px;
    line-height: 18.6px;
  `,
);
