import React from 'react';
import { ITabsProps } from './interfaces';
import { Tab } from './components';
import { Flex } from '@Styles';
import { EmbeddedTabLink } from './styled';

export const EmbeddedTabPages: React.FC<ITabsProps> = ({ items, onClick, activeTab }) => {
  const activeKey = activeTab;

  return (
    <Flex>
      {items.map((item) => {
        if (item.linkTo) {
          return (
            <EmbeddedTabLink key={item.key} to={item.linkTo}>
              {({ isActive }) => <Tab item={item} onClick={onClick} isActive={isActive} />}
            </EmbeddedTabLink>
          );
        }

        return <Tab key={item.key} item={item} onClick={onClick} isActive={activeKey === item.key} />;
      })}
    </Flex>
  );
};
