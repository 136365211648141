import styled from 'styled-components';
import { ColumnAlign } from '../types';
import { Link } from 'react-router-dom';

export const TableLinkCellStyled = styled(Link)<{ $align: ColumnAlign; $padding?: string; $isLink: boolean }>`
  display: block;
  height: 100%;
  width: 100%;
  padding: ${({ theme, $padding }) => $padding || theme.spacing[2]};
  text-align: ${({ $align }) => $align || ColumnAlign.Left};
  color: ${({ theme }) => theme.colors.zinc[900]};
  cursor: ${({ $isLink }) => ($isLink ? 'pointer' : 'auto')};

  &:hover,
  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.zinc[900]};
  }
`;
