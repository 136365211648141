import { faCodeCompare, faFile, faFolderOpen, faShieldExclamation } from '@fortawesome/pro-light-svg-icons';
import { forEach } from 'lodash';
import { Node, Edge } from 'reactflow';
import { uniqBy } from 'lodash';

import { IReachabilityIssueDetails } from '../../types';
import { createEdge } from './createEdge';
import { createNode } from './createNode';
import { getNodeLabel } from './getNodeLabel';

export function createNodesAndEdges(reachabilityIssue: IReachabilityIssueDetails): { nodes: Node[]; edges: Edge[] } {
  const { repositoryName, filePath, dependencyChain: chains } = reachabilityIssue;
  const nodes = [createNode({ label: repositoryName, icon: faFolderOpen }), createNode({ label: filePath, icon: faFile })];
  const edges = [createEdge(repositoryName, filePath)];

  if (!chains || !chains.length) {
    return { nodes, edges };
  }

  chains.forEach((chainItems) => {
    forEach(chainItems, (chain, key) => {
      const isLastChain = !chainItems[+key + 1];
      const label = getNodeLabel(chain);
      nodes.push(
        createNode({
          label,
          icon: isLastChain ? faShieldExclamation : faCodeCompare,
          isIssueDependency: isLastChain,
        }),
      );
      if (key === '0') {
        edges.push(createEdge(filePath, label));
      } else {
        edges.push(createEdge(getNodeLabel(chainItems[key - 1]), label));
      }
    });
  });

  return { nodes: uniqBy(nodes, 'id'), edges: uniqBy(edges, 'id') };
}
