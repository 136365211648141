import { EScanConfigDependenciesInventoryFile } from '@blindspot/common/types/bff/configs';

export const dependenciesInventoryFileOptions: { value: EScanConfigDependenciesInventoryFile; title: string; description: string }[] = [
  {
    value: EScanConfigDependenciesInventoryFile.MANIFEST,
    title: 'Manifest File',
    description: 'Will list of the dependencies in the original manifest file and their relationship declared in that file',
  },
  {
    value: EScanConfigDependenciesInventoryFile.LOCK,
    title: 'Manifest Lock File',
    description: 'Will list of the dependencies in the lock file and their relationship declared in the lock file',
  },
];
