import styled from 'styled-components';

export const RemediationIssuesTitleStyled = styled.span`
  cursor: pointer;
  width: max-content;

  &:hover {
    text-decoration: underline;
  }
`;
