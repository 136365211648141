import React from 'react';
import { Tooltip } from '@Components';
import { IScoreFlagInfoTooltipProps } from '../types/scoreflag-info-tooltip.interface';
import { ERepositoryScore } from '@blindspot/common/types/bff/repositories';
import { FlagInfoTooltipStyled } from '../styled/FlagInfoTooltip.styled';

export const ScoreFlagInfoTooltip: React.FC<IScoreFlagInfoTooltipProps> = ({ score }: IScoreFlagInfoTooltipProps) => {
  const handleTooltipText = (score) => {
    switch (score) {
      case ERepositoryScore.A:
        return 'No issues detected in your repositories! Great Job!';
      case ERepositoryScore.B:
        return 'Your grade is B due to recommended low severity issues that were detected in your repositories';
      case ERepositoryScore.C:
        return 'Your grade is C due to recommended medium severity issues that were detected in your repositories';
      case ERepositoryScore.D:
        return 'Your grade is D due to recommended high severity issues that were detected in your repositories';
      case ERepositoryScore.F:
        return 'Your grade is F due to recommended critical issue that were detected in your repositories';
      default:
        return 'No issues detected in your repositories! Great Job!';
    }
  };
  return (
    <Tooltip placement="bottom" tooltip={handleTooltipText(score)}>
      <FlagInfoTooltipStyled />
    </Tooltip>
  );
};
