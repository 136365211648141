import { useInfiniteQuery } from 'react-query';
import { fetchPullRequests } from '../api';
import { IApiPullRequestsParams } from '../types';
import { IPullRequest } from '@blindspot/common/types/bff/api';
import { IPage } from '@APITypes';
import { useSession } from '@descope/react-sdk';

export const useInfinitePullRequests = (params: IApiPullRequestsParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['infinitePullRequests', params],
    queryFn: async ({ pageParam = 0 }) => {
      const newParams = {
        ...params,
        pagination: {
          ...params.pagination,
          pageIndex: pageParam,
        },
      };
      return await fetchPullRequests(newParams, sessionToken);
    },
    getNextPageParam: (lastPage: IPage<IPullRequest>) => {
      return lastPage.meta.pageCount > lastPage?.meta?.page ? lastPage.meta.page : undefined;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useInfiniteQuery<IPage<IPullRequest>>(queryOptions);
};
