import React from 'react';
import { ITabProps } from './interfaces';
import { ComingSoon, NumberChip, OldTabWrapper, TabIconStyled, TabWrapper } from './styled';

export const Tab: React.FC<ITabProps> = ({ item, isActive, onClick, isOldTab }) => {
  if (item.isTemporary) {
    if (isOldTab) {
      return (
        <OldTabWrapper $disabled>
          {item.title}
          <ComingSoon>Coming soon</ComingSoon>
        </OldTabWrapper>
      );
    }
    return (
      <TabWrapper $disabled>
        {item.title}
        <ComingSoon>Coming soon</ComingSoon>
      </TabWrapper>
    );
  }

  if (isOldTab) {
    return (
      <OldTabWrapper
        $active={isActive}
        onClick={() => {
          if (onClick) {
            onClick(item);
          }
        }}
      >
        {item.icon && <TabIconStyled icon={item.icon} />}
        {item.title}
        {item.number !== undefined && <NumberChip>{item.number}</NumberChip>}
      </OldTabWrapper>
    );
  }

  return (
    <TabWrapper
      $active={isActive}
      onClick={() => {
        if (onClick) {
          onClick(item);
        }
      }}
    >
      {item.icon && <TabIconStyled icon={item.icon} />}
      {item.title}
      {item.number !== undefined && <NumberChip>{item.number}</NumberChip>}
    </TabWrapper>
  );
};
