import { axiosConfig as axios } from '@Utils';
import { IApiRemediationTargetUpdateParams } from '../types';

export const updateRemediationTarget = async ({ repoId, depId, selectedVersion }: IApiRemediationTargetUpdateParams, token: string) => {
  const response = await axios.patch(
    `remediation/repository/${repoId}/sourcedependency/${depId}`,
    { selectedVersion },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response?.data;
};
