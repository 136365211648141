import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';
import { ToastItem } from './components';
import React from 'react';
import { IToastItemProps } from './components/ToastItem/types';

export const toastService = (props: IToastItemProps, options?: ToastOptions): number | string => {
  if (!options || options?.toastId === undefined) {
    options = {
      ...options,
      toastId: `${props.header}-${props.message}-${props.type}`,
    };
  }
  return toast(<ToastItem {...props} />, options);
};
