import React, { useMemo, useState } from 'react';
import { createColumnHelper, PaginationState } from '@tanstack/react-table';
import { useQueryClient } from 'react-query';
import { Table, initialTablePagination } from 'core/Table';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { AvatarRender, Loader } from '@Components';
import { useModal } from '@Modal';
import { Button, Flex, spacing } from '@Styles';
import { IOrganizationUser, NotAvailable } from '@APITypes';
import { UserWrapper, Name, InviteButton, UsersIcon, UserStatusChip, CellText } from './styled';
import { useUser } from '../../hooks';
import { InviteMemberModal } from '../InviteMemberModal';
import { useOrganizationUsers, useOrganizationUsersDelete } from './hooks';
import { usePagination, useQueryParams, useSearch } from '@Utils';
import { initialPaginatedData } from 'core/Table/config';
import { isPendingUser } from './utils';
import { ActionsDropdown } from 'core/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TeamTable: React.FC = () => {
  const { openModal } = useModal();

  const { queryParams } = useQueryParams({});
  const [searchValue, setSearchValue] = useState(queryParams.search);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: queryParams.page - 1 || initialTablePagination.pageIndex,
    pageSize: queryParams.size || initialTablePagination.pageSize,
  });
  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

  const queryClient = useQueryClient();
  const { data: organizationUsers = initialPaginatedData, isLoading } = useOrganizationUsers({ pagination, search: searchValue });
  const { mutate: deleteOrganizationUser } = useOrganizationUsersDelete();
  const { isLoading: isUserLoading, data: user } = useUser();
  usePagination(pageIndex, pageSize);
  useSearch(searchValue, { pageSize, setPagination });

  const onRemoveUser = (userId: string) => {
    deleteOrganizationUser(userId, {
      onSuccess() {
        queryClient.invalidateQueries('organizationUsers');
      },
    });
  };

  const onClickInviteMember = () => {
    openModal(<InviteMemberModal />);
  };

  const columnHelper = createColumnHelper<IOrganizationUser>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('displayName', {
        header: 'Name',
        meta: { padding: `${spacing[1]} ${spacing[2]}` },
        cell: ({ row }) => {
          const { status, uuid, displayName } = row.original;
          const isCurrentUser = uuid === user?.uuid;
          const showLoader = isUserLoading && isCurrentUser;
          const name = isCurrentUser ? user.displayName : displayName;
          let nameElement = <Name>{name}</Name>;

          if (showLoader) {
            nameElement = <Loader />;
          } else if (isPendingUser(status)) {
            nameElement = <CellText $pending>{NotAvailable}</CellText>;
          }

          return (
            <UserWrapper>
              <AvatarRender size="large" url={row.original.avatarUrl} />
              {nameElement}
            </UserWrapper>
          );
        },
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        cell: ({ row }) => {
          const { email, status } = row.original;

          return (
            <Flex>
              <CellText $pending={isPendingUser(status)}>{email}</CellText>
              {isPendingUser(status) && <UserStatusChip>Pending</UserStatusChip>}
            </Flex>
          );
        },
      }),
      columnHelper.accessor('role', {
        header: 'Role',
        cell: ({ row }) => {
          const { role, status } = row.original;
          return <CellText $pending={isPendingUser(status)}>{role.name}</CellText>;
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => {
          const isCurrentUser = row.original.uuid === user?.uuid;
          if (!isCurrentUser) {
            return (
              <Flex justifyContent="flex-end">
                <ActionsDropdown
                  isIconView={true}
                  menuItems={[
                    <Button onClick={() => onRemoveUser(row.original.uuid)} key={row.original.uuid}>
                      {<FontAwesomeIcon icon={faTrashCan} />}
                      Remove
                    </Button>,
                  ]}
                />
              </Flex>
            );
          }
        },
      }),
    ],
    [user],
  );

  const tableActions = (
    <InviteButton onClick={onClickInviteMember}>
      <UsersIcon icon={faCircleUser} />
      Invite members
    </InviteButton>
  );

  const options = {
    pagination: {
      value: pagination,
      onChange: setPagination,
      pageCount: organizationUsers.meta.pageCount,
      itemCount: organizationUsers.meta.itemCount,
    },
    search: {
      value: searchValue,
      onChange: (value: string) => setSearchValue(value),
    },
    actions: tableActions,
  };

  return <Table columns={columns} data={organizationUsers.data} tableFeatures={options} isLoading={isLoading} />;
};
