import React from 'react';
import { LanguageListStyled, LanguageItemStyled } from './styled';
import { isLanguageSupported } from 'Utils/language/isLanguageSupported';
import { ILanguageListProps } from './interfaces';

export const LanguageList: React.FC<ILanguageListProps> = ({ languages }) => {
  const transformedLanguages: Record<string, boolean> = languages.reduce(
    (accumulator, value) => ({
      ...accumulator,
      [value]: isLanguageSupported(value),
    }),
    {},
  );

  const sortedTransformedLanguages = Object.entries(transformedLanguages).sort((previousValue, currentValue) => Number(currentValue[1]) - Number(previousValue[1]));

  const items = sortedTransformedLanguages.map(([key, value]) => (
    <LanguageItemStyled $isBold={value} key={`langList-${key}`}>
      {`${key}`}
    </LanguageItemStyled>
  ));

  return (
    <LanguageListStyled>
      {items.reduce((previousValue, currentValue) => [...previousValue, currentValue, <i key={`langList-${currentValue.key}-comma`}>,&nbsp;</i>], []).slice(0, -1)}
    </LanguageListStyled>
  );
};
