import { axiosConfig as axios } from '@Utils';

export const fetchMagicLink = async (repoUuid: string, token: string) => {
  const response = await axios(`/repositories/magic-link/${repoUuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
