import NoMonitoringIcon from '@Images/folder-check.svg';
import { NoMonitoringDescriptionStyled, DashboardImageStyled } from './styled';
import { NoMonitoringContent } from './NoMonitoringContent';

export const NoMonitoringContentVisibility = () => {
  return (
    <NoMonitoringContent>
      <DashboardImageStyled src={NoMonitoringIcon} alt="folder-check" />
      <NoMonitoringDescriptionStyled>
        {`You're not monitoring any of your entities. By monitoring repositories, we will be able scan your dependencies on every change in real-time.`}
      </NoMonitoringDescriptionStyled>
    </NoMonitoringContent>
  );
};
