import styled, { css } from 'styled-components';

export const Divider = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.zinc[200]};
    width: 100%;
    height: 1px;
    margin: ${theme.spacing[2]} 0;
  `,
);
