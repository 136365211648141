import { useContext, useEffect, useState } from 'react';
import FunnelGraphEngine from 'funnel-graph-js';
import 'funnel-graph-js/dist/css/main.min.css';
import 'funnel-graph-js/dist/css/theme.min.css';
import { FunnelHeaderText } from './FunnelHeaderText';
import { ChartLoadingContext } from 'Layouts/DashboardPage/context';
import { Loader } from '@Components';
import { FunnelGraphStyled } from './FunnelGraph.styled';
import { pageTools } from '@Utils';
import { EIssueGroup } from 'Layouts/IssuesPage/interfaces';
import { useNavigate } from 'react-router-dom';
import { FUNNEL_DEFAULT_DATA, FUNNEL_DEFAULT_OPTIONS, LABEL_TO_LINK } from './types';

export const FunnelGraph = ({ series, isLoading }: { series: number[][]; isLoading: boolean }) => {
  const { setLoadingChart } = useContext(ChartLoadingContext);
  const [graphRef, setGraphRef] = useState(null);
  const navigate = useNavigate();

  const handleLabelClick = () => {
    const labels = document.querySelectorAll('.label__title');

    labels.forEach((label) => {
      label.addEventListener('click', () => {
        const route = LABEL_TO_LINK[label.innerHTML];
        navigate(route);
      });
    });
  };

  const rewriteLabelsSegments = (series: number[][]) => {
    const sum = (arr) => arr.reduce((acc, curr) => acc + curr, 0);
    const getPercent = (part, total) => Math.round((part / total) * 100);

    const segmentLabels = document.querySelectorAll('.segment-percentage__list');
    series.forEach((segment, index) => {
      const segmentLabel = segmentLabels[index];

      if (!segmentLabel) {
        return;
      }

      if (index === 0) {
        segmentLabel.parentElement.setAttribute('style', 'display: none');
        return;
      }

      const partCount = sum(segment);
      const totalCount = sum(series[index - 1]);

      segmentLabel.innerHTML = `
          <li>
          <span class="percentage__list-label">${100 - getPercent(partCount, totalCount)}%</span> reduction from  ${index === 1 ? 'All issues' : 'Severe issues'}
          </li>
        `;
    });
  };

  const clearGraph = () => {
    const funnel = document.getElementById('funnel');
    funnel.innerHTML = '';
  };

  useEffect(() => {
    setTimeout(() => {
      handleLabelClick();
      rewriteLabelsSegments(series);
    }, 0);
  }, [series]);

  useEffect(() => {
    setLoadingChart(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!series) return;

    const data = {
      ...FUNNEL_DEFAULT_DATA,
      values: series,
    };

    const options = {
      ...FUNNEL_DEFAULT_OPTIONS,
      data: data,
    };

    const graph = graphRef || new FunnelGraphEngine(options);
    setGraphRef(graph);

    if (graphRef) {
      graph.updateData(options.data);
    } else {
      clearGraph();
      graph.draw();
    }
  }, [series]);

  return (
    <FunnelGraphStyled>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <FunnelHeaderText to={`${pageTools.issues.path}/${EIssueGroup.ALL}`}>Issues</FunnelHeaderText>
          <div id="funnel" />
        </>
      )}
    </FunnelGraphStyled>
  );
};
