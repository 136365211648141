import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { InputLabel } from '@Components/Form';
import { Flex } from '@Styles';
import { Input } from '@FormElements';
import { ControlErrorMessage } from 'Layouts/components/Form/Controls';
import { IAppPasswordProps } from '../types/appPasswordProps.interface';
import { TextFieldStyled } from '../styled';

export const AppPasswordInputs = ({ control, errors }: IAppPasswordProps) => {
  return (
    <Flex flexDirection="column" gap={2.5}>
      <div>
        <Controller
          name="userName"
          control={control}
          rules={{ required: 'Please provide a user name' }}
          render={({ fieldState, field: { onChange } }) => (
            <TextFieldStyled>
              <InputLabel htmlFor="userName" $error={!!fieldState.error}>
                User name*
              </InputLabel>
              <Input $error={!!fieldState.error} name="userName" onChange={onChange} />
            </TextFieldStyled>
          )}
        />
        <ErrorMessage errors={errors} name="userName" render={ControlErrorMessage} />
      </div>
      <div>
        <Controller
          name="password"
          control={control}
          rules={{ required: 'Please provide a password' }}
          render={({ fieldState, field: { onChange } }) => (
            <TextFieldStyled>
              <InputLabel htmlFor="password" $error={!!fieldState.error}>
                Password*
              </InputLabel>
              <Input $error={!!fieldState.error} name="password" onChange={onChange} />
            </TextFieldStyled>
          )}
        />
        <ErrorMessage errors={errors} name="password" render={ControlErrorMessage} />
      </div>
    </Flex>
  );
};
