import React from 'react';
import { useModal } from '@Modal';
import { ModalHeader, CloseIcon } from 'Modal/components/ModalStandard';
import { MonitoringPreferences } from './MonitoringPreferences';
import { PreferencesModalTitle, PreferencesModalWrapper } from './styled';
import { IMonitoringPreferencesModalProps } from './interfaces';

export const MonitoringPreferencesModal: React.FC<IMonitoringPreferencesModalProps> = ({ repositoryId, isEditMode, onMonitor }) => {
  const { closeModal } = useModal();

  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (e) => e.stopPropagation();

  return (
    <PreferencesModalWrapper onClick={stopPropagation}>
      <ModalHeader>
        <PreferencesModalTitle>Monitoring preferences</PreferencesModalTitle>
        <CloseIcon onClick={closeModal} />
      </ModalHeader>
      <MonitoringPreferences repositoryId={repositoryId} isEditMode={isEditMode} onMonitor={onMonitor} onClose={closeModal} />
    </PreferencesModalWrapper>
  );
};
