import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const EmbeddedTabLink = styled(NavLink)`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[3]}`};
  &.active {
    border-radius: 5px 5px 0px 0px;
    border-top: 2px solid ${({ theme }) => theme.colors.indigo[500]};
    background: ${({ theme }) => theme.colors.white};
    & span {
      border-bottom: none;
    }
  }
`;
