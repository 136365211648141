import { axiosConfig as axios } from '@Utils';
import { IScanConfigSet } from '@blindspot/common/types/bff/configs';

export const createScanConfigSet = async (data: Partial<IScanConfigSet>, token: string): Promise<IScanConfigSet> => {
  const response = await axios.post('configs/scan', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
