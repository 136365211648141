import { axiosConfig as axios } from '@Utils';
import { ICreateReportDto } from '../../interfaces';

export const createReportApi = async (data: ICreateReportDto, token: string) => {
  return axios.post('report', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
