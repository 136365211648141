import styled from 'styled-components';
import { Flex } from '@Styles';

export const Container = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
`;
