import { ENotificationType, toastService } from '@Notification';

export const copyToClipboard = (textToCopy: string, entityName: string) => {
  const el = document.createElement('textarea');
  el.value = textToCopy;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  toastService({
    header: 'Copied to clipboard',
    message: `${entityName} copied to clipboard successfully`,
    type: ENotificationType.SUCCESS,
  });
};
