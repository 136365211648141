import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NotAvailable } from '@APITypes';
import { DrawerStandard, useDrawer } from '@Drawer';
import { SecurityScoreDetails } from './SecurityScoreDetails';
import { ISecurityScoreProps } from './interfaces';
import { ScoreBlock, Categories, CategoryScoreBlock, ScoreCircle, ScoreCircleBlock, ScoreDetailsLabel, Arrow, Clickable } from './styled';

export const SecurityScore: React.FC<ISecurityScoreProps> = ({ score, vulnerability, severity }) => {
  const isCVSSVector = !!vulnerability.cvssV3Vector;
  const { openDrawer } = useDrawer();

  const onClickScore = () => {
    if (isCVSSVector) {
      const drawerContent = <DrawerStandard title="" content={<SecurityScoreDetails score={score} vulnerability={vulnerability} severity={severity} />} />;
      openDrawer(drawerContent);
    }
  };

  return (
    <ScoreBlock>
      <ScoreCircleBlock>
        {score && (
          <ScoreCircle $severity={severity} value={Number(score) * 10} strokeWidth={10} $small>
            <span>{score}</span>
          </ScoreCircle>
        )}
        {!score && NotAvailable}
        <Clickable onClick={onClickScore}>
          <ScoreDetailsLabel $underline={isCVSSVector}>CVSS 3 Score</ScoreDetailsLabel>
          {isCVSSVector && <Arrow icon={faChevronRight} />}
        </Clickable>
      </ScoreCircleBlock>
      <Categories>
        <CategoryScoreBlock>
          <span>Exploitation Score</span>
          <span>{vulnerability.cvssV3ExploitabilitySubScore || NotAvailable}</span>
        </CategoryScoreBlock>
        <CategoryScoreBlock>
          <span>Impact Score</span>
          <span>{vulnerability.cvssV3ImpactSubScore || NotAvailable}</span>
        </CategoryScoreBlock>
      </Categories>
    </ScoreBlock>
  );
};
