import styled, { css } from 'styled-components';

export const ModalWindowContainer = styled.div<{ $open: boolean; $transitioning: boolean; $transitionSpeedMs: number }>`
  position: fixed;
  z-index: 1600;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  transition: ${({ $transitionSpeedMs }) => css`all ${$transitionSpeedMs}ms ease`};

  ${({ $open, $transitioning }) =>
    $open &&
    $transitioning &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
