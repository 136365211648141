import React from 'react';
import { useModal } from '@Modal';
import { CloseIcon, ModalContainer, ModalContent, ModalHeader, ModalTitle, ModalTitleWrapper, ModalDescription } from './styled';
import { IModalStandardProps } from './interfaces';

export const ModalStandard: React.FC<IModalStandardProps> = ({ title, description, content }) => {
  const { closeModal } = useModal();

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalTitleWrapper>
          <ModalTitle>{title}</ModalTitle>
          {description && <ModalDescription>{description}</ModalDescription>}
        </ModalTitleWrapper>

        <CloseIcon onClick={closeModal} />
      </ModalHeader>

      <ModalContent>{content}</ModalContent>
    </ModalContainer>
  );
};
