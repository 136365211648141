import { axiosConfig as axios } from '@Utils';

export const fetchSastIssueFileById = async (token: string, sastIssueUuid: string) => {
  const response = await axios.get(`/issues/first-party/file/${sastIssueUuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
