import styled, { css } from 'styled-components';

export const TableResizerStyled = styled.div<{ $isResizing: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 3px;
  background-color: ${({ theme }) => theme.colors.indigo[200]};
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: 0;

  ${({ $isResizing }) =>
    $isResizing &&
    css`
      background-color: ${({ theme }) => theme.colors.indigo[300]};
      opacity: 1;
    `}
`;
