import React, { useState, useMemo } from 'react';
import { TabPages } from '@Components';
import { IssuesContainer } from '../styled';
import { ISastIssueDetailsAdditionalProps } from './interfaces';
import { useLocation } from 'react-router-dom';
import { TabContent } from '@Styles';
import { SastIssueDetailsMetadata } from './SastIssueDetailsMetadata';

export const SastIssueDetailsAdditional: React.FC<ISastIssueDetailsAdditionalProps> = ({ issue, isDiff }) => {
  const location = useLocation();
  const baseUrl = location.pathname;

  const tabs = useMemo(() => {
    const tabsArray = [
      {
        title: 'Details',
        key: 'details',
        linkTo: `${baseUrl + location.search}`,
      },
    ];

    return tabsArray;
  }, [baseUrl]);

  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const onTabClick = (tab) => {
    setActiveTab(tab.key);
  };

  return (
    <div>
      <IssuesContainer>
        <TabPages activeTab={activeTab} onClick={onTabClick} items={tabs} />
      </IssuesContainer>
      <TabContent>
        <SastIssueDetailsMetadata issue={issue} />
      </TabContent>
    </div>
  );
};
