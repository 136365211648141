import styled from 'styled-components';

export const DotSeparatorStyled = styled.span`
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  line-height: 24px;
  letter-spacing: 0.2px;
  margin-left: ${({ theme }) => theme.spacing[1]};
  margin-right: ${({ theme }) => theme.spacing[1]};
`;
