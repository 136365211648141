import { Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const ModalContent = styled(Grid)(
  ({ theme }) => css`
    margin-top: ${theme.spacing[1.5]};
    row-gap: ${theme.spacing[3]};
    position: relative;
    min-height: 200px;
  `,
);
