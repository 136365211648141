import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useModal } from '@Modal';
import { ENotificationType, toastService } from '@Notification';
import { Button, ButtonColor, ButtonSize, ButtonVariant, Loader } from '@Components';
import { IScanConfigSet, EScanConfigName } from '@blindspot/common/types/bff/configs';
import { getConfigValueByConfigName } from '@blindspot/common/utils/configs';
import { useScanConfigSetUpdate, useScanConfigSetDetails } from '../../hooks';
import { ScanConfigSetStyled, ActionsContainerStyled, IndentedBlock, ScanConfigSetHeaderStyled } from './styled';
import {
  PRChecksSettings,
  ScanConfigSetCoverage,
  ScanConfigSetActionModal,
  ScanConfigSetActionsMenu,
  ScanConfigSetHeader,
  EmailUpdatesSettings,
  MonitoringEngineSettings,
  MonitoringEngineDisabledWarning,
  DependenciesInventorySettings,
} from './components';
import { isPRChecksConfigValuesEqual, isEmailUpdatesConfigValuesEqual, isMonitoringEngineConfigValuesEqual, isDependenciesInventoryConfigValuesEqual } from './utils';
import { ELoaderSize } from 'Layouts/components/Loader/types';

export const ScanConfigSetDetails: React.FC = () => {
  const { scanConfigSetUuid } = useParams();
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const { data: configSet, isLoading } = useScanConfigSetDetails(scanConfigSetUuid);
  const { mutate: updateScanConfigSetApi, isLoading: isUpdatingScanConfigSet } = useScanConfigSetUpdate();
  const [draftUpdates, setDraftUpdates] = useState<IScanConfigSet>();

  useEffect(() => {
    if (configSet?.uuid !== draftUpdates?.uuid) {
      resetDraftUpdates();
    }
  }, [configSet?.uuid]);

  const updateScanConfigSet = async () => {
    updateScanConfigSetApi(draftUpdates, {
      onSuccess: () => {
        queryClient.invalidateQueries('scanConfigSets');
        toastService({
          header: 'Scan config updated successfully',
          type: ENotificationType.SUCCESS,
        });
      },
      onError: () => {
        toastService({
          header: 'An error occurred while updating scan config',
          type: ENotificationType.ERROR,
        });
      },
    });
  };

  const resetDraftUpdates = () => {
    setDraftUpdates(configSet);
  };

  const onSaveDraftUpdates = () => {
    if (configSet.repositoriesCount > 0) {
      const content = (
        <>
          <p>All changes made to this configuration will apply to all repositories in use.</p>
          <MonitoringEngineDisabledWarning actualConfigValue={monitoringEngineConfigValueActual} draftConfigValue={monitoringEngineConfigValueDraft} />
        </>
      );
      openModal(<ScanConfigSetActionModal repositoriesCount={configSet.repositoriesCount} content={content} onSave={updateScanConfigSet} />);
    } else {
      updateScanConfigSet();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!configSet || !draftUpdates) {
    return null;
  }

  const prChecksConfigValueActual = getConfigValueByConfigName(configSet.configs, EScanConfigName.PR_CHECKS);
  const prChecksConfigValueDraft = getConfigValueByConfigName(draftUpdates.configs, EScanConfigName.PR_CHECKS);
  const emailUpdatesConfigValueActual = getConfigValueByConfigName(configSet.configs, EScanConfigName.EMAIL_UPDATES);
  const emailUpdatesConfigValueDraft = getConfigValueByConfigName(draftUpdates.configs, EScanConfigName.EMAIL_UPDATES);
  const monitoringEngineConfigValueActual = getConfigValueByConfigName(configSet.configs, EScanConfigName.MONITORING_ENGINE);
  const monitoringEngineConfigValueDraft = getConfigValueByConfigName(draftUpdates.configs, EScanConfigName.MONITORING_ENGINE);
  const dependenciesInventoryConfigValueActual = getConfigValueByConfigName(configSet.configs, EScanConfigName.DEPENDENCIES_INVENTORY);
  const dependenciesInventoryConfigValueDraft = getConfigValueByConfigName(draftUpdates.configs, EScanConfigName.DEPENDENCIES_INVENTORY);

  const configSetChanged = configSet.name !== draftUpdates?.name;
  const prChecksConfigValueChanged = !isPRChecksConfigValuesEqual(prChecksConfigValueActual, prChecksConfigValueDraft);
  const emailUpdatesConfigValueChanged = !isEmailUpdatesConfigValuesEqual(emailUpdatesConfigValueActual, emailUpdatesConfigValueDraft);
  const monitoringEngineConfigValueChanged = !isMonitoringEngineConfigValuesEqual(monitoringEngineConfigValueActual, monitoringEngineConfigValueDraft);
  const dependenciesInventoryConfigValueChanged = !isDependenciesInventoryConfigValuesEqual(dependenciesInventoryConfigValueActual, dependenciesInventoryConfigValueDraft);
  const shouldRenderActions =
    configSet.uuid === draftUpdates.uuid &&
    (configSetChanged || prChecksConfigValueChanged || emailUpdatesConfigValueChanged || monitoringEngineConfigValueChanged || dependenciesInventoryConfigValueChanged);

  return (
    <ScanConfigSetStyled>
      <IndentedBlock>
        <ScanConfigSetHeaderStyled>
          <ScanConfigSetHeader configSetUuid={configSet.uuid} repositoriesCount={configSet.repositoriesCount} name={draftUpdates.name} setDraftUpdates={setDraftUpdates} />
          <ScanConfigSetActionsMenu configSet={configSet} />
        </ScanConfigSetHeaderStyled>
      </IndentedBlock>

      <ScanConfigSetCoverage />
      <IndentedBlock>
        <DependenciesInventorySettings configValue={dependenciesInventoryConfigValueDraft} setDraftUpdates={setDraftUpdates} />
        <MonitoringEngineSettings configValue={monitoringEngineConfigValueDraft} setDraftUpdates={setDraftUpdates} />
        <PRChecksSettings configValue={prChecksConfigValueDraft} setDraftUpdates={setDraftUpdates} />
        <EmailUpdatesSettings configValue={emailUpdatesConfigValueDraft} setDraftUpdates={setDraftUpdates} />
      </IndentedBlock>

      {shouldRenderActions && (
        <ActionsContainerStyled>
          <Button onClick={resetDraftUpdates} color={ButtonColor.INDIGO} variant={ButtonVariant.OUTLINED} size={ButtonSize.LARGE}>
            Cancel
          </Button>
          <Button disabled={isUpdatingScanConfigSet} onClick={onSaveDraftUpdates} variant={ButtonVariant.CONTAINED} size={ButtonSize.LARGE}>
            {isUpdatingScanConfigSet ? <Loader size={ELoaderSize.SMALL} /> : 'Save'}
          </Button>
        </ActionsContainerStyled>
      )}
    </ScanConfigSetStyled>
  );
};
