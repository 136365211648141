import styled, { css } from 'styled-components';

export const PreferencesSectionTitle = styled.p<{ $indented?: boolean }>(
  ({ theme, $indented }) => css`
    font-size: ${theme.text.size.subtitle2};
    font-weight: ${theme.text.weight.medium};
    letter-spacing: 0.15px;
    line-height: 160%;
    color: ${theme.colors.zinc[500]};
    margin-bottom: ${theme.spacing[1.5]};

    ${$indented &&
    css`
      margin-left: ${theme.spacing[1.5]};
    `}
  `,
);
