import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { PaginationState } from '@tanstack/react-table';

import { useProtectFinish, useProtectStart, useQueryParams, useSearch, useSubscribe } from '@Utils';
import { CardList, initialListPagination } from 'core/CardList';
import { EPullRequestState } from '@blindspot/common/types/bff';

import { usePullRequests } from '../PullRequestsChecks/hooks';
import { TableNoData } from '../DependenciesTable/styled';
import { PullRequestCard } from './PullRequestCard';
import { initialPaginatedData } from 'core/CardList/config';

export const PullRequestsList = () => {
  const { repoId } = useParams();
  const { queryParams } = useQueryParams({});
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: queryParams.page - 1 || initialListPagination.pageIndex,
    pageSize: queryParams.size || initialListPagination.pageSize,
  });
  const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
  const [searchValue, setSearchValue] = useState(queryParams.search);
  useSearch(searchValue, { pageSize, setPagination });

  const pullRequestParams = {
    repoId,
    pagination,
    search: searchValue || '',
    filters: {
      'filter[state]': EPullRequestState.OPEN,
      'filter[hasIssues]': true,
    },
  };

  const { data: pullRequests = initialPaginatedData, isLoading, refetch } = usePullRequests(pullRequestParams);

  useProtectFinish(refetch);
  useProtectStart(refetch);

  const showSearch = pullRequests?.meta?.itemCount > 0;

  const emptyStateComponent = (
    <TableNoData>
      <FontAwesomeIcon icon={faCircleCheck} />
      <p>No pull requests</p>
    </TableNoData>
  );

  const options = {
    pagination: {
      value: pagination,
      onChange: setPagination,
      pageCount: pullRequests?.meta?.pageCount,
      itemCount: pullRequests?.meta?.itemCount,
    },
    ...(showSearch && {
      search: {
        value: searchValue,
        onChange: (value: string) => setSearchValue(value),
      },
    }),
  };

  return (
    <CardList key={`pull-requests-list-${repoId}`} cardComponent={PullRequestCard} data={pullRequests.data} listFeatures={options} emptyState={emptyStateComponent} isLoading={isLoading} />
  );
};
