import styled, { css } from 'styled-components';

export const InputStyled = styled.input<{ $error?: boolean }>(
  ({ theme, $error }) => css`
    font-family: Manrope;
    letter-spacing: 0.2px;
    line-height: 21px;
    outline: none;
    color: ${theme.colors.zinc[800]};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    border-radius: 8px;
    padding: ${`${theme.spacing['0.5']} ${theme.spacing['1']}`};
    border: 1px solid ${theme.colors.zinc[200]};
    transition: border 200ms;

    &:focus {
      border: 1px solid ${theme.colors.indigo[500]};
    }

    ${$error &&
    css`
      border: 1px solid ${theme.colors.rose[600]};
    `}

    ::placeholder {
      color: ${({ theme }) => theme.colors.zinc[800]};
    }
  `,
);
