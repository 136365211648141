import React, { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ResourceContext, resourceContextDefault } from '@Contexts';
import { TabPages } from '@Components';
import { useIssuesSummary } from './hooks';
import { EIssueGroup, IIssuesGroupsContextType } from './interfaces';
import { ContainerStyled, ContextContainer, HeaderContainerStyled, IssuesHeading } from './styled';
import { TabContent } from '@Styles';
import { IssuesSourceTypeSwitch } from './components';
import { EIssueSourceType } from '@blindspot/common/types/bff';

export const IssuesPage: React.FC<{ issueSourceType?: EIssueSourceType }> = ({ issueSourceType }) => {
  const [allIssuesCount, setAllIssuesCount] = useState(0);
  const [recommendedIssuesCount, setRecommendedIssuesCount] = useState(0);
  const [lowRiskIssuesCount, setLowRiskIssuesCount] = useState(0);
  const [ignoredIssuesCount, setIgnoredIssuesCount] = useState(0);
  const [fixedIssuesCount, setFixedIssuesCount] = useState(0);
  const { data: issuesSummary } = useIssuesSummary({ issueSourceType });
  useEffect(() => {
    resetIssuesCounts();
  }, [issuesSummary?.fixed, issuesSummary?.ignored, issuesSummary?.lowRisk, issuesSummary?.recommended, issuesSummary?.total]);

  const resetIssuesCounts = () => {
    setAllIssuesCount(issuesSummary?.total);
    setRecommendedIssuesCount(issuesSummary?.recommended);
    setLowRiskIssuesCount(issuesSummary?.lowRisk);
    setIgnoredIssuesCount(issuesSummary?.ignored);
    setFixedIssuesCount(issuesSummary?.fixed);
  };

  const tabItems = useMemo(() => {
    return [
      {
        title: 'All',
        key: EIssueGroup.ALL,
        linkTo: EIssueGroup.ALL,
        number: allIssuesCount,
      },
      {
        title: 'Recommended',
        key: EIssueGroup.RECOMMENDED,
        linkTo: EIssueGroup.RECOMMENDED,
        number: recommendedIssuesCount,
      },
      {
        title: 'Low Risk',
        key: EIssueGroup.LOW_RISK,
        linkTo: EIssueGroup.LOW_RISK,
        number: lowRiskIssuesCount,
      },
      {
        title: 'Ignored',
        key: EIssueGroup.IGNORED,
        linkTo: EIssueGroup.IGNORED,
        number: ignoredIssuesCount,
      },
      {
        title: 'Fixed',
        key: EIssueGroup.FIXED,
        linkTo: EIssueGroup.FIXED,
        number: fixedIssuesCount,
      },
    ];
  }, [allIssuesCount, recommendedIssuesCount, lowRiskIssuesCount, ignoredIssuesCount, fixedIssuesCount]);
  return (
    <ContainerStyled>
      <ResourceContext.Provider value={resourceContextDefault}>
        <ContextContainer>
          <HeaderContainerStyled>
            <IssuesHeading>Issues</IssuesHeading>
            <IssuesSourceTypeSwitch />
          </HeaderContainerStyled>
          <TabPages items={tabItems} onClick={resetIssuesCounts} />
          <TabContent>
            <Outlet
              context={
                {
                  setAllIssuesCount,
                  setRecommendedIssuesCount,
                  setLowRiskIssuesCount,
                  setIgnoredIssuesCount,
                  setFixedIssuesCount,
                } as IIssuesGroupsContextType
              }
            />
          </TabContent>
        </ContextContainer>
      </ResourceContext.Provider>
    </ContainerStyled>
  );
};
