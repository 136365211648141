import { Flex } from '@Styles';
import styled from 'styled-components';

export const ActionContainer = styled(Flex)`
  width: 210px;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[1]};
  margin-top: 40px;
`;
