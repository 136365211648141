import styled from 'styled-components';

export const UserWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 32px auto ${(props) => props.theme.spacing['1.5']};
  grid-column-gap: ${({ theme }) => theme.spacing['1.5']};
  align-items: center;
  height: 48px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing['1']};
  margin: ${({ theme }) => theme.spacing['2']} 0;
  color: ${({ theme }) => theme.colors.white} !important;
  font-weight: ${({ theme }) => theme.text.weight.regular} !important;
`;
