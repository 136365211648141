import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { TabPages } from '@Components';
import { TabsContainer } from 'Layouts/SettingsPage/styled';
import { TabContent } from '@Styles';
import { IPullRequest } from '@blindspot/common/types/bff/api';
import { IPullRequestDetailsContext } from '../../types';

export const PullRequestDetailsAdditional = ({ pullRequest }: { pullRequest: IPullRequest }) => {
  const { repoId, pullRequestUuid } = useParams();
  const baseUrl = `/discovery/repositories/${repoId}/pull-requests/${pullRequestUuid}`;

  const tabs = useMemo(() => {
    return [
      {
        title: 'Issues',
        key: 'issues',
        linkTo: `${baseUrl}/issues/all`,
      },
      {
        title: 'Dependencies',
        key: `dependencies`,
        linkTo: `${baseUrl}/dependencies`,
      },
      {
        title: 'Remediation Plan',
        key: 'remediation',
        linkTo: `${baseUrl}/remediation`,
      },
    ];
  }, [baseUrl]);

  return (
    <>
      <TabsContainer>
        <TabPages items={tabs} />
      </TabsContainer>
      <TabContent>
        <Outlet
          context={
            {
              pullRequest,
            } as IPullRequestDetailsContext
          }
        />
      </TabContent>
    </>
  );
};
