import { EIssueSubType } from '@APITypes';
import { CodeFiles } from '@Components';
import { isEmpty } from '@Utils';
import { ICodeDetails } from 'Layouts/components/CodeFiles/types';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { IIssueDetailsContext } from '../types';
import { CodeBlock, GptResponse } from './components';
import { CodeColumnsStyled, CodeDetailsStyled, CodeViewerStyled, NoDataLabelStyled, TamperedStyled, VerticalDivider } from './styled';
import { prepareComponentData } from './utils';
import { SCALE_TO_PERCENTAGE } from './consts';

export const CodeViewer: React.FC = () => {
  const { issueDetails } = useOutletContext<IIssueDetailsContext>();
  const [codeDetails, setCodeDetails] = useState<ICodeDetails>({});

  const handleFileClick = ({ decompileEnrich, sourceCode, gptScore, gptDescription }: ICodeDetails) => {
    setCodeDetails({ decompileEnrich, sourceCode, gptScore, gptDescription });
  };

  if (![EIssueSubType.RISKY_CODE, EIssueSubType.TAMPERING].includes(issueDetails.category)) {
    return null;
  }

  const { filePaths, functionsNames, decompileEnrich, sourceCodes, aiScores, aiDescriptions, language, isInvalidCodeData, isSingleColumn, code, binaryCode, scoreLabel } =
    prepareComponentData(issueDetails, codeDetails);

  if (isInvalidCodeData) {
    return (
      <CodeViewerStyled $singleColumn>
        <NoDataLabelStyled>
          There is no data to show
          <br />
          Please reach out to our support for help
        </NoDataLabelStyled>
      </CodeViewerStyled>
    );
  }

  return (
    <CodeViewerStyled>
      <TamperedStyled>
        <CodeFiles
          filesTypeLabel="Tampered Files"
          filePaths={filePaths}
          functionsNames={functionsNames}
          decompileEnrich={decompileEnrich}
          sourceCodes={sourceCodes}
          aiScores={aiScores}
          aiDescriptions={aiDescriptions}
          onFileClick={handleFileClick}
        />
      </TamperedStyled>
      <VerticalDivider />
      {!isEmpty(codeDetails) ? (
        <CodeDetailsStyled>
          <GptResponse score={codeDetails.gptScore * SCALE_TO_PERCENTAGE} description={codeDetails.gptDescription} scoreLabel={scoreLabel} />
          <CodeColumnsStyled $singleColumn={isSingleColumn}>
            <CodeBlock label="Source Code Function" code={code} language={language} />
            {!!binaryCode && <CodeBlock label="Binary Code Function" code={binaryCode} language={language} />}
          </CodeColumnsStyled>
        </CodeDetailsStyled>
      ) : null}
    </CodeViewerStyled>
  );
};
