import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { VerticalDivider } from '../../CodeViewer/styled';
import { useReachabilityIssue } from '../hooks';
import { ReachabilityPathsGraph } from './ReachabilityPathsGraph';
import { ReachabilityPathsSidebar } from './ReachabilityPathsSidebar';
import { ReachabilityPathsContentStyled } from './styled';

export const ReachabilityPathsContent = () => {
  const [selectedChainItemId, setSelectedChainItemId] = useState<string>();
  const { baseIssueUuid, issueUuid } = useParams();
  const { data: reachabilityIssue } = useReachabilityIssue({ baseIssueUuid, issueUuid });

  if (!reachabilityIssue?.chains?.length) {
    return null;
  }

  const selectChainItem = (chainItemId: string) => {
    setSelectedChainItemId(chainItemId);
  };

  return (
    <ReachabilityPathsContentStyled>
      <ReachabilityPathsSidebar chains={reachabilityIssue.chains} selectedNodeId={selectedChainItemId} onNodeClick={selectChainItem} />
      <VerticalDivider />
      <ReachabilityPathsGraph chains={reachabilityIssue.chains} selectedNodeId={selectedChainItemId} onNodeClick={selectChainItem} />
    </ReachabilityPathsContentStyled>
  );
};
