import React from 'react';
import { MonitorButton } from '../SubComponents/MonitorButton';
import { IStatusInfoProps } from './interface';
import { StatusInfoContainerStyled, StatusInfoContentStyled, StatusInfoDescriptionStyled, StatusInfoIcon, StatusInfoTitleStyled, StatusInfoActionsStyled } from './styled';

export const StatusInfo: React.FC<IStatusInfoProps> = ({ repository, onMonitorClick }) => {
  const { uuid: id, languages } = repository;

  return (
    <StatusInfoContainerStyled>
      <StatusInfoIcon />
      <StatusInfoContentStyled>
        <StatusInfoTitleStyled>Unmonitored repository</StatusInfoTitleStyled>
        <StatusInfoDescriptionStyled>This repository will not be scanned, therefore it might contain security issues.</StatusInfoDescriptionStyled>
      </StatusInfoContentStyled>
      <StatusInfoActionsStyled>
        <MonitorButton repositoryId={id} buttonText={`Monitor repository`} languages={languages} onClick={onMonitorClick} />
      </StatusInfoActionsStyled>
    </StatusInfoContainerStyled>
  );
};
