import { ETicketManager } from '@blindspot/common/types/bff/tickets';
import { axiosConfig as axios } from '@Utils';

export const getTicketManagerProjects = async (token: string, ticketManager: ETicketManager) => {
  const response = await axios.get(`/tickets/${ticketManager}/projects`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
