import { axiosConfig as axios } from '@Utils';
import { IInviteTeamMemberRequestPayload } from '../interfaces';

export const inviteUser = async (data: IInviteTeamMemberRequestPayload, token: string) => {
  const response = await axios.post('org/users/invite', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
