import { useMutation } from 'react-query';
import { IScanConfigSet } from '@blindspot/common/types/bff/configs';
import { updateScanConfigSet } from './api';
import { useSession } from '@descope/react-sdk';

export const useScanConfigSetUpdate = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  return useMutation(async (data: Partial<IScanConfigSet>) => {
    if (isAuthenticated && !isSessionLoading) {
      return updateScanConfigSet(data.uuid, data, sessionToken);
    }
  });
};
