import { useContext } from 'react';
import { faTicketSimple } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ETicketManager } from '@blindspot/common/types/bff/tickets';
import { useModal } from '@Modal';
import { ParagonContext } from '@Utils';
import { toastService } from '@Notification';
import { NavActionContainerStyled } from '../../components/styled';
import { CreateTicketModal } from './CreateTicketModal';
import { CreateTicketIconButton } from './CreateTicketIconButton';
import { ICreateTicketButtonProps } from './types';
import { TicketsList } from './TicketsList';

export const CreateTicketButton: React.FC<ICreateTicketButtonProps> = ({ baseIssueUuid, tickets, isIconView }) => {
  const { openModal } = useModal();
  const { ticketManagers, isLoading } = useContext(ParagonContext);

  const onClickCreate = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const enabledTicketManager = ticketManagers.find(({ isEnabled }) => isEnabled)?.type as ETicketManager;
    enabledTicketManager
      ? openModal(<CreateTicketModal ticketManager={enabledTicketManager} baseIssueUuid={baseIssueUuid} />)
      : toastService({ header: 'Please integrate your ticketing system.' });
  };

  if (tickets?.length > 0) {
    return <TicketsList tickets={tickets} />;
  }

  if (isIconView) {
    return <CreateTicketIconButton onClick={onClickCreate} disabled={isLoading} />;
  }

  return (
    <NavActionContainerStyled onClick={onClickCreate} disabled={isLoading}>
      <FontAwesomeIcon icon={faTicketSimple} />
      <span>Open Ticket</span>
    </NavActionContainerStyled>
  );
};
