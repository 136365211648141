import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { RemediationNoDataIconStyled, RemediationNoDataStyled } from './styled';

export const IssueRemediationNoData: React.FC = () => (
  <RemediationNoDataStyled>
    <RemediationNoDataIconStyled icon={faCircleCheck} />
    <div>
      <p>There is no remediation plan for this vulnerability at the moment.</p>
      <p>We are still searching for the best available version.</p>
    </div>
  </RemediationNoDataStyled>
);
