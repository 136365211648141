import { InfoLabelContainer } from 'Layouts/components/InfoLabel/styled';
import { TooltipContainer } from 'Layouts/components/Tooltip/styled';
import styled from 'styled-components';

export const RemediationLabelsStyled = styled.div`
  margin-left: ${({ theme }) => theme.spacing[1]};
  ${TooltipContainer}:last-child {
    margin-right: 0;
  }

  ${InfoLabelContainer} {
    margin: 0;
  }
`;
