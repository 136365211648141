import styled from 'styled-components';
import { Grid } from '@Styles';

export const DetailsColumn = styled(Grid)`
  row-gap: ${({ theme }) => theme.spacing['5']};
  align-content: flex-start;
  font-size: ${({ theme }) => theme.text.size.body2};

  &:first-of-type {
    grid-template-rows: repeat(4, auto);
    padding-right: ${({ theme }) => theme.spacing['6']};
    border-right: 1px solid ${({ theme }) => theme.colors.zinc['200']};
  }

  &:last-of-type {
    grid-template-rows: repeat(2, auto);
    padding-left: ${({ theme }) => theme.spacing['6']};
  }
`;
