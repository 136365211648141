import React, { useCallback, useContext } from 'react';
import { pageTools } from '@Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faRightFromBracket, faArrowDownToLine, faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { useDrawer } from '@Drawer';
import { ExportsDrawer } from 'Layouts/Exports/components';
import { UserDropdownLinkStyled, UserDropdownWrapperStyled } from './styled';
import { PopperContext } from '@Components';
import { useDescope } from '@descope/react-sdk';

export const UserDropdown: React.FC = () => {
  const { logout } = useDescope();

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);
  const { openDrawer } = useDrawer();
  const { setOpen } = useContext(PopperContext);

  const onClickExports = () => {
    setOpen(false);
    openDrawer(<ExportsDrawer />);
  };

  const onSettings = () => {
    setOpen(false);
  };

  const onClickApiReference = () => {
    setOpen(false);
    window.open(process.env.REACT_APP_DOCS_API_REFERENCE_URL, '_blank');
  };

  return (
    <UserDropdownWrapperStyled>
      <UserDropdownLinkStyled onClick={onSettings} to={pageTools.settings.path}>
        <FontAwesomeIcon icon={faGear} />
        Settings
      </UserDropdownLinkStyled>
      <UserDropdownLinkStyled to="#" onClick={onClickExports}>
        <FontAwesomeIcon icon={faArrowDownToLine} />
        Exports
      </UserDropdownLinkStyled>
      <UserDropdownLinkStyled to="#" onClick={onClickApiReference}>
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        API Reference
      </UserDropdownLinkStyled>
      <UserDropdownLinkStyled to="#" onClick={handleLogout}>
        <FontAwesomeIcon icon={faRightFromBracket} />
        Log out
      </UserDropdownLinkStyled>
    </UserDropdownWrapperStyled>
  );
};
