import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

import { NotAvailable } from '@APITypes';
import { pageTools, useQuery, IIntegrations, IntegrationsContext } from '@Utils';
import { useModal } from '@Modal';
import {
  Integrator,
  IntegrationsCategory,
  IntegrationsWrapper,
  IntegrationTitle,
  InfoGitLabTokenOrganizationModal,
  InputGitLabTokenOrganizationModal,
  SelectIntegrationOrganizationModal,
  BitbucketIntegrationModal,
} from './components';
import { IntegrationStatusEnum } from './types';
import { findIntegrationAppType, findIntegrationExpiredAt, findIntegrationStatus } from './utils';
import { ConnectToGithubModal } from './components/ConnectToGithubModal';
import { EScmAppType } from '@blindspot/common/types/bff';
import { Button } from './components/Integrator/styled';
import { Tooltip } from '@Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpgradeTextStyled } from './styled';
import { useIntegrationUpdate, useIntegration, useIntegrationLink } from './hooks';
import { IntegrationsContentTooltip } from './components';
import { TicketingIntegrations } from './TicketingIntegrations';
import { EScmType } from '@blindspot/common/types/bff/scm';

export const IntegrationsContent: React.FC = () => {
  const [localIntegrations, setLocalIntegrations] = useState<IIntegrations[]>([]);
  const { integrations } = useContext(IntegrationsContext);
  const { provider } = useParams<{ provider?: EScmType }>();
  const query = useQuery();
  const { openModal } = useModal();
  const { mutate: setIntegration } = useIntegration();
  const { mutate: updateIntegration } = useIntegrationUpdate();
  const startIntegration = useCallback(
    (provider?: EScmType) => {
      if (!provider) {
        return;
      }
      setLocalIntegrations((prevState) => {
        prevState.push({ type: provider, status: IntegrationStatusEnum.LOADING });
        return prevState;
      });
      switch (provider) {
        case EScmType.GITHUB: {
          const installationId = query.get('installation_id');
          const state = query.get('state');
          const appType = state?.split(':')?.at(-1);
          if (!installationId) {
            return;
          }

          const integrationPayload = {
            provider,
            data: { installationId: +installationId, scmApiType: appType as EScmAppType },
          };

          const integrationHandler = findIntegrationStatus(integrations, EScmType.GITHUB) === IntegrationStatusEnum.ACTIVE ? updateIntegration : setIntegration;

          integrationHandler(integrationPayload, {
            onSuccess: () => {
              window.location.href = pageTools.dashboard.path;
            },
          });
          break;
        }
        case EScmType.AZURE_DEVOPS: {
          const code = query.get('code');
          if (!code) {
            return;
          }
          setIntegration(
            {
              provider,
              data: { code: code },
            },
            {
              onSuccess: () => {
                setLocalIntegrations((prevState) =>
                  prevState.map((integration) => {
                    if (integration.type === EScmType.AZURE_DEVOPS) {
                      return { ...integration, status: IntegrationStatusEnum.PENDING };
                    }
                    return integration;
                  }),
                );
                openModal(<SelectIntegrationOrganizationModal provider={provider} />);
              },
            },
          );
          break;
        }
        case EScmType.GITLAB: {
          break;
        }
        default:
          break;
      }
    },
    [query],
  );

  useEffect(() => {
    startIntegration(provider);
  }, [provider, startIntegration]);

  useEffect(() => {
    if (integrations?.length) {
      setLocalIntegrations(integrations);
    }
  }, [integrations]);

  const { mutate: getScmIntegrationLink } = useIntegrationLink();

  const onScmIntegratorClick = async (provider: EScmType): Promise<void> => {
    const integrationStatus = findIntegrationStatus(localIntegrations, provider);

    if (provider !== EScmType.GITHUB && integrationStatus === IntegrationStatusEnum.PENDING) {
      return openModal(<SelectIntegrationOrganizationModal provider={provider} />);
    }
    if (provider === EScmType.GITHUB) {
      return openModal(<ConnectToGithubModal />);
    }

    if (provider === EScmType.AZURE_DEVOPS) {
      getScmIntegrationLink(
        { provider },
        {
          onSuccess: (data) => {
            window.location.href = data.data.link;
          },
        },
      );
    }

    if (provider === EScmType.GITLAB) {
      return openModal(<InfoGitLabTokenOrganizationModal />);
    }

    if (provider === EScmType.BITBUCKET) {
      return openModal(<BitbucketIntegrationModal />);
    }
  };

  const onIntegratorClick = async (provider: 'jenkins' | 'slack'): Promise<void> => {
    console.log(provider);
  };

  const isDisabledSCM = (scmType: EScmType): boolean => {
    const isLoading = localIntegrations.find((integration) => integration.status === IntegrationStatusEnum.LOADING);
    const isOtherScmPending = localIntegrations.find((integration) => integration.status === IntegrationStatusEnum.PENDING && integration.type !== scmType);

    if (isLoading || isOtherScmPending) {
      return true;
    }

    return localIntegrations.some((localProvider) => localProvider.status === IntegrationStatusEnum.ACTIVE);
  };

  return (
    <IntegrationsWrapper>
      <IntegrationTitle>SCM</IntegrationTitle>
      <IntegrationsCategory>
        <Integrator
          onButtonClick={() => onScmIntegratorClick(EScmType.GITHUB)}
          status={findIntegrationStatus(localIntegrations, EScmType.GITHUB)}
          disabled={isDisabledSCM(EScmType.GITHUB)}
          editButton={
            findIntegrationAppType(integrations, EScmType.GITHUB) === EScmAppType.READ && (
              <Tooltip tooltip={<IntegrationsContentTooltip />} fullWidth>
                <Button onClick={() => openModal(<ConnectToGithubModal />)}>
                  <UpgradeTextStyled>Upgrade</UpgradeTextStyled> <FontAwesomeIcon icon={faCircleInfo} size={'lg'} />
                </Button>
              </Tooltip>
            )
          }
          name="GitHub"
        />
        <Integrator
          onButtonClick={() => onScmIntegratorClick(EScmType.AZURE_DEVOPS)}
          status={findIntegrationStatus(localIntegrations, EScmType.AZURE_DEVOPS)}
          disabled={isDisabledSCM(EScmType.AZURE_DEVOPS)}
          name="Azure Devops"
        />
        <Integrator
          onButtonClick={() => onScmIntegratorClick(EScmType.GITLAB)}
          status={findIntegrationStatus(localIntegrations, EScmType.GITLAB)}
          disabled={isDisabledSCM(EScmType.GITLAB)}
          expiredAt={findIntegrationExpiredAt(localIntegrations, EScmType.GITLAB) || NotAvailable}
          editButton={<Button onClick={() => openModal(<InputGitLabTokenOrganizationModal isUpdate />)}>Manage</Button>}
          name="GitLab"
        />
        <Integrator
          onButtonClick={() => onScmIntegratorClick(EScmType.BITBUCKET)}
          status={findIntegrationStatus(localIntegrations, EScmType.BITBUCKET)}
          disabled={isDisabledSCM(EScmType.BITBUCKET)}
          editButton={<Button onClick={() => openModal(<BitbucketIntegrationModal isUpdate />)}>Manage</Button>}
          name="Bitbucket"
        />
      </IntegrationsCategory>

      <TicketingIntegrations />

      <IntegrationTitle>Communication</IntegrationTitle>
      <IntegrationsCategory>
        <Integrator onButtonClick={() => onIntegratorClick('slack')} status={IntegrationStatusEnum.NOT_INTEGRATED} name="Slack" comingSoon />
      </IntegrationsCategory>
    </IntegrationsWrapper>
  );
};
