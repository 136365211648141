import { faCodeBranch } from '@fortawesome/pro-solid-svg-icons';

import { CardInfoTextStyled, IconStyled } from '../styled';
import { IBranchNameProps } from '../interface';

export const BranchName = ({ branchName }: IBranchNameProps) => {
  return (
    <>
      <IconStyled icon={faCodeBranch} />
      <CardInfoTextStyled>{branchName}</CardInfoTextStyled>
    </>
  );
};
