import { axiosConfig as axios } from '@Utils';
import { ICreateOrganizationTokenResponse } from '../interfaces';

export const createOrganizationToken = async (tokenName: string, token: string): Promise<{ data: ICreateOrganizationTokenResponse }> => {
  return axios.post(
    'org/tokens',
    { name: tokenName },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
