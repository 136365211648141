import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const NoDataIcon = styled(FontAwesomeIcon)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing[0.5]};
    color: ${theme.colors.green[700]};
    font-size: ${theme.text.size.body1};
  `,
);
