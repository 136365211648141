import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const Arrow = styled(FontAwesomeIcon)<{ $rotated: boolean }>`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.zinc[800]};

  ${({ $rotated }) =>
    $rotated &&
    css`
      transform: rotate(180deg);
    `};
`;
