import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useForm, Controller, SubmitHandler, UseControllerReturn } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { ENotificationType, toastService } from '@Notification';
import { useModal } from '@Modal';
import { InputRow, SubmitButton } from './styled';
import { inviteTeamMemberValidationRules } from './validation';
import { DropdownSelect } from '@Components';
import { ControlFormStyled, EmailsInput } from '@Components/Form';
import { IInviteTeamMemberFormData, IInviteTeamMemberRequestPayload } from './interfaces';
import { useOrganizationUserInvite, useUserRoles } from './hooks';
import { useUserOrganization } from '@Hooks';

export const InviteMemberForm: React.FC = () => {
  const queryClient = useQueryClient();
  const { data: organization } = useUserOrganization();
  const { data: roles = [] } = useUserRoles();
  const { mutate: inviteUsersToOrganization } = useOrganizationUserInvite();
  const { closeModal } = useModal();
  const defaultValues = { emails: [], role: '', loginMethod: '' };
  const { control, handleSubmit, setError, clearErrors, reset } = useForm<IInviteTeamMemberFormData>({
    defaultValues,
    shouldFocusError: false,
  });

  const roleSelectOptions = roles.map((role) => {
    return { value: `${role.weight}_${role.name}`, name: role.name };
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  const onSubmit: SubmitHandler<IInviteTeamMemberFormData> = async ({ role, emails }: IInviteTeamMemberFormData) => {
    return await Promise.all(
      emails.map((email) => {
        const invitation: IInviteTeamMemberRequestPayload = {
          email: email.toLowerCase(),
          role,
          orgProviderId: organization.org,
        };
        inviteUsersToOrganization(invitation, {
          onSuccess() {
            toastService({ header: 'Invite sent', type: ENotificationType.SUCCESS });
            queryClient.invalidateQueries('organizationUsers');
            closeModal();
          },
          onError(error, variables) {
            if (error instanceof AxiosError) {
              if (error.response?.data?.message === 'User already exists') {
                toastService({ header: `User with email ${variables.email} already exists` });
                return;
              }
              toastService({ header: 'Something went wrong', type: ENotificationType.ERROR });
            }
          },
        });
      }),
    );
  };

  const renderEmailsInput = ({ field: { onChange, onBlur, ref }, fieldState: { error } }: UseControllerReturn<IInviteTeamMemberFormData, 'emails'>) => (
    <EmailsInput
      refCallback={ref}
      onBlur={onBlur}
      onChange={onChange}
      error={error?.message}
      setError={(message: string) => {
        setError('emails', { message });
      }}
      clearError={() => {
        clearErrors('emails');
      }}
    />
  );

  const renderRoleSelect = ({ field: { onChange, onBlur, value }, fieldState: { error } }: UseControllerReturn<IInviteTeamMemberFormData, 'role'>) => (
    <DropdownSelect
      name="role"
      placeholder="Select role*"
      value={value}
      options={roleSelectOptions}
      error={error?.message}
      onBlur={onBlur}
      onChange={(value) => {
        onChange(value);
      }}
    />
  );

  return (
    <ControlFormStyled onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Controller name="emails" control={control} rules={inviteTeamMemberValidationRules.emails} render={renderEmailsInput} />
      <InputRow>
        <Controller name="role" control={control} rules={inviteTeamMemberValidationRules.role} render={renderRoleSelect} />
      </InputRow>
      <SubmitButton type="submit">Send invite</SubmitButton>
    </ControlFormStyled>
  );
};
