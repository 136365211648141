import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Backdrop } from '@Components';
import { useMountTransition } from '@Utils';
import { DrawerWindowContainer } from './styled';
import { IDrawerWindowProps } from './interfaces';
import { createPortalRoot } from './createPortalRoot';
import { drawerElementId } from './constants';

export const DrawerWindow: React.FC<IDrawerWindowProps> = ({ content, isOpen, closeDrawer }) => {
  const bodyRef = useRef(document.querySelector('body'));
  const portalRootRef = useRef(document.getElementById(drawerElementId) || createPortalRoot());

  const [isTransitioning, transitionSpeedMs] = useMountTransition(isOpen);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeDrawer();
      }
    };

    window.addEventListener('keydown', onKeyDown);
    bodyRef.current.appendChild(portalRootRef.current);
    const portal = portalRootRef.current;
    const bodyEl = bodyRef.current;

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      portal.remove();
      bodyEl.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const updatePageScroll = () => {
      if (isOpen) {
        bodyRef.current.style.overflow = 'hidden';
      } else {
        bodyRef.current.style.overflow = '';
      }
    };

    updatePageScroll();
  }, [isOpen]);

  if (!isOpen && !isTransitioning) {
    return null;
  }

  return createPortal(
    <>
      <DrawerWindowContainer $open={isOpen} $transitioning={isTransitioning} $transitionSpeedMs={transitionSpeedMs} aria-hidden={isOpen ? 'false' : 'true'}>
        {content}
      </DrawerWindowContainer>
      <Backdrop open={isOpen} transitioning={isTransitioning} transitionSpeedMs={transitionSpeedMs} onClick={closeDrawer} />
    </>,
    portalRootRef.current,
  );
};
