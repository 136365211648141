import { useQuery, UseQueryOptions } from 'react-query';
import { PaginationState } from '@tanstack/react-table';
import { fetchRepositoryDependenciesFiles } from '../api';
import { IPage, IRepositoryDependencyFile } from '@APITypes';
import { useSession } from '@descope/react-sdk';

export const useRepositoryDependenciesFiles = (repoId: string, params: PaginationState) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions: UseQueryOptions<IPage<IRepositoryDependencyFile>> = {
    queryKey: ['repoDependenciesFiles', repoId, params],
    enabled: isAuthenticated && !isSessionLoading,
    queryFn: async () => {
      return await fetchRepositoryDependenciesFiles(repoId, params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  };
  return useQuery<IPage<IRepositoryDependencyFile>>(queryOptions);
};
