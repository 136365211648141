import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const MonitoringEngineOptionsWrapperStyled = styled(Flex)(
  ({ theme }) => css`
    flex-direction: column;
    gap: ${theme.spacing[2]};
    width: fit-content;
    border-radius: 20px;
    padding: ${theme.spacing[2.5]} ${theme.spacing[3]};
    background: ${theme.colors.indigo[50]};
    margin-top: ${theme.spacing[2]};
    flex: 1;
  `,
);
