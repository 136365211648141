import { axiosConfig as axios } from '@Utils';
import { IApiPullRequestsIssuesParams } from '../types';

export const fetchPullRequestsIssues = async ({ pagination, repoId, branchId }: IApiPullRequestsIssuesParams, token: string) => {
  const response = await axios(`issues/diff`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      repoId,
      branchId,
      ...(pagination && {
        page: pagination?.pageIndex + 1,
        take: pagination?.pageSize,
      }),
    },
  });
  return response?.data;
};
