import styled from 'styled-components';

export const LoginContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 420px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing[4]};
  border-radius: 30px;
  margin: 30px;
  z-index: 10;
`;
