import styled from 'styled-components';
import { Grid } from '@Styles';

export const SourceTitle = styled(Grid)`
  font-size: ${({ theme }) => theme.text.size['body1']};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  line-height: 28px;
  grid-template-columns: auto 1fr;
  gap: ${({ theme }) => theme.spacing['1']};
  align-items: center;
`;
