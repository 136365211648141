import { useQuery } from 'react-query';
import { IApiDashboardFilterParams } from '../interfaces';
import { fetchRepositoriesScore } from '../api/fetchRepositoriesScore.api';
import { ERepositoryScore } from '@blindspot/common/types/bff/repositories';
import { useSession } from '@descope/react-sdk';

export const useRepositoriesScore = (params?: IApiDashboardFilterParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const key = 'repositoriesScore';

  const queryOptions = {
    queryKey: [key, params],
    queryFn: async () => {
      return await fetchRepositoriesScore(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<ERepositoryScore>(queryOptions);
};
