import styled from 'styled-components';

export const SelectButtonStyled = styled.button<{ ref: any; $isOpen: boolean }>`
  height: 30px;
  background: inherit;
  font-size: ${({ theme }) => theme.text.size.body2};
  color: inherit;
  padding: 0 ${({ theme }) => theme.spacing[2]};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  transition: 200ms;
  border-radius: 13px;
  border: none;
  outline: none;
  cursor: pointer;

  & [data-icon] {
    display: flex;
    font-size: ${({ theme }) => theme.text.size.overline};
  }

  ${({ $isOpen }) => {
    if ($isOpen) {
      return `
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                & [data-icon="angle-down"] {
                    transform: rotate(180deg);
                }
            `;
    }
  }}
`;
