import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IBackButtonProps } from './types';
import { BackLinkStyled, BackLinkTitleStyled } from './styled';

export const BackLink = ({ title, onClick }: IBackButtonProps) => {
  return (
    <BackLinkStyled onClick={onClick}>
      <FontAwesomeIcon icon={faArrowLeft} />
      <BackLinkTitleStyled> {title}</BackLinkTitleStyled>
    </BackLinkStyled>
  );
};
