import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SourceTypeLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing['1']};
  letter-spacing: 0.15px;
  font-size: ${({ theme }) => theme.text.size['subtitle2']};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  color: ${({ theme }) => theme.colors.zinc['500']};
  line-height: 22px;

  :hover,
  :visited,
  :active {
    color: ${({ theme }) => theme.colors.zinc['500']};
    text-decoration: underline;
  }
`;
