import React from 'react';
import { ModalProvider } from '@Modal';
import { ContentBlock } from '../ContentBlock';
import { TeamTable } from '../TeamTable';
import { Profile } from '../Profile';
import { OrganizationTokensTable } from '../OrganizationTokensTable';

export const UserManagement: React.FC = () => {
  return (
    <>
      <ContentBlock title="Profile">
        <Profile />
      </ContentBlock>

      <ContentBlock title="Team">
        <ModalProvider>
          <TeamTable />
        </ModalProvider>
      </ContentBlock>

      <ContentBlock title="Tokens">
        <ModalProvider>
          <OrganizationTokensTable />
        </ModalProvider>
      </ContentBlock>
    </>
  );
};
