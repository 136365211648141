import styled, { css } from 'styled-components';
import { Flex } from '@Styles';

export const TicketsListModalStyled = styled(Flex)(
  ({ theme }) => css`
    width: 400px;
    gap: ${theme.spacing[1]};
    flex-wrap: wrap;
  `,
);
