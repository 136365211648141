import { axiosConfig as axios } from '@Utils';

export const fetchRemediationTargets = async (repoId: string, depId: string, token: string) => {
  const response = await axios(`remediation/repository/${repoId}/sourcedependency/${depId}/targets`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
