import React, { useState } from 'react';
import { IIssueDescriptionByCategory } from './types';
import { IssueDescriptionStyled, IssueDescriptionShowMoreStyled } from './styled';
import { getIssueDescription, isLongDescription } from '@Utils';
import { markdownToHtml } from 'Utils/markdownToHtml';

export const IssueDescriptionByCategory: React.FC<IIssueDescriptionByCategory> = ({ issue }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  let description = getIssueDescription(issue);
  const isLongCurrentDescription = isLongDescription(description);
  if (isLongCurrentDescription && !isExpanded) {
    description = `${description.slice(0, 200)}...`;
  }

  const onClick = () => setIsExpanded(!isExpanded);

  return (
    <div>
      <IssueDescriptionStyled $isLongDescription={isLongCurrentDescription && !isExpanded}>
        <div dangerouslySetInnerHTML={{ __html: markdownToHtml(description) }} />
      </IssueDescriptionStyled>
      {isLongCurrentDescription && <IssueDescriptionShowMoreStyled onClick={onClick}>{`Show ${isExpanded ? 'less' : 'more'}`}</IssueDescriptionShowMoreStyled>}
    </div>
  );
};
