import { ApexOptions } from 'apexcharts';
import { formatNumberWithCommas, transformNumberToLabelText } from '@Utils';
import { chartAnimationOptions } from './chartAnimationOptions';

export const getMonitoredChartOptions = ({ theme, monitored, total }) => {
  return {
    chart: {
      type: 'donut',
      animations: chartAnimationOptions,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
    },
    legend: {
      position: 'bottom',
      fontSize: theme.text.size.overline,
      fontFamily: theme.text.fontFamily,
    },
    labels: ['Monitored', 'Unmonitored'],
    colors: [theme.colors.indigo[500], theme.colors.indigo[200]],
    dataLabels: {
      enabled: false,
    },
    subtitle: {
      text: `${transformNumberToLabelText(monitored)}/${transformNumberToLabelText(total)}`,
      align: 'center',
      floating: true,
      offsetY: 120,
      style: {
        fontSize: theme.text.size.h2,
        fontWeight: 'bold',
        foreColor: theme.colors.black,
        fontFamily: theme.text.fontFamily,
      },
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: theme.text.size.overline,
        fontFamily: theme.text.fontFamily,
      },
      y: {
        formatter: (value) => formatNumberWithCommas(value),
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
  } as ApexOptions;
};
