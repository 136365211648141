import { ControlInputBlockStyled, ControlInputContainer, ControlInputStyled, SubmitButton, CancelButton, ControlFormStyled } from '@Components/Form';
import { ModalStandard } from '@Modal';
import { Flex } from '@Styles';
import { useModal } from '@Modal';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useIssueDetails } from 'Layouts/IssuesPage/hooks';
import { useParams } from 'react-router-dom';
import { useIgnoreIssue } from './hooks';
import { ControlErrorMessage } from 'Layouts/components/Form/Controls';
import { useMemo } from 'react';
import { IIgnoreIssueModalProps } from './interfaces';

export const IgnoreIssueModal: React.FC<IIgnoreIssueModalProps> = ({ isSast, isSastIgnored, handleSastIgnore }) => {
  const { closeModal } = useModal();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ reason: string }>({
    defaultValues: { reason: '' },
    shouldFocusError: true,
  });

  const { baseIssueUuid, pullRequestUuid, branchUuid } = useParams();
  const isDiff = !!pullRequestUuid;
  const diff = useMemo(() => (isDiff ? { branchId: branchUuid } : undefined), [isDiff, branchUuid]);
  const { data: issueDetails } = useIssueDetails({ issueUuid: baseIssueUuid, diff });
  const ignoreIssue = useIgnoreIssue();
  const handleReasonSubmit: SubmitHandler<{ reason: string }> = async ({ reason }) => {
    isSast ? handleSastIgnore({ ignored: !isSastIgnored, reason }) : ignoreIssue.mutate({ issueId: issueDetails?.uuid, data: { reason } });
    closeModal();
  };

  return (
    <ModalStandard
      title="Ignore Issue"
      content={
        <ControlFormStyled onSubmit={handleSubmit(handleReasonSubmit)}>
          <span>Please provide a reason for ignoring this issues:</span>
          <ControlInputBlockStyled margin={2}>
            <ControlInputContainer $focused={false} $error={!!errors.reason}>
              <Controller
                name="reason"
                control={control}
                rules={{ required: 'Please provide a reason for ignoring issue' }}
                render={({ fieldState, field: { onChange } }) => <ControlInputStyled $error={!!fieldState.error} name="Controls" placeholder="Write your reason here*" onChange={onChange} />}
              />
            </ControlInputContainer>
            <ErrorMessage errors={errors} name="reason" render={ControlErrorMessage} />
          </ControlInputBlockStyled>
          <Flex flexDirection="row" justifyContent="flex-end" gap={1}>
            <CancelButton type="button" onClick={closeModal}>
              Cancel
            </CancelButton>
            <SubmitButton type="submit">Ignore</SubmitButton>
          </Flex>
        </ControlFormStyled>
      }
    />
  );
};
