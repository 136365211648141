import ReactSlider from 'react-slider';
import { ISliderProps } from './types';
import { SliderContainerStyled } from './styled/SliderContainer.styled';
import { SliderTrackStyled, SliderTrackContainerStyled, SliderTrackRangedStyled, SliderTrackCirecleStyled, SliderMultiTrackStyled } from './styled';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export const Slider = ({ min, max, value, onChange, isMulti = false }: ISliderProps) => {
  if (isMulti) {
    return (
      <SliderContainerStyled>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="slider-thumb"
          onChange={onChange}
          min={min}
          max={max}
          value={value || [0, 0]}
          defaultValue={[0, 0]}
          renderTrack={(props, state) => (
            <SliderTrackContainerStyled>
              <SliderMultiTrackStyled {...props} $index={state.index} />
            </SliderTrackContainerStyled>
          )}
          renderThumb={(props, state) => (
            <div {...props}>
              <span>{state.valueNow}</span>
              <SliderTrackCirecleStyled border icon={faCircle} />
            </div>
          )}
        />
      </SliderContainerStyled>
    );
  }

  return (
    <SliderContainerStyled>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="slider-thumb"
        onChange={onChange}
        value={value || 0}
        renderTrack={(props, state) => (
          <SliderTrackContainerStyled>
            {state.index === 1 && <SliderTrackRangedStyled>{min}</SliderTrackRangedStyled>}
            <SliderTrackStyled {...props} $index={state.index} />
            {state.index === 1 && <SliderTrackRangedStyled>{max}</SliderTrackRangedStyled>}
          </SliderTrackContainerStyled>
        )}
        renderThumb={(props, state) => (
          <div {...props}>
            <span>{state.valueNow}</span>
            <SliderTrackCirecleStyled border icon={faCircle} />
          </div>
        )}
      />
    </SliderContainerStyled>
  );
};
