import styled from 'styled-components';

export const RemediationEffortSourceCodeStyled = styled.code`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
  align-items: center;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacing[1]};
  background: ${({ theme }) => theme.colors.white};
  border: 0;
  border-radius: ${({ theme }) => theme.spacing[1]};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.text.size.caption};
  word-break: break-all;

  & a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
  }
`;
