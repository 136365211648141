import React from 'react';
import { RemediationNoDataIconStyled, RemediationNoDataStyled } from './styled';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

export const RemediationNoData: React.FC = () => (
  <RemediationNoDataStyled>
    <RemediationNoDataIconStyled icon={faCircleCheck} />
    <p>No remediation</p>
  </RemediationNoDataStyled>
);
