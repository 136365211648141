import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const GptResponseInfoStyled = styled(Flex)(
  ({ theme }) => css`
    flex-direction: column;
    align-self: center;
    gap: 6px;
    color: ${theme.colors.black};
    font-size: ${theme.text.size.caption};
    font-weight: ${theme.text.weight.extraLight};
    line-height: 18px;
    letter-spacing: 0.15px;
  `,
);
