import { fetchUserOrg } from '@API';
import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import { IConnection } from './types';
import { useQuery } from 'react-query';
import { IIntegrations } from '../integrations';
import { useDescope, useSession } from '@descope/react-sdk';

export const useConnection = (): IConnection => {
  const { logout } = useDescope();
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const [integrations, setIntegrations] = useState<IIntegrations[]>([]);
  const { isLoading: integrationsLoading } = useQuery(
    'fetchUserOrg',
    async () => {
      return await fetchUserOrg(sessionToken);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ scm }) => {
        setIntegrations(scm);
      },
      onError: ({ message }) => {
        Sentry.captureMessage(`Layout error: ${message}`);
        handleLogout();
      },
      enabled: isAuthenticated && !isSessionLoading,
    },
  );

  return { integrations, integrationsLoading };
};
