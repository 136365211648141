import { isAfter } from 'date-fns';
import { IIntegrations } from './types';

export const getClosestExpiredAt = (integrations: IIntegrations[]) =>
  integrations.reduce((acc: null, integration) => {
    const { expiredAt } = integration;
    if (!acc) {
      return expiredAt;
    }
    if (expiredAt && isAfter(new Date(expiredAt), new Date(acc))) {
      return expiredAt;
    }
    return acc;
  }, '');
