import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const InfoIcon = styled(FontAwesomeIcon).attrs({ icon: faCircleInfo })(
  ({ theme }) => css`
    color: ${theme.colors.zinc[500]};
    margin-left: 2px;
  `,
);
