import { Flex } from '@Styles';
import styled from 'styled-components';

export const DetailsLabel = styled(Flex)`
  font-size: ${({ theme }) => theme.text.size.body2};
  color: ${({ theme }) => theme.colors.zinc['500']};
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[0.5]};
  align-items: center;
`;
