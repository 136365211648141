import React from 'react';
import { Chip, Tooltip } from '@Components';
import { useModal } from '@Modal';
import { FilePath, FileCell } from './styled';
import { IDependencyFileCellProps } from './types';
import { DependencyFilesModal } from './components';

export const DependencyFilesCell: React.FC<IDependencyFileCellProps> = ({ repositories }) => {
  const { openModal } = useModal();

  const onClickDependencyFile = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    openModal(<DependencyFilesModal repositories={repositories} />);
  };

  const totalFiles = repositories.map(({ files }) => files).flat().length;
  const filePath = `${repositories[0].owner}/${repositories[0].name}/${repositories[0].files[0].path}`;

  return (
    <Tooltip tooltip="Click to show more">
      <FileCell onClick={onClickDependencyFile}>
        <FilePath>{filePath}</FilePath>
        {totalFiles > 1 && <Chip>{`+${totalFiles - 1}`}</Chip>}
      </FileCell>
    </Tooltip>
  );
};
