import ReactApexChart, { Props } from 'react-apexcharts';
import { Loader } from '@Components';

import { ChartStyled, ChartContainerStyled } from '../styled';
import { useContext, useEffect } from 'react';
import { ChartLoadingContext } from '../context';

interface IChartProps extends Props {
  isLoading?: boolean;
  maxPrintWidth?: string;
  isLanguageInUse?: boolean;
  isInteractiveCategories?: boolean;
  isInteractiveTitle?: boolean;
  isInteractiveLabels?: boolean;
}

export const Chart = ({ isLoading, maxPrintWidth, isLanguageInUse, isInteractiveCategories, isInteractiveTitle, isInteractiveLabels, ...chartProps }: IChartProps) => {
  const { setLoadingChart } = useContext(ChartLoadingContext);

  useEffect(() => {
    setLoadingChart(isLoading);
  }, [isLoading]);

  return (
    <ChartStyled isInteractiveCategories={isInteractiveCategories} isInteractiveTitle={isInteractiveTitle} isInteractiveLabels={isInteractiveLabels}>
      {isLoading && <Loader />}
      <ChartContainerStyled data-max-print-width={maxPrintWidth || '540px'} {...(isLanguageInUse && { 'data-language-is-used': true })}>
        {!isLoading && <ReactApexChart {...chartProps} />}
      </ChartContainerStyled>
    </ChartStyled>
  );
};
