import { axiosConfig as axios } from '@Utils';
import { IApiDependencyDetailsParams } from '../interfaces';

export const fetchDependencyDetails = async ({ depId, diff, filters }: IApiDependencyDetailsParams, token: string) => {
  const response = await axios('dependencies', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { depId, diff, ...(filters && filters) },
  });
  return response?.data?.data?.[0];
};
