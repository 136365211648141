import { marked } from 'marked';
import React from 'react';
import { IMaliciousPackageSummaryProps } from './interfaces';
import { MaliciousPackagePreStyled } from './styled';

export const MaliciousPackageSummary: React.FC<IMaliciousPackageSummaryProps> = ({ markdown }) => {
  const htmlContent = marked(markdown);

  return <MaliciousPackagePreStyled dangerouslySetInnerHTML={{ __html: htmlContent.toString() }} />;
};
