import { Flex } from '@Styles';
import { SelectTriggerWrapper } from 'Layouts/components/DropdownSelect/styled';
import styled from 'styled-components';

export const RemediationTargetHeaderStyled = styled(Flex)`
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[3.5]};

  ${SelectTriggerWrapper} {
    min-width: 150px;
  }
`;
