import styled from 'styled-components';

export const RemediationListTitleStyled = styled.span`
  margin-top: ${({ theme }) => theme.spacing[3]};
  margin-left: ${({ theme }) => theme.spacing[2]};
  padding: ${({ theme }) => `0 ${theme.spacing[0.5]}`};
  font-size: ${({ theme }) => theme.text.size.body2};
  color: ${({ theme }) => theme.colors.zinc[500]};
  letter-spacing: 0.2px;
`;
