import React from 'react';
import { CodeInjectStyled } from './styled';
import { ICodeInjectProps } from './interfaces';

export const CodeInject: React.FC<ICodeInjectProps> = ({ code, language = 'plaintext' }) => {
  return (
    <CodeInjectStyled showLineNumbers language={language}>
      {code}
    </CodeInjectStyled>
  );
};
