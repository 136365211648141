import { EScmAppType } from '@blindspot/common/types/bff';
import { RadioLabel } from '../components';

export const appTypeRadioOptions = [
  {
    label: (
      <RadioLabel
        text={'Detect'}
        subText={
          <p>
            By choosing to install the Detect app we will request read-only access to your repositories, we will be able to scan and detect all types of issues (including reachability), but{' '}
            <strong>won’t</strong> be able to <strong>scan PRs</strong>
          </p>
        }
      />
    ),
    value: EScmAppType.READ,
  },
  {
    label: (
      <RadioLabel
        text={'Detect & Protect'}
        subText={
          <p>
            By choosing to install the Detect & Protect app we will request read & write access to your repositories, we will be able to scan and detect all types of issues (including
            reachability), while also <strong>supporting</strong> scanning and commenting on <strong>PRs</strong>
          </p>
        }
      />
    ),
    value: EScmAppType.MANAGEMENT,
  },
];
