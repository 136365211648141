import React from 'react';
import { ModalStandard } from '@Modal';
import { TicketLabel } from './TicketLabel';
import { ITicketsListProps } from './types';
import { TicketsListModalStyled } from './styled';

export const TicketsListModal: React.FC<ITicketsListProps> = ({ tickets }) => {
  return (
    <ModalStandard
      title="Tickets"
      content={
        <TicketsListModalStyled>
          {tickets.map((ticket) => (
            <TicketLabel key={ticket.originTicketId} ticket={ticket} />
          ))}
        </TicketsListModalStyled>
      }
    />
  );
};
