import { isLanguageSupported } from '@Utils';
import { CodingLanguages } from '@blindspot/common/types/bff/scm';
import { DevLanguage } from '@blindspot/common/types/bff/scm';

export const isAnyLanguageSupported = (languages: CodingLanguages) => {
  if (languages) {
    return Object.keys(languages).some((language: DevLanguage) => isLanguageSupported(language));
  }
  return false;
};
