import { useQuery } from 'react-query';
import { ITicketDescriptionRequestParams } from '../types';
import { getTicketDescription } from './api';
import { useSession } from '@descope/react-sdk';

export const useTicketDescription = (params: ITicketDescriptionRequestParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const queryOptions = {
    queryKey: ['useTicketDescription', params],
    queryFn: async () => {
      return await getTicketDescription(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };
  return useQuery<{ description: string; summary: string }>(queryOptions);
};
