import { NavActionContainerStyled } from 'Layouts/IssuesPage/components/issueDetails/components/styled';
import styled, { css } from 'styled-components';

export const CreateRepositoryTicketButtonStyled = styled(NavActionContainerStyled)(
  ({ theme }) => css`
    gap: ${theme.spacing[1]};
    width: 130px;
    height: 32px;
    padding: ${theme.spacing[0.5]} ${theme.spacing[1.5]};
    color: ${theme.colors.zinc[900]};
    border: 1px solid ${theme.colors.zinc[500]};
  `,
);
