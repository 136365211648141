import React from 'react';
import { ReportName, ReportWrapper } from './styled';
import { IReportProps } from './interfaces';
import { ReportDescription, ReportAction } from './components';

export const Report: React.FC<IReportProps> = ({ report }) => {
  return (
    <ReportWrapper>
      <div>
        <ReportName>{report.name}</ReportName>
        <ReportDescription report={report} />
      </div>
      <ReportAction report={report} />
    </ReportWrapper>
  );
};
