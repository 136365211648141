import React from 'react';
import { Link } from 'react-router-dom';
import { getFullRepositoryName } from '@Utils';
import { Loader, Tooltip } from '@Components';
import { ELoaderSize } from 'Layouts/components/Loader/types';
import { ProgressBarItemWrapper, ProgressBarItemSecondaryText, ProgressBarItemPrimaryText, CheckIcon } from '../styled';
import { IRepositoryItem } from '../interfaces';

export const RepositoryItem: React.FC<IRepositoryItem> = ({ repository }) => {
  const repoName = getFullRepositoryName(repository.owner, repository.name);

  return (
    <Link to={`/discovery/repositories/${repository.uuid}/issues`}>
      <ProgressBarItemWrapper>
        <div>
          <ProgressBarItemPrimaryText>{repository.scanningComplete ? 'Scan complete' : 'Scanning repository'}</ProgressBarItemPrimaryText>
          <ProgressBarItemSecondaryText>
            <Tooltip tooltip={repoName}>{repoName}</Tooltip>
          </ProgressBarItemSecondaryText>
        </div>
        {repository.scanningComplete ? <CheckIcon /> : <Loader size={ELoaderSize.SMALL} />}
      </ProgressBarItemWrapper>
    </Link>
  );
};
