import { axiosConfig as axios } from '@Utils';
import { IApiRepositoriesParams } from '../interfaces';

export const fetchRepositories = async (token: string, params?: IApiRepositoriesParams) => {
  const { pagination, search, repoId, depId, filters } = params;
  const response = await axios('repositories', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(pagination && {
        page: pagination?.pageIndex + 1,
        take: pagination?.pageSize,
      }),
      ...(search && { search }),
      repoId,
      depId,
      ...(filters && filters),
    },
  });
  return response?.data;
};
