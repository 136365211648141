import React, { useCallback, useEffect, useState } from 'react';
import { IFilterOptionsProps } from '../../types';
import debounce from 'lodash.debounce';
import { useQueryParams } from '@Utils';
import { useFilter } from '../../context';
import { Slider } from 'core/Slider';

export const FilterRange: React.FC<IFilterOptionsProps> = ({ filterId, min, max, isMulti }) => {
  const { queryParams } = useQueryParams({});
  const [selectedValue, setSelectedValue] = useState(null);
  const { updateFilters } = useFilter();
  const currentFilter = queryParams[`filter[${filterId}]`];

  useEffect(() => {
    if (currentFilter) {
      if (isMulti) {
        setSelectedValue(currentFilter.split('+').map((v) => +v));
      } else {
        setSelectedValue(+currentFilter);
      }
    } else {
      setSelectedValue(null);
    }
  }, [currentFilter]);

  const onOptionChange = useCallback(
    debounce((value: string | null) => {
      updateFilters(filterId, value);
    }, 150),
    [filterId, updateFilters],
  );

  const handleInputChange = useCallback(
    (value: number | number[]) => {
      if (isMulti && Array.isArray(value)) {
        if (value[0] === 0 && value[1] === 0) {
          setSelectedValue(null);
          onOptionChange(null);
          return;
        }

        setSelectedValue(value);
        onOptionChange(value.join('+'));
        return;
      }

      if (+value === 0 || !value) {
        setSelectedValue(null);
        onOptionChange(null);
        return;
      }

      if (+value < min) {
        setSelectedValue(min);
        return;
      } else if (+value > max) {
        setSelectedValue(max);
        return;
      }
      setSelectedValue(value);
      onOptionChange(value.toString());
    },
    [max, min, onOptionChange, selectedValue],
  );

  return <Slider min={min} max={max} value={selectedValue} onChange={handleInputChange} isMulti={isMulti} />;
};
