import { marked } from 'marked';
import React from 'react';
import { TicketDescriptionPreStyled, TicketDescriptionStyled } from './styled';

export const TicketDescription: React.FC<{ markdown: string }> = ({ markdown }) => {
  const htmlContent = marked(markdown);

  return (
    <TicketDescriptionStyled>
      <TicketDescriptionPreStyled dangerouslySetInnerHTML={{ __html: htmlContent.toString() }} />
    </TicketDescriptionStyled>
  );
};
