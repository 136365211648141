import styled from 'styled-components';
import { ColumnAlign } from '../types';
import { TableResizerStyled } from './TableResizer.styled';

export const TableHeaderStyled = styled.th<{ $align?: ColumnAlign }>`
  position: relative;
  color: ${({ theme }) => theme.colors.zinc[600]};
  padding: ${({ theme }) => `${theme.spacing[2.5]} ${theme.spacing[2]}`};
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  text-align: ${({ $align }) => $align || ColumnAlign.Left};

  &:hover {
    background: ${({ theme }) => theme.colors.indigo[100]};

    ${TableResizerStyled} {
      opacity: 1;
    }
  }
`;
