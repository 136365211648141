import { Button } from '@Styles';
import styled from 'styled-components';

export const RemediationEffortShowMoreStyled = styled(Button)`
  background: none;
  padding: 0;
  margin: ${({ theme }) => theme.spacing[2]} 0 0 0;
  color: ${({ theme }) => theme.colors.indigo[500]};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  font-size: ${({ theme }) => theme.text.size.body2};
  font-family: ${({ theme }) => theme.text.fontFamily};

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;
