import styled, { css } from 'styled-components';
import { Grid, Flex } from '@Styles';

export const UserWrapper = styled(Flex)(
  ({ theme }) => css`
    align-items: center;
    gap: ${theme.spacing[2]};
    height: ${theme.spacing[5]};
    color: ${theme.colors.zinc[800]};
  `,
);
