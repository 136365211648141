import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';
import { useSession } from '@descope/react-sdk';

const bffApi = process.env.BFF_BASE_URL;
const bffApiVersion = process.env.REACT_APP_BFF_API_VERSION;
const cluster = process.env.REACT_APP_PUSHER_CLUSTER;
const key = process.env.REACT_APP_PUSHER_KEY;
export const usePusher = () => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();
  const [pusher, setPusher] = useState<Pusher>(undefined);

  const pusherInit = async () => {
    const pusherClass = new Pusher(key, {
      cluster: cluster,
      channelAuthorization: {
        endpoint: `${bffApi}/${bffApiVersion}/user/auth/socket/channel`,
        transport: 'ajax',
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      },
      userAuthentication: {
        endpoint: `${bffApi}/${bffApiVersion}/user/auth/socket`,
        transport: 'ajax',
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      },
    });
    pusherClass.signin();
    setPusher(pusherClass);
  };

  useEffect(() => {
    if (!isAuthenticated || isSessionLoading) {
      return;
    }
    if (!pusher) {
      pusherInit();
    }
  }, [isAuthenticated, isSessionLoading]);

  return { pusher };
};
