import { ContentBlock } from '@Styles';
import styled, { css } from 'styled-components';

export const ExpirationTokenModalWrapper = styled(ContentBlock)(
  ({ theme }) => css`
    padding: ${theme.spacing[3]} ${theme.spacing[6]};
    width: 70vw;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
);
