import { axiosConfig as axios } from '@Utils';

export const fetchOrganizationDiscoveryProgressBar = async (token: string) => {
  const response = await axios('org/discovery/progress-bar', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};
