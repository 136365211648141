import { Button, ButtonModes } from '@FormElements';
import { useNavigate } from 'react-router-dom';
import { ErrorContainerStyled, ErrorInfoStyled } from '@Error';

export const ErrorBlock = ({ isFullHeight = false }) => {
  const navigate = useNavigate();

  return (
    <ErrorContainerStyled $fullHeight={isFullHeight}>
      <ErrorInfoStyled>{`We're sorry but something went wrong, please try again in a couple of minutes`}</ErrorInfoStyled>
      <Button styleMode={ButtonModes.Error} onClick={(): void => navigate(0)}>
        Reload application
      </Button>
    </ErrorContainerStyled>
  );
};
