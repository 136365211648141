import styled, { css } from 'styled-components';

export const SwitchLabelStyled = styled.label<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing[1]};
    width: fit-content;
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.medium};
    line-height: 22px;
    letter-spacing: 0.15px;
    text-align: left;
    color: ${theme.colors.zinc[500]};
    cursor: ${$disabled ? 'default' : 'pointer'};
    user-select: none;
  `,
);
