import { faShieldExclamation, faShieldSlash } from '@fortawesome/pro-regular-svg-icons';
import { NavActionContainerStyled } from '../../../components/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModal } from '@Modal';
import { IgnoreIssueModal } from '../IgnoreIssueModal';
import { useUnIgnoreIssue } from '../hooks';
import { IIgnoreIssueButtonProps } from './interfaces';
import { isEmpty } from '@Utils';

export const IgnoreIssueButton = ({ ignoreIssue, baseIssueUuid, isFixed }: IIgnoreIssueButtonProps) => {
  const { openModal } = useModal();
  const unIgnoreIssue = useUnIgnoreIssue();

  const handleIgnoreIssueClick = () => {
    openModal(<IgnoreIssueModal />);
  };

  const handleUnignoreIssueClick = () => {
    unIgnoreIssue.mutate({ issueId: baseIssueUuid });
  };

  if (isFixed) {
    return null;
  }

  if (!isEmpty(ignoreIssue)) {
    return (
      <NavActionContainerStyled onClick={handleUnignoreIssueClick}>
        <FontAwesomeIcon icon={faShieldExclamation} />
        <span>Unignore Issue</span>
      </NavActionContainerStyled>
    );
  }

  return (
    <NavActionContainerStyled onClick={handleIgnoreIssueClick}>
      <FontAwesomeIcon icon={faShieldSlash} />
      <span>Ignore Issue</span>
    </NavActionContainerStyled>
  );
};
