import { DashboardImageStyled, EmptyStateTitleStyled, NoIntegrationDescriptionStyled, NoIssuesContentStyled } from 'Layouts/DashboardPage/components/EmptyState/styled';
import { DashboardCardStyled } from 'Layouts/DashboardPage/styled';
import StartMonitoringIcon from '@Images/folder-check.svg';

export const StartMonitoring = () => (
  <DashboardCardStyled $maxHeight="300px">
    <NoIssuesContentStyled>
      <DashboardImageStyled src={StartMonitoringIcon} alt="folder-check" />
      <EmptyStateTitleStyled>Start monitoring your entities</EmptyStateTitleStyled>
      <NoIntegrationDescriptionStyled>By monitoring repositories, we will be able scan your dependencies on every change in real-time.</NoIntegrationDescriptionStyled>
    </NoIssuesContentStyled>
  </DashboardCardStyled>
);
