import styled from 'styled-components';

export const NumberChip = styled.span`
  display: inline-block;
  background: ${({ theme }) => theme.colors.zinc[200]};
  padding: 2px ${({ theme }) => theme.spacing[1]};
  border-radius: 64px;
  margin-left: ${({ theme }) => theme.spacing[1]};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  color: ${({ theme }) => theme.colors.zinc[500]};
  font-size: ${({ theme }) => theme.text.size.caption};
  line-height: 16px;
  letter-spacing: 0.15px;
`;
