import { DefaultTheme } from 'styled-components';

export const getChartTitleOptions = (theme: DefaultTheme, text: string, floating?: boolean): { title: ApexTitleSubtitle } => ({
  title: {
    text,
    align: 'left',
    floating,
    style: {
      color: theme.colors.black,
      fontSize: theme.text.size.h3,
      fontFamily: theme.text.fontFamily,
    },
  },
});
