import * as Sentry from '@sentry/react';
import Pusher, { Channel } from 'pusher-js';
import { EWorkflowEvents } from '@APITypes';
import { IEventHandlerRef, SocketEventHandlerType } from '@Utils';

// map of eventHandlers(handlers that passed for hooks/components) for each event
const eventHandlersRef: Partial<Record<EWorkflowEvents, IEventHandlerRef[]>> = {};
// map of subscribedEvents handlers
const eventBindedCallbacks: Partial<Record<EWorkflowEvents, any>> = {};

export const bindEvent = (eventName: EWorkflowEvents, eventHandler: SocketEventHandlerType, pusher: Pusher, throwError: (error: any) => any, sourceId: string, channel?: Channel) => {
  if (!eventHandlersRef[eventName]) {
    eventHandlersRef[eventName] = [];
  }

  if (eventHandlersRef[eventName].some((handler: IEventHandlerRef) => handler.id === sourceId)) {
    return;
  }

  eventHandlersRef[eventName].push({
    id: sourceId,
    handler: eventHandler,
  });

  const callback = (data: any) => {
    try {
      const handlers = eventHandlersRef[eventName];
      handlers.forEach((eventHandler) => {
        eventHandler.handler(data);
      });
    } catch (err) {
      Sentry.captureException(err);
      Sentry.captureMessage(`Websocket Socket client error on ${eventName} event: ${err.message}`);
      throwError(err);
    }
  };

  if (!eventBindedCallbacks[eventName]) {
    eventBindedCallbacks[eventName] = callback;
    if (channel) {
      channel.bind(eventName, eventBindedCallbacks[eventName]);
    } else {
      pusher.user.bind(eventName, eventBindedCallbacks[eventName]);
    }
  }

  return () => {
    const eventHandlersBeforeUnbindLength = eventHandlersRef[eventName].length;
    eventHandlersRef[eventName] = eventHandlersRef[eventName].filter((handler) => handler.id !== sourceId);
    const shouldUnbindEvent = eventHandlersRef[eventName].length === 0 && eventHandlersBeforeUnbindLength > 0;

    if (shouldUnbindEvent) {
      eventBindedCallbacks[eventName] = null;

      if (channel) {
        channel.unbind(eventName);
      } else {
        pusher.user.unbind(eventName);
      }
    }
  };
};
