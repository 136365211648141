import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const DetailsLeftStyled = styled(Flex)(
  ({ theme }) => css`
    align-items: center;
    gap: ${theme.spacing[1]};
    grid-column: 1;
    color: ${theme.colors.zinc[500]};
  `,
);
