import { axiosConfig as axios } from '@Utils';
import { IApiFilterParams } from '@ComponentsTypes';

export const fetchIssuesSeveritySummary = async (token: string, params?: IApiFilterParams) => {
  const response = await axios('issues/summary/severity', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...(params?.filter && params?.filter),
    },
  });
  return response?.data;
};
