import { Flex } from '@Styles';
import styled, { css } from 'styled-components';

export const NotificationsWrapper = styled(Flex)(
  ({ theme }) => css`
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: ${theme.colors.indigo[100]};
    mix-blend-mode: multiply;
    padding: ${theme.spacing[1.5]} ${theme.spacing[2]};
    margin-bottom: ${theme.spacing[2]};
  `,
);
