import React, { useEffect, useState } from 'react';
import { ICodeDetails, IFolderMap, ITamperedFilesProps } from './types';
import { FolderLine } from './FilesList';
import { getFolderTree } from './utils';
import { Block } from '@Styles';
import { CodeFilesTitleStyled, CodeFilesWrapperStyled } from './styled';

export const CodeFiles: React.FC<ITamperedFilesProps> = ({ filePaths, functionsNames, decompileEnrich, sourceCodes, aiScores, aiDescriptions, onFileClick, filesTypeLabel }) => {
  const [folderMap, setFolderMap] = useState<IFolderMap>(new Map());
  const [filesCount, setFilesCount] = useState<number>(0);
  const [selectedKey, setSelectedKey] = useState<string>('');

  useEffect(() => {
    setFolderMap(getFolderTree(filePaths, functionsNames, decompileEnrich, sourceCodes, aiScores, aiDescriptions));
    const counter = new Set<string>();
    filePaths.forEach((file) => {
      counter.add(file);
    });
    setFilesCount(counter.size);

    if (decompileEnrich?.length) {
      const lastDecompileEnrich = decompileEnrich?.length && decompileEnrich[decompileEnrich?.length - 1];
      const lastSourceCode = sourceCodes?.length && sourceCodes[sourceCodes?.length - 1];
      const lastGptScore = aiScores?.length && aiScores[aiScores?.length - 1];
      const lastGptDescription = aiDescriptions?.length && aiDescriptions[aiDescriptions?.length - 1];

      const lastCodeDetails = {
        ...(lastDecompileEnrich && { decompileEnrich: lastDecompileEnrich }),
        ...(lastSourceCode && { sourceCode: lastSourceCode }),
        ...(lastGptScore && { gptScore: lastGptScore }),
        ...(lastGptDescription && { gptDescription: lastGptDescription }),
      } as ICodeDetails;

      const lastFunctionNameIndex = functionsNames.length - 1;
      const lastFunction = functionsNames[lastFunctionNameIndex];

      const selectedKey = `function-${lastFunction}-${lastFunctionNameIndex}`;

      handleFileClick(lastCodeDetails, selectedKey);
    }
  }, [filePaths, functionsNames, decompileEnrich, sourceCodes, aiScores, aiDescriptions]);

  const handleFileClick = (fn: ICodeDetails, key: string) => {
    setSelectedKey(key);
    return onFileClick({ decompileEnrich: fn.decompileEnrich, sourceCode: fn.sourceCode, gptScore: fn.gptScore, gptDescription: fn.gptDescription });
  };

  if (folderMap.size === 0) return null;

  return (
    <Block>
      <CodeFilesTitleStyled>
        {filesCount} {filesTypeLabel}
      </CodeFilesTitleStyled>
      <CodeFilesWrapperStyled>
        {[...folderMap.values()].map((folder) => (
          <FolderLine
            key={folder.name}
            name={folder.name}
            type={folder.type}
            filesList={folder.filesList}
            functions={folder.functions}
            onFunctionClick={handleFileClick}
            selectedKey={selectedKey}
          />
        ))}
      </CodeFilesWrapperStyled>
    </Block>
  );
};
