import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';

import { Select } from 'core/CardList/components';
import { ESelectPosition, ESelectStyleMode } from 'core/CardList/types';
import { rowsInPageOptions } from 'core/CardList';
import { IListPaginationProps } from 'core/CardList/types';
import { PaginationStyled, PaginationWrapperStyled, PaginationPagesStyled, PaginationButtonsStyled, PaginationButtonStyled } from './styled';

export const Pagination: React.FC<IListPaginationProps> = ({ value, pageCount, itemCount, onChange }) => {
  const { pageIndex, pageSize } = value;

  const currentPage = pageIndex + 1;
  const pageItems = pageIndex === 0 ? pageSize : currentPage * pageSize;
  const pageItemsFrom = pageItems - pageSize + 1;
  const pageItemsTo = pageItems <= itemCount ? pageItems : itemCount;

  const handleSelect = (value: number) => {
    onChange({ pageIndex: 0, pageSize: value });
  };

  const handlePreviousPage = () => {
    onChange({ pageIndex: pageIndex - 1, pageSize });
  };

  const handleNextPage = () => {
    onChange({ pageIndex: pageIndex + 1, pageSize });
  };

  const isCanGetPreviousPage = useMemo(() => pageIndex > 0, [pageIndex]);

  const isCanGetNextPage = useMemo(() => pageIndex < pageCount - 1, [pageIndex, pageCount]);

  return (
    <PaginationStyled>
      <PaginationWrapperStyled>
        <span>Row per page:</span>
        <Select styleMode={ESelectStyleMode.Empty} name={'inPage'} options={rowsInPageOptions} selectedOption={pageSize} openDirection={ESelectPosition.Bottom} onChange={handleSelect} />
        <PaginationPagesStyled>
          {`${pageItemsFrom}-${pageItemsTo}`} of {itemCount}
        </PaginationPagesStyled>
        {pageCount > 1 && (
          <PaginationButtonsStyled>
            <PaginationButtonStyled onClick={() => handlePreviousPage()} disabled={!isCanGetPreviousPage}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </PaginationButtonStyled>
            <PaginationButtonStyled onClick={() => handleNextPage()} disabled={!isCanGetNextPage}>
              <FontAwesomeIcon icon={faAngleRight} />
            </PaginationButtonStyled>
          </PaginationButtonsStyled>
        )}
      </PaginationWrapperStyled>
    </PaginationStyled>
  );
};
