import styled, { css } from 'styled-components';

export const TicketDescriptionPreStyled = styled.pre(
  ({ theme }) =>
    css`
      font-size: ${theme.text.size.body2};
      font-family: ${theme.text.fontFamily};
      font-weight: ${theme.text.weight.regular};
      color: ${theme.colors.black};
      margin: 0;
      padding: 0;
      white-space: normal;

      & > p {
        margin-top: ${theme.spacing[1.5]};
        margin-bottom: 0px;
      }

      & p,
      li {
        line-height: 22px;
      }

      & h1,
      h1 * {
        font-size: ${theme.text.size.h3};
        font-weight: ${theme.text.weight.bold};
      }

      & ul {
        list-style: disc;
        padding-left: ${theme.spacing[2]};
        margin: ${theme.spacing[1]} 0;
      }

      & li + li {
        margin-top: ${theme.spacing[0.5]};
      }

      & table {
        border-spacing: 0;
        border: 0.5px solid ${theme.colors.zinc[200]};
        word-break: break-word;
        width: -webkit-fill-available;
        margin: ${theme.spacing[1]} 0;
      }

      & td,
      th {
        text-align: left;
        padding: ${theme.spacing[1]};
        border: 0.5px solid ${theme.colors.zinc[200]};
      }

      & th {
        background: ${theme.colors.zinc[100]};
      }
    `,
);
