import React from 'react';
import { Controller, UseControllerReturn } from 'react-hook-form';
import { pageTools } from '@Utils';
import { RadioGroup } from '@FormElements';
import { IMonitoringPreferencesFormData } from '../../interfaces';
import { ScanConfigsNoteStyled, ScanConfigsWrapperStyled, LinkStyled } from './styled';
import { IScanConfigSetSelectProps } from './interfaces';
import { ScanConfigSetSelectOption } from './ScanConfigSetSelectOption';

export const ScanConfigSetSelect: React.FC<IScanConfigSetSelectProps> = ({ control, scanConfigSets }) => {
  const scanConfigSetSelectOptions = scanConfigSets
    .sort(({ isDefault }) => (isDefault ? -1 : 1))
    .map((scanConfigSet) => ({
      value: scanConfigSet.uuid,
      name: scanConfigSet.name,
      label: <ScanConfigSetSelectOption scanConfigSet={scanConfigSet} />,
    }));

  return (
    <>
      <Controller
        name="scanConfigSet"
        control={control}
        rules={{ required: 'Please select scan configuration' }}
        render={({ field: { onChange, value } }: UseControllerReturn<IMonitoringPreferencesFormData, 'scanConfigSet'>) => (
          <ScanConfigsWrapperStyled>
            <RadioGroup options={scanConfigSetSelectOptions} value={value} onChange={onChange} />
          </ScanConfigsWrapperStyled>
        )}
      />
      <ScanConfigsNoteStyled>
        To edit and create configurations go to{' '}
        <LinkStyled to={`/${pageTools.settings.name}/scan-configurations`} target="_blank" rel="noopener noreferrer">
          settings
        </LinkStyled>
      </ScanConfigsNoteStyled>
    </>
  );
};
