import React, { useEffect } from 'react';
import { Loader } from '@Components';
import { ELoaderSize } from 'Layouts/components/Loader/types';
import { ProgressBarItem, ProgressBarItemTitle } from './styled';
import { IRepositoryProgressBarListItem } from './interfaces';
import { useDynamicProgressBar } from './hooks';
import { getProgressBarTitle } from './utils';

export const RepositoryProgressBarListItem: React.FC<IRepositoryProgressBarListItem> = ({ progressBar, onFinish }) => {
  const { data, updateProgressBar } = useDynamicProgressBar(progressBar);

  useEffect(() => {
    if (progressBar.seen > progressBar.total) {
      updateProgressBar({ seen: progressBar.total, total: progressBar.total });
    } else {
      updateProgressBar(progressBar);
    }
  }, [progressBar]);

  useEffect(() => {
    if (data.seen >= data.total) {
      onFinish(progressBar);
    }
  }, [data]);

  return (
    <ProgressBarItem>
      <Loader size={ELoaderSize.SMALL} />
      <ProgressBarItemTitle>{getProgressBarTitle({ ...progressBar, seen: data.seen, total: data.total })}</ProgressBarItemTitle>
    </ProgressBarItem>
  );
};
