import styled from 'styled-components';

export const DrawerIconButtonStyled = styled.button<{ $disable: boolean }>`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.indigo[300]};
  border-radius: 8px;
  height: 28px;
  width: 32px;
  color: ${({ theme }) => theme.colors.indigo[500]};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${({ $disable }) => ($disable ? 0.5 : 1)};
  cursor: ${({ $disable }) => ($disable ? 'not-allowed' : 'pointer')};
`;
