import { Flex } from '@Styles';
import styled from 'styled-components';

export const ColumnVisibilityItemStyled = styled(Flex)`
  position: relative;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[0.5]};
  color: ${({ theme }) => theme.colors.zinc[900]};
  font-size: ${({ theme }) => theme.text.size.body1};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  transition: 200ms;
  z-index: 10;

  &:hover {
    background: ${({ theme }) => theme.colors.zinc[100]};
  }

  label {
    display: flex;
    align-items: center;
    padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2.5]} `};
    width: 100%;

    svg {
      top: -1px;
      left: -1px;
    }
  }
`;
