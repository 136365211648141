import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { DashboardCardStyled, RedirectButtonStyled } from '../../styled';
import { pageTools } from '@Utils';
import { NoMonitoringContentStyled } from './styled';

export const NoMonitoringContent: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  return (
    <DashboardCardStyled $maxHeight="370px">
      <NoMonitoringContentStyled>
        {children}
        <RedirectButtonStyled onClick={() => navigate(pageTools.discovery.path)}>Go to Discovery</RedirectButtonStyled>
      </NoMonitoringContentStyled>
    </DashboardCardStyled>
  );
};
