import React from 'react';
import { IRemediationIssueLabelsProps } from './types';
import { Tooltip } from '@Components';
import { ESeverityLevel } from '@APITypes';
import { RemediationInfoLabelStyled, RemediationIssueLabelsStyled } from './styled';
import { firstLetterToUpperCase } from '@Utils';

export const RemediationIssueLabels: React.FC<IRemediationIssueLabelsProps> = ({ issues }) => {
  const severityOrder = Object.values(ESeverityLevel);
  const severityIssues = Object.keys(issues) as ESeverityLevel[];
  const sortedIssuesBySeverity = severityIssues.sort((previousValue, currentValue) => (severityOrder.indexOf(previousValue) > severityOrder.indexOf(currentValue) ? -1 : 1));

  return (
    <RemediationIssueLabelsStyled>
      {sortedIssuesBySeverity.map((key, index) => (
        <Tooltip key={`RemediationIssueLabel-${key}-${index}`} tooltip={firstLetterToUpperCase(key)}>
          <RemediationInfoLabelStyled $type={key} $celled>
            {Math.max(0, issues[key])}
          </RemediationInfoLabelStyled>
        </Tooltip>
      ))}
    </RemediationIssueLabelsStyled>
  );
};
