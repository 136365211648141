import { EMonitoringStatus, NotAvailable } from '@APITypes';
import { getFullRepositoryName, getKeyWithMaxValue, isAnyLanguageSupported, isEmpty } from '@Utils';
import { ESign, SignRender } from '@Components';
import { RepositoryCardStyled, RepositoryNameStyled, RepositoryCardInfoStyled, RepositoryCardContentStyled } from './styled';
import { Monitored, RepositoryCardConnections, Scanning, RepositoryIssues, UnsupportedLanguage, LatestCommit } from './components';
import { MonitorButton } from '../../SubComponents';
import { IRepositoryCardProps } from './interfaces';
import { useEffect, useState } from 'react';
import { DotSeparator } from 'Layouts/components/DotSeparator';
import { DevLanguage } from '@blindspot/common/types/bff/scm';
import { ProtectTourMsg } from '../../ProtectTourMsg';
import { ScoreFlag } from 'Layouts/components/ScoreFlag';
import { EScoreFlagSize } from 'Layouts/components/ScoreFlag/types';
import { Flex } from '@Styles';

export const RepositoryCard = (props: IRepositoryCardProps) => {
  const { repository, dialogRenderId, getCardLink, onMonitor } = props;
  const { owner, name, languages, uuid, scmOriginType, dependenciesCount, branchesCount, buildsCount, lastCommitDate, monitoringStatus, severeIssues } = repository;

  const [localMonitoringStatus, setLocalMonitoringStatus] = useState(monitoringStatus);

  const availableLanguages = Object.keys(languages).reduce((acc, language) => {
    if (DevLanguage[language.toUpperCase()]) {
      acc[language] = languages[language];
    }
    return acc;
  }, {});

  const mainLanguage = !isEmpty(availableLanguages) ? getKeyWithMaxValue(availableLanguages) : NotAvailable;
  const isShowIssue = !!(severeIssues?.critical || severeIssues?.high);

  const isSupportLanguage = isAnyLanguageSupported(languages);
  const isShowDialog = dialogRenderId === uuid;

  const onMonitorClick = (branch?: string, scanConfigSetUuid?: string) => {
    setLocalMonitoringStatus(EMonitoringStatus.SCANNING);
    onMonitor(repository, branch, scanConfigSetUuid);
  };

  useEffect(() => {
    setLocalMonitoringStatus(monitoringStatus);
  }, [monitoringStatus]);

  return (
    <RepositoryCardStyled to={getCardLink(repository)}>
      <RepositoryCardContentStyled>
        <Flex>
          <SignRender size="semi-small" name={ESign[scmOriginType]} />
          <RepositoryNameStyled>{getFullRepositoryName(owner, name)}</RepositoryNameStyled>
          {localMonitoringStatus !== EMonitoringStatus.UNMONITORED && <ScoreFlag size={EScoreFlagSize.SMALL} score={props.repository.repoScore} />}
        </Flex>
        <RepositoryCardInfoStyled>
          <SignRender size={'semi-small'} top={'0px'} name={mainLanguage?.toLowerCase() as unknown as ESign} />
          <DotSeparator />
          <RepositoryCardConnections dependenciesCount={dependenciesCount} branchesCount={branchesCount} buildsCount={buildsCount} />
          <DotSeparator />

          {isShowIssue && (
            <>
              <RepositoryIssues {...severeIssues} />
              <DotSeparator />
            </>
          )}

          <LatestCommit lastCommitDate={lastCommitDate as unknown as string} />
        </RepositoryCardInfoStyled>
      </RepositoryCardContentStyled>
      {localMonitoringStatus === EMonitoringStatus.UNMONITORED && isSupportLanguage && !isShowDialog && (
        <MonitorButton repositoryId={repository.uuid} languages={languages} onClick={onMonitorClick} />
      )}
      {localMonitoringStatus === EMonitoringStatus.UNMONITORED && isSupportLanguage && isShowDialog && (
        <ProtectTourMsg>
          <MonitorButton repositoryId={repository.uuid} languages={languages} onClick={onMonitorClick} />
        </ProtectTourMsg>
      )}
      {!isSupportLanguage && <UnsupportedLanguage />}
      {localMonitoringStatus === EMonitoringStatus.SCANNING && <Scanning />}
      {localMonitoringStatus === EMonitoringStatus.MONITORED && <Monitored />}
    </RepositoryCardStyled>
  );
};
