import { useQuery, UseQueryOptions } from 'react-query';
import { fetchOrganizationUsers } from '../api';
import { IApiOrganizationUsersParams } from '../interfaces';
import { IOrganizationUser, IPage } from '@APITypes';
import { useSession } from '@descope/react-sdk';

export const useOrganizationUsers = (params: IApiOrganizationUsersParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions: UseQueryOptions<IPage<IOrganizationUser>> = {
    queryKey: ['organizationUsers', params],
    enabled: isAuthenticated && !isSessionLoading,
    queryFn: async () => {
      return await fetchOrganizationUsers(params, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  };
  return useQuery<IPage<IOrganizationUser>>(queryOptions);
};
