import React from 'react';
import { RemediationListStyled } from './styled';
import { RemediationItem } from './RemediationItem';
import { IRemediationsProps } from './types';
import { ModalProvider } from '@Modal';

export const RemediationList: React.FC<IRemediationsProps> = ({ repoId, remediations, effortLevel }) => (
  <ModalProvider>
    <RemediationListStyled>
      {remediations.map((remediation) => (
        <RemediationItem
          effortLevel={effortLevel}
          key={remediation.dependencyFileName}
          repoId={repoId}
          depFileName={remediation.dependencyFileName}
          depFileUuid={remediation.depFileUuid}
          dependencies={remediation.dependencies}
          depFilefixedIssues={remediation.depFilefixedIssues}
        />
      ))}
    </RemediationListStyled>
  </ModalProvider>
);
