import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import { Tooltip } from '@Components';

const CopyIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.zinc['400']};
  padding: 2.5px;
  cursor: pointer;
`;

interface IProps {
  data: string;
}

export const CopyButton: React.FC<IProps> = ({ data }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2500);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const onCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(data);
  };

  return (
    <Tooltip tooltip={copied ? 'Copied' : 'Copy'}>
      <CopyIcon icon={faClone} onClick={onCopy} />
    </Tooltip>
  );
};
