import React from 'react';
import { monitoringEngineLabels } from '../MonitoringEngineSettings/constants';
import { IMonitoringEngineDisabledWarningProps } from './interfaces';
import { MonitoringEngineDisabledWarningStyled } from './styled';

export const MonitoringEngineDisabledWarning: React.FC<IMonitoringEngineDisabledWarningProps> = ({ actualConfigValue, draftConfigValue }) => {
  const disabledEngines = Object.keys(actualConfigValue).filter((engine) => actualConfigValue[engine] && !draftConfigValue[engine]);

  if (!disabledEngines.length) {
    return null;
  }

  const enginesLabels = disabledEngines.map((engine) => monitoringEngineLabels[engine]).join(', ');

  return (
    <MonitoringEngineDisabledWarningStyled>
      <p>
        By switching <b>OFF</b> the <b>{enginesLabels}</b> all the issues detected by {disabledEngines.length > 1 ? 'those' : 'this'} detection{' '}
        {disabledEngines.length > 1 ? 'engines' : 'engine'} will be permanently removed from the Myrror console.
      </p>
      <p>
        Are you sure you want to switch <b>OFF</b> the <b>{enginesLabels}</b>?
      </p>
    </MonitoringEngineDisabledWarningStyled>
  );
};
