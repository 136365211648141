import styled from 'styled-components';

export const FilePath = styled.span`
  direction: rtl;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
  cursor: pointer;
`;
