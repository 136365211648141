import styled, { css } from 'styled-components';

export const TokenDetailsHelperTextStyled = styled.span(
  ({ theme }) => css`
    font-size: ${theme.text.size.body2};
    padding: ${theme.spacing[1]};
    margin-top: ${theme.spacing[1.5]};
    background: ${theme.colors.indigo[100]};
    border-radius: 8px;
  `,
);
