import { useTheme } from 'styled-components';
import { CreateReportButtonStyled } from '../styled';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { CreateReportButtonProps } from '../interfaces';
import { useContext } from 'react';
import { ChartLoadingContext } from '../context';

export const CreateReportButton = ({ html, setIsReportLoading }: CreateReportButtonProps) => {
  const theme = useTheme();

  const { loadingCharts } = useContext(ChartLoadingContext);
  const isChartsLoading = loadingCharts > 0;

  const resizeElements = (resizableElements: NodeListOf<HTMLElement>) => {
    resizableElements.forEach((element) => {
      const elementWidth = element.offsetWidth;
      const maxPrintWidth = element.dataset.maxPrintWidth;
      if (maxPrintWidth && elementWidth > parseInt(maxPrintWidth, 10)) {
        element.style.width = maxPrintWidth;
      }
    });
  };

  const prepareHtml = (html: HTMLElement, resizableElements: NodeListOf<HTMLElement>, hideInPdfElements: NodeListOf<HTMLElement> | null) => {
    const htmlWidth = html.offsetWidth;
    if (htmlWidth < 1200) {
      html.style.width = '1200px';
      resizeElements(resizableElements);
    }

    hideInPdfElements.forEach((element) => {
      element.style.display = 'none';
    });

    setIsReportLoading(true);
    document.body.style.overflow = 'hidden';
  };

  const normalizeHtml = (html: HTMLElement, resizableElements: NodeListOf<HTMLElement>, hideInPdfElements: NodeListOf<HTMLElement> | null) => {
    html.style.cssText = '';
    resizableElements.forEach((element) => {
      element.style.cssText = '';
    });

    const languagesInUse = html.querySelector('[data-language-is-used]') as HTMLElement;

    languagesInUse.style.width = '300px';

    setTimeout(() => {
      languagesInUse.style.cssText = '';
    }, 100);

    hideInPdfElements.forEach((element) => {
      element.style.display = 'block';
    });

    setIsReportLoading(false);
    document.body.style.overflow = 'auto';
  };

  const handleGeneratePdf = async () => {
    const hideInPdfElements = html.querySelectorAll<HTMLElement>('[data-hide-in-pdf]');
    const resizableElements = html.querySelectorAll<HTMLElement>('[data-max-print-width]');

    prepareHtml(html, resizableElements, hideInPdfElements);

    await new Promise((resolve) => setTimeout(resolve, 100));

    const pages = Array.from(html.querySelectorAll<HTMLElement>('[id^="pdfPage"]'));

    const pdf = new jsPDF('p', 'px', 'a4');

    const pageCanvases = await Promise.all(pages.map((page) => html2canvas(page)));

    pageCanvases.forEach((canvas, index) => {
      if (index > 0) {
        pdf.addPage();
      }
      const imgData = canvas.toDataURL('image/png');
      const htmlHeight = canvas.height;
      const htmlWidth = canvas.width;
      const fullWidth = pdf.internal.pageSize.getWidth();
      const fullHeight = pdf.internal.pageSize.getHeight();

      const margin = 10;

      const width = fullWidth - margin * 2;
      const scaleFactor = fullWidth / htmlWidth;
      const height = htmlHeight * scaleFactor - margin * 2;

      pdf.setFillColor(theme.colors.indigo[50]);
      pdf.rect(0, 0, fullWidth, fullHeight, 'F');

      pdf.addImage(imgData, 'PNG', margin, margin, width, height);
    });

    const nowTime = new Date().toISOString().replace(/:/g, '-').split('T')[0];
    pdf.save(`report-${nowTime}.pdf`);

    normalizeHtml(html, resizableElements, hideInPdfElements);
  };

  return (
    <CreateReportButtonStyled onClick={handleGeneratePdf} data-hide-in-pdf disabled={isChartsLoading}>
      Create Report
    </CreateReportButtonStyled>
  );
};
