export enum RemediationEffortMethodDiffs {
  STABLE = 'STABLE',
  GENERIC_SIGNATURE_CHANGE = 'GENERIC_SIGNATURE_CHANGE',
  CLASS_REMOVED = 'CLASS_REMOVED',
  REMOVED = 'REMOVED',
  STATIC = 'STATIC',
  ABSTRACT = 'ABSTRACT',
  FINAL = 'FINAL',
  SYNCHRONIZED = 'SYNCHRONIZED',
  NATIVE = 'NATIVE',
  VARARGS = 'VARARGS',
  PARAMETER_COUNT = 'PARAMETER_COUNT',
  RETURN_TYPE = 'RETURN_TYPE',
  PARAMETER_TYPE = 'PARAMETER_TYPE',
}
