import { ButtonModes } from '@FormElements';
import styled, { css } from 'styled-components';

export const ButtonStyled = styled.button<{ $mode: ButtonModes }>`
  font-family: ${({ theme }) => theme.text.fontFamily};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.spacing[1]};
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[1.5]}`};
  transition: 200ms;
  font-size: ${({ theme }) => theme.text.size.caption};
  font-weight: ${({ theme }) => theme.text.weight.medium};
  letter-spacing: 0.17px;
  white-space: nowrap;
  outline: none;
  align-items: center;
  display: inline-flex;

  &:disabled {
    border-color: ${({ theme }) => theme.colors.zinc[300]};
    color: ${({ theme }) => theme.colors.zinc[300]};
    cursor: default;
    pointer-events: none;
  }

  ${({ $mode }) => {
    if ($mode === ButtonModes.Transparent) {
      return css`
        border: 1px solid ${({ theme }) => theme.colors.zinc[400]};
        color: ${({ theme }) => theme.colors.indigo[900]};
        background: ${({ theme }) => theme.colors.white};
      `;
    }

    if ($mode === ButtonModes.Opacity) {
      return css`
        border: none;
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.zinc[500]};
      `;
    }

    if ($mode === ButtonModes.TransparentAdditional) {
      return css`
        border: 1px solid ${({ theme }) => theme.colors.indigo[500]};
        color: ${({ theme }) => theme.colors.indigo[500]};
        background: ${({ theme }) => theme.colors.white};
      `;
    }

    if ($mode === ButtonModes.Service) {
      return css`
        border: 1px solid ${({ theme }) => theme.colors.indigo[500]};
        color: ${({ theme }) => theme.colors.indigo[500]};
        background: ${({ theme }) => theme.colors.white};
      `;
    }

    if ($mode === ButtonModes.ServiceFilled) {
      return css`
        border: 1px solid ${({ theme }) => theme.colors.indigo[500]};
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.indigo[500]};

        &:disabled {
          color: ${({ theme }) => theme.colors.white};
          background: ${({ theme }) => theme.colors.zinc[300]};
        }
      `;
    }

    if ($mode === ButtonModes.Monitor) {
      return css`
        font-size: ${({ theme }) => theme.text.size.body2};
        font-weight: ${({ theme }) => theme.text.weight.bold};
        letter-spacing: 0.4px;
        border: 1px solid ${({ theme }) => theme.colors.indigo[500]};
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.indigo[500]};

        &:disabled {
          color: ${({ theme }) => theme.colors.white};
          background: ${({ theme }) => theme.colors.zinc[300]};
        }
      `;
    }

    if ($mode === ButtonModes.Error) {
      return css`
        padding: ${({ theme }) => theme.spacing[2]} 44px;
        font-size: 20px;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.indigo[600]};
        border-radius: 100px;
      `;
    }
  }}
`;
