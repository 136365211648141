import React from 'react';
import { DetailsSource } from '../DetailsSource';
import { DetailsContainer } from '../styled';
import { LeftSide, IssueSubTitleStyled, DetailsStyled } from './styled';
import { EIssueSubType } from '@APITypes';
import { VulnerabilityDetails } from '../VulnerabilityDetails';
import { MaliciousPackagesDetails } from '../MaliciousPackagesDetails';
import { useOutletContext } from 'react-router-dom';
import { IIssueDetailsContext } from '../types';
import { SummaryStyled } from '../VulnerabilityDetails/styled';
import { IssueDescriptionByCategory } from '../components';
import { IDetailsProps } from './interfaces';

export const Details: React.FC<IDetailsProps> = ({ isDiff }) => {
  const { issueDetails } = useOutletContext<IIssueDetailsContext>();

  if (!issueDetails) {
    return <DetailsContainer />;
  }

  return (
    <DetailsStyled>
      <IssueSubTitleStyled>Summary</IssueSubTitleStyled>
      <DetailsContainer>
        <LeftSide>
          {issueDetails.category !== EIssueSubType.MALICIOUS_PACKAGE && (
            <SummaryStyled>
              <IssueDescriptionByCategory issue={issueDetails} />
            </SummaryStyled>
          )}
          {issueDetails.category === EIssueSubType.VULNERABILITY && <VulnerabilityDetails issue={issueDetails} />}
          {issueDetails.category === EIssueSubType.MALICIOUS_PACKAGE && <MaliciousPackagesDetails issue={issueDetails} />}
        </LeftSide>
        <DetailsSource issue={issueDetails} isDiff={isDiff} />
      </DetailsContainer>
    </DetailsStyled>
  );
};
