import { Flex, Grid } from '@Styles';
import styled, { css } from 'styled-components';

export const RecommendedSubtitle = styled(Flex)(
  ({ theme }) => css`
    align-items: flex-start;
    margin-top: 20px;
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.regular};
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${theme.colors.zinc[800]};
  `,
);
