import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

export const InfoIcon = styled(FontAwesomeIcon).attrs({ icon: faInfoCircle })(
  ({ theme }) => css`
    color: ${theme.colors.indigo[400]};
    padding: ${theme.spacing[0.5]} 10px ${theme.spacing[1]} 0;
  `,
);
