import { Button } from '@Styles';
import styled, { css } from 'styled-components';

export const ReportActionButton = styled(Button)(
  ({ theme }) => css`
    background: transparent;
    line-height: 22.4px;
    letter-spacing: 0.4px;
    height: fit-content;
    text-transform: uppercase;
    padding: ${theme.spacing[1]} ${theme.spacing[1.5]};
    font-size: ${theme.text.size.body2};
    font-weight: ${theme.text.weight.bold};
    color: ${theme.colors.indigo[500]};
    word-break: normal;

    &:disabled {
      color: ${theme.colors.zinc[400]};
      cursor: default;
      text-transform: none;
      pointer-events: none;
    }
  `,
);
