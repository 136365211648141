import { axiosConfig as axios } from '@Utils';
import { EScmAppType } from '@blindspot/common/types/bff';

export const getIntegrationLink = async (provider: string, token: string, appType?: EScmAppType) => {
  return axios.get(`scm/integration/${provider}/link${appType ? `/${appType}` : ''}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
