import { useQuery } from 'react-query';
import { fetchRemediationFixedIssues } from '../api';
import { IRemediationIssue } from '@APITypes';
import { useSession } from '@descope/react-sdk';
import { ERemediationEffortLevels } from '@blindspot/common/types/bff/remediation';

export const useRemediationFixedissues = (repoId: string, depFileUuid: string, effortLevel: ERemediationEffortLevels) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['repoRemediationFixedIssues', repoId, depFileUuid],
    queryFn: async () => {
      return await fetchRemediationFixedIssues(repoId, depFileUuid, effortLevel, sessionToken);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IRemediationIssue[]>(queryOptions);
};
