import { ESign } from '@Components';
import { IntegrationModal } from '../IntegrationModal';
import { IBitbucketIntegrationModalProps } from './types';
import { BitbucketIntegrationInfo, ConnectFlowSelection } from './components';

export const BitbucketIntegrationModal = ({ isUpdate }: IBitbucketIntegrationModalProps) => {
  return (
    <IntegrationModal
      hideActions
      nextButtonType="submit"
      title={'Bitbucket'}
      titleSign={ESign.bitbucket}
      info={<BitbucketIntegrationInfo />}
      connectFlowSelection={<ConnectFlowSelection isUpdate={isUpdate} />}
    />
  );
};
