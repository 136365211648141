import { useQuery } from 'react-query';
import { fetchDependenciesSummary } from '@API';
import { IApiFilterParams, IApiDependenciesSummaryResponse } from '@ComponentsTypes';
import { useSession } from '@descope/react-sdk';

export const useDependenciesSummary = (params?: IApiFilterParams) => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

  const queryOptions = {
    queryKey: ['summary', 'dependencies', 'dependenciesSummary', params],
    queryFn: async () => {
      return await fetchDependenciesSummary(sessionToken, params);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated && !isSessionLoading,
  };

  return useQuery<IApiDependenciesSummaryResponse>(queryOptions);
};
