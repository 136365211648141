import styled, { css } from 'styled-components';

export const SmallDetailsStyled = styled.span(
  ({ theme }) => css`
    font-size: ${theme.text.size.overline};
    color: ${theme.colors.zinc[500]};
    font-weight: ${theme.text.weight.extraLight};
    padding-left: ${theme.spacing[1]};
    position: relative;
    top: 1px;
  `,
);
